import "./custom-field-hex-color-editor-element.styl";
import template from "./custom-field-hex-color-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { Observable, PureComputed } from "knockout";
import ko, { observable, observableArray, pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import { defaultStore } from "@/stores/default-store";

export type CustomFieldHexColorEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<string | null>;
} & EditorElementParams<string | null>;

class CustomFieldHexColorEditorElementState {
   private readonly internalState: {
      isDisabled: boolean;
      isEditorPopupVisible: Observable<boolean>;
   };

   readonly isPopupVisible = pureComputed(() => this.internalState.isEditorPopupVisible());

   constructor(isDisabled: boolean) {
      this.internalState = {
         isDisabled,
         isEditorPopupVisible: observable(false),
      };
   }

   readonly setIsPopupVisible = (isVisible: boolean) => {
      if (this.internalState.isDisabled == true) return;
      this.internalState.isEditorPopupVisible(isVisible);
   };

   readonly toggleIsPopupVisible = () => {
      this.setIsPopupVisible(!this.isPopupVisible());
   };
}

export class CustomFieldHexColorEditorElement extends ViewModel {
   private readonly state: CustomFieldHexColorEditorElementState;

   readonly invalidInputMessage: CustomFieldHexColorEditorElementParams["invalidInputMessage"];
   readonly onChange: CustomFieldHexColorEditorElementParams["onChange"];
   readonly title: CustomFieldHexColorEditorElementParams["title"];
   readonly value: CustomFieldHexColorEditorElementParams["value"];

   readonly textFieldMediator = pureComputed<string | null>({
      read: () => this.value(),
      write: (value) => {
         this.onChange(value);
      },
   });

   readonly colorText = pureComputed(() => {
      const color = this.textFieldMediator();
      return color != null && color.length > 0 ? color : "No Value";
   });

   onColorOptionClick = (color: string): void => {
      this.textFieldMediator(color);
   };
   readonly colorOptions = observableArray(defaultStore.getDefaultResourceColorValues());

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldHexColorEditorElementParams) {
      super(template());
      this.state = new CustomFieldHexColorEditorElementState(isDisabled);

      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.title = title;
      this.value = value;
   }

   static factory(
      params: CustomFieldHexColorEditorElementParams,
   ): ComponentArgs<CustomFieldHexColorEditorElementParams> {
      return {
         name: "custom-field-hex-color-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-hex-color-editor-element", {
   viewModel: CustomFieldHexColorEditorElement,
   template: template(),
   synchronous: true,
});

import "./confirm-action-pane.styl"
import template from "./confirm-action-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmActionPaneViewModel extends ModalPane
   constructor: (actionBtnTxt, message, title) ->
      assertArgs(arguments, String, nullable(String), optional(String))
      super("", "", template())
      @title = ko.observable(title or "Confirm Action")
      @actionBtnTxt = ko.observable(actionBtnTxt)
      @message = ko.observable(message)

   confirm: ->
      modalManager.modalFinished()

import "./drop-down-editor.styl";
import type { MaybeObservable, Observable } from "knockout";
import ko, { isComputed, isObservable, observable, pureComputed, unwrap } from "knockout";
import template from "./drop-down-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDownPane, ItemBase } from "@/lib/components/drop-downs/drop-down-pane";
import type { GridCellFactory } from "@/lib/components/grid/grid-column";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";
import type { ActionInterceptor } from "@/lib/components/drop-downs/drop-down-2";
import { isEqualSets } from "@/lib/utils/sets";

export interface DropDownEditorParams<TItem extends ItemBase>
   extends EditorComponentParams<Set<string>, Set<TItem>> {
   pane: MaybeObservable<DropDownPane<TItem>>;
   cellFactory: MaybeObservable<GridCellFactory<TItem>>;
   selectedItemCellFactory?: GridCellFactory<TItem>;
   selectedItem?: MaybeObservable<TItem | null>;
   actionInterceptor?: ActionInterceptor<TItem>;
   /** Whether a validation error is shown until the field is selected. */
   isRequired?: MaybeObservable<boolean>;
   /** Whether the clear button is visible. */
   isClearable?: MaybeObservable<boolean>;
   placeholder?: MaybeObservable<string>;
   selectionDescriptionProvider?: () => string | ComponentArgs | null;
   saveText?: MaybeObservable<string>;
   validators?: Array<(value: Set<string>) => Omit<ValidatedValue<Set<TItem>>, "value">>;
}

export class DropDownEditor<TItem extends ItemBase> extends AbstractFieldEditor<
   Set<string>,
   Set<TItem>
> {
   readonly hasChanged = pureComputed(() => {
      return !isEqualSets(this.value(), this.initialValue);
   });

   readonly pane: DropDownPane<TItem>;
   readonly cellFactory: GridCellFactory<TItem>;
   readonly selectedItem: Observable<TItem | null>;
   readonly validators?: Array<(value: Set<string>) => Omit<ValidatedValue<Set<TItem>>, "value">>;

   constructor(public readonly params: DropDownEditorParams<TItem>) {
      super(
         params,
         isObservable(params.value) || isComputed(params.value)
            ? params.value
            : observable(params.value as Set<string>),
      );
      this.pane = unwrap(params.pane);
      this.cellFactory = unwrap(params.cellFactory);
      this.selectedItem = observable(unwrap(params.selectedItem || null));
      this.validators = params.validators;
   }

   validate(value: Set<string>): ValidatedValue<Set<TItem>> {
      if (this.validators != null) {
         const invalidValue = this.validators
            .map((v) => v(value))
            .find((response) => response.valid == false) as
            | (ValidatedValue<Set<TItem>> & { valid: false })
            | undefined;
         if (invalidValue) return invalidValue;
      } else if (unwrap<boolean | undefined>(this.params.isRequired) && value.size == 0) {
         return { valid: false, error: `${this.title} is required.` };
      }

      if (value.size == 0) {
         return { valid: true, value: new Set<TItem>() };
      }

      // Use the supplied selected item when it's the only selected item.
      const selectedItem = this.selectedItem();
      if (value.size == 1 && value.values().next().value == selectedItem?.id) {
         return {
            valid: true,
            value: new Set([selectedItem!]),
         };
      }

      const gridStore = this.pane.gridStore();
      if (!gridStore) {
         return { valid: false, error: null };
      }

      // Grab all the items from the grid store. Fail if some items don't exist.
      const rows = gridStore.rows();
      const values = Array.from(value).map<TItem>((id) => rows.find((r) => r.id == id)!);
      if (values.some((v) => !v)) {
         return { valid: false, error: "Loading..." };
      }

      return { valid: true, value: new Set(values) };
   }

   onDelete = (): Promise<void> => {
      return this.save(new Set());
   };

   static factory<TRecord, TItem extends ItemBase>(
      provider: (records: TRecord[]) => DropDownEditorParams<TItem>,
   ): (records: TRecord[]) => ComponentArgs<DropDownEditorParams<TItem>> {
      return (records) => ({
         name: "drop-down-editor",
         params: provider(records),
      });
   }
}

ko.components.register("drop-down-editor", {
   viewModel: DropDownEditor,
   template: template(),
   synchronous: true,
});

import React from "react";
import { DetailPage, Breadcrumbs, useI18nContext } from "@procore/core-react";
import type { Project } from "@laborchart-modules/common";

type LaborPlansBreadCrumbsPropTypes = {
   onClick: (route: string) => void;
   project: Project;
   projectDetailsUrl: string;
};
export const LaborPlansBreadCrumbs = (props: LaborPlansBreadCrumbsPropTypes) => {
   const { onClick, project, projectDetailsUrl } = props;
   const I18n = useI18nContext();
   return (
      <DetailPage.Breadcrumbs>
         <Breadcrumbs>
            <a onClick={() => onClick("/home")}>
               <Breadcrumbs.Crumb>
                  {I18n.t("views.company.workforce_planning.app_header")}
               </Breadcrumbs.Crumb>
            </a>
            <a
               onClick={() => {
                  onClick(projectDetailsUrl);
               }}
            >
               <Breadcrumbs.Crumb>{project?.name}</Breadcrumbs.Crumb>
            </a>
            <a href="">
               <Breadcrumbs.Crumb active={true} isLast={true}>
                  {I18n.t("views.company.workforce_planning.labor_plan.create_title")}
               </Breadcrumbs.Crumb>
            </a>
         </Breadcrumbs>
      </DetailPage.Breadcrumbs>
   );
};

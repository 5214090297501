export class ScrollbarMediator
   constructor: (initCallback) ->
      @initCallback = initCallback
      @initialized = false

   checkContentSize: -> throw new Error "checkContentSize not set"

   initialize: (component) =>
      @checkContentSize = component.checkContentSize if component.checkContentSize?
      @initCallback() if @initCallback?
      @initialized = true


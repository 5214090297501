Color = {}

Color.LC_ORANGE = "hsl(19, 100%, 50%)"
Color.LC_ORANGE_DARKER = "hsl(19, 100%, 45%)"
Color.LC_ORANGE_DISABLED = "hsl(19, 100%, 85%)"
Color.LC_WHITE = "#ffffff"
Color.LC_NEAR_WHITE = "#eeeeee"
Color.LC_NEAR_BLACK = "#363d43"

Color.getVisibleTextColor = (hexcolor) ->
   if hexcolor && hexcolor != "undefined"
      hexcolor = hexcolor.toLowerCase()
      if hexcolor == Color.LC_ORANGE || hexcolor == Color.LC_ORANGE_DARKER
         return Color.LC_NEAR_WHITE
      r = parseInt(hexcolor.substr(1,2),16)
      g = parseInt(hexcolor.substr(3,2),16)
      b = parseInt(hexcolor.substr(5,2),16)
      yiq = ((r*299)+(g*587)+(b*114))/1000
      if yiq >= 128
         return Color.LC_NEAR_BLACK
      return Color.LC_NEAR_WHITE
   else
      return Color.LC_NEAR_BLACK

Color.colorIsDark = (hexcolor) ->
   if hexcolor? && hexcolor != "undefined"
      hexcolor = hexcolor.toLowerCase()
      if hexcolor == Color.LC_ORANGE || hexcolor == Color.LC_ORANGE_DARKER
         return true
      r = parseInt(hexcolor.substr(1,2),16)
      g = parseInt(hexcolor.substr(3,2),16)
      b = parseInt(hexcolor.substr(5,2),16)
      yiq = ((r*299)+(g*587)+(b*114))/1000
      if yiq >= 128
         return false
      return true
   else
      return false

Color.blendColors = (hex1, hex2, percent) ->
   # 0% (hex1) -> 100% (hex2)
   ratio = percent / 100
   hex = (x) ->
       x = x.toString(16);
       return if (x.length == 1) then "0#{x}" else x

   r = Math.ceil(parseInt(hex2.substring(0,2), 16) * ratio + parseInt(hex1.substring(0,2), 16) * (1-ratio))
   g = Math.ceil(parseInt(hex2.substring(2,4), 16) * ratio + parseInt(hex1.substring(2,4), 16) * (1-ratio))
   b = Math.ceil(parseInt(hex2.substring(4,6), 16) * ratio + parseInt(hex1.substring(4,6), 16) * (1-ratio))

   return (hex(r) + hex(g) + hex(b))

Color.getRgb = (hex) ->
   values = hex.replace("#", "")
   return [
      parseInt(values.substr(0, 2), 16),
      parseInt(values.substr(2, 2), 16),
      parseInt(values.substr(4, 2), 16),
   ]

Color.isHexColor = (value) -> 
   hexRegex = /^#[0-9a-fA-F]{6}$/
   return hexRegex.test(value)

export Color = Color

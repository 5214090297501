import { Token } from "@procore/core-react";
import React from "react";

export const TokenRenderer = ({ option, removeToken }: any) => (
   <Token
      style={{
         margin: "0 4px 0 -2px",
         padding: "0",
      }}
   >
      <Token.Label
         style={{
            paddingLeft: "8px",
         }}
      >
         {option.name}
      </Token.Label>
      <Token.Remove
         onClick={removeToken}
         style={{
            marginRight: "1px",
         }}
      />
   </Token>
);

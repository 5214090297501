import { Button, Banner, useI18nContext } from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { useGetCurrentAssignments } from "./queries";
import { Info } from "@procore/core-icons";
import { getCurrentAssignmentsFormatted } from "./helpers";
import type { AssignmentFormattedTypes, CurrentAssignmentsBannerProps } from "./types";
import { useGetUpcomingTimeOff } from "../../common/queries/queries";

export const CurrentAssignmentsBanner = (props: CurrentAssignmentsBannerProps) => {
   const { personId, personName, showBanner } = props;

   const { currentAssignments, isLoading } = useGetCurrentAssignments(personId);
   const { scheduledTimeOff, isLoading: isScheduledTimeOff } = useGetUpcomingTimeOff(personId);
   const [scheduleData, setScheduleData] = useState<AssignmentFormattedTypes[] | undefined>([]);

   const I18n = useI18nContext();
   const [isExpanded, setIsExpanded] = React.useState(true);
   const [isBannerVisible, setIsBannerVisible] = useState(showBanner);
   const [assignmentData, setAssignmentData] = useState<AssignmentFormattedTypes[]>([]);

   useEffect(() => {
      setAssignmentData([]);
      setIsExpanded(false);
      setIsBannerVisible(showBanner);
   }, [personId]);

   useEffect(() => {
      if (!isLoading && !isScheduledTimeOff) {
         setScheduleData(
            getCurrentAssignmentsFormatted(currentAssignments?.data, scheduledTimeOff),
         );
      }
   }, [currentAssignments, scheduledTimeOff]);

   const toggleBannerVisibility = () => {
      setIsBannerVisible((prevVisibility) => !prevVisibility);
   };
   const handleButtonClick = (
      assignment: AssignmentFormattedTypes[] | undefined,
      displayState: boolean,
   ) => {
      if (displayState) {
         setAssignmentData([]);
         setIsExpanded(false);
         return;
      }

      setAssignmentData(assignment ?? []);
      setIsExpanded(!isExpanded);
   };
   return (
      (scheduleData?.length && isBannerVisible && (
         <Banner {...props} data-testid="current-assignments-banner">
            <Banner.Icon icon={<Info size="md" />} />
            <Banner.Content>
               <Banner.Title>
                  {I18n.t("views.company.workforce_planning.assignment.schedule_for_text")}{" "}
                  {personName}
               </Banner.Title>
               <Banner.Body>
                  {scheduleData?.slice(0, 2).map((assignment) => (
                     <li key={assignment.assignmentId}>{assignment.formattedAssignmentDetails}</li>
                  ))}
                  {assignmentData.map((assignment) => (
                     <li key={assignment.assignmentId}>{assignment.formattedAssignmentDetails}</li>
                  ))}
               </Banner.Body>
            </Banner.Content>
            <Banner.Action>
               <Button onClick={() => handleButtonClick(scheduleData?.slice(2), isExpanded)}>
                  {isExpanded
                     ? I18n.t("views.company.workforce_planning.show_less")
                     : I18n.t("views.company.workforce_planning.show_more")}
               </Button>
            </Banner.Action>
            <Banner.Dismiss
               onClick={toggleBannerVisibility}
               data-testid="current-assignments-banner-dismiss"
            />
         </Banner>
      )) ||
      null
   );
};

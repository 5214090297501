import "./calendar-pane.styl"
import template from "./calendar-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { DateUtils } from "@/lib/utils/date"

export class CalendarPane extends PopupPane
   constructor: (selectedDate) ->
      assertArgs(arguments, Function)
      assertOfType(selectedDate(), optional(Date))
      super(template())
      # Popup Properties
      @title = ko.observable()
      @rightActionBtn = ko.observable({text: 'Today', callback: @goToToday})
      # Date Properties
      @curMonthIndex = ko.observable()
      @curYear = ko.observable()
      @currentTitleString = ko.pureComputed =>
         # return "#{DateUtils.getMonth(@curMonthIndex())} #{@curYear()}"
         return "#{DateUtils.getMonth(@curMonthIndex())}"
      
      @earliestYear = ko.pureComputed =>
         return @curYear() - 2

      @previousYear = ko.pureComputed =>
         return @curYear() - 1

      @nextYear = ko.pureComputed =>
         return @curYear() + 1

      @lastYear = ko.pureComputed =>
         return @curYear() + 2

      @startingDayOfWeek = ko.computed( =>
         year = ko.unwrap(@curYear)
         monthIndex = ko.unwrap(@curMonthIndex)
         return null unless year? and monthIndex?
         date = new Date(year, monthIndex)
         return date.getDay()
         ).extend({notify:'always'})

      @hasSixthRow = ko.observable(false)
      @daysData = ko.observableArray()
      @hasDays = ko.pureComputed =>
         return if @daysData().length then true else false

      @startingDayOfWeek.subscribe (newVal) =>
         return unless newVal?
         daysData = []
         # Leading days for previous month
         unless newVal == 0
            prevMonthLastDay = new Date(@curYear(), @curMonthIndex(), 0).getDate()
            prevMonthDayToShow = prevMonthLastDay - (newVal - 1)
            i = 0
            while i < newVal
               daysData.push({name: prevMonthDayToShow, value: false})
               prevMonthDayToShow++
               i++
         # Days for current month
         curMonthLastDate = new Date(@curYear(), (@curMonthIndex() + 1), 0).getDate()
         for day in [1..curMonthLastDate]
            daysData.push({name: day, value: day})
         # Trailing days of next month
         curMonthLastDay = new Date(@curYear(), (@curMonthIndex() + 1), 0).getDay()
         unless curMonthLastDay == 6
            i = 1
            while curMonthLastDay < 6
               daysData.push({name: i, value: false})
               curMonthLastDay++
               i++
         @hasSixthRow(false) unless curMonthLastDate == 31 and newVal > 4
         @daysData(daysData)
         @hasSixthRow(true) if ((curMonthLastDate == 30 and newVal > 5) or (curMonthLastDate == 31 and newVal > 4))

      # Trigger inital load
      @curMonthIndex(new Date().getMonth())
      @curYear(new Date().getFullYear())

      @selectedDate = selectedDate
      if selectedDate()?
         @curYear(@selectedDate().getFullYear())
         @curMonthIndex(@selectedDate().getMonth())

   getDayContent: (day) =>
      return day.name unless day.value?
      selectedDate = @selectedDate()
      today = new Date()
      unless @curYear() == selectedDate?.getFullYear() or @curYear() == today.getFullYear()
         return day.name
      unless @curMonthIndex() == selectedDate?.getMonth() or @curMonthIndex() == today.getMonth()
         return day.name
      if day.value == selectedDate?.getDate() and day.value == today.getDate()
         return "<div class='calendar-pane__today'><div class='calendar-pane__selected-cirlce'>#{day.name}</div></div>"
      else if day.value == selectedDate?.getDate() and @curMonthIndex() == selectedDate?.getMonth()
         return "<div class='calendar-pane__selected-cirlce'>#{day.name}</div>"
      else if day.value == today.getDate() and @curMonthIndex() == today.getMonth()
         return "<div class='calendar-pane__today'>#{day.name}</div>"
      else
         return day.name

   pageBack: =>
      unless @curMonthIndex() == 0
         return @curMonthIndex(@curMonthIndex() - 1)
      @curYear(@curYear() - 1)
      return @curMonthIndex(11)

   pageForward: =>
      unless @curMonthIndex() == 11
         return @curMonthIndex(@curMonthIndex() + 1)
      @curYear(@curYear() + 1)
      return @curMonthIndex(0)

   setYear: (year) =>
      @curYear(ko.unwrap(year))

   goToToday: =>
      today = new Date(new Date().setHours(0,0,0,0))
      @curYear(today.getFullYear())
      @curMonthIndex(today.getMonth())
      @selectedDate(today)

   dateClicked: (date) =>
      return unless date
      @selectedDate(new Date(@curYear(), @curMonthIndex(), date))
      @dismissHandler()

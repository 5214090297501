import { CancelledError } from "@/lib/async/cancelled-error";
import type { MaybeObservable, PureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DisabledEditorParams } from "@/lib/components/editors/disabled-editor/disabled-editor";

/**
 * Error that can be thrown by `saveProvider` callbacks to indicate that the save
 * was cancelled. The editor should act as if the save button was not pressed.
 */
export class SaveCancelledError extends CancelledError {}

/**
 * @type TValue is the type for the intial data value of the compenent.
 * @type TSaveValue is the type for the saved value param of saveProvider. Defaults to TValue.
 */
export interface EditorComponentParams<TValue, TSaveValue = TValue> {
   /** The user-facing name of the editor. */
   title: MaybeObservable<string | null>;

   /** The initial value for the editor. */
   value: MaybeObservable<TValue> | PureComputed<TValue>;

   /** Takes the TSaveValue as input and implements executes save actions. */
   saveProvider: (value: TSaveValue) => Promise<void>;

   /** This callback is invoked "on close" of the editor (saved or not). */
   onClose?: (context: { hasSaved: boolean }) => void;
}

export interface EditorComponentFactory<
   Schema,
   TValue = unknown,
   TSaveValue = TValue,
   Params extends
      | EditorComponentParams<TValue, TSaveValue>
      | DisabledEditorParams = EditorComponentParams<TValue, TSaveValue>,
> {
   (records: Schema[]): ComponentArgs<Params> | null;
}

import ko from "knockout"

import "./gantt-export-modal-pane.styl"
import template from "./gantt-export-modal-pane.pug"
import { router } from "@/lib/router"
import { Icons } from "@/lib/managers/notification-manager"
import { Notification } from "@/lib/managers/notification-manager"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { GeneratedReportStore } from "@/stores/generated-report-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Notifications ###
import { GeneratedReportNotification } from "@/notifications/generated-report-notification"

import { ReportOptions } from "@/lib/reports/report-options"

Mode = {
   SINGLE: "single"
   MULTIPLE: "multiple"
}

State = {
   SELECTING: "selecting",
   DONE: "done",
}

ACTION_BUTTON_TEXT = "Export"

export class GanttExportModalPane extends ModalPane
   constructor: (entity, mode, payload = {}) ->
      assertArgs(arguments, String, String, Object)
      super("Export Gantt Report", ACTION_BUTTON_TEXT, template())
      @entity = entity
      @payload = payload
      @overflowable(true)
      @state = ko.observable(State.SELECTING)
      @showPageBreakOption = mode == Mode.MULTIPLE
      @hasPageBreakOnStart = ko.observable(false)
      @orientationOptions = ReportOptions.createOrientationOptions()
      @selectedOrientationOption = ko.observable(@orientationOptions[1])
      @pdfSizeOptions = ReportOptions.createPdfSizeOptions()
      @selectedPDFSizeOption = ko.observable(@pdfSizeOptions[0])

   actionBtnIntercept: () =>
      return if @state() != State.SELECTING
      orientation = @selectedOrientationOption().value()
      pageSize = @selectedPDFSizeOption().value()

      reportOptionsType = if @entity == "people" then {
         path: "people-gantt-reports"
         type: "people_gantt"
         pageBreakKey: "has_page_break_for_person_start"
      } else {
         path: "projects-gantt-reports"
         type: "project_gantt"
         pageBreakKey: "has_page_break_for_project_start"
      };

      Object.assign(@payload, {
         report_type: reportOptionsType.type
         page_size: ({
            width: if orientation == "portrait" then pageSize.width else pageSize.height
            height: if orientation == "portrait" then  pageSize.height else pageSize.width
         })
      })
      @payload[reportOptionsType.pageBreakKey] = @hasPageBreakOnStart()

      @isActionEnabled(false)
      @setActionText("Exporting...")

      try
         @payload['group_id'] = authManager.selectedGroupId()
         result = await GeneratedReportStore.create(reportOptionsType.path, @payload).payload
         report = result.data;
         report.name = ko.observable(report.name)
         @setActionText("")
         @state(State.DONE)
         return notificationManager.show(new GeneratedReportNotification(report))
      catch err
         console.log err
         @isActionEnabled(true)
         @setActionText(ACTION_BUTTON_TEXT)
         @state(State.SELECTING)
         return notificationManager.show(
            new Notification({
               icon: Icons.WARNING,
               text: "Unable to export report. Please try again or contact support.",
            })
         )

   getPageBreakText: () =>
      text = if @entity == "people" then "people" else "projects"
      return "Page break between #{text}"

   navigateToReports: () =>
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/reports")
      modalManager.modalFinished()

GanttExportModalPane.Mode = Mode
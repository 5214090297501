import React, { useEffect, useState, useCallback } from "react";
import { Box, Card, Spinner, Typography, useI18nContext } from "@procore/core-react";
import type { ListProjectActivitiesProps, ProjectDetailsActivitiesProps } from "../types";
import {
   ActivityCategory,
   IntegratedEntity,
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { dateSortOptions, projectSpecificDateSortOptions } from "@/react/shared/constants";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { ActivityStore } from "@/stores/activity-store.core";
import "../../style.css";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { ProjectActivitiesInfoCard } from "./project-activities-info-card";
import { useFindActivityPaginatedQuery } from "@/react/components/common/queries/queries";
import ActivityList from "../../activities/activity-list";
import EmptyActivities from "../../activities/activity-empty";
import type { ActivitiesResponseProps, ActivitiesSpecificOptionType } from "../../types";
import { initialLimitForActivity } from "../../constants";
import { formatCurrency, formatDate, prepareActivitiesPayload } from "../../helper";

const ListActivities: React.FC<ListProjectActivitiesProps> = ({
   activitiesState,
   setActivitiesState,
   projectId,
   entityType,
   includedCategories,
   nextStartingAfter,
   setNextStartingAfter,
   limitActivities,
   projectSpecificOption,
   setLimitActivities,
}) => {
   const I18n = useI18nContext();
   const [sortedActivities, setSortedActivities] = useState(activitiesState);
   const [sortOption, setSortOption] = useState(dateSortOptions[1]);
   const [sortProjectSpecificOption, setSortProjectSpecificOption] =
      useState<ActivitiesSpecificOptionType>(projectSpecificOption[0]);
   useEffect(() => {
      setSortedActivities(activitiesState);
   }, [activitiesState]);

   useEffect(() => {
      loadSpecificActivities();
   }, [sortProjectSpecificOption]);

   useEffect(() => {
      const sorted = [...activitiesState].sort((a, b) => {
         const dateA = new Date(a.created_at).getTime();
         const dateB = new Date(b.created_at).getTime();
         return sortOption.value === Order.ASCENDING ? dateA - dateB : dateB - dateA;
      });
      setSortedActivities(sorted);
   }, [sortOption, activitiesState]);

   const loadMoreActivities = useCallback(async () => {
      const payload = prepareActivitiesPayload(
         projectId,
         entityType,
         sortProjectSpecificOption.id === "0"
            ? includedCategories
            : [sortProjectSpecificOption.value],
         initialLimitForActivity,
         nextStartingAfter,
      );
      const loadMoreActivitiesData = await ActivityStore.findActivityPaginated(payload, {}).payload;
      if (loadMoreActivitiesData?.data && loadMoreActivitiesData?.data.length > 0) {
         setNextStartingAfter(loadMoreActivitiesData?.pagination?.next_starting_after || "");
         setActivitiesState((prevState) => [...prevState, ...loadMoreActivitiesData?.data]);
      }
   }, [
      projectId,
      entityType,
      sortProjectSpecificOption,
      includedCategories,
      nextStartingAfter,
      setActivitiesState,
      setNextStartingAfter,
   ]);

   const loadSpecificActivities = useCallback(async () => {
      const payload = prepareActivitiesPayload(
         projectId,
         entityType,
         sortProjectSpecificOption.id === "0"
            ? includedCategories
            : [sortProjectSpecificOption.value],
         initialLimitForActivity,
      );
      const loadSpecificActivitiesData = await ActivityStore.findActivityPaginated(payload, {})
         .payload;
      setNextStartingAfter(loadSpecificActivitiesData?.pagination?.next_starting_after || "");
      setActivitiesState(loadSpecificActivitiesData?.data);
      setLimitActivities(loadSpecificActivitiesData?.pagination?.total_possible);
   }, [
      projectId,
      entityType,
      sortProjectSpecificOption,
      includedCategories,
      setActivitiesState,
      setNextStartingAfter,
      setLimitActivities,
   ]);

   const renderActivityContent = (activity: ActivitiesResponseProps) => {
      switch (activity.category) {
         case projectSpecificDateSortOptions[1].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name} `}</span>
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.to",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.attachment_from",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[2].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name} `}</span>
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.to",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.a",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[3].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added_request_status",
                                { status: `${activity?.meta?.status_name}` },
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.deleted_request",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${formatDate(
                        activity?.meta?.start_day,
                     )} `}</span>
                     <span className="light-text">{`${I18n.t(
                        "views.company.workforce_planning.project_details_activities.activity_action.to",
                     )} `}</span>
                     <span className="dark-highlighted-text">
                        {formatDate(activity?.meta?.end_day)}
                     </span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[5].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added_note",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed_note",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[6].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${
                        typeof activity?.subject_3_name === "string"
                           ? activity.subject_3_name
                           : activity.subject_3_name?.first
                     } ${
                        typeof activity?.subject_3_name === "string"
                           ? activity.subject_3_name
                           : activity.subject_3_name?.last
                     } `}</span>
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.to_role",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.from_role",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[7].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name} `}</span>
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.to",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.from",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_1_name}`}</span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[8].value:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added_wage_override",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed_wage_override",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${formatCurrency(
                        activity?.meta?.rate,
                        I18n,
                     )} `}</span>
                     <span>
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.created_wage_override",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.from_the",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name} `}</span>
                     <span className="light-text">
                        {I18n.t(
                           "views.company.workforce_planning.project_details_activities.activity_action.position",
                        )}
                     </span>
                  </Typography>
               </Box>
            );
         case projectSpecificDateSortOptions[4].value:
            return <ProjectActivitiesInfoCard activity={activity} />;
         case ActivityCategory.PROJECT:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added_project",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_1_name} `}</span>
                  </Typography>
               </Box>
            );
         case ActivityCategory.PROJECT_TAGS_ATTACHMENTS:
            return (
               <Box display="flex" style={{ marginTop: "10px" }}>
                  <Typography intent="body" color="gray50">
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.added_attachment",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.removed_attachment",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_3_name} `}</span>
                     <span className="light-text">
                        {activity?.action_type === "create"
                           ? `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.to_tag",
                             )} `
                           : `${I18n.t(
                                "views.company.workforce_planning.project_details_activities.activity_action.from_tag",
                             )} `}
                     </span>
                     <span className="dark-highlighted-text">{`${activity?.subject_2_name}`}</span>
                  </Typography>
               </Box>
            );
         default:
            return null;
      }
   };

   return (
      <ActivityList
         title="views.company.workforce_planning.project_details_activities.title"
         subtext="views.company.workforce_planning.project_details_activities.activities_sub_text"
         sortActivitiesOptions={projectSpecificOption}
         sortActivitiesSpecificOption={sortProjectSpecificOption}
         onActivitiesSortChange={setSortProjectSpecificOption}
         sortOptions={dateSortOptions}
         sortOption={sortOption}
         onSortChange={setSortOption}
         activities={sortedActivities}
         renderActivityContent={renderActivityContent}
         loadMoreHandler={loadMoreActivities}
         limit={limitActivities}
         loadMoreLabel="views.company.workforce_planning.project_details_activities.lode_more"
      />
   );
};

export const ProjectDetailsActivities: React.FC<ProjectDetailsActivitiesProps> = ({
   projectId,
}) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const [activitiesState, setActivitiesState] = useState<ActivitiesResponseProps[]>([]);
   const [nextStartingAfter, setNextStartingAfter] = useState<string>("");
   const [limitActivities, setLimitActivities] = useState<number>(initialLimitForActivity);
   const [projectSpecificOption, setProjectSpecificOption] = useState<
      ActivitiesSpecificOptionType[]
   >(projectSpecificDateSortOptions);

   const includedCategories: string[] = [];

   const entityType = IntegratedEntity.PROJECTS;

   useEffect(() => {
      const actionMapping = [
         {
            id: "1",
            action: AuthAction.VIEW_PROJECT_ATTACHMENTS,
            value: projectSpecificDateSortOptions[1].value,
         },
         {
            id: "5",
            action: AuthAction.VIEW_PROJECT_NOTES,
            value: projectSpecificDateSortOptions[5].value,
         },
         {
            id: "6",
            action: AuthAction.VIEW_PROJECT_ROLES,
            value: projectSpecificDateSortOptions[6].value,
         },
         {
            id: "7",
            action: AuthAction.VIEW_PROJECT_TAGS,
            value: projectSpecificDateSortOptions[7].value,
         },
         {
            id: "8",
            action: AuthAction.VIEW_PROJECT_WAGE_OVERRIDES,
            value: projectSpecificDateSortOptions[8].value,
         },
      ];

      actionMapping.forEach(({ id, action, value }) => {
         if (checkAuthAction(action)) {
            includedCategories.push(value);
         } else {
            setProjectSpecificOption((prevItems) =>
               prevItems.map((item) => (item.id === id ? { ...item, show: false } : item)),
            );
         }
      });

      includedCategories.push(projectSpecificDateSortOptions[2].value);
      includedCategories.push(projectSpecificDateSortOptions[3].value);
      includedCategories.push(projectSpecificDateSortOptions[4].value);
   }, [checkAuthAction]);

   const { data: activitiesData, isLoading: activitiesLoading } = useFindActivityPaginatedQuery(
      projectId,
      entityType,
      includedCategories,
      initialLimitForActivity,
   );

   useEffect(() => {
      if (activitiesData?.data && activitiesData?.data.length > 0) {
         setNextStartingAfter(activitiesData?.pagination?.next_starting_after || "");
         setActivitiesState(activitiesData?.data);
         setLimitActivities(activitiesData?.pagination?.total_possible);
      }
   }, [activitiesData]);

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={activitiesLoading}>
            {activitiesData && activitiesData.data && activitiesData?.data.length === 0 && (
               <EmptyActivities
                  title={I18n.t(
                     "views.company.workforce_planning.project_details_activities.title",
                  )}
                  emptyTitle={I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_title",
                  )}
                  emptyDescription={I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_description",
                  )}
               />
            )}
            {activitiesData && activitiesData.data && activitiesData?.data.length > 0 && (
               <ListActivities
                  activitiesState={activitiesState}
                  setActivitiesState={setActivitiesState}
                  projectId={projectId}
                  entityType={entityType}
                  includedCategories={includedCategories}
                  nextStartingAfter={nextStartingAfter}
                  setNextStartingAfter={setNextStartingAfter}
                  limitActivities={limitActivities}
                  projectSpecificOption={projectSpecificOption}
                  setLimitActivities={setLimitActivities}
               />
            )}
         </Spinner>
      </Card>
   );
};

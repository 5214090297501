import { Model } from "@/models/model"

export class CannedMessage extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.owner_id, optional(String))
         assertOfType(data.type, String)
         assertOfType(data.content, String)
         assertOfType(data.include_signature, Boolean)
         assertOfType(data.is_private, Boolean)
         assertOfType(data.is_group, Boolean)
         assertOfType(data.dynamic_tokens, arrayOf(Object))
         assertOfType(data.subject, nullable(String))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id
      @ownerId = data.owner_id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @type = ko.observable(data.type)
      @subject = ko.observable(data.subject)
      @content = ko.observable(data.content)
      @includeSignature = ko.observable(data.include_signature)
      @isPrivate = ko.observable(data.is_private)
      @isGroup = ko.observable(data.is_group)
      if data.dynamic_tokens?
         @dynamicTokens = ko.observableArray data.dynamic_tokens.map (token) ->
            return new DynamicToken(token, disableTypes)
      else
         @dynamicTokens = ko.observableArray([])


export class DynamicToken extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
      # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.subject_1_key, String)
         assertOfType(data.subject_1_type, String)
         assertOfType(data.subject_1_id, nullable(String))
         assertOfType(data.subject_2_type, optional(String))
         assertOfType(data.subject_2_key, optional(String))
         assertOfType(data.subject_3_type, optional(String))
         assertOfType(data.subject_3_key, optional(String))

      @id = data.id
      @name = data.name
      @value = data.value
      @subject1Key = data.subject_1_key
      @subject1Type = data.subject_1_type
      @subject1Id = data.subject_1_id
      @subject2Key = data.subject_2_key
      @subject2Type = data.subject_2_type
      @subject3Key = data.subject_3_key
      @subject3Type = data.subject_3_type


CannedMessage.Type = {
   ASSIGNMENT_NEW: 'assignment-new'
   ASSIGNMENT_EDIT: 'assignment-edit'
   ASSIGNMENT_TRANSFER: 'assignment-transfer'
   ASSIGNMENT_DELETE: 'assignment-delete'
   ASSIGNMENT_REMINDER: 'assignment-reminder'
}

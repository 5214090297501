import "./color-selector-pane.styl"
import template from "./color-selector-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import ko from 'knockout'
import { Color } from "@/lib/utils/color"

export class ColorSelectorPane extends PopupPane
   constructor: (selectedColor) ->
      assertArgs(arguments, Function)
      super(template())

      # Popup Properties
      @title = ko.observable()
      
      # Data
      @selectedColor = selectedColor
      # To decouple selections from saves. 
      @workingColor = ko.observable(@selectedColor())
      @selectedColor.subscribe (newVal) =>
         @workingColor(newVal)
      
   chooseColor: =>
      if @workingColor()? and Color.isHexColor(@workingColor())
         @selectedColor("#{@workingColor()}")
      return @dismissHandler()

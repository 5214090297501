import "./phone-editor.styl";
import type { MaybeSubscribable, Subscription } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./phone-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";
import { formatName } from "@/lib/utils/preferences";
import { TwilioStoreCore } from "@/stores/twilio-store.core";

export type PhoneEditorValidator = (val: string | null) => {
   status: boolean;
   message: string | null;
};

export interface PhoneEditorParams extends EditorComponentParams<string | null> {
   isRequired?: MaybeSubscribable<boolean>;
   width: MaybeSubscribable<number>;
   validators?: PhoneEditorValidator[];
}
const INVALID_PHONE_NUMBER_TEXT = "Invalid Phone Number.";

type Conflict = {
   value: string;
   name: string;
};

export class PhoneEditor extends AbstractFieldEditor<string | null> {
   readonly validators: PhoneEditorValidator[] = [];
   readonly width = observable(0);
   readonly conflict = observable<Conflict | null>(null);
   readonly invalidNumber = observable<string | null>(null);
   readonly formattedValue = observable<string | null>(null);

   readonly subscriptions: Subscription[] = [];

   validate(value: string | null): ValidatedValue<string | null> {
      const { isRequired } = this.params;
      const isEmpty = value == null;
      // Only show the first wrong constraint so as not to overwhelm UI.
      const getFirstConstraint = () =>
         this.validators.map((v) => v(value)).filter((v) => !v.status)[0];

      if (isEmpty && unwrap(isRequired)) {
         return { valid: false, error: `${this.title} is required.` };
      }
      const firstConstraint = getFirstConstraint();
      if (firstConstraint) {
         return { valid: false, error: firstConstraint.message };
      }

      const conflict = this.conflict();
      if (conflict && conflict.value == value) {
         return {
            valid: false,
            error: `This number is already associated with a person, ${conflict.name}. Please try again.`,
         };
      }

      if (value && this.invalidNumber() == value) {
         return {
            valid: false,
            error: INVALID_PHONE_NUMBER_TEXT,
         };
      }

      return { valid: true, value: this.formattedValue() };
   }

   async onSave(): Promise<void> {
      const phoneNumber = this.value();
      // Allow null phone numbers
      if (!phoneNumber) {
         // force null value;
         this.value(null);
         this.formattedValue(null);
         return super.onSave();
      }

      const response = await TwilioStoreCore.validateNumber(phoneNumber).payload;
      const { data } = response;
      if (data.conflicts && data.conflicts.length > 0) {
         const [
            { name } = {
               name: { first: "", last: "" },
            },
         ] = data.conflicts;
         return this.conflict({
            name: formatName(name),
            value: phoneNumber,
         });
      }

      if (!data.formatted_number) {
         return this.invalidNumber(this.value());
      }

      this.formattedValue(data.formatted_number);
      return super.onSave();
   }

   constructor(private readonly params: PhoneEditorParams) {
      super(params, observable(unwrap(params.value)));
      if (this.params.width) {
         this.width(unwrap(this.params.width));
      }

      this.validators.push((phone) => {
         return phone === null ||
            phone.match(/^\d{3}-?\d{2,3}$/) ||
            phone.match(/^(\+[1-9])?\d{1,14}$/)
            ? { status: true, message: null }
            : { status: false, message: INVALID_PHONE_NUMBER_TEXT };
      });
      if (this.params.validators) {
         this.validators.push(...this.params.validators);
      }
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   static factory<T>(
      provider: (records: T[]) => PhoneEditorParams,
   ): (records: T[]) => ComponentArgs<PhoneEditorParams> {
      return (records) => ({
         name: "phone-editor",
         params: provider(records),
      });
   }
}

ko.components.register("phone-editor", {
   viewModel: PhoneEditor,
   template: template(),
   synchronous: true,
});

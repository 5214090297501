import type { FormSelectOption } from "@/react/prop-types";
import type {
   CommonFormValuesForRequestAndAssignment,
   CommonPayloadForRequestAndAssignment,
} from "../types";
import type { BannerVariant } from "@procore/core-react/dist/Banner/Banner.types";

export type OverTimeRates = {
   sunday: number;
   monday: number;
   tuesday: number;
   wednesday: number;
   thursday: number;
   friday: number;
   saturday: number;
};

export type PaySplitRates = {
   overtime: number;
   straight: number;
   unpaid: number;
};

export type AssignmentFormValues = CommonFormValuesForRequestAndAssignment & {
   equipment: FormSelectOption<string> | null;
   overtime: boolean;
   overtime_rates: OverTimeRates;
   pay_split: PaySplitRates;
};

export type AssignmentWithResource = CommonPayloadForRequestAndAssignment & {
   resource_id: string;
};

export type AssignmentWithEquipment = CommonPayloadForRequestAndAssignment & {
   equipment_id: string;
};

export enum AssignmentFields {
   ALLOCATION = "assignment_by_allocation",
   TIME = "assignment_by_time",
}

export type AssignmentFormattedTypes = { assignmentId: string; formattedAssignmentDetails: string };

export type CurrentAssignmentsBannerProps = {
   bodyContent?: number;
   icon?: string;
   titleContent?: number;
   variant: BannerVariant;
   expandableContentMaxHeight?: number;
   expandableContentLines?: number;
   personId: string;
   personName: string;
   showBanner: boolean;
};

import { DateUtils } from "@/lib/utils/date"
import { mutateForTimezone } from "@/lib/utils/date-2"
import { Format } from "@/lib/utils/format"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"

import { PermissionLevel } from "@/models/permission-level"
import moment from "moment"

ActivityContentFormatter = {}
ActivityContentFormatter.FormatContent = (activity) ->
   assertArgs(arguments, Object)
   for key, val of ActivityContentFormatter.Messages
      if activity.category() == key
         for actionType, message of val
            if actionType == activity.actionType()
               return message(activity)
   return null

ActivityContentFormatter.FormatMetaContent = (activity) ->
   assertArgs(arguments, Object)
   createdDate = new Date(activity.createdAt())
   if activity.category() == "project_tags_attachments" or activity.category() == "project_attachments"
      return "#{activity.authorName()} - File Size: #{Format.kilobytes(activity.details().bytes / 1000)} - #{createdDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})} #{DateUtils.getMonthAbbreviation(createdDate)} #{createdDate.getDate()}, #{createdDate.getFullYear()}"
   else
      return "#{activity.authorName()} - #{createdDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})} #{DateUtils.getMonthAbbreviation(createdDate)} #{createdDate.getDate()}, #{createdDate.getFullYear()}"

ActivityContentFormatter.Messages = {
   person: {
      create: (data) -> "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> Created"
      delete: (data) -> "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> Deleted"
   }
   person_user_lock: {
      create: (data) -> "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> Was unlocked and has regained access"
      delete: (data) -> "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> Was locked out for too many failed login attempts."
   }
   person_notes: {
      create: (data) -> "Added a Note to <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
      update: () -> ""
      delete: (data) -> "Removed a Note from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
   }
   person_attachments: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject2Name()}</span> to " +
         "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject2Name()}</span> attachment " +
         "from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
   }
   person_tags: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject2Name()}</span> to " +
         "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject2Name()}</span> " +
         "from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
      update: (data) ->
         return switch data.meta().property_name
            when "expr_date"
               return "Changed <span class='lc-bold-text'>#{data.subject2Name()}</span> expiration date from " +
               "<span class='lc-bold-text'>#{DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())}</span> to " +
               "<span class='lc-bold-text'>#{DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())}</span>"
   }
   person_tags_attachments: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject3Name()}</span> to the " +
         "<span class='lc-bold-text'>#{data.subject2Name()}</span> Tag"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject3Name()}</span> attachment " +
         "from the <span class='lc-bold-text'>#{data.subject2Name()}</span> Tag"
   }
   person_time_off: {
      create: (data) ->
         text = "Added "
         text += "repeating " if data.details().repeat != "never"
         text += "time off for #{Format.capitalize(data.details().reason)} for <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
         return text
      update: (data) ->
         text = "Updated "
         text += "repeating " if data.details().repeat != "never"
         text += "time off for #{Format.capitalize(data.details().reason)} for <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
         return text
      delete: (data) ->
         text = "Removed "
         text += "repeating " if data.details().repeat != "never"
         text += "time off for #{Format.capitalize(data.details().reason)} scheduled for <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
         return text
   }
   person_assignments: {
      create: (data) ->
         formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
         formattedEndDate = if data.meta().end_day? then DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat()) else "TBD"
         closing = if data.meta().transfer then "project via an Assignment Transfer" else "project"
         
         projectName = if data.job_number? then "#{data.subject2Name()} - #{data.job_number}" else data.subject2Name()
         hasGroupAccess = authManager.isAdmin() or data.projectGroupIds().some((groupId) =>
            authManager.getContextAccessibleGroupIds().has(groupId)
         )

         statusName = if data.meta().status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""

         content = "Assigned <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> #{statusName}" +
         "from <span class='lc-bold-text'>#{formattedStartDate}</span> to " +
         "<span class='lc-bold-text'>#{formattedEndDate}</span>"

         if data.meta().percent_allocated?
            percentAllocated = data.meta().percent_allocated
            content += " with a time allocation of <span class='lc-bold-text'>#{percentAllocated}%</span>"
         else
            startTime = defaultStore.formatTime(data.meta().start_time)
            endTime = defaultStore.formatTime(data.meta().end_time)
            content += " with a start time of " +
            "<span class='lc-bold-text'>#{startTime}</span> and end time of " +
            "<span class='lc-bold-text'>#{endTime}</span>"

         if authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT) and hasGroupAccess
            content += " to <a class='lc-bold-text activity-record-link activity-record-link--projects' href='/groups/#{authManager.selectedGroupId()}/projects/#{data.subject2Id()}'>#{projectName}</a> #{closing}"
         else
            content += " to <span class='lc-bold-text'>#{projectName}</span> #{closing}"
         return content

      update: (data) ->
         if data.meta().new_format
            formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
            formattedEndDate = if data.meta().end_day? then DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat()) else "TBD"
            
            closing = if data.meta().transfer then "project via an Assignment Transfer" else "project"

            projectName = if data.job_number? then "#{data.subject2Name()} - #{data.job_number}" else data.subject2Name()
            hasGroupAccess = authManager.isAdmin() or data.projectGroupIds().some((groupId) =>
               authManager.getContextAccessibleGroupIds().has(groupId)
               )

            statusName = if data.meta().status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""

            content = "Updated <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}'s</span> assignment " +
            statusName +
            "from <span class='lc-bold-text'>#{formattedStartDate}</span> to " +
            "<span class='lc-bold-text'>#{formattedEndDate}</span>"

            if data.meta().percent_allocated?
               percentAllocated = data.meta().percent_allocated
               content += " with a time allocation of <span class='lc-bold-text'>#{percentAllocated}%</span>"
            else
               startTime = defaultStore.formatTime(data.meta().start_time)
               endTime = defaultStore.formatTime(data.meta().end_time)
               content += " with a start time of " +
               "<span class='lc-bold-text'>#{startTime}</span> and end time of " +
               "<span class='lc-bold-text'>#{endTime}</span>"

            if authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT) and hasGroupAccess
               content += " on the <a class='lc-bold-text activity-record-link activity-record-link--projects' href='/groups/#{authManager.selectedGroupId()}/projects/#{data.subject2Id()}'>#{projectName}</a> #{closing}"
            else
               content += " on the <span class='lc-bold-text'>#{projectName}</span> #{closing}"
            return content
         else
            formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
            formattedEndDate = if data.meta().end_day? then DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat()) else "TBD"
            keyDispayName = switch data.meta().key
               when "cost_code_id" then "the Category"
               when "label_id" then "the Subcategory"
               when "start_day" then "the Start Date"
               when "end_day" then "the End Date"
               when "tbd_end_date" then "the End Date"
               when "start_time" then "the Start Time"
               when "end_time" then "the End Time"
               when "percent_allocated" then "Allocation Percentage"
               when "saturdays_on" then "Working Saturdays"
               when "sundays_on" then "Working Sundays"
               when "status_id" then "the Assignment Statuses"

            ### eslint-disable no-unreachable ###
            oldVal = switch data.meta().key
               when "cost_code_id" then null
               when "label_id" then null
               when "start_day" then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
               when "end_day"
                  if data.meta().old_val? then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat()) else "TBD"
               when "tbd_end_date"
                  if data.meta().old_val? then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat()) else "TBD"
               when "start_time" then defaultStore.formatTime(data.meta().old_val)
               when "end_time" then defaultStore.formatTime(data.meta().old_val)
               when "percent_allocated"
                  if data.meta().old_val? then "#{data.meta().old_val}%" else "0%"
               when "saturdays_on"
                  if data.meta().old_val then "True" else "False"
               when "sundays_on"
                  if data.meta().old_val then "True" else "False"

            newVal = switch data.meta().key
               when "cost_code_id" then null
               when "label_id" then null
               when "start_day" then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
               when "end_day"
                  if data.meta().new_val? then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat()) else "TBD"
               when "tbd_end_date"
                  if data.meta().new_val? then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat()) else "TBD"
               when "start_time" then defaultStore.formatTime(data.meta().new_val)
               when "end_time" then defaultStore.formatTime(data.meta().new_val)
               when "percent_allocated"
                  if data.meta().new_val? then "#{data.meta().new_val}%" else "0%"
               when "saturdays_on"
                  if data.meta().new_val then "True" else "False"
               when "sundays_on"
                  if data.meta().new_val then "True" else "False"
            ### eslint-enable no-unreachable ###

            if (data.meta().key == "cost_code_id") or (data.meta().key == "label_id") or (data.meta().key == "status_id")
               projectName = if data.job_number? then "#{data.subject2Name()} - #{data.job_number}" else data.subject2Name()
               hasGroupAccess = authManager.isAdmin() or data.projectGroupIds().some((groupId) =>
                  authManager.getContextAccessibleGroupIds().has(groupId)
               )

               if authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT) and hasGroupAccess
                  return "Changed #{keyDispayName} for " +
                     "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s " +
                     "#{formattedStartDate} - #{formattedEndDate} assignment on the <a class='lc-bold-text activity-record-link activity-record-link--projects' href='/groups/#{authManager.selectedGroupId()}/projects/#{data.subject2Id()}'>#{projectName}</a> project"
               else
                  return "Changed #{keyDispayName} for " +
                     "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s " +
                     "#{formattedStartDate} - #{formattedEndDate} assignment on the <span class='lc-bold-text'>#{projectName}</span> project"
            else
               closing = if data.meta().transfer then "project via an Assignment Transfer" else "project"
               projectName = if data.job_number? then "#{data.subject2Name()} - #{data.job_number}" else data.subject2Name()
               hasGroupAccess = authManager.isAdmin() or data.projectGroupIds().some((groupId) =>
                  authManager.getContextAccessibleGroupIds().has(groupId)
               )

               if authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT) and hasGroupAccess
                  return "Changed #{keyDispayName} from <span class='lc-bold-text'>#{oldVal}</span> " +
                     "to <span class='lc-bold-text'>#{newVal}</span> for " +
                     "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s " +
                     "#{formattedStartDate} - #{formattedEndDate} assignment on the <a class='lc-bold-text activity-record-link activity-record-link--projects' href='/groups/#{authManager.selectedGroupId()}/projects/#{data.subject2Id()}'>#{projectName}</a> #{closing}"
               else
                  return "Changed #{keyDispayName} from <span class='lc-bold-text'>#{oldVal}</span> " +
                     "to <span class='lc-bold-text'>#{newVal}</span> for " +
                     "<span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s " +
                     "#{formattedStartDate} - #{formattedEndDate} assignment on the <span class='lc-bold-text'>#{projectName}</span> #{closing}"

      delete: (data) ->
         formattedStartDate = DateUtils.formatDetachedDay(data.meta().start_day, defaultStore.getDateFormat())
         formattedEndDate = if data.meta().end_day? then DateUtils.formatDetachedDay(data.meta().end_day, defaultStore.getDateFormat()) else "TBD"
         closing = if data.meta().transfer then "project via an Assignment Transfer" else "project"
         statusName = if data.meta().status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""
         content = "Removed <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}'s</span> " +
         "assignment #{statusName} from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"

         if data.meta().percent_allocated?
            percentAllocated = data.meta().percent_allocated
            content += " with a time allocation of <span class='lc-bold-text'>#{percentAllocated}%</span>"
         else
            startTime = defaultStore.formatTime(data.meta().start_time)
            endTime = defaultStore.formatTime(data.meta().end_time)
            content += " with a start time of " +
            "<span class='lc-bold-text'>#{startTime}</span> and end time of " +
            "<span class='lc-bold-text'>#{endTime}</span>"
         
         projectName = if data.job_number? then "#{data.subject2Name()} - #{data.job_number}" else data.subject2Name()
         hasGroupAccess = authManager.isAdmin() or data.projectGroupIds().some((groupId) =>
            authManager.getContextAccessibleGroupIds().has(groupId)
         )
         
         if authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT) and hasGroupAccess
            content += " on the <a class='lc-bold-text activity-record-link activity-record-link--projects' href='/groups/#{authManager.selectedGroupId()}/projects/#{data.subject2Id()}'>#{projectName}</a> #{closing}"
         else
            content += " on the <span class='lc-bold-text'>#{projectName}</span> #{closing}"
   }
   person_info: {
      create: (data) ->
         return switch data.meta().property_name
            when "group_ids"
               "Added <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> to the <span class='lc-bold-text'>#{data.subject2Name()}</span> Group"
            when "position_id"
               return "Added a Job Title of <span class='lc-bold-text'>#{data.subject2Name()}</span> to <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
            when "permission_level_id"
               return "Added a Permission Level of <span class='lc-bold-text'>#{data.subject2Name()}</span> to <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
            when "notification_profile_id"
               return "Added the <span class='lc-bold-text'>#{data.subject2Name()}</span> Notification Profile to <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"

      update: (data) ->
         ### eslint-disable no-unreachable ###
         return switch data.meta().property_name
            when "name"
               if (data.meta().new_val.first != data.meta().old_val.first) and (data.meta().new_val.last != data.meta().old_val.last)
                  return "Changed Name from  <span class='lc-bold-text'>#{data.meta().old_val.first} #{data.meta().old_val.last}</span> " +
                  "to  <span class='lc-bold-text'>#{data.meta().new_val.first} #{data.meta().new_val.last}</span>"
               else if data.meta().new_val.first != data.meta().old_val.first
                  return "Changed First Name from  <span class='lc-bold-text'>#{data.meta().old_val.first}</span> " +
                  "to  <span class='lc-bold-text'>#{data.meta().new_val.first}</span>"
               else if (data.meta().new_val.last != data.meta().old_val.last)
                  return "Changed Last Name from  <span class='lc-bold-text'>#{data.meta().old_val.last}</span> " +
                  "to  <span class='lc-bold-text'>#{data.meta().new_val.last}</span>"
            when "position_id"
               return "Changed <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s Job Title to <span class='lc-bold-text'>#{data.subject2Name()}</span>"
            when "permission_level_id"
               return "Changed <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s Permission Level to <span class='lc-bold-text'>#{data.subject2Name()}</span>"
            when "notification_profile_id"
               return "Changed <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>'s Notification Profile to <span class='lc-bold-text'>#{data.subject2Name()}</span>"
            when "profile_pic_url"
               if !data.meta().old_val?
                  return "Added profile picture."
               else if !data.meta().new_val?
                  return "Removed profile picture."
               else
                  return "Changed profile picture."
            when "status"
               oldVal = data.meta().old_val.charAt(0).toUpperCase() + data.meta().old_val.slice(1)
               newVal = data.meta().new_val.charAt(0).toUpperCase() + data.meta().new_val.slice(1)
               return "Changed Status from <span class='lc-bold-text'>#{oldVal}</span> to " +
               "<span class='lc-bold-text'>#{newVal}</span>"
            # Optional.
            when "phone"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Phone Number from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Phone Number: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Phone Number value."

            when "permission"
               if data.meta().new_val?
                  newValText = data.meta().new_val.replace("-", " ").split(" ")
                  newVal = newValText.map( (word) ->
                     return word[0].toUpperCase() + word[1..-1].toLowerCase()
                  ).join(" ")
               if data.meta().old_val?
                  oldValText = data.meta().old_val.replace("-", " ").split(" ")
                  oldVal = oldValText.map( (word) ->
                     return word[0].toUpperCase() + word[1..-1].toLowerCase()
                  ).join(" ")
                  return "Removed Permission Level: <span class='lc-bold-text'>#{oldVal}</span>" unless data.meta().new_val?
                  return "Changed Permission Level from <span class='lc-bold-text'>#{oldVal}</span> " +
                  "to <span class='lc-bold-text'>#{newVal}</span>"
               else
                  return "Added Permission Level: <span class='lc-bold-text'>#{newVal}</span>"

            when "is_user"
               personName = data.subject1Name().first + ' ' + data.subject1Name().last
               return "#{personName} is now a User" if data.meta().new_val
               return "#{personName} is no longer a User"

            when "is_assignable"
               personName = data.subject1Name().first + ' ' + data.subject1Name().last
               return "#{personName} became Assignable" if data.meta().new_val
               return "#{personName} is no longer Assignable"

            when "can_recieve_sms"
               return "Enabled receiving SMS Messages" if data.meta().new_val
               return "Disabled receiving SMS Messages"

            when "email"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Email from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Email: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Email value."

            when "can_recieve_email"
               return "Enabled receiving Email Messages" if data.meta().new_val
               return "Disabled receiving Email Messages"

            when "can_recieve_mobile"
               return "Enabled receiving Mobile Notifications" if data.meta().new_val
               return "Disabled receiving Mobile Notifications"

            when "employee_number"
               if data.meta().old_val?
                  return "Removed Employee ID: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Employee ID from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Employee ID: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "lat"
               if data.meta().old_val?
                  return "Removed Latitude: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Latitude from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Latitude: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "long"
               if data.meta().old_val?
                  return "Removed Longitude: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Longitude from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Longitude: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "address_1"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Address from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Address: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Address value. "

            when "address_2"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Address 2 from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Address 2: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Address 2 value."

            when "city_town"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed City/Town from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added City/Town: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed City/Town value."

            when "state_province"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed State/Province from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added State/Province: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed State/Province value."

            when "zipcode"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Postal Code from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Postal Code: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Postal Code value."

            when "country"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Country from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Country: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Country value."

            when "hourly_wage"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Hourly Wage from <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span> " +
                  "to <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
               else if data.meta().new_val?
                  return "Added Hourly Wage: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
               else
                  return "Removed Hourly Wage value."

            when "hired_date"
               if data.meta().old_val?
                  oldDate = new Date(data.meta().old_val)
                  mutateForTimezone(oldDate)
                  oldDate = DateUtils.getShortNumericDate(oldDate, defaultStore.getDateFormat())
               else
                  oldDate = null
               if data.meta().new_val?
                  newDate = new Date(data.meta().new_val)
                  mutateForTimezone(newDate)
                  newDate = DateUtils.getShortNumericDate(newDate, defaultStore.getDateFormat())
               else
                  newDate = null
               if oldDate? and newDate?
                  return "Changed Hire Date from <span class='lc-bold-text'>#{oldDate}</span> " +
                  "to <span class='lc-bold-text'>#{newDate}</span>"
               else if oldDate?
                  return "Removed Hire Date of <span class='lc-bold-text'>#{oldDate}</span>"
               else if newDate?
                  return "Added Hire Date of <span class='lc-bold-text'>#{newDate}</span>"

            when "dob"
               if data.meta().old_val?
                  oldDate = new Date(data.meta().old_val)
                  mutateForTimezone(oldDate)
                  oldDate = DateUtils.getShortNumericDate(oldDate, defaultStore.getDateFormat())
               else
                  oldDate = null
               if data.meta().new_val?
                  newDate = new Date(data.meta().new_val)
                  mutateForTimezone(newDate)
                  newDate = DateUtils.getShortNumericDate(newDate, defaultStore.getDateFormat())
               else
                  newDate = null
               if oldDate? and newDate?
                  return "Changed Birth Date from <span class='lc-bold-text'>#{oldDate}</span> " +
                  "to <span class='lc-bold-text'>#{newDate}</span>"
               else if oldDate?
                  return "Removed Birth Date of <span class='lc-bold-text'>#{oldDate}</span>"
               else if newDate?
                  return "Added Birth Date of <span class='lc-bold-text'>#{newDate}</span>"

            when "language"
               if data.meta().old_val? and data.meta().new_val?
                  formatedOld = Format.capitalize(data.meta().old_val)
                  formatedNew = Format.capitalize(data.meta().new_val)
                  return "Changed Language from <span class='lc-bold-text'>#{formatedOld}</span> " +
                  "to <span class='lc-bold-text'>#{formatedNew}</span>"
               else if data.meta().new_val?
                  formatedNew = Format.capitalize(data.meta().new_val)
                  return "Added Language: <span class='lc-bold-text'>#{formatedNew}</span>"
               else if data.meta().old_val?
                  formatedOld = Format.capitalize(data.meta().old_val)
                  return "Removed Language: <span class='lc-bold-text'>#{formatedOld}</span>"

            when "is_male"
               if data.meta().old_val?
                  oldGender = if data.meta().old_val == true then "Male" else "Female"
                  newGender = if data.meta().new_val == true then "Male" else "Female"
                  if data.meta().new_val == null
                     return "Removed Gender: <span class='lc-bold-text'>#{oldGender}</span>"
                  return "Changed Gender from <span class='lc-bold-text'>#{oldGender}</span> " +
                  "to <span class='lc-bold-text'>#{newGender}</span>"
               else
                  newGender = if data.meta().new_val == true then "Male" else "Female"
                  return "Added Gender: <span class='lc-bold-text'>#{newGender}</span>"

            when "emergency_contact_name"
               if data.meta().old_val?
                  return "Removed Emergency Contact Name: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Emergency Contact Name from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Emergency Contact Name: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "emergency_contact_email"
               if data.meta().old_val?
                  return "Removed Emergency Contact Email: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Emergency Contact Email from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Emergency Contact Email: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "emergency_contact_number"
               if data.meta().old_val?
                  return "Removed Emergency Contact Phone Number: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Emergency Contact Phone Number from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Emergency Contact Phone Number: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "emergency_contact_relation"
               if data.meta().old_val? and data.meta().new_val?
                  formatedOld = Format.capitalize(data.meta().old_val)
                  formatedNew = Format.capitalize(data.meta().new_val)
                  return "Changed Emergency Contact Relationship from <span class='lc-bold-text'>#{formatedOld}</span> " +
                  "to <span class='lc-bold-text'>#{formatedNew}</span>"
               else if data.meta().new_val?
                  formatedNew = Format.capitalize(data.meta().new_val)
                  return "Added Emergency Contact Relationship: <span class='lc-bold-text'>#{formatedNew}</span>"
               else if data.meta().old_val?
                  formatedOld = Format.capitalize(data.meta().old_val)
                  return "Removed Emergency Contact Relationship: <span class='lc-bold-text'>#{formatedOld}</span>"

            when "custom_fields"
               if data.meta().custom_field_type == 'multi-select'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{data.meta().old_val.join(', ')}</span> " + 
                     "to <span class='lc-bold-text'>#{data.meta().new_val.join(', ')}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().new_val.join(', ')}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().old_val.join(', ')}</span>"

               else if data.meta().custom_field_type == 'hex-color'
                  if data.meta().old_val? and data.meta().new_val?
                     ### eslint-disable no-useless-escape ###
                     oldColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().old_val}\"></div>"
                     newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                     return "Changed #{data.meta().custom_field_name} from #{oldColorString} to #{newColorString}"
                  else if data.meta().new_val?
                     newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                     return "Added #{data.meta().custom_field_name}: #{newColorString}"
                  else if data.meta().old_val?
                     oldColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().old_val}\"></div>"
                     ### eslint-enable no-useless-escape ###
                     return "Removed #{data.meta().custom_field_name}: #{oldColorString}"

               else if data.meta().custom_field_type == 'bool'
                  if data.meta().old_val? and data.meta().new_val?
                     formatedOld = Format.capitalize(String(data.meta().old_val))
                     formatedNew = Format.capitalize(String(data.meta().new_val))
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{formatedOld}</span> " + 
                     "to <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().new_val?
                     formatedNew = Format.capitalize(String(data.meta().new_val))
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().old_val?
                     formatedOld = Format.capitalize(String(data.meta().old_val))
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedOld}</span>"

               else if data.meta().custom_field_type == 'date'
                  if data.meta().old_val? and data.meta().new_val?
                     formatedOld = DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
                     formatedNew = DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{formatedOld}</span> " + 
                     "to <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().new_val?
                     formatedNew = DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().old_val?
                     formatedOld = DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedOld}</span>"

               else if data.meta().custom_field_type == 'currency'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span> " + 
                     "to <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span>"

               else if data.meta().custom_field_type == 'number'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{Format.formatNumber(data.meta().old_val)}</span> " + 
                     "to <span class='lc-bold-text'>#{Format.formatNumber(data.meta().new_val)}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatNumber(data.meta().new_val)}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatNumber(data.meta().old_val)}</span>"

               else
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{data.meta().old_val}</span> " + 
                     "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().old_val}</span>"
         ### eslint-enable no-unreachable ###

      delete: (data) ->
         return switch data.meta().property_name
            when "group_ids"
               return "Removed <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span> from the " +
               "<span class='lc-bold-text'>#{data.subject2Name()}</span> Group"
            when "position_id"
               return "Removed Job Title of <span class='lc-bold-text'>#{data.subject2Name()}</span> from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
            when "permission_level_id"
               return "Removed Permission Level of <span class='lc-bold-text'>#{data.subject2Name()}</span> from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
            when "notification_profile_id"
               return "Removed the <span class='lc-bold-text'>#{data.subject2Name()}</span> Notification Profile from <span class='lc-bold-text'>#{data.subject1Name().first} #{data.subject1Name().last}</span>"
   }

   project: {
      create: (data) -> "<span class='lc-bold-text'>#{data.subject1Name()}</span> Created"
      delete: (data) -> "<span class='lc-bold-text'>#{data.subject1Name()}</span> Deleted"
   }
   project_cost_codes: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject2Name()}</span> to " +
            "<span class='lc-bold-text'>#{data.subject1Name()}</span>"
      update: (data) ->
         return "Updated Category Name from <span class='lc-bold-text'>#{data.meta().old_val}</span> to " +
            "<span class='lc-bold-text'>#{data.meta().new_val}</span>"
      delete: (data) -> "Removed <span class='lc-bold-text'>#{data.subject2Name()}</span> Category " +
         "from <span class='lc-bold-text'>#{data.subject1Name()}</span>"
   }
   project_cost_codes_labels: {
      create: (data) ->
         return "Added Subcategory <span class='lc-bold-text'>#{data.subject3Name()}</span> to " +
            "<span class='lc-bold-text'>#{data.subject2Name()}</span>"
      update: (data) ->
         return "Updated Subcategory Name from <span class='lc-bold-text'>#{data.meta().old_val}</span> to " +
            "<span class='lc-bold-text'>#{data.meta().new_val}</span> inside the " +
            "<span class='lc-bold-text'>#{data.subject2Name()}</span> Category"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject3Name()}</span> Subcategory " +
            "from the <span class='lc-bold-text'>#{data.subject2Name()}</span> Category"
   }
   project_canned_assignment_message: {
      create: (data) ->
         messageType = switch data.meta().type
            when "assignment-new" then "Assignment Creations"
            when "assignment-edit" then "Assignment Updates"
            when "assignment-transfer" then "Assignment Transfers"
            when "assignment-delete" then "Assignment Deletions"
            when "assignment-reminder" then "Assignment Reminders"
         return "Added a new <span class='lc-bold-text'>Alert Template</span> for <span class='lc-bold-text'>#{messageType}</span>"
      update: (data) ->
         messageType = switch data.meta().type
            when "assignment-new" then "Assignment Creations"
            when "assignment-edit" then "Assignment Updates"
            when "assignment-transfer" then "Assignment Transfers"
            when "assignment-delete" then "Assignment Deletions"
            when "assignment-reminder" then "Assignment Reminders"
         return "Updated the <span class='lc-bold-text'>Alert Template</span> for <span class='lc-bold-text'>#{messageType}</span>"
      delete: (data) ->
         messageType = switch data.meta().type
            when "assignment-new" then "Assignment Creations"
            when "assignment-edit" then "Assignment Updates"
            when "assignment-transfer" then "Assignment Transfers"
            when "assignment-delete" then "Assignment Deletions"
            when "assignment-reminder" then "Assignment Reminders"
         return "Removed the <span class='lc-bold-text'>Alert Template</span> for <span class='lc-bold-text'>#{messageType}</span>"
   }
   project_notes: {
      create: (data) -> "Added a Note to <span class='lc-bold-text'>#{data.subject1Name()}</span>"
      update: () -> ""
      delete: (data) -> "Removed a Note from <span class='lc-bold-text'>#{data.subject1Name()}</span>"
   }
   project_placeholder: {
      create: (data) ->
         what = "Request"
         what = "<span class='lc-bold-text'>#{data.details().position.name}</span> Request" if data.details().position?
         startDay = data.meta()?.start_day or data.details().start_day
         formattedStartDate = DateUtils.formatDetachedDay(startDay, defaultStore.getDateFormat())
         end = "TBD"
         statusName = if data.meta().status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""
         if data.meta()?.end_day or data.details().end_day?
            endDay = data.meta()?.end_day or data.details().end_day
            end = DateUtils.formatDetachedDay(endDay, defaultStore.getDateFormat())

         return "Added a #{what} #{statusName} from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{end}</span>"
      update: (data) ->
         if data.meta().new_format
            what = "Request"
            what = "<span class='lc-bold-text'>#{data.details().position.name}</span> Request" if data.details().position?
            startDay = data.meta()?.start_day or data.details().start_day
            formattedStartDate = DateUtils.formatDetachedDay(startDay, defaultStore.getDateFormat())
            end = "TBD"
            statusName = if data.meta().status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""
            if data.meta()?.end_day or data.details().end_day?
               endDay = data.meta()?.end_day or data.details().end_day
               end = DateUtils.formatDetachedDay(endDay, defaultStore.getDateFormat())

            return "Updated a #{what} #{statusName} from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{end}</span>"
         else
            formattedStartDate = DateUtils.formatDetachedDay(data.meta()?.start_day, defaultStore.getDateFormat())
            formattedEndDate = if data.meta()?.end_day then DateUtils.formatDetachedDay(data.meta()?.end_day, defaultStore.getDateFormat()) else "TBD"
            what = "Request"
            what = "<span class='lc-bold-text'>#{data.details().position.name}</span> Request" if data.details().position?

            if data.meta().key == 'status_id'
               if data.subject3Name()?
                  return "Changed Status to <span class='lc-bold-text'>#{data.subject3Name()}</span> for #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
               else
                  return "Removed Status from #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
            else if data.meta().key == 'position_id'
               if data.subject3Name()?
                  return "Changed Position for #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
               else
                  return "Removed Position from #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
            else if data.meta().key == 'cost_code_id'
               return "Changed Category for #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
            else if data.meta().key == 'label_id'
               return "Changed Subcategory for #{what} " +
                  "from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
            else if data.meta().key == 'work_scope_text'
               return "Updated Scope of Work for Request"
            else if data.meta().key == 'instruction_text'
               return "Updated Assignment Instructions for Request"
            else
               keyDispayName = switch data.meta().key
                  when "start_day" then "the Start Date"
                  when "end_day" then "the End Date"
                  when "tbd_end_date" then "the End Date"
                  when "start_time" then "the Start Time"
                  when "end_time" then "the End Time"
                  when "saturdays_on" then "Working Saturdays"
                  when "sundays_on" then "Working Sundays"

               ### eslint-disable no-unreachable ###
               oldVal = switch data.meta().key
                  when "start_day" then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
                  when "end_day"
                     if data.meta().old_val? then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat()) else "TBD"
                  when "tbd_end_date"
                     if data.meta().old_val? then DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat()) else "TBD"
                  when "start_time" then defaultStore.formatTime(data.meta().old_val)
                  when "end_time" then defaultStore.formatTime(data.meta().old_val)
                  when "saturdays_on"
                     if data.meta().old_val then "True" else "False"
                  when "sundays_on"
                     if data.meta().old_val then "True" else "False"

               newVal = switch data.meta().key
                  when "start_day" then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
                  when "end_day"
                     if data.meta().new_val? then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat()) else "TBD"
                  when "tbd_end_date"
                     if data.meta().new_val? then DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat()) else "TBD"
                  when "start_time" then defaultStore.formatTime(data.meta().new_val)
                  when "end_time" then defaultStore.formatTime(data.meta().new_val)
                  when "saturdays_on"
                     if data.meta().new_val then "True" else "False"
                  when "sundays_on"
                     if data.meta().new_val then "True" else "False"
               ### eslint-enable no-unreachable ###

               return "Changed #{keyDispayName} from <span class='lc-bold-text'>#{oldVal}</span> " +
                  "to <span class='lc-bold-text'>#{newVal}</span> for " +
                  "the #{what} from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{formattedEndDate}</span>"
      delete: (data) ->
         action = "Deleted"
         action = "Filled" if data.meta()?.filled_resource_id?
         what = "Request"
         what = "<span class='lc-bold-text'>#{data.details().position.name}</span> Request" if data.details().position?
         startDay = data.meta()?.start_day or data.details().start_day
         formattedStartDate = DateUtils.formatDetachedDay(startDay, defaultStore.getDateFormat())
         end = "TBD"
         statusName = if data.meta()?.status_name? then "(status: <span class='lc-bold-text'>#{data.meta().status_name}</span>) " else ""
         if data.meta()?.end_day or data.details().end_day?
            endDay = data.meta()?.end_day or data.details().end_day
            end = DateUtils.formatDetachedDay(endDay, defaultStore.getDateFormat())
         return "#{action} #{what} #{statusName} from <span class='lc-bold-text'>#{formattedStartDate}</span> to <span class='lc-bold-text'>#{end}</span>"
   }
   project_tags: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject2Name()}</span> to " +
         "<span class='lc-bold-text'>#{data.subject1Name()}</span>"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject2Name()}</span> " +
         "from <span class='lc-bold-text'>#{data.subject1Name()}</span>"
   }
   project_tags_attachments: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject3Name()}</span> to the " +
         "<span class='lc-bold-text'>#{data.subject2Name()}</span> Tag"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject3Name()}</span> attachment " +
         "from the <span class='lc-bold-text'>#{data.subject2Name()}</span> Tag"
   }
   project_attachments: {
      create: (data) ->
         return "Added <span class='lc-bold-text'>#{data.subject2Name()}</span> to " +
         "<span class='lc-bold-text'>#{data.subject1Name()}</span>"
      delete: (data) ->
         return "Removed <span class='lc-bold-text'>#{data.subject2Name()}</span> attachment " +
         "from <span class='lc-bold-text'>#{data.subject1Name()}</span>"
   }
   project_wage_override: {
      create: (data) ->
         return "Added a <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().rate)}</span> Wage Override " +
         "for the <span class='lc-bold-text'>#{data.subject2Name()}</span> position"
      update: (data) ->
         return "Changed Rate from <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span> to " +
         "<span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span> for the Wage Override on the " +
         "<span class='lc-bold-text'>#{data.subject2Name()}</span> position"
      delete: (data) ->
         return "Removed Wage Override of <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().rate)}</span> " +
         "from the <span class='lc-bold-text'>#{data.subject2Name()}</span> position"
   }
   project_forecasting: {
      create: () -> ""
      update: () -> ""
      delete: () -> ""
   }
   project_roles: {
      create: (data) ->
         if data.subject3Name()?.first?
            assigneeName = "#{data.subject3Name().first} #{data.subject3Name().last}"
         else
            assigneeName = data.subject3Name()
         return "Assigned <span class='lc-bold-text'>#{assigneeName}</span> to the project role <span class='lc-bold-text'>#{data.subject2Name()}</span>"
      update: () -> ""
      delete: (data) ->
         if data.subject3Name()?.first?
            assigneeName = "#{data.subject3Name().first} #{data.subject3Name().last}"
         else
            assigneeName = data.subject3Name()
         return "Removed <span class='lc-bold-text'>#{assigneeName}</span> from the project role <span class='lc-bold-text'>#{data.subject2Name()}</span>"
   }
   project_shift: {
      update: (data) ->
         oldStartDate = moment(data.meta().old_start_date).startOf("day")
         newStartDate = moment(data.meta().new_start_date).startOf("day")

         if oldStartDate? and newStartDate?
            shiftDelta = Math.round(moment.duration(newStartDate.diff(oldStartDate)).asDays());
            return "Shifted the entire project (including assignments and requests) by <span class='lc-bold-text'>#{shiftDelta}</span> days."
   }
   project_info: {
      create: (data) ->
         return switch data.meta().property_name
            when "group_ids"
               "Added Project to the <span class='lc-bold-text'>#{data.subject2Name()}</span> Group"
      update: (data) ->
         ### eslint-disable no-unreachable ###
         return switch data.meta().property_name
            when "name"
               return "Changed Name from <span class='lc-bold-text'>#{data.meta().old_val}</span> to " +
               "<span class='lc-bold-text'>#{data.meta().new_val}</span>"
            when "profile_pic_url"
               if !data.meta().old_val?
                  return "Added profile picture."
               else if !data.meta().new_val?
                  return "Removed profile picture."
               else
                  return "Changed profile picture."
            when "status"
               oldVal = data.meta().old_val.charAt(0).toUpperCase() + data.meta().old_val.slice(1)
               newVal = data.meta().new_val.charAt(0).toUpperCase() + data.meta().new_val.slice(1)
               return "Changed Status from <span class='lc-bold-text'>#{oldVal}</span> to " +
               "<span class='lc-bold-text'>#{newVal}</span>"
            when "timezone"
               if data.meta()?.old_val and data.meta()?.new_val
                  oldVal = "#{data.meta().old_val}"
                  newVal = "#{data.meta().new_val}"
                  return "Changed Timezone from <span class='lc-bold-text'>#{oldVal}</span> to " +
                  "<span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta()?.new_val?
                  newVal = "#{data.meta().new_val}"
                  return "Added Timezone " +
                  "<span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta()?.old_val?
                  oldVal = "#{data.meta().old_val}"
                  return "Removed Timezone " +
                  "<span class='lc-bold-text'>#{oldVal}</span>"
            # Optional
            when "job_number"
               if data.meta().old_val?
                  return "Removed Project Number: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Project Number from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Project Number: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "lat"
               if data.meta().old_val?
                  return "Removed Latitude: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Latitude from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Latitude: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "long"
               if data.meta().old_val?
                  return "Removed Longitude: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Longitude from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Longitude: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "address_1"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Address from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Address: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Address value."

            when "address_2"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Address 2 from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Address 2: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Address 2 value."

            when "city_town"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed City/Town from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added City/Town: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed City/Town value."

            when "state_province"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed State/Province from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added State/Province: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed State/Province value."

            when "zipcode"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Postal Code from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Postal Code: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Postal Code value."

            when "country"
               if data.meta().old_val? && data.meta().new_val?
                  return "Changed Country from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else if data.meta().new_val?
                  return "Added Country: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Removed Country value."

            when "daily_start_time"
               if data.meta().old_val? and data.meta().new_val?
                  oldVal = defaultStore.formatTime(data.meta().old_val)
                  newVal = defaultStore.formatTime(data.meta().new_val)
                  return "Changed Daily Start Time from <span class='lc-bold-text'>#{oldVal}</span> " +
                  "to <span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta().new_val?
                  newVal = defaultStore.formatTime(data.meta().new_val)
                  return "Added Daily Start Time: <span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta().old_val?
                  oldVal = defaultStore.formatTime(data.meta().old_val)
                  return "Removed Daily Start Time: <span class='lc-bold-text'>#{oldVal}</span>"

            when "daily_end_time"
               if data.meta().old_val? and data.meta().new_val?
                  oldVal = defaultStore.formatTime(data.meta().old_val)
                  newVal = defaultStore.formatTime(data.meta().new_val)
                  return "Changed Daily End Time from <span class='lc-bold-text'>#{oldVal}</span> " +
                  "to <span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta().new_val?
                  newVal = defaultStore.formatTime(data.meta().new_val)
                  return "Added Daily End Time: <span class='lc-bold-text'>#{newVal}</span>"
               else if data.meta().old_val?
                  oldVal = defaultStore.formatTime(data.meta().old_val)
                  return "Removed Daily End Time: <span class='lc-bold-text'>#{oldVal}</span>"

            when "bid_rate"
               if data.meta().old_val? and data.meta().new_val?
                  return "Changed Estimated Avg. Rate from <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span> " +
                  "to <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
               else if data.meta().new_val?
                  return "Added Estimated Avg. Rate: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
               else if data.meta().old_val?
                  return "Removed Estimated Avg. Rate: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span>"

            when "percent_complete"
               if data.meta().old_val?
                  return "Removed Percent Complete: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Percent Complete from <span class='lc-bold-text'>#{data.meta().old_val}%</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}%</span>"
               else if data.meta().new_val?
                  return "Added Percent Complete: <span class='lc-bold-text'>#{data.meta().new_val}%</span>"

             when "customer_name"
               if data.meta().old_val?
                  return "Removed Customer: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Customer from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Customer: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

             when "project_type"
               if data.meta().old_val?
                  return "Removed Project Type: <span class='lc-bold-text'>#{data.meta().old_val}</span>" if data.meta().new_val == null
                  return "Changed Project Type from <span class='lc-bold-text'>#{data.meta().old_val}</span> " +
                  "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
               else
                  return "Added Project Type: <span class='lc-bold-text'>#{data.meta().new_val}</span>"

            when "color"
               if data.meta().old_val?
                  ### eslint-disable no-useless-escape ###
                  oldColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().old_val}\"></div>"
                  newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                  return "Changed Project Color from #{oldColorString} to #{newColorString}"
               else
                  newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                  ### eslint-enable no-useless-escape ###
                  return "Added Project Color: #{newColorString}"

            when "start_date"
               if data.meta().old_val?
                  oldDate = new Date(data.meta().old_val)
                  mutateForTimezone(oldDate)
                  oldDate = DateUtils.getShortNumericDate(oldDate, defaultStore.getDateFormat())
               else
                  oldDate = null
               if data.meta().new_val?
                  newDate = new Date(data.meta().new_val)
                  mutateForTimezone(newDate)
                  newDate = DateUtils.getShortNumericDate(newDate, defaultStore.getDateFormat())
               else
                  newDate = null

               if oldDate? and newDate?
                  return "Changed Start Date from <span class='lc-bold-text'>#{oldDate}</span> " +
                  "to <span class='lc-bold-text'>#{newDate}</span>"
               else if oldDate?
                  return "Removed Start Date of <span class='lc-bold-text'>#{oldDate}</span>"
               else if newDate?
                  return "Added Start Date of <span class='lc-bold-text'>#{newDate}</span>"

            when "est_end_date"
               if data.meta().old_val?
                  oldDate = new Date(data.meta().old_val)
                  mutateForTimezone(oldDate)
                  oldDate = DateUtils.getShortNumericDate(oldDate, defaultStore.getDateFormat())
               else
                  oldDate = null
               if data.meta().new_val?
                  newDate = new Date(data.meta().new_val)
                  mutateForTimezone(newDate)
                  newDate = DateUtils.getShortNumericDate(newDate, defaultStore.getDateFormat())
               else
                  newDate = null

               if oldDate? and newDate?
                  return "Changed Estimated End Date from <span class='lc-bold-text'>#{oldDate}</span> " +
                  "to <span class='lc-bold-text'>#{newDate}</span>"
               else if oldDate?
                  return "Removed Estimated End Date of <span class='lc-bold-text'>#{oldDate}</span>"
               else if newDate?
                  return "Added Estimated End Date of <span class='lc-bold-text'>#{newDate}</span>"

            when "custom_fields"
               if data.meta().custom_field_type == 'multi-select'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{data.meta().old_val.join(', ')}</span> " + 
                     "to <span class='lc-bold-text'>#{data.meta().new_val.join(', ')}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().new_val.join(', ')}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().old_val.join(', ')}</span>"

               else if data.meta().custom_field_type == 'hex-color'
                  if data.meta().old_val? and data.meta().new_val?
                     ### eslint-disable no-useless-escape ###
                     oldColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().old_val}\"></div>"
                     newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                     return "Changed #{data.meta().custom_field_name} from #{oldColorString} to #{newColorString}"
                  else if data.meta().new_val?
                     newColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().new_val}\"></div>"
                     return "Added #{data.meta().custom_field_name}: #{newColorString}"
                  else if data.meta().old_val?
                     oldColorString = "<div class=\"project-detail__activity-content--color\" style=\"background-color: #{data.meta().old_val}\"></div>"
                     ### eslint-enable no-useless-escape ###
                     return "Removed #{data.meta().custom_field_name}: #{oldColorString}"

               else if data.meta().custom_field_type == 'bool'
                  if data.meta().old_val? and data.meta().new_val?
                     formatedOld = Format.capitalize(String(data.meta().old_val))
                     formatedNew = Format.capitalize(String(data.meta().new_val))
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{formatedOld}</span> " + 
                     "to <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().new_val?
                     formatedNew = Format.capitalize(String(data.meta().new_val))
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().old_val?
                     formatedOld = Format.capitalize(String(data.meta().old_val))
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedOld}</span>"

               else if data.meta().custom_field_type == 'date'
                  if data.meta().old_val? and data.meta().new_val?
                     formatedOld = DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
                     formatedNew = DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{formatedOld}</span> " + 
                     "to <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().new_val?
                     formatedNew = DateUtils.formatDetachedDay(data.meta().new_val, defaultStore.getDateFormat())
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedNew}</span>"
                  else if data.meta().old_val?
                     formatedOld = DateUtils.formatDetachedDay(data.meta().old_val, defaultStore.getDateFormat())
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{formatedOld}</span>"

               else if data.meta().custom_field_type == 'currency'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span> " + 
                     "to <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().new_val)}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatCurrency(data.meta().old_val)}</span>"

               else if data.meta().custom_field_type == 'number'
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{Format.formatNumber(data.meta().old_val)}</span> " + 
                     "to <span class='lc-bold-text'>#{Format.formatNumber(data.meta().new_val)}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatNumber(data.meta().new_val)}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{Format.formatNumber(data.meta().old_val)}</span>"

               else
                  if data.meta().old_val? and data.meta().new_val?
                     return "Changed #{data.meta().custom_field_name} from <span class='lc-bold-text'>#{data.meta().old_val}</span> " + 
                     "to <span class='lc-bold-text'>#{data.meta().new_val}</span>"
                  else if data.meta().new_val?
                     return "Added #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().new_val}</span>"
                  else if data.meta().old_val?
                     return "Removed #{data.meta().custom_field_name}: <span class='lc-bold-text'>#{data.meta().old_val}</span>"
         ### eslint-enable no-unreachable ###

      delete: (data) ->
         return switch data.meta().property_name
            when "group_ids"
               return "Removed Project from the " +
               "<span class='lc-bold-text'>#{data.subject2Name()}</span> Group"
   }
}


export ActivityContentFormatter = ActivityContentFormatter

import type { StoreJsonResponse } from "./common/store.core";
import { Store } from "./common/store.core";
import type {
   CreateInitialMessagePayload,
   CreateInitialMessageResponse,
   ScheduleInitialMessagePayload,
} from "@laborchart-modules/lc-core-api/dist/api/messages/create-message";
import type {
   GetMessagesForConversationResponse,
   GetMessageContextCountsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/messages/get-message";
import { authManager } from "@/lib/managers/auth-manager";
import type { CannedMessage } from "@laborchart-modules/common";

export abstract class MessageStore extends Store {
   static createInitialMessageAndConversation(
      message: CreateInitialMessagePayload,
   ): StoreJsonResponse<CreateInitialMessageResponse> {
      return this.requestJson({
         url: "/api/v3/initial-message",
         method: "POST",
         body: {
            ...message,
            group_id:
               authManager.selectedGroupId() == "my-groups"
                  ? undefined
                  : authManager.selectedGroupId(),
         },
      });
   }

   static scheduleInitialMessageAndConversation(
      message: ScheduleInitialMessagePayload,
   ): StoreJsonResponse<CreateInitialMessageResponse> {
      return this.requestJson({
         url: "/api/v3/initial-message/schedule",
         method: "POST",
         body: {
            ...message,
            group_id:
               authManager.selectedGroupId() == "my-groups"
                  ? undefined
                  : authManager.selectedGroupId(),
         },
      });
   }

   static getMessagesForConversation(
      conversationId: string,
   ): StoreJsonResponse<GetMessagesForConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversation/${conversationId}/messages`,
         method: "GET",
      });
   }

   static getMessageContextCounts(): StoreJsonResponse<GetMessageContextCountsResponse> {
      return this.requestJson({
         url: `/api/v3/message-context-counts`,
         method: "GET",
      });
   }

   static createCannedMessage(payload: Partial<CannedMessage>): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/canned-messages`,
         method: "POST",
         body: payload,
      });
   }

   static deleteCannedMessage(id: string): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/canned-messages/${id}`,
         method: "DELETE",
      });
   }

   static getCannedMessage(id: string): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/canned-messages/${id}`,
         method: "GET",
      });
   }

   static updateCannedMessage(id: string, payload: Partial<CannedMessage>): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/canned-messages/${id}`,
         method: "PATCH",
         body: payload,
      });
   }
}

import ko from "knockout"

import "./totals-export-modal-pane.styl"
import template from "./totals-export-modal-pane.pug"

import { router } from "@/lib/router"
import { Icons } from "@/lib/managers/notification-manager"
import { Notification } from "@/lib/managers/notification-manager"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { GeneratedReportStore } from "@/stores/generated-report-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Notifications ###
import { GeneratedReportNotification } from "@/notifications/generated-report-notification"

import { observable } from "knockout"

State = {
   SELECTING: "selecting",
   DONE: "done",
}

ACTION_BUTTON_TEXT = "Export"

export class TotalsExportModalPane extends ModalPane
   constructor: (payload = {}, title, reportTargetPath) ->
      assertArgs(arguments, Object, String, String)
      super(title or "Export CSV", ACTION_BUTTON_TEXT, template())
      @payload = payload
      @reportTargetPath = reportTargetPath
      @state = observable(State.SELECTING)

   actionBtnIntercept: () =>
      return if @state() != State.SELECTING
      @isActionEnabled(false)
      @setActionText("Exporting...")

      report = null;
      try
         @payload['group_id'] = authManager.selectedGroupId()
         result = await GeneratedReportStore.create(@reportTargetPath, @payload).payload
         report = result.data;
         report.name = ko.observable(report.name)
         @setActionText("")
         @state(State.DONE)
         return notificationManager.show(new GeneratedReportNotification(report))
      catch err
         console.log err
         @isActionEnabled(true)
         @setActionText(ACTION_BUTTON_TEXT)
         @state(State.SELECTING)
         return notificationManager.show(
            new Notification({
               icon: Icons.WARNING,
               text: "Unable to export report. Please try again or contact support.",
            })
         )  

   navigateToReports: () =>
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/reports")
      modalManager.modalFinished()
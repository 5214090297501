import { LegacyStore } from "@/stores/legacy-store.core";
import type { GetAssignmentCreationSupportDataResponse } from "@laborchart-modules/lc-core-api/dist/api/legacy/get-assignment-creation-support-data";
import React from "react";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import type {
   FindAssignmentsPaginatedQueryParams,
   FindAssignmentsPaginatedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { FindAssignmentsSortBy } from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-assignments";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import {
   FilterClassifier,
   FilterFieldType,
} from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";

export function useGetAssignmentCreationSupportDataQuery(groupId: string) {
   const params: { group_id?: string } = {};
   if (groupId != "my-groups") {
      params.group_id = groupId;
   }
   const [data, setData] = React.useState<GetAssignmentCreationSupportDataResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function fetchSupportData() {
         setIsLoading(true);
         setData(null);

         const data = await LegacyStore.getAssignmentCreationSupportData(params).payload;
         setData(data);
         setIsLoading(false);
      }
      fetchSupportData();
   }, []);

   return { data, isLoading };
}

export function useGetCurrentAssignments(personId: string) {
   const [currentAssignments, setCurrentAssignments] =
      React.useState<FindAssignmentsPaginatedResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   const fetchCurrentAssignments = async () => {
      setIsLoading(true);
      setCurrentAssignments(null);

      const query: FindAssignmentsPaginatedQueryParams = {
         filters: [
            {
               name: "Person",
               property: "person_id",
               type: FilterFieldType.SELECT,
               value_sets: [{ negation: false, value: personId }],
            },
            {
               name: "Assignment End Date",
               property: "end_date",
               type: FilterFieldType.DATE,
               value_sets: [
                  {
                     negation: false,
                     value: getDetachedDay(new Date()),
                     classifier: FilterClassifier.GREATER_THAN_OR_EQUAL,
                  },
               ],
            },
         ],
         limit: 2,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         sort_by: FindAssignmentsSortBy.ASSIGNMENT_START_DATE,
         sort_direction: Order.ASCENDING,
      };

      const data = await Assignment2Store.getAssignments(query).payload;
      if (data?.pagination?.next_starting_after && data?.pagination?.total_possible > 2) {
         const nextData = await Assignment2Store.getAssignments({
            ...query,
            starting_after: data.pagination.next_starting_after,
            limit: data.pagination.total_possible,
         }).payload;
         data.data.push(...nextData.data);
      }
      setCurrentAssignments(data);
      setIsLoading(false);
   };

   React.useEffect(() => {
      if (!personId) return;
      fetchCurrentAssignments();
   }, [personId]);

   return { currentAssignments, isLoading, fetchCurrentAssignments };
}

import "./save-report-pane.styl"
import template from "./save-report-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"
import { ValidationUtils } from "@/lib/utils/validation"


export class SaveReportPane extends PopupPane
   constructor: (existingReportId, existingReportName, callbacks) ->
      assertArgs(arguments, insist.nullable(String), insist.nullable(String), Object)
      super(template())
      @existingReportId = existingReportId
      @name = ko.observable(existingReportName or null)
      @existingReportName = existingReportName
      # @public = ko.observable(reportIsPublic or false)
      @callbacks = callbacks

      @canSaveAs = ko.pureComputed =>
         return (@existingReportId? and ValidationUtils.validateInput(@name()) and
         @name() != @existingReportName)

      @canSave = ko.pureComputed =>
         return ValidationUtils.validateInput(@name())


   handleSave: ->
      @callbacks.handleSave(@name()) if @callbacks.handleSave?
      @dismissHandler()

   handleSaveAs: ->
      @callbacks.handleSaveAs(@name()) if @callbacks.handleSaveAs?
      @dismissHandler()

   handleDelete: ->
      @callbacks.handleDelete() if @callbacks.handleDelete?
      @dismissHandler()

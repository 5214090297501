import "./groups-editor.styl";
import template from "./groups-editor.pug";
import {
   notificationManagerInstance,
   Notification,
   Icons,
} from "@/lib/managers/notification-manager";
import { GroupStore } from "@/stores/group-store.core";
import type { MaybeSubscribable, ObservableArray } from "knockout";
import ko, { observable, observableArray, pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common/component-args";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
// TODO: Factor out this frontend model.
import { Group } from "@/models/group";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import type { RowBase } from "@/lib/components/grid/grid-store";
import { BatchEditConflictModalPane } from "@/lib/components/batch-edit/batch-edit-conflict-modal-pane";
import type { AuthManager } from "@/lib/managers/auth-manager";
import { authManager } from "@/lib/managers/auth-manager";
import { MultilineTextCell } from "@/lib/components/grid/cells/multiline-text-cell";
import type { GridCellFactory } from "@/lib/components/grid/grid-column";
import { ActionResult } from "@/lib/components/drop-downs/drop-down-2";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import { ColorCircleTextCell } from "../../grid/cells/color-circle-text-cell";
import type { Conflict } from "../../batch-actions/batch-actions";

export type GroupsEditorUpdate = Record<string, boolean>;

type RenderedGroup<TRecord extends RowBase> = {
   group: Group;
   records: TRecord[];
};

enum View {
   LOADING = 0,
   GROUP_LIST = 1,
   ADD_GROUP = 2,
}

export enum GroupType {
   GROUP_IDS = "group_ids",
   ACCESS_GROUP_IDS = "access_group_ids",
   ASSIGNABLE_GROUP_IDS = "assignable_group_ids",
}

export interface GroupsEditorParams<TRecord extends RowBase = RowBase>
   extends EditorComponentParams<TRecord[], GroupsEditorUpdate> {
   groupType: GroupType;
   isAccessibleGroupId: (record: string) => boolean;
   isVisibleGroupId: (record: string) => boolean;
   getRecordGroupIds: (record: TRecord) => Set<string>;
   setRecordGroupIds: (record: TRecord, ids: Set<string>) => void;
   // TODO: Find a typing to require conflictModalColumnGroups if validator defined.
   validator?: (record: TRecord, update: GroupsEditorUpdate) => string | null;
   conflictModalColumnGroups?: Array<GridColumnGroup<TRecord>>;
   isRequired?: MaybeSubscribable<boolean>;
}

export class GroupsEditor<TRecord extends RowBase> {
   readonly records: ObservableArray<TRecord>;
   readonly view = observable(View.LOADING);
   readonly panes = observableArray<ArrayDropDownPane<Group>>();
   readonly transition = observable(Transition.FORWARD);
   readonly containsFocus = observable(false).extend({ notify: "always" });
   readonly recordsInSelectedGroupCount = observable(0);
   readonly recordsOutOfSelectedGroupCount = observable(0);
   readonly selectedGroup = observable<RenderedGroup<TRecord> | null>(null);
   readonly isSaving = observable(false);
   readonly groupType: GroupType;
   readonly isAccessibleGroupId: (record: string) => boolean;
   readonly isVisibleGroupId: (record: string) => boolean;
   readonly getRecordGroupIds: (record: TRecord) => Set<string>;
   readonly getRecordAccessibleGroupIds: (record: TRecord) => Set<string>;
   readonly getRecordVisibleGroupIds: (record: TRecord) => Set<string>;
   readonly setRecordGroupIds: (record: TRecord, ids: Set<string>) => void;

   private readonly availableGroups = observableArray<Group>([]);

   readonly title = pureComputed(() => {
      if (this.view() == View.GROUP_LIST)
         return this.groupType == GroupType.ACCESS_GROUP_IDS
            ? "Access Groups"
            : this.groupType == GroupType.ASSIGNABLE_GROUP_IDS
            ? "Assignable Groups"
            : "Groups";
      return !this.canBeRemoved() ? "Add Group" : "Edit Group";
   });

   readonly canBeAdded = pureComputed(() => {
      return this.recordsInSelectedGroupCount() >= 0;
   });

   readonly canBeRemoved = pureComputed(() => {
      const selectedGroup = this.selectedGroup();
      if (selectedGroup == null) return false;
      return this.groupIdsInUse().has(selectedGroup.group.id);
   });

   readonly canSave = pureComputed(() => {
      const selectedGroup = this.selectedGroup();
      if (this.isSaving() || !selectedGroup) return false;
      return this.canBeAdded();
   });

   readonly renderedGroups = pureComputed(() => {
      const records = this.records();
      return this.availableGroups()
         .map<RenderedGroup<TRecord>>((group) => ({
            group,
            records: records.filter((record) =>
               this.getRecordAccessibleGroupIds(record).has(group.id),
            ),
         }))
         .filter((renderedGroups) => renderedGroups.records.length);
   }).extend({ trackArrayChanges: true });

   readonly groupIdsInUse = pureComputed(() => {
      return new Set(this.renderedGroups().map((renderedGroup) => renderedGroup.group.id));
   });

   readonly cellFactory = ColorCircleTextCell.factory<Group>((group) => ({
      text: group.name(),
      color: group.color(),
   }));

   constructor(readonly params: GroupsEditorParams<TRecord>) {
      this.records = observableArray(unwrap(params.value) || []);
      this.groupType = params.groupType;
      this.isAccessibleGroupId = params.isAccessibleGroupId;
      this.isVisibleGroupId = params.isVisibleGroupId;
      this.getRecordGroupIds = params.getRecordGroupIds;
      this.getRecordAccessibleGroupIds = (person) =>
         new Set(Array.from(this.getRecordGroupIds(person)).filter(this.isAccessibleGroupId));
      this.getRecordVisibleGroupIds = (person) =>
         new Set(Array.from(this.getRecordGroupIds(person)).filter(this.isVisibleGroupId));
      this.setRecordGroupIds = params.setRecordGroupIds;

      this.panes([
         new ArrayDropDownPane({
            items: this.availableGroups,
            searchTextProvider: (group) => group.name(),
         }),
      ]);

      this.loadGroups();
   }

   getCountText(count: number): string {
      return count == 1 ? "1 record" : `${count} records`;
   }

   onGroupSelected = (group: Group): void => {
      this.selectGroup({
         group: group.clone(Group),
         records: this.records(),
      });
   };

   actionInterceptor = (group: Group): ActionResult => {
      this.onGroupSelected(group);
      return ActionResult.CLOSE;
   };

   onHidden = (): void => {
      // Change focus when a view is hidden instead of when one is visible
      // to guarantee the recently hidden view doesn't grab focus.
      this.containsFocus(true);
   };

   onSaveClicked = async (): Promise<void> => {
      const selectedGroup = this.selectedGroup();
      if (!selectedGroup) return;
      const update: GroupsEditorUpdate = { [selectedGroup.group.id]: true };
      const isSuccess = await this.saveChanges({
         update: update,
         errorMessage: "An unexpected error prevented the group from being added.",
      });
      if (!isSuccess) return;
      this.records(
         this.records().map((r) => {
            if (this.params.validator && this.params.validator(r, update) != null) return r;
            const groupIds = this.getRecordAccessibleGroupIds(r);
            groupIds.add(selectedGroup.group.id);
            this.setRecordGroupIds(r, groupIds);
            return r;
         }),
      );
      this.transition(Transition.BACKWARD);
      this.view(View.GROUP_LIST);
   };

   onDeleteClicked = async (): Promise<void> => {
      const selectedGroup = this.selectedGroup();
      if (!selectedGroup) return;
      const update: GroupsEditorUpdate = { [selectedGroup.group.id]: false };
      const isSuccess = await this.saveChanges({
         update: update,
         errorMessage: "An unexpected error prevented the group from being removed.",
      });
      if (!isSuccess) return;
      this.records(
         this.records()
            .map((r) => {
               if (this.params.validator && this.params.validator(r, update) != null) return r;
               const groupIds = this.getRecordGroupIds(r);
               groupIds.delete(selectedGroup.group.id);
               this.setRecordGroupIds(r, groupIds);
               return r;
            })
            .filter((r) => this.getRecordVisibleGroupIds(r).size > 0),
      );
      this.transition(Transition.BACKWARD);
      this.view(View.GROUP_LIST);
   };

   onCancelClicked = (): void => {
      this.transition(Transition.BACKWARD);
      this.view(View.GROUP_LIST);
   };

   onCloseClicked = (): void => {
      if (this.params.onClose) this.params.onClose({ hasSaved: false });
   };

   static factory<TRecord extends RowBase>(
      provider: (records: TRecord[]) => GroupsEditorParams<TRecord>,
   ): (records: TRecord[]) => ComponentArgs<GroupsEditorParams<TRecord>> {
      return (records) => ({
         name: "groups-editor",
         params: provider(records),
      });
   }

   // TODO: Find another location for this.
   /**
    * This isn't actually used at all by GroupsEditor, but it is used for
    * all groups columns. This was the best logical location for now.
    */
   static getColumnProviders<TRecord extends RowBase>({
      authManager,
      groupIdsSelector,
   }: {
      authManager: AuthManager;
      groupIdsSelector: (row: TRecord) => string[];
   }): {
      cellFactory: GridCellFactory<TRecord, unknown>;
      copyProvider: (data: TRecord) => string;
   } {
      return MultilineTextCell.columnProviders<TRecord>((row) => {
         const groupNames = authManager.getVisibilitySortedGroupNames(
            new Set(groupIdsSelector(row)),
         );
         return {
            // TODO: Update to handle dynamic height of cell.
            values:
               groupNames.length > 4
                  ? groupNames
                       .slice(0, 3)
                       .map((v) => ({ text: v }))
                       .concat([{ text: `+${groupNames.length - 3} more...` }])
                  : groupNames.map((v) => ({ text: v })),
         };
      });
   }

   private async loadGroups() {
      try {
         const groups = [];
         const stream = await GroupStore.findGroupsStream({}).stream;
         for await (const group of stream) {
            groups.push(new Group(group));
         }
         const unsortedGroups = groups.filter((g) => this.isAccessibleGroupId(g.id));
         const sortedGroups = authManager.getVisibilitySortedGroupItems(
            unsortedGroups,
            (group) => group.id,
         );
         this.availableGroups(sortedGroups);
         this.transition(Transition.FADE);
         this.view(View.GROUP_LIST);
      } catch {
         notificationManagerInstance.show(
            new Notification({
               text: "Unable to load Group Options",
               icon: Icons.WARNING,
               duration: 5000,
            }),
         );
      }
   }

   private async saveChanges({
      update,
      errorMessage,
   }: {
      update: GroupsEditorUpdate;
      errorMessage: string;
   }): Promise<boolean> {
      try {
         if (this.params.validator != null) {
            const conflicts = this.getConflicts(update);
            if (conflicts.length) {
               await BatchEditConflictModalPane.show({
                  records: this.records(),
                  conflicts: conflicts,
                  columnGroups: this.params.conflictModalColumnGroups!,
                  saveProvider: async () => {
                     const editorFactory = GroupsEditor.factory(() => this.params);
                     const validRecords = this.records().filter(
                        (r) => !conflicts.some((c) => c.record.id == r.id),
                     );
                     editorFactory(validRecords).params.saveProvider(update);
                  },
               });
               return true;
            }
         }
         await this.params.saveProvider(update);
         return true;
      } catch (err) {
         notificationManagerInstance.show(
            new Notification({
               text: errorMessage,
               icon: Icons.WARNING,
               duration: 5000,
            }),
         );
         return false;
      } finally {
         this.isSaving(false);
      }
   }

   private getConflicts(update: GroupsEditorUpdate): Array<Conflict<TRecord>> {
      if (this.params.validator == null) return [];
      return this.records()
         .map((r) => ({ record: r, reason: this.params.validator!(r, update) }))
         .filter((r) => r.reason != null) as Array<Conflict<TRecord>>;
   }

   private selectGroup(renderedGroup: RenderedGroup<TRecord>) {
      const records = renderedGroup.records;
      const group = renderedGroup.group;

      this.selectedGroup(renderedGroup);
      this.recordsInSelectedGroupCount(
         records.filter((r) => !this.getRecordAccessibleGroupIds(r).has(group.id)).length,
      );
      this.recordsOutOfSelectedGroupCount(
         records.filter((r) => this.getRecordAccessibleGroupIds(r).has(group.id)).length,
      );
      this.transition(Transition.FORWARD);
      this.view(View.ADD_GROUP);
   }
}

ko.components.register("groups-editor", {
   viewModel: GroupsEditor,
   template: template(),
   synchronous: true,
});

import React, { useEffect } from "react";
import { DateSelect, FlexList, Select, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import { dateClassifiers } from "@/react/shared/constants";

type CustomFilterComponentPropTypes = {
   columnDefinition: ColumnDefinition;
   onChange: (newValues: any[]) => void;
   value: any;
};

export const DateFilter = (props: CustomFilterComponentPropTypes) => {
   // The date filter will only allow one date to be selected at a time, but the
   // props.value will always come as an array. In the future there is potential to enhance this component
   // to allow multiple dates to be selected. But now, we only care about the first value in the props.value
   // array.
   const [classifier, setClassifier] = React.useState<
      undefined | { id: number; label: string; value: string }
   >(props.value?.[0]?.classifier);
   const [date, setDate] = React.useState<string | undefined>(props.value?.[0]?.date);
   const I18n = useI18nContext();

   useEffect(() => {
      if (props.value && props.value.length > 0) {
         setClassifier(props.value[0].classifier);
         setDate(props.value[0].date);
      } else {
         setDate(undefined);
      }
   }, [props.value]);

   /* istanbul ignore next */
   function handleClassifierSelect(selection: any) {
      const selected = dateClassifiers.find((x) => x.value == selection.item);
      if (selected) selected.label = I18n.t(selected.label);
      setClassifier(selected);
      setDate(undefined);
      props.onChange([]);
   }

   function handleDateChange(newVal: Date | null) {
      setDate(newVal?.toISOString());
      if (classifier && newVal) {
         const newValue = [{ classifier: classifier, date: newVal.toISOString() }];
         props.onChange(newValue);
      } else {
         setDate(undefined);
         props.onChange([]);
      }
   }

   return (
      <FlexList>
         <Select
            label={classifier?.label}
            onSelect={handleClassifierSelect}
            style={{ width: "190px" }}
            placeholder={I18n.t("views.company.workforce_planning.classifiers.placeholder")}
         >
            {dateClassifiers.map((c) => (
               <Select.Option key={c.id} value={c.value} selected={c.value === classifier?.value}>
                  {I18n.t(c.label)}
               </Select.Option>
            ))}
         </Select>
         <DateSelect
            disabled={!classifier}
            onChange={handleDateChange}
            value={date ? new Date(date) : undefined}
         />
      </FlexList>
   );
};

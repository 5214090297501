import React, { useEffect, useState } from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   Button,
   Spinner,
   H2,
   Grid,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import { Pencil, Trash } from "@procore/core-icons";
import classnames from "classnames";
import { useModal } from "@/react/shared/hooks/useModal";
import type {
   EmptyRolesProps,
   ListRolesCardProps,
   ProjectDetailsCardProps,
   ProjectRoleData,
   Role,
} from "../types";
import { useGetJobTitles, useGetPeopleStream } from "@/react/components/common/queries/queries";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useToastAlertContext } from "@procore/toast-alert";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { AddRolesProject } from "./project-details-add-roles";
import { ActionMode } from "@/react/prop-types";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { ColorSwab } from "@/react/components/data-table/ColorSelectComponent/ColorSelectColumn";
import { prepareRolesPayload } from "../helpers";
import { commonButtonStyle } from "@/react/shared/helper";

import styles from "./project-details.scss";
const cx = classnames.bind(styles);

const EmptyRoles: React.FC<EmptyRolesProps> = ({
   editRoles,
   openAddRolesModal,
   isAddRolesOpen,
}) => {
   const I18n = useI18nContext();
   if (isAddRolesOpen) {
      return null;
   }
   return (
      <React.Fragment>
         <Box padding="lg">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t("views.company.workforce_planning.project_details_roles.title")}</H2>
            </FlexList>
         </Box>
         <Box padding="sm">
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_roles.roles_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_roles.roles_empty_state_description",
                  )}
               </EmptyState.Description>
               {editRoles && (
                  <EmptyState.Actions className={cx("empty-state-actions")}>
                     <Button variant="secondary" onClick={openAddRolesModal}>
                        {I18n.t("views.company.workforce_planning.project_details_roles.add_role")}
                     </Button>
                  </EmptyState.Actions>
               )}
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const ListRolesCard: React.FC<ListRolesCardProps> = ({
   roles,
   editRoles,
   openAddRolesModal,
   isAddRolesOpen,
   selectedRoleId,
   setSelectedRoleId,
   jobTitles,
   resourceName,
   onUpdateProject,
}) => {
   const { openModal, closeModal } = useModal();
   const [mode, setMode] = useState(ActionMode.READ);

   const toggleSelectedRole = (roleId: string, mode: ActionMode) => {
      setSelectedRoleId(selectedRoleId === roleId ? "" : roleId);
      setMode(mode);
      openModal();
   };

   const resetView = () => {
      closeModal();
      setSelectedRoleId("");
      setMode(ActionMode.READ);
   };

   const getInitialValues = (role: Role) => {
      return {
         job_title: {
            id: role.job_title_id,
            label: role.position.name,
         },
         resource_title: {
            id: role.person_id,
            label: `${role.assignee.name.first} ${role.assignee.name.last}`,
         },
      };
   };

   if (isAddRolesOpen) {
      return null;
   }

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_details_roles.title"
            helpTextKey="views.company.workforce_planning.project_details_roles.roles_help_text"
            buttonTextKey="views.company.workforce_planning.project_details_roles.add_role"
            btnDisabled={selectedRoleId !== ""}
            openAddItemModal={openAddRolesModal}
            permission={editRoles}
         />
         <Box className={cx("custom-box")}>
            <React.Fragment>
               {roles.map((item: Role, index: number) => (
                  <React.Fragment key={item.id}>
                     <Box
                        padding="md"
                        style={{
                           borderBottom: index !== roles.length - 1 ? "1px solid #D6DADC" : "none",
                        }}
                     >
                        {selectedRoleId !== item.id && (
                           <Grid>
                              <Grid.Row>
                                 <Grid.Col
                                    colWidth={5}
                                    style={{ display: "flex", alignItems: "center" }}
                                 >
                                    <ColorSwab
                                       color={item.position.color}
                                       shape="circle"
                                       label={item.position.name}
                                       height="20px"
                                       width="20px"
                                    />{" "}
                                    <Typography
                                       intent="h3"
                                       className={cx("custom-line-height-font-size-margin")}
                                    >
                                       {item.position.name}
                                    </Typography>
                                 </Grid.Col>
                                 <Grid.Col colWidth={5}>
                                    <Typography
                                       intent="small"
                                       className={cx("custom-line-height-font-size-margin")}
                                       style={{ color: "#6A767C" }}
                                    >
                                       {`${item.assignee?.name?.first} ${item.assignee?.name?.last}`}
                                    </Typography>
                                 </Grid.Col>
                                 {editRoles && (
                                    <Grid.Col colWidth={2} className={cx("icons-at-end")}>
                                       <span>
                                          <Pencil
                                             size="sm"
                                             data-testid="edit-role"
                                             style={{
                                                ...commonButtonStyle({
                                                   selectedId: selectedRoleId,
                                                   currentId: item.id,
                                                }),
                                                marginRight: "15px",
                                             }}
                                             onClick={() =>
                                                toggleSelectedRole(item.id, ActionMode.EDIT)
                                             }
                                          />

                                          <Trash
                                             size="sm"
                                             data-testid="delete-role"
                                             style={commonButtonStyle({
                                                selectedId: selectedRoleId,
                                                currentId: item.id,
                                             })}
                                             onClick={() =>
                                                toggleSelectedRole(item.id, ActionMode.DELETE)
                                             }
                                          />
                                       </span>
                                    </Grid.Col>
                                 )}
                              </Grid.Row>
                           </Grid>
                        )}
                        {selectedRoleId === item.id && (
                           <AddRolesProject
                              onClose={resetView}
                              handleCreate={onUpdateProject}
                              mode={mode}
                              initialValues={getInitialValues(item)}
                              availableJobTitles={jobTitles}
                              availableResourceName={resourceName}
                              roles={roles}
                           />
                        )}
                     </Box>
                  </React.Fragment>
               ))}
            </React.Fragment>
         </Box>
         <Box padding="xxs" />
      </React.Fragment>
   );
};

export const ProjectDetailsRolesCard: React.FC<ProjectDetailsCardProps> = ({
   projectData,
   updateProjectData,
   refetchData,
   loading: projectDataLoading,
}) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const { openModal, closeModal, isOpen } = useModal();
   const { data: jobTitles } = useGetJobTitles(groupId);
   const { data: resourceName } = useGetPeopleStream();
   const [canEditRoles, setCanEditRoles] = useState<boolean>(false);
   const [selectedRoleId, setSelectedRoleId] = useState<string>("");

   useEffect(() => {
      const canEditRoles = checkAuthAction(AuthAction.EDIT_PROJECT_ROLES);
      setCanEditRoles(canEditRoles);
   }, [checkAuthAction]);

   const handleRolesCreate = async (
      jobTitleValue: string,
      personIdValue: string,
      archived: boolean,
   ) => {
      const payload: ProjectRoleData = prepareRolesPayload(
         selectedRoleId,
         jobTitleValue,
         personIdValue,
         archived,
      );
      try {
         const updatedProject = await updateProjectData(payload);
         if (updatedProject.data) {
            setSelectedRoleId("");
            refetchData();
            closeModal();
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      }
   };
   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={projectDataLoading}>
            {projectData && projectData.data?.roles.length === 0 && (
               <EmptyRoles
                  editRoles={canEditRoles}
                  openAddRolesModal={openModal}
                  isAddRolesOpen={isOpen}
               />
            )}
            {projectData && projectData.data?.roles.length > 0 && (
               <ListRolesCard
                  roles={projectData?.data?.roles}
                  editRoles={canEditRoles}
                  openAddRolesModal={openModal}
                  isAddRolesOpen={isOpen}
                  selectedRoleId={selectedRoleId}
                  setSelectedRoleId={setSelectedRoleId}
                  jobTitles={jobTitles}
                  resourceName={resourceName}
                  onUpdateProject={handleRolesCreate}
               />
            )}
            {isOpen && projectData && (
               <AddRolesProject
                  onClose={closeModal}
                  handleCreate={handleRolesCreate}
                  availableJobTitles={jobTitles}
                  availableResourceName={resourceName}
                  roles={projectData.data?.roles}
               />
            )}
         </Spinner>
      </Card>
   );
};

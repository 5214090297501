import "./paragraph-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./paragraph-cell.pug";
import ko, { observable, pureComputed } from "knockout";
import type { Size } from "@/lib/utils/geometry";

export interface ParagraphCellParams {
   text: string;
   className?: string | null;

   /**
    * Not guarenteed to be the maximum in all cases.
    *    If other cells cause the paragraph cell to be taller, more lines will be shown
    */
   maxNumberOfLines?: number | null;
}

const CONTENT_CLASS = "paragraph-cell__content";
const LINE_HEIGHT = 15;

export class ParagraphCell {
   private static measuringElement: HTMLElement;
   readonly size = observable<Size>({ height: 0, width: 0 });
   readonly lineClamp = pureComputed(() => {
      return Math.floor(this.size().height / LINE_HEIGHT);
   });

   readonly text: string;
   readonly className: string;
   constructor(params: ParagraphCellParams) {
      this.text = params.text;
      this.className = params.className || "";
   }

   static factory<T>(
      formatter: (data: T) => ParagraphCellParams,
   ): GridCellFactory<T, ParagraphCellParams> {
      return (data: T, context): GridCell<ParagraphCellParams> => {
         const params = formatter(data);
         return {
            component: {
               name: "paragraph-cell",
               params,
            },
            height: this.measureText(params, context.width),
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => ParagraphCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: ParagraphCell.factory(provider),
         copyProvider: (data: T) => provider(data).text,
      };
   }

   private static measureText(params: ParagraphCellParams, width: number) {
      const maxNumberOfLines = params.maxNumberOfLines ? params.maxNumberOfLines : 0;
      // Create an element to use for measuring text.
      if (!this.measuringElement) {
         this.measuringElement = document.createElement("span");
         this.measuringElement.classList.add(CONTENT_CLASS);
         this.measuringElement.style.position = "absolute";
         this.measuringElement.style.top = "-99999px";
         this.measuringElement.style.left = "-99999px";
         document.body.appendChild(this.measuringElement);
      }
      this.measuringElement.classList.forEach((className) => {
         if (className != CONTENT_CLASS) {
            this.measuringElement.classList.remove(className);
         }
      });
      if (params.className) {
         this.measuringElement.classList.add(params.className);
      }
      this.measuringElement.style.width = `${width}px`;
      this.measuringElement.textContent = params.text;

      if (maxNumberOfLines == 0) return this.measuringElement.clientHeight;

      return this.measuringElement.clientHeight <= maxNumberOfLines * LINE_HEIGHT
         ? this.measuringElement.clientHeight
         : maxNumberOfLines * LINE_HEIGHT;
   }
}

ko.components.register("paragraph-cell", {
   viewModel: ParagraphCell,
   template: template(),
   synchronous: true,
});

import type { StoreJsonResponse, StoreStreamResponse } from "./common/store.core";
import { Store } from "./common/store.core";
import type {
   CreatePermissionLevelPayload,
   CreatePermissionLevelResponse,
} from "@laborchart-modules/lc-core-api/dist/api/permissions/create-permission";
import type { DeletePermissionLevelResponse } from "@laborchart-modules/lc-core-api/dist/api/permissions/delete-permission";
import type {
   FindPermissionLevelsStreamQueryParams,
   SerializedFindPermissionLevel,
} from "@laborchart-modules/lc-core-api/dist/api/permissions/find-permission";
import type {
   UpdatePermissionLevelPayload,
   UpdatePermissionLevelResponse,
} from "@laborchart-modules/lc-core-api/dist/api/permissions/update-permission";

export abstract class PermissionStore extends Store {
   static createPermissionLevel(
      permissionLevelData: CreatePermissionLevelPayload,
   ): StoreJsonResponse<CreatePermissionLevelResponse> {
      return this.requestJson({
         url: "/api/v3/permission-levels/",
         method: "POST",
         body: permissionLevelData,
      });
   }

   static deletePermissionLevel(
      permissionLevelId: string,
   ): StoreJsonResponse<DeletePermissionLevelResponse> {
      return this.requestJson({
         url: `/api/v3/permission-levels/${permissionLevelId}`,
         method: "DELETE",
      });
   }

   static findPermissionLevelsStream(
      query: FindPermissionLevelsStreamQueryParams,
   ): StoreStreamResponse<SerializedFindPermissionLevel> {
      return this.requestStream({
         url: "/api/v3/permission-levels/stream",
         query,
      });
   }

   static updatePermissionLevel(
      permissionLevelId: string,
      update: UpdatePermissionLevelPayload,
   ): StoreJsonResponse<UpdatePermissionLevelResponse> {
      return this.requestJson({
         url: `/api/v3/permission-levels/${permissionLevelId}`,
         method: "PATCH",
         body: update,
      });
   }
}

import template from "./people-gantt-config-pane.pug"
import ko from "knockout"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

## UI Assets ##
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class PeopleGanttConfigPane extends PopupPane
   constructor: (selections, applyCallback) ->
      assertArgs(arguments, Object, Function)
      super(template(), "Configure View")
      # Popup Properties

      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)

      @enableOverflow = ko.observable(true)

      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Reset Defaults"
      })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
      })

      @applyCallback = applyCallback

      @peopleSortOptions = [
         new DropDownItem("Name", "name")
         new DropDownItem("Employee Number", "employee-number")
         new DropDownItem("Job Title", "job-title")
      ]
      @selectePeopleSort = ko.observable()
      for option in @peopleSortOptions
         if option.value() == selections.peopleSort
            @selectePeopleSort(option)
            break
      @selectePeopleSort(@peopleSortOptions[0]) unless @selectePeopleSort()?

      @totalCellOptions = [
         new SegmentedControllerItem("Allocation", "allocation")
         new SegmentedControllerItem("Man-Days", "man-days")
         new SegmentedControllerItem("Hours", "hours")
      ]
      if @canViewProjectFinancials
         @totalCellOptions.push(new SegmentedControllerItem("Cost", "cost"))
         
      @selectedTotalCellOption = ko.observable()
      for option in @totalCellOptions
         if option.value() == selections.totalsUnit
            @selectedTotalCellOption(option)
            break
      @selectedTotalCellOption(@totalCellOptions[0]) unless @selectedTotalCellOption()?

      # Remove this property when the LD flag is removed (WFP-1231)
      @RESOURCE_GROUP_CHECK_ENABLED = LaunchDarklyBrowser.getFlagValue("gantt-group-filter-by-default") ? false

      # If LD flag enabled, default value is false (new default behavior), otherwise default value is true (traditional default behavior)
      @showResourcesNotInGroup = ko.observable(if @RESOURCE_GROUP_CHECK_ENABLED then selections.showResourcesNotInGroup else true)
      @showPositionName = ko.observable(selections.showPositionName ? false)
      @showProjectNumbers = ko.observable(selections.showProjectNumbers ? false)
      @showEmployeeNumber = ko.observable(selections.showEmployeeNumber ? false)

      @barSplitOptions = [
         new SegmentedControllerItem("Solid Bars", "solid")
         new SegmentedControllerItem("Working Day Splits", "split")
      ]
      @selectedBarSplit = ko.observable()
      for option in @barSplitOptions
         if option.value() == selections.barSplit
            @selectedBarSplit(option)
            break
      @selectedBarSplit(@barSplitOptions[0]) unless @selectedBarSplit()?

      @barColorOptions = [
         new DropDownItem("Project Color", "project-color")
         new DropDownItem("Allocation %", "allocation")
         new DropDownItem("Overtime", "overtime")
         new DropDownItem("Status", "status")
      ]

      @selectedBarColor = ko.observable(@barColorOptions[0])
      for option in @barColorOptions
         if option.value() == selections.barColor
            @selectedBarColor(option)
            break

      @projectSortOptions = [
         new DropDownItem("Name", "name")
         new DropDownItem("Project Number", "job-number")
         new DropDownItem("Earliest Assignment", "earliest-assignment")
         new DropDownItem("Earliest Start Date", "earliest-start-date")
         new DropDownItem("Earliest End Date", "earliest-end-date")
      ]
      @selectedProjectSort = ko.observable(@projectSortOptions[0])
      for option in @projectSortOptions
         if option.value() == selections.projectSort
            @selectedProjectSort(option)
            break

   applyChanges: =>
      data = {
         peopleSort: @selectePeopleSort().value()
         totalsUnit: @selectedTotalCellOption().value()
         showProjectNumbers: @showProjectNumbers()
         showResourcesNotInGroup: @showResourcesNotInGroup()
         showPositionName: @showPositionName()
         showEmployeeNumber: @showEmployeeNumber()
         barSplit: @selectedBarSplit().value()
         barColor: @selectedBarColor().value()
         projectSort: @selectedProjectSort().value()
      }
      @applyCallback(data)
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @applyCallback(null)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)



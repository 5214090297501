import { Flex, H1, Typography } from "@procore/core-react";
import React from "react";

const ExportHeader = () => (
   <Flex justifyContent="space-between">
      <H1>Table data</H1>
      <Typography>Printed on: {new Date().toString()}</Typography>
   </Flex>
);

export default ExportHeader;

interface Color {
   [key: string]: string;
}

const Color: Color = {
   LC_ORANGE: "hsl(19, 100%, 50%)",
   LC_ORANGE_DARKER: "hsl(19, 100%, 45%)",
   LC_ORANGE_DISABLED: "hsl(19, 100%, 85%)",
   LC_WHITE: "#ffffff",
   LC_NEAR_WHITE: "#eeeeee",
   LC_NEAR_BLACK: "#363d43",
};

export function getVisibleTextColor(hexcolor: string | undefined) {
   if (hexcolor) {
      hexcolor = hexcolor.toLowerCase();
      if (hexcolor === Color.LC_ORANGE || hexcolor === Color.LC_ORANGE_DARKER)
         return Color.LC_NEAR_WHITE;

      const r: number = parseInt(hexcolor.substring(1, 2), 16);
      const g: number = parseInt(hexcolor.substring(3, 2), 16);
      const b: number = parseInt(hexcolor.substring(5, 2), 16);
      const yiq: number = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) return Color.LC_NEAR_BLACK;

      return Color.LC_NEAR_WHITE;
   } else {
      return Color.LC_NEAR_BLACK;
   }
}

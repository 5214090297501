export enum ColumnWidthDefault {
   ADDRESS_1 = 100,
   ADDRESS_2 = 100,
   CATEGORY = 100,
   CITY_TOWN = 150,
   COUNTRY = 120,
   EMPLOYEE_NUMBER = 105,
   FLAG = 110,
   JOB_TITLE = 155,
   PERSON_NAME = 190,
   PERSON_STATUS = 105,
   PROJECT_NAME = 220,
   PROJECT_NUMBER = 130,
   PROJECT_STATUS = 105,
   SHORT_DATE = 120, // 130, 85
   STATE_PROVINCE = 120,
   STATUS = 120,
   SUBCATEGORY = 100,
   TAGS = 165,
   TIME = 125,
   TIMEZONE = 130,
   ZIPCODE = 100,
}

export enum ColumnWidthMin {
   ADDRESS_1 = 80,
   ADDRESS_2 = 80,
   CATEGORY = 90,
   CITY_TOWN = 80,
   COUNTRY = 80,
   EMPLOYEE_NUMBER = 90,
   FLAG = 90,
   JOB_TITLE = 140,
   PERSON_NAME = 170,
   PERSON_STATUS = 80,
   PROJECT_NAME = 170,
   PROJECT_NUMBER = 100,
   PROJECT_STATUS = 80,
   SHORT_DATE = 85,
   STATE_PROVINCE = 80,
   STATUS = 100,
   SUBCATEGORY = 100,
   TAGS = 125,
   TIME = 100,
   TIMEZONE = 120,
   ZIPCODE = 80,
}

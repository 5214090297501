import "./paragraph-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./paragraph-editor.pug";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";

const PARAGRAPH_CHARACTER_LIMIT = 6000;

export interface ParagraphEditorParams extends EditorComponentParams<string | null> {
   isRequired?: MaybeSubscribable<boolean>;
   isDisabled?: boolean;
}

export class ParagraphEditor extends AbstractFieldEditor<string | null> {
   readonly cancelText = pureComputed(() => {
      return this.isDisabled ? "Close" : "Cancel";
   });

   readonly canSave = pureComputed(() => {
      return !this.isSaving() && !this.validation() && this.hasChanged();
   });

   private readonly isDisabled: boolean;

   constructor(private readonly params: ParagraphEditorParams) {
      super(params, observable(unwrap(params.value)));
      this.isDisabled = params.isDisabled || false;
   }

   validate(value: string | null): ValidatedValue<string | null> {
      return !value || value.length <= PARAGRAPH_CHARACTER_LIMIT
         ? { valid: true, value }
         : { valid: false, error: `Exceeded character limit of: ${PARAGRAPH_CHARACTER_LIMIT}` };
   }

   static factory<T>(
      provider: (records: T[]) => ParagraphEditorParams,
   ): (records: T[]) => ComponentArgs<ParagraphEditorParams> {
      return (records) => ({
         name: "paragraph-editor",
         params: provider(records),
      });
   }
}

ko.components.register("paragraph-editor", {
   viewModel: ParagraphEditor,
   template: template(),
   synchronous: true,
});

import React from "react";
import { Box, Checkbox, FlexList, useI18nContext } from "@procore/core-react";
import { daysOfWeekAbbreviations } from "@/react/shared/constants";
import { ActionMode } from "@/react/prop-types";
import { Check, Ban } from "@procore/core-icons";

export const WorkDaysCheckboxes = (props: any) => {
   const { setSelectedWorkDays, setValues, value = {} } = props;
   const { view } = props.field.meta;
   const I18n = useI18nContext();

   const handleCheckboxChange = (dayIndex: number) => {
      const newVal = !value[dayIndex];
      const updatedValues = { ...value, [dayIndex]: newVal };

      setSelectedWorkDays(updatedValues);
      setValues("work_days", updatedValues);
   };

   return (
      <FlexList space="sm" wrap="wrap" marginTop="sm">
         {daysOfWeekAbbreviations.map((day, index) => {
            const renderContent = () => {
               if (view === ActionMode.READ) {
                  return (
                     <>
                        {value[index] ? <Check size="sm" /> : <Ban size="sm" />}
                        <span style={{ paddingLeft: "10px" }}>
                           {I18n.t(`views.company.workforce_planning.${day}`)}
                        </span>
                     </>
                  );
               } else {
                  return (
                     <Checkbox checked={value[index]} onChange={() => handleCheckboxChange(index)}>
                        {I18n.t(`views.company.workforce_planning.${day}`)}
                     </Checkbox>
                  );
               }
            };

            return (
               <Box key={day} display="flex" alignItems="center">
                  {renderContent()}
               </Box>
            );
         })}
      </FlexList>
   );
};

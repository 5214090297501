import template from "../../../templates/auto-message-config-pane.pug"
import { ObservableData } from "@/lib/utils/observable-data"
import { Format as FormatUtils } from "@/lib/utils/format"
import { DateUtils } from "@/lib/utils/date"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore as defaultStores } from "@/stores/default-store"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

export class AutoMessageConfigPane extends PopupPane
   constructor: (messageConfig) ->
      assertArgs(arguments, Function)
      super(template())
      # Popup Properties
      @title = ko.observable("Messaging")
      @enableOverflow = ko.observable(true)

      @leftActionBtn = ko.observable({
         callback: @disableMessaging
         text: "Disable"
      })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
      })

      @rightActionBtnEnabled = ko.pureComputed =>
         return (!@scheduleMessages() or
            (@scheduleMessages() and @scheduledDate()? and @scheduledTime()?))

      @messageConfig = messageConfig

      @sendInstantly = ko.observable(false)
      @saveToDraft = ko.observable(false)
      @scheduleMessages = ko.observable(false)

      @sendInstantly.subscribe (newVal) =>
         if newVal
            @saveToDraft(false)
            @scheduleMessages(false)
            @scheduledDate(null)
            @scheduledTime(null)
      @saveToDraft.subscribe (newVal) =>
         if newVal
            @sendInstantly(false)
            @scheduleMessages(false)
            @scheduledDate(null)
            @scheduledTime(null)
      @scheduleMessages.subscribe (newVal) =>
         if newVal
            @sendInstantly(false)
            @saveToDraft(false)

      @calendarPopupFrame = Popup.FrameType.ABOVE
      @calendarPopupArrow = Popup.ArrowLocation.BOTTOM_RIGHT

      @scheduledDate = ko.observable(null)
      @scheduledTime = ko.observable(null)
      @timeOptions = ko.observableArray(defaultStores.getTimeOptions(4.75))

   applyChanges: =>
      config = {}
      if @sendInstantly()
         config['context'] = 'sent'
      else if @saveToDraft()
         config['context'] = 'drafts'
      else if @scheduleMessages() and @scheduledDate()? and @scheduledTime()?
         config['context'] = 'scheduled'
         config['scheduledDetachedDay'] = DateUtils.getDetachedDay(@scheduledDate())
         config['scheduledTime'] = @scheduledTime().value()
      else
         return
      @messageConfig(config)
      @dismissHandler()

   disableMessaging: =>
      @messageConfig(null)
      @dismissHandler()


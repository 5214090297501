import type { CalendarModel, TaskModelConfig } from "@bryntum/gantt";
import "@bryntum/gantt/gantt.stockholm.css";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime/date";
import { timeParse } from "d3-time-format";
import { getCalendarName } from "./gantt-calendar";
import "./gantt-container.css";
import type {
   Equipment,
   GanttProject,
   GroupedTasksWrapper,
   Task,
   TaskLookup,
   noSubcategoryTasks,
} from "./prop-types";
import { TaskType } from "./prop-types";
import moment from "moment-timezone";
import { LaunchDarklyClient } from "@laborchart-modules/launch-darkly-browser/dist/client";

export const sortPriorityLevels = {
   [TaskType.PROJECT]: 0,
   [TaskType.ASSIGNMENT]: 1,
   [TaskType.EQUIPMENT_ASSIGNMENT]: 2,
   [TaskType.REQUEST]: 3,
   [TaskType.CATEGORY]: 4,
   [TaskType.SUBCATEGORY]: 5,
};

export const rawAssignmentToTask = ({
   assignment,
   person,
   project,
   jobTitles,
}: {
   assignment: any;
   person: any;
   project: any;
   jobTitles?: any[];
}) => {
   const dateParse = timeParse("%Y-%m-%d");
   const jobTitle =
      person.job_title ?? jobTitles?.find((jobTitle: any) => jobTitle.id == person.job_title_id);

   const startDate =
      typeof assignment.start_day === "string"
         ? dateParse(assignment.start_day)
         : assignment.start_day;
   let endDate =
      typeof assignment.end_day === "string" ? dateParse(assignment.end_day) : assignment.end_day;
   endDate = new Date(endDate.setHours(23, 59, 59, 999));

   const firstName = person.first_name ?? person.name.first;
   const lastName = person.last_name ?? person.name.last;

   const assignmentTask: Task = {
      id: assignment.id,
      type: TaskType.ASSIGNMENT,
      startDate,
      endDate,
      startTime: assignment.start_time,
      endTime: assignment.end_time,
      name: `${firstName} ${lastName}`,
      projectId: project.id,
      projectColor: project.color,
      projectName: project.name,
      projectJobNumber: project.job_number,
      parentCategoryId: assignment.cost_code_id,
      parentCategoryName: assignment.cost_code?.name,
      parentSubcategoryId: assignment.label_id,
      parentSubcategoryName: assignment.label?.name,
      jobTitleColor: jobTitle?.color,
      jobTitleName: jobTitle?.name,
      workDays: assignment.work_days,
      calendar: getCalendarName(assignment, TaskType.ASSIGNMENT) as CalendarModel & string,
      manuallyScheduled: false,
      projectConstraintResolution: "ignore",
      constraintType: "startnoearlierthan",
      constraintDate: startDate,
      sortPriority: sortPriorityLevels[TaskType.ASSIGNMENT],
      categoryId: assignment.cost_code_id ?? null,
      subcategoryId: assignment.label_id ?? null,
      resourceId: person.id,
      resourceName: person.first_name + " " + person.last_name,
   };

   return assignmentTask;
};

export const rawEquipmentAssignmentToTask = ({
   equipmentItem,
   project,
   equipment,
}: {
   equipmentItem: any;
   project: any;
   equipment: Equipment[];
}) => {
   const dateParse = timeParse("%Y-%m-%d");
   const currentEquipment = equipment.find(
      (item: Equipment) => item.id == equipmentItem.resource_id,
   );

   const startDate =
      typeof equipmentItem.start_day === "string"
         ? dateParse(equipmentItem.start_day)
         : equipmentItem.start_day;
   let endDate =
      typeof equipmentItem.end_day === "string"
         ? dateParse(equipmentItem.end_day)
         : equipmentItem.end_day;
   endDate = new Date(endDate.setHours(23, 59, 59, 999));

   const equipmentAssignmentTask: Task = {
      id: equipmentItem.id,
      type: TaskType.EQUIPMENT_ASSIGNMENT,
      startDate,
      endDate,
      startTime: equipmentItem.start_time,
      endTime: equipmentItem.end_time,
      name: currentEquipment?.name ?? "Equipment test name",
      projectId: project.id,
      projectColor: project.color,
      projectName: project.name,
      projectJobNumber: project.job_number,
      parentCategoryId: equipmentItem.cost_code_id,
      parentCategoryName: equipmentItem.cost_code?.name,
      parentSubcategoryId: equipmentItem.label_id,
      parentSubcategoryName: equipmentItem.label?.name,
      jobTitleName: currentEquipment?.type.name ?? "Equipment test type",
      workDays: equipmentItem.work_days,
      calendar: getCalendarName(equipmentItem, TaskType.EQUIPMENT_ASSIGNMENT) as CalendarModel &
         string,
      manuallyScheduled: false,
      projectConstraintResolution: "ignore",
      constraintType: "startnoearlierthan",
      sortPriority: sortPriorityLevels[TaskType.EQUIPMENT_ASSIGNMENT],
      categoryId: equipmentItem.cost_code_id ?? null,
      subcategoryId: equipmentItem.label_id ?? null,
   };

   return equipmentAssignmentTask;
};

export const rawRequestToTask = ({
   request,
   project,
   jobTitles,
}: {
   request: any;
   project: any;
   jobTitles?: any[];
}) => {
   try {
      const dateParse = timeParse("%Y-%m-%d");
      const jobTitle =
         request.job_title ??
         jobTitles?.find(
            (jobTitle: any) => jobTitle.id == (request.job_title_id ?? request.position_id),
         );

      const isDetachedDay =
         typeof request.start_day === "number" && String(request.start_day).length === 8;
      if (isDetachedDay) {
         request.start_day = getAttachedDate(request.start_day);
         request.end_day = getAttachedDate(request.end_day);
      } else if (typeof request.start_day === "string" && typeof request.end_day === "string") {
         request.start_day = dateParse(request.start_day)!;
         request.end_day = dateParse(request.end_day)!;
      } else {
         request.start_day = new Date(request.start_day);
         request.end_day = new Date(request.end_day);
      }

      const startDate = request.start_day;
      const endDate = new Date(request.end_day.setHours(23, 59, 59, 999)); // request bar should end at the end of the day

      const requestTask: Task = {
         id: request.id,
         type: TaskType.REQUEST,
         startDate,
         endDate,
         startTime: request.start_time,
         endTime: request.end_time,
         name: "Request",
         projectId: project.id,
         projectColor: project.color,
         projectName: project.name,
         projectJobNumber: project.job_number,
         parentCategoryId: request.cost_code_id,
         parentCategoryName: request.cost_code?.name,
         parentSubcategoryId: request.label_id,
         parentSubcategoryName: request.label?.name,
         jobTitleColor: jobTitle?.color,
         jobTitleName: jobTitle?.name,
         workDays: request.work_days,
         calendar: getCalendarName(request, TaskType.REQUEST) as CalendarModel & string,
         manuallyScheduled: false,
         projectConstraintResolution: "ignore",
         constraintType: "startnoearlierthan",
         constraintDate: startDate,
         sortPriority: sortPriorityLevels[TaskType.REQUEST],
         categoryId: request.cost_code_id ?? null,
         subcategoryId: request.label_id ?? null,
      };
      return requestTask;
   } catch (error) {
      console.error("error in rawRequestToTask:", error);
   }
};

function createSegment(newTask: Task) {
   const duration = moment(newTask.endDate).diff(moment(newTask.startDate), "days");
   const segment = {
      ...newTask,
      id: newTask.id as string,
      startDate: newTask.startDate,
      duration: duration > 0 ? duration : 1,
   } as any;

   delete segment.calendar;
   delete segment.constraintDate;

   return segment;
}

function groupByResource(tasks: Task[]) {
   const unasignedTasks: Task[] = [];

   const groupedByResource: Record<string, Task[]> = tasks.reduce((acc: any, task: Task) => {
      if (!task.resourceId) {
         unasignedTasks.push(task);
         return acc;
      }
      if (!acc[task.resourceId]) {
         acc[task.resourceId] = [];
      }
      acc[task.resourceId].push(task);
      return acc;
   }, {});

   const groupedChildren: Task[] = Object.values(groupedByResource).map((taskSegments: Task[]) => {
      const mainTask = taskSegments[0];
      if (taskSegments.length === 1) return mainTask;

      const segments = taskSegments
         .map((task) => createSegment(task))
         .sort((a, b) => ((a.startDate as Date) < (b.startDate as Date) ? -1 : 1));

      let soonestTaskStartDate = segments[0].startDate as Date;
      let latestTaskEndDate = segments[segments.length - 1].endDate as Date;

      segments.forEach((segment) => {
         if (soonestTaskStartDate > segment.startDate) {
            soonestTaskStartDate = segment.startDate;
         }
         if (latestTaskEndDate < segment.endDate) {
            latestTaskEndDate = segment.endDate;
         }
      });

      return {
         ...mainTask,
         calendar: mainTask.resourceId as any,
         constraintDate: null,
         startDate: soonestTaskStartDate,
         endDate: latestTaskEndDate,
         segments: taskSegments
            .map((task) => createSegment(task))
            .sort((a, b) => ((a.startDate as Date) < (b.startDate as Date) ? -1 : 1)),
      };
   });
   groupedChildren.push(...unasignedTasks);
   return groupedChildren;
}

export function groupTasks(
   project: GanttProject,
   people: any,
   jobTitles: any[],
   equipment: Equipment[],
   expandedTasks: Set<string>,
): GroupedTasksWrapper {
   let earliestTaskStartDate: Date | null = null; //new Date(project.start_date);
   let latestTaskEndDate: Date | null = null; //new Date(project.est_end_date);
   const categories = project.cost_codes;
   const assignments = project.assignments;
   const requests = project.requests;
   const equipmentAssignments = project.equipment_assignments ?? [];

   // Step 1: Create a nested lookup table for tasks
   const taskLookup: TaskLookup = {};
   let noCategoryTasks: Task[] = [];
   const noSubcategoryTasks: noSubcategoryTasks = {};

   const group = (task: Task) => {
      const { categoryId, subcategoryId } = task;

      if (categoryId === null) {
         noCategoryTasks.push(task);
      } else {
         if (!taskLookup[categoryId]) {
            taskLookup[categoryId] = {};
         }
         if (subcategoryId === null) {
            if (!noSubcategoryTasks[categoryId]) {
               noSubcategoryTasks[categoryId] = [];
            }
            noSubcategoryTasks[categoryId].push(task);
         } else {
            if (!taskLookup[categoryId][subcategoryId]) {
               taskLookup[categoryId][subcategoryId] = [];
            }
            taskLookup[categoryId][subcategoryId].push(task);
         }
      }
   };

   assignments.forEach((assignment: any) => {
      const person = people.find((person: any) => person.id == assignment.resource_id) as any;

      if (person == null) {
         console.error("person not found for assignment:", assignment);
      } else {
         const assignmentTask = rawAssignmentToTask({ assignment, person, project, jobTitles });
         const startDate = assignmentTask.startDate as Date;
         const endDate = assignmentTask.endDate as Date;

         if (earliestTaskStartDate == null || startDate < earliestTaskStartDate)
            earliestTaskStartDate = startDate;
         if (latestTaskEndDate == null || endDate > latestTaskEndDate) latestTaskEndDate = endDate;

         group(assignmentTask);
      }
   });

   requests.forEach((request: any) => {
      const requestTask = rawRequestToTask({ request, project, jobTitles });
      const startDate = requestTask!.startDate as Date;
      const endDate = requestTask!.endDate as Date;

      if (earliestTaskStartDate == null || startDate < earliestTaskStartDate)
         earliestTaskStartDate = startDate;
      if (latestTaskEndDate == null || endDate > latestTaskEndDate) latestTaskEndDate = endDate;
      group(requestTask!);
   });

   equipmentAssignments.forEach((equipmentItem: any) => {
      const equipmentAssignmentTask = rawEquipmentAssignmentToTask({
         equipmentItem,
         project,
         equipment,
      });
      const startDate = equipmentAssignmentTask!.startDate as Date;
      const endDate = equipmentAssignmentTask!.endDate as Date;

      if (earliestTaskStartDate == null || startDate < earliestTaskStartDate)
         earliestTaskStartDate = startDate;
      if (latestTaskEndDate == null || endDate > latestTaskEndDate) latestTaskEndDate = endDate;
      group(equipmentAssignmentTask);
   });

   // Step 2: Organize tasks into the desired structure
   const groupedCategories =
      categories
         ?.sort((prev, next) => prev.sequence - next.sequence)
         ?.map((category) => {
            const groupedSubcategories = category.labels
               ?.sort((prev, next) => prev.sequence - next.sequence)
               .map((subcategory) => {
                  if (subcategory == null) {
                     console.error(
                        "subcategory is null. supposed to belong to category:",
                        category,
                     );
                  }
                  let children =
                     (taskLookup[category.id] && taskLookup[category.id][subcategory.id]) || [];

                  if (LaunchDarklyClient.getFlagValue("gantt-task-splitting")) {
                     children = groupByResource(children);
                  }
                  return {
                     id: subcategory?.id,
                     archived: subcategory?.archived,
                     categoryId: category.id,
                     name: subcategory?.name ?? null,
                     subcategoryName: subcategory?.name ?? null,
                     categoryName: category.name,
                     projectId: project.id,
                     projectColor: project.color,
                     parentCategoryId: category.id,
                     parentCategoryName: category.name,
                     projectName: project.name,
                     projectJobNumber: project.job_number,
                     projectConstraintResolution: "ignore",
                     type: TaskType.SUBCATEGORY,
                     expanded: expandedTasks.has(subcategory?.id),
                     children,
                     sequence: subcategory.sequence,
                     sortPriority: sortPriorityLevels[TaskType.SUBCATEGORY],
                  };
               })
               .filter((subcategory) => subcategory.archived === false);

            let children = (noSubcategoryTasks[category.id] ?? []).concat(
               groupedSubcategories as any,
            );
            if (LaunchDarklyClient.getFlagValue("gantt-task-splitting")) {
               children = groupByResource(children);
            }

            return {
               id: category.id,
               archived: category.archived,
               categoryId: category.id,
               name: category.name,
               projectId: project.id,
               projectColor: project.color,
               projectName: project.name,
               projectJobNumber: project.job_number,
               projectConstraintResolution: "ignore",
               type: TaskType.CATEGORY,
               expanded: expandedTasks.has(category?.id),
               children,
               sequence: category.sequence,
               sortPriority: sortPriorityLevels[TaskType.CATEGORY],
            };
         })
         .filter((category) => category.archived === false) ?? [];

   if (LaunchDarklyClient.getFlagValue("gantt-task-splitting") && noCategoryTasks.length > 0) {
      noCategoryTasks = groupByResource(noCategoryTasks);
   }

   return {
      tasks: noCategoryTasks.concat(groupedCategories as any),
      earliestTaskStartDate,
      latestTaskEndDate,
   };
}

/* istanbul ignore next */
export function lightenHexColor(hex: string, amount: number) {
   if (!hex) return;

   if (hex.indexOf("#") !== 0) {
      hex = "#" + hex;
   }

   const num = parseInt(hex.slice(1), 16);
   let r = num >> 16;
   let g = (num >> 8) & 0x00ff;
   let b = num & 0x0000ff;

   // Scale each component by the percentage amount towards 255
   r = Math.floor(r + (255 - r) * amount);
   g = Math.floor(g + (255 - g) * amount);
   b = Math.floor(b + (255 - b) * amount);

   // Combine back into a hex string
   return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
}

export function getColorShades(color: string) {
   const baseColor = color;
   const lightColor = lightenHexColor(baseColor, 0.4) ?? baseColor;
   const lightestColor = lightenHexColor(baseColor, 0.8) ?? baseColor;

   return [baseColor, lightColor, lightestColor];
}

export function createProjectTask(project: GanttProject, expandedTasks: Set<string>) {
   const isExpanded = localStorage.getItem("gantt-expanded") === "true";

   return {
      id: project.id,
      name: project.name,
      startDate: new Date(project.start_date),
      endDate: new Date(project.est_end_date),
      // Adding prefix of "project" to "projectColor" to make it consistent with assignments & requests
      projectColor: project.color,
      projectJobNumber: project.job_number,
      dailyStartTime: project.daily_start_time,
      dailyEndTime: project.daily_end_time,
      type: TaskType.PROJECT,
      expanded: expandedTasks.has(project.id) ?? isExpanded,
      resizable: true,
      requestsNumber: project.requests?.length ?? 0,
      manuallyScheduled: true,
      constraintType: null,
      projectAssignments: project.assignments,
      wageOverrides: project.wage_overrides,
      workedHours: "main",
      sortPriority: sortPriorityLevels[TaskType.PROJECT],
   } as Partial<TaskModelConfig>;
}

import "./comment-action-cell.styl";
import type { GridActionProvider, GridActionProviderParams, GridCellFactory } from "../grid-column";
import { GridActionResult, GridActionSource, GridCursorState } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./comment-action-cell.pug";
import type { MaybeObservable, Observable } from "knockout";
import ko, { observable, unwrap } from "knockout";

export interface CommentActionCellParams<T> {
   commentCount: MaybeObservable<number>;
   data: T;
   isDisabled?: (data: T) => boolean;
   onClick: (data: T) => void;
}

export class CommentActionCell<T> {
   readonly commentCount: Observable<number>;
   readonly isDisabled: boolean;

   constructor(private readonly params: CommentActionCellParams<T>) {
      this.commentCount = observable(unwrap(params.commentCount));
      this.isDisabled = params.isDisabled ? params.isDisabled(params.data) : false;
   }

   onClick = (): void => {
      this.params.onClick(this.params.data);
   };

   static factory<T>(
      formatter: (data: T) => CommentActionCellParams<T>,
   ): GridCellFactory<T, CommentActionCellParams<T>> {
      return (data: T): GridCell<CommentActionCellParams<T>> => {
         return {
            component: {
               name: "comment-action-cell",
               params: formatter(data),
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(formatter: (data: T) => CommentActionCellParams<T>): {
      cellFactory: GridCellFactory<T>;
      cursorStateProvider: () => GridCursorState.ACTIONABLE;
      actionProvider: GridActionProvider<T>;
   } {
      return {
         cellFactory: CommentActionCell.factory(formatter),
         cursorStateProvider: () => GridCursorState.ACTIONABLE,
         actionProvider: ({ row, source }: GridActionProviderParams<T>) => {
            const params = formatter(row);
            if (source == GridActionSource.ENTER_KEY) params.onClick(row);
            return GridActionResult.REMAIN_FOCUSED;
         },
      };
   }
}

ko.components.register("comment-action-cell", {
   viewModel: CommentActionCell,
   template: template(),
   synchronous: true,
});

import React from "react";
import {
   FileDownloadToastCard,
   FileDownloadToastCardContainer,
   FileDownloadToastContent,
} from "./file-download-toast.styl";
import { Button, FlexList, Spinner, Typography, useI18nContext } from "@procore/core-react";
import { GeneratedReportStore } from "@/stores/generated-report-store.core";
import { Check, Error, FileList, FileQuestionMark } from "@procore/core-icons";

const GET_REPORT_REFRESH_INTERVAL = 3000;
const GET_REPORT_TOTAL_TIMEOUT = 60000;
const GET_REPORT_RETRY_LIMIT = Math.floor(GET_REPORT_TOTAL_TIMEOUT / GET_REPORT_REFRESH_INTERVAL);

type ReportStatus = "pending" | "failed" | "completed";

const FileDownloadAction = (props: {
   status: ReportStatus;
   item: { id: string; name: string };
}) => {
   const I18n = useI18nContext();
   const { status, item } = props;

   async function onDownload() {
      try {
         await GeneratedReportStore.download(item.id);
      } catch (err) {
         console.error(`Error downloading generated report ${item.id}. Err: ${err}`);
      }
   }

   if (status === "failed") {
      return (
         <FlexList alignItems="center">
            <FileQuestionMark color="grey" />
            <Typography>
               {I18n.t("views.company.workforce_planning.file_download_toast.failed", {
                  report_name: item.name,
               })}
            </Typography>
            <Error color="red" />
         </FlexList>
      );
   }

   if (status === "completed") {
      return (
         <FlexList alignItems="center">
            <FileList color="red" />
            <Typography>{item.name}</Typography>
            <Button variant="secondary" onClick={onDownload}>
               {I18n.t("views.company.workforce_planning.download")}
            </Button>
            <Check color="green" />
         </FlexList>
      );
   }

   return (
      <FlexList alignItems="center">
         <FileQuestionMark color="grey" />
         <Typography>
            {I18n.t("views.company.workforce_planning.file_download_toast.in_progress", {
               report_name: item.name,
            })}
         </Typography>
         <Spinner size="md" />
      </FlexList>
   );
};

export const FileDownloadToastItem = (props: any) => {
   const { item } = props;
   const [reportStatus, setReportStatus] = React.useState<ReportStatus>("pending");

   async function getReportStatus(count: number) {
      try {
         if (count > GET_REPORT_RETRY_LIMIT) {
            setReportStatus("failed");
         } else {
            const result = (await GeneratedReportStore.get(item.id).payload).data;
            if (result.report_status === "pending") {
               setTimeout(() => {
                  getReportStatus(count + 1);
               }, GET_REPORT_REFRESH_INTERVAL);
            } else if (result.report_status === "failed") {
               setReportStatus("failed");
            } else {
               setReportStatus("completed");
            }
         }
      } catch (err) {
         console.error("ERR: ", err);
      }
   }
   React.useEffect(() => {
      getReportStatus(1);
   }, [item]);

   return (
      <FileDownloadToastCardContainer>
         <FileDownloadToastCard data-qa="file-upload-toast-card">
            <FileDownloadToastContent>
               <FileDownloadAction item={item} status={reportStatus} />
            </FileDownloadToastContent>
         </FileDownloadToastCard>
      </FileDownloadToastCardContainer>
   );
};

import "./tag-chip.styl";
import template from "./tag-chip.pug";
import type { MaybeSubscribable } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "../common";

export enum TagExpirationState {
   NOT_EXPIRING_SOON,
   EXPIRING_SOON,
   EXPIRED,
}

export enum TagSize {
   SMALL = "small",
   MEDIUM = "medium",
}

export interface TagChipParams {
   abbreviation: MaybeSubscribable<string>;
   color: MaybeSubscribable<string>;
   expirationState?: MaybeSubscribable<TagExpirationState>;
   size?: MaybeSubscribable<TagSize>;
}

export class TagChip {
   readonly iconClass = pureComputed(() => {
      const state = unwrap(this.params.expirationState || TagExpirationState.NOT_EXPIRING_SOON);
      const classes: string[] = [`tag-chip__icon--${this.size()}`];
      switch (state) {
         case TagExpirationState.EXPIRING_SOON:
            classes.push("sprite", "icon-caution-triangle");
            break;
         case TagExpirationState.EXPIRED:
            classes.push("sprite", "icon-warning-triangle");
            break;
      }
      return classes.join(" ");
   });
   readonly size = pureComputed(() => {
      return unwrap(this.params.size || TagSize.MEDIUM);
   });

   constructor(readonly params: TagChipParams) {}

   static factory(params: TagChipParams): ComponentArgs<TagChipParams> {
      return {
         name: "tag-chip",
         params,
      };
   }
}

ko.components.register("tag-chip", {
   viewModel: TagChip,
   template: template(),
   synchronous: true,
});

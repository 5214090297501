import "./linked-text-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./linked-text-cell.pug";
import ko from "knockout";
import { requestContext } from "@/stores/common/request-context";

export interface LinkedTextCellParams {
   href: string | null;
   text: string;
   isItalicized?: boolean;
}

export class LinkedTextCell {
   readonly href: LinkedTextCellParams["href"];
   readonly isItalicized: Exclude<LinkedTextCellParams["isItalicized"], undefined>;
   readonly text: LinkedTextCellParams["text"];

   constructor(params: LinkedTextCellParams) {
      this.href = params.href;
      this.isItalicized = params.isItalicized ?? false;
      this.text = params.text;
   }

   static factory<T>(
      formatter: (data: T) => LinkedTextCellParams,
   ): GridCellFactory<T, LinkedTextCellParams> {
      return (data: T): GridCell<LinkedTextCellParams> => {
         return {
            component: {
               name: "linked-text-cell",
               params: formatter(data),
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => LinkedTextCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
      urlProvider: (data: T) => string | null;
   } {
      return {
         cellFactory: LinkedTextCell.factory(provider),
         copyProvider: (data: T) => provider(data).text,
         urlProvider: (data: T) => requestContext.basePath + provider(data).href,
      };
   }
}

ko.components.register("linked-text-cell", {
   viewModel: LinkedTextCell,
   template: template(),
   synchronous: true,
});

import type { Observable } from "knockout";
import { observable, observableArray } from "knockout";
import type { GridStore, RowBase } from "./grid-store";
import { LoadingState } from "./grid-store";

/** `GridStore` that's always in an error state. */
export class AlwaysErrorGridStore implements GridStore<RowBase> {
   readonly rows = observableArray();
   readonly loadingState = observable(LoadingState.NONE);
   readonly hasPreviousRows = observable(false);
   readonly hasNextRows = observable(false);
   readonly error: Observable<string | null>;

   constructor(error: string) {
      this.error = observable<string | null>(error);
   }

   setFirstVisibleRow(): void {}
   loadInitialRows(): void {}
   loadPreviousRows(): void {}
   loadNextRows(): void {}
}

import { getDetachedDay, getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import type { CustomField } from "@/react/prop-types";
import { processCustomFields } from "@/react/shared/custom-field-utils";
import type { AssignmentFormValues, OverTimeRates } from "./assignment/types";
import type { RequestFormValues } from "./request/types";
import type {
   ActivitiesResponseProps,
   BaggageTimeKey,
   CommonPayloadForRequestAndAssignment,
   ProjectOptions,
} from "./types";
import { timeOptions } from "@/lib/utils/timezones";
import { dayNameToNumberMap, numberToDayNameMap } from "@/react/shared/constants";
import Resizer from "react-image-file-resizer";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import type { AttachmentsData } from "./attachments/types";
import type { ActivityCategory } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { ActivityEntityType } from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";
import type { DateTimeOptions } from "@procore/globalization-toolkit";
import { CurrencyFormatter, DateTimeFormatter } from "@procore/globalization-toolkit";
import { translationsLabelsActivityCard } from "./constants";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import {
   renderAdd,
   renderChange,
   renderColorAdd,
   renderColorChange,
   renderColorRemove,
   renderRemove,
} from "./activities/activity-helper";

export const getCommonFieldsForRequestAndAssignment = (
   values: AssignmentFormValues | RequestFormValues,
   customFields: CustomField[] | null,
): CommonPayloadForRequestAndAssignment => ({
   project_id: values.project!.id,
   category_id: values.category?.id ?? null,
   subcategory_id: values.subcategory?.id ?? null,
   start_day: getDetachedDay(new Date(values.start_date!)),
   end_day: getDetachedDay(new Date(values.end_date!)),
   start_time: values.assignment_by_time ? values.start_time.id : null,
   end_time: values.assignment_by_time ? values.end_time.id : null,
   percent_allocated: values.assignment_by_allocation ? values.percent_allocated : null,
   status_id: values.status?.id ?? null,
   work_days: values.work_days,
   custom_fields: customFields ? processCustomFields(customFields, values) : {},
   instruction_text: values.instruction_text ?? null,
   work_scope_text: values.work_scope_text ?? null,
   comments: values.comments
      ? [
           {
              author_id: values.author_id!,
              content: values.comments,
           },
        ]
      : undefined,
});

export const getTimeOptionsFromProject = (
   selectedItem: ProjectOptions | undefined,
   defaultTime: number,
   timeKey: BaggageTimeKey,
) => {
   if (selectedItem) {
      const timeId = selectedItem.baggage[timeKey] ?? defaultTime;
      const timeLabel = timeOptions.find(({ id }) => id === timeId)?.label;
      return { id: timeId, label: timeLabel };
   }
};

export const getTotalHours = (endTime: number, startTime: number): number => {
   const totalHours = endTime >= startTime ? endTime - startTime : 24 + endTime - startTime;
   return totalHours;
};

// Function to transform overtime rates to use numeric keys
export const transformOvertimeRates = (overtimeRates: OverTimeRates) => {
   const transformedRates: { [key: number]: number } = {};

   for (const [dayName, rate] of Object.entries(overtimeRates)) {
      const dayNumber =
         dayNameToNumberMap[dayName.toLowerCase() as keyof typeof dayNameToNumberMap];
      // Check if dayNumber is not undefined (include 0)
      if (dayNumber !== undefined) {
         // If rate is null or undefined, set it to 0
         transformedRates[dayNumber] = rate ?? 0;
      }
   }

   return transformedRates;
};

// Function to reverse transform overtime rates to use day names
export const reverseTransformOvertimeRates = (transformedRates: { [key: number]: number }) => {
   const originalRates: { [key: string]: number } = {};

   for (const [dayNumber, rate] of Object.entries(transformedRates)) {
      const dayName = numberToDayNameMap[Number(dayNumber) as keyof typeof numberToDayNameMap];

      // Check if dayName is defined in the map
      if (dayName !== undefined) {
         // Assign to the original structure using day names
         originalRates[dayName] = rate ?? 0;
      }
   }

   return originalRates;
};

export const getFileDimensions = (file: string) => {
   return new Promise<{ width: number; height: number }>((resolve) => {
      const img = new Image();
      img.src = file;
      img.addEventListener("load", () => resolve({ width: img.width, height: img.height }));
   });
};

export const resizeFile = (
   file: File,
   maxSize: number,
   minSize?: number,
   outputType: string = "file",
) => {
   return new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         maxSize,
         maxSize,
         "png",
         100,
         0,
         (uri) => {
            resolve(uri);
         },
         outputType,
         minSize ?? undefined,
         minSize ?? undefined,
      );
   });
};

export const readFile = (file: File) => {
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
   });
};

export const readFileFromUrl = async (name: string, url: string) => {
   const response = await fetch(url);
   const blob = await response.blob();
   return new File([blob], name ?? "file_name.png", {
      type: blob.type,
   });
};

export function prepareAttachmentsPayload(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   const payload: AttachmentsData = {
      attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
   };

   return payload;
}

export function getUniqueAttachmentIds(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   // Get array of attachment IDs from attachments by user
   const addedAttachmentIds = attachments.map((attachment) => attachment.id);

   // addedAttachmentIds, and convert to Set for uniqueness to remove duplicates if any
   let uniqueAttachmentIds: any = new Set([...addedAttachmentIds]);

   // Remove IDs that are in deletedAttachmentIds array
   deletedAttachmentIds.forEach((id) => {
      uniqueAttachmentIds.delete(id);
   });

   // Convert Set back to array
   uniqueAttachmentIds = Array.from(uniqueAttachmentIds);

   return uniqueAttachmentIds;
}

export const prepareActivitiesPayload = (
   entityId: string,
   entityType: string,
   includedCategories: string[] | string,
   limitActivities: number,
   nextStartingAfter?: string,
) => {
   const payload = {
      entity_id: entityId,
      entity_type: entityType as ActivityEntityType,
      included_categories: includedCategories as ActivityCategory[],
      limit: limitActivities,
      starting_after: nextStartingAfter,
   };
   return payload;
};

export const formatDate = (date?: number) => {
   if (!date) return "";
   const dateTimeOptions = {
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as DateTimeOptions);
   const dateFull = getAttachedDate(date);
   return dateFormatter.formatDateTime(dateFull);
};

export const formatCurrency = (value?: number, I18n?: any): string => {
   if (!value) {
      return "";
   }
   const currencyOptions = { locale: I18n.currentLocale(), currencyIsoCode: "USD" };
   const currencyFormatter = new CurrencyFormatter(currencyOptions);
   return currencyFormatter.formatCurrency(value);
};

export const getTranslationLabel = (label: string, I18n: any): string => {
   const translationItem = translationsLabelsActivityCard.find((item) => item.name === label);
   return translationItem ? I18n.t(translationItem.translation) : label;
};

export const customFieldsCard = (data: ActivitiesResponseProps, I18n: any) => {
   const { custom_field_name, custom_field_type, new_val, old_val } = data.meta ?? {};

   if (!data.meta) return null;

   switch (custom_field_type) {
      case CustomFieldType.TEXT:
      case CustomFieldType.NUMBER:
      case CustomFieldType.SELECT:
      case CustomFieldType.MULTI_SELECT:
         if (new_val !== null && old_val !== null)
            return renderChange(custom_field_name as string, I18n, old_val, new_val);
         if (new_val === null && old_val !== undefined)
            return renderRemove(custom_field_name as string, old_val, I18n);
         if (old_val === null && new_val !== undefined)
            return renderAdd(custom_field_name as string, new_val, I18n);
         break;
      case CustomFieldType.DATE:
         if (new_val !== null && old_val !== null)
            return renderChange(
               custom_field_name as string,
               I18n,
               formatDate(old_val as number),
               formatDate(new_val as number),
            );
         if (new_val === null && old_val !== null)
            return renderRemove(custom_field_name as string, formatDate(old_val as number), I18n);
         if (old_val === null && new_val !== null)
            return renderAdd(custom_field_name as string, formatDate(new_val as number), I18n);
         break;
      case CustomFieldType.BOOL: {
         const yesValue = I18n.t("views.company.workforce_planning.boolean_values.yes_value");
         const noValue = I18n.t("views.company.workforce_planning.boolean_values.no_value");
         if (new_val !== null && old_val !== null) {
            return renderChange(
               custom_field_name as string,
               I18n,
               old_val ? yesValue : noValue,
               new_val ? yesValue : noValue,
            );
         }
         if (new_val === null && old_val !== null) {
            return renderRemove(custom_field_name as string, old_val ? yesValue : noValue, I18n);
         }
         if (old_val === null && new_val !== null) {
            return renderAdd(custom_field_name as string, new_val ? yesValue : noValue, I18n);
         }
         break;
      }
      case CustomFieldType.CURRENCY:
         if (new_val !== null && old_val !== null)
            return renderChange(
               custom_field_name as string,
               I18n,
               formatCurrency(old_val as number, I18n),
               formatCurrency(new_val as number, I18n),
            );
         if (new_val === null && old_val !== null)
            return renderRemove(
               custom_field_name as string,
               formatCurrency(old_val as number, I18n),
               I18n,
            );
         if (old_val === null && new_val !== null)
            return renderAdd(
               custom_field_name as string,
               formatCurrency(new_val as number, I18n),
               I18n,
            );
         break;
      case CustomFieldType.HEX_COLOR:
         if (new_val !== null && old_val !== null)
            return renderColorChange(
               custom_field_name as string,
               I18n,
               old_val as string,
               new_val as string,
            );
         if (new_val === null && old_val !== undefined)
            return renderColorRemove(custom_field_name as string, old_val as string, I18n);
         if (old_val === null && new_val !== undefined)
            return renderColorAdd(custom_field_name as string, new_val as string, I18n);
         break;
      default:
         return null;
   }
};

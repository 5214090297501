import { observable, observableArray } from "knockout";
import type { GridStore, RowBase } from "./grid-store";
import { LoadingState } from "./grid-store";

/** `GridStore` that's always in a loading state.. */
export class AlwaysLoadingGridStore implements GridStore<RowBase> {
   readonly rows = observableArray();
   readonly loadingState = observable(LoadingState.INITIAL);
   readonly hasPreviousRows = observable(false);
   readonly hasNextRows = observable(false);

   setFirstVisibleRow(): void {}
   loadInitialRows(): void {}
   loadPreviousRows(): void {}
   loadNextRows(): void {}
}

import "./permission-user-list-pane.styl"
import template from "./permission-user-list-pane.pug"
import { router } from "@/lib/router"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

export class PermissionUserListPane extends ModalPane
   constructor: (userData) ->
      assertArgs(arguments, arrayOf(Object))
      super("Permission's Users", "", template())

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)

      @users = ko.observableArray userData.sort (a, b) ->
         aFull = "#{a.name.first} #{a.name.last}"
         bFull = "#{b.name.first} #{b.name.last}"
         if a < b
            return -1
         else if a > b
            return 1
         
         return 0

   navigateToPerson: (person) =>
      return unless @canViewPeople
      modalManager.modalFinished()
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{person.id}")

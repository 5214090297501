import "./reset-password-pane.styl"
import template from "./reset-password-pane.pug"
import { router } from "@/lib/router"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { peopleStore } from "@/stores/people-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

export class ResetPasswordPane extends ModalPane
   constructor: () ->
      super("Reset Password", "Reset", template())

      @currentPassword = ko.observable()
      @newPassword = ko.observable()
      @confirmPassword = ko.observable()

      @isActionEnabled = ko.pureComputed =>
         return (@currentPassword()? and @newPassword()? and (@newPassword() != @currentPassword()) and
         (@newPassword() == @confirmPassword()) and @validPass())

      @upperCaseValid = ko.pureComputed =>
         return false unless @newPassword()?
         return @newPassword().match(/^(?=.*[A-Z])/)?

      @lowerCaseValid = ko.pureComputed =>
         return false unless @newPassword()?
         return @newPassword().match(/^(?=.*[a-z])/)?

      @numberValid = ko.pureComputed =>
         return false unless @newPassword()?
         return @newPassword().match(/^(?=.*[0-9])/)?

      @lengthValid = ko.pureComputed =>
         return false unless @newPassword()?
         return @newPassword().length >= 8

      @validPass = ko.pureComputed =>
         return false unless @newPassword()?
         return @newPassword().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)?

      @confirmInputInvalid = ko.pureComputed =>
         return false unless @confirmPassword()?
         return @newPassword() != @confirmPassword()

      @confirmInputValid = ko.pureComputed =>
         return false unless @confirmPassword()?
         return @newPassword() == @confirmPassword()

   actionBtnIntercept: ->
      return unless @isActionEnabled()
      peopleStore.resetPasswordInternally authManager.authedUser().id, @currentPassword(),
      @newPassword(), (err, success) =>
         if err?
            if err.detailCode == ResetPasswordPane.RecievableError.WRONG_PASSWORD
               return @displayingNotice(ResetPasswordPane.Notice.WRONG_PASSWORD)
            if err.detailCode == ResetPasswordPane.RecievableError.INVALID_COMPLEX_PASSWORD
               return @displayingNotice(ResetPasswordPane.Notice.INVALID_COMPLEX_PASSWORD)
            if err.detailCode == ResetPasswordPane.RecievableError.REUSED_PASSWORD
               return @displayingNotice(ResetPasswordPane.Notice.REUSED_PASSWORD)
            return @displayingNotice(ResetPasswordPane.Notice.RESET_FAILED)
         if success
            @displayingNotice(null)
            modalManager.modalFinished()

ResetPasswordPane.Notice = {
   WRONG_PASSWORD: {
      text: 'Current password was not correct. Passwords are case sensitive.'
      info: null
      color: 'red'
      dissmissable: true
   }
   RESET_FAILED: {
      text: "Reset password failed, please refresh the page and try again."
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_COMPLEX_PASSWORD: {
      text: "Your password did not meet the complexity requirements."
      info: null
      color: 'red'
      dissmissable: true
   }
   REUSED_PASSWORD: {
      text: "You can not reuse a previous password."
      info: null
      color: 'red'
      dissmissable: true
   }
}

ResetPasswordPane.RecievableError = {
   WRONG_PASSWORD: 'wrongPassword'
   INVALID_COMPLEX_PASSWORD: 'invalidPassword'
   REUSED_PASSWORD: 'reusedPassword'
}

export function textSearch<T>({
   items,
   textProvider,
   search,
}: {
   items: T[];
   textProvider: (item: T) => string;
   search: string | null;
}): T[] {
   if (!search) return items;
   const tokens = search.toLowerCase().split(" ");
   return items.filter((item) => {
      const text = textProvider(item).toLowerCase();
      return tokens.every((token) => text.includes(token));
   });
}

import "./work-day-editor-element.styl";
import template from "./work-day-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";

export type WorkDayEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   workDays: PureComputed<WorkDaySelection>;
} & EditorElementParams<Partial<WorkDaySelection>>;

// TODO: Move to schemas.
export const enum WorkDay {
   SUNDAY = 0,
   MONDAY = 1,
   TUESDAY = 2,
   WEDNESDAY = 3,
   THURSDAY = 4,
   FRIDAY = 5,
   SATURDAY = 6,
}
export type WorkDaySelection = Record<WorkDay, boolean>;

export class WorkDayEditorElement extends ViewModel {
   readonly invalidInputMessage: WorkDayEditorElementParams["invalidInputMessage"];
   readonly onChange: WorkDayEditorElementParams["onChange"];
   readonly title: WorkDayEditorElementParams["title"];
   readonly workDays: WorkDayEditorElementParams["workDays"];

   readonly workDayMediators = [
      WorkDay.SUNDAY,
      WorkDay.MONDAY,
      WorkDay.TUESDAY,
      WorkDay.WEDNESDAY,
      WorkDay.THURSDAY,
      WorkDay.FRIDAY,
      WorkDay.SATURDAY,
   ].map((day) => ({
      name: this.getDayAbbreviation(day),
      mediator: pureComputed({
         read: () => unwrap(this.workDays)[day],
         write: (isWorkDay: boolean) => this.onChange({ [day]: isWorkDay }),
      }),
   }));

   constructor({
      invalidInputMessage,
      onChange,
      workDays,
      title = "Working Days",
   }: WorkDayEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.title = title;
      this.workDays = workDays;
   }

   getDayAbbreviation(day: WorkDay): string {
      const abbreviations: Record<WorkDay, string> = {
         [WorkDay.SUNDAY]: "Sun",
         [WorkDay.MONDAY]: "Mon",
         [WorkDay.TUESDAY]: "Tue",
         [WorkDay.WEDNESDAY]: "Wed",
         [WorkDay.THURSDAY]: "Thu",
         [WorkDay.FRIDAY]: "Fri",
         [WorkDay.SATURDAY]: "Sat",
      };
      return abbreviations[day];
   }

   static factory(params: WorkDayEditorElementParams): ComponentArgs<WorkDayEditorElementParams> {
      return {
         name: "work-day-editor-element",
         params,
      };
   }
}

ko.components.register("work-day-editor-element", {
   viewModel: WorkDayEditorElement,
   template: template(),
   synchronous: true,
});

import React from "react";
import { Box, Typography, useI18nContext } from "@procore/core-react";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import {
   getSubjectName,
   renderAdd,
   renderChange,
   renderPersonAssignableActivity,
   renderPersonNotificationActivity,
   renderPersonProfilePicActivity,
   renderPersonSpecificAdd,
   renderPersonSpecificDelete,
   renderPersonSpecificUpdate,
   renderRemove,
} from "../../activities/activity-helper";
import { customFieldsCard, formatCurrency, formatDate } from "../../helper";
import type { ExtendedActivitiesResponseProps } from "../../types";

export const PersonActivitiesInfoCard: React.FC<{ activity: ExtendedActivitiesResponseProps }> = ({
   activity,
}) => {
   const I18n = useI18nContext();
   const dataCardActivities = (data: ExtendedActivitiesResponseProps) => {
      const { meta } = data;
      const { property_name, new_val, old_val } = meta ?? {};

      if (!meta) return null;

      switch (property_name) {
         case "name":
            if (new_val?.first != old_val?.first && new_val?.last != old_val?.last) {
               const oldName = `${old_val?.first} ${old_val?.last}`;
               const newName = `${new_val?.first} ${new_val?.last}`;
               return renderChange(property_name, I18n, oldName, newName);
            }
            if (new_val?.first != old_val?.first) {
               return renderChange("first_name", I18n, old_val?.first, new_val?.first);
            }
            if (new_val?.last != old_val?.last) {
               return renderChange("last_name", I18n, old_val?.last, new_val?.last);
            }
            break;
         case "is_male":
            if (new_val !== null && old_val !== null) {
               const oldGenderValue = old_val
                  ? I18n.t("views.company.workforce_planning.people.male")
                  : I18n.t("views.company.workforce_planning.people.female");
               const newGenderValue = new_val
                  ? I18n.t("views.company.workforce_planning.people.male")
                  : I18n.t("views.company.workforce_planning.people.female");
               return renderChange(property_name, I18n, oldGenderValue, newGenderValue);
            }

            if (new_val === null && old_val !== undefined) {
               const genderValue = old_val
                  ? I18n.t("views.company.workforce_planning.people.male")
                  : I18n.t("views.company.workforce_planning.people.female");
               return renderRemove(property_name, genderValue, I18n);
            }

            if (old_val === null && new_val !== undefined) {
               const genderValue = new_val
                  ? I18n.t("views.company.workforce_planning.people.male")
                  : I18n.t("views.company.workforce_planning.people.female");
               return renderAdd(property_name, genderValue, I18n);
            }

            break;
         case "emergency_contact_name":
         case "emergency_contact_number":
         case "emergency_contact_relation":
         case "status":
         case "address_1":
         case "address_2":
         case "city_town":
         case "state_province":
         case "zipcode":
         case "country":
         case "language":
         case "phone":
         case "lat":
         case "long":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, I18n, old_val, new_val);
            if (new_val === null && old_val !== undefined)
               return renderRemove(property_name, old_val, I18n);
            if (old_val === null && new_val !== undefined)
               return renderAdd(property_name, new_val, I18n);
            break;
         case "email":
         case "emergency_contact_email":
         case "employee_number":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, I18n, old_val, new_val, "highlighted-text");
            if (new_val === null && old_val !== undefined)
               return renderRemove(property_name, old_val, I18n, "highlighted-text");
            if (old_val === null && new_val !== undefined)
               return renderAdd(property_name, new_val, I18n, "highlighted-text");
            break;
         case "hired_date":
         case "dob":
            if (new_val !== null && old_val !== null) {
               const oldDetachedDate = getDetachedDay(new Date(old_val as number));
               const newDetachedDate = getDetachedDay(new Date(new_val as number));
               return renderChange(
                  property_name,
                  I18n,
                  formatDate(oldDetachedDate),
                  formatDate(newDetachedDate),
               );
            }
            if (new_val === null && old_val !== undefined) {
               const detachedDate = getDetachedDay(new Date(old_val as number));
               return renderRemove(property_name, formatDate(detachedDate), I18n);
            }

            if (old_val === null && new_val !== undefined) {
               const detachedDate = getDetachedDay(new Date(new_val as number));
               return renderAdd(property_name, formatDate(detachedDate), I18n);
            }
            break;
         case "hourly_wage":
            if (new_val !== null && old_val !== null)
               return renderChange(
                  property_name,
                  I18n,
                  formatCurrency(old_val as number, I18n),
                  formatCurrency(new_val as number, I18n),
               );
            if (new_val === null && old_val !== null)
               return renderRemove(property_name, formatCurrency(old_val as number, I18n), I18n);
            if (old_val === null && new_val !== null)
               return renderAdd(property_name, formatCurrency(new_val as number, I18n), I18n);
            break;
         case "custom_fields":
            return customFieldsCard(data, I18n);
         case "position_id":
         case "permission_level_id":
         case "notification_profile_id":
         case "group_ids": {
            const personName = getSubjectName(activity, "subject_1_name");
            const value = getSubjectName(activity, "subject_2_name");
            if (activity?.action_type === "create") {
               return renderPersonSpecificAdd(property_name, value, personName, I18n);
            }
            if (activity?.action_type === "update") {
               return renderPersonSpecificUpdate(property_name, value, personName, I18n);
            }
            if (activity?.action_type === "delete") {
               return renderPersonSpecificDelete(property_name, value, personName, I18n);
            }
            break;
         }
         case "can_recieve_sms":
         case "can_recieve_mobile":
         case "can_recieve_email":
            return renderPersonNotificationActivity(property_name, new_val as boolean, I18n);
         case "profile_pic_url":
            return renderPersonProfilePicActivity(
               property_name,
               old_val as string,
               new_val as string,
               I18n,
            );
         case "is_user":
         case "is_assignable": {
            const personName = getSubjectName(activity, "subject_1_name");
            return renderPersonAssignableActivity(
               property_name,
               personName,
               new_val as boolean,
               I18n,
            );
         }
         default:
            return null;
      }
   };

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray30">
            {dataCardActivities(activity)}
         </Typography>
      </Box>
   );
};

import { PLACEHOLDER_KEYS } from "@/react/components/tearsheets/constants";
import { DropDownType } from "@/react/components/tearsheets/types";
import React, { useState, useCallback } from "react";
import { formatOptions } from "../helper";
import type { FormattedOption } from "@/react/prop-types";
import { CurrentAssignmentsBanner } from "@/react/components/tearsheets/assignment/current-assignments-banner";
import type { BannerVariant } from "@procore/core-react/dist/Banner/Banner.types";
import { ProjectStore } from "@/stores/project-store.core";
import type { ExtendedGetProjectDetailResponse } from "@/react/components/tearsheets/project/types";
import type { CurrentAssignmentsBannerProps } from "@/react/components/tearsheets/assignment/types";
import { getTotalHours } from "@/react/components/tearsheets/helper";
import { PersonStore } from "@/stores/person-store.core";
import type { GetPersonDetailsResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";

export const useDropDownSelectionHandler = (assignmentCreationSupportData: any) => {
   const [categoryOptions, setCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [subCategoryOptions, setSubCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [categoryPlaceHolder, setCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.category.project_selected_first,
   );
   const [subCategoryPlaceHolder, setSubCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.subCategory.category_selected_first,
   );
   const [currentAssignments, setCurrentAssignments] = useState<React.ReactNode>(null);
   const [projectProcoreId, setProjectProcoreId] = useState<number | null>(null);
   const [projectUserId, setProjectUserId] = useState<number | null>(null);
   const [totalHours, setTotalHours] = useState<number>(0);

   const resetSubCategory = useCallback(() => {
      setSubCategoryOptions([]);
      setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_selected_first);
   }, []);

   const handleSelection = useCallback(
      async (option: any, type: string, makeTotalHoursAssignment: boolean = true) => {
         const { id, label } = option || {};
         const { data } = assignmentCreationSupportData ?? {};

         if (!data) return;

         switch (type) {
            case DropDownType.PROJECT: {
               setCategoryOptions([]);
               resetSubCategory();
               if (id && data?.grouped_cost_code_options?.[id]) {
                  const categoryOptions = formatOptions(data.grouped_cost_code_options[id]);
                  setCategoryOptions(categoryOptions);
                  setCategoryPlaceHolder(
                     categoryOptions.length
                        ? PLACEHOLDER_KEYS.category.select_category
                        : PLACEHOLDER_KEYS.category.project_no_options,
                  );
                  const projectData: ExtendedGetProjectDetailResponse =
                     await ProjectStore.getProjectDetails(id).payload;

                  setProjectProcoreId(
                     projectData.data.procore_id != null
                        ? Number(projectData.data.procore_id)
                        : null,
                  );
                  if (makeTotalHoursAssignment) {
                     setTotalHours(
                        getTotalHours(
                           projectData.data.daily_end_time,
                           projectData.data.daily_start_time,
                        ),
                     );
                  }
               } else {
                  setCategoryPlaceHolder(PLACEHOLDER_KEYS.category.project_no_options);
               }
               break;
            }

            case DropDownType.CATEGORY: {
               setSubCategoryOptions([]);
               if (id && data?.grouped_label_options?.[id]) {
                  const subCategoryOptions = formatOptions(data.grouped_label_options[id]);
                  setSubCategoryOptions(subCategoryOptions);
                  setSubCategoryPlaceHolder(
                     subCategoryOptions.length
                        ? PLACEHOLDER_KEYS.subCategory.select_subcategory
                        : PLACEHOLDER_KEYS.subCategory.category_no_options,
                  );
               } else {
                  setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_no_options);
               }
               break;
            }

            case DropDownType.RESOURCE: {
               let bannerProps: CurrentAssignmentsBannerProps = {
                  variant: "info" as BannerVariant,
                  personId: "",
                  icon: "info",
                  personName: "",
                  showBanner: false,
               };
               if (option) {
                  bannerProps = {
                     ...bannerProps,
                     personId: id,
                     personName: label,
                     showBanner: true,
                  };

                  const personData: GetPersonDetailsResponse = await PersonStore.getPersonDetails(
                     id,
                  ).payload;
                  setProjectUserId(personData.data.procore_person_id ?? null);
               }
               const currentAssignmentsBanner = <CurrentAssignmentsBanner {...bannerProps} />;
               setCurrentAssignments(currentAssignmentsBanner);
               break;
            }

            default:
               break;
         }
      },
      [assignmentCreationSupportData, resetSubCategory],
   );

   return {
      categoryOptions,
      subCategoryOptions,
      categoryPlaceHolder,
      subCategoryPlaceHolder,
      currentAssignments,
      handleSelection,
      resetSubCategory,
      projectProcoreId,
      totalHours,
      setTotalHours,
      projectUserId,
   };
};

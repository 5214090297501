import React from "react";
import { ErrorBanner as CoreErrorBanner, Banner } from "@procore/core-react";

type ErrorBannerProps = {
   title: string;
   content: string;
};

export const ErrorBanner = (props: ErrorBannerProps) => {
   const { title, content } = props;
   return (
      <CoreErrorBanner>
         <Banner.Content>
            <Banner.Title>{title}</Banner.Title>
            <Banner.Body>{content}</Banner.Body>
         </Banner.Content>
      </CoreErrorBanner>
   );
};

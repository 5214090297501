import "./project-roles-editor.styl";
import template from "./project-roles-editor.pug";
import type { Observable, PureComputed, Subscription } from "knockout";
import ko, {
   isObservable,
   isPureComputed,
   observable,
   observableArray,
   pureComputed,
   unwrap,
} from "knockout";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import { PositionStore } from "@/stores/position-store.core";
import { formatName } from "@/lib/utils/preferences";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { PersonDropDownPane } from "@/lib/components/drop-downs/panes/person-drop-down-pane";
import { ActionResult } from "@/lib/components/drop-downs/drop-down-2";
import type {
   SerializedPerson,
   SerializedPosition,
} from "@laborchart-modules/common/dist/rethink/serializers";
import type { StreamProjectUpdatesPayload } from "@laborchart-modules/lc-core-api/dist/api/projects/update-projects";
import type { DisplayRole } from "@/stores/project-store";
import type { AuthType } from "@laborchart-modules/lc-core-api";
import { JobTitleDropDownPane } from "../../drop-downs/panes/job-title-drop-down-pane";
import { ColorCircleTextCell } from "../../grid/cells/color-circle-text-cell";
import { v4 } from "uuid";

export interface ProjectRolesEditorProject {
   id: string;
   groupIds: Set<string>;
   roles: DisplayRole[];
}

export interface ProjectRolesEditorParams
   extends EditorComponentParams<ProjectRolesEditorProject[], ProjectRolesEditorUpdate> {
   /**
    * Optional: Specifying while limit the editor to only modifying roles for the
    * supplied job title.
    */
   jobTitleId?: string;
}

export type ProjectRolesEditorUpdate = {
   payload: StreamProjectUpdatesPayload<AuthType.SESSION>;
   added: DisplayRole | null;
   removed: DisplayRole | null;
   isReplace: boolean;
};

interface RenderedJobTitle {
   jobTitle: SerializedPosition;
   projects: ProjectRolesEditorProject[];
}

interface RenderedPerson {
   id: string;
   name: string;
   projects: ProjectRolesEditorProject[];
}

enum View {
   LOADING = 0,
   JOB_TITLE_LIST = 1,
   PEOPLE_LIST = 2,
   ADD_ROLE = 3,
}

export class ProjectRolesEditor {
   readonly view = observable(View.LOADING);
   readonly transition = observable(Transition.FORWARD);
   readonly contentResizeTrigger = observable(0);

   readonly fixedJobTitleId: string | null;
   readonly allJobTitles = observableArray<SerializedPosition>();
   readonly projects:
      | Observable<ProjectRolesEditorProject[]>
      | PureComputed<ProjectRolesEditorProject[]>;

   readonly selectedJobTitle = observable<RenderedJobTitle | null>(null);
   readonly selectedPerson = observable<RenderedPerson | null>(null);
   readonly isReplacePersonChecked = observable(false);

   readonly isSaving = observable(false);
   readonly containsFocus = observable(false).extend({ notify: "always" });

   readonly title = pureComputed(() => {
      if (this.view() == View.ADD_ROLE) {
         return `Add ${this.selectedJobTitle()!.jobTitle.name}`;
      }
      return unwrap(this.params.title) || "Roles";
   });

   readonly allRenderedJobTitles = pureComputed(() => {
      return this.allJobTitles()
         .filter((jobTitle) => {
            return this.projects().some((project) => this.isJobTitleVisible(project, jobTitle));
         })
         .map(
            (jobTitle): RenderedJobTitle => ({
               jobTitle,
               projects: this.projects().filter((project) => this.getRole(project, jobTitle.id)),
            }),
         );
   });
   readonly visibleJobTitles = pureComputed(() => {
      return this.allRenderedJobTitles().filter((rendered) => rendered.projects.length > 0);
   });

   readonly jobTitleDropDownPane = new JobTitleDropDownPane({
      transformer: (position) => position,
   });
   readonly jobTitleCellFactory = ColorCircleTextCell.factory<SerializedPosition>((data) => ({
      text: data.name,
      color: data.color,
   }));

   // Rendered people for the people list.
   readonly renderedPeople = pureComputed(() => {
      const selectedJobTitle = this.selectedJobTitle();
      if (!selectedJobTitle) return [];

      const flattenedRoles = this.projects()
         .map((project) => {
            return project.roles.map((role) => ({ project, role }));
         })
         .reduce((acc, roles) => {
            return acc.concat(roles);
         }, [])
         .filter((flattenedRoles) => {
            return flattenedRoles.role.position_id == selectedJobTitle.jobTitle.id;
         });

      const people = flattenedRoles.reduce((acc, flattenedRoles) => {
         return acc.set(flattenedRoles.role.assignee_id, flattenedRoles.role.assignee_name);
      }, new Map<string, string>());

      return Array.from(people.entries())
         .map(
            ([id, name]): RenderedPerson => ({
               id: id,
               name,
               projects: flattenedRoles
                  .filter((flattenedRoles) => flattenedRoles.role.assignee_id == id)
                  .map((flattenedRoles) => flattenedRoles.project),
            }),
         )
         .sort((a, b) => a.name.localeCompare(b.name));
   });

   // People dropdown configuration.
   readonly personPane = PersonDropDownPane.create();
   readonly personCellFactory = TextCell.factory<SerializedPerson>((person) =>
      formatName(person.name),
   );

   readonly replaceRolesCheckboxTitle = pureComputed(() => {
      const selectedJobTitle = this.selectedJobTitle();
      if (!selectedJobTitle) return "";
      return `Replace all ${selectedJobTitle.jobTitle.name} roles`;
   });
   readonly addedOnSaveText = pureComputed(() => {
      const person = this.selectedPerson();
      if (!person) return null;
      return this.projects().length > 1
         ? this.getProjectCountText(this.projects().length - person.projects.length)
         : this.getRoleCountText(1);
   });
   readonly removedOnSaveText = pureComputed(() => {
      const person = this.selectedPerson();
      if (!this.isReplacePersonChecked() || !person) return "-";
      const jobTitleId = this.selectedJobTitle()!.jobTitle.id;

      // List the number of projects affected.
      if (this.projects().length > 1) {
         const projectsWithRemoval = this.projects().filter((project) => {
            return project.roles.some(
               (role) => role.position_id == jobTitleId && role.assignee_id != person.id,
            );
         });
         return this.getProjectCountText(projectsWithRemoval.length);
      }

      // List the number of individual roles affected.
      const rolesToRemove = (this.projects()[0]?.roles ?? []).filter(
         (role) => role.position_id == jobTitleId,
      );
      return this.getRoleCountText(rolesToRemove.length);
   });
   readonly unchangedOnSaveText = pureComputed(() => {
      const person = this.selectedPerson();
      return person ? this.getProjectCountText(person.projects.length) : null;
   });

   private readonly subscriptions: Subscription[] = [];

   constructor(readonly params: ProjectRolesEditorParams) {
      this.projects =
         isObservable(params.value) || isPureComputed(params.value)
            ? params.value
            : observableArray(params.value as ProjectRolesEditorProject[]);
      this.fixedJobTitleId = params.jobTitleId || null;
      this.loadAllJobTitles();
      this.subscriptions.push(
         this.visibleJobTitles.subscribe(
            this.onBeforeVisibleJobTitlesChanged,
            this,
            "beforeChange",
         ),
         this.renderedPeople.subscribe(this.onBeforeRenderedPeopleChanged, this, "beforeChange"),
      );
   }

   getProjectCountText = (count: number): string => {
      return count == 1 ? "1 project" : `${count} projects`;
   };

   getRoleCountText = (count: number): string => {
      return count == 1 ? "1 role" : `${count} roles`;
   };

   onHidden = (): void => {
      // Change focus when a view is hidden instead of when one is visible
      // to guarantee the recently hidden view doesn't grab focus.
      this.containsFocus(true);
   };

   onJobTitleClicked = (renderedJobTitle: RenderedJobTitle): void => {
      this.selectedJobTitle(renderedJobTitle);
      this.transition(Transition.FORWARD);
      this.view(View.PEOPLE_LIST);
   };

   onBackClicked = (): void => {
      this.transition(Transition.BACKWARD);
      this.view(this.view() == View.ADD_ROLE ? View.PEOPLE_LIST : View.JOB_TITLE_LIST);
   };

   onCloseClicked = (): void => {
      if (this.params.onClose) this.params.onClose({ hasSaved: false });
   };

   jobTitleDropDownInterceptor = (jobTitle: SerializedPosition): ActionResult => {
      const selectedJobTitle = {
         jobTitle: jobTitle,
         projects: this.projects().filter((project) => this.getRole(project, jobTitle.id)),
      };
      this.selectedJobTitle(selectedJobTitle);
      this.transition(Transition.FORWARD);
      this.view(View.PEOPLE_LIST);
      return ActionResult.CLOSE;
   };

   personDropDownInterceptor = (person: SerializedPerson): ActionResult => {
      const selectedPerson = this.renderedPeople().find((renderedPerson) => {
         return renderedPerson.id == person.id;
      }) || {
         id: person.id,
         name: formatName(person.name),
         projects: [],
      };
      this.selectedPerson(selectedPerson);
      this.isReplacePersonChecked(false);
      this.transition(Transition.FORWARD);
      this.view(View.ADD_ROLE);
      return ActionResult.CLOSE;
   };

   onSaveClicked = async (): Promise<void> => {
      const projects = this.projects();
      const jobTitleId = this.selectedJobTitle()!.jobTitle.id;
      const renderedPerson = this.selectedPerson()!;
      const isReplace = this.isReplacePersonChecked();
      const newRoleId = v4();

      // Add all of the new roles for projects without the role.
      const payload: StreamProjectUpdatesPayload<AuthType.SESSION> = projects
         .filter((project) => !renderedPerson.projects.find((p) => p.id == project.id))
         // creating a new roles object
         .map((project) => {
            const updateRoles = [
               {
                  id: newRoleId,
                  position_id: jobTitleId,
                  person_id: renderedPerson.id,
                  archived: false,
               },
            ];

            // Explicitly remove all roles matching the selected job title that are not
            // with the selected person.
            if (isReplace) {
               const replacedRoles = project.roles.map((role) => ({
                  id: role.id,
                  position_id: role.position_id,
                  person_id: role.assignee_id,
                  archived: true,
               }));
               updateRoles.push(...replacedRoles);
            }

            return { id: project.id, roles: updateRoles };
         });

      const update: ProjectRolesEditorUpdate = {
         payload,
         added: {
            id: newRoleId,
            assignee_id: renderedPerson.id,
            assignee_name: renderedPerson.name,
            position_id: jobTitleId,
         },
         removed: null,
         isReplace,
      };

      const isSuccess = await this.saveChanges({
         update,
         errorMessage: "An unexpected error prevented the roles from being created.",
      });
      if (isSuccess) {
         this.transition(Transition.BACKWARD);
         this.view(View.PEOPLE_LIST);
      }
   };

   onDeletePersonClicked = async (renderedPerson: RenderedPerson): Promise<void> => {
      const selectedJobTitle = this.selectedJobTitle()!;

      const payload: StreamProjectUpdatesPayload<AuthType.SESSION> = renderedPerson.projects.map(
         (project) => {
            const currentRole = project.roles.find(
               (role) =>
                  role.position_id == selectedJobTitle.jobTitle.id &&
                  role.assignee_id == renderedPerson.id,
            );
            if (!currentRole)
               throw new Error(
                  `Project does not currently have an active role with job-title ID of ${selectedJobTitle.jobTitle.id} and person ID of ${renderedPerson.id}`,
               );

            return {
               id: project.id,
               roles: [
                  {
                     id: currentRole!.id!,
                     position_id: selectedJobTitle.jobTitle.id,
                     person_id: renderedPerson.id,
                     archived: true,
                  },
               ],
            };
         },
      );

      await this.saveChanges({
         update: {
            payload,
            added: null,
            removed: {
               assignee_id: renderedPerson.id,
               assignee_name: renderedPerson.name,
               position_id: selectedJobTitle.jobTitle.id,
            } as any,
            isReplace: false,
         },
         errorMessage: "An unexpected error prevented the deletion.",
      });
   };

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   private async saveChanges({
      update: payload,
      errorMessage,
   }: {
      update: ProjectRolesEditorUpdate;
      errorMessage: string;
   }): Promise<boolean> {
      try {
         await this.params.saveProvider(payload);
         return true;
      } catch (err) {
         notificationManagerInstance.show(
            new Notification({
               text: errorMessage,
               icon: Icons.WARNING,
               duration: 5000,
            }),
         );
         return false;
      } finally {
         this.isSaving(false);
      }
   }

   private async loadAllJobTitles() {
      const showErrorAndClose = (error: string) => {
         notificationManagerInstance.show(new Notification({ text: error, icon: Icons.WARNING }));
         if (this.params.onClose) this.params.onClose({ hasSaved: false });
      };

      /*
      We are using 100 as the pagination limit due to a known bug with the editor.
      Job Titles that are far enough down the company's hierarchy will
      not get included in the visibleJobTitles since their sequence is higher
      than the pagination limit.
      This is a temporary fix and should be updated to a scalable method.
      */
      try {
         const jobTitles = this.fixedJobTitleId
            ? (
                 await PositionStore.findPositionsPaginated({
                    limit: 100,
                    starting_at: this.fixedJobTitleId,
                 }).payload
              ).data
            : (await PositionStore.findPositionsPaginated({ limit: 100 }).payload).data;
         this.allJobTitles(jobTitles);
         this.transition(Transition.FADE);
         if (this.fixedJobTitleId) {
            const jobTitle = this.allJobTitles().find(
               (jobTitle) => jobTitle.id == this.fixedJobTitleId,
            );
            if (!jobTitle) {
               return showErrorAndClose(
                  "An error occurred when attempting to edit the project roles.",
               );
            }
            this.selectedJobTitle({
               jobTitle,
               projects: this.projects().filter((project) => {
                  project.roles.some((role) => role.position_id == this.fixedJobTitleId);
               }),
            });
            this.view(View.PEOPLE_LIST);
         } else {
            this.view(View.JOB_TITLE_LIST);
         }
      } catch (err) {
         return showErrorAndClose("An unexpected error occurred.");
      }
   }

   private onBeforeVisibleJobTitlesChanged() {
      this.contentResizeTrigger(Date.now());
   }

   private onBeforeRenderedPeopleChanged() {
      this.contentResizeTrigger(Date.now());
   }

   private getRole(project: ProjectRolesEditorProject, jobTitleId: string): DisplayRole | null {
      return project.roles.find((role) => role.position_id == jobTitleId) || null;
   }

   private isJobTitleVisible(project: ProjectRolesEditorProject, jobTitle: SerializedPosition) {
      return (
         jobTitle.globally_accessible ||
         jobTitle.group_ids.some((groupId) => project.groupIds.has(groupId))
      );
   }

   static factory<T>(
      provider: (records: T[]) => ProjectRolesEditorParams,
   ): (records: T[]) => ComponentArgs<ProjectRolesEditorParams> {
      return (records) => ({
         name: "project-roles-editor",
         params: provider(records),
      });
   }
}

ko.components.register("project-roles-editor", {
   viewModel: ProjectRolesEditor,
   template: template(),
   synchronous: true,
});

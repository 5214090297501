import ko from "knockout";
import type { Filter } from "@/lib/components/chip-filter/chip-filter";

export enum BrowserLocalStorageKey {
   ASSIGNMENTS_EXPORT_CONFIG = "assignments-export-config",
   BOARDS_CONFIG = "boards-config",
   CHIP_FILTERS = "chip-filters",
   GANTT_PEOPLE_CONFIG = "gantt-people-config",
   GANTT_PEOPLE_SEARCH = "gant-people-search",
   GANTT_PROJECT_CONFIG = "gantt-project-config",
   GANTT_PROJECT_SEARCH = "gant-project-search",
   GLOBAL_ACTIVITY_ENTITY = "global-activity-entity",
}

export function storePageFilterChips(chips: Array<Filter<unknown>>, key?: string): void {
   const urlParts = window.location.pathname.split("?");
   const fullKey = `${key ?? BrowserLocalStorageKey.CHIP_FILTERS}_${urlParts[0]}`;
   return window.localStorage.setItem(fullKey, JSON.stringify(ko.toJS(chips)));
}

export function getPageFilterChips(key?: string | null): Filter[] | null {
   const urlParts = window.location.pathname.split("?");
   const fullKey = `${key ?? BrowserLocalStorageKey.CHIP_FILTERS}_${urlParts[0]}`;
   const chips = window.localStorage.getItem(fullKey);
   return chips != null ? JSON.parse(chips) : null;
}

export function storeJsonValue(fullKey: string, value: unknown): void {
   if (value != null) {
      return window.localStorage.setItem(fullKey, JSON.stringify(value));
   } else {
      return window.localStorage.removeItem(fullKey);
   }
}

export function getJsonValue<TData>(fullKey: string): TData | null {
   const data = window.localStorage.getItem(fullKey);
   const value = data != null ? JSON.parse(data) : null;
   return value;
}

export function storeBasicValue(fullKey: string, value: string | null): void {
   if (value != null) {
      return window.localStorage.setItem(fullKey, value);
   } else {
      return window.localStorage.removeItem(fullKey);
   }
}

export function getBasicValue(fullKey: string): string | null {
   return window.localStorage.getItem(fullKey);
}

import "./request-details-modal.styl";
import template from "./request-details-modal.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { Observable, PureComputed } from "knockout";
import ko, { toJS, pureComputed, observable, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common/component-args";
import type { ModalAction, ModalFooterParams } from "@/lib/components/modals/common/modal-footer";
import { ButtonDropDown, ButtonItem } from "@/lib/components/button-drop-down/button-drop-down";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { ButtonColor, ButtonSize } from "@/lib/utils/buttons";
import type { OptionalSize, CanRequestSize } from "@/lib/components/modals/common/sized-modal";
import { RequestEditPane } from "./panes/request-edit-pane/request-edit-pane";
import { RequestScopeOfWorkPane } from "./panes/request-scope-of-work-pane/request-scope-of-work-pane";
import { RequestAssignmentInstructions } from "./panes/request-assignment-instructions-pane/request-assignment-instructions-pane";
import { RequestCommentsPane } from "./panes/request-comments-pane/request-comments-pane";
import type {
   AuthType,
   UpdateRequestCommentsPayload,
   UpdateRequestPayload,
} from "@laborchart-modules/lc-core-api";
import {
   Icons,
   notificationManagerInstance,
   Notification,
} from "@/lib/managers/notification-manager";
import { ProgressNotification } from "@/notifications/progress-notification";
import { authManager } from "@/lib/managers/auth-manager";
import type { AssignmentWorkDays } from "@/models/assignment";
import type { NestedComputedAssignmentSupportData } from "@/stores/assignment-2-store.core";
import type { ModalTab, TabbedPaneParams } from "@/lib/components/modals/common/tabbed-pane";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { NullableCustomFieldValue } from "../editors/custom-field-editor-element/custom-field-editor-element";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { ModalHeaderParams } from "@/lib/components/modals/common/modal-header";
import { WorkDay } from "../editors/work-day-editor-element/work-day-editor-element";
import type {
   CreateRequestPayload,
   CreateRequestResponse,
} from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import { EventHandler } from "@/lib/utils/event-handler";
import type { MakeKeysNullable } from "@/lib/type-utils";
import type { DeleteRequestResponse } from "@laborchart-modules/lc-core-api/dist/api/requests/delete-request";
import { PermissionLevel } from "@/models/permission-level";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

export const enum RequestDetailsModalPane {
   EDIT_REQUEST = 0,
   SCOPE_OF_WORK = 1,
   ASSIGNMENT_INSTRUCTIONS = 2,
   COMMENTS = 3,
}

export type RequestDetailsModalUpdater = ({
   request,
   update,
}: {
   request: SerializedFindRequest;
   update: Partial<RequestDetailsModalStagedUpdate>;
}) => Promise<void>;

export type RequestDetailsModalParams = {
   createRequest: (request: CreateRequestPayload) => Promise<CreateRequestResponse | void>;
   customFields: PureComputed<SerializedCustomField[]>;
   deleteRequest: (request: SerializedFindRequest) => Promise<DeleteRequestResponse | void>;
   supportData: NestedComputedAssignmentSupportData;
   tags: PureComputed<SerializedTag[]>;
   updateRequest: RequestDetailsModalUpdater;

   initialPane?: RequestDetailsModalPane;
   newRequestSeedData?: Partial<Pick<StagedRequest, "project">>;
   request?: PureComputed<SerializedFindRequest> | null;
} & CanRequestSize;

export type UpdatableRequestFields = Pick<
   UpdateRequestPayload<AuthType.SESSION>,
   | "category_id"
   | "comments"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "instruction_text"
   | "job_title_id"
   | "percent_allocated"
   | "project_id"
   | "start_day"
   | "start_time"
   | "status_id"
   | "subcategory_id"
   | "tag_ids"
   | "work_days"
   | "work_scope_text"
>;
export type UpdateRequestDetails = Pick<
   UpdatableRequestFields,
   | "category_id"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "job_title_id"
   | "percent_allocated"
   | "project_id"
   | "start_day"
   | "start_time"
   | "status_id"
   | "subcategory_id"
   | "work_days"
>;

export type RequestDetailsModalStagedUpdate = Pick<
   UpdateRequestPayload<AuthType.SESSION>,
   | "category_id"
   | "comments"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "instruction_text"
   | "job_title_id"
   | "percent_allocated"
   | "project_id"
   | "start_day"
   | "start_time"
   | "status_id"
   | "subcategory_id"
   | "tag_ids"
   | "work_days"
   | "work_scope_text"
> & {
   category?: SerializedFindRequest["category"];
   job_title?: SerializedFindRequest["job_title"];
   project?: SerializedFindRequest["project"];
   status?: SerializedFindRequest["status"];
   subcategory?: SerializedFindRequest["subcategory"];
   quantity?: number | null;
};

type RequestUpdateVisitor = {
   visit: (update: Partial<StagedRequest>) => boolean;
   accept: (update: Partial<StagedRequest>) => void;
};

export type StagedRequest = MakeKeysNullable<
   SerializedFindRequest,
   "end_day" | "project" | "project_id" | "start_day"
> & {
   quantity?: number | null;
};

class RequestDetailsModalState {
   readonly request: PureComputed<StagedRequest | SerializedFindRequest>;

   private readonly requestCreator: RequestDetailsModalParams["createRequest"];
   private readonly requestDeleter: RequestDetailsModalParams["deleteRequest"];
   private readonly requestUpdater: RequestDetailsModalParams["updateRequest"];
   readonly supportData: RequestDetailsModalParams["supportData"];

   private readonly internalState: {
      isDeleting: Observable<boolean>;
      isNewRequest: Observable<boolean>;
      isSaving: Observable<boolean>;
      preventSaveFromEditPane: Observable<boolean>;
      requestedSizeOfContent: Observable<OptionalSize | null>;
      requestedSizeOfFooter: Observable<OptionalSize | null>;
      requestedSizeOfHeader: Observable<OptionalSize | null>;
      stagedComment: Observable<{
         author_id: Exclude<UpdateRequestCommentsPayload[0]["author_id"], undefined>;
         content: UpdateRequestCommentsPayload[0]["content"];
      } | null>;
      stagedRequest: Observable<StagedRequest>;
   };

   // Nouns.
   readonly preventSaveFromEditPane = pureComputed(() =>
      this.internalState.preventSaveFromEditPane(),
   );
   readonly requestedSizeCombined = pureComputed<OptionalSize>(() => {
      const width =
         (this.internalState.requestedSizeOfHeader()?.width ?? 0) +
         (this.internalState.requestedSizeOfContent()?.width ?? 0) +
         (this.internalState.requestedSizeOfFooter()?.width ?? 0);
      const height =
         (this.internalState.requestedSizeOfHeader()?.height ?? 0) +
         (this.internalState.requestedSizeOfContent()?.height ?? 0) +
         (this.internalState.requestedSizeOfFooter()?.height ?? 0);
      return {
         width: width === 0 ? null : width,
         height: height === 0 ? null : height,
      };
   });

   readonly isSaving = pureComputed(() => this.internalState.isSaving());
   readonly isNewRequest = pureComputed(() => this.internalState.isNewRequest());
   readonly isDeleting = pureComputed(() => this.internalState.isDeleting());
   readonly stagedComment = pureComputed(() => this.internalState.stagedComment() ?? null);
   readonly stagedRequest = pureComputed(() => this.internalState.stagedRequest());

   readonly stagedUpdatesFromEditPane = pureComputed((): RequestDetailsModalStagedUpdate => {
      const standardFieldsUpdate = this.getStandardFieldUpdates();
      const customFieldsUpdate = this.getCustomFieldUpdates();
      return customFieldsUpdate != null
         ? { ...standardFieldsUpdate, ...customFieldsUpdate }
         : standardFieldsUpdate;
   });

   readonly getStandardFieldUpdates = (): RequestDetailsModalStagedUpdate => {
      const request = this.request();
      const stagedRequest = this.stagedRequest();
      if (stagedRequest == null) return {};

      // TODO: Move to a shared location.
      const getIdListDelta = (
         oldList: Set<string>,
         newList: Set<string>,
      ): Record<string, boolean> => {
         const delta: Record<string, boolean> = {};
         new Set([...oldList, ...newList]).forEach((item) => {
            if (!oldList.has(item)) delta[item] = true;
            if (!newList.has(item)) delta[item] = false;
         });
         return delta;
      };

      const visitors: Array<{
         visit: boolean;
         accept: RequestDetailsModalStagedUpdate;
      }> = [
         {
            visit:
               "project" in stagedRequest &&
               stagedRequest.project != null &&
               (stagedRequest.project?.id ?? null) != (request.project?.id ?? null),
            accept: {
               project: stagedRequest.project!,
               project_id: stagedRequest.project?.id,
            },
         },
         {
            visit:
               "category" in stagedRequest &&
               (stagedRequest.category?.id ?? null) != (request.category?.id ?? null),
            accept: {
               category: stagedRequest.category,
               category_id: stagedRequest.category?.id ?? null,
            },
         },
         {
            visit:
               "subcategory" in stagedRequest &&
               (stagedRequest.subcategory?.id ?? null) != (request.subcategory?.id ?? null),
            accept: {
               subcategory: stagedRequest.subcategory,
               subcategory_id: stagedRequest.subcategory?.id ?? null,
            },
         },
         {
            visit:
               "job_title" in stagedRequest &&
               (stagedRequest.job_title?.id ?? null) != (request.job_title?.id ?? null),
            accept: {
               job_title: stagedRequest.job_title,
               job_title_id: stagedRequest.job_title?.id ?? null,
            },
         },
         {
            visit:
               "status" in stagedRequest &&
               (stagedRequest.status?.id ?? null) != (request.status?.id ?? null),
            accept: {
               status: stagedRequest.status,
               status_id: stagedRequest.status?.id ?? null,
            },
         },
         {
            visit:
               "start_day" in stagedRequest &&
               stagedRequest.start_day != null &&
               stagedRequest.start_day != request.start_day,
            accept: { start_day: stagedRequest.start_day! },
         },
         {
            visit:
               "end_day" in stagedRequest &&
               stagedRequest.end_day != null &&
               stagedRequest.end_day != request.end_day,
            accept: { end_day: stagedRequest.end_day! },
         },
         {
            visit: "start_time" in stagedRequest && stagedRequest.start_time != request.start_time,
            accept: { start_time: stagedRequest.start_time! },
         },
         {
            visit: "end_time" in stagedRequest && stagedRequest.end_time != request.end_time,
            accept: { end_time: stagedRequest.end_time! },
         },
         {
            visit:
               "percent_allocated" in stagedRequest &&
               stagedRequest.percent_allocated != request.percent_allocated,
            accept: { percent_allocated: stagedRequest.percent_allocated! },
         },
         {
            visit: "quantity" in stagedRequest,
            accept: { quantity: stagedRequest.quantity! },
         },
         {
            visit:
               "tag_ids" in stagedRequest &&
               (stagedRequest.tag_ids.length != request.tag_ids.length ||
                  stagedRequest.tag_ids.some((tagId) => !request.tag_ids.includes(tagId))),
            accept: {
               tag_ids: getIdListDelta(new Set(request.tag_ids), new Set(stagedRequest.tag_ids)),
            },
         },
         {
            visit:
               "work_days" in stagedRequest &&
               stagedRequest.work_days != null &&
               new Array<keyof AssignmentWorkDays>(0, 1, 2, 3, 4, 5, 6).some(
                  (dayIndex) => stagedRequest.work_days[dayIndex] != request.work_days[dayIndex],
               ),
            accept: { work_days: stagedRequest.work_days },
         },
      ];

      return visitors
         .filter((visitor) => visitor.visit)
         .map<RequestDetailsModalStagedUpdate>((acceptor) => acceptor.accept)
         .reduce((left, right) => ({ ...left, ...right }), {});
   };

   // TODO: Refactor some of this out to a shared location.
   readonly getCustomFieldUpdates = () => {
      const request = this.request();
      const stagedRequest = this.stagedRequest();

      if (authManager.companyModules()?.customFields != true) {
         return null;
      }
      const newValueUpdates = stagedRequest.custom_fields
         .filter((field) => {
            const originalField = request.custom_fields.find((cf) => cf.field_id == field.field_id);
            if (originalField == null) {
               // Custom field instance did not already exist on request record.
               return true;
            } else if (!Array.isArray(originalField.value) || !Array.isArray(field.value)) {
               return originalField.value != field.value;
            } else {
               // Handle comparisons of arrays, specifically multi-selects.
               const originalSet = new Set(originalField.value);
               const newSet = new Set(field.value);
               return (
                  originalSet.size != newSet.size ||
                  [...newSet].some((val) => !originalSet.has(val))
               );
            }
         })
         .map<Record<string, NullableCustomFieldValue>>((field) => ({
            [field.field_id]: field.value,
         }));
      const deletedValueUpdates = request.custom_fields
         .filter(
            (field) =>
               stagedRequest.custom_fields.findIndex((cf) => cf.field_id == field.field_id) == -1,
         )
         .map<Record<string, NullableCustomFieldValue>>((field) => ({
            [field.field_id]: null,
         }));
      const allUpdates = [...newValueUpdates, ...deletedValueUpdates].reduce(
         (acc, cur) => ({ ...acc, ...cur }),
         {},
      );

      return Object.keys(allUpdates).length > 0 ? { custom_fields: allUpdates } : null;
   };

   readonly stagedUpdatesFromScopeOfWorkPane = pureComputed(
      (): Pick<RequestDetailsModalStagedUpdate, "work_scope_text"> => {
         const request = this.request();
         const stagedRequest = this.stagedRequest();
         if (stagedRequest == null) return {};

         return "work_scope_text" in stagedRequest &&
            stagedRequest.work_scope_text !== undefined &&
            stagedRequest.work_scope_text != request.work_scope_text
            ? { work_scope_text: stagedRequest.work_scope_text! }
            : {};
      },
   );

   readonly stagedUpdatesFromAssignmentInstructionsPane = pureComputed(
      (): Pick<RequestDetailsModalStagedUpdate, "instruction_text"> => {
         const request = this.request();
         const stagedRequest = this.stagedRequest();
         if (stagedRequest == null) return {};

         return "instruction_text" in stagedRequest &&
            stagedRequest.instruction_text !== undefined &&
            stagedRequest.instruction_text != request.instruction_text
            ? { instruction_text: stagedRequest.instruction_text! }
            : {};
      },
   );

   readonly stagedUpdatesFromCommentsPane = pureComputed(
      (): Pick<RequestDetailsModalStagedUpdate, "comments"> => {
         const commentUpdate = this.stagedComment();
         return commentUpdate != null ? { comments: [commentUpdate] } : {};
      },
   );

   readonly stagedUpdate = pureComputed((): RequestDetailsModalStagedUpdate => {
      const editUpdates = this.stagedUpdatesFromEditPane();
      const scopeOfWorkUpdates = this.stagedUpdatesFromScopeOfWorkPane();
      const assignmentInstructionUpdates = this.stagedUpdatesFromAssignmentInstructionsPane();
      const commentUpdates = this.stagedUpdatesFromCommentsPane();
      return {
         ...editUpdates,
         ...scopeOfWorkUpdates,
         ...assignmentInstructionUpdates,
         ...commentUpdates,
      };
   });

   readonly hasEditPaneChanges = pureComputed(() => {
      return Object.keys(this.stagedUpdatesFromEditPane()).length > 0;
   });
   readonly hasScopeOfWorkChanges = pureComputed(() => {
      return Object.keys(this.stagedUpdatesFromScopeOfWorkPane()).length > 0;
   });
   readonly hasAssignmentInstructionChanges = pureComputed(() => {
      return Object.keys(this.stagedUpdatesFromAssignmentInstructionsPane()).length > 0;
   });
   readonly hasCommentChanges = pureComputed(() => {
      return Object.keys(this.stagedUpdatesFromCommentsPane()).length > 0;
   });

   readonly hasAnyChanges = pureComputed(() => {
      return (
         this.hasEditPaneChanges() === true ||
         this.hasScopeOfWorkChanges() === true ||
         this.hasAssignmentInstructionChanges() === true ||
         this.hasCommentChanges() === true
      );
   });

   readonly canSave = pureComputed(() => {
      return this.hasAnyChanges() === true && this.preventSaveFromEditPane() === false;
   });

   // Verbs.
   readonly setPreventSaveFromEditPane = (preventSaveFromEditPane: boolean) => {
      this.internalState.preventSaveFromEditPane(preventSaveFromEditPane);
   };
   readonly setIsSaving = (isSaving: boolean) => {
      this.internalState.isSaving(isSaving);
   };
   readonly setIsDeleting = (isDeleting: boolean) => {
      this.internalState.isDeleting(isDeleting);
   };
   readonly addStagedUpdateData = (update: Partial<StagedRequest>) => {
      this.internalState.stagedRequest({
         ...this.stagedRequest(),
         ...update,
      });
   };
   readonly requestSizeForContent = (size: OptionalSize) => {
      this.internalState.requestedSizeOfContent(size);
   };
   readonly requestSizeForFooter = (size: OptionalSize) => {
      this.internalState.requestedSizeOfFooter(size);
   };
   readonly requestSizeForHeader = (size: OptionalSize) => {
      this.internalState.requestedSizeOfHeader(size);
   };

   //Request Verbs.
   readonly saveRequest = async () => {
      if (this.isNewRequest()) {
         const stagedRequest = this.stagedRequest();
         const stagedComment = this.stagedComment();
         const newRequest: CreateRequestPayload = {
            category_id: stagedRequest.category_id,
            comments: stagedComment ? [stagedComment] : [],
            custom_fields: stagedRequest.custom_fields
               .map((field) => ({ [field.field_id]: field.value }))
               .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
            end_day: stagedRequest.end_day!,
            end_time: stagedRequest.end_time,
            instruction_text: stagedRequest.instruction_text,
            job_title_id: stagedRequest.job_title_id,
            percent_allocated: stagedRequest.percent_allocated,
            project_id: stagedRequest.project_id!,
            quantity: stagedRequest.quantity ?? 1,
            start_day: stagedRequest.start_day!,
            start_time: stagedRequest.start_time,
            status_id: stagedRequest.status_id,
            subcategory_id: stagedRequest.subcategory_id,
            tag_ids: stagedRequest.tag_ids,
            work_days: stagedRequest.work_days,
            work_scope_text: stagedRequest.work_scope_text,
         };
         await this.requestCreator(newRequest);
      } else {
         await this.requestUpdater({
            request: this.request() as SerializedFindRequest,
            update: this.stagedUpdate(),
         });
      }
      this.internalState.stagedRequest(JSON.parse(JSON.stringify(toJS(this.request))));
      this.internalState.stagedComment(null);
   };
   readonly deleteRequest = () => {
      if (this.isNewRequest()) return;
      return this.requestDeleter(this.request() as SerializedFindRequest);
   };

   readonly updateRequestWorkScopeText = (workScopeText: string | null) => {
      if (unwrap(this.stagedRequest().work_scope_text) != workScopeText) {
         this.addStagedUpdateData({ work_scope_text: workScopeText });
      }
   };
   readonly updateInstructionText = (instructionText: string | null) => {
      if (unwrap(this.stagedRequest().instruction_text) != instructionText) {
         this.addStagedUpdateData({ instruction_text: instructionText });
      }
   };
   readonly updateNewComment = (comment: string | null) => {
      const commentUpdate =
         comment && comment.length
            ? { author_id: authManager.authedUserId()!, content: comment }
            : null;
      this.internalState.stagedComment(commentUpdate);
   };

   constructor({
      request,
      newRequestSeedData,
      requestCreator,
      requestDeleter,
      requestUpdater,
      supportData,
   }: {
      request?: PureComputed<SerializedFindRequest> | null;
      newRequestSeedData?: Partial<Pick<StagedRequest, "project">>;
      requestCreator: (request: CreateRequestPayload) => Promise<CreateRequestResponse | void>;
      requestDeleter: (request: SerializedFindRequest) => Promise<DeleteRequestResponse | void>;
      requestUpdater: RequestDetailsModalUpdater;
      supportData: NestedComputedAssignmentSupportData;
   }) {
      this.request = (request ??
         pureComputed<StagedRequest>(
            (): StagedRequest => ({
               category_id: null,
               category: null,
               comments: [],
               company_id: authManager.companyId(),
               created_at: Date.now(),
               creator_id: null,
               creator: null,
               custom_fields: [],
               editor_ids: [],
               end_day: null,
               end_time: newRequestSeedData?.project?.daily_end_time ?? null,
               id: null as any,
               instruction_text: null,
               job_title_id: null,
               job_title: null,
               percent_allocated: null,
               project_id: newRequestSeedData?.project?.id ?? null,
               project: newRequestSeedData?.project ?? null,
               quantity: 1,
               start_day: null,
               start_time: newRequestSeedData?.project?.daily_start_time ?? null,
               status_id: null,
               status: null,
               subcategory_id: null,
               subcategory: null,
               tag_ids: [],
               tags: [],
               work_days: {
                  [WorkDay.SUNDAY]: false,
                  [WorkDay.MONDAY]: true,
                  [WorkDay.TUESDAY]: true,
                  [WorkDay.WEDNESDAY]: true,
                  [WorkDay.THURSDAY]: true,
                  [WorkDay.FRIDAY]: true,
                  [WorkDay.SATURDAY]: false,
               },
               work_scope_text: null,
            }),
         )) as PureComputed<SerializedFindRequest | StagedRequest>;
      this.internalState = {
         isDeleting: observable(false),
         isNewRequest: observable(request == null),
         isSaving: observable(false),
         preventSaveFromEditPane: observable(false),
         requestedSizeOfContent: observable(null),
         requestedSizeOfFooter: observable(null),
         requestedSizeOfHeader: observable(null),
         stagedComment: observable(null),
         stagedRequest: observable(JSON.parse(JSON.stringify(toJS(this.request)))),
      };
      this.requestCreator = requestCreator;
      this.requestDeleter = requestDeleter;
      this.requestUpdater = requestUpdater;
      this.supportData = supportData;
   }
}

export class RequestDetailsModal extends ViewModel {
   private readonly state: RequestDetailsModalState;

   readonly customFields: RequestDetailsModalParams["customFields"];
   readonly tags: RequestDetailsModalParams["tags"];

   readonly notificationManagerInstance = notificationManagerInstance;

   readonly headerSize = observable<OptionalSize>({ height: null, width: null });
   readonly footerSize = observable<OptionalSize>({ height: null, width: null });
   readonly contentSize = observable<OptionalSize>({ height: null, width: null });

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const handled = new EventHandler([
         {
            visit: () =>
               event.key == "Enter" &&
               (event.metaKey || event.ctrlKey) &&
               unwrap(this.state.canSave),
            accept: () => this.state.saveRequest(),
         },
         {
            visit: () => event.key == "Escape" && !this.state.hasAnyChanges(),
            accept: () => modalManager.clearModal(),
         },
      ]).handle(event);
      return !handled;
   };

   readonly cancelButton = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Cancel",
            clickedText: "Exiting...",
            confirmText: pureComputed<string>(() => {
               return this.state.hasAnyChanges() ? "Close without saving?" : "";
            }),
            onClick: () => {
               modalManager.clearModal();
            },
         }),
      ],
      size: ButtonSize.LARGE,
   });
   readonly saveButtons = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Save",
            clickedText: "Saving...",
            onClick: async () => {
               if (this.state.canSave()) await this.saveRequest();
            },
            isDisabled: pureComputed(() => !this.state.canSave()),
         }),
      ],
      color: ButtonColor.ORANGE,
      size: ButtonSize.LARGE,
   });
   readonly deleteButtons = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Delete",
            clickedText: "Deleting...",
            confirmText: "Are you sure?",
            onClick: async () => {
               await this.deleteRequest();
            },
            isDisabled: false,
         }),
      ],
      color: ButtonColor.RED,
      size: ButtonSize.LARGE,
   });

   readonly canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS);
   readonly canManageOthersRequest = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_OTHERS_REQUESTS,
   );
   readonly isOwnRequest = pureComputed(
      () => this.state.request().creator_id == authManager.authedUserId(),
   );
   readonly showCommentsOnly = pureComputed(() => {
      if (
         (this.isOwnRequest() && this.canManageRequests) ||
         (!this.isOwnRequest() && this.canManageOthersRequest) ||
         this.state.isNewRequest()
      ) {
         return false;
      }
      return true;
   });

   readonly actionButtons = pureComputed<ModalAction[]>(() => {
      return [
         { buttonComponent: this.cancelButton },
         ...(this.state.isNewRequest() || this.showCommentsOnly()
            ? []
            : [{ buttonComponent: this.deleteButtons }]),
         ...(this.showCommentsOnly() ? [] : [{ buttonComponent: this.saveButtons }]),
      ];
   });

   readonly tabs: ModalTab[];

   private readonly modalFooterParams: ModalFooterParams;
   private readonly modalHeaderParams: ModalHeaderParams;
   private readonly tabbedPaneParams: TabbedPaneParams;

   constructor({
      createRequest,
      customFields,
      deleteRequest,
      newRequestSeedData,
      request,
      requestSize,
      supportData,
      tags,
      updateRequest,
      initialPane = RequestDetailsModalPane.EDIT_REQUEST,
   }: RequestDetailsModalParams) {
      super(template());
      this.state = new RequestDetailsModalState({
         newRequestSeedData,
         request,
         requestCreator: createRequest,
         requestDeleter: deleteRequest,
         requestUpdater: updateRequest,
         supportData,
      });
      this.customFields = customFields;
      this.tags = tags;
      this.state.requestedSizeCombined.subscribe((size) => {
         requestSize(size);
      });
      this.tabs = this.createTabs();

      this.modalFooterParams = {
         actions: this.actionButtons,
         requestSize: this.state.requestSizeForFooter,
      };
      this.modalHeaderParams = {
         title: this.state.isNewRequest() ? "New Request" : "Request Details",
         requestSize: this.state.requestSizeForHeader,
      };
      this.tabbedPaneParams = {
         tabs: this.tabs,
         initialTabIndex: this.tabs.length === 1 ? 0 : initialPane,
         requestSize: this.state.requestSizeForContent,
      };
   }
   readonly createTabs: () => ModalTab[] = () => [
      ...(this.showCommentsOnly()
         ? []
         : [
              {
                 tabName: "Edit Request",
                 component: RequestEditPane.factory({
                    customFields: this.customFields,
                    isNewRequest: this.state.isNewRequest,
                    preventingSave: this.state.preventSaveFromEditPane,
                    request: this.state.request,
                    requestSize: () => {}, // Overwritten by tabbed-pane.
                    setPreventingSave: this.state.setPreventSaveFromEditPane,
                    stagedRequest: this.state.stagedRequest,
                    supportData: this.state.supportData,
                    tags: this.tags,
                    updateRequestDetails: this.updateRequestDetails,
                 }),
                 hasChanges: pureComputed(() => this.state.hasEditPaneChanges()),
              },
           ]),
      ...(this.showCommentsOnly()
         ? []
         : [
              {
                 tabName: "Scope of Work",
                 component: RequestScopeOfWorkPane.factory({
                    request: this.state.request,
                    requestSize: () => {}, // Overwritten by tabbed-pane.
                    updateWorkScopeText: this.state.updateRequestWorkScopeText,
                 }),
                 hasChanges: pureComputed(() => this.state.hasScopeOfWorkChanges()),
              },
           ]),
      ...(this.showCommentsOnly()
         ? []
         : [
              {
                 tabName: "Assignment Instructions",
                 component: RequestAssignmentInstructions.factory({
                    request: this.state.request,
                    requestSize: () => {}, // Overwritten by tabbed-pane.
                    updateInstructionText: this.state.updateInstructionText,
                 }),
                 hasChanges: pureComputed(() => this.state.hasAssignmentInstructionChanges()),
              },
           ]),
      {
         tabName: "Comments",
         component: RequestCommentsPane.factory({
            request: this.state.request,
            requestSize: () => {}, // Overwritten by tabbed-pane.
            stagedCommentContent: pureComputed(() => this.state.stagedComment()?.content ?? null),
            updateNewComment: this.state.updateNewComment,
         }),
         hasChanges: pureComputed(() => this.state.hasCommentChanges()),
      },
   ];

   static factory(params: RequestDetailsModalParams): ComponentArgs<RequestDetailsModalParams> {
      return {
         name: "request-details-modal",
         params,
      };
   }

   private async saveRequest(): Promise<void> {
      if (this.state.isSaving()) {
         const notification = new Notification({
            text: "Save already in progress.",
            icon: Icons.WARNING,
         });
         this.notificationManagerInstance.show(notification);
         return;
      }
      this.state.setIsSaving(true);
      const notification = new ProgressNotification({
         message: "Saving...",
         actions: [],
      });
      this.notificationManagerInstance.show(notification);
      try {
         await this.state.saveRequest();
         notification.success({
            message: "Saved successfully.",
         });
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
      } catch (error) {
         notification.failed({
            message: "Failed to save changes.",
         });
      }
      this.state.setIsSaving(false);
   }

   private async deleteRequest(): Promise<void> {
      if (this.state.isDeleting()) {
         const notification = new Notification({
            text: "Deletion already in progress.",
            icon: Icons.WARNING,
         });
         this.notificationManagerInstance.show(notification);
         return;
      }
      this.state.setIsDeleting(true);
      const notification = new ProgressNotification({
         message: "Deleting...",
         actions: [],
      });
      this.notificationManagerInstance.show(notification);
      try {
         await this.state.deleteRequest();
         notification.success({
            message: "Deleted successfully.",
         });
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
      } catch (error) {
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "request-details-modal_delete";
            event.addMetadata(BUGSNAG_META_TAB.REQUEST, this.state.request());
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });

         notification.failed({
            message: "Failed to delete request.",
         });
      }
      this.state.setIsDeleting(false);
      modalManager.clearModal();
   }

   private updateRequestDetails = (update: Partial<StagedRequest>): void => {
      this.requestUpdateVisitors
         .filter((visitor) => visitor.visit(update))
         .forEach((acceptor) => acceptor.accept(update));
   };

   private readonly requestUpdateVisitors: RequestUpdateVisitor[] = [
      {
         visit: ({ project, project_id }): boolean => {
            return project != null && project_id != null;
         },
         accept: ({ project, project_id }): void => {
            this.state.addStagedUpdateData({
               project: project!,
               project_id: project_id!,
            });
         },
      },
      {
         visit: ({ category, category_id }): boolean => {
            return category !== undefined && category_id !== undefined;
         },
         accept: ({ category, category_id }): void => {
            this.state.addStagedUpdateData({ category, category_id });
         },
      },
      {
         visit: ({ subcategory, subcategory_id }): boolean => {
            return subcategory !== undefined && subcategory_id !== undefined;
         },
         accept: ({ subcategory, subcategory_id }): void => {
            this.state.addStagedUpdateData({ subcategory, subcategory_id });
         },
      },
      {
         visit: ({ job_title, job_title_id }): boolean => {
            return job_title !== undefined && job_title_id !== undefined;
         },
         accept: ({ job_title, job_title_id }): void => {
            this.state.addStagedUpdateData({ job_title, job_title_id });
         },
      },
      {
         visit: ({ status, status_id }): boolean => {
            return status !== undefined && status_id !== undefined;
         },
         accept: ({ status, status_id }): void => {
            this.state.addStagedUpdateData({ status, status_id });
         },
      },
      {
         visit: ({ start_day }): boolean => {
            return start_day !== undefined;
         },
         accept: ({ start_day }): void => {
            this.state.addStagedUpdateData({ start_day });
         },
      },
      {
         visit: ({ end_day }): boolean => {
            return end_day !== undefined;
         },
         accept: ({ end_day }): void => {
            this.state.addStagedUpdateData({ end_day });
         },
      },
      {
         visit: ({ start_time }): boolean => {
            return start_time !== undefined;
         },
         accept: ({ start_time }): void => {
            this.state.addStagedUpdateData({ start_time });
         },
      },
      {
         visit: ({ end_time }): boolean => {
            return end_time !== undefined;
         },
         accept: ({ end_time }): void => {
            this.state.addStagedUpdateData({ end_time });
         },
      },
      {
         visit: ({ percent_allocated }): boolean => {
            return percent_allocated !== undefined;
         },
         accept: ({ percent_allocated }): void => {
            this.state.addStagedUpdateData({ percent_allocated });
         },
      },
      {
         visit: ({ quantity }): boolean => {
            return quantity !== undefined;
         },
         accept: ({ quantity }): void => {
            this.state.addStagedUpdateData({ quantity });
         },
      },
      {
         visit: ({ tag_ids }): boolean => {
            return tag_ids != null;
         },
         accept: ({ tag_ids }): void => {
            this.state.addStagedUpdateData({ tag_ids });
         },
      },
      {
         visit: ({ work_days }): boolean => {
            return work_days != null;
         },
         accept: ({ work_days }): void => {
            this.state.addStagedUpdateData({ work_days });
         },
      },
      {
         visit: ({ custom_fields }): boolean => {
            return custom_fields != null;
         },
         accept: ({ custom_fields }): void => {
            this.state.addStagedUpdateData({ custom_fields });
         },
      },
   ];
}

ko.components.register("request-details-modal", {
   viewModel: RequestDetailsModal,
   template: template(),
   synchronous: true,
});

import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";
import type { GetStatusResponse } from "@laborchart-modules/lc-core-api/dist/api/statuses/get-status";
import type { FindStatusesStreamQueryParams } from "@laborchart-modules/lc-core-api/dist/api/statuses/find-statuses";
import type {
   CreateStatusPayload,
   CreateStatusResponse,
} from "@laborchart-modules/lc-core-api/dist/api/statuses/create-status";
import type {
   UpdateStatusPayload,
   UpdateStatusResponse,
} from "@laborchart-modules/lc-core-api/dist/api/statuses/update-status";
import type { DeleteStatusResponse } from "@laborchart-modules/lc-core-api/dist/api/statuses/delete-status";
import type { StreamReorderStatusesPayload } from "@laborchart-modules/lc-core-api/dist/api/statuses/reorder-statuses";
import { Store } from "@/stores/common/store.core";

export abstract class StatusStore extends Store {
   static createStatus(payload: CreateStatusPayload): StoreJsonResponse<CreateStatusResponse> {
      return StatusStore.requestJson({
         body: payload,
         method: "POST",
         url: "/api/v3/statuses",
      });
   }

   static deleteStatus(statusId: string): StoreJsonResponse<DeleteStatusResponse> {
      return StatusStore.requestJson({
         url: `/api/v3/statuses/${statusId}`,
         method: "DELETE",
      });
   }

   static findStatusesStream(
      query: FindStatusesStreamQueryParams,
   ): StoreStreamResponse<SerializedStatus> {
      return StatusStore.requestStream({
         method: "GET",
         query,
         url: "/api/v3/statuses/stream",
      });
   }

   static getStatus(statusId: string): StoreJsonResponse<GetStatusResponse> {
      return StatusStore.requestJson({
         url: `/api/v3/statuses/${statusId}`,
         method: "GET",
      });
   }

   static reorderStatuses(
      reorderStatuses: Record<number, string>,
   ): StoreStreamResponse<StreamReorderStatusesPayload> {
      return this.requestStream({
         url: "/api/v3/statuses/reorder",
         method: "PATCH",
         body: reorderStatuses,
      });
   }

   static updateStatus(
      statusId: string,
      update: UpdateStatusPayload,
   ): StoreJsonResponse<UpdateStatusResponse> {
      return StatusStore.requestJson({
         url: `/api/v3/statuses/${statusId}`,
         method: "PATCH",
         body: update,
      });
   }
}

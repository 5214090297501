import { authManager } from "@/lib/managers/auth-manager";
import type { PropsWithChildren } from "react";
import React, { createContext, useState, useContext, useEffect } from "react";

const GroupContext = createContext({
   groupId: authManager.selectedGroupId(),
});

export const useGroupContext = () => useContext(GroupContext);

export const GroupContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
   const [groupId, setGroupId] = useState<string>(authManager.selectedGroupId());

   // Since the authManager is legacy KO, we can subscribe to chanages and updated the provider state.
   // In the future this will need to be migrated to React router and use appropriate hooks.

   useEffect(() => {
      const groupIdSub = authManager.selectedGroupId.subscribe((value) => {
         setGroupId(value);
      });
      // When the component unmounts we need to unsubscribe to prevent memory leaks
      return () => {
         groupIdSub.dispose();
      };
   }, []);

   return <GroupContext.Provider value={{ groupId: groupId }}>{children}</GroupContext.Provider>;
};

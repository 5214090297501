import type {
   StoreJsonResponse,
   StoreRequestErrorHandlingOptions,
   StoreStreamResponse,
} from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { StreamedUpdate } from "@laborchart-modules/lc-core-api";
import type {
   UpdateRequestPayload,
   UpdateRequestsPayload,
   UpdateSingleRequestResponse,
} from "@laborchart-modules/lc-core-api/dist/api/requests/update-requests";
import type {
   FindRequestsPaginatedQueryParams,
   FindRequestsPaginatedResponse,
   FindRequestsQueryParams,
   SerializedFindRequest,
} from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type {
   DeleteRequestResponse,
   BatchDeleteRequestsPayload,
} from "@laborchart-modules/lc-core-api/dist/api/requests/delete-request";
import type {
   BatchCreateRequestPayload,
   CreateRequestPayload,
   CreateRequestResponse,
} from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type {
   FillRequestPayload,
   FillRequestResponse,
} from "@laborchart-modules/lc-core-api/dist/api/requests/fill-request";
import type { GetRequestResponse } from "@laborchart-modules/lc-core-api/dist/api/requests/get-request";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";

export abstract class RequestStore extends Store {
   static createRequest(request: CreateRequestPayload): StoreJsonResponse<CreateRequestResponse> {
      return this.requestJson({
         url: "/api/v3/requests",
         method: "POST",
         body: request,
      });
   }

   static batchCreateRequests(
      body: BatchCreateRequestPayload,
   ): StoreJsonResponse<BatchCreateRequestPayload[]> {
      return this.requestJson({
         url: "/api/v3/requests/batch",
         method: "POST",
         body: body,
      });
   }

   static fillRequest(
      requestId: string,
      payload: FillRequestPayload<AuthType.SESSION>,
   ): StoreJsonResponse<FillRequestResponse> {
      return this.requestJson({
         url: `/api/v3/requests/fill/${requestId}`,
         method: "POST",
         body: payload,
      });
   }

   static findRequestsPaginated(
      query: FindRequestsPaginatedQueryParams,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<FindRequestsPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/requests",
         method: "GET",
         query,
         ...errorHandlingOptions,
      });
   }

   static findRequestsStream(
      query: FindRequestsQueryParams,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreStreamResponse<SerializedFindRequest> {
      return this.requestStream({
         url: "/api/v3/requests/stream",
         method: "GET",
         query,
         ...errorHandlingOptions,
      });
   }

   static getRequest(requestId: string): StoreJsonResponse<GetRequestResponse> {
      return this.requestJson({
         method: "GET",
         url: `/api/v3/requests/${requestId}`,
      });
   }

   static updateRequestsStream(
      updates: Array<UpdateRequestsPayload<AuthType.SESSION>>,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/requests/stream",
         method: "PATCH",
         body: updates,
         ...errorHandlingOptions,
      });
   }

   static updateSingleRequest(
      requestId: string,
      update: UpdateRequestPayload<AuthType.SESSION>,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<UpdateSingleRequestResponse> {
      return this.requestJson({
         url: `/api/v3/requests/${requestId}`,
         method: "PATCH",
         body: update,
         ...errorHandlingOptions,
      });
   }

   static deleteRequest(
      requestId: string,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<DeleteRequestResponse> {
      return this.requestJson({
         url: `/api/v3/requests/${requestId}`,
         method: "DELETE",
         ...errorHandlingOptions,
      });
   }

   static batchDelete(updates: BatchDeleteRequestsPayload): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/requests/batch-delete",
         method: "POST",
         body: updates,
      });
   }
}

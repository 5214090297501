import { Dropdown } from "@procore/core-react";
import React from "react";
import type { TableApi } from "@procore/data-table";
import ExportHeader from "./ExportHeader";

export type ExportFileProps = {
   tableApiObj?: TableApi;
   columnNames?: string[];
   exportFileName: string;
};

export const ExportFile = (props: ExportFileProps) => {
   const { tableApiObj, exportFileName } = props;

   //Export to HTML - this will open a new window with the table data and allow the user to print or save as PDF
   const exportToHTMLString = async () => {
      const htmlString =
         (await tableApiObj?.exportToHTMLString({
            header: ExportHeader,
         })) || "";
      const newWindow = window.open("") || window.open();
      newWindow!.document.getElementsByTagName("html")[0].innerHTML = htmlString
         .replace("<html>", "")
         .replace("</html>", "");
   };

   //Export to CSV - this will download the table data as a CSV file
   //
   const exportToCSV = async () => {
      const columnNames = tableApiObj
         ?.getColumnDefinitions()
         ?.map((column: any) => {
            if (
               !column.hidden &&
               !["rowActions", "color", "cf_hex_color_all", "cf_hex_color", "procore_id"].includes(
                  column.field,
               )
            )
               return column.field;
         })
         .filter((column: any) => column);
      tableApiObj?.exportToCSV({
         fileName: exportFileName,
         columnKeys: columnNames,
         skipFooters: true,
         skipGroups: true,
         skipPinnedBottom: true,
         skipPinnedTop: true,
      });
   };

   //Handle the user selection of PDF or CSV
   const handleSelect = async (values: any) => {
      if (values.item === "pdf") {
         exportToHTMLString();
      }
      if (values.item === "csv") {
         exportToCSV();
      }
   };

   return (
      <Dropdown label="Export" variant="secondary" onSelect={(item) => handleSelect(item)}>
         <Dropdown.Item item="pdf">PDF</Dropdown.Item>
         <Dropdown.Item item="csv">CSV</Dropdown.Item>
      </Dropdown>
   );
};

import type { FormFieldValueComponentProps } from "@procore/core-react";
import { FlexList, Switch, Typography, useI18nContext } from "@procore/core-react";
import React from "react";

export function SwitchField(props: Readonly<FormFieldValueComponentProps>) {
   const I18n = useI18nContext();
   const field = props.field.input.name;
   const [checked, setChecked] = React.useState<boolean>(!!props.field.input.value);

   function handleChange() {
      setChecked(!checked);
      props.field.helpers.setValue(!checked);
   }
   return (
      <FlexList space="sm">
         <Switch checked={checked} onChange={handleChange} />
         <Typography intent="body">
            {I18n.t(`views.company.workforce_planning.people.${field}`)}
         </Typography>
      </FlexList>
   );
}

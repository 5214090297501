import { authManager } from "@/lib/managers/auth-manager";
import type { PropsWithChildren } from "react";
import React, { createContext, useContext } from "react";

export enum AuthAction {
   VIEW_PEOPLE = "viewPeople",
   EDIT_PEOPLE_PERMISSIONS = "editPeoplePermissions",
   EDIT_PEOPLE_DETAILS = "editPeopleDetails",
   VIEW_PEOPLE_SENSITIVE = "viewPeopleSensitive",
   EDIT_PEOPLE_SENSITIVE = "editPeopleSensitive",
   VIEW_PEOPLE_TAGS = "viewPeopleTags",
   EDIT_PEOPLE_TAGS = "editPeopleTags",
   VIEW_PEOPLE_ATTACHMENTS = "viewPeopleAttachments",
   EDIT_PEOPLE_ATTACHMENTS = "editPeopleAttachments",
   VIEW_PEOPLE_TIMEOFF = "viewPeopleTimeoff",
   EDIT_PEOPLE_TIMEOFF = "editPeopleTimeoff",
   VIEW_PEOPLE_NOTES = "viewPeopleNotes",
   EDIT_PEOPLE_NOTES = "editPeopleNotes",
   VIEW_PEOPLE_ACTIVITY = "viewPeopleActivity",
   DELETE_PEOPLE = "deletePeople",
   CREATE_PEOPLE = "createPeople",

   // Projects
   VIEW_PROJECT = "viewProject",
   EDIT_PROJECT_DETAILS = "editProjectDetails",
   VIEW_PROJECT_SENSITIVE = "viewProjectSensitive",
   EDIT_PROJECT_SENSITIVE = "editProjectSensitive",
   VIEW_PROJECT_TAGS = "viewProjectTags",
   EDIT_PROJECT_TAGS = "editProjectTags",
   VIEW_PROJECT_ATTACHMENTS = "viewProjectAttachments",
   EDIT_PROJECT_ATTACHMENTS = "editProjectAttachments",
   EDIT_PROJECT_CATEGORIES = "editProjectCategories",
   VIEW_PROJECT_ROLES = "viewProjectRoles",
   EDIT_PROJECT_ROLES = "editProjectRoles",
   VIEW_PROJECT_WAGE_OVERRIDES = "viewProjectWageOverrides",
   EDIT_PROJECT_WAGE_OVERRIDES = "editProjectWageOverrides",
   EDIT_PROJECT_CUSTOM_ALERTS = "editProjectCustomAlerts",
   VIEW_PROJECT_DEFAULT_RECIPIENTS = "viewProjectDefaultRecipients",
   EDIT_PROJECT_DEFAULT_RECIPIENTS = "editProjectDefaultRecipients",
   VIEW_PROJECT_NOTES = "viewProjectNotes",
   EDIT_PROJECT_NOTES = "editProjectNotes",
   VIEW_PROJECT_ACTIVITY = "viewProjectActivity",
   DELETE_PROJECT = "deleteProject",
   CREATE_PROJECT = "createProject",
   VIEW_UNASSOCIATED_PROJECTS = "viewUnassociatedProjects",

   // Requests
   VIEW_REQUESTS = "viewRequests",
   MANAGE_REQUESTS = "manageRequests",
   MANAGE_OTHERS_REQUESTS = "manageOthersRequests",
   VIEW_REQUESTS_NOTES = "viewRequestsNotes",

   // Assignments
   VIEW_ASSIGNMENTS = "viewAssignments",
   MANAGE_ASSIGNMENTS = "manageAssignments",

   // Statuses
   CAN_VIEW_ALL_STATUSES = "canViewAllStatuses",

   // Reports & Exporting
   ALLOW_EXPORTING_DATA = "allowExportingData",

   // Page Specific
   ACCESS_MAP_PAGE = "accessMapPage",
   ACCESS_GANTT_PAGE = "accessGanttPage",
   ACCESS_TOTALS_PAGE = "accessTotalsPage",

   // Messages
   CREATE_MESSAGES = "createMessages",

   // Alerts
   VIEW_ALERTS = "viewAlerts",
   MANAGE_ALERTS = "manageAlerts",

   // Financial
   VIEW_PROJECT_FINANCIALS = "viewProjectFinancials",
   VIEW_PEOPLE_FINANCIALS = "viewPeopleFinancials",

   // Settings
   VIEW_COMPANY_SETTINGS = "viewCompanySettings",
   MANAGE_COMPANY_SETTINGS = "manageCompanySettings",
   VIEW_PERMISSION_SETTINGS = "viewPermissionSettings",
   MANAGE_PERMISSION_SETTINGS = "managePermissionSettings",
   VIEW_GROUP_SETTINGS = "viewGroupSettings",
   MANAGE_GROUP_SETTINGS = "manageGroupSettings",
   VIEW_POSITION_SETTINGS = "viewPositionSettings",
   MANAGE_POSITION_SETTINGS = "managePositionSettings",
   VIEW_TAGS_SETTINGS = "viewTagsSettings",
   MANAGE_TAGS_SETTINGS = "manageTagsSettings",
   VIEW_STATUS_SETTINGS = "viewStatusSettings",
   MANAGE_STATUS_SETTINGS = "manageStatusSettings",
   VIEW_ALERTS_SETTINGS = "viewAlertsSettings",
   MANAGE_ALERTS_SETTINGS = "manageAlertsSettings",
   VIEW_COSTING_SETTINGS = "viewCostingSettings",
   MANAGE_COSTING_SETTINGS = "manageCostingSettings",
   VIEW_CUSTOM_FIELDS_SETTINGS = "viewCustomFieldsSettings",
   MANAGE_CUSTOM_FIELDS_SETTINGS = "manageCustomFieldsSettings",
   VIEW_QR_CODES_SETTINGS = "viewQrCodesSettings",
   MANAGE_QR_CODES_SETTINGS = "manageQrCodesSettings",
   VIEW_NOTIFICATIONS_SETTINGS = "viewNotificationsSettings",
   MANAGE_NOTIFICATIONS_SETTINGS = "manageNotificationsSettings",

   // Misc
   CAN_UNLOCK_USERS = "canUnlockUsers",
}
const PermissionContext = createContext({
   checkAuthAction: (authAction: AuthAction) => authManager.checkAuthAction(authAction as any),
});

const PreferenceContext = createContext({
   getViewPreference: () => authManager.authedUser()?.preferences(),
});

export const usePermissionContext = () => useContext(PermissionContext);

export const useViewPreferenceContext = () => useContext(PreferenceContext);

export const PermissionContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
   const checkAuthAction = React.useCallback((authAction: AuthAction) => {
      return authManager.checkAuthAction(authAction as any);
   }, []);

   return (
      <PermissionContext.Provider value={{ checkAuthAction }}>
         {children}
      </PermissionContext.Provider>
   );
};

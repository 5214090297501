import template from "./project-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { ProjectDropDownPane } from "@/lib/components/drop-downs/panes/project-drop-down-pane";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import { authManager } from "@/lib/managers/auth-manager";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { SerializedProject } from "@laborchart-modules/common/dist/rethink/serializers";
import type { EditorElementParams } from "../common/editor-element-template";
import type { FindProjectsFilter } from "@laborchart-modules/common/dist/reql-builder/procedures/find-projects";

export type ProjectEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   project: PureComputed<SerializedProject | null>;
} & EditorElementParams<SerializedProject | null>;

export class ProjectEditorElement extends ViewModel {
   readonly invalidInputMessage: ProjectEditorElementParams["invalidInputMessage"];
   readonly onChange: ProjectEditorElementParams["onChange"];
   readonly project: ProjectEditorElementParams["project"];
   readonly title: ProjectEditorElementParams["title"];

   readonly projectMediator = pureComputed<SerializedProject | null>({
      read: () => this.project(),
      write: (project) => {
         this.onChange(project);
      },
   });

   readonly dropDown2Params: DropDown2Params<SerializedProject>;

   constructor({
      invalidInputMessage,
      onChange,
      project,
      title = "Project",
   }: ProjectEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.project = project;
      this.title = title;

      const pane = new ProjectDropDownPane({
         transformer: (project) => project,
         filters: [
            {
               name: "Status",
               // TODO: Move FindProjectsFilter to another file in reql-builder so it
               // can be imported without conflicts.
               property: "status" as FindProjectsFilter,
               type: FilterFieldType.SELECT,
               value_sets: [{ value: ProjectStatus.ACTIVE }, { value: ProjectStatus.PENDING }],
            },
         ],
         groupId:
            authManager.selectedGroupId() != "my-groups" ? authManager.selectedGroupId() : null,
      });

      this.dropDown2Params = {
         cellFactory: TextCell.factory<SerializedProject>((item) => item.name),
         panes: [pane as any],
         selectedIds: pureComputed({
            read: () => {
               const project = this.project();
               return new Set(project ? [project.id] : []);
            },
            write: (ids: Set<string>) => {
               const loadedProjects = pane.gridStore()?.rows() ?? [];
               const selectedProject = loadedProjects.find(
                  (project: SerializedProject) => project.id == [...ids][0],
               );
               this.projectMediator(selectedProject ?? null);
            },
         }),
         selectedItem: this.projectMediator,
         selectedItemCellFactory: TextCell.factory<SerializedProject>((item) => item.name),
      };
   }

   static factory(params: ProjectEditorElementParams): ComponentArgs<ProjectEditorElementParams> {
      return {
         name: "project-editor-element",
         params,
      };
   }
}

ko.components.register("project-editor-element", {
   viewModel: ProjectEditorElement,
   template: template(),
   synchronous: true,
});

import type { DetailsCardHeaderProps } from "@/react/prop-types";
import { Plus } from "@procore/core-icons";
import { Box, Button, FlexList, Title, useI18nContext, H2 } from "@procore/core-react";
import React from "react";

export const DetailsCardHeader: React.FC<DetailsCardHeaderProps> = ({
   titleKey,
   helpTextKey,
   buttonTextKey,
   openAddItemModal,
   btnDisabled,
   permission = true,
   icon = <Plus />, // Default to Plus icon
}) => {
   const I18n = useI18nContext();
   return (
      <Box padding="lg">
         <FlexList justifyContent="space-between">
            <Title>
               <Title.Text>
                  <H2>{I18n.t(titleKey)}</H2>
               </Title.Text>
               <Title.Subtext>{I18n.t(helpTextKey)}</Title.Subtext>
            </Title>
            {permission && (
               <Button
                  icon={icon}
                  variant="secondary"
                  onClick={openAddItemModal}
                  disabled={btnDisabled}
               >
                  {I18n.t(buttonTextKey)}
               </Button>
            )}
         </FlexList>
      </Box>
   );
};

import { DEFAULT_START_TIME, DEFAULT_END_TIME, workDays } from "@/react/shared/constants";
import { timeOptions } from "@/lib/utils/timezones";

export const PLACEHOLDER_KEYS = {
   category: {
      project_no_options:
         "views.company.workforce_planning.category_placeholder.project_no_options",
      project_selected_first:
         "views.company.workforce_planning.category_placeholder.project_selected_first",
      select_category: "views.company.workforce_planning.category_placeholder.select_category",
   },
   subCategory: {
      category_no_options:
         "views.company.workforce_planning.subcategory_placeholder.category_no_options",
      category_selected_first:
         "views.company.workforce_planning.subcategory_placeholder.category_selected_first",
      select_subcategory:
         "views.company.workforce_planning.subcategory_placeholder.select_subcategory",
   },
};

// Common Initial Values
export const commonInitialValuesForRequestAndAssignment = {
   resource: null,
   project: null,
   status: null,
   category: null,
   subcategory: null,
   start_date: new Date(),
   end_date: null,
   start_time: {
      id: DEFAULT_START_TIME,
      label: timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
   },
   end_time: {
      id: DEFAULT_END_TIME,
      label: timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
   },
   assignment_by_time: true,
   assignment_by_allocation: false,
   work_days: workDays,
   percent_allocated: 100,
   instruction_text: "",
   work_scope_text: "",
   comments: "",
   author_id: "",
};

export const initialLimitForActivity = 12;

export const translationsLabelsActivityCard = [
   {
      name: "name",
      translation: "views.company.workforce_planning.time_off.name",
   },
   {
      name: "color",
      translation: "views.company.workforce_planning.projects.color",
   },
   {
      name: "status",
      translation: "views.company.workforce_planning.projects.status",
   },
   {
      name: "timezone",
      translation: "views.company.workforce_planning.projects.timezone",
   },
   {
      name: "address_1",
      translation: "views.company.workforce_planning.projects.address",
   },
   {
      name: "address_2",
      translation: "views.company.workforce_planning.projects.address_2",
   },
   {
      name: "city_town",
      translation: "views.company.workforce_planning.projects.city",
   },
   {
      name: "state_province",
      translation: "views.company.workforce_planning.projects.state",
   },
   {
      name: "zipcode",
      translation: "views.company.workforce_planning.projects.postal",
   },
   {
      name: "country",
      translation: "views.company.workforce_planning.projects.country",
   },
   {
      name: "profile_pic_url",
      translation: "views.company.workforce_planning.projects.profile_pic_url",
   },
   {
      name: "job_number",
      translation: "views.company.workforce_planning.projects.job_number",
   },
   {
      name: "project_type",
      translation: "views.company.workforce_planning.projects.project_type",
   },
   {
      name: "start_date",
      translation: "views.company.workforce_planning.start_date",
   },
   {
      name: "end_date",
      translation: "views.company.workforce_planning.end_date",
   },
   {
      name: "daily_start_time",
      translation: "views.company.workforce_planning.time_off.modals.labels.daily_start_time",
   },
   {
      name: "daily_end_time",
      translation: "views.company.workforce_planning.time_off.modals.labels.daily_end_time",
   },
   {
      name: "bid_rate",
      translation: "views.company.workforce_planning.projects.est_avg_rate",
   },
   {
      name: "percent_complete",
      translation: "views.company.workforce_planning.projects.percent_complete",
   },
   {
      name: "customer_name",
      translation: "views.company.workforce_planning.projects.customer",
   },
   {
      name: "group_ids",
      translation: "views.company.workforce_planning.projects.groups",
   },
   {
      name: "custom_fields",
      translation: "views.company.workforce_planning.projects.custom_fields",
   },
   {
      name: "hired_date",
      translation: "views.company.workforce_planning.people.hired_date",
   },
   {
      name: "dob",
      translation: "views.company.workforce_planning.people.birth_date",
   },
   {
      name: "hourly_wage",
      translation: "views.company.workforce_planning.people.hourly_wage",
   },
   {
      name: "email",
      translation: "views.company.workforce_planning.people.email",
   },
   {
      name: "is_male",
      translation: "views.company.workforce_planning.people.gender",
   },
   {
      name: "position_id",
      translation: "views.company.workforce_planning.people.job_title",
   },
   {
      name: "permission_level_id",
      translation: "views.company.workforce_planning.people.permission_level",
   },
   {
      name: "notification_profile_id",
      translation: "views.company.workforce_planning.people.notification_profile",
   },
   {
      name: "emergency_contact_email",
      translation: "views.company.workforce_planning.people.emergency_contact_email",
   },
   {
      name: "emergency_contact_name",
      translation: "views.company.workforce_planning.people.emergency_contact_name",
   },
   {
      name: "emergency_contact_relation",
      translation: "views.company.workforce_planning.people.emergency_contact_relation",
   },
   {
      name: "emergency_contact_number",
      translation: "views.company.workforce_planning.people.emergency_contact_phone",
   },
   {
      name: "profile_pic_url_people",
      translation: "views.company.workforce_planning.people.profile_photo",
   },
   {
      name: "phone",
      translation: "views.company.workforce_planning.people.phone_number",
   },
   {
      name: "language",
      translation: "views.company.workforce_planning.people.language",
   },
   {
      name: "first_name",
      translation: "views.company.workforce_planning.people.first_name",
   },
   {
      name: "last_name",
      translation: "views.company.workforce_planning.people.last_name",
   },
   {
      name: "employee_number",
      translation: "views.company.workforce_planning.people.employee_id",
   },
   {
      name: "lat",
      translation: "views.company.workforce_planning.people.latitude",
   },
   {
      name: "long",
      translation: "views.company.workforce_planning.people.longitude",
   },
];

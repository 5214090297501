import { type FormikHelpers, type FormikProps } from "formik";
import type { CreateRequestTearsheetProps } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   Page,
   H1,
   Box,
   Flex,
   Required,
   FlexList,
   Button,
   Form,
   Card,
   Title,
   useI18nContext,
   Spinner,
   H2,
} from "@procore/core-react";
import type { FC } from "react";
import React, { useEffect } from "react";

import { useGroupContext } from "@/react/providers/group-context-provider";
import { useGetAssignmentCreationSupportDataQuery } from "../assignment/queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { useGetCustomFields, useGetJobTitles } from "../../common/queries/queries";
import type { WorkdayOptions } from "../../labor-plans/prop-types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { formatOptions, generateQuantityOptions } from "@/react/shared/helper";
import { useToastAlertContext } from "@procore/toast-alert";
import { DEFAULT_END_TIME, DEFAULT_START_TIME, workDays } from "@/react/shared/constants";
import type { View } from "@procore/core-react/dist/Form/Form.types";
import { requestSchema } from "./validation-schemas";
import type { Comments, ProjectOptions } from "../types";
import { DropDownType } from "../types";
import type { RequestFormValues, RequestPayload } from "./types";
import { formInitialValues } from "./constants";
import { RequestStore } from "@/stores/request-store.core";
import { authManager } from "@/lib/managers/auth-manager";
import { useDropDownSelectionHandler } from "@/react/shared/hooks/useDropDownSelectionHandler";
import { getCommonFieldsForRequestAndAssignment, getTimeOptionsFromProject } from "../helper";
import { ScheduleCard } from "../shared/schedule-card";
import CustomFieldsCard from "../shared/custom-fields-card";
import { processCustomFieldsFormValues } from "@/react/shared/custom-field-utils";
import type { IdListRecordUpdate } from "@laborchart-modules/lc-core-api/dist/api/shared";
import { DateUtils } from "@/lib/utils/date";
import { timeOptions } from "@/lib/utils/timezones";
import AdditionalInstructionsCard from "../shared/additional-instructions-card";
import CommentsCard from "../shared/comments-card";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import { ProjectStore } from "@/stores/project-store.core";
import { rawRequestToTask } from "../../gantt/gantt-utils";
import { PositionStore } from "@/stores/position-store.core";
import type { SerializedRequest } from "@laborchart-modules/common/dist/rethink/serializers";

export const RequestTearsheetContent: FC<CreateRequestTearsheetProps> = ({
   onClose,
   requestId, // its an optional property so it can be undefined will be defined in the edit Request flow
   projectId, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   categoryId,
   subcategoryId,
   callback,
}) => {
   const authorId = authManager.authedUserId();
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: assignmentCreationSupportData, isLoading: supportDataLoading } =
      useGetAssignmentCreationSupportDataQuery(groupId);
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.REQUEST);
   const {
      categoryOptions,
      subCategoryOptions,
      categoryPlaceHolder,
      subCategoryPlaceHolder,
      handleSelection,
      resetSubCategory,
   } = useDropDownSelectionHandler(assignmentCreationSupportData);

   const { data: jobTitles } = useGetJobTitles(groupId);

   const [selectedWorkDays, setSelectedWorkDays] = React.useState<WorkdayOptions>(workDays);

   const [assignmentByTime, setAssignmentByTime] = React.useState(true);
   const [assignmentByAllocation, setAssignmentByAllocation] = React.useState(false);
   const [canViewAllStatuses, setCanViewAllStatuses] = React.useState<boolean>(false);
   const [loading, setLoading] = React.useState<boolean>(false);
   const [formViewMode, setFormViewMode] = React.useState<ActionMode>(ActionMode.CREATE);
   const [initialValues, setInitialValues] = React.useState<RequestFormValues>(
      formInitialValues(projectId, categoryId, subcategoryId),
   );
   const [filterIntegrationOnly, setFilterIntegrationOnly] = React.useState<boolean>(true);
   const [showQuantity, setShowQuantity] = React.useState<boolean>(true);
   const [showResource, setShowResource] = React.useState<boolean>(false);
   const [commentList, setCommentList] = React.useState<Comments[]>([]);

   const handleClose = () => {
      onClose();
   };

   // Helper function to map data to initial values for the request form, this will be triggered in edit mode
   const mapDataToInitialValues = (data: SerializedFindRequest) => {
      const baseFormValues = {
         job_title: {
            id: data.job_title?.id,
            label: data.job_title?.name,
         },
         project: {
            id: data.project?.id,
            label: data.project?.name,
         },
         status: data.status
            ? {
                 id: data.status.id,
                 label: data.status.name,
              }
            : null,
         category: data.category
            ? {
                 id: data.category.id,
                 label: data.category.name,
              }
            : null,
         subcategory: data.subcategory
            ? {
                 id: data.subcategory.id,
                 label: data.subcategory.name,
              }
            : null,
         start_date: DateUtils.getAttachedDate(data.start_day),
         end_date: DateUtils.getAttachedDate(data.end_day),
         start_time: {
            id: data.start_time ?? DEFAULT_START_TIME,
            label: data.start_time
               ? timeOptions.find(({ id }) => id === data.start_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
         },
         end_time: {
            id: data.end_time ?? DEFAULT_END_TIME,
            label: data.end_time
               ? timeOptions.find(({ id }) => id === data.end_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
         },
         assignment_by_time: data.start_time != null && data.end_time != null,
         assignment_by_allocation: Boolean(data.percent_allocated),
         work_days: data.work_days,
         percent_allocated: data.percent_allocated ?? 100,
         work_scope_text: data.work_scope_text ?? "",
         instruction_text: data.instruction_text ?? "",
         comments: "",
         tags: data.tag_ids,
      };
      // Initialize custom fields in form values
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      // setting local states
      setAssignmentByAllocation(baseFormValues.assignment_by_allocation);
      setAssignmentByTime(baseFormValues.assignment_by_time);
      setSelectedWorkDays(baseFormValues.work_days);
      setFormViewMode(ActionMode.UPDATE);
      setFilterIntegrationOnly(false);
      setShowQuantity(false);
      setShowResource(true);
      setCommentList(data.comments);
      return { ...baseFormValues, ...customFieldValues };
   };

   useEffect(() => {
      const fetchRequestDetails = async () => {
         if (requestId) {
            try {
               const requestDetails: SerializedFindRequest = (
                  await RequestStore.findRequestsPaginated({
                     limit: 1,
                     sort_by: "id",
                     starting_at: requestId,
                     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }).payload
               ).data[0];
               setInitialValues(mapDataToInitialValues(requestDetails));
            } catch (error) {
               showToast.error(I18n.t("views.company.workforce_planning.error"));
            }
         }
      };
      fetchRequestDetails();
   }, [requestId]);

   // this is needed to toggle the category and subcategory options/placeholders based on the project/category selection
   /* istanbul ignore next */
   useEffect(() => {
      if (initialValues.project) {
         handleSelection(initialValues.project, DropDownType.PROJECT);
      }

      if (initialValues.category) {
         handleSelection(initialValues.category, DropDownType.CATEGORY);
      }
   }, [initialValues.project, assignmentCreationSupportData?.data]);

   useEffect(() => {
      const canViewAllStatuses = checkAuthAction(AuthAction.CAN_VIEW_ALL_STATUSES);
      setCanViewAllStatuses(canViewAllStatuses);
   }, [checkAuthAction]);

   const validationSchema = requestSchema(
      I18n,
      !canViewAllStatuses,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
      showQuantity,
   );

   /* istanbul ignore next */
   async function handleSubmit(
      values: RequestFormValues,
      { resetForm }: FormikHelpers<RequestFormValues>,
   ) {
      values.author_id = authorId ?? "";
      const commonFields = getCommonFieldsForRequestAndAssignment(values, customFields);

      const request: RequestPayload = {
         job_title_id: values.job_title!.id,
         tag_ids: values.tags,
         resource_id: values.resource?.id ?? null,
         ...commonFields,
      };
      if (values.quantity) {
         request.quantity = Number(values.quantity.id);
      }
      try {
         setLoading(true);
         const listRequests: SerializedRequest[] = [];
         if (requestId) {
            //logic for preparing the tag_ids field as expected in the update API
            const allTagIds = new Set([...initialValues.tags, ...values.tags]);
            const tagIds = Array.from(allTagIds).reduce((acc: IdListRecordUpdate, tagId) => {
               acc[tagId] = values.tags.includes(tagId); // true if in values.tags, false otherwise
               return acc;
            }, {} as IdListRecordUpdate);

            // overriding the tag_ids field in request payload with the new tag_ids
            request.tag_ids = tagIds;

            const payload = await RequestStore.updateSingleRequest(requestId, request).payload;
            listRequests.push(payload.data);
            handleClose();
         } else {
            const payload = await RequestStore.createRequest(request).payload;
            listRequests.push(...payload.data);
            if (payload.data.length) {
               setFormViewMode(ActionMode.READ);
            }
         }

         // Handle callback method to update the Bryntum Gantt
         if (request && callback) {
            // Let's fetch person name as well so we can return it in the callback
            const [projectPayload, jobTitlePayload] = await Promise.all([
               ProjectStore.getProject(request.project_id).payload,
               PositionStore.getPosition(request.job_title_id).payload,
            ]);

            try {
               const startDay = DateUtils.getAttachedDate(request.start_day);
               const endDay = DateUtils.getAttachedDate(request.end_day);
               let startTime: string | number | null = request.start_time;
               let endTime: string | number | null = request.end_time;

               // if startTime is a float (eg. 14.5 === 2:30pm), we're transforming it to a string in the format of "HH:MM:00"
               if (typeof startTime === "number") {
                  const hours = Math.floor(startTime).toString().padStart(2, "0");
                  const minutes = Math.floor((startTime % 1) * 60)
                     .toString()
                     .padStart(2, "0");
                  startTime = `${hours}:${minutes}:00`;
               }
               // same check & transformation for endTime
               if (typeof endTime === "number") {
                  const hours = Math.floor(endTime).toString().padStart(2, "0");
                  const minutes = Math.floor((endTime % 1) * 60)
                     .toString()
                     .padStart(2, "0");
                  endTime = `${hours}:${minutes}:00`;
               }

               const newTasks = listRequests.map((req: SerializedRequest) => {
                  return rawRequestToTask({
                     request: {
                        id: req.id,
                        ...request,
                        start_day: startDay,
                        end_day: endDay,
                        start_time: startTime,
                        end_time: endTime,
                     },
                     project: projectPayload.data,
                     jobTitles: [jobTitlePayload.data],
                  });
               });

               // All of the possible calendars for requests are already in the CalendarManagerStore, so there's no need
               // to return calendarForNewTask like we do for assignments. Requests calendars are simpler than the many
               // possible assignment calendars, because requests don't have a concept of time-off.
               callback({ newTasks });
            } catch (error) {
               console.error("ERROR IN CALLBACK:", error);
            }
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.request.create_error"));
         resetForm();
      } finally {
         setLoading(false);
      }
   }
   return (
      <Spinner loading={(supportDataLoading || loading) && assignmentCreationSupportData === null}>
         <Page>
            <Page.Main
               style={{
                  width: "950px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <Page.Header>
                  <Page.Title>
                     <H1>
                        {requestId
                           ? I18n.t("views.company.workforce_planning.request.edit_title")
                           : I18n.t("views.company.workforce_planning.request.create_title")}
                     </H1>
                  </Page.Title>
               </Page.Header>
               <Form
                  view={formViewMode as View}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
               >
                  {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                  {({
                     submitForm,
                     isSubmitting,
                     setFieldValue,
                  }: FormikProps<RequestFormValues>) => (
                     <React.Fragment>
                        <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                           <Card style={{ marginBottom: "10px" }}>
                              <Box padding="md">
                                 <FlexList justifyContent="space-between" marginBottom="sm">
                                    <Title>
                                       <Title.Text>
                                          <H2>
                                             {I18n.t(
                                                "views.company.workforce_planning.specifications",
                                             )}
                                          </H2>
                                       </Title.Text>
                                    </Title>
                                 </FlexList>
                                 <Form.Form>
                                    <Form.Row>
                                       {/** Resource drop down will only be visible in edit mode */}
                                       {showResource && (
                                          <Form.Select
                                             colStart={1}
                                             colWidth={6}
                                             name="resource"
                                             label={I18n.t(
                                                "views.company.workforce_planning.resource",
                                             )}
                                             options={formatOptions(
                                                assignmentCreationSupportData?.data
                                                   .resource_options ?? [],
                                             )}
                                             placeholder={I18n.t(
                                                "views.company.workforce_planning.select_option",
                                             )}
                                          />
                                       )}
                                       <Form.Select
                                          colStart={showResource ? 7 : 1} // switching the value of colStart based on the resource visibility
                                          colWidth={6}
                                          name="job_title"
                                          label={I18n.t(
                                             "views.company.workforce_planning.job_title_needed",
                                          )}
                                          required
                                          options={jobTitles}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                       />
                                       {/** Quantity drop down will only be visible in create mode */}
                                       {showQuantity && (
                                          <Form.Select
                                             colStart={7}
                                             colWidth={6}
                                             name="quantity"
                                             label={I18n.t(
                                                "views.company.workforce_planning.quantity",
                                             )}
                                             required
                                             options={generateQuantityOptions(1, 99)}
                                             placeholder={I18n.t(
                                                "views.company.workforce_planning.select_option",
                                             )}
                                             onClear={false}
                                          />
                                       )}
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={4}
                                          name="project"
                                          label={I18n.t("views.company.workforce_planning.project")}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.project_options ??
                                                [],
                                          )}
                                          onSelect={({ item }) => {
                                             const selectedItem =
                                                assignmentCreationSupportData?.data.project_options.find(
                                                   (project) => project.id === item.id,
                                                ) as ProjectOptions;
                                             handleSelection(item, DropDownType.PROJECT);
                                             setFieldValue("category", null);
                                             setFieldValue("subcategory", null);
                                             setFieldValue(
                                                "start_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_START_TIME,
                                                   "daily_start_time",
                                                ),
                                             );
                                             setFieldValue(
                                                "end_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_END_TIME,
                                                   "daily_end_time",
                                                ),
                                             );
                                          }}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                       />
                                       <Form.Select
                                          colStart={5}
                                          colWidth={4}
                                          name="category"
                                          label={I18n.t(
                                             "views.company.workforce_planning.category",
                                          )}
                                          options={categoryOptions}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.CATEGORY);
                                             setFieldValue("subcategory", null);
                                          }}
                                          placeholder={I18n.t(categoryPlaceHolder)}
                                          disabled={categoryOptions.length === 0}
                                          onClear={() => {
                                             resetSubCategory();
                                             setFieldValue("subcategory", null);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={9}
                                          colWidth={4}
                                          name="subcategory"
                                          label={I18n.t(
                                             "views.company.workforce_planning.subcategory",
                                          )}
                                          options={subCategoryOptions}
                                          placeholder={I18n.t(subCategoryPlaceHolder)}
                                          disabled={subCategoryOptions.length === 0}
                                       />
                                    </Form.Row>
                                 </Form.Form>
                              </Box>
                           </Card>
                           {/** form for the schedule card */}
                           <ScheduleCard
                              I18n={I18n}
                              setFieldValue={setFieldValue}
                              assignmentCreationSupportData={assignmentCreationSupportData}
                              selectedWorkDays={selectedWorkDays}
                              setSelectedWorkDays={setSelectedWorkDays}
                              assignmentByTime={assignmentByTime}
                              setAssignmentByTime={setAssignmentByTime}
                              assignmentByAllocation={assignmentByAllocation}
                              setAssignmentByAllocation={setAssignmentByAllocation}
                              canViewAllStatuses={canViewAllStatuses}
                              showTagSelector={true}
                           />
                           {/** form for the additional information/custom field card */}
                           <CustomFieldsCard
                              customFields={customFields}
                              I18n={I18n}
                              filterIntegrationOnly={filterIntegrationOnly}
                           />
                           <AdditionalInstructionsCard />
                           <CommentsCard comments={commentList} />
                        </Page.Body>
                        {formViewMode !== ActionMode.READ && (
                           <Page.Footer>
                              <Box padding="md">
                                 <Flex justifyContent="space-between" alignItems="center">
                                    <Required showLabel />
                                    <FlexList space="sm">
                                       <Button
                                          variant="tertiary"
                                          disabled={isSubmitting}
                                          onClick={handleClose}
                                       >
                                          {I18n.t("views.company.workforce_planning.cancel")}
                                       </Button>
                                       <Button
                                          type="submit"
                                          disabled={isSubmitting}
                                          onClick={submitForm}
                                       >
                                          {requestId
                                             ? I18n.t("views.company.workforce_planning.update")
                                             : I18n.t("views.company.workforce_planning.create")}
                                       </Button>
                                    </FlexList>
                                 </Flex>
                              </Box>
                           </Page.Footer>
                        )}
                     </React.Fragment>
                  )}
               </Form>
            </Page.Main>
         </Page>
      </Spinner>
   );
};

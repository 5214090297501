import { Trash } from "@procore/core-icons";
import { Button, Modal, Spinner, Typography, useI18nContext } from "@procore/core-react";
import React from "react";
import { TimeOffStore } from "@/stores/time-off-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import { useRowSelectionState } from "@procore/data-table";
import type { TimeOffDataTable } from "../time-off-list-prop-types";
import type { TableApi } from "@procore/data-table";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export type DeleteTimeOffModalPropTypes = {
   isBulk?: boolean;
   timeOffId?: string;
   tableApi?: TableApi;
};

export const DeleteTimeOffModal = (props: DeleteTimeOffModalPropTypes) => {
   const { isBulk = false, timeOffId, tableApi } = props;
   const I18n = useI18nContext();
   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const { showToast } = useToastAlertContext();
   const selectedRows = isBulk ? useRowSelectionState().map((x: any) => x.data) : [];

   const handleDelete = async () => {
      try {
         setLoading(true);
         if (isBulk) {
            const ids = selectedRows?.map((x: TimeOffDataTable) => x.id);
            await TimeOffStore.batchDelete({ ids: ids }).stream;
            showToast.success(
               I18n.t("views.company.workforce_planning.time_off.modals.bulk_delete_success", {
                  count: ids.length,
               }),
            );
         } else {
            await TimeOffStore.deleteTimeOff(timeOffId!).payload;
            showToast.success(
               I18n.t("views.company.workforce_planning.time_off.modals.delete_success_toast"),
            );
         }
         setOpen(false);
         setLoading(false);
         tableApi?.refreshServerSide({});
      } catch (err) {
         setLoading(false);
      }
   };

   function getDeleteText() {
      const resourcePlanningRebrand = LaunchDarklyClient.getFlagValue(
         "resource-management-rebrand",
      );

      if (resourcePlanningRebrand) {
         return isBulk
            ? I18n.t("views.company.workforce_planning.time_off.modals.bulk_delete_text_resource", {
                 count: selectedRows.length,
              })
            : I18n.t("views.company.workforce_planning.time_off.modals.delete_text_resource");
      } else {
         return isBulk
            ? I18n.t("views.company.workforce_planning.time_off.modals.bulk_delete_text", {
                 count: selectedRows.length,
              })
            : I18n.t("views.company.workforce_planning.time_off.modals.delete_text");
      }
   }

   return (
      <>
         <Button icon={<Trash />} onClick={() => setOpen(true)} variant="tertiary" />
         <Modal open={open} onClose={() => setOpen(false)} width="md">
            <Modal.Header>
               {I18n.t("views.company.workforce_planning.time_off.modals.delete_header")}
            </Modal.Header>
            <Spinner loading={loading}>
               <Modal.Body>
                  <Typography>{getDeleteText()}</Typography>
               </Modal.Body>
               <Modal.Footer>
                  <Modal.FooterButtons>
                     <Button variant="secondary" onClick={() => setOpen(false)}>
                        {I18n.t("views.company.workforce_planning.cancel")}
                     </Button>
                     <Button variant="primary" onClick={handleDelete}>
                        {I18n.t("views.company.workforce_planning.delete")}
                     </Button>
                  </Modal.FooterButtons>
               </Modal.Footer>
            </Spinner>
         </Modal>
      </>
   );
};

export class GanttMediator
   constructor: (initCallback) ->
      @initCallback = initCallback
      @isInitialized = ko.observable(false)

   redraw: -> throw new Error "redraw not set"
   appendData: -> throw new Error "appendData not set"
   prependData: -> throw new Error "prependData not set"

   initialize: (component) =>
      @redraw = component.redraw if component.redraw?
      @appendData = component.appendData if component.appendData?
      @prependData = component.prependData if component.prependData?
      @isInitialized(true)
      @initCallback() if @initCallback?


import type { ActionMode } from "@/react/prop-types";
import type { ResponsePayload } from "@laborchart-modules/common/dist/api";
import type { CreateNotePayload } from "@laborchart-modules/lc-core-api/dist/api/notes/create-note";
import type { SerializedPersonDetailsPerson } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type { SerializedProjectDetailProject } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";

// Create an extended type that includes procore_id
export type ExtendedDetail =
   | SerializedProjectDetailProject
   | (SerializedPersonDetailsPerson & {
        procore_id?: string; // Optional property
     });

export type ExtendedGetDetailResponse = ResponsePayload<ExtendedDetail>;

export enum NotesType {
   PROJECT = "project",
   PERSON = "person",
}

export type EmptyNotesProps = {
   openAddNotesModal: () => void;
   isAddNotesOpen: boolean;
   canEditNotes: boolean;
};

export type DetailsNotesCardProps = {
   notesData?: ExtendedGetDetailResponse | null;
   refetchData: () => void;
   loading: boolean;
   noteType: NotesType;
   contextId: string;
};

export type AddNotesModalProps = {
   onClose: () => void;
   handleCreateUpdate: (payload: CreateNotePayload, noteId?: string) => Promise<void>;
   showLoader?: boolean;
   mode?: ActionMode;
   initialValues?: any;
   showToolBar?: boolean;
   canEditNotes: boolean;
   handleDelete?: (noteId: string) => Promise<void>;
   noteType?: NotesType;
};

export type ListNotesCardProps = {
   notes: Note[];
   onUpdateNote: (payload: CreateNotePayload, noteId?: string) => Promise<void>;
   selectedNoteId: string;
   setSelectedNoteId: (tagId: string) => void;
   openAddNotesModal: () => void;
   isAddNotesOpen: boolean;
   canEditNotes: boolean;
   onDeleteNote: (noteId: string) => Promise<void>;
   showLoader: boolean;
   noteType?: NotesType;
};

export type Note = {
   author: {
      last: string;
      first: string;
   };
   attachments: any[];
   content: string;
   is_private: boolean;
   id: string;
   last_edited: number;
};

import React from "react";
import { Box, Typography, useI18nContext } from "@procore/core-react";
import "../../style.css";
import type { ActivitiesResponseProps } from "../../types";
import { customFieldsCard, formatCurrency, formatDate } from "../../helper";
import { renderAdd, renderChange, renderRemove } from "../../activities/activity-helper";

export const ProjectActivitiesInfoCard: React.FC<{ activity: ActivitiesResponseProps }> = ({
   activity,
}) => {
   const I18n = useI18nContext();
   const dataCardActivities = (data: ActivitiesResponseProps) => {
      const { meta } = data;
      const { property_name, new_val, old_val } = meta || {};

      if (!meta) return null;

      switch (property_name) {
         case "name":
         case "color":
         case "status":
         case "timezone":
         case "address_1":
         case "address_2":
         case "city_town":
         case "state_province":
         case "zipcode":
         case "country":
         case "profile_pic_url":
         case "job_number":
         case "project_type":
         case "customer_name":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, I18n, old_val, new_val);
            if (new_val === null && old_val !== undefined)
               return renderRemove(property_name, old_val, I18n);
            if (old_val === null && new_val !== undefined)
               return renderAdd(property_name, new_val, I18n);
            break;
         case "start_date":
         case "est_end_date":
            return renderChange(
               property_name,
               I18n,
               formatDate(old_val as number),
               formatDate(new_val as number),
            );
         case "daily_start_time":
         case "daily_end_time":
            return renderChange(property_name, I18n, old_val, new_val);
         case "bid_rate":
            if (new_val !== null && old_val !== null)
               return renderChange(
                  property_name,
                  I18n,
                  formatCurrency(old_val as number, I18n),
                  formatCurrency(new_val as number, I18n),
               );
            if (new_val === null && old_val !== null)
               return renderRemove(property_name, formatCurrency(old_val as number, I18n), I18n);
            if (old_val === null && new_val !== null)
               return renderAdd(property_name, formatCurrency(new_val as number, I18n), I18n);
            break;
         case "percent_complete":
            if (new_val !== null && old_val !== null)
               return renderChange(property_name, I18n, `${old_val}%`, `${new_val}%`);
            if (new_val === null && old_val !== null)
               return renderRemove(property_name, `${old_val}%`, I18n);
            if (old_val === null && new_val !== null)
               return renderAdd(property_name, `${new_val}%`, I18n);
            break;
         case "group_ids":
            return (
               <>
                  <span>
                     {activity?.action_type === "create"
                        ? `${I18n.t(
                             "views.company.workforce_planning.project_details_activities.activity_action.added_project_group_ids",
                          )} `
                        : `${I18n.t(
                             "views.company.workforce_planning.project_details_activities.activity_action.removed_project_group_ids",
                          )} `}
                  </span>
                  <span className="dark-highlighted-text">{`${data.subject_2_name} `}</span>
                  <span>{I18n.t("views.company.workforce_planning.projects.groups")}</span>
               </>
            );
         case "custom_fields":
            return customFieldsCard(data, I18n);
         default:
            return null;
      }
   };

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray30">
            {dataCardActivities(activity)}
         </Typography>
      </Box>
   );
};

import "./rapid-assign-config-pane.styl";
import template from "./rapid-assign-config-pane.pug";
import { defaultStore } from "@/stores/default-store";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import { authManager } from "@/lib/managers/auth-manager";
import { PermissionLevel } from "@/models/permission-level";

/* Popups */
import { PopupPane } from "@/lib/components/popup/popup-pane";
import { DateUtils } from "@/lib/utils/date";

/* UI Assets */
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";
import {
   DayFormat,
   MonthFormat,
   WeekDayFormat,
   YearFormat,
} from "@laborchart-modules/common/dist/datetime/format";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { MessageSendOption } from "../batch-config-pane/batch-config-pane";
import type { ValueSet } from "@/models/value-set";
import { AllocationType } from "../../modals/editors/allocation-type-editor-element/allocation-type-editor-element";
import { EndDayOption } from "../../modals/editors/end-day-editor-element/end-day-editor-element";
import { getLastWorkDateInNumberOfWeeks } from "@/lib/utils/date-2";

// TODO: Move.
export enum RapidAssignTimeType {
   CUSTOM = "custom",
   PROJECT_DEFAULTS = "project-defaults",
}

type CostingData = {
   paidShiftHours: number;
   overtimeDayRates: {
      sunday: number;
      monday: number;
      tuesday: number;
      wednesday: number;
      thursday: number;
      friday: number;
      saturday: number;
   } | null;
};

export type RapidAssignConfigPaneConfig = {
   editWorkDays: Observable<boolean>;
   endDate: Observable<Date | null>;
   endTime: Observable<number | null>;
   messageScheduleDate: Observable<Date | null>;
   messageScheduleTime: Observable<number | null>;
   messageType: Observable<MessageSendOption | null>;
   percentAllocated: Observable<number | null>;
   rapidAssignActive: Observable<boolean>;
   selectedStatus: Observable<ValueSet | null>;
   startDate: Observable<Date | null>;
   startTime: Observable<number | null>;

   overtime: Observable<boolean>;
   overtimeHours: Observable<number | null>;
   straightHours: Observable<number | null>;
   unpaidHours: Observable<number | null>;

   sundayOtRate: Observable<number | null>;
   mondayOtRate: Observable<number | null>;
   tuesdayOtRate: Observable<number | null>;
   wednesdayOtRate: Observable<number | null>;
   thursdayOtRate: Observable<number | null>;
   fridayOtRate: Observable<number | null>;
   saturdayOtRate: Observable<number | null>;

   sunday: Observable<boolean>;
   monday: Observable<boolean>;
   tuesday: Observable<boolean>;
   wednesday: Observable<boolean>;
   thursday: Observable<boolean>;
   friday: Observable<boolean>;
   saturday: Observable<boolean>;
};

const DATE_FORMAT_OPTIONS = {
   weekdayFormat: WeekDayFormat.ABBREV,
   monthFormat: MonthFormat.FULL,
   dayFormat: DayFormat.ONE_DIGIT,
   yearFormat: YearFormat.FULL,
};

class RapidAssignConfigPaneState {
   //#region Local Constants
   readonly allocationOptions = [
      new SegmentedControllerItem("Work Hours", AllocationType.HOURS),
      new SegmentedControllerItem("Percent", AllocationType.PERCENT),
   ];
   readonly endTypeOptions = [
      new SegmentedControllerItem("Date", EndDayOption.DATE),
      new SegmentedControllerItem("Weeks", EndDayOption.WEEKS),
      new SegmentedControllerItem("TBD", EndDayOption.TBD),
   ];
   readonly rapidAssignTimeTypes = [
      new SegmentedControllerItem("Project Defaults", RapidAssignTimeType.PROJECT_DEFAULTS),
      new SegmentedControllerItem("Custom", RapidAssignTimeType.CUSTOM),
   ];
   readonly canViewAllStatuses = authManager.checkAuthAction(
      PermissionLevel.Action.CAN_VIEW_ALL_STATUSES,
   );
   //#endregion

   readonly costingData: CostingData;
   readonly companyTbdWeeks: PureComputed<AssignmentSupportData["companyTbdWeeks"]>;
   readonly groupedCostCodeOptions: PureComputed<AssignmentSupportData["groupedCostCodeOptions"]>;
   readonly groupedLabelOptions: PureComputed<AssignmentSupportData["groupedLabelOptions"]>;
   readonly projectOptions: PureComputed<AssignmentSupportData["projectOptions"]>;
   readonly statusOptions: PureComputed<AssignmentSupportData["statusOptions"]>;

   private readonly internalState: {
      dontSend: Observable<boolean>;
      editWorkDays: Observable<boolean>;
      endDate: Observable<Date | null>;
      endTime: Observable<number | null>;
      messageScheduleDate: Observable<Date | null>;
      messageScheduleTime: Observable<number | null>;
      messageType: Observable<MessageSendOption | null>;
      numberOfWeeks: Observable<number | null>;
      percentAllocated: Observable<number | null>;
      saveDraft: Observable<boolean>;
      schedule: Observable<boolean>;
      selectedAllocationOption: Observable<SegmentedControllerItem<AllocationType>>;
      selectedCostCode: Observable<ValueSet | null>;
      selectedEndType: Observable<SegmentedControllerItem<EndDayOption>>;
      selectedLabel: Observable<ValueSet | null>;
      selectedProject: Observable<ValueSet | null>;
      selectedRapidAssignTimeType: Observable<SegmentedControllerItem<RapidAssignTimeType>>;
      selectedStatus: Observable<ValueSet | null>;
      sendInstantly: Observable<boolean>;
      showingCostCode: Observable<boolean>;
      showingLabel: Observable<boolean>;
      startDate: Observable<Date | null>;
      startTime: Observable<number | null>;
      rapidAssignActive: Observable<boolean>;

      overtime: Observable<boolean>;
      overtimeHours: Observable<number | null>;
      straightHours: Observable<number | null>;
      unpaidHours: Observable<number | null>;

      sundayOtRate: Observable<number | null>;
      mondayOtRate: Observable<number | null>;
      tuesdayOtRate: Observable<number | null>;
      wednesdayOtRate: Observable<number | null>;
      thursdayOtRate: Observable<number | null>;
      fridayOtRate: Observable<number | null>;
      saturdayOtRate: Observable<number | null>;

      sunday: Observable<boolean>;
      monday: Observable<boolean>;
      tuesday: Observable<boolean>;
      wednesday: Observable<boolean>;
      thursday: Observable<boolean>;
      friday: Observable<boolean>;
      saturday: Observable<boolean>;
   };
   //#region Legacy Mediators
   readonly selectedCostCode = pureComputed({
      read: () => this.internalState.selectedCostCode(),
      write: (costCode: ValueSet | null) => {
         if (costCode?.value != this.internalState.selectedCostCode()?.value) {
            this.selectedLabel(null);
         }
         this.internalState.selectedCostCode(costCode);
      },
   });
   readonly selectedLabel = pureComputed({
      read: () => this.internalState.selectedLabel(),
      write: (label: ValueSet | null) => {
         this.internalState.selectedLabel(label);
      },
   });
   readonly selectedProject = pureComputed({
      read: () => this.internalState.selectedProject(),
      write: (project: ValueSet | null) => {
         if (project?.value != this.internalState.selectedProject()?.value) {
            this.selectedCostCode(null);
            this.internalState.showingCostCode(false);
            this.internalState.showingLabel(false);
         }
         this.internalState.selectedProject(project);
      },
   });
   readonly selectedStatus = pureComputed({
      read: () => this.internalState.selectedStatus(),
      write: (status: ValueSet | null) => {
         this.internalState.selectedStatus(status);
      },
   });
   readonly startDate = pureComputed({
      read: () => this.internalState.startDate(),
      write: (startDate: Date | null) => {
         this.internalState.startDate(startDate);
         const endDate = this.endDate();
         if (startDate == null) return;

         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         } else if (endDate == null || startDate > endDate) {
            this.endDate(startDate);
         }
      },
   });

   readonly endDate = pureComputed({
      read: () => this.internalState.endDate(),
      write: (endDate: Date | null) => {
         this.internalState.endDate(endDate);
         const startDate = this.startDate();
         if (endDate != null && (startDate == null || startDate > endDate)) {
            this.startDate(endDate);
         }
      },
   });

   readonly startTime = pureComputed({
      read: () => this.internalState.startTime(),
      write: (startTime) => {
         this.internalState.startTime(startTime);
      },
   });

   readonly endTime = pureComputed({
      read: () => this.internalState.endTime(),
      write: (endTime) => {
         this.internalState.endTime(endTime);
      },
   });

   readonly percentAllocated = pureComputed({
      read: () => this.internalState.percentAllocated(),
      write: (percentAllocated) => {
         this.internalState.percentAllocated(percentAllocated);
      },
   });

   readonly selectedEndType = pureComputed({
      read: () => this.internalState.selectedEndType(),
      write: (endTypeItem) => {
         this.internalState.selectedEndType(endTypeItem);
         const endType = endTypeItem.value();

         if (endType == EndDayOption.DATE) {
            this.numberOfWeeks(null);
         } else if (endType == EndDayOption.TBD) {
            const startDate = this.startDate();
            if (startDate != null) {
               this.numberOfWeeks(this.companyTbdWeeks());
            }
         }
      },
   });

   readonly selectedAllocationOption = pureComputed({
      read: () => this.internalState.selectedAllocationOption(),
      write: (allocationTypeItem) => {
         this.internalState.selectedAllocationOption(allocationTypeItem);
         const allocationType = allocationTypeItem.value();
         if (allocationType === AllocationType.HOURS) {
            this.percentAllocated(null);
         } else if (allocationType === AllocationType.PERCENT) {
            this.startTime(null);
            this.endTime(null);
         }
      },
   });

   readonly selectedRapidAssignTimeType = pureComputed({
      read: () => this.internalState.selectedRapidAssignTimeType(),
      write: (rapidAssignTimeTypeItem) => {
         this.internalState.selectedRapidAssignTimeType(rapidAssignTimeTypeItem);
      },
   });

   readonly numberOfWeeks = pureComputed({
      read: () => this.internalState.numberOfWeeks(),
      write: (numberOfWeeks) => {
         this.internalState.numberOfWeeks(numberOfWeeks);

         const startDate = this.startDate();
         if (startDate != null && numberOfWeeks != null) {
            const endDate = getLastWorkDateInNumberOfWeeks({
               numberOfWeeks,
               startDate,
               workDays: this.workDays(),
            });

            this.endDate(endDate);
         }
      },
   });

   readonly rapidAssignActive = pureComputed({
      read: () => this.internalState.rapidAssignActive(),
      write: (isActive) => {
         this.internalState.rapidAssignActive(isActive);
      },
   });

   readonly editWorkDays = pureComputed({
      read: () => this.internalState.editWorkDays(),
      write: (value) => {
         this.internalState.editWorkDays(value);
      },
   });
   readonly sunday = pureComputed({
      read: () => this.internalState.sunday(),
      write: (value) => {
         this.internalState.sunday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly monday = pureComputed({
      read: () => this.internalState.monday(),
      write: (value) => {
         this.internalState.monday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly tuesday = pureComputed({
      read: () => this.internalState.tuesday(),
      write: (value) => {
         this.internalState.tuesday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly wednesday = pureComputed({
      read: () => this.internalState.wednesday(),
      write: (value) => {
         this.internalState.wednesday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly thursday = pureComputed({
      read: () => this.internalState.thursday(),
      write: (value) => {
         this.internalState.thursday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly friday = pureComputed({
      read: () => this.internalState.friday(),
      write: (value) => {
         this.internalState.friday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly saturday = pureComputed({
      read: () => this.internalState.saturday(),
      write: (value) => {
         this.internalState.saturday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });

   readonly overtime = pureComputed({
      read: () => this.internalState.overtime(),
      write: (overtime) => {
         this.internalState.overtime(overtime);

         const startTime = this.startTime();
         const endTime = this.endTime();
         if (overtime === false || startTime == null || endTime == null) {
            return;
         }

         const hoursDuration =
            startTime < endTime ? endTime - startTime : endTime + (24 - startTime);
         if (hoursDuration <= this.costingData.paidShiftHours + 0.5) {
            this.overtimeHours(0);
            if (hoursDuration <= this.costingData.paidShiftHours) {
               this.straightHours(hoursDuration);
               this.unpaidHours(0);
               return;
            } else {
               this.straightHours(this.costingData.paidShiftHours);
               this.unpaidHours(hoursDuration - this.costingData.paidShiftHours);
               return;
            }
         } else {
            this.straightHours(this.costingData.paidShiftHours);
            this.unpaidHours(0.5);
            this.overtimeHours(hoursDuration - (this.costingData.paidShiftHours + 0.5));
            return;
         }
      },
   });
   readonly straightHours = pureComputed({
      read: () => this.internalState.straightHours(),
      write: (straightHours) => {
         this.internalState.straightHours(straightHours);
      },
   });
   readonly overtimeHours = pureComputed({
      read: () => this.internalState.overtimeHours(),
      write: (overtimeHours) => {
         this.internalState.overtimeHours(overtimeHours);
      },
   });
   readonly unpaidHours = pureComputed({
      read: () => this.internalState.unpaidHours(),
      write: (unpaidHours) => {
         this.internalState.unpaidHours(unpaidHours);
      },
   });
   readonly sundayOtRate = pureComputed({
      read: () => this.internalState.sundayOtRate(),
      write: (value) => {
         this.internalState.sundayOtRate(value);
      },
   });
   readonly mondayOtRate = pureComputed({
      read: () => this.internalState.mondayOtRate(),
      write: (value) => {
         this.internalState.mondayOtRate(value);
      },
   });
   readonly tuesdayOtRate = pureComputed({
      read: () => this.internalState.tuesdayOtRate(),
      write: (value) => {
         this.internalState.tuesdayOtRate(value);
      },
   });
   readonly wednesdayOtRate = pureComputed({
      read: () => this.internalState.wednesdayOtRate(),
      write: (value) => {
         this.internalState.wednesdayOtRate(value);
      },
   });
   readonly thursdayOtRate = pureComputed({
      read: () => this.internalState.thursdayOtRate(),
      write: (value) => {
         this.internalState.thursdayOtRate(value);
      },
   });
   readonly fridayOtRate = pureComputed({
      read: () => this.internalState.fridayOtRate(),
      write: (value) => {
         this.internalState.fridayOtRate(value);
      },
   });
   readonly saturdayOtRate = pureComputed({
      read: () => this.internalState.saturdayOtRate(),
      write: (value) => {
         this.internalState.saturdayOtRate(value);
      },
   });

   readonly sendInstantly = pureComputed({
      read: () => this.internalState.sendInstantly(),
      write: (sendInstantly) => {
         this.internalState.sendInstantly(sendInstantly);
         if (sendInstantly === false) {
            return;
         }
         this.messageType(MessageSendOption.SEND_INSTANTLY);
         this.saveDraft(false);
         this.schedule(false);
         this.dontSend(false);
      },
   });

   readonly saveDraft = pureComputed({
      read: () => this.internalState.saveDraft(),
      write: (saveDraft) => {
         this.internalState.saveDraft(saveDraft);
         if (saveDraft === false) {
            return;
         }
         this.messageType(MessageSendOption.SAVE_DRAFT);
         this.sendInstantly(false);
         this.schedule(false);
         this.dontSend(false);
      },
   });

   readonly schedule = pureComputed({
      read: () => this.internalState.schedule(),
      write: (schedule) => {
         this.internalState.schedule(schedule);
         if (schedule === false) {
            return;
         }
         this.messageType(MessageSendOption.SCHEDULE);
         this.saveDraft(false);
         this.sendInstantly(false);
         this.dontSend(false);
      },
   });

   readonly dontSend = pureComputed({
      read: () => this.internalState.dontSend(),
      write: (dontSend) => {
         this.internalState.dontSend(dontSend);
         if (dontSend === false) {
            return;
         }
         this.messageType(MessageSendOption.DONT_SEND);
         this.saveDraft(false);
         this.schedule(false);
         this.sendInstantly(false);
      },
   });

   readonly messageType = pureComputed({
      read: () => this.internalState.messageType(),
      write: (messageType) => {
         this.internalState.messageType(messageType);
      },
   });

   readonly messageScheduleDate = pureComputed({
      read: () => this.internalState.messageScheduleDate(),
      write: (messageScheduleDate) => {
         this.internalState.messageScheduleDate(messageScheduleDate);
      },
   });

   readonly messageScheduleTime = pureComputed({
      read: () => this.internalState.messageScheduleTime(),
      write: (messageScheduleTime) => {
         this.internalState.messageScheduleTime(messageScheduleTime);
      },
   });
   //#endregion

   //#region Pure Computeds
   readonly allocationIsHours = pureComputed(() => {
      return this.selectedAllocationOption().value() === AllocationType.HOURS;
   });

   readonly assignmentHourDuration = pureComputed(() => {
      const startTime = this.startTime();
      const endTime = this.endTime();
      const allocationIsHours = this.allocationIsHours();
      if (allocationIsHours === false || startTime === null || endTime === null) {
         return "";
      }
      if (startTime < endTime) {
         return endTime - startTime;
      } else if (startTime > endTime) {
         // Overnight
         return endTime + (24 - startTime);
      } else {
         return "";
      }
   });

   readonly tbdEndDateString = pureComputed(() => {
      const selectedEndType = this.selectedEndType().value();
      const startDate = this.startDate();
      const endDate = this.endDate();
      if (selectedEndType != EndDayOption.TBD || startDate == null || endDate == null) {
         return null;
      }
      const tbdWeeks = this.companyTbdWeeks();
      const calculatedDateString = DateUtils.formatDate(
         endDate,
         defaultStore.getDateFormat(),
         DATE_FORMAT_OPTIONS,
      );
      return `${calculatedDateString} - ${tbdWeeks} Weeks`;
   });

   readonly weeksCalculatedDateString = pureComputed(() => {
      const numberOfWeeks = this.numberOfWeeks();
      const startDate = this.startDate();
      const endDate = this.endDate();
      if (endDate == null || numberOfWeeks == null || numberOfWeeks <= 0) {
         return "Enter # of weeks";
      }
      if (startDate == null) {
         return "Select Start Date";
      }
      return DateUtils.formatDate(endDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS);
   });

   readonly filteredCostCodeOptions = pureComputed(() => {
      const costCodeOptions = this.groupedCostCodeOptions();
      const selectedProject = this.selectedProject();

      if (costCodeOptions == null || selectedProject == null) return [];

      if (costCodeOptions[selectedProject.id!] != null) {
         return costCodeOptions[selectedProject.id!].filter(function (code) {
            return code.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly filteredLabelOptions = pureComputed(() => {
      const labelOptions = this.groupedLabelOptions();
      const selectedProject = this.selectedProject();
      const selectedCostCode = this.selectedCostCode();

      if (labelOptions == null || selectedProject == null || selectedCostCode == null) return [];

      if (labelOptions[selectedCostCode.value()] != null) {
         return labelOptions[selectedCostCode.value()].filter(function (label) {
            return label.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly workDays = pureComputed(() => {
      return {
         0: this.internalState.sunday(),
         1: this.internalState.monday(),
         2: this.internalState.tuesday(),
         3: this.internalState.wednesday(),
         4: this.internalState.thursday(),
         5: this.internalState.friday(),
         6: this.internalState.saturday(),
      };
   });

   readonly rightActionBtnEnabled = pureComputed(() => {
      return (
         this.startDate() != null &&
         this.endDate() != null &&
         ((this.allocationIsHours() &&
            this.selectedRapidAssignTimeType().value() === RapidAssignTimeType.PROJECT_DEFAULTS) ||
            (this.allocationIsHours() && this.startTime() != null && this.endTime() != null) ||
            (!this.allocationIsHours() && this.percentAllocated() != null)) &&
         (this.sendInstantly() ||
            this.saveDraft() ||
            this.dontSend() ||
            (this.schedule() &&
               this.messageScheduleDate() != null &&
               this.messageScheduleTime() != null)) &&
         (this.sunday() ||
            this.monday() ||
            this.tuesday() ||
            this.wednesday() ||
            this.thursday() ||
            this.friday() ||
            this.saturday()) &&
         (this.canViewAllStatuses === true || this.selectedStatus() != null)
      );
   });

   readonly usingCustomRapidTimes = pureComputed(() => {
      return this.selectedRapidAssignTimeType().value() === RapidAssignTimeType.CUSTOM;
   });

   readonly allowOvertimeControls = pureComputed(() => {
      return this.costingData.overtimeDayRates != null;
   });

   readonly paySplitInvalid = pureComputed(() => {
      const accountedHours =
         Number(this.straightHours()) + Number(this.overtimeHours()) + Number(this.unpaidHours());
      const startTime = this.startTime();
      const endTime = this.endTime();
      if (startTime == null || endTime == null) {
         return true;
      }
      const hoursDuration = startTime < endTime ? endTime - startTime : endTime + (24 - startTime);
      return !(accountedHours === hoursDuration);
   });
   //#endregion

   constructor({
      config,
      supportData,
      costingData,
   }: {
      config: RapidAssignConfigPaneConfig;
      supportData: NestedComputedAssignmentSupportData;
      costingData: CostingData;
   }) {
      this.costingData = costingData;

      this.companyTbdWeeks = supportData.companyTbdWeeks;
      this.groupedCostCodeOptions = supportData.groupedCostCodeOptions;
      this.groupedLabelOptions = supportData.groupedLabelOptions;
      this.projectOptions = supportData.projectOptions;
      this.statusOptions = supportData.statusOptions;

      this.internalState = {
         dontSend: observable(true),
         numberOfWeeks: observable(null),
         saveDraft: observable(false),
         schedule: observable(false),
         selectedAllocationOption: observable(this.allocationOptions[0]),
         selectedCostCode: observable(null),
         selectedEndType: observable(this.endTypeOptions[0]),
         selectedLabel: observable(null),
         selectedProject: observable(null),
         selectedRapidAssignTimeType: observable(this.rapidAssignTimeTypes[0]),
         sendInstantly: observable(false),
         showingCostCode: observable(false),
         showingLabel: observable(false),

         editWorkDays: config.editWorkDays,
         endDate: config.endDate,
         endTime: config.endTime,
         messageScheduleDate: config.messageScheduleDate,
         messageScheduleTime: config.messageScheduleTime,
         messageType: config.messageType,
         percentAllocated: config.percentAllocated,
         rapidAssignActive: config.rapidAssignActive,
         selectedStatus: config.selectedStatus,
         startDate: config.startDate,
         startTime: config.startTime,

         overtime: config.overtime,
         overtimeHours: config.overtimeHours,
         straightHours: config.straightHours,
         unpaidHours: config.unpaidHours,

         sundayOtRate: config.sundayOtRate,
         mondayOtRate: config.mondayOtRate,
         tuesdayOtRate: config.tuesdayOtRate,
         wednesdayOtRate: config.wednesdayOtRate,
         thursdayOtRate: config.thursdayOtRate,
         fridayOtRate: config.fridayOtRate,
         saturdayOtRate: config.saturdayOtRate,

         sunday: config.sunday,
         monday: config.monday,
         tuesday: config.tuesday,
         wednesday: config.wednesday,
         thursday: config.thursday,
         friday: config.friday,
         saturday: config.saturday,
      };

      const messageType = this.messageType();
      if (messageType != null && messageType !== MessageSendOption.DONT_SEND) {
         if (messageType === MessageSendOption.SAVE_DRAFT) {
            this.saveDraft(true);
         } else if (messageType === MessageSendOption.SCHEDULE) {
            this.schedule(true);
         } else if (messageType === MessageSendOption.SEND_INSTANTLY) {
            this.sendInstantly(true);
         }
      } else {
         this.messageType(MessageSendOption.DONT_SEND);
      }

      if (this.costingData.overtimeDayRates != null) {
         this.sundayOtRate(this.costingData.overtimeDayRates.sunday);
         this.mondayOtRate(this.costingData.overtimeDayRates.monday);
         this.tuesdayOtRate(this.costingData.overtimeDayRates.tuesday);
         this.wednesdayOtRate(this.costingData.overtimeDayRates.wednesday);
         this.thursdayOtRate(this.costingData.overtimeDayRates.thursday);
         this.fridayOtRate(this.costingData.overtimeDayRates.friday);
         this.saturdayOtRate(this.costingData.overtimeDayRates.saturday);
      }

      if (this.rapidAssignActive() && this.startTime() != null && this.endTime() != null) {
         this.selectedRapidAssignTimeType(this.rapidAssignTimeTypes[1]);
      } else {
         this.selectedRapidAssignTimeType(this.rapidAssignTimeTypes[0]);
      }
   }

   readonly activateHandler = () => {
      if (!this.rightActionBtnEnabled()) {
         return;
      }
      this.rapidAssignActive(true);
   };

   readonly deactivate = () => {
      this.startDate(null);
      this.endDate(null);
      this.startTime(null);
      this.endTime(null);
      this.percentAllocated(null);
      this.selectedRapidAssignTimeType(this.rapidAssignTimeTypes[0]);
      this.sunday(false);
      this.monday(true);
      this.tuesday(true);
      this.wednesday(true);
      this.thursday(true);
      this.friday(true);
      this.saturday(false);
      this.sendInstantly(false);
      this.messageScheduleDate(null);
      this.messageScheduleTime(null);
      this.rapidAssignActive(false);
      this.selectedStatus(null);
   };
}

export class RapidAssignConfigPane extends PopupPane {
   readonly state: RapidAssignConfigPaneState;

   readonly enableOverflow = pureComputed(() => true);

   constructor(
      config: RapidAssignConfigPaneConfig,
      supportData: NestedComputedAssignmentSupportData,
      costingData: CostingData,
   ) {
      super(template());

      this.state = new RapidAssignConfigPaneState({
         config,
         costingData,
         supportData,
      });

      this.rightActionBtn({
         text: "Activate",
         callback: this.activateHandler,
      });
      this.leftActionBtn({
         text: "Close",
         callback: this.closePopup,
      });
      this.rightActionBtnEnabled = this.state.rightActionBtnEnabled;

      if (this.state.rapidAssignActive() == true) {
         this.rightActionBtn({
            text: "Set",
            callback: this.activateHandler,
         });
      }
   }

   readonly activateHandler = (): void => {
      this.state.activateHandler();
      this.dismissHandler!();
   };

   readonly deactivate = (): void => {
      this.state.deactivate();
      this.dismissHandler!();
   };

   readonly closePopup = (): void => {
      return this.dismissHandler!();
   };
}

import "./overtime-rates-editor-element.styl";
import template from "./overtime-rates-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";
import { TextFieldType } from "@/lib/components/text-field/text-field";
import { WorkDay } from "../work-day-editor-element/work-day-editor-element";

// TODO: Move to common/schemas.
export type OvertimeRates = Record<WorkDay, number>;

export type OvertimeRatesEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   overtimeRates: PureComputed<OvertimeRates | null>;
} & EditorElementParams<Partial<OvertimeRates> | null>;

export class OvertimeRatesEditorElement extends ViewModel {
   readonly invalidInputMessage: OvertimeRatesEditorElementParams["invalidInputMessage"];
   readonly isDisabled: OvertimeRatesEditorElementParams["isDisabled"];
   readonly onChange: OvertimeRatesEditorElementParams["onChange"];
   readonly title: OvertimeRatesEditorElementParams["title"];
   readonly overtimeRates: OvertimeRatesEditorElementParams["overtimeRates"];

   readonly overtimeRateMediators = [
      WorkDay.SUNDAY,
      WorkDay.MONDAY,
      WorkDay.TUESDAY,
      WorkDay.WEDNESDAY,
      WorkDay.THURSDAY,
      WorkDay.FRIDAY,
      WorkDay.SATURDAY,
   ].map((day) => ({
      name: this.getDayAbbreviation(day),
      mediator: pureComputed<string | null>({
         read: () => {
            const val = this.overtimeRates();
            return val ? val[day].toString() : null;
         },
         write: (rate) => {
            const rateAsNumber = Number(rate);
            this.onChange({ [day]: isNaN(rateAsNumber) ? 0 : rateAsNumber });
         },
      }),
   }));

   private readonly rateFieldParams: Omit<TextFieldParams, "value">;

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      overtimeRates,
   }: OvertimeRatesEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.overtimeRates = overtimeRates;

      this.rateFieldParams = {
         isClearable: pureComputed(() => false),
         isDisabled,
         type: TextFieldType.NUMBER,
      };
   }

   getDayAbbreviation(day: WorkDay): string {
      const abbreviations: Record<WorkDay, string> = {
         [WorkDay.SUNDAY]: "Sun",
         [WorkDay.MONDAY]: "Mon",
         [WorkDay.TUESDAY]: "Tue",
         [WorkDay.WEDNESDAY]: "Wed",
         [WorkDay.THURSDAY]: "Thu",
         [WorkDay.FRIDAY]: "Fri",
         [WorkDay.SATURDAY]: "Sat",
      };
      return abbreviations[day];
   }

   static factory(
      params: OvertimeRatesEditorElementParams,
   ): ComponentArgs<OvertimeRatesEditorElementParams> {
      return {
         name: "overtime-rates-editor-element",
         params,
      };
   }
}

ko.components.register("overtime-rates-editor-element", {
   viewModel: OvertimeRatesEditorElement,
   template: template(),
   synchronous: true,
});

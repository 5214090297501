import "./person-type-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./person-type-cell.pug";
import type { MaybeSubscribable, ObservableArray } from "knockout";
import ko, { observableArray, unwrap } from "knockout";

export type PersonTypeCellParams = {
   isAssignable: MaybeSubscribable<boolean>;
   isInvitePending: MaybeSubscribable<boolean>;
   isUser: MaybeSubscribable<boolean>;
};

enum PersonType {
   ASSIGNABLE = "assignable",
   INVITE_PENDING = "invite-pending",
   USER = "user",
}

export class PersonTypeCell {
   readonly types: ObservableArray<PersonType>;

   constructor(params: PersonTypeCellParams) {
      const isAssignable = unwrap(params.isAssignable);
      const isInvitePending = unwrap(params.isInvitePending);
      const isUser = unwrap(params.isUser);
      const items = [];
      if (isAssignable) items.push(PersonType.ASSIGNABLE);
      if (isUser && isInvitePending) {
         items.push(PersonType.INVITE_PENDING);
      } else if (isUser && !isInvitePending) {
         items.push(PersonType.USER);
      }

      this.types = observableArray(items);
   }

   static factory<T>(
      formatter: (data: T) => PersonTypeCellParams,
   ): GridCellFactory<T, PersonTypeCellParams> {
      return (data: T): GridCell<PersonTypeCellParams> => {
         return {
            component: {
               name: "person-type-cell",
               params: formatter(data),
            },
            height: 16,
         };
      };
   }
}

ko.components.register("person-type-cell", {
   viewModel: PersonTypeCell,
   template: template(),
   synchronous: true,
});

import { PositionStore } from "@/stores/position-store.core";
import { observable } from "knockout";
import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers";
import type { KeysetGridStoreParams, ResponsePayload } from "../../grid/keyset-grid-store";
import { KeysetGridStore } from "../../grid/keyset-grid-store";
import type { StoreStreamResponse } from "@/stores/common/store.core";
import type { FindPositionsPaginatedQueryParams } from "@laborchart-modules/lc-core-api/dist/api/positions/find-positions";
import { authManager } from "@/lib/managers/auth-manager";

export type SerializedPositionTransformer<T extends ItemBase> = (position: SerializedPosition) => T;

/** `DropDownStore` backed by job title (position) options. */
export class JobTitleDropDownPane<T extends ItemBase = SerializedPosition>
   implements DropDownPane<T>
{
   // TODO: Check on this one
   readonly gridStore = observable<any>(null);
   readonly isSearchable = observable(true);

   private readonly transformer: (position: SerializedPosition) => T;
   private readonly groupIds: Set<string> | null;

   constructor({
      transformer,
      groupIds = null,
   }: {
      transformer: (position: SerializedPosition) => T;
      groupIds?: Set<string> | null;
   }) {
      this.groupIds = groupIds;
      this.transformer = transformer;
   }

   loadItems(params: LoadItemsParams): void {
      if (params.startingAt) {
         PositionStore.getPosition(params.startingAt).payload.then(({ data }) => {
            const companyGroupIds = authManager.getContextAccessibleGroupIds();
            const startingAt =
               data.globally_accessible || data.group_ids.some((id) => companyGroupIds.has(id))
                  ? params.startingAt
                  : null;
            this.gridStore(
               this.createGridStore({
                  ...params,
                  startingAt,
               }),
            );
         });
      } else {
         this.gridStore(this.createGridStore(params));
      }
   }

   private createGridStore(params: LoadItemsParams) {
      return new PositionGridStore({
         queryParams: {
            limit: 20,
            group_ids: this.groupIds != null ? [...this.groupIds] : [],
            name: params.search ?? undefined,
         },
         startingCursor: params.startingAt ? { startingAt: params.startingAt } : null,
         transformer: this.transformer,
      });
   }

   static create(
      params: {
         groupIds?: Set<string> | null;
      } = {},
   ): JobTitleDropDownPane<SerializedPosition> {
      return new JobTitleDropDownPane({
         ...params,
         transformer: (position) => position,
      });
   }
}

type PositionGridStoreParams<T extends ItemBase> =
   KeysetGridStoreParams<FindPositionsPaginatedQueryParams> & {
      transformer: SerializedPositionTransformer<T>;
   };

class PositionGridStore<T extends ItemBase = SerializedPosition> extends KeysetGridStore<
   T,
   FindPositionsPaginatedQueryParams
> {
   private readonly transformer: SerializedPositionTransformer<T>;

   constructor(params: PositionGridStoreParams<T>) {
      super(params);
      this.transformer = params.transformer;
   }

   async loadRows(queryParams: FindPositionsPaginatedQueryParams): Promise<ResponsePayload<T>> {
      const payload = await PositionStore.findPositionsPaginated(queryParams).payload;
      return {
         data: payload.data.map(this.transformer),
         pagination: payload.pagination,
      };
   }

   createLoadAllRowsStream(): StoreStreamResponse<T> {
      throw new Error("Not implemented.");
   }
}

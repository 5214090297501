import "./end-day-editor-element.styl";
import template from "./end-day-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import {
   DayFormat,
   MonthFormat,
   WeekDayFormat,
   YearFormat,
} from "@laborchart-modules/common/dist/datetime";
import type {
   SegmentedController2Option,
   SegmentedController2Params,
} from "@/lib/components/segmented-controller-2/segmented-controller-2";
import { DateUtils } from "@/lib/utils/date";
import { defaultStore } from "@/stores/default-store";
import type { DateInput2Params } from "@/lib/components/date-input-2/date-input-2";
import type { EditorElementParams } from "../common/editor-element-template";

export type EndDayEditorElementParams = {
   endDay: PureComputed<Date | null>;
   endDayNumWeeks: PureComputed<number | null>;
   endDayOption: PureComputed<EndDayOption>;
   projectHasEndDay: PureComputed<boolean>;
   invalidInputMessage: PureComputed<string | null>;
   onEndDayChange: (day: Date | null) => void;
   onEndDayNumWeeksChange: (endDayNumWeeks: number | null) => void;
   onEndDayOptionChange: (option: EndDayOption) => void;
   title: string;
} & Omit<EditorElementParams<any>, "onChange">;

export const enum EndDayOption {
   DATE = "date",
   PROJECT_END = "project-end",
   TBD = "tbd",
   WEEKS = "weeks",
}

export class EndDayEditorElement extends ViewModel {
   readonly endDay: EndDayEditorElementParams["endDay"];
   readonly endDayNumWeeks: EndDayEditorElementParams["endDayNumWeeks"];
   readonly endDayOption: EndDayEditorElementParams["endDayOption"];
   readonly projectHasEndDay: EndDayEditorElementParams["projectHasEndDay"];
   readonly invalidInputMessage: EndDayEditorElementParams["invalidInputMessage"];
   readonly onEndDayChange: EndDayEditorElementParams["onEndDayChange"];
   readonly onEndDayNumWeeksChange: EndDayEditorElementParams["onEndDayNumWeeksChange"];
   readonly onEndDayOptionChange: EndDayEditorElementParams["onEndDayOptionChange"];
   readonly title: EndDayEditorElementParams["title"];

   readonly isOptionDate = pureComputed(() => this.endDayOption() === EndDayOption.DATE);
   readonly isOptionProjectEnd = pureComputed(
      () => this.endDayOption() === EndDayOption.PROJECT_END,
   );
   readonly isOptionTbd = pureComputed(() => this.endDayOption() === EndDayOption.TBD);
   readonly isOptionWeeks = pureComputed(() => this.endDayOption() === EndDayOption.WEEKS);

   readonly dateInputParams: DateInput2Params;
   readonly segmentedControllerParams: SegmentedController2Params<EndDayOption>;

   readonly isClearable = pureComputed(() => false);

   readonly endDayWeeksMediator = pureComputed({
      read: () => unwrap(this.endDayNumWeeks),
      write: (weeks: number | null) => {
         this.onEndDayNumWeeksChange(weeks);
      },
   });

   // Formatted date text.
   readonly formattedDateText = pureComputed(() => {
      const endDay = unwrap(this.endDay);
      if (endDay == null) return null;
      const formattedDate = DateUtils.formatDate(endDay, defaultStore.getDateFormat(), {
         weekdayFormat: WeekDayFormat.ABBREV,
         monthFormat: MonthFormat.FULL,
         dayFormat: DayFormat.ONE_DIGIT,
         yearFormat: YearFormat.FULL,
      });
      return `${formattedDate} - ${unwrap(this.endDayNumWeeks)} weeks`;
   });

   constructor({
      endDay,
      endDayNumWeeks,
      endDayOption,
      projectHasEndDay,
      invalidInputMessage,
      onEndDayChange,
      onEndDayNumWeeksChange,
      onEndDayOptionChange,
      title = "End Day",
   }: EndDayEditorElementParams) {
      super(template());
      this.endDay = endDay;
      this.endDayNumWeeks = endDayNumWeeks;
      this.endDayOption = endDayOption;
      this.projectHasEndDay = projectHasEndDay;
      this.invalidInputMessage = invalidInputMessage;
      this.onEndDayChange = onEndDayChange;
      this.onEndDayNumWeeksChange = onEndDayNumWeeksChange;
      this.onEndDayOptionChange = onEndDayOptionChange;
      this.title = title;

      this.dateInputParams = {
         date: pureComputed<Date | null>(() => this.endDay()),
         isInvalid: pureComputed(() => this.invalidInputMessage() != null),
         onDateChange: (endDay) => {
            this.onEndDayChange(endDay);
         },
      };
      this.segmentedControllerParams = {
         onChange: this.onEndDayOptionChange,
         options: pureComputed<Array<SegmentedController2Option<EndDayOption>>>(() => [
            { name: "Date", value: EndDayOption.DATE, isSelected: this.isOptionDate },
            { name: "Weeks", value: EndDayOption.WEEKS, isSelected: this.isOptionWeeks },
            ...(this.projectHasEndDay()
               ? [
                    {
                       name: "Project End",
                       value: EndDayOption.PROJECT_END,
                       isSelected: this.isOptionProjectEnd,
                    },
                 ]
               : [{ name: "TBD", value: EndDayOption.TBD, isSelected: this.isOptionTbd }]),
         ]),
         requestSize: () => {},
      };
   }

   static factory(params: EndDayEditorElementParams): ComponentArgs<EndDayEditorElementParams> {
      return {
         name: "end-day-editor-element",
         params,
      };
   }
}

ko.components.register("end-day-editor-element", {
   viewModel: EndDayEditorElement,
   template: template(),
   synchronous: true,
});

import type { ComponentArgs } from "@/lib/components/common/component-args";
import type { ModalAction } from "@/lib/components/modals/common/modal-footer";
import type { CanRequestSize } from "@/lib/components/modals/common/sized-modal";
import type { CreatePermissionLevelPayload } from "@laborchart-modules/lc-core-api/dist/api/permissions/create-permission";
import type { Observable, ObservableArray, Computed, PureComputed } from "knockout";
import template from "./permission-level-modal.pug";
import "./permission-level-modal.styl";
import { ViewModel } from "@/lib/vm/viewmodel";
import { observable, observableArray, pureComputed, toJSON, toJS, components } from "knockout";
import { authManager } from "@/lib/managers/auth-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import type { SerializedPermissionLevel } from "@laborchart-modules/common/dist/rethink/serializers/permission-level-serializer";
import {
   Icons,
   notificationManagerInstance,
   Notification,
} from "@/lib/managers/notification-manager";
import { ProgressNotification } from "@/notifications/progress-notification";
import { ButtonColor, ButtonSize } from "@/lib/utils/buttons";
import { ButtonDropDown, ButtonItem } from "@/lib/components/button-drop-down/button-drop-down";
import { PermissionStore } from "@/stores/permission-store.core";
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import type { DropDown2Params } from "../../drop-downs/drop-down-2";
import type { MultiSelectItem } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";
import type { SearchBarParams } from "../../search-bar/search-bar";
import { SettingsStore } from "@/stores/settings-store.core";

export enum PermissionLevelModalType {
   CREATE_PERMISSION = "create-permission",
   EDIT_PERMISSION = "edit-permission",
}

export type PermissionLevelModalParams = {
   activeEditPermission: SerializedPermissionLevel | null;
   modalType: PermissionLevelModalType;
   afterSave: (permissionLevel: SerializedPermissionLevel) => void;
} & CanRequestSize;

type Permission = {
   value: PureComputed<boolean>;
   name: string;
   details: string;
};

type PermissionGroup = {
   name: string;
   permissions: Permission[];
};

export class PermissionLevelModal extends ViewModel {
   private readonly modalType: PermissionLevelModalType;
   private readonly state: PermissionLevelModalState;
   private readonly afterSave: (permissionLevel: SerializedPermissionLevel) => void;
   private readonly canSave: PureComputed<boolean>;
   private readonly modalTitle: string;
   private readonly statuses: ObservableArray<SerializedStatus>;
   private readonly statusDropDownParams: DropDown2Params<MultiSelectItem<SerializedStatus>>;

   constructor(params: PermissionLevelModalParams) {
      super(template());
      this.modalType = params.modalType;
      this.state = new PermissionLevelModalState(params.activeEditPermission);
      this.modalTitle =
         this.modalType == PermissionLevelModalType.CREATE_PERMISSION
            ? "New Permission"
            : "Edit Permission";
      this.afterSave = params.afterSave;

      this.canSave = pureComputed(() => {
         return (
            this.state.name() != "" &&
            this.state.enabledCount() > 0 &&
            this.state.hasPendingChanges()
         );
      });

      this.statuses = observableArray();
      this.loadStatuses();

      const statusMultiSelectPane = new MultiSelectDropDownPane({
         items: this.statuses,
         selectedIds: this.state.selectedStatusIds,
         searchTextProvider: (label) => label.name,
         textProvider: (label) => label.name,
      });
      this.statusDropDownParams = {
         panes: [statusMultiSelectPane],
         placeholder: "No Visible Statuses Selected",
         selectedIds: this.state.selectedStatusIds,
         isClearable: true,
         cellFactory: statusMultiSelectPane.cellFactory,
         actionInterceptor: statusMultiSelectPane.actionInterceptor,
         selectionDescriptionProvider: () => {
            const selectedSize: number = this.state.selectedStatusIds().size;
            if (selectedSize == 1) {
               return `${selectedSize} Visible Status`;
            } else if (selectedSize > 1) {
               return `${selectedSize} Visible Statuses`;
            }
            return null;
         },
      };
   }

   private async loadStatuses() {
      const statuses = [];
      const stream = await SettingsStore.findStatusesStream({}).stream;
      for await (const status of stream) {
         statuses.push(status);
      }
      this.statuses(statuses);
   }

   /* ACTION BUTTONS */
   private readonly saveButton = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Save",
            clickedText: "Saving...",
            onClick: async () => {
               if (this.canSave()) await this.savePermission();
            },
            isDisabled: pureComputed(() => !this.canSave()),
         }),
      ],
      color: ButtonColor.ORANGE,
      size: ButtonSize.LARGE,
   });

   private readonly cancelButton = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Cancel",
            clickedText: "Exiting...",
            confirmText: pureComputed<string>(() => {
               return this.state.hasPendingChanges() ? "Close without saving?" : "";
            }),
            onClick: () => {
               modalManager.clearModal();
            },
         }),
      ],
      size: ButtonSize.LARGE,
   });

   private readonly actionButtons = pureComputed<ModalAction[]>(() => {
      return [{ buttonComponent: this.cancelButton }, { buttonComponent: this.saveButton }];
   });

   /* METHODS */
   private readonly updateActivePermissionDetails = (details: string): void => {
      this.state.activePermissionDetails(details);
   };

   private async savePermission(): Promise<void> {
      if (this.state.isSaving() === true) {
         const notification = new Notification({
            text: "Save already in progress.",
            icon: Icons.WARNING,
         });
         notificationManagerInstance.show(notification);
         return;
      }
      this.state.isSaving(true);
      const notification = new ProgressNotification({
         message: "Saving...",
         actions: [],
      });
      notificationManagerInstance.show(notification);
      try {
         await this.state.savePermission(this.modalType, this.afterSave);
         notification.success({
            message: "Saved successfully.",
         });
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
      } catch (error) {
         notification.failed({
            message: "Failed to save changes.",
         });
      }
      this.state.isSaving(false);
   }

   static factory(params: PermissionLevelModalParams): ComponentArgs<PermissionLevelModalParams> {
      return {
         name: "permission-level-modal",
         params,
      };
   }
}

class PermissionLevelModalState {
   private readonly id: string;
   private readonly activePermissionLevel: SerializedPermissionLevel | null;
   readonly name: Observable<string>;
   readonly isAdmin: Observable<boolean>;
   readonly companyId: Computed<string>;
   readonly activePermissionDetails: Observable<string | null>;
   readonly isSaving: Observable<boolean>;
   readonly selectedStatusIds: Observable<Set<string>>;

   private readonly permissions: {
      // People
      readonly viewPeople: Observable<boolean>;
      readonly editPeoplePermissions: Observable<boolean>;
      readonly editPeopleDetails: Observable<boolean>;
      readonly viewPeopleSensitive: Observable<boolean>;
      readonly editPeopleSensitive: Observable<boolean>;
      readonly viewPeopleTags: Observable<boolean>;
      readonly editPeopleTags: Observable<boolean>;
      readonly viewPeopleAttachments: Observable<boolean>;
      readonly editPeopleAttachments: Observable<boolean>;
      readonly viewPeopleTimeoff: Observable<boolean>;
      readonly editPeopleTimeoff: Observable<boolean>;
      readonly viewPeopleNotes: Observable<boolean>;
      readonly editPeopleNotes: Observable<boolean>;
      readonly viewPeopleActivity: Observable<boolean>;
      readonly deletePeople: Observable<boolean>;
      readonly createPeople: Observable<boolean>;

      // Projects
      readonly viewProject: Observable<boolean>;
      readonly editProjectDetails: Observable<boolean>;
      readonly viewProjectSensitive: Observable<boolean>;
      readonly editProjectSensitive: Observable<boolean>;
      readonly viewProjectTags: Observable<boolean>;
      readonly editProjectTags: Observable<boolean>;
      readonly viewProjectAttachments: Observable<boolean>;
      readonly editProjectAttachments: Observable<boolean>;
      readonly editProjectCategories: Observable<boolean>;
      readonly viewProjectRoles: Observable<boolean>;
      readonly editProjectRoles: Observable<boolean>;
      readonly viewProjectWageOverrides: Observable<boolean>;
      readonly editProjectWageOverrides: Observable<boolean>;
      readonly editProjectCustomAlerts: Observable<boolean>;
      readonly viewProjectDefaultRecipients: Observable<boolean>;
      readonly editProjectDefaultRecipients: Observable<boolean>;
      readonly viewProjectNotes: Observable<boolean>;
      readonly editProjectNotes: Observable<boolean>;
      readonly viewProjectActivity: Observable<boolean>;
      readonly deleteProject: Observable<boolean>;
      readonly createProject: Observable<boolean>;
      readonly viewUnassociatedProjects: Observable<boolean>;

      // Requests
      readonly viewRequests: Observable<boolean>;
      readonly manageRequests: Observable<boolean>;
      readonly manageOthersRequests: Observable<boolean>;
      readonly viewRequestsNotes: Observable<boolean>;

      // Assignments
      readonly viewAssignments: Observable<boolean>;
      readonly manageAssignments: Observable<boolean>;

      // Statuses
      readonly canViewAllStatuses: Observable<boolean>;

      // Reports & Exporting
      readonly allowExportingData: Observable<boolean>;

      // Page Specific
      readonly accessMapPage: Observable<boolean>;
      readonly accessGanttPage: Observable<boolean>;
      readonly accessTotalsPage: Observable<boolean>;

      // Messages
      readonly createMessages: Observable<boolean>;

      // Alerts
      readonly viewAlerts: Observable<boolean>;
      readonly manageAlerts: Observable<boolean>;

      // Financial
      readonly viewProjectFinancials: Observable<boolean>;
      readonly viewPeopleFinancials: Observable<boolean>;

      // Settings
      readonly viewCompanySettings: Observable<boolean>;
      readonly manageCompanySettings: Observable<boolean>;
      readonly viewPermissionSettings: Observable<boolean>;
      readonly managePermissionSettings: Observable<boolean>;
      readonly viewGroupSettings: Observable<boolean>;
      readonly manageGroupSettings: Observable<boolean>;
      readonly viewPositionSettings: Observable<boolean>;
      readonly managePositionSettings: Observable<boolean>;
      readonly viewTagsSettings: Observable<boolean>;
      readonly manageTagsSettings: Observable<boolean>;
      readonly viewStatusSettings: Observable<boolean>;
      readonly manageStatusSettings: Observable<boolean>;
      readonly viewAlertsSettings: Observable<boolean>;
      readonly manageAlertsSettings: Observable<boolean>;
      readonly viewCostingSettings: Observable<boolean>;
      readonly manageCostingSettings: Observable<boolean>;
      readonly viewCustomFieldsSettings: Observable<boolean>;
      readonly manageCustomFieldsSettings: Observable<boolean>;
      readonly viewQrCodesSettings: Observable<boolean>;
      readonly manageQrCodesSettings: Observable<boolean>;
      readonly viewNotificationsSettings: Observable<boolean>;
      readonly manageNotificationsSettings: Observable<boolean>;

      // Misc
      readonly canUnlockUsers: Observable<boolean>;
   };

   readonly enabledCount: PureComputed<number>;
   private readonly totalCount: number;
   private readonly initialStateCopy: string;
   readonly hasPendingChanges: () => boolean;

   constructor(activePermissionLevel: SerializedPermissionLevel | null) {
      const data = activePermissionLevel != null ? toJS(activePermissionLevel) : ({} as any);

      /* PROPERTIES */
      this.activePermissionLevel = activePermissionLevel;
      this.id = data.id ?? null;
      this.name = observable(data.name ?? "");
      this.isAdmin = observable(data.is_admin);
      this.companyId = authManager.companyId;
      this.activePermissionDetails = observable(null);
      this.selectedStatusIds = observable(
         new Set(activePermissionLevel?.visible_status_ids) ?? new Set<string>(),
      );

      this.permissions = {
         // People
         viewPeople: observable(data.view_people ?? false),
         editPeoplePermissions: observable(data.edit_people_permissions ?? false),
         editPeopleDetails: observable(data.edit_people_details ?? false),
         viewPeopleSensitive: observable(data.view_people_sensitive ?? false),
         editPeopleSensitive: observable(data.edit_people_sensitive ?? false),
         viewPeopleTags: observable(data.view_people_tags ?? false),
         editPeopleTags: observable(data.edit_people_tags ?? false),
         viewPeopleAttachments: observable(data.view_people_attachments ?? false),
         editPeopleAttachments: observable(data.edit_people_attachments ?? false),
         viewPeopleTimeoff: observable(data.view_people_timeoff ?? false),
         editPeopleTimeoff: observable(data.edit_people_timeoff ?? false),
         viewPeopleNotes: observable(data.view_people_notes ?? false),
         editPeopleNotes: observable(data.edit_people_notes ?? false),
         viewPeopleActivity: observable(data.view_people_activity ?? false),
         deletePeople: observable(data.delete_people ?? false),
         createPeople: observable(data.create_people ?? false),

         // Projects
         viewProject: observable(data.view_project ?? false),
         viewUnassociatedProjects: observable(data.view_unassociated_projects ?? false),
         editProjectDetails: observable(data.edit_project_details ?? false),
         viewProjectSensitive: observable(data.view_project_sensitive ?? false),
         editProjectSensitive: observable(data.edit_project_sensitive ?? false),
         viewProjectTags: observable(data.view_project_tags ?? false),
         editProjectTags: observable(data.edit_project_tags ?? false),
         viewProjectAttachments: observable(data.view_project_attachments ?? false),
         editProjectAttachments: observable(data.edit_project_attachments ?? false),
         editProjectCategories: observable(data.edit_project_categories ?? false),
         viewProjectRoles: observable(data.view_project_roles ?? false),
         editProjectRoles: observable(data.edit_project_roles ?? false),
         viewProjectWageOverrides: observable(data.view_project_wage_overrides ?? false),
         editProjectWageOverrides: observable(data.edit_project_wage_overrides ?? false),
         editProjectCustomAlerts: observable(data.edit_project_custom_alerts ?? false),
         viewProjectDefaultRecipients: observable(data.view_project_default_recipients ?? false),
         editProjectDefaultRecipients: observable(data.edit_project_default_recipients ?? false),
         viewProjectNotes: observable(data.view_project_notes ?? false),
         editProjectNotes: observable(data.edit_project_notes ?? false),
         viewProjectActivity: observable(data.view_project_activity ?? false),
         deleteProject: observable(data.delete_project ?? false),
         createProject: observable(data.create_project ?? false),

         // Requests
         viewRequests: observable(data.view_requests ?? false),
         manageRequests: observable(data.manage_requests ?? false),
         manageOthersRequests: observable(data.manage_others_requests ?? false),
         viewRequestsNotes: observable(data.view_requests_notes ?? false),

         // Assignments
         viewAssignments: observable(data.view_assignments ?? false),
         manageAssignments: observable(data.manage_assignments ?? false),

         // Statuses
         canViewAllStatuses: observable(data.can_view_all_statuses ?? true),

         // Reports & Exporting
         allowExportingData: observable(data.allow_exporting_data ?? false),

         // Page Specific
         accessMapPage: observable(data.access_map_page ?? false),
         accessGanttPage: observable(data.access_gantt_page ?? false),
         accessTotalsPage: observable(data.access_totals_page ?? false),

         // Messages
         createMessages: observable(data.create_messages ?? false),

         // Alerts
         viewAlerts: observable(data.view_alerts ?? false),
         manageAlerts: observable(data.manage_alerts ?? false),

         // Financial
         viewProjectFinancials: observable(data.view_project_financials ?? false),
         viewPeopleFinancials: observable(data.view_people_financials ?? false),

         // Settings
         viewCompanySettings: observable(data.view_company_settings ?? false),
         manageCompanySettings: observable(data.manage_company_settings ?? false),
         viewPermissionSettings: observable(data.view_permission_settings ?? false),
         managePermissionSettings: observable(data.manage_permission_settings ?? false),
         viewGroupSettings: observable(data.view_group_settings ?? false),
         manageGroupSettings: observable(data.manage_group_settings ?? false),
         viewPositionSettings: observable(data.view_position_settings ?? false),
         managePositionSettings: observable(data.manage_position_settings ?? false),
         viewTagsSettings: observable(data.view_tags_settings ?? false),
         manageTagsSettings: observable(data.manage_tags_settings ?? false),
         viewStatusSettings: observable(data.view_status_settings ?? false),
         manageStatusSettings: observable(data.manage_status_settings ?? false),
         viewAlertsSettings: observable(data.view_alerts_settings ?? false),
         manageAlertsSettings: observable(data.manage_alerts_settings ?? false),
         viewCostingSettings: observable(data.view_costing_settings ?? false),
         manageCostingSettings: observable(data.manage_costing_settings ?? false),
         viewCustomFieldsSettings: observable(data.view_custom_fields_settings ?? false),
         manageCustomFieldsSettings: observable(data.manage_custom_fields_settings ?? false),
         viewQrCodesSettings: observable(data.view_qr_codes_settings ?? false),
         manageQrCodesSettings: observable(data.manage_qr_codes_settings ?? false),
         viewNotificationsSettings: observable(data.view_notifications_settings ?? false),
         manageNotificationsSettings: observable(data.manage_notifications_settings ?? false),

         // Misc
         canUnlockUsers: observable(data.can_unlock_users ?? false),
      };

      this.enabledCount = pureComputed(() => {
         return Object.values(this.permissions).filter(
            (val) => val !== undefined && typeof val() === "boolean" && val() === true,
         ).length;
      });
      this.isSaving = observable(false);
      this.totalCount = Object.keys(this.permissions).length;

      this.initialStateCopy = toJSON({
         name: this.name,
         selectedStatusIds: [...this.selectedStatusIds()],
         ...this.permissions,
      });
      this.hasPendingChanges = pureComputed(() => {
         const currentState = toJSON({
            name: this.name,
            selectedStatusIds: [...this.selectedStatusIds()],
            ...this.permissions,
         });
         return currentState != this.initialStateCopy;
      });
   }

   /* PERMISSION DEPENDENCIES */
   // People
   private readonly viewPeople = pureComputed({
      read: () => this.permissions.viewPeople(),
      write: (isEnabled) => {
         this.permissions.viewPeople(isEnabled);
         if (isEnabled === false) {
            this.editPeoplePermissions(false);
            this.editPeopleDetails(false);
            this.viewPeopleSensitive(false);
            this.editPeopleSensitive(false);
            this.viewPeopleTags(false);
            this.editPeopleTags(false);
            this.viewPeopleAttachments(false);
            this.editPeopleAttachments(false);
            this.viewPeopleTimeoff(false);
            this.editPeopleTimeoff(false);
            this.viewPeopleNotes(false);
            this.editPeopleNotes(false);
            this.viewPeopleActivity(false);
            this.deletePeople(false);
            this.createPeople(false);
            this.viewAssignments(false);
         }
      },
   });
   private readonly editPeoplePermissions = pureComputed({
      read: () => this.permissions.editPeoplePermissions(),
      write: (isEnabled) => {
         this.permissions.editPeoplePermissions(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.editPeopleDetails(true);
         }
      },
   });

   private readonly editPeopleSensitive = pureComputed({
      read: () => this.permissions.editPeopleSensitive(),
      write: (isEnabled) => {
         this.permissions.editPeopleSensitive(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.editPeopleDetails(true);
            this.viewPeopleSensitive(true);
         }
      },
   });
   private readonly editPeopleDetails = pureComputed({
      read: () => this.permissions.editPeopleDetails(),
      write: (isEnabled) => {
         this.permissions.editPeopleDetails(isEnabled);
         if (isEnabled === false) {
            this.editPeoplePermissions(false);
            this.editPeopleSensitive(false);
         } else {
            this.viewPeople(true);
         }
      },
   });
   private readonly viewPeopleSensitive = pureComputed({
      read: () => this.permissions.viewPeopleSensitive(),
      write: (isEnabled) => {
         this.permissions.viewPeopleSensitive(isEnabled);
         if (isEnabled === false) {
            this.editPeopleSensitive(false);
         } else {
            this.viewPeople(true);
         }
      },
   });

   private readonly editPeopleTags = pureComputed({
      read: () => this.permissions.editPeopleTags(),
      write: (isEnabled) => {
         this.permissions.editPeopleTags(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.viewPeopleTags(true);
         }
      },
   });
   private readonly viewPeopleTags = pureComputed({
      read: () => this.permissions.viewPeopleTags(),
      write: (isEnabled) => {
         this.permissions.viewPeopleTags(isEnabled);
         if (isEnabled === false) {
            this.editPeopleTags(false);
         } else {
            this.viewPeople(true);
         }
      },
   });

   private readonly editPeopleAttachments = pureComputed({
      read: () => this.permissions.editPeopleAttachments(),
      write: (isEnabled) => {
         this.permissions.editPeopleAttachments(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.viewPeopleAttachments(true);
         }
      },
   });
   private readonly viewPeopleAttachments = pureComputed({
      read: () => this.permissions.viewPeopleAttachments(),
      write: (isEnabled) => {
         this.permissions.viewPeopleAttachments(isEnabled);
         if (isEnabled === false) {
            this.editPeopleAttachments(false);
         } else {
            this.viewPeople(true);
         }
      },
   });

   private readonly editPeopleTimeoff = pureComputed({
      read: () => this.permissions.editPeopleTimeoff(),
      write: (isEnabled) => {
         this.permissions.editPeopleTimeoff(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.viewPeopleTimeoff(true);
         }
      },
   });
   private readonly viewPeopleTimeoff = pureComputed({
      read: () => this.permissions.viewPeopleTimeoff(),
      write: (isEnabled) => {
         this.permissions.viewPeopleTimeoff(isEnabled);
         if (isEnabled === false) {
            this.editPeopleTimeoff(false);
         } else {
            this.viewPeople(true);
         }
      },
   });

   private readonly editPeopleNotes = pureComputed({
      read: () => this.permissions.editPeopleNotes(),
      write: (isEnabled) => {
         this.permissions.editPeopleNotes(isEnabled);
         if (isEnabled === true) {
            this.viewPeople(true);
            this.viewPeopleNotes(true);
         }
      },
   });
   private readonly viewPeopleNotes = pureComputed({
      read: () => this.permissions.viewPeopleNotes(),
      write: (isEnabled) => {
         this.permissions.viewPeopleNotes(isEnabled);
         if (isEnabled === false) {
            this.editPeopleNotes(false);
         } else {
            this.viewPeople(true);
         }
      },
   });

   private readonly viewPeopleActivity = pureComputed({
      read: () => this.permissions.viewPeopleActivity(),
      write: (isEnabled) => {
         this.permissions.viewPeopleActivity(isEnabled);
         if (isEnabled === true) this.viewPeople(true);
      },
   });
   private readonly deletePeople = pureComputed({
      read: () => this.permissions.deletePeople(),
      write: (isEnabled) => {
         this.permissions.deletePeople(isEnabled);
         if (isEnabled === true) this.viewPeople(true);
      },
   });
   private readonly createPeople = pureComputed({
      read: () => this.permissions.createPeople(),
      write: (isEnabled) => {
         this.permissions.createPeople(isEnabled);
         if (isEnabled === true) this.viewPeople(true);
      },
   });

   // Projects
   private readonly viewProject = pureComputed({
      read: () => this.permissions.viewProject(),
      write: (isEnabled) => {
         this.permissions.viewProject(isEnabled);
         if (isEnabled === false) {
            this.viewUnassociatedProjects(false);
            this.editProjectDetails(false);
            this.viewProjectSensitive(false);
            this.editProjectSensitive(false);
            this.viewProjectTags(false);
            this.editProjectTags(false);
            this.viewProjectAttachments(false);
            this.editProjectAttachments(false);
            this.editProjectCategories(false);
            this.viewProjectRoles(false);
            this.editProjectRoles(false);
            this.viewProjectWageOverrides(false);
            this.editProjectWageOverrides(false);
            this.editProjectCustomAlerts(false);
            this.viewProjectDefaultRecipients(false);
            this.editProjectDefaultRecipients(false);
            this.viewProjectNotes(false);
            this.editProjectNotes(false);
            this.viewProjectActivity(false);
            this.deleteProject(false);
            this.createProject(false);
            this.viewRequests(false);
            this.manageAssignments(false);
         }
      },
   });

   private readonly viewUnassociatedProjects = pureComputed({
      read: () => this.permissions.viewUnassociatedProjects(),
      write: (isEnabled) => {
         this.permissions.viewUnassociatedProjects(isEnabled);
         if (isEnabled === true) this.viewProject(true);
      },
   });

   private readonly editProjectSensitive = pureComputed({
      read: () => this.permissions.editProjectSensitive(),
      write: (isEnabled) => {
         this.permissions.editProjectSensitive(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectSensitive(true);
            this.editProjectDetails(true);
         }
      },
   });
   private readonly editProjectDetails = pureComputed({
      read: () => this.permissions.editProjectDetails(),
      write: (isEnabled) => {
         this.permissions.editProjectDetails(isEnabled);
         if (isEnabled === false) {
            this.editProjectSensitive(false);
         } else {
            this.viewProject(true);
         }
      },
   });
   private readonly viewProjectSensitive = pureComputed({
      read: () => this.permissions.viewProjectSensitive(),
      write: (isEnabled) => {
         this.permissions.viewProjectSensitive(isEnabled);
         if (isEnabled === false) {
            this.editProjectSensitive(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectTags = pureComputed({
      read: () => this.permissions.editProjectTags(),
      write: (isEnabled) => {
         this.permissions.editProjectTags(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectTags(true);
         }
      },
   });
   private readonly viewProjectTags = pureComputed({
      read: () => this.permissions.viewProjectTags(),
      write: (isEnabled) => {
         this.permissions.viewProjectTags(isEnabled);
         if (isEnabled === false) {
            this.editProjectTags(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectAttachments = pureComputed({
      read: () => this.permissions.editProjectAttachments(),
      write: (isEnabled) => {
         this.permissions.editProjectAttachments(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectAttachments(true);
         }
      },
   });
   private readonly viewProjectAttachments = pureComputed({
      read: () => this.permissions.viewProjectAttachments(),
      write: (isEnabled) => {
         this.permissions.viewProjectAttachments(isEnabled);
         if (isEnabled === false) {
            this.editProjectAttachments(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectCategories = pureComputed({
      read: () => this.permissions.editProjectCategories(),
      write: (isEnabled) => {
         this.permissions.editProjectCategories(isEnabled);
         if (isEnabled === true) this.viewProject(true);
      },
   });

   private readonly editProjectRoles = pureComputed({
      read: () => this.permissions.editProjectRoles(),
      write: (isEnabled) => {
         this.permissions.editProjectRoles(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectRoles(true);
         }
      },
   });
   private readonly viewProjectRoles = pureComputed({
      read: () => this.permissions.viewProjectRoles(),
      write: (isEnabled) => {
         this.permissions.viewProjectRoles(isEnabled);
         if (isEnabled === false) {
            this.editProjectRoles(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectWageOverrides = pureComputed({
      read: () => this.permissions.editProjectWageOverrides(),
      write: (isEnabled) => {
         this.permissions.editProjectWageOverrides(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectWageOverrides(true);
         }
      },
   });
   private readonly viewProjectWageOverrides = pureComputed({
      read: () => this.permissions.viewProjectWageOverrides(),
      write: (isEnabled) => {
         this.permissions.viewProjectWageOverrides(isEnabled);
         if (isEnabled === false) {
            this.editProjectWageOverrides(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectCustomAlerts = pureComputed({
      read: () => this.permissions.editProjectCustomAlerts(),
      write: (isEnabled) => {
         this.permissions.editProjectCustomAlerts(isEnabled);
         if (isEnabled === true) this.viewProject(true);
      },
   });

   private readonly editProjectDefaultRecipients = pureComputed({
      read: () => this.permissions.editProjectDefaultRecipients(),
      write: (isEnabled) => {
         this.permissions.editProjectDefaultRecipients(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectDefaultRecipients(true);
         }
      },
   });
   private readonly viewProjectDefaultRecipients = pureComputed({
      read: () => this.permissions.viewProjectDefaultRecipients(),
      write: (isEnabled) => {
         this.permissions.viewProjectDefaultRecipients(isEnabled);
         if (isEnabled === false) {
            this.editProjectDefaultRecipients(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly editProjectNotes = pureComputed({
      read: () => this.permissions.editProjectNotes(),
      write: (isEnabled) => {
         this.permissions.editProjectNotes(isEnabled);
         if (isEnabled === true) {
            this.viewProject(true);
            this.viewProjectNotes(true);
         }
      },
   });
   private readonly viewProjectNotes = pureComputed({
      read: () => this.permissions.viewProjectNotes(),
      write: (isEnabled) => {
         this.permissions.viewProjectNotes(isEnabled);
         if (isEnabled === false) {
            this.editProjectNotes(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly viewProjectActivity = pureComputed({
      read: () => this.permissions.viewProjectActivity(),
      write: (isEnabled) => {
         this.permissions.viewProjectActivity(isEnabled);
         if (isEnabled) this.viewProject(true);
      },
   });
   private readonly deleteProject = pureComputed({
      read: () => this.permissions.deleteProject(),
      write: (isEnabled) => {
         this.permissions.deleteProject(isEnabled);
         if (isEnabled) this.viewProject(true);
      },
   });
   private readonly createProject = pureComputed({
      read: () => this.permissions.createProject(),
      write: (isEnabled) => {
         this.permissions.createProject(isEnabled);
         if (isEnabled) this.viewProject(true);
      },
   });

   // Requests
   private readonly viewRequests = pureComputed({
      read: () => this.permissions.viewRequests(),
      write: (isEnabled) => {
         this.permissions.viewRequests(isEnabled);
         if (isEnabled === false) {
            this.manageRequests(false);
            this.manageOthersRequests(false);
            this.viewRequestsNotes(false);
         } else {
            this.viewProject(true);
         }
      },
   });

   private readonly manageRequests = pureComputed({
      read: () => this.permissions.manageRequests(),
      write: (isEnabled) => {
         this.permissions.manageRequests(isEnabled);
         if (isEnabled === true) this.viewRequests(true);
      },
   });
   private readonly manageOthersRequests = pureComputed({
      read: () => this.permissions.manageOthersRequests(),
      write: (isEnabled) => {
         this.permissions.manageOthersRequests(isEnabled);
         if (isEnabled === true) this.viewRequests(true);
      },
   });
   private readonly viewRequestsNotes = pureComputed({
      read: () => this.permissions.viewRequestsNotes(),
      write: (isEnabled) => {
         this.permissions.viewRequestsNotes(isEnabled);
         if (isEnabled === true) this.viewRequests(true);
      },
   });

   // Assignments
   private readonly viewAssignments = pureComputed({
      read: () => this.permissions.viewAssignments(),
      write: (isEnabled) => {
         this.permissions.viewAssignments(isEnabled);
         if (isEnabled === false) {
            this.manageAssignments(false);
         }
      },
   });

   private readonly manageAssignments = pureComputed({
      read: () => this.permissions.manageAssignments(),
      write: (isEnabled) => {
         this.permissions.manageAssignments(isEnabled);
         if (isEnabled === true) {
            this.viewAssignments(true);
            this.viewProject(true);
         }
      },
   });

   // Statuses
   private readonly canViewAllStatuses = pureComputed({
      read: () => this.permissions.canViewAllStatuses!(),
      write: (isEnabled) => {
         this.permissions.canViewAllStatuses!(isEnabled);
      },
   });

   // Reports & Exports
   private readonly allowExportingData = pureComputed({
      read: () => this.permissions.allowExportingData(),
      write: (isEnabled) => this.permissions.allowExportingData(isEnabled),
   });

   // Specific Pages
   private readonly accessMapPage = pureComputed({
      read: () => this.permissions.accessMapPage(),
      write: (isEnabled) => this.permissions.accessMapPage(isEnabled),
   });
   private readonly accessGanttPage = pureComputed({
      read: () => this.permissions.accessGanttPage(),
      write: (isEnabled) => this.permissions.accessGanttPage(isEnabled),
   });
   private readonly accessTotalsPage = pureComputed({
      read: () => this.permissions.accessTotalsPage(),
      write: (isEnabled) => this.permissions.accessTotalsPage(isEnabled),
   });

   // Messages & Alerts
   private readonly createMessages = pureComputed({
      read: () => this.permissions.createMessages(),
      write: (isEnabled) => this.permissions.createMessages(isEnabled),
   });

   private readonly manageAlerts = pureComputed({
      read: () => this.permissions.manageAlerts(),
      write: (isEnabled) => {
         this.permissions.manageAlerts(isEnabled);
         if (isEnabled === true) this.viewAlerts(true);
      },
   });
   private readonly viewAlerts = pureComputed({
      read: () => this.permissions.viewAlerts(),
      write: (isEnabled) => {
         this.permissions.viewAlerts(isEnabled);
         if (isEnabled === false) this.manageAlerts(false);
      },
   });

   // Financial
   private readonly viewProjectFinancials = pureComputed({
      read: () => this.permissions.viewProjectFinancials(),
      write: (isEnabled) => this.permissions.viewProjectFinancials(isEnabled),
   });
   private readonly viewPeopleFinancials = pureComputed({
      read: () => this.permissions.viewPeopleFinancials(),
      write: (isEnabled) => this.permissions.viewPeopleFinancials(isEnabled),
   });

   // Settings
   private readonly manageCompanySettings = pureComputed({
      read: () => this.permissions.manageCompanySettings(),
      write: (isEnabled) => {
         this.permissions.manageCompanySettings(isEnabled);
         if (isEnabled === true) this.viewCompanySettings(true);
      },
   });
   private readonly viewCompanySettings = pureComputed({
      read: () => this.permissions.viewCompanySettings(),
      write: (isEnabled) => {
         this.permissions.viewCompanySettings(isEnabled);
         if (isEnabled === false) this.manageCompanySettings(false);
      },
   });

   private readonly managePermissionSettings = pureComputed({
      read: () => this.permissions.managePermissionSettings(),
      write: (isEnabled) => {
         this.permissions.managePermissionSettings(isEnabled);
         if (isEnabled === true) this.viewPermissionSettings(true);
      },
   });
   private readonly viewPermissionSettings = pureComputed({
      read: () => this.permissions.viewPermissionSettings(),
      write: (isEnabled) => {
         this.permissions.viewPermissionSettings(isEnabled);
         if (isEnabled === false) this.managePermissionSettings(false);
      },
   });

   private readonly manageGroupSettings = pureComputed({
      read: () => this.permissions.manageGroupSettings(),
      write: (isEnabled) => {
         this.permissions.manageGroupSettings(isEnabled);
         if (isEnabled === true) this.viewGroupSettings(true);
      },
   });
   private readonly viewGroupSettings = pureComputed({
      read: () => this.permissions.viewGroupSettings(),
      write: (isEnabled) => {
         this.permissions.viewGroupSettings(isEnabled);
         if (isEnabled === false) this.manageGroupSettings(false);
      },
   });

   private readonly managePositionSettings = pureComputed({
      read: () => this.permissions.managePositionSettings(),
      write: (isEnabled) => {
         this.permissions.managePositionSettings(isEnabled);
         if (isEnabled === true) this.viewPositionSettings(true);
      },
   });
   private readonly viewPositionSettings = pureComputed({
      read: () => this.permissions.viewPositionSettings(),
      write: (isEnabled) => {
         this.permissions.viewPositionSettings(isEnabled);
         if (isEnabled === false) this.managePositionSettings(false);
      },
   });

   private readonly manageTagsSettings = pureComputed({
      read: () => this.permissions.manageTagsSettings(),
      write: (isEnabled) => {
         this.permissions.manageTagsSettings(isEnabled);
         if (isEnabled === true) this.viewTagsSettings(true);
      },
   });
   private readonly viewTagsSettings = pureComputed({
      read: () => this.permissions.viewTagsSettings(),
      write: (isEnabled) => {
         this.permissions.viewTagsSettings(isEnabled);
         if (isEnabled === false) this.manageTagsSettings(false);
      },
   });

   private readonly viewStatusSettings = pureComputed({
      read: () => this.permissions.viewStatusSettings!(),
      write: (isEnabled) => {
         this.permissions.viewStatusSettings!(isEnabled);
         if (isEnabled === false) this.manageStatusSettings(false);
      },
   });
   private readonly manageStatusSettings = pureComputed({
      read: () => this.permissions.manageStatusSettings!(),
      write: (isEnabled) => {
         this.permissions.manageStatusSettings!(isEnabled);
         if (isEnabled === true) this.viewStatusSettings(true);
      },
   });

   private readonly manageAlertsSettings = pureComputed({
      read: () => this.permissions.manageAlertsSettings(),
      write: (isEnabled) => {
         this.permissions.manageAlertsSettings(isEnabled);
         if (isEnabled === true) this.viewAlertsSettings(true);
      },
   });
   private readonly viewAlertsSettings = pureComputed({
      read: () => this.permissions.viewAlertsSettings(),
      write: (isEnabled) => {
         this.permissions.viewAlertsSettings(isEnabled);
         if (isEnabled === false) this.manageAlertsSettings(false);
      },
   });

   private readonly manageCostingSettings = pureComputed({
      read: () => this.permissions.manageCostingSettings(),
      write: (isEnabled) => {
         this.permissions.manageCostingSettings(isEnabled);
         if (isEnabled === true) this.viewCostingSettings(true);
      },
   });
   private readonly viewCostingSettings = pureComputed({
      read: () => this.permissions.viewCostingSettings(),
      write: (isEnabled) => {
         this.permissions.viewCostingSettings(isEnabled);
         if (isEnabled === false) this.manageCostingSettings(false);
      },
   });

   private readonly manageCustomFieldsSettings = pureComputed({
      read: () => this.permissions.manageCustomFieldsSettings(),
      write: (isEnabled) => {
         this.permissions.manageCustomFieldsSettings(isEnabled);
         if (isEnabled === true) this.viewCustomFieldsSettings(true);
      },
   });
   private readonly viewCustomFieldsSettings = pureComputed({
      read: () => this.permissions.viewCustomFieldsSettings(),
      write: (isEnabled) => {
         this.permissions.viewCustomFieldsSettings(isEnabled);
         if (isEnabled === false) this.manageCustomFieldsSettings(false);
      },
   });

   private readonly manageQrCodesSettings = pureComputed({
      read: () => this.permissions.manageQrCodesSettings(),
      write: (isEnabled) => {
         this.permissions.manageQrCodesSettings(isEnabled);
         if (isEnabled === true) this.viewQrCodesSettings(true);
      },
   });
   private readonly viewQrCodesSettings = pureComputed({
      read: () => this.permissions.viewQrCodesSettings(),
      write: (isEnabled) => {
         this.permissions.viewQrCodesSettings(isEnabled);
         if (isEnabled === false) this.manageQrCodesSettings(false);
      },
   });

   private readonly manageNotificationsSettings = pureComputed({
      read: () => this.permissions.manageNotificationsSettings(),
      write: (isEnabled) => {
         this.permissions.manageNotificationsSettings(isEnabled);
         if (isEnabled === true) this.viewNotificationsSettings(true);
      },
   });
   private readonly viewNotificationsSettings = pureComputed({
      read: () => this.permissions.viewNotificationsSettings(),
      write: (isEnabled) => {
         this.permissions.viewNotificationsSettings(isEnabled);
         if (isEnabled === false) this.manageNotificationsSettings(false);
      },
   });

   // Misc
   private readonly canUnlockUsers = pureComputed({
      read: () => this.permissions.canUnlockUsers(),
      write: (isEnabled) => this.permissions.canUnlockUsers(isEnabled),
   });

   /* PERMISSION GROUP LIST */
   private readonly permissionGroupList: PermissionGroup[] = [
      {
         name: "PEOPLE",
         permissions: [
            {
               value: this.viewPeople,
               name: "View People",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Removes all hyperlinks that would take a user to a person detail page.<br><br>
               <span class='selected-details__header'>People List Page:</span><br>
               Removed<br><br>
               <span class='selected-details__header'>Time Off List Page:</span><br>
               Removed<br><br>
               <span class='selected-details__header'>Maps Page:</span><br>
               Removes all people pins and options.<br><br>
               <span class='selected-details__header'>Global Activity List Page:</span><br>
               Removes all people related activity.<br><br>`,
            },
            {
               value: this.editPeopleDetails,
               name: "Edit People Details",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes editing controls for a person's permission level and type (user / assignable / both).`,
            },
            {
               value: this.editPeoplePermissions,
               name: "Edit People Permissions & Type",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Disables editing controls for the info/details section of the person detail page.`,
            },
            {
               value: this.viewPeopleSensitive,
               name: "View People Sensitive Fields",
               details: `<span class='selected-details__header'>People List Page:</span>
               <br>Removes sensitive fields as filter and column options.<br><br>
               <span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes sensitive fields from the info section.
               <br>Removes records about sensitive fields from the activity feed.<br><br>
               <span class='selected-details__header'>Global Activity Page:</span>
               <br>Removes records about sensitive fields from the activity feed.`,
            },
            {
               value: this.editPeopleSensitive,
               name: "Edit People Sensitive Fields",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes editing controls for sensitive fields from the info section.`,
            },
            {
               value: this.viewPeopleTags,
               name: "View People Tags",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Hides peoples tags in all locations throughout the platform.
               <br>Remove the ability to filter for people by tags.`,
            },
            {
               value: this.editPeopleTags,
               name: "Edit People Tags",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Disables editing controls on the tag section.`,
            },
            {
               value: this.viewPeopleAttachments,
               name: "View People Attachments",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Hides the attachments section.`,
            },
            {
               value: this.editPeopleAttachments,
               name: "Edit People Attachments",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes editing controls from the attachments section.`,
            },
            {
               value: this.viewPeopleTimeoff,
               name: "View People Time Off",
               details: `<span class='selected-details__header'>Time Off List Page:</span>
               <br>Removed<br><br>
               <span class='selected-details__header'>Person Detail Page:</span>
               <br>Hides time off section.`,
            },
            {
               value: this.editPeopleTimeoff,
               name: "Edit People Time Off",
               details: `<span class='selected-details__header'>Time Off List Page:</span>
               <br>Removes creation and editing controls for time off records.<br><br>
               <span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes creation and editing controls for time off records.`,
            },
            {
               value: this.viewPeopleNotes,
               name: "View People Notes",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Hides the notes section.`,
            },
            {
               value: this.editPeopleNotes,
               name: "Edit People Notes",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes the ability to create or edit notes.`,
            },
            {
               value: this.viewPeopleActivity,
               name: "View People Activity",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Hides the activity section.<br><br>
               <span class='selected-details__header'>Global Activity List Page:</span>
               <br>Removes the option to show activity related to people.`,
            },
            {
               value: this.deletePeople,
               name: "Delete People",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes the delete button.`,
            },
            {
               value: this.createPeople,
               name: "Create People",
               details: `<span class='selected-details__header'>People List Page:</span>
               <br>Removes the new person button.`,
            },
         ],
      },
      {
         name: "PROJECTS",
         permissions: [
            {
               value: this.viewProject,
               name: "View Projects",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Removes hyperlinks & buttons throughout the platform that would link to a project detail page.<br><br>
               <span class='selected-details__header'>Map Page:</span>
               <br>Removes all project pins from the map and all project related controls.<br><br>
               <span class='selected-details__header'>Projects List Page:</span>
               <br>Removed<br><br>
               <span class='selected-details__header'>Global Activity List Page:</span>
               <br>Removes all project related activity.`,
            },
            {
               value: this.viewUnassociatedProjects,
               name: "View Unassociated Projects",
               details: `<span class='selected-details__header'>Project Association:</span>
               <br>A user is associated to a project when they meet any of the following criteria:<br>
               <ul><li class='selected-details__list-item'>The user is assigned to the project.</li>
               <li class='selected-details__list-item'>The user is specified as a Project Role on the project.</li>
               <li class='selected-details__list-item'>The user created the project.</li></ul>
               Users are automatically de-associated from projects when all of the above statements are no longer true.<br><br>
               <span class='selected-details__header'>Globally:</span>
               <br><em>Checked:</em> Allows users to view all projects, even if they are not associated with them.
               <br><em>Unchecked:</em> Restricts users to only seeing projects they are associated with.<br><br>
               <span class='selected-details__header'>Assignments List Page:</span>
               <br><em>Checked:</em> Allows users to view assignments for all projects, even if they are not associated with them.
               <br><em>Unchecked:</em> Restricts users to only seeing assignments on the projects they are associated with.<br><br>
               <span class='selected-details__header'>Requests List Page:</span>
               <br><em>Checked:</em> Allows users to view requests for all projects, even if they are not associated with them.
               <br><em>Unchecked:</em> Restricts users to only seeing requests on the projects they are associated with.<br><br>`,
            },
            {
               value: this.editProjectDetails,
               name: "Edit Project Details",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes the editing controls on the info/details section.`,
            },
            {
               value: this.viewProjectSensitive,
               name: "View Project Sensitive Fields",
               details: `<span class='selected-details__header'>Projects List Page:</span>
               <br>Removes sensitive fields as filter and column options.<br><br>
               <span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes sensitive fields from the info section.
               <br>Removes records about sensitive fields from the activity feed.<br><br>
               <span class='selected-details__header'>Global Activity Page:</span>
               <br>Removes records about sensitive fields from the activity feed.`,
            },
            {
               value: this.editProjectSensitive,
               name: "Edit Project Sensitive Fields",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for sensitive fields from the info section.`,
            },
            {
               value: this.viewProjectTags,
               name: "View Project Tags",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Hides project tags throughout the platform.
               <br>Removes the ability to filter for projects by tags.`,
            },
            {
               value: this.editProjectTags,
               name: "Edit Project Tags",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for the tag section.`,
            },
            {
               value: this.viewProjectAttachments,
               name: "View Project Attachments",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the attachment section.`,
            },
            {
               value: this.editProjectAttachments,
               name: "Edit Project Attachments",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for the attachment section.`,
            },
            {
               value: this.editProjectCategories,
               name: "Edit Project Categories",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for the category section.`,
            },
            {
               value: this.viewProjectRoles,
               name: "View Project Roles",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Removes the ability to filter by project role throughout the platform.<br><br>
               <span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the project roles section.<br><br>
               <span class='selected-details__header'>Projects List Page:</span>
               <br>Removes project roles as a column option for view and exports.`,
            },
            {
               value: this.editProjectRoles,
               name: "Edit Project Roles",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for the project role section.`,
            },
            {
               value: this.viewProjectWageOverrides,
               name: "View Project Wage Overrides",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Hides the wage override indicator.<br><br>
               <span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the wage override section.`,
            },
            {
               value: this.editProjectWageOverrides,
               name: "Edit Project Wage Overrides",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls from the wage override section.`,
            },
            {
               value: this.editProjectCustomAlerts,
               name: "Edit Project Custom Alert",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls from the custom alerts section.`,
            },
            {
               value: this.viewProjectDefaultRecipients,
               name: "View Project Default Recipients",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the default recipient section.`,
            },
            {
               value: this.editProjectDefaultRecipients,
               name: "Edit Project Default Recipients",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes editing controls for the default recipient section.`,
            },
            {
               value: this.viewProjectNotes,
               name: "View Project Notes",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the notes section.`,
            },
            {
               value: this.editProjectNotes,
               name: "Edit Project Notes",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes the ability to create and edit notes.`,
            },
            {
               value: this.viewProjectActivity,
               name: "View Project Activity",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Hides the activity feed.<br><br>
               <span class='selected-details__header'>Global Activity List Page:</span>
               <br>Removes all project related activity.`,
            },
            {
               value: this.deleteProject,
               name: "Delete Projects",
               details: `<span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes the delete button.`,
            },
            {
               value: this.createProject,
               name: "Create Projects",
               details: `<span class='selected-details__header'>Projects List Page:</span>
               <br>Removes the new project button.`,
            },
         ],
      },
      {
         name: "REQUESTS",
         permissions: [
            {
               value: this.viewRequests,
               name: "View Requests",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Hides blue request tray & request cards on each project.
               <br>Removes the ability to create a new request by clicking the '...' more button.<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>Hides request section & bars on each project.
               <br>Removes the ability to create a new request by clicking the '...' more button.<br><br>
               details: <span class='selected-details__header'>Totals Page:</span>owns.<br><br>
               <span class='selected-details__header'>Requests List Page:</span>
               <br>Removed`,
            },
            {
               value: this.manageRequests,
               name: "Create / Edit / Delete Own Requests",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Removes the ability to create a new request by clicking the '...' more button.
               <br>Removes the edit button from requests cards.<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>Disables clicking on request bars to edit.
               <br>Removes the ability to create a new request by clicking the '...' more button.<br><br>
               <span class='selected-details__header'>Requests List Page:</span>
               <br>Removes creation and editing controls for requests.`,
            },
            {
               value: this.manageOthersRequests,
               name: "Edit / Delete Others Requests",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Removes the edit button from request cards not created by the user.<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>Disables clicking on request bars created by others to edit them.<br><br>
               <span class='selected-details__header'>Requests List Page:</span>
               <br>Disables editing controls for requests not created by the user.`,
            },
            {
               value: this.viewRequestsNotes,
               name: "View Request Comments",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Disables comments tab on all requests modals.<br><br>
               <span class='selected-details__header'>Requests List Page:</span>
               <br>Removes comments column.`,
            },
         ],
      },
      {
         name: "ASSIGNMENTS",
         permissions: [
            {
               value: this.viewAssignments,
               name: "View Assignments",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Removed<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>Removed (even if Access to Gantt page is enabled)`,
            },
            {
               value: this.manageAssignments,
               name: "Create / Edit / Delete Assignments",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Prevents making and editing all assignments.<br><br>
               <span class='selected-details__header'>Boards Page:</span>
               <br>Prevents dragging any cards.
               <br>Hides the rapid assign controls.
               <br>Hides batch controls.`,
            },
         ],
      },
      {
         name: "STATUSES",
         permissions: [
            {
               value: this.canViewAllStatuses,
               name: "Can View All Statuses",
               details: `<span class='selected-details__header'>Globally:</span><br>
            <ul><li class='selected-details__list-item'>Hides all Assignments and Requests that do not have a Visible Status.</li>
            <li class='selected-details__list-item'>When users with this permission create a new Assignment or Request, they are required to apply one of the Visible Statuses.</li></ul>`,
            },
         ],
      },
      {
         name: "REPORTS & EXPORTS",
         permissions: [
            {
               value: this.allowExportingData,
               name: "Allow Exporting Data",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Hides all downloading & exporting options throughout the platform.<br><br>
               <span class='selected-details__header'>Reports Page:</span>
               <br>Removed`,
            },
         ],
      },
      {
         name: "SPECIFIC PAGES",
         permissions: [
            {
               value: this.accessMapPage,
               name: "Access to Map page",
               details: `<span class='selected-details__header'>Map Page:</span>
               <br>Removed`,
            },
            {
               value: this.accessGanttPage,
               name: "Access to Gantt page",
               details: `<span class='selected-details__header'>Gantt Page:</span>
               <br>Removed`,
            },
            {
               value: this.accessTotalsPage,
               name: "Access to Totals page",
               details: `<span class='selected-details__header'>Totals Page:</span>
               <br>Removed`,
            },
         ],
      },
      {
         name: "MESSAGES & ALERTS",
         permissions: [
            {
               value: this.createMessages,
               name: "Create Messages",
               details: `<span class='selected-details__header'>Messages Page:</span>
               <br>Removes the ability to create new messages. User can still respond to messages they receive.`,
            },
            {
               value: this.viewAlerts,
               name: "View Alerts",
               details: `<span class='selected-details__header'>Alerts Page:</span>
               <br>Removed`,
            },
            {
               value: this.manageAlerts,
               name: "Create / Edit / Delete Alerts",
               details: `<span class='selected-details__header'>Globally:</span>
               <br>Removes options to 'Save & Alert' and 'Delete & Alert' when editing assignments.<br><br>
               <span class='selected-details__header'>Alerts Page:</span>
               <br>Removes all controls on alerts to prompt, send, close, edit.`,
            },
         ],
      },
      {
         name: "FINANCIAL",
         permissions: [
            {
               value: this.viewProjectFinancials,
               name: "View Project Financials",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>Financial info is hidden from the meta bar under each project name.<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>The configuartion option to show totals by cost is removed.<br><br>
               <span class='selected-details__header'>Totals Page:</span>
               <br>The configuartion option to show totals by cost is removed.<br><br>
               <span class='selected-details__header'>Project List Page:</span>
               <br>Removes financial columns (including custom fields of the 'currency' type).
               <br>Removes financial filter options (including custom fields of the 'currency' type).<br><br>
               <span class='selected-details__header'>Project Detail Page:</span>
               <br>Removes financial fields (including custom fields of the 'currency' type) are hidden in the info section.`,
            },
            {
               value: this.viewPeopleFinancials,
               name: "View People Financials",
               details: `<span class='selected-details__header'>Boards Page:</span>
               <br>People's hourly wages are no longer visible on assignment card info popups.<br><br>
               <span class='selected-details__header'>Gantt Page:</span>
               <br>Hourly wage when clicking for info on people is not longer visible.<br><br>
               <span class='selected-details__header'>People List Page:</span>
               <br>Removes financial columns (including custom fields of the 'currency' type).
               <br>Removes financial filter options (including custom fields of the 'currency' type).<br><br>
               <span class='selected-details__header'>Person Detail Page:</span>
               <br>Removes financial fields (including custom fields of the 'currency' type) are hidden in the info section.`,
            },
         ],
      },
      {
         name: "SETTINGS",
         permissions: [
            {
               value: this.viewCompanySettings,
               name: "View Company Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes company tab.`,
            },
            {
               value: this.manageCompanySettings,
               name: "Edit Company Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewPermissionSettings,
               name: "View Permission Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes permissions tab.`,
            },
            {
               value: this.managePermissionSettings,
               name: "Edit Permission Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewGroupSettings,
               name: "View Group Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes groups tab.`,
            },
            {
               value: this.manageGroupSettings,
               name: "Edit Group Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewPositionSettings,
               name: "View Job Titles Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes job titles tab.`,
            },
            {
               value: this.managePositionSettings,
               name: "Edit Job Titles Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewTagsSettings,
               name: "View Tag Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes tags tab.`,
            },
            {
               value: this.manageTagsSettings,
               name: "Edit Tag Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewStatusSettings,
               name: "View Status Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
         <br>Removes assignment / request statuses tab.`,
            },
            {
               value: this.manageStatusSettings,
               name: "Edit Status Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
         <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewAlertsSettings,
               name: "View Alert Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes alerts tab.`,
            },
            {
               value: this.manageAlertsSettings,
               name: "Edit Alert Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewCostingSettings,
               name: "View Costing Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes costing tab.`,
            },
            {
               value: this.manageCostingSettings,
               name: "Edit Costing Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewCustomFieldsSettings,
               name: "View Custom Field Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes custom fields tab.`,
            },
            {
               value: this.manageCustomFieldsSettings,
               name: "Edit Custom Field Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewQrCodesSettings,
               name: "View QR Code Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes QR codes tab.`,
            },
            {
               value: this.manageQrCodesSettings,
               name: "Edit QR Code Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes creation & editing controls.`,
            },
            {
               value: this.viewNotificationsSettings,
               name: "View Notifications Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes Notification Profiles tab.`,
            },
            {
               value: this.manageNotificationsSettings,
               name: "Edit Notifications Settings",
               details: `<span class='selected-details__header'>Settings Page:</span>
               <br>Removes Notification Profiles tab.`,
            },
         ],
      },
      {
         name: "MISC",
         permissions: [
            {
               value: this.canUnlockUsers,
               name: "Can Unlock Locked Out Users",
               details: `<span class='selected-details__header'>Person Detail Page:</span>
               <br>Allows the user to unlock other users who have been locked out of their account due to too many failed logins attempts.`,
            },
         ],
      },
   ];

   private readonly filteredPermissionGroupList: ObservableArray<PermissionGroup> = observableArray(
      this.permissionGroupList,
   );

   /* METHODS */
   private readonly filterPermissionGroupList = (searchQuery: Observable<string | null>) => {
      if (searchQuery() == null || searchQuery() == "") {
         this.filteredPermissionGroupList(this.permissionGroupList);
         return;
      }
      const lowercaseSearchTerm = searchQuery()!.toLowerCase();
      const filteredList: PermissionGroup[] = this.permissionGroupList
         .filter((group) =>
            group.permissions.some((perm) => {
               return perm.name.toLowerCase().includes(lowercaseSearchTerm);
            }),
         )
         .map((group) => {
            return {
               name: group.name,
               permissions: group.permissions.filter((perm) =>
                  perm.name.toLowerCase().includes(lowercaseSearchTerm),
               ),
            };
         });
      this.filteredPermissionGroupList(filteredList);
   };

   private readonly searchParams: SearchBarParams = {
      query: observable<string | null>(""),
      callback: this.filterPermissionGroupList,
   };

   private readonly getStagedPermission = (): CreatePermissionLevelPayload => {
      return {
         is_admin: this.isAdmin(),
         name: this.name(),
         visible_status_ids: [...this.selectedStatusIds()],
         // Permissions
         access_gantt_page: this.accessGanttPage(),
         access_map_page: this.accessMapPage(),
         access_totals_page: this.accessTotalsPage(),
         allow_exporting_data: this.allowExportingData(),
         can_unlock_users: this.canUnlockUsers(),
         can_view_all_statuses: this.canViewAllStatuses(),
         create_messages: this.createMessages(),
         create_people: this.createPeople(),
         create_project: this.createProject(),
         delete_people: this.deletePeople(),
         delete_project: this.deleteProject(),
         edit_people_attachments: this.editPeopleAttachments(),
         edit_people_details: this.editPeopleDetails(),
         edit_people_notes: this.editPeopleNotes(),
         edit_people_permissions: this.editPeoplePermissions(),
         edit_people_sensitive: this.editPeopleSensitive(),
         edit_people_tags: this.editPeopleTags(),
         edit_people_timeoff: this.editPeopleTimeoff(),
         edit_project_attachments: this.editProjectAttachments(),
         edit_project_categories: this.editProjectCategories(),
         edit_project_custom_alerts: this.editProjectCustomAlerts(),
         edit_project_default_recipients: this.editProjectDefaultRecipients(),
         edit_project_details: this.editProjectDetails(),
         edit_project_notes: this.editProjectNotes(),
         edit_project_roles: this.editProjectRoles(),
         edit_project_sensitive: this.editProjectSensitive(),
         edit_project_tags: this.editProjectTags(),
         edit_project_wage_overrides: this.editProjectWageOverrides(),
         manage_alerts_settings: this.manageAlertsSettings(),
         manage_alerts: this.manageAlerts(),
         manage_assignments: this.manageAssignments(),
         manage_company_settings: this.manageCompanySettings(),
         manage_costing_settings: this.manageCostingSettings(),
         manage_custom_fields_settings: this.manageCustomFieldsSettings(),
         manage_group_settings: this.manageGroupSettings(),
         manage_notifications_settings: this.manageNotificationsSettings(),
         manage_others_requests: this.manageOthersRequests(),
         manage_permission_settings: this.managePermissionSettings(),
         manage_position_settings: this.managePositionSettings(),
         manage_qr_codes_settings: this.manageQrCodesSettings(),
         manage_requests: this.manageRequests(),
         manage_status_settings: this.manageStatusSettings(),
         manage_tags_settings: this.manageTagsSettings(),
         view_alerts_settings: this.viewAlertsSettings(),
         view_alerts: this.viewAlerts(),
         view_assignments: this.viewAssignments(),
         view_company_settings: this.viewCompanySettings(),
         view_costing_settings: this.viewCostingSettings(),
         view_custom_fields_settings: this.viewCustomFieldsSettings(),
         view_group_settings: this.viewGroupSettings(),
         view_notifications_settings: this.viewNotificationsSettings(),
         view_people_activity: this.viewPeopleActivity(),
         view_people_attachments: this.viewPeopleAttachments(),
         view_people_financials: this.viewPeopleFinancials(),
         view_people_notes: this.viewPeopleNotes(),
         view_people_sensitive: this.viewPeopleSensitive(),
         view_people_tags: this.viewPeopleTags(),
         view_people_timeoff: this.viewPeopleTimeoff(),
         view_people: this.viewPeople(),
         view_permission_settings: this.viewPermissionSettings(),
         view_position_settings: this.viewPositionSettings(),
         view_project_activity: this.viewProjectActivity(),
         view_project_attachments: this.viewProjectAttachments(),
         view_project_default_recipients: this.viewProjectDefaultRecipients(),
         view_project_financials: this.viewProjectFinancials(),
         view_project_notes: this.viewProjectNotes(),
         view_project_roles: this.viewProjectRoles(),
         view_project_sensitive: this.viewProjectSensitive(),
         view_project_tags: this.viewProjectTags(),
         view_project_wage_overrides: this.viewProjectWageOverrides(),
         view_project: this.viewProject(),
         view_qr_codes_settings: this.viewQrCodesSettings(),
         view_requests_notes: this.viewRequestsNotes(),
         view_requests: this.viewRequests(),
         view_status_settings: this.viewStatusSettings(),
         view_tags_settings: this.viewTagsSettings(),
         view_unassociated_projects: this.viewUnassociatedProjects(),
      };
   };

   readonly savePermission = async (
      modalType: PermissionLevelModalType,
      afterSave: (permissionLevel: SerializedPermissionLevel) => void,
   ): Promise<void> => {
      const data = this.getStagedPermission();
      try {
         if (modalType == PermissionLevelModalType.CREATE_PERMISSION) {
            const newPermissionPayload = await PermissionStore.createPermissionLevel(data).payload;
            afterSave(newPermissionPayload.data);
         } else {
            const newPermissionPayload = await PermissionStore.updatePermissionLevel(this.id, data)
               .payload;
            afterSave(newPermissionPayload.data);
         }
         modalManager.clearModal();
      } catch (err) {
         return console.log(err);
      }
   };
}

components.register("permission-level-modal", {
   viewModel: PermissionLevelModal,
   template: template(),
   synchronous: true,
});

import template from "./cost-code-label-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { SerializedCostCodeLabel } from "@laborchart-modules/common/dist/rethink/serializers";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import type { EditorElementParams } from "../common/editor-element-template";

export type CostCodeLabelEditorElementParams = {
   costCodeLabel: PureComputed<SerializedCostCodeLabel | null>;
   costCodeLabelOptions: PureComputed<SerializedCostCodeLabel[]>;
   isDisabled?: PureComputed<boolean>;
   placeholder?: PureComputed<string | null>;
} & EditorElementParams<SerializedCostCodeLabel | null>;

export class CostCodeLabelEditorElement extends ViewModel {
   readonly title: string;

   readonly onChange: CostCodeLabelEditorElementParams["onChange"];
   readonly costCodeLabel: CostCodeLabelEditorElementParams["costCodeLabel"];
   readonly costCodeLabelOptions: CostCodeLabelEditorElementParams["costCodeLabelOptions"];

   readonly costCodeLabelMediator = pureComputed<SerializedCostCodeLabel | null>({
      read: () => this.costCodeLabel(),
      write: (costCodeLabel) => {
         this.onChange(costCodeLabel);
      },
   });

   readonly dropDown2Params: DropDown2Params<SerializedCostCodeLabel>;

   constructor({
      onChange,
      costCodeLabel,
      costCodeLabelOptions,
      isDisabled,
      placeholder,
      title = "Subcategory",
   }: CostCodeLabelEditorElementParams) {
      super(template());
      this.onChange = onChange;
      this.costCodeLabel = costCodeLabel;
      this.costCodeLabelOptions = costCodeLabelOptions;
      this.title = title;

      const costCodeLabelDropDownPane = new ArrayDropDownPane({
         items: pureComputed(() => {
            return this.costCodeLabelOptions().sort(
               (left, right) => left.sequence - right.sequence,
            );
         }) as PureComputed<SerializedCostCodeLabel[] | Error | null>,
         searchTextProvider: (label) => label.name,
      });
      this.dropDown2Params = {
         panes: [costCodeLabelDropDownPane],
         placeholder,
         cellFactory: TextCell.factory<SerializedCostCodeLabel>((item) => item.name),
         isClearable: true,
         isDisabled: isDisabled ?? false,
         selectedItemCellFactory: TextCell.factory<SerializedCostCodeLabel>((item) => item.name),
         selectedIds: pureComputed({
            read: () => {
               const costCodeLabelId = this.costCodeLabelMediator()?.id;
               return new Set(costCodeLabelId ? [costCodeLabelId] : []);
            },
            write: (ids) => {
               const loadedCostCodes = costCodeLabelDropDownPane.gridStore()?.rows() ?? [];
               const selectedCostCode = loadedCostCodes.find(
                  (costCodeLabel) => costCodeLabel.id == [...ids][0],
               );
               this.costCodeLabelMediator(selectedCostCode ?? null);
            },
         }),
         selectedItem: pureComputed({
            read: () => this.costCodeLabelMediator(),
            write: (data) => {
               this.costCodeLabelMediator(data);
            },
         }),
      };
   }

   static factory(
      params: CostCodeLabelEditorElementParams,
   ): ComponentArgs<CostCodeLabelEditorElementParams> {
      return {
         name: "cost-code-label-editor-element",
         params,
      };
   }
}

ko.components.register("cost-code-label-editor-element", {
   viewModel: CostCodeLabelEditorElement,
   template: template(),
   synchronous: true,
});

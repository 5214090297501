import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { GetCompanyResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-company";
import type { GetAlertConfigResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-alert-config";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import type { ResetDemoResponse } from "@laborchart-modules/lc-core-api/dist/api/company/reset-demo";

export abstract class CompanyStore extends Store {
   static getCompany(): StoreJsonResponse<GetCompanyResponse> {
      return this.requestJson({
         method: "GET",
         url: `/api/v3/company`,
      });
   }

   static getAlertConfig(): StoreJsonResponse<GetAlertConfigResponse> {
      return this.requestJson({
         method: "GET",
         url: "/api/v3/alert-config",
      });
   }

   static getIntegratedFields(): StoreJsonResponse<GetIntegratedFieldsResponse> {
      return this.requestJson({
         method: "GET",
         url: "/api/v3/integrated-fields",
      });
   }

   static resetDemoData(srcCompanyId: string): StoreJsonResponse<ResetDemoResponse> {
      return this.requestJson({
         method: "POST",
         url: `/api/v3/reset-demo`,
         body: { src_company_id: srcCompanyId },
      });
   }
}

import { Model } from "@/models/model"
import { Status } from "@/models/status"
import ko from "knockout"

export class Assignment extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.project_id, String)
         assertOfType(data.cost_code_id, nullable(String))
         assertOfType(data.label_id, nullable(String))
         assertOfType(data.resource_id, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.start_day, Number)
         assertOfType(data.end_day, Number)
         
         # Optional
         assertOfType(data.start_time, nullable(Number))
         assertOfType(data.end_time, nullable(Number))
         assertOfType(data.percent_allocated, nullable(Number))

         assertOfType(data.status_id, optional(String))


      ###------------------------------------------------
         Model ID & Meta
      ------------------------------------------------###
      @id = data.id
      @createdAt = ko.observable(data.created_at)

      ###------------------------------------------------
         Knockout Observables
      ------------------------------------------------###
      @projectId = ko.observable(data.project_id)
      @costCodeId = ko.observable(data.cost_code_id)
      @labelId = ko.observable(data.label_id)
      @resourceId = ko.observable(data.resource_id)
      @startDay = ko.observable(data.start_day)
      @endDay = ko.observable(data.end_day)

      @startTime = ko.observable(data.start_time)
      @endTime = ko.observable(data.end_time)
      @percentAllocated = ko.observable(data.percent_allocated)

      @workDays = ko.observable(data.work_days)
      @overtime = ko.observable(data.overtime)
      @paySplit = ko.observable(data.pay_split)
      @overtimeRates = ko.observable(data.overtime_rates)

      @statusId = ko.observable(data.status_id or null)
      @status = ko.observable(if data.status? then new Status(data.status) else null)



import type { GridColumn } from "./grid-column";
import type { RowBase } from "./grid-store";

export type GridColumnGroup<
   TRow extends RowBase,
   THeaderParams = unknown,
   TCellParams = unknown,
   TValue = unknown,
   TSaveValue = TValue,
> = {
   columns: Array<GridColumn<TRow, THeaderParams, TCellParams, TValue, TSaveValue>>;
   isDraggable?: boolean;
};

/** Utility for creating a column group for each column. */
export function createColumnGroupForEach<TRow extends RowBase, THeaderParams>(
   ...columns: Array<GridColumn<TRow, THeaderParams>>
): Array<GridColumnGroup<TRow, THeaderParams>> {
   return columns.map((column) => ({ columns: [column] }));
}

export function transformColumnGroups<TOld extends RowBase, TNew extends RowBase>({
   columnGroups,
   transform,
}: {
   columnGroups: Array<GridColumnGroup<TOld>>;
   transform: (row: TNew) => TOld;
}): Array<GridColumnGroup<TNew>> {
   return columnGroups.map(
      (columnGroup): GridColumnGroup<TNew> => ({
         columns: columnGroup.columns.map(
            (column): GridColumn<TNew> => ({
               ...column,
               cursorStateProvider: column.cursorStateProvider
                  ? (row) => column.cursorStateProvider!(transform(row))
                  : undefined,
               actionProvider: column.actionProvider
                  ? ({ row, ...rest }) => column.actionProvider!({ row: transform(row), ...rest })
                  : undefined,
               editorFactory: column.editorFactory
                  ? ({ row, ...rest }) => column.editorFactory!({ row: transform(row), ...rest })
                  : undefined,
               copyProvider: column.copyProvider
                  ? (row) => column.copyProvider!(transform(row))
                  : undefined,
               urlProvider: column.urlProvider
                  ? (row) => column.urlProvider!(transform(row))
                  : undefined,
               cellFactory: (row, context) => column.cellFactory(transform(row), context),
            }),
         ),
      }),
   );
}

import { PageTitle, type IntegratedField } from "@/react/prop-types";
import { isIntegratedField } from "@/react/shared/helper";
import { PersonStatus } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { useI18nContext } from "@procore/core-react";
import { Label, Typography } from "@procore/core-react";
import React from "react";
import { Lock } from "@procore/core-icons";
import { legacyFieldNamesMap } from "../../people-list/helpers";
import "../style.css";

// Function to get the person types
export const getPersonTypes = (I18n: ReturnType<typeof useI18nContext>) => [
   {
      id: 0,
      label: (
         <Typography>
            {`${I18n.t("views.company.workforce_planning.people.assignable")} `}
            <i>{I18n.t("views.company.workforce_planning.people.assignable_desc")}</i>
         </Typography>
      ),
      value: "assignable",
   },
   {
      id: 1,
      label: (
         <Typography>
            {`${I18n.t("views.company.workforce_planning.people.assignable_user")} `}
            <i>{I18n.t("views.company.workforce_planning.people.assignable_user_desc")}</i>
         </Typography>
      ),
      value: "assignable_user",
   },
   {
      id: 2,
      label: (
         <Typography>
            {`${I18n.t("views.company.workforce_planning.people.user")} `}
            <i>{I18n.t("views.company.workforce_planning.people.user_desc")}</i>
         </Typography>
      ),
      value: "user",
   },
];

// Function to get the person status map
export const getPersonStatusMap = (I18n: ReturnType<typeof useI18nContext>) =>
   ({
      active: {
         id: PersonStatus.ACTIVE,
         label: I18n.t("views.company.workforce_planning.active"),
         color: "green",
      },
      inactive: {
         id: PersonStatus.INACTIVE,
         label: I18n.t("views.company.workforce_planning.inactive"),
         color: "gray",
      },
   } as const);

// Function to get the gender options
export const getPersonGenderOptions = (I18n: ReturnType<typeof useI18nContext>) => [
   {
      id: "male",
      label: I18n.t("views.company.workforce_planning.people.male"),
   },
   {
      id: "female",
      label: I18n.t("views.company.workforce_planning.people.female"),
   },
];

// utility function to find an item by id
export const findById = <T extends { id: string }>(
   array: T[] | undefined,
   id: string | undefined | null,
) => {
   return array?.find((item) => item.id === id) ?? null;
};

export const getPersonType = (
   isAssignable: boolean,
   isUser: boolean,
   personTypes: Array<{ id: number; label: React.ReactNode; value: string }>,
) => {
   if (isAssignable && isUser) {
      return personTypes[1];
   }
   return isAssignable ? personTypes[0] : personTypes[2];
};

// Function to generate custom label for form fields
export const generateCustomLabel = (
   field: string,
   translationString: string,
   personIntegratedFields: IntegratedField[],
   I18n: ReturnType<typeof useI18nContext>,
): JSX.Element => {
   return (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
         <span>{I18n.t(translationString)}</span>
         {isIntegratedField(field, personIntegratedFields) && (
            <Lock size="sm" style={{ marginLeft: "3px" }} />
         )}
      </div>
   );
};

// Function to get the full name
export const getFullName = (name: { first: string; last: string } | undefined): string => {
   if (!name) {
      return "";
   }
   const { first, last } = name;
   return `${first} ${last}`.trim();
};

export const getReadOnlyViewForDates = (
   fieldSource: string | undefined,
   colStartBase: number,
   colWidth: number,
   I18n: ReturnType<typeof useI18nContext>,
   field: { id: string; name: string; integration_only: boolean },
   personIntegratedFields: IntegratedField[],
   fieldValue: string,
): JSX.Element => {
   return (
      <>
         <div
            style={{
               gridColumnStart: colStartBase,
               gridColumnEnd: `span ${colWidth}`,
               gridRow: 1,
            }}
         >
            <Label>
               {fieldSource === PageTitle.PEOPLE_DETAILS ? (
                  generateCustomLabel(
                     legacyFieldNamesMap[field.name],
                     field.id,
                     personIntegratedFields,
                     I18n,
                  )
               ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <span>{field.name}</span>
                     {(isIntegratedField(field.id, personIntegratedFields) ||
                        field.integration_only) && <Lock size="sm" style={{ marginLeft: "3px" }} />}
                  </div>
               )}
            </Label>
         </div>
         <div
            style={{
               gridColumnStart: colStartBase,
               gridColumnEnd: `span ${colWidth}`,
               gridRow: 2,
            }}
         >
            <span className="text-style">{fieldValue}</span>
         </div>
      </>
   );
};

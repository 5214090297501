import React from "react";
import { Switch } from "@procore/core-react";

export const HidePastTimeOffFilter = (props: any) => {
   return (
      <Switch
         {...props}
         checked={!!props.value}
         onChange={() => {
            let newValue = [{ value: true }];
            if (props.value) {
               newValue = [];
            }
            props.onChange(newValue);
         }}
      />
   );
};

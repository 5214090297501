import type { OptionItem } from "@/react/components/data-table/custom-filters/multiselect-pill-filter";
import {
   getMultiSelectId,
   getMultiSelectLabel,
   optionRenderer,
   tokenRenderer,
} from "@/react/components/data-table/custom-filters/multiselect-pill-filter";
import React, { useEffect } from "react";
import { Box, MultiSelect, useI18nContext } from "@procore/core-react";
import { ColorSwab } from "@/react/components/data-table/ColorSelectComponent/ColorSelectColumn";
import { useGetTagOptionsQuery } from "@/react/components/common/queries/queries";
import { ActionMode } from "@/react/prop-types";
export const TagSelector = (props: any) => {
   const I18n = useI18nContext();
   const [selected, setSelected] = React.useState<string[]>([]);
   const [options, setOptions] = React.useState<OptionItem[]>([]);
   const { data: tags } = useGetTagOptionsQuery();
   const { value } = props.field.input;
   const { setValue } = props.field.helpers;
   const { view } = props.field.meta;

   React.useEffect(() => {
      const fetchOptions = () => {
         const formattedTags = tags?.map((option: any) => ({
            id: option.id,
            label: option.label,
            name: option.name,
            color: option.color,
         })) as OptionItem[];
         if (formattedTags?.length > 0) {
            setOptions(formattedTags);
         }
      };

      fetchOptions();
   }, [tags]);

   useEffect(() => {
      // Only proceed if there is a value and tags are available
      if (value && tags) {
         const preSelected = value.map((selectedId: string) => {
            // Match each pre-selected ID with the full tag details
            const matchedTag = tags.find((tag: any) => tag.id === selectedId);
            if (matchedTag) {
               return {
                  id: matchedTag.id,
                  label: matchedTag.label,
                  name: matchedTag.name,
                  color: matchedTag.color,
               };
            }
            // Fallback: return the ID if no match is found
            return { id: selectedId, label: "", name: "", color: "" };
         });
         // Set the pre-selected options with full details
         setSelected(preSelected);
      }
   }, [value, tags]);

   function handleChange(key: any) {
      const selectedOpt = key?.map((option: any) => ({
         ...option,
         value: option.id,
         label: option.name,
      }));
      setSelected(selectedOpt);
      setValue(selectedOpt.map((option: any) => option.id));
   }

   const renderTags = () => {
      if (!Array.isArray(selected) || selected.length === 0) {
         return <span>--</span>;
      }

      return selected.map((tag: any) => (
         <ColorSwab key={tag.id} color={tag.color} shape="pill" label={tag.name} />
      ));
   };

   return (
      <>
         {view === ActionMode.READ ? (
            <Box>{renderTags()}</Box>
         ) : (
            <MultiSelect
               getId={getMultiSelectId}
               getLabel={getMultiSelectLabel}
               onChange={handleChange}
               options={options}
               optionRenderer={optionRenderer}
               tokenRenderer={tokenRenderer}
               value={selected}
               placeholder={I18n.t("views.company.workforce_planning.select_option")}
               block
            />
         )}
      </>
   );
};

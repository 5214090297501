import "./percent-allocation-editor-element.styl";
import template from "./percent-allocation-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";

export type PercentAllocationEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   percentAllocation: PureComputed<number | null>;
} & EditorElementParams<number | null>;

export class PercentAllocationEditorElement extends ViewModel {
   readonly invalidInputMessage: PercentAllocationEditorElementParams["invalidInputMessage"];
   readonly onChange: PercentAllocationEditorElementParams["onChange"];
   readonly percentAllocation: PercentAllocationEditorElementParams["percentAllocation"];
   readonly title: PercentAllocationEditorElementParams["title"];

   readonly percentAllocationMediator = pureComputed<number | null>({
      read: () => unwrap(this.percentAllocation),
      write: (percentAllocation) => {
         this.onChange(percentAllocation ? Number(percentAllocation) : null);
      },
   });

   readonly isClearable = pureComputed(() => false);

   constructor({
      invalidInputMessage,
      onChange,
      percentAllocation,
      title = "Percent Allocated",
   }: PercentAllocationEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.percentAllocation = percentAllocation;
      this.title = title;
   }

   static factory(
      params: PercentAllocationEditorElementParams,
   ): ComponentArgs<PercentAllocationEditorElementParams> {
      return {
         name: "percent-allocation-editor-element",
         params,
      };
   }
}

ko.components.register("percent-allocation-editor-element", {
   viewModel: PercentAllocationEditorElement,
   template: template(),
   synchronous: true,
});

import { Column } from "@/models/column"
import { Guid as GUID } from "@/lib/utils/guid"

export class ColumnGroup
   constructor: (params) ->
     data = params || {}
     @key = ko.observable(data.key || GUID())
     @sequence = ko.observable(data.sequence)
     @disableDrag = ko.observable(!!data.disableDrag)
     @columns = ko.observableArray(
        (params.columns || []).map  (column) => return new Column(column)
     )

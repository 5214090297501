import ko from 'knockout'
import "./chip-filter.styl"
import chipFilterTemplateFn from "./chip-filter.pug"
import { DateUtils } from "@/lib/utils/date"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { DropDownItem, } from '@/lib/components/drop-downs/drop-down'
# This symbol is made available to support a specific filtering of a classifier and value not both being ANY.
export ANY = Symbol("any")

TIME_TENSE = {
   PAST: 'last',
   PRESENT: 'current',
   FUTURE: 'next'
}

TIME_UNIT = {
   DAY: 'day',
   WEEK: 'week',
   MONTH: 'month',
   QUARTER: 'quarter',
   YEAR: 'year'
}

export class ChipFilter
   constructor: (params) ->
      @typesVisible = ko.observable(false)
      @selectedFilter = ko.observable(null)
      @showingClassifier = ko.observable(false)
      @selectedType = ko.observable(null)

      @searchQuery = ko.observable('')

      @pendingChip = ko.observable(null)
      # This was causing an issue with the fixed chips
      @originalChips = params.chips
      @chips = ko.observableArray(ko.unwrap(params.chips).slice(0))
      @fixedChips = params.fixedChips or null

      @matchTypeOptions = [
         new SegmentedControllerItem("Matches", "match", "chip-filter--match")
         new SegmentedControllerItem("Doesn't Match", "not-match", "chip-filter--not-match")
      ]
      @selectedMatchType = ko.observable(@matchTypeOptions[0])

      @dateRelativeOptions = [
         new SegmentedControllerItem("Last", TIME_TENSE.PAST)
         new SegmentedControllerItem("Current", TIME_TENSE.PRESENT)
         new SegmentedControllerItem("Next", TIME_TENSE.FUTURE)
      ]

      @dateRelativeSelection = ko.observable(@dateRelativeOptions[1])

      @dateRelativeSelection.subscribe (val) =>
         unit = @selectedRelativeDateIncrements()
         return unless unit
         newUnits = if val.value() == TIME_TENSE.PRESENT then singularUnits else pluralUnits
         if !newUnits.find((u) -> u == unit)
            @selectedRelativeDateIncrements(newUnits.find((u) -> u.value() == unit.value()))

      singularUnits = [
               new DropDownItem("Day", TIME_UNIT.DAY)
               new DropDownItem("Week", TIME_UNIT.WEEK)
               new DropDownItem("Month", TIME_UNIT.MONTH)
               new DropDownItem("Quarter", TIME_UNIT.QUARTER)
               new DropDownItem("Year", TIME_UNIT.YEAR)
            ]
      pluralUnits = [
               new DropDownItem("Days", TIME_UNIT.DAY)
               new DropDownItem("Weeks", TIME_UNIT.WEEK)
               new DropDownItem("Months", TIME_UNIT.MONTH)
               new DropDownItem("Quarters", TIME_UNIT.QUARTER)
               new DropDownItem("Years", TIME_UNIT.YEAR)
            ]
      @relativeDateIncrements = ko.pureComputed =>
         if @dateRelativeSelection().value() == TIME_TENSE.PRESENT
            return singularUnits
         else
            return pluralUnits

      @selectedRelativeDateIncrements = ko.observable(@relativeDateIncrements()[0])
      @relativeDateIncrements.subscribe () =>
         if @dateRelativeSelection().value() == TIME_TENSE.PRESENT || @valueInput() == null
            @valueInput(1)

      @showRelativeDateInput = ko.pureComputed => @dateRelativeSelection().value() != TIME_TENSE.PRESENT
      @inputValueIsNumber = ko.pureComputed => Boolean(@valueInput())

      if params.defaultChips? 
         if params.defaultChips instanceof Function
            @defaultChips = params.defaultChips
         else
            @defaultChips = ko.observableArray(params.defaultChips)
      else
         @defaultChips = ko.observableArray([])

      @mainFilterTitle = params.mainFilterTitle or "Filter"
      @classifierPaneName = ko.observable("Filter Type")

      @labelNames = ko.observableArray()
      @labeledOptions = params.labeledOptions
      # Initial Load
      if @labeledOptions()?
         newNames = []
         for key of @labeledOptions()
            newNames.push(key)
         @labelNames(newNames.sort (a, b) ->
            return a.toLowerCase().localeCompare(b.toLowerCase())
         )

      @labeledOptions.subscribe (newVal) =>
         @labelNames([])
         newNames = []
         if newVal?
            for key of newVal
               newNames.push(key)
         @labelNames(newNames.sort (a, b) ->
            return a.toLowerCase().localeCompare(b.toLowerCase())
         )

      @typeOptions = ko.pureComputed =>
         return [] unless @labeledOptions()[@selectedFilter()]?().classifiers?
         if @labeledOptions()[@selectedFilter()]().disableSearch
            return @labeledOptions()[@selectedFilter()]().classifiers
         else
            search = @searchQuery().toLowerCase()
            return ko.utils.arrayFilter @labeledOptions()[@selectedFilter()]().classifiers, (item) ->
               item.listLabel.toLowerCase().indexOf(search) >= 0

      @searchVisible = ko.pureComputed => 
         if !@selectedFilter()
            return true
         else 
            try
               return !@labeledOptions()[@selectedFilter()]().disableSearch
            catch
               return true

      @valueOptions = ko.pureComputed =>
         return [] unless @labeledOptions()?[@selectedFilter()]?().values?
         if @labeledOptions()[@selectedFilter()]().disableSearch
            return @labeledOptions()[@selectedFilter()]().values
         search = @searchQuery().toLowerCase()
         values = @labeledOptions()[@selectedFilter()]().values
         unless values instanceof Array
            # Grouped values by classifier
            return [] unless @selectedType()?
            values = values[@selectedType().value]
         return ko.utils.arrayFilter values, (item) =>
            # Special case for filtering an ANY value if the selected type existed and was ANY also
            if @selectedType()?.value == ANY and item.value() == ANY
               return false
            return item.name().toLowerCase().indexOf(search) >= 0

      @hasChanges = ko.pureComputed =>
         return true if @chips().length != @originalChips().length
         for chip in @chips()
            foundMatch = false
            for oChip in @originalChips()
               if oChip.value == chip.value
                  foundMatch = true
                  break
            return true unless foundMatch
         return false

      @overflowCount = ko.observable()

      @allChipsVisible = ko.observable(false)

      @showValueList = ko.observable(false)
      @isMultiSelect = ko.observable(false)
      @isNumeric = ko.observable(false)
      @isInput = ko.observable(false)
      @isDate = ko.observable(false)
      @isValued = ko.observable(false)
      @showValues = ko.observable(false)
      @backEnabled = ko.observable(false)

      @valueInput = ko.observable(null)
      @filterDate = ko.observable()

      @multiHasSelections = ko.pureComputed =>
         return false unless @selectedFilter()?
         try
            options = @labeledOptions()[@selectedFilter()]().values
            if options instanceof Array
               for item in options
                  return true if item.selected() 
               return false
            else
               for kay, values of options
                  for item in values
                     return true if item.selected()
               return false
         catch
            return false

      params.mediator.initialize(@)

   updateVisibleFilters: (filters) =>
      # TODO: Check for fixed values and add them if needed.
      @chips(filters)

   toggleItemSelected: (item) ->
      if item.selected()?
         return item.selected(!item.selected())
      else
         item.selected(true)

   showTypes: ->
      @selectedType(null)
      @pendingChip(null)
      @showingClassifier(false)
      @selectedMatchType(@matchTypeOptions[0])
      @dateRelativeSelection(@dateRelativeOptions[1])
      @selectedRelativeDateIncrements(@relativeDateIncrements()[0])
      # Clear Data Types
      @isMultiSelect(false)
      @isNumeric(false)
      @isInput(false)
      @isDate(false)
      @isValued(false)

      # Clear Vals
      @valueInput(null)
      @filterDate(null)

      @typesVisible(!@typesVisible())
      if @overflowCount() > 0
         @allChipsVisible(true)

   filterSelected: (filter) =>
      @typesVisible(false)
      if @labeledOptions()[filter]?().type == "multi-select"
         @isMultiSelect(true)

      if @labeledOptions()[filter]?().type?
         if (@labeledOptions()[filter]?().type == "number" or
         @labeledOptions()[filter]?().type == "currency")
            @isNumeric(true)
         else if @labeledOptions()[filter]?().type == "date"
            @isDate(true)
         else if @labeledOptions()[filter]?().type == "text"
            @isInput(true)
         else
            @isValued(true)
      else
         @isValued(true)

      if @labeledOptions()[filter]?().classifiers?
         @showingClassifier(true)
      else
         @showValues(true)

      if @labeledOptions()[filter]?().backEnabled?
         @backEnabled(@labeledOptions()[filter]?().backEnabled)
      else
         @backEnabled(false)

      if @labeledOptions()[filter]?().classifierPaneName?
         @classifierPaneName(@labeledOptions()[filter]?().classifierPaneName)
      else
         @classifierPaneName("Filter Type")

      @selectedFilter(filter)
      @pendingChip({
         filterName: filter
         type: @labeledOptions()[filter]?().type
         property: @labeledOptions()[filter]?().property
         classifier: null
         classifierLabel: null
         value: null
         customFieldId: @labeledOptions()[filter]?().customFieldId or null
         isPending: true
         negation: @selectedMatchType().value() == "not-match"
         timestamp: @labeledOptions()[filter]?().timestamp or false
      })
      @searchQuery('')

   typeSelected: (type) =>
      @selectedType(type)
      pendingChip = @pendingChip()
      pendingChip.classifier = type.value
      pendingChip['classifierLabel'] = type.chipLabel
      @pendingChip(pendingChip)
      @showingClassifier(false)
      @showValues(true)
      @searchQuery('')
   
   valueSelected: (valueSet) =>
      newChip = @pendingChip()
      newChip['valueName'] = valueSet.name()
      newChip['value'] = valueSet.value()
      newChip['negation'] = @selectedMatchType().value() == "not-match"
      @pendingChip(null)
      @chips.push(newChip)
      @showValues(false)
      @selectedFilter(null)
      @typesVisible(false)

   saveMultipleValues: =>
      selected = []
      options = @labeledOptions()[@selectedFilter()]().values
      if options instanceof Array
         for option in options
            selected.push(option) if option.selected()
      else
         for key, values of options
            for item in values
               selected.push(item) if item.selected()

      return unless selected.length > 0

      newChip = @pendingChip()
      newChip['valueName'] = selected.map((i) -> i.name()).join(" & ")
      newChip['value'] = selected
      newChip['negation'] = @selectedMatchType().value() == "not-match"
      for item in selected
         item.selected(false)
      @pendingChip(null)
      @chips.push(newChip)
      @showValues(false)
      @selectedFilter(null)
      @typesVisible(false)
      @isNumeric(false)

   saveRelativeDateValue: (value) =>
      timeTense = @dateRelativeSelection().value()
      timeUnit = @selectedRelativeDateIncrements().value()
      timeQuantity = Math.abs(Number(value))
      newChip = @pendingChip()

      newChip.classifier = "<=x<"
      newChip.negation = @selectedMatchType().value() == "not-match"
      # Required to flag off that this filter needs to be JSON.stringified.
      newChip.enableRelativeDate = true
      newChip.valueName = @calculateRelativeDateValueName_(timeTense, timeUnit, timeQuantity)
      newChip.value = @calculateRelativeDateValue_(timeTense, timeUnit, timeQuantity)

      @filterDate(null)
      @pendingChip(null)
      @chips.push(newChip)
      @showValues(false)
      @selectedFilter(null)
      @typesVisible(false)
      @isNumeric(false)

   saveInputValue: =>
      newChip = @pendingChip()
      value = if @isNumeric() then Number(@valueInput()) else @valueInput()
      newChip['valueName'] = value
      newChip['value'] = value
      newChip['negation'] = @selectedMatchType().value() == "not-match"
      @pendingChip(null)
      @chips.push(newChip)
      @showValues(false)
      @selectedFilter(null)
      @typesVisible(false)
      @isNumeric(false)

   calculateRelativeDateValue_: (tense, unit, quantity) =>
      if tense == TIME_TENSE.PRESENT
         return [
            [unit, 0],
            [unit, 1]
         ]
      if tense == TIME_TENSE.PAST
         return [
            [unit, -1 * quantity],
            [unit, 0]
         ]
      if tense == TIME_TENSE.FUTURE
         return [
            [unit, 1],
            [unit, quantity + 1]
         ]

      throw Error("Invalid relative date tense.")

   calculateRelativeDateValueName_: (tense, unit, quantity) =>
      if tense == TIME_TENSE.PRESENT &&
            unit == TIME_UNIT.DAY
         return 'Today'
      if tense == TIME_TENSE.PAST &&
            unit == TIME_UNIT.DAY &&
            quantity == 1
         return 'Yesterday'
      if tense == TIME_TENSE.FUTURE &&
            unit == TIME_UNIT.DAY &&
            quantity == 1
         return 'Tomorrow'

      if tense == TIME_TENSE.PRESENT
         return "Within This #{@calculateTimeUnitLabel_(unit, 0)}"
      if tense == TIME_TENSE.PAST
         return "Within Last #{@calculateTimeUnitLabel_(unit, quantity)}"
      if tense == TIME_TENSE.FUTURE
         return "Within Next #{@calculateTimeUnitLabel_(unit, quantity)}"

      throw Error("Invalid relative date.")

   calculateTimeUnitLabel_: (unit, quantity) =>
      if unit == TIME_UNIT.DAY
         return if quantity < 2 then 'Day' else "#{quantity} Days"
      if unit == TIME_UNIT.WEEK
         return if quantity < 2 then 'Week' else  "#{quantity} Weeks"
      if unit == TIME_UNIT.MONTH
         return if quantity < 2 then 'Month' else  "#{quantity} Months"
      if unit == TIME_UNIT.QUARTER
         return if quantity < 2 then 'Quarter' else "#{quantity} Quarters"
      if unit == TIME_UNIT.YEAR
         return if quantity < 2 then 'Year' else "#{quantity} Years"

      throw Error("Invalid relative date unit.")

   saveDateValue: =>
      newChip = @pendingChip()
      newChip['valueName'] = DateUtils.getShortNumericDate(@filterDate(), defaultStore.getDateFormat())

      if newChip.timestamp
         newChip['value'] = @filterDate().getTime()
      else   
         newChip['value'] = DateUtils.getDetachedDay(@filterDate())

      newChip['negation'] = @selectedMatchType().value() == "not-match"
      @filterDate(null)
      @pendingChip(null)
      @chips.push(newChip)
      @showValues(false)
      @selectedFilter(null)
      @typesVisible(false)
      @isNumeric(false)

   backToClassifiers: =>
      @showingClassifier(true)
      @showValues(false)

   removeChip: (chip) =>
      @chips.remove(chip)

   clearChips: ->
      chips = @defaultChips()
      if @fixedChips?
         fixedChips = ko.unwrap(@fixedChips)
         chips = fixedChips.concat(chips)
      @chips(chips.slice(0))
      @originalChips(chips)
      @allChipsVisible(false)

   applyChanges: ->
      # Clone the array
      cleanedChips = @chips().map (item) ->
         delete item.isPending
         return item
      @chips([])
      @chips(cleanedChips)
      @originalChips(cleanedChips.slice(0))
      @allChipsVisible(false)

   showAllChips: ->
      @allChipsVisible(!@allChipsVisible())

   handleSelectorClickOff: =>
      for option in @valueOptions()
         option.selected(false)
      @pendingChip(null)
      @selectedFilter(null)
      @showingClassifier(false)
      @typesVisible(false)
      @isMultiSelect(false)
      @isNumeric(false)
      @showValues(false)
      @searchQuery('')

chipFilterTemplate = chipFilterTemplateFn()

if !ko.components.isRegistered('chip-filter')
   ko.components.register("chip-filter",
      viewModel: ChipFilter,
      template: chipFilterTemplate,
   )
import { Ban, Check, PaperAirplane } from "@procore/core-icons";
import {
   Button,
   H1,
   Page,
   Tearsheet,
   Form,
   Box,
   Flex,
   FlexList,
   Card,
   Required,
   H2,
   Typography,
   Switch,
   P,
   Table,
   useI18nContext,
} from "@procore/core-react";
import React from "react";
import type { TableApi } from "@procore/data-table";
import {
   scheduleAlertHourOptions,
   scheduleAlertMinuteOptions,
} from "@/react/components/communications/constants";
import { PersonStore } from "@/stores/person-store.core";
import { useGroupContext } from "@/react/providers/group-context-provider";
import type { FindPeoplePaginatedQueryParams } from "@laborchart-modules/lc-core-api";
import { FindPeopleSortBy } from "@/react/components/people-list/people-list-enums";
import { useToastAlertContext } from "@procore/toast-alert";
import { ConversationContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { sendOrScheduleMessage } from "./message-queries";
import { bulkSendMessageSchema } from "./bulk-message-validation-schema";
import { isBefore } from "date-fns";

type BulkMessageTearsheetProps = {
   tableApi?: TableApi;
};

type CheckOption = {
   id: string | number;
   label: string;
   value: string | number;
};

export type BulkMessageFormValues = {
   content: string;
   day_night: CheckOption;
   hour: CheckOption;
   minute: CheckOption;
   recipients: any[];
   scheduled_day: Date;
   send_as: CheckOption;
   subject: string;
   message_visibility: CheckOption[];
};

export const BulkSendMessageTearsheet = (props: BulkMessageTearsheetProps) => {
   const I18n = useI18nContext();
   const { tableApi } = props;
   const [recipients, setRecipients] = React.useState<any[]>([]);
   const { groupId } = useGroupContext();
   const [isOpen, setIsOpen] = React.useState<boolean>(false);
   const [recipientOptions, setRecipientOptions] = React.useState<any[]>([]);
   const [conversationContext, setConversationContext] = React.useState<ConversationContext>(
      ConversationContext.SENT,
   );
   const shouldScheduleDelivery = conversationContext === ConversationContext.SCHEDULED;
   const { showToast } = useToastAlertContext();

   function handleClose(): void {
      setIsOpen(false);
   }

   async function handleSubmit(values: BulkMessageFormValues) {
      try {
         await sendOrScheduleMessage(values, conversationContext);
         const toastMessage = shouldScheduleDelivery
            ? I18n.t(
                 "views.company.workforce_planning.communications.message_toasts.scheduled_success",
              )
            : I18n.t("views.company.workforce_planning.communications.message_toasts.sent_success");
         showToast.success(toastMessage);
         handleClose();
      } catch (error) {
         showToast.error(
            I18n.t("views.company.workforce_planning.communications.message_toasts.failure"),
         );
      }
   }

   React.useEffect(() => {
      if (isOpen && tableApi) {
         setRecipients(
            tableApi.getSelectedRows().map((r) => ({
               ...r,
               label: `${r.name.first} ${r.name.last}`,
            })),
         );
      }
   }, [isOpen, tableApi]);

   React.useEffect(() => {
      fetchRecipientOptions();
   }, [groupId]);

   async function fetchRecipientOptions(search?: string) {
      if (search) {
         setRecipientOptions([]);
      }
      const params: FindPeoplePaginatedQueryParams = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         sort_by: FindPeopleSortBy.PERSON_NAME_FIRST,
         starting_after: search ? undefined : recipientOptions.at(-1)?.id, // ID of the last fetched item
         group_ids: groupId === "my-groups" ? undefined : [groupId],
         search: search,
      };
      const response = await PersonStore.findPeopleList(params).payload;

      const options = response.data.map((person) => ({
         id: person.id,
         label: `${person.name.first} ${person.name.last}`,
         name: person.name,
         can_receive_email: person.can_receive_email,
         can_receive_sms: person.can_receive_sms,
         can_receive_mobile: person.can_receive_mobile,
      }));
      setRecipientOptions((prevState: any[]) => prevState.concat(options));
   }
   return (
      <>
         <Button onClick={() => setIsOpen(true)} icon={<PaperAirplane />} variant="tertiary" />
         <Tearsheet open={isOpen} onClose={() => setIsOpen(false)}>
            <Page>
               <Page.Main
                  style={{
                     width: "952px",
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  <Page.Header>
                     <Page.Title>
                        <H1>{I18n.t("views.company.workforce_planning.communications.message")}</H1>
                     </Page.Title>
                  </Page.Header>
                  <Form
                     view="create"
                     onSubmit={handleSubmit}
                     initialValues={{
                        recipients: recipients,
                        send_as: {
                           id: "individual_messages",
                           value: "individual_messages",
                           label: "",
                        },
                        subject: "",
                        content: "",
                        scheduled_day: new Date(),
                        message_visibility: [],
                        day_night: {
                           id: "am",
                           value: "am",
                           label: "AM",
                        },
                        hour: {
                           id: 12,
                           value: 0,
                           label: "12",
                        },
                        minute: {
                           id: 0,
                           value: 0,
                           label: "00",
                        },
                     }}
                     validationSchema={bulkSendMessageSchema(I18n, shouldScheduleDelivery)}
                  >
                     {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                     {({ values, isSubmitting, submitForm, setFieldValue }: FormikProps<any>) => (
                        <>
                           <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                              <Form.Form>
                                 <Box padding="md">
                                    <Card>
                                       <Box padding="md">
                                          <H2>
                                             {I18n.t(
                                                "views.company.workforce_planning.communications.recipients",
                                             )}
                                          </H2>
                                          <Form.Row style={{ paddingTop: "8px" }}>
                                             <Form.RadioButtons
                                                name="send_as"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.communications.send_as",
                                                )}
                                                required
                                                options={[
                                                   {
                                                      id: "individual_messages",
                                                      value: "individual_messages",
                                                      label: (
                                                         <Typography>
                                                            {I18n.t(
                                                               "views.company.workforce_planning.communications.individual_messages",
                                                            )}
                                                         </Typography>
                                                      ),
                                                   },
                                                   {
                                                      id: "group_message",
                                                      value: "group_message",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.communications.group_messages",
                                                      ),
                                                   },
                                                ]}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.MultiSelect
                                                name="recipients"
                                                label="Add Recipient"
                                                options={recipientOptions}
                                                loading={recipientOptions.length === 0}
                                                onScrollBottom={() => {
                                                   fetchRecipientOptions();
                                                }}
                                                onSearch={(event) => {
                                                   const search = event.target.value;
                                                   fetchRecipientOptions(search);
                                                }}
                                             />
                                          </Form.Row>
                                          <Table.Container>
                                             <Table.Header style={{ width: "100%" }}>
                                                <Table.HeaderCell>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.time_off.name",
                                                   )}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.people.email",
                                                   )}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.communications.sms",
                                                   )}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.communications.mobile",
                                                   )}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                             </Table.Header>
                                             <Table.Body>
                                                {values.recipients.map((recipient: any) => (
                                                   <Table.BodyRow key={`recipient-${recipient.id}`}>
                                                      <Table.BodyCell style={{ width: "50%" }}>
                                                         <Table.TextCell>
                                                            <P>{`${recipient.name.first} ${recipient.name.last}`}</P>
                                                         </Table.TextCell>
                                                      </Table.BodyCell>
                                                      <Table.BodyCell>
                                                         <Flex justifyContent="center">
                                                            <Table.IconCell>
                                                               {recipient.can_receive_email ? (
                                                                  <Check />
                                                               ) : (
                                                                  <Ban />
                                                               )}
                                                            </Table.IconCell>
                                                         </Flex>
                                                      </Table.BodyCell>
                                                      <Table.BodyCell>
                                                         <Flex justifyContent="center">
                                                            <Table.IconCell>
                                                               {recipient.can_receive_sms ? (
                                                                  <Check />
                                                               ) : (
                                                                  <Ban />
                                                               )}
                                                            </Table.IconCell>
                                                         </Flex>
                                                      </Table.BodyCell>
                                                      <Table.BodyCell>
                                                         <Flex justifyContent="center">
                                                            <Table.IconCell>
                                                               {recipient.can_receive_mobile ? (
                                                                  <Check />
                                                               ) : (
                                                                  <Ban />
                                                               )}
                                                            </Table.IconCell>
                                                         </Flex>
                                                      </Table.BodyCell>
                                                      <Table.BodyCell style={{ width: "30%" }}>
                                                         <Flex justifyContent="center">
                                                            <Button
                                                               variant="secondary"
                                                               onClick={() => {
                                                                  const newRecipients =
                                                                     values.recipients.filter(
                                                                        (r: any) =>
                                                                           r.id !== recipient.id,
                                                                     );
                                                                  setFieldValue(
                                                                     "recipients",
                                                                     newRecipients,
                                                                  );
                                                               }}
                                                            >
                                                               {I18n.t(
                                                                  "views.company.workforce_planning.remove",
                                                               )}
                                                            </Button>
                                                         </Flex>
                                                      </Table.BodyCell>
                                                   </Table.BodyRow>
                                                ))}
                                             </Table.Body>
                                          </Table.Container>
                                       </Box>
                                    </Card>
                                    <Box paddingTop="sm">
                                       <Card>
                                          <Box padding="md">
                                             <Form.Row>
                                                <Form.Text
                                                   name="subject"
                                                   colStart={1}
                                                   colWidth={12}
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.communications.subject",
                                                   )}
                                                   required
                                                />
                                             </Form.Row>
                                             <Form.Row>
                                                <Form.TextArea
                                                   name="content"
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.communications.message_content",
                                                   )}
                                                   required
                                                   style={{
                                                      height: "259px",
                                                      width: "872px",
                                                   }}
                                                />
                                             </Form.Row>
                                             <Flex gap="sm">
                                                <Switch
                                                   data-testid="scheduled-delivery-switch"
                                                   checked={shouldScheduleDelivery}
                                                   onChange={() =>
                                                      setConversationContext((prevState) =>
                                                         prevState === ConversationContext.SENT
                                                            ? ConversationContext.SCHEDULED
                                                            : ConversationContext.SENT,
                                                      )
                                                   }
                                                />
                                                <P>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.communications.schedule_delivery",
                                                   )}
                                                </P>
                                             </Flex>
                                             {shouldScheduleDelivery && (
                                                <Form.Row>
                                                   <Form.DateSelect
                                                      colStart={1}
                                                      name="scheduled_day"
                                                      label={I18n.t(
                                                         "views.company.workforce_planning.communications.date",
                                                      )}
                                                      disabledDate={(date, today) =>
                                                         isBefore(date, today)
                                                      }
                                                      required
                                                   />
                                                   <Form.Select
                                                      colStart={4}
                                                      colWidth={3}
                                                      name="hour"
                                                      label={I18n.t(
                                                         "views.company.workforce_planning.communications.hour",
                                                      )}
                                                      options={scheduleAlertHourOptions}
                                                      onSearch={false}
                                                      required
                                                   />
                                                   <Form.Select
                                                      colStart={7}
                                                      colWidth={3}
                                                      name="minute"
                                                      label={I18n.t(
                                                         "views.company.workforce_planning.communications.minute",
                                                      )}
                                                      options={scheduleAlertMinuteOptions}
                                                      onSearch={false}
                                                      required
                                                   />
                                                   <Form.RadioButtons
                                                      colStart={10}
                                                      colWidth={2}
                                                      name="day_night"
                                                      label={I18n.t(
                                                         "views.company.workforce_planning.communications.day_night",
                                                      )}
                                                      tooltip={I18n.t(
                                                         "views.company.workforce_planning.communications.day_night_tooltip",
                                                      )}
                                                      options={[
                                                         {
                                                            id: "am",
                                                            label: "AM",
                                                            value: "am",
                                                         },
                                                         {
                                                            id: "pm",
                                                            label: "PM",
                                                            value: "pm",
                                                         },
                                                      ]}
                                                   />
                                                </Form.Row>
                                             )}
                                             <Form.Row>
                                                <Form.Checkboxes
                                                   name="message_visibility"
                                                   label="Message Visibility"
                                                   options={[
                                                      {
                                                         id: "all_can_view",
                                                         value: "all_can_view",
                                                         label: "All users can view this message",
                                                      },
                                                   ]}
                                                />
                                             </Form.Row>
                                          </Box>
                                       </Card>
                                    </Box>
                                 </Box>
                              </Form.Form>
                           </Page.Body>
                           <Page.Footer>
                              <Box padding="md">
                                 <Flex justifyContent="space-between" alignItems="center">
                                    <Required showLabel />
                                    <FlexList space="sm">
                                       <Button
                                          variant="tertiary"
                                          disabled={isSubmitting}
                                          onClick={handleClose}
                                       >
                                          {I18n.t("views.company.workforce_planning.cancel")}
                                       </Button>
                                       <Button
                                          variant="secondary"
                                          onClick={() => {
                                             setConversationContext(ConversationContext.DRAFTS);
                                             submitForm();
                                          }}
                                       >
                                          {I18n.t(
                                             "views.company.workforce_planning.communications.save_draft",
                                          )}
                                       </Button>
                                       <Button variant="primary" onClick={submitForm}>
                                          {I18n.t(
                                             "views.company.workforce_planning.communications.send",
                                          )}
                                       </Button>
                                    </FlexList>
                                 </Flex>
                              </Box>
                           </Page.Footer>
                        </>
                     )}
                  </Form>
               </Page.Main>
            </Page>
         </Tearsheet>
      </>
   );
};

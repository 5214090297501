import React from "react";
import { Button, Form, Modal, useI18nContext } from "@procore/core-react";
import type { FormikProps } from "formik";

export type ExportType = "csv" | "pdf";
export type ExportModalProps = {
   onExport: (params: ExportFormValues) => void;
};

export type ExportFormValues = {
   file_type: {
      id: "pdf" | "csv";
      value: "pdf" | "csv";
      label: string;
   };
   orientation: {
      id: "portrait" | "landscape";
      value: "portrait" | "landscape";
      label: string;
   };
   page_size: {
      id: string;
      value: {
         width: number;
         height: number;
      };
      label: string;
   };
};
export const ExportModal = (props: Readonly<ExportModalProps>) => {
   const I18n = useI18nContext();
   const [exportModalOpen, setExportModalOpen] = React.useState<boolean>(false);

   function handleSubmit(params: ExportFormValues) {
      props.onExport(params);
      setExportModalOpen(false);
   }

   function handleClick() {
      setExportModalOpen(true);
   }

   const initialValues: ExportFormValues = {
      file_type: {
         id: "pdf",
         value: "pdf",
         label: I18n.t("views.company.workforce_planning.export_modal.page_sizes.pdf"),
      },
      orientation: {
         id: "landscape",
         value: "landscape",
         label: I18n.t("views.company.workforce_planning.export_modal.landscape"),
      },
      page_size: {
         id: "letter",
         label: I18n.t("views.company.workforce_planning.export_modal.page_sizes.letter"),
         value: {
            width: 792,
            height: 612,
         },
      },
   };

   return (
      <>
         <Button onClick={handleClick} variant="secondary">
            {I18n.t("views.company.workforce_planning.export_modal.export")}
         </Button>
         <Modal
            onClose={() => setExportModalOpen(false)}
            open={exportModalOpen}
            howToClose={["x", "footer-button"]}
            width="md"
         >
            <Form view="create" initialValues={initialValues} onSubmit={handleSubmit}>
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ submitForm, values }: FormikProps<any>) => (
                  <>
                     <Modal.Header>
                        <Modal.Heading>
                           {I18n.t("views.company.workforce_planning.export_modal.modal_header")}
                        </Modal.Heading>
                     </Modal.Header>
                     <Modal.Body>
                        <Modal.Section>
                           <Form.Form>
                              <Form.Row>
                                 <Form.RadioButtons
                                    colStart={1}
                                    colWidth={6}
                                    name="file_type"
                                    label={I18n.t(
                                       "views.company.workforce_planning.export_modal.file_type",
                                    )}
                                    options={[
                                       {
                                          id: "pdf",
                                          value: "pdf",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.pdf",
                                          ),
                                       },
                                       {
                                          id: "csv",
                                          value: "csv",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.csv",
                                          ),
                                       },
                                    ]}
                                 />
                                 <Form.RadioButtons
                                    colStart={7}
                                    colWidth={6}
                                    name="orientation"
                                    label={I18n.t(
                                       "views.company.workforce_planning.export_modal.page_format",
                                    )}
                                    options={[
                                       {
                                          id: "portrait",
                                          value: "portrait",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.portrait",
                                          ),
                                       },
                                       {
                                          id: "landscape",
                                          value: "landscape",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.landscape",
                                          ),
                                       },
                                    ]}
                                    disabled={values.file_type === "csv"}
                                 />
                              </Form.Row>
                              <Form.Row>
                                 <Form.Select
                                    colStart={1}
                                    colWidth={6}
                                    name="page_size"
                                    label={I18n.t(
                                       "views.company.workforce_planning.export_modal.page_size",
                                    )}
                                    options={[
                                       {
                                          id: "letter",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.letter",
                                          ),
                                          value: { width: 612, height: 792 },
                                       },
                                       {
                                          id: "tabloid",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.tabloid",
                                          ),
                                          value: { width: 792, height: 1224 },
                                       },
                                       {
                                          id: "legal",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.legal",
                                          ),
                                          value: { width: 612, height: 1008 },
                                       },
                                       {
                                          id: "arch_a",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.arch_a",
                                          ),
                                          value: { width: 648, height: 864 },
                                       },
                                       {
                                          id: "arch_b",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.arch_b",
                                          ),
                                          value: { width: 864, height: 1296 },
                                       },
                                       {
                                          id: "arch_c",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.arch_c",
                                          ),
                                          value: { width: 1296, height: 1728 },
                                       },
                                       {
                                          id: "arch_d",
                                          label: I18n.t(
                                             "views.company.workforce_planning.export_modal.page_sizes.arch_d",
                                          ),
                                          value: { width: 1728, height: 2592 },
                                       },
                                    ]}
                                 />
                              </Form.Row>
                           </Form.Form>
                        </Modal.Section>
                     </Modal.Body>
                     <Modal.Footer>
                        <Modal.FooterButtons>
                           <Button
                              type="submit"
                              onClick={submitForm}
                              data-testid="export-submit-button"
                           >
                              {I18n.t("views.company.workforce_planning.export_modal.export")}
                           </Button>
                        </Modal.FooterButtons>
                     </Modal.Footer>
                  </>
               )}
            </Form>
         </Modal>
      </>
   );
};

import template from "./custom-field-text-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import { observable } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";

export type CustomFieldTextEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<string | null>;
} & EditorElementParams<string | null>;

export class CustomFieldTextEditorElement extends ViewModel {
   readonly invalidInputMessage: CustomFieldTextEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldTextEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldTextEditorElementParams["onChange"];
   readonly title: CustomFieldTextEditorElementParams["title"];
   readonly value: CustomFieldTextEditorElementParams["value"];

   readonly textFieldMediator = pureComputed<string | null>({
      read: () => this.value(),
      write: (value) => {
         this.onChange(value);
      },
   });

   private readonly textFieldParams: TextFieldParams;

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldTextEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;

      this.textFieldParams = {
         isClearable: observable(isDisabled != true),
         isDisabled,
         value: this.textFieldMediator,
      };
   }

   static factory(
      params: CustomFieldTextEditorElementParams,
   ): ComponentArgs<CustomFieldTextEditorElementParams> {
      return {
         name: "custom-field-text-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-text-editor-element", {
   viewModel: CustomFieldTextEditorElement,
   template: template(),
   synchronous: true,
});

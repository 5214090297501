import type { Dispatch, SetStateAction } from "react";
import type { ganttFilterType } from "../prop-types";
import { ganttFilterTypeEnums } from "../prop-types";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import { dateClassifiers } from "@/react/shared/constants";

export const getPreSelected = (
   ganttFilter: ganttFilterType,
   filterId: string,
   mapFn: (item: any) => any,
) => {
   return ganttFilter[ganttFilterTypeEnums.customFieldsFilters]?.[filterId]?.flatMap(mapFn) || [];
};

export const setClassifierFromValue = (
   classifiers: any[],
   classifierValue: string,
   I18nObject: any,
) => {
   for (const c of classifiers) {
      if (c.value === classifierValue) {
         const optionSelected = { ...c };
         optionSelected.label = I18nObject.t(optionSelected.label);
         return optionSelected; // Option is returned
      }
   }
   return null;
};

// Function to check for duplicates
export const isDuplicate = (currentFilters: any, newFilter: any, filter: any) => {
   return (currentFilters[filter.id] || []).some(
      (existingFilter: any) =>
         existingFilter.property === newFilter.property &&
         existingFilter.type === newFilter.type &&
         existingFilter.value === newFilter.value &&
         existingFilter.customFieldId === newFilter.customFieldId,
   );
};

export const updateGanttFilter = (
   updatedFilter: any,
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>,
) => {
   setGanttFilter((ganttFilter) => ({
      ...ganttFilter,
      [ganttFilterTypeEnums.customFieldsFilters]: updatedFilter,
   }));
};

export const deleteFilter = (
   currentFilters: any,
   filter: any,
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>,
) => {
   const { [filter.id]: deletedFilter, ...restFilters } = currentFilters;
   updateGanttFilter(restFilters, setGanttFilter);
};

export const getQualifiersLabel = (label: string | number | null, type: string): string | null => {
   if (type === CustomFieldType.DATE) {
      return dateClassifiers.find((qualifier) => qualifier.value === label)?.label ?? null;
   }
   return null;
};

import React from "react";
import classnames from "classnames";
import styles from "../project/project-detail-cards/project-details.scss";
const cx = classnames.bind(styles);
import { Box, FlexList, H2, EmptyState } from "@procore/core-react";

const EmptyActivities: React.FC<{
   title: string;
   emptyTitle: string;
   emptyDescription: string;
}> = ({ title, emptyTitle, emptyDescription }) => {
   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>{title}</H2>
            </FlexList>
         </Box>
         <Box padding="md">
            <EmptyState compact>
               <EmptyState.NoItemsWithoutCreatePermissions />
               <EmptyState.Title>{emptyTitle}</EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {emptyDescription}
               </EmptyState.Description>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

export default EmptyActivities;

import "./gantt-pane.styl"
import template from "./gantt-pane.pug"
import { GanttPaneCore } from "./gantt-pane.core"
import { DateUtils } from "@/lib/utils/date"
import * as BrowserStorageUtils from "@/lib/utils/browser-storage"
import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { mutateForTimezone } from "@/lib/utils/date-2"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { Assignment2Store } from "@/stores/assignment-2-store.core"
import { PersonStore } from "@/stores/person-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { RequestStore } from "@/stores/request-store.core";
import { StatusStore } from "@/stores/status-store.core";
import { LegacyStore } from "@/stores/legacy-store.core";
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Mediators ###
import { GanttMediator } from "@/lib/mediators/gantt-mediator"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { CalendarPane } from "@/lib/components/popup/calendar-pane"

### Models ###
import { Assignment } from "@/models/assignment"
import { PermissionLevel } from "@/models/permission-level"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import ko from "knockout"

{ WeekDayFormat, MonthFormat, YearFormat, DayFormat } = DateUtils
DATE_FORMAT_OPTIONS = {
   weekdayFormat: WeekDayFormat.ABBREV
   monthFormat: MonthFormat.FULL
   dayFormat: DayFormat.ONE_DIGIT
   yearFormat: YearFormat.FULL
}

export class GanttPane extends ModalPane
   constructor: (resourceId, startDay, editingAssignment, supportData, costingData, fillingRequestId) ->
      # assertArgs(arguments, [Function, String], Boolean)
      super("Assignment Details", null, template())

      @core = new GanttPaneCore({
         resourceId,
         supportData,
      })

      @canManageAssignments = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ASSIGNMENTS)
      @canManageAlerts = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)

      @isAdmin = authManager.isAdmin()

      @actionText("New") if @canManageAssignments

      @costingData = ko.observable(costingData)
      @fillingRequestId = fillingRequestId
      @currentProjectHasStartDate = ko.observable(false)

      @resourceId = resourceId
      @core.state.selectedResource.subscribe (newVal) =>
         proceedWithReload = =>
            @showProjectTray(false)
            @showRangeTray(false)
            @showDetailTray(false)
            @showBlockedTray(false)
            @resourceId = if newVal? then newVal.value() else null
            @loadAssignmenments()
         if @editingAssignment?
            @cancelChanges proceedWithReload
         else
            proceedWithReload()
      
      @supportData = supportData

      @originalAssignment = null
      @editingAssignment = editingAssignment

      @editingBatch = ko.observable(false)
      @editingExisting = false

      @deletedAssignments = ko.observableArray([])
      @savedBatchIds = ko.observableArray([])

      @processingExistingAssignmentVals = ko.observable(false)

      @barSplitOptions = [
         new SegmentedControllerItem("Solid Bars", "solid")
         new SegmentedControllerItem("Working Day Splits", "split")
      ]
      @selectedBarSplit = ko.observable()
      savedBarSplit = BrowserStorageUtils.getBasicValue(BrowserStorageUtils.BrowserLocalStorageKey.ADM_BAR_SPLIT)
      if savedBarSplit?
         for option in @barSplitOptions
            if option? and option.value() == savedBarSplit
               @selectedBarSplit(option)
               break
      else
         @selectedBarSplit(@barSplitOptions[0])
      @selectedBarSplit.subscribe (newVal) =>
         BrowserStorageUtils.storeBasicValue(BrowserStorageUtils.BrowserLocalStorageKey.ADM_BAR_SPLIT, if newVal? then newVal.value() else null)
         @handleRedraw_()

      @isSaving = ko.observable(false)
      @canSave = ko.pureComputed =>
         return false if @isSaving()
         if @editingExisting
            return (
               (
                  @core.state.selectedProject()? and
                  @core.state.selectedProject().id != @editingAssignment.projectId
               ) or
               (
                  @core.state.selectedCostCode()? and
                  @core.state.selectedCostCode().value() != @editingAssignment.costCodeId
               ) or
               (
                  @core.state.selectedLabel()? and
                  @core.state.selectedLabel().value() != @editingAssignment.labelId
               ) or
               @hasStartDateChanged() or
               @hasEndDateChanged() or
               (
                  @editingStartTime()? and
                  @editingStartTime() != @editingAssignment.startTime
               ) or
               (
                  @editingEndTime()? and
                  @editingEndTime() != @editingAssignment.endTime
               ) or
               (
                  @percentAllocated()? and
                  @percentAllocated() != @editingAssignment.percentAllocated
               ) or
               @haveWorkdaysChanged() or
               @hasSelectedStatusChanged() or
               @hasOvertimeChanged()
            )
         else
            return (
               @core.state.selectedProject()? and
               @editingStartDate()? and
               @editingEndDate()? and
               (
                  @sundayOn() or
                  @mondayOn() or
                  @tuesdayOn() or
                  @wednesdayOn() or
                  @thursdayOn() or
                  @fridayOn() or
                  @saturdayOn()
               ) and
               (
                  (
                     @editingStartTime()? and
                     @editingEndTime()?
                  ) or
                  @percentAllocated()?
               )
            )

      @hasStartDateChanged = ko.pureComputed =>
         return @editingStartDate()? and
            (
               (
                  @editingBatch() and
                  DateUtils.getDetachedDay(@editingStartDate()) != @editingAssignment.batchStartDay
               ) or
               (
                  !@editingBatch() and
                  DateUtils.getDetachedDay(@editingStartDate()) != @editingAssignment.startDay
               )
            )

      @hasEndDateChanged = ko.pureComputed =>
         return @editingEndDate()? and
            (
               (
                  @editingBatch() and
                  DateUtils.getDetachedDay(@editingEndDate()) != @editingAssignment.batchEndDay
               ) or
               (
                  !@editingBatch() and
                  DateUtils.getDetachedDay(@editingEndDate()) != @editingAssignment.endDay
               )
            )

      @haveWorkdaysChanged = ko.pureComputed =>
         return (
               @sundayOn() or
               @mondayOn() or
               @tuesdayOn() or
               @wednesdayOn() or
               @thursdayOn() or
               @fridayOn() or
               @saturdayOn()
            ) and
            (
               @sundayOn() != @editingAssignment.workDays?[0] or
               @mondayOn() != @editingAssignment.workDays?[1] or
               @tuesdayOn() != @editingAssignment.workDays?[2] or
               @wednesdayOn() != @editingAssignment.workDays?[3] or
               @thursdayOn() != @editingAssignment.workDays?[4] or
               @fridayOn() != @editingAssignment.workDays?[5] or
               @saturdayOn() != @editingAssignment.workDays?[6]
            )

      @hasSelectedStatusChanged = ko.pureComputed =>
         statusId = ko.unwrap(@editingAssignment.statusId)
         return (
            Boolean(@core.state.selectedStatus()?) != Boolean(statusId)
         ) or
         (
            @core.state.selectedStatus()? and
            @core.state.selectedStatus().value() != statusId
         )

      @ganttMediator = new GanttMediator () =>
         setTimeout => @ganttMediator.redraw([], @defaultRangeData)

      @originalRows = ko.observableArray()
      @rows = ko.observableArray([])

      @showingGantt = ko.observable(true)

      rawToday = DateUtils.getAttachedDate(startDay)
      @originalBoardsDays = startDay
      sanitizedToday = new Date(rawToday.getFullYear(), rawToday.getMonth(), rawToday.getDate())
      @disposableViewStartBlock = false
      @viewStartDate = ko.observable(sanitizedToday)
      @viewStartDate.subscribe(@handleViewingDateChanged_)

      @viewStartDay = ko.pureComputed =>
         return DateUtils.getDetachedDay(@viewStartDate())

      @selectedRangeDays = ko.observable(28)
      @selectedRangeDays.subscribe(@handleViewingRangeChanged_)

      @loadingAssignments = ko.observable(false)

      @editingTotalString = ko.observable(null)
      @editingOccurenceString = ko.observable(null)

      @editingSingleOccurenceBatch = ko.observable(false)

      @showRangeTray = ko.observable(false)
      @showDetailTray = ko.observable(false)
      @showProjectTray = ko.observable(false)
      @showBlockedTray = ko.observable(false)

      @showingDeleteButtons = ko.observable(false)

      @barHoveringData = ko.observable(null)
      @hoverBarString = ko.pureComputed =>
         return '' unless @barHoveringData()?
         data = @barHoveringData()

         if data.projectName
            info = "<span class='gantt-pane__hover-bar__label'>Project:</span> #{data.projectName}"
            if data.costCodeName?
               info += " - #{data.costCodeName}"
            if data.labelName?
               info += " - #{data.labelName}"

            formattedStartDate = DateUtils.formatDetachedDay(data.batchStartDay, defaultStore.getDateFormat())
            formattedEndDate = DateUtils.formatDetachedDay(data.batchEndDay, defaultStore.getDateFormat())

         else
            info = data.reason
            info = "<span class='gantt-pane__hover-bar__label'>Time Off</span> - #{info.charAt(0).toUpperCase() + info.slice(1,info.length)}"
            formattedStartDate = DateUtils.formatDetachedDay(data.startDay, defaultStore.getDateFormat())
            formattedEndDate = DateUtils.formatDetachedDay(data.endDay, defaultStore.getDateFormat())
         
         info += "<span class='gantt-pane__hover-bar__label'>Range:</span> #{formattedStartDate} - #{formattedEndDate}"
         if data.percentAllocated?
            info += "<span class='gantt-pane__hover-bar__label'>Allocation:</span> #{data.percentAllocated}%"
         else if data.startTime? and data.endTime?
            startTime = DateUtils.formatTimeVal(data.startTime)
            endTime = DateUtils.formatTimeVal(data.endTime)
            info += "<span class='gantt-pane__hover-bar__label'>Times:</span> #{startTime} - #{endTime}"

         return info

      @isEditing = ko.pureComputed =>
         return @showRangeTray() or @showDetailTray() or @showProjectTray()

      @defaultRangeData = {
         startDate: @viewStartDate()
         days: @selectedRangeDays()
         disableInfoSecion: true
         graphBaseId: "gantt-modal"
         barSplit: if @selectedBarSplit()? then @selectedBarSplit().value() else null
      }

      @callbacks = {
         personClicked: () ->
         assignmentClicked: @handleAssignmentClick
         assignmentMouseOver: @handleAssignmentMouseOver
         assignmentMouseOut: @handleAssignmentMouseOut
      }

      # Editing Values
      @editingStartDateDisposableBlock = false
      @editingStartDate = ko.observable()
      @editingStartDate.subscribe (newVal) =>
         return @editingStartDateDisposableBlock = false if @editingStartDateDisposableBlock

         if newVal > @editingEndDate()
            @editingEndDateDisposableBlock = true
            @editingEndDate(newVal)
            @selectedEndType(@endTypeOptions()[0])

         if newVal? and @selectedEndType()? and @selectedEndType().value() == "tbd"
            tbdWeeks = ko.unwrap(supportData.companyTbdWeeks)
            calculatedDate = DateUtils.incrementDate(newVal, (tbdWeeks * 7))
            @editingEndDateDisposableBlock = true
            @editingEndDate(calculatedDate)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)  
            @tbdEndDateString("#{calculatedDateString} - #{tbdWeeks} Weeks")

         @handleChangesforRedraw() if newVal? and @editingEndDate()?

      @projectStartDateString = ko.observable()
      @startTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Date", "date")
         new SegmentedControllerItem("Project Start", "project-start")
      ])
      @selectedStartType = ko.observable(@startTypeOptions()[0])
      @selectedStartType.subscribe (newVal) =>
         return unless newVal?.value() == "project-start"
         
         if @core.state.selectedProject()?.baggage()?.start_date?
            calculatedDate = new Date(@core.state.selectedProject().baggage().start_date)
            mutateForTimezone(calculatedDate)

            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
            @editingStartDate(calculatedDate)
            @projectStartDateString(calculatedDateString)

      @editingEndDateDisposableBlock = false
      @editingEndDate = ko.observable()
      @editingEndDate.subscribe (newVal) =>
         return @editingEndDateDisposableBlock = false if @editingEndDateDisposableBlock

         if newVal < @editingStartDate()
            # Change the start date to be equal to end the date Make sure to change
            # back to 'date' type if the end date is before the project start.
            @editingStartDateDisposableBlock = true
            @editingStartDate(newVal)
            @selectedStartType(@startTypeOptions()[0])

         @handleChangesforRedraw() if newVal? and @editingStartDate()?              

      tbdEndOption = new SegmentedControllerItem("TBD", "tbd")
      projectEndOption = new SegmentedControllerItem("Project End", "project-end")
      @endTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Date", "date")
         new SegmentedControllerItem("Weeks", "weeks")   
         tbdEndOption
      ])
      @selectedEndType = ko.observable(@endTypeOptions()[0])
      @selectedEndType.subscribe (newVal) =>
         return unless newVal?
         if newVal.value() == "tbd" and @editingStartDate()?
            tbdWeeks = ko.unwrap(supportData.companyTbdWeeks)
            dayStep = if tbdWeeks >= 1 then (((tbdWeeks - 1) * 7) + 6) else (tbdWeeks * 7)
            dayStep = Math.round(dayStep)
            calculatedDate = DateUtils.incrementDate(@editingStartDate(), dayStep)
            @editingEndDate(calculatedDate)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)  
            @tbdEndDateString("#{calculatedDateString} - #{tbdWeeks} Weeks")
         else if newVal.value() == "project-end" and @core.state.selectedProject()?.baggage()?.est_end_date?
            calculatedDate = new Date(@core.state.selectedProject().baggage().est_end_date)
            mutateForTimezone(calculatedDate)

            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)  
            @editingEndDate(calculatedDate)

      @numberOfWeeks = ko.observable(null)
      @weeksCalculatedDateString = ko.pureComputed =>
         return "Enter # of weeks" unless @numberOfWeeks()? and Number(@numberOfWeeks()) > 0
         return "Select Start Date" unless @editingStartDate()?
         weeks = Number(@numberOfWeeks())
         dayStep = if weeks >= 1 then (((weeks - 1) * 7) + 6) else (weeks * 7)
         dayStep = Math.round(dayStep)
         
         calculatedDate = DateUtils.incrementDate(ko.unwrap(@editingStartDate), dayStep)
         # Run it back to the last selected work day
         lastActiveWorkDay = @getLastSelectedWorkDayVal_()
         if lastActiveWorkDay?
            calculatedDay = calculatedDate.getDay()
            foundLastActiveWeekDay = false
            unless @checkWorkDayValSelection_(calculatedDay)
               while !foundLastActiveWeekDay
                  if calculatedDay == 0
                     calculatedDay = 6
                  else
                     calculatedDay--
                  foundLastActiveWeekDay = @checkWorkDayValSelection_(calculatedDay)

            delta = 0
            
            if calculatedDay <= calculatedDate.getDay()
               delta = calculatedDate.getDay() - calculatedDay
            else
               delta = (7 + calculatedDate.getDay()) - calculatedDay

            calculatedDate = new Date(calculatedDate.setDate(calculatedDate.getDate() - delta))
            @editingEndDate(calculatedDate)
            return DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), {
               weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
               dayFormat: DateUtils.DayFormat.ONE_DIGIT,
               monthFormat: DateUtils.MonthFormat.ABBREV,
               yearFormat: DateUtils.YearFormat.FULL,
            })
         else
            return "..."
      @tbdEndDateString = ko.observable("Select Start Date...")

      @sundayOtRate = ko.observable()
      @mondayOtRate = ko.observable()
      @tuesdayOtRate = ko.observable()
      @wednesdayOtRate = ko.observable()
      @thursdayOtRate = ko.observable()
      @fridayOtRate = ko.observable()
      @saturdayOtRate = ko.observable()

      @hasOvertimeChanged = ko.pureComputed =>
         return (
            @overtime() != @editingAssignment.overtime or
            (
               @overtime() and
               (
                  @haveAnyOvertimeRatesChanged() or
                  @hasOvertimePaySplitChanged()
               )
            )
         )

      @haveAnyOvertimeRatesChanged = ko.pureComputed =>
         return (
            @hasOvertimeRateChanged(@sundayOtRate(), @editingAssignment.overtimeRates?['0']) or
            @hasOvertimeRateChanged(@mondayOtRate(), @editingAssignment.overtimeRates?['1']) or
            @hasOvertimeRateChanged(@tuesdayOtRate(), @editingAssignment.overtimeRates?['2']) or
            @hasOvertimeRateChanged(@wednesdayOtRate(), @editingAssignment.overtimeRates?['3']) or
            @hasOvertimeRateChanged(@thursdayOtRate(), @editingAssignment.overtimeRates?['4']) or
            @hasOvertimeRateChanged(@fridayOtRate(), @editingAssignment.overtimeRates?['5']) or
            @hasOvertimeRateChanged(@saturdayOtRate(), @editingAssignment.overtimeRates?['6'])
         )

      @hasOvertimePaySplitChanged = ko.pureComputed =>
         if @editingAssignment.overtime and !@editingAssignment.paySplit
            console.error("overtime assignment does not have a pay-split:", @editingAssignment)
            Bugsnag.notify("overtime assignment does not have a pay-split", (event) =>
               event['context'] = "gantt-pane.coffee__hasOvertimePaySplitChanged"
               event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
               event.addMetadata("Assignment", @editingAssignment)
            )
            return false
         return (
            !@paySplitInvalid() and
            (
               @editingAssignment.paySplit.straight != Number(@straightHours()) or
               @editingAssignment.paySplit.overtime != Number(@overtimeHours()) or
               @editingAssignment.paySplit.unpaid != Number(@unpaidHours())
            )
         )

      overtimeDayRates = ko.unwrap(costingData?.overtimeDayRates)
      if overtimeDayRates?
         @sundayOtRate(overtimeDayRates.sunday)
         @mondayOtRate(overtimeDayRates.monday)
         @tuesdayOtRate(overtimeDayRates.tuesday)
         @wednesdayOtRate(overtimeDayRates.wednesday)
         @thursdayOtRate(overtimeDayRates.thursday)
         @fridayOtRate(overtimeDayRates.friday)
         @saturdayOtRate(overtimeDayRates.saturday)

      @sundayOn = ko.observable(false)
      @sundayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @mondayOn = ko.observable(true)
      @mondayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @tuesdayOn = ko.observable(true)
      @tuesdayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @wednesdayOn = ko.observable(true)
      @wednesdayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @thursdayOn = ko.observable(true)
      @thursdayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @fridayOn = ko.observable(true)
      @fridayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @saturdayOn = ko.observable(false)
      @saturdayOn.subscribe => 
         @handleChangesforRedraw() if @editingAssignment? and !@processingExistingAssignmentVals()

      @editingStartTime = ko.observable()
      @editingEndTime = ko.observable()

      @assignmentHourDuration = ko.pureComputed =>
         return '' unless (@allocationIsHours() and 
         @editingStartTime()? and @editingEndTime()?)

         startTime = @editingStartTime()
         endTime = @editingEndTime()
         if startTime < endTime
            return endTime - startTime
         else if startTime > endTime
            # Overnight
            return endTime + (24 - startTime)
         else
            return ''

      @allocationOptions = [
         new SegmentedControllerItem("Work Hours", "hours")
         new SegmentedControllerItem("Percent", "percent")
      ]
      @selectedAllocationOption = ko.observable(@allocationOptions[0])
      @selectedAllocationOption.subscribe (newVal) =>
         if newVal? and newVal.value() == "percent"
            @overtime(false)

      @allocationIsHours = ko.pureComputed =>
         return if @selectedAllocationOption()? then @selectedAllocationOption().value() == "hours" else false

      @percentAllocated = ko.observable()

      @core.state.selectedProject.subscribe (newVal) =>
         return unless newVal?
         @handleChangesforRedraw() unless @editingAssignment.projectId?
         @showDetailTray(true) if @showProjectTray()
         @core.state.selectedCostCode(null)
         @editingStartTime(newVal.baggage().daily_start_time) if newVal.baggage()?.daily_start_time?
         @editingEndTime(newVal.baggage().daily_end_time) if newVal.baggage()?.daily_end_time?

         if newVal.baggage()?.start_date?
            @currentProjectHasStartDate(true)
         else
            @currentProjectHasStartDate(false)

         if newVal.baggage()?.est_end_date?
            endOptions = @endTypeOptions()
            endOptions[2] = projectEndOption
            @endTypeOptions(endOptions)
            if @selectedEndType()?.value() == "tbd"
               @selectedEndType(projectEndOption)

            prjEndDate = new Date(newVal.baggage().est_end_date)
            mutateForTimezone(prjEndDate)

            @tbdEndDateString(DateUtils.formatDate(prjEndDate), defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
         else
            endOptions = @endTypeOptions()
            endOptions[2] = tbdEndOption
            @endTypeOptions(endOptions)
            if @selectedEndType()?.value() == "project-end"
               @selectedEndType(tbdEndOption)
            tbdWeeks = ko.unwrap(supportData.companyTbdWeeks)
            if @editingStartDate()?
               calculatedDate = DateUtils.incrementDate(@editingStartDate(), (tbdWeeks * 7))
               calculatedDate = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)  
               @tbdEndDateString("#{calculatedDate} - #{tbdWeeks} Weeks")
            else
               @tbdEndDateString("#{tbdWeeks} Weeks Out")

      @showingCostCode = ko.observable(false)
      @showingLabel = ko.observable(false)

      @rangeTitle = ko.pureComputed =>
         return switch @selectedRangeDays()
            when 1 then "1 Day"
            when 7 then "1 Week"
            when 14 then "2 Weeks"
            when 28 then "4 Weeks"
            when 56 then "8 Weeks"
            when 91 then "13 Weeks"

      
      @allowOvertimeControls = ko.pureComputed =>
         return ko.unwrap(@costingData()?.overtimeDayRates)?

      @straightHours = ko.observable()
      @overtimeHours = ko.observable()
      @unpaidHours = ko.observable()

      @paySplitInvalid = ko.pureComputed =>
         accountedHours = Number(@straightHours()) + Number(@overtimeHours()) + Number(@unpaidHours())
         if @editingStartTime() < @editingEndTime()
            hoursDuration = @editingEndTime() - @editingStartTime()
         else
            hoursDuration = @editingEndTime() + (24 - @editingStartTime())

         return !(accountedHours == hoursDuration)
      
      @overtime = ko.observable(false)
      @overtime.subscribe () =>
         # TODO: Make sure this doesn't override actuals from editing. 
         @calculatePaySplit()

      # Popups
      @rangePopupBuilder = =>
         handleRangeSelection = (days) =>
            @selectedRangeDays(days)

         items = [
            new PopupListItem({title: "1 Day", clickCallback: handleRangeSelection, callbackData: 1})
            new PopupListItem({title: "1 Week", clickCallback: handleRangeSelection, callbackData: 7})
            new PopupListItem({title: "2 Weeks", clickCallback: handleRangeSelection, callbackData: 14})
            new PopupListItem({title: "4 Weeks", clickCallback: handleRangeSelection, callbackData: 28})
            new PopupListItem({title: "8 Weeks", clickCallback: handleRangeSelection, callbackData: 56})
            new PopupListItem({title: "13 Weeks", clickCallback: handleRangeSelection, callbackData: 91})
         ]
         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new PopupListPane("", items, {showArrows: false})],
            ['gantt-toolbar__range-selector', 'gantt-toolbar__range-title'], ["gantt-chart__popup--range"])

      @rangePopupWrapper = {
         popupBuilder: @rangePopupBuilder
         options: {triggerClasses: ['gantt-toolbar__range-selector']}
      }

      # Assignment Calendar
      @assignmentsCalendarPopupBuilder = =>
         return new Popup("Select Date", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new CalendarPane(@viewStartDate)], ['gantt-pane__toolbar__btn', 'icon-calendar'],
            ['gantt-pane__toolbar__date-popup'])

      @assignmentsCalendarPopupWrapper = ko.observable({
         popupBuilder: @assignmentsCalendarPopupBuilder,
         options: {triggerClasses: ['icon-calendar']}
      })

      if @editingAssignment?
         @processExistingAssignment =>
            @showProjectTray(true)
            @showDetailTray(true)
            @showBlockedTray(false)

      @loadAssignmenments()

   afterInitialize: =>
      @data.set('deletedAssignments', @deletedAssignments)
      @data.set('savedBatchIds', @savedBatchIds)

   calculatePaySplit: =>
      return unless @overtime() and @editingStartTime()? and @editingEndTime()?
      if @editingStartTime() < @editingEndTime()
         hoursDuration = @editingEndTime() - @editingStartTime()
      else
         hoursDuration = @editingEndTime() + (24 - @editingStartTime())

      if hoursDuration <= (ko.unwrap(@costingData().paidShiftHours) + .5)
         @overtimeHours(0)
         if hoursDuration <= ko.unwrap(@costingData().paidShiftHours)
            @straightHours(hoursDuration)
            @unpaidHours(0)
         else
            @straightHours(ko.unwrap(@costingData().paidShiftHours))
            @unpaidHours(hoursDuration - ko.unwrap(@costingData().paidShiftHours))
      else
         @straightHours(ko.unwrap(@costingData().paidShiftHours))
         @unpaidHours(.5)
         @overtimeHours(hoursDuration - (ko.unwrap(@costingData().paidShiftHours) + .5))

   actionBtnIntercept: () =>
      return unless @canManageAssignments
      showNew_ = =>
         startDay = DateUtils.getDetachedDay(@viewStartDate())
         @editingAssignment = {
            startDay: startDay
            endDay: startDay
            batchStartDay: startDay
            batchEndDay: startDay
            isPending: true
            workDays: {
               0: false
               1: true
               2: true
               3: true
               4: true
               5: true
               6: false
            }
         }
         @editingStartDateDisposableBlock = true
         @editingStartDate(DateUtils.getAttachedDate(startDay))
         @editingEndDateDisposableBlock = true
         @editingEndDate(DateUtils.getAttachedDate(startDay))
         @rows()[0].assignments.push(@editingAssignment)
         @showBlockedTray(false)
         @showProjectTray(true)

      if @editingAssignment?
         @cancelChanges showNew_
      else
         showNew_()

   handleAssignmentMouseOver: (assignment) =>
      return if assignment.isPending
      @barHoveringData(assignment)

   handleAssignmentMouseOut: =>
      @barHoveringData(null)

   getLastSelectedWorkDayVal_: ->
      return 6 if @saturdayOn()
      return 5 if @fridayOn()
      return 4 if @thursdayOn()
      return 3 if @wednesdayOn()
      return 2 if @tuesdayOn()
      return 1 if @mondayOn()
      return 0 if @sundayOn()
      return null

   checkWorkDayValSelection_: (val) ->
      switch val
         when 0 then @sundayOn()
         when 1 then @mondayOn()
         when 2 then @tuesdayOn()
         when 3 then @wednesdayOn()
         when 4 then @thursdayOn()
         when 5 then @fridayOn()
         when 6 then @saturdayOn()

   showDeleteButtons: ->
      @showingDeleteButtons(true)

   handleChangesforRedraw: ->
      return unless @rows()?[0]?
      if @editingBatch()
         # Remove other occurences of batch
         workingBatchId = @editingAssignment.batchId
         @rows()[0].assignments = @rows()[0].assignments.filter (ass) ->
            return if ass.isPending then false else ass.batchId != workingBatchId
      else
         # Remove the old copy of this occurence
         @rows()[0].assignments = @rows()[0].assignments.filter (ass) ->
            return !ass.isPending

      instanceStartDay = DateUtils.getDetachedDay(@editingStartDate())
      instanceEndDay = DateUtils.getDetachedDay(@editingEndDate())
      newOccurence = {
         batchStartDay: instanceStartDay
         batchEndDay: instanceEndDay
         projectId: @core.state.selectedProject().id
         costCodeId: if @core.state.selectedCostCode()? then @core.state.selectedCostCode().value() else null
         labelId: if @core.state.selectedLabel()? then @core.state.selectedLabel().value() else null
         startDay: instanceStartDay
         endDay: instanceEndDay
         workDays: {
            0: @sundayOn()
            1: @mondayOn()
            2: @tuesdayOn()
            3: @wednesdayOn()
            4: @thursdayOn()
            5: @fridayOn()
            6: @saturdayOn()
         }
         statusId: if @core.state.selectedStatus()? then @core.state.selectedStatus().value() else ko.unwrap(@editingAssignment.statusId)
         overtime: @overtime()
         batchId: @editingAssignment.batchId
         originalStartDay: @editingAssignment.startDay
         originalEndDay: @editingAssignment.endDay
         isPending: true

         # Not Needed in Pending
         projectName: @editingAssignment.projectName
         color: @editingAssignment.color
         costCodeName: @editingAssignment.costCodeName
         labelName: @editingAssignment.labelName
      }

      if @allocationIsHours()
         newOccurence['startTime'] = @editingStartTime()
         newOccurence['endTime'] = @editingEndTime()
         newOccurence['percentAllocated'] = null
      else
         newOccurence['percentAllocated'] = @percentAllocated()
         newOccurence['startTime'] = 0
         newOccurence['endTime'] = 23.99

      # Add to existing data
      @rows()[0].assignments.push(newOccurence)

      @processRows @rows(), =>
         @handleRedraw_()

   closeRangeTray: ->
      @editingAssignment = null
      @originalAssignment = null
      @editingExisting = false

      @showRangeTray(false)
      @showProjectTray(false)
      @showDetailTray(false)
      @showBlockedTray(false)

      @core.state.selectedCostCode(null)
      @editingStartDate(null)
      @editingEndDate(null)
      @editingStartTime(null)
      @editingEndTime(null)
      @sundayOn(false)
      @mondayOn(true)
      @tuesdayOn(true)
      @wednesdayOn(true)
      @thursdayOn(true)
      @fridayOn(true)
      @saturdayOn(false)
      @selectedEndType(@endTypeOptions()[0])

   handleCancelClick: =>
      # We need this so that KO doesn't pass a value into the actual method.
      @cancelChanges()

   cancelChanges: (next) ->
      # Catches assignments brought in from a drag-drop
      if @editingAssignment.isPending and @originalRows().length
         @originalRows()[0].assignments = @originalRows()[0].assignments.filter (item) ->
            return !item.isPending

      # Remove the old copy of this occurence
      @originalRows()[0].assignments = @originalRows()[0].assignments.filter (ass) ->
         return !ass.isPending

      @editingAssignment = null
      @originalAssignment = null
      @editingExisting = false

      @showRangeTray(false)
      @showProjectTray(false)
      @showDetailTray(false)
      @showBlockedTray(false)

      # Decouple and store original to revert to.
      oldOriginal = @originalRows()
      @originalRows oldOriginal.map (item) ->
         newRow = Object.assign({}, item)
         newRow.assignments = newRow.assignments.map (ass) ->
            return Object.assign({}, ass)
         return newRow
      @processRows oldOriginal, =>
         @handleRedraw_()
         setTimeout -> next() if next

      @core.state.selectedProject(null)
      @core.state.selectedCostCode(null)
      @core.state.selectedStatus(null)
      @editingStartDate(null)
      @editingEndDate(null)
      @editingStartTime(null)
      @editingEndTime(null)
      @sundayOn(false)
      @mondayOn(true)
      @tuesdayOn(true)
      @wednesdayOn(true)
      @thursdayOn(true)
      @fridayOn(true)
      @saturdayOn(false)
      @selectedEndType(@endTypeOptions()[0])

   handleAssignmentClick: (data) =>
      return unless @canManageAssignments
      return if data.isPending

      # Decoupling to display gantt bar for percent allocated assignments
      assignmentData = Object.assign({}, data)
      if data.percentAllocated?
         assignmentData['startTime'] = null
         assignmentData['endTime'] = null

      selectAssignment = =>
         @editingExisting = true
         @originalAssignment = Object.assign({}, data)
         @editingAssignment = assignmentData
         # @editingAssignment['isPending'] = true
         @showProjectTray(false)
         @showDetailTray(false)
         @showBlockedTray(false)
         options = {
            yearFormat: DateUtils.YearFormat.SHORT
            monthFormat: DateUtils.MonthFormat.ONE_DIGIT
            dayFormat: DateUtils.DayFormat.ONE_DIGIT
            weekdayFormat: DateUtils.WeekDayFormat.ABBREV
         }
         if data.batchStartDay?
            formattedBatchStart = DateUtils.formatDetachedDay(data.batchStartDay, defaultStore.getDateFormat(), options)
            formattedBatchEnd = DateUtils.formatDetachedDay(data.batchEndDay, defaultStore.getDateFormat(), options)
            @editingTotalString("#{formattedBatchStart} - #{formattedBatchEnd}")

         formattedStart = DateUtils.formatDetachedDay(data.startDay, defaultStore.getDateFormat(), options)
         formattedEnd = DateUtils.formatDetachedDay(data.endDay, defaultStore.getDateFormat(), options)

         @editingSingleOccurenceBatch(formattedBatchStart == formattedStart && formattedBatchEnd == formattedEnd)
         @editingOccurenceString("#{formattedStart} - #{formattedEnd}")

         @showRangeTray(true)

      continueWithSelection = =>
         if @editingAssignment?
            @cancelChanges selectAssignment
         else
            selectAssignment()

      if @isAdmin
         continueWithSelection()
      else
         foundId = false
         if authManager.usingTypedGroups()
            usersGroupIds = authManager.authedUser().accessGroupIds()
         else
            usersGroupIds = authManager.authedUser().groupIds()
         for id in data.groupIds
            if usersGroupIds.indexOf(id) != -1
               continueWithSelection()
               foundId = true
               break
         # If the resource is an Admin, they are available to all Groups.
         if data.groupIds.length == 0
            continueWithSelection()
            foundId = true
         unless foundId
            @showRangeTray(false)
            @showProjectTray(false)
            @showDetailTray(false)
            @showBlockedTray(true)

   processExistingAssignment: (next) ->
      @processingExistingAssignmentVals(true)
      if @editingAssignment.statusId?
         for option in ko.unwrap(@core.state.statusOptions)
            if option? and option.value() == @editingAssignment.statusId
               @core.state.selectedStatus(option)
               break

      for option in @core.state.activeProjectOptions()
         if option? and option.id == @editingAssignment.projectId
            @core.state.selectedProject(option)
            break

      if @editingAssignment.costCodeId?
         for option in @core.state.filteredCostCodeOptions()
            if option? and option.value() == @editingAssignment.costCodeId
               @showingCostCode(true)
               @core.state.selectedCostCode(option)
               break

      if @editingAssignment.labelId?
         for option in @core.state.filteredLabelOptions()
            if option? and option.value() == @editingAssignment.labelId
               @showingLabel(true)
               @core.state.selectedLabel(option)
               break

      @sundayOn(@editingAssignment.workDays[0])
      @mondayOn(@editingAssignment.workDays[1])
      @tuesdayOn(@editingAssignment.workDays[2])
      @wednesdayOn(@editingAssignment.workDays[3])
      @thursdayOn(@editingAssignment.workDays[4])
      @fridayOn(@editingAssignment.workDays[5])
      @saturdayOn(@editingAssignment.workDays[6])

      @editingStartDateDisposableBlock = true
      @editingEndDateDisposableBlock = true
      if @editingBatch()
         @editingStartDate(DateUtils.getAttachedDate(@editingAssignment.batchStartDay))
         @editingEndDate(DateUtils.getAttachedDate(@editingAssignment.batchEndDay))
      else
         @editingStartDate(DateUtils.getAttachedDate(@editingAssignment.startDay))
         @editingEndDate(DateUtils.getAttachedDate(@editingAssignment.endDay))
      @selectedEndType(@endTypeOptions()[0])

      if @editingAssignment.startTime? and @editingAssignment.endTime?
         @editingStartTime(@editingAssignment.startTime)
         @editingEndTime(@editingAssignment.endTime)
         @percentAllocated(null)
         @selectedAllocationOption(@allocationOptions[0])
      else if @editingAssignment.percentAllocated?
         @editingStartTime(null)
         @editingEndTime(null)
         @percentAllocated(@editingAssignment.percentAllocated)
         @selectedAllocationOption(@allocationOptions[1])

      @overtime(@editingAssignment.overtime)

      if @editingAssignment.overtimeRates?
         @sundayOtRate(@editingAssignment.overtimeRates['0'])
         @mondayOtRate(@editingAssignment.overtimeRates['1'])
         @tuesdayOtRate(@editingAssignment.overtimeRates['2'])
         @wednesdayOtRate(@editingAssignment.overtimeRates['3'])
         @thursdayOtRate(@editingAssignment.overtimeRates['4'])
         @fridayOtRate(@editingAssignment.overtimeRates['5'])
         @saturdayOtRate(@editingAssignment.overtimeRates['6'])

      if @editingAssignment.paySplit?
         @straightHours(@editingAssignment.paySplit.straight)
         @overtimeHours(@editingAssignment.paySplit.overtime)
         @unpaidHours(@editingAssignment.paySplit.unpaid)

      @processingExistingAssignmentVals(false)

      @handleChangesforRedraw() if @editingAssignment.fillingRequest

      next()

   toggleCostCodeVisibility: ->
      @showingCostCode(!@showingCostCode())

   toggleLabelVisibility: ->
      @showingLabel(!@showingLabel())

   editTotalAssignment: =>
      @editingBatch(true)
      # @editingAssignment.isPending == true
      @processExistingAssignment =>
         @editingTotalString(null)
         @editingOccurenceString(null)
         @showRangeTray(false)
         @showBlockedTray(false)
         @showProjectTray(true)
         @showDetailTray(true)
         @handleChangesforRedraw()

   editAssignmentOccurence: =>
      @editingBatch(false)
      @processExistingAssignment =>
         @editingTotalString(null)
         @editingOccurenceString(null)
         @showRangeTray(false)
         @showBlockedTray(false)
         @showProjectTray(true)
         @showDetailTray(true)
         @handleChangesforRedraw()

   handleViewingDateChanged_: () =>
      return @disposableViewStartBlock = false if @disposableViewStartBlock
      @loadAssignmenments()

   handleViewingRangeChanged_: () =>
      @loadAssignmenments()

   calculateLastWorkDay: (endDay, workDays) ->
      # Run it back to the last selected work day
      lastActiveWorkDay = null
      if workDays[6]
         lastActiveWorkDay = 6
      else if workDays[5]
         lastActiveWorkDay = 5
      else if workDays[4]
         lastActiveWorkDay = 4
      else if workDays[3]
         lastActiveWorkDay = 3
      else if workDays[2]
         lastActiveWorkDay = 2
      else if workDays[1]
         lastActiveWorkDay = 1
      else if workDays[0]
         lastActiveWorkDay = 0


      if lastActiveWorkDay?
         calculatedDate = DateUtils.getAttachedDate(endDay)
         calculatedDay = calculatedDate.getDay()
         foundLastActiveWeekDay = false
         unless workDays[calculatedDay]
            while !foundLastActiveWeekDay
               if calculatedDay == 0
                  calculatedDay = 6
               else
                  calculatedDay--
               foundLastActiveWeekDay = workDays[calculatedDay]

         delta = 0
         if calculatedDay <= calculatedDate.getDay()
            delta = calculatedDate.getDay() - calculatedDay
         else
            delta = (7 + calculatedDate.getDay()) - calculatedDay
         calculatedDate = new Date(calculatedDate.setDate(calculatedDate.getDate() - delta))

         return DateUtils.getDetachedDay(calculatedDate)
      else
         return null

   calculateFirstWorkDay: (startDay, workDays) ->
      # Run it forward to the last selected work day
      firstAciveWortkDay = null
      if workDays[0]
         firstAciveWortkDay = 0
      else if workDays[1]
         firstAciveWortkDay = 1
      else if workDays[2]
         firstAciveWortkDay = 2
      else if workDays[3]
         firstAciveWortkDay = 3
      else if workDays[4]
         firstAciveWortkDay = 4
      else if workDays[5]
         firstAciveWortkDay = 5
      else if workDays[6]
         firstAciveWortkDay = 6


      if firstAciveWortkDay?
         calculatedDate = DateUtils.getAttachedDate(startDay)
         calculatedDay = calculatedDate.getDay()
         foundLastActiveWeekDay = false
         unless workDays[calculatedDay]
            while !foundLastActiveWeekDay
               if calculatedDay == 6
                  calculatedDay = 0
               else
                  calculatedDay++
               foundLastActiveWeekDay = workDays[calculatedDay]

         delta = 0
         if calculatedDay <= calculatedDate.getDay()
            delta = (7 - calculatedDate.getDay()) + calculatedDay
         else
            delta = calculatedDay - calculatedDate.getDay()

         calculatedDate = new Date(calculatedDate.setDate(calculatedDate.getDate() + delta))

         calcDay = DateUtils.getDetachedDay(calculatedDate)
         return calcDay

      else
         return null

   handleRedraw_: ->
      rangeData = {
         startDate: @viewStartDate(), 
         days: @selectedRangeDays(), 
         disableInfoSecion: true
         graphBaseId: "gantt-modal"
         barSplit: if @selectedBarSplit()? then @selectedBarSplit().value() else null
      }

      # If viewing one day, we don't want to show an assignment if we're not on one of its work days. I tried doing this filter
      # elsewhere and it wasn't very dependable, so doing it here just before the redraw
      if @selectedRangeDays() == 1
         @rows(
            @rows().map((row) =>
               row.assignments = row.assignments.filter((item) => return item.workDays[rangeData.startDate.getDay()]);
               return row;
            )
         )

      @ganttMediator.redraw(@rows(), rangeData)

   constructAssignmentForGantt: (assignment) ->
      newAssignmentForGantt = {
         color: assignment.baggage().project_color
         startDay: assignment.startDay()
         start: DateUtils.getAttachedDate(assignment.startDay())
         endDay: assignment.endDay()
         end: DateUtils.getAttachedDate(assignment.endDay() + 1)
         batchStartDay: assignment.startDay() or null
         batchEndDay: assignment.endDay() or null
         singleDay: null
         groupIds: assignment.baggage().group_ids
         personGroupIds: assignment.baggage().person_group_ids
         personAssignableGroupIds: assignment.baggage().person_assignable_group_ids
         projectName: assignment.baggage().project_name
         jobNumber: assignment.baggage().job_number
         projectId: assignment.projectId()
         costCodeName: assignment.baggage().cost_code_name
         costCodeId: assignment.costCodeId()
         labelName: assignment.labelName
         labelId: assignment.labelId()
         startTime: assignment.startTime() or 0
         endTime: assignment.endTime() or 23.99
         percentAllocated: assignment.percentAllocated() or null
         workDays: assignment.workDays()
         resourceId: assignment.resourceId()
         instanceId: null
         batchId: assignment.id
         personName: assignment.baggage().person_name
         employeeNumber: assignment.baggage().employee_number
         status: assignment.status() or null
         statusId: assignment.statusId or null
         statusName: if assignment.status()? then assignment.status().name else null
      }
      return newAssignmentForGantt


   save: (notifying) ->
      return unless @canSave()

      activeWorkDays = {
         0: @sundayOn()
         1: @mondayOn()
         2: @tuesdayOn()
         3: @wednesdayOn()
         4: @thursdayOn()
         5: @fridayOn()
         6: @saturdayOn()
      }
      startWorkDay = @editingStartDate().getDay()
      endWorkDay = @editingEndDate().getDay()
      return @displayingNotice(GanttPane.Notice.START_DAY_NOT_ACTIVE) unless activeWorkDays[startWorkDay]
      return @displayingNotice(GanttPane.Notice.END_DAY_NOT_ACTIVE) unless activeWorkDays[endWorkDay]

      newBatch = {
         project_id: @core.state.selectedProject().id
         cost_code_id: if @core.state.selectedCostCode()? then @core.state.selectedCostCode().value() else null
         label_id: if @core.state.selectedLabel()? then @core.state.selectedLabel().value() else null
         resource_id: @resourceId
         start_day: DateUtils.getDetachedDay(@editingStartDate())
         end_day: DateUtils.getDetachedDay(@editingEndDate())
         work_days: {
            0: @sundayOn()
            1: @mondayOn()
            2: @tuesdayOn()
            3: @wednesdayOn()
            4: @thursdayOn()
            5: @fridayOn()
            6: @saturdayOn()
         }
         overtime: @overtime()
      }

      unless authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES) == true || @core.state.selectedStatus()?
         return @displayingNotice(GanttPane.Notice.STATUS_REQUIRED)
      newBatch['status_id'] = if @core.state.selectedStatus()? then @core.state.selectedStatus().value() else null

      if @allocationIsHours()
         newBatch['start_time'] = @editingStartTime()
         newBatch['end_time'] = @editingEndTime()
         newBatch['percent_allocated'] = null
      else
         newBatch['percent_allocated'] = @percentAllocated()
         newBatch['start_time'] = null
         newBatch['end_time'] = null

      if @overtime()
         newBatch['pay_split'] = {
            straight: Number(@straightHours())
            overtime: Number(@overtimeHours())
            unpaid: Number(@unpaidHours())
         }

         # Only save rates if different than defaults.
         if (Number(@sundayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).sunday or
         Number(@mondayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).monday or
         Number(@tuesdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).tuesday or
         Number(@wednesdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).wednesday or
         Number(@thursdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).thursday or
         Number(@fridayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).friday or
         Number(@saturdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).saturday)
            newBatch['overtime_rates'] = {
               "0": Number(@sundayOtRate())
               "1": Number(@mondayOtRate())
               "2": Number(@tuesdayOtRate())
               "3": Number(@wednesdayOtRate())
               "4": Number(@thursdayOtRate())
               "5": Number(@fridayOtRate())
               "6": Number(@saturdayOtRate())
            }

      rangeData = {
         startDay: @viewStartDay()
         endDay: if @selectedRangeDays() == 1 then @viewStartDay() else DateUtils.incrementDetachedDay(@viewStartDay(), @selectedRangeDays())
         cardDayFilter: @originalBoardsDays
         barSplit: if @selectedBarSplit()? then @selectedBarSplit().value() else null
      }

      handleSaveCompletion = =>
         @isSaving(false)
         if notifying
            modalManager.modalFinished()
         else
            @processRows @rows(), =>
               @originalRows(ko.toJS(@rows))
               @handleRedraw_()

      @isSaving(true)
      if @editingExisting
         if @editingBatch()
            GanttPaneCore.updateAssignment(@editingAssignment.batchId, newBatch, (err, newAssignment) =>
               return @onRequestError(err) if err
               # @data.set('notifyBatchId', resourcesData.newBatchId)
               # @rows()[0] = resourcesData
               @savedBatchIds.push(newAssignment.id)

               @rows()[0].assignments = @rows()[0].assignments.filter (assignment) =>
                  return !assignment.isPending and assignment.batchId != @editingAssignment.batchId

               formattedAssignment = @constructAssignmentForGantt(newAssignment)

               @rows()[0].assignments.push(formattedAssignment)

               # TODO: Update this? 
               existingNewAssignments = @data.get("newAssignments")
               if existingNewAssignments?
                  existingNewAssignments.push(newAssignment)
                  @data.set('newAssignments', existingNewAssignments)
               else
                  @data.set('newAssignments', [newAssignment])

               @data.set('notifyBatchId', newAssignment.id)
               # TODO: Update this.
               # if data.assignmentCard?
               #    @data.set('assignmentCard', data.assignmentCard)
               
               @closeRangeTray()

               handleSaveCompletion()
            )
         else
            originalBatchStartDay = @editingAssignment.batchStartDay
            originalBatchEndDay = @editingAssignment.batchEndDay
            newBatchStartDay = DateUtils.getDetachedDay(@editingStartDate())
            newBatchEndDay = DateUtils.getDetachedDay(@editingEndDate())

            formattedNewBatch = {
               ...newBatch,
               category_id: newBatch.cost_code_id
               custom_fields: {}
               overtime: newBatch.overtime ? false,
               subcategory_id: newBatch.label_id
            }
            batchesToSave = [formattedNewBatch]
            updateData = {}

            # Checking to see if we cut in the middle or if its touching either end of the original.
            if @editingAssignment.startDay > originalBatchStartDay and @editingAssignment.endDay < originalBatchEndDay

               # New end date for the start of the original assignment
               calculatedEndDay = @calculateLastWorkDay(DateUtils.decrementDetachedDay(@editingAssignment.startDay), @editingAssignment.workDays)
               updateData['end_day'] = calculatedEndDay

               # Add another assignment for the second chunk of the assignment that holds the original params. 
               calculatedStartDay = @calculateFirstWorkDay(DateUtils.incrementDetachedDay(@editingAssignment.endDay), @editingAssignment.workDays)
               trailingBatch = {
                  category_id: @editingAssignment.costCodeId
                  custom_fields: {},
                  end_day: @editingAssignment.batchEndDay
                  end_time: @editingAssignment.endTime
                  overtime_rates: @editingAssignment.overtimeRates
                  overtime: @editingAssignment.overtime ? false
                  pay_split: @editingAssignment.paySplit
                  percent_allocated: @editingAssignment.percentAllocated
                  project_id: @editingAssignment.projectId
                  resource_id: @editingAssignment.resourceId
                  start_day: calculatedStartDay
                  start_time: @editingAssignment.startTime
                  status_id: ko.unwrap(@editingAssignment.statusId)
                  subcategory_id: @editingAssignment.labelId
                  work_days: @editingAssignment.workDays
               }

               batchesToSave.push(trailingBatch)

            else
               # Figure out if we should update the start or end of the original assignment. 
               if @editingAssignment.startDay <= originalBatchStartDay
                  # Update the end chunk as original
                  calculatedStartDay = @calculateFirstWorkDay(DateUtils.incrementDetachedDay(@editingAssignment.endDay), @editingAssignment.workDays)
                  updateData['start_day'] = calculatedStartDay
               else if @editingAssignment.endDay >= originalBatchEndDay
                  # Update the start chunk as original
                  calculatedEndDay = @calculateLastWorkDay(DateUtils.decrementDetachedDay(@editingAssignment.startDay), @editingAssignment.workDays)
                  updateData['end_day'] = calculatedEndDay

            oldbatchId = @editingAssignment.batchId

            formattedEditingAssignment = {
               id: oldbatchId
               category_id : @editingAssignment.costCodeId
               end_day: @editingAssignment.batchEndDay
               end_time: @editingAssignment.endTime
               overtime_rates: @editingAssignment.overtimeRates
               overtime: @editingAssignment.overtime ? false
               pay_split: @editingAssignment.paySplit
               percent_allocated: @editingAssignment.percentAllocated
               project_id: @editingAssignment.projectId
               resource_id: @editingAssignment.resourceId
               start_day: @editingAssignment.startDay
               start_time: @editingAssignment.startTime
               status_id: ko.unwrap(@editingAssignment.statusId)
               subcategory_id: @editingAssignment.labelId
               work_days: @editingAssignment.workDays
            }
            try
               newAssignments = await LegacyStore.breakoutAssignments({
                  newAssignmentsPayload: batchesToSave,
                  originalAssignment: formattedEditingAssignment,
                  updateData,
               })
               @closeRangeTray()
               @rows()[0].assignments = @rows()[0].assignments.filter (item) =>
                  return item.batchId != oldbatchId and !item.isPending

               formattedNewAssignment = newAssignments.map (item) =>
                  return @constructAssignmentForGantt(item)
               @rows()[0].assignments = @rows()[0].assignments.concat(formattedNewAssignment)

               notifyBatchId = null
               for assignment in newAssignments
                  if assignment.startDay() == newBatchStartDay and assignment.endDay() == newBatchEndDay
                     notifyBatchId = assignment.id

               @data.set('notifyBatchId', notifyBatchId) if notifyBatchId?
               handleSaveCompletion()
            catch err
               return @onRequestError(err)

      else
         processNewAssignmentForGantt = (newAssignment) =>
            @rows()[0].assignments = @rows()[0].assignments.filter (assignment) ->
                  return !assignment.isPending

            formattedAssignment = @constructAssignmentForGantt(newAssignment)

            # TODO: Should this just be a push?
            @rows()[0].assignments = @rows()[0].assignments.concat(formattedAssignment)

            handleSaveCompletion()

         if @fillingRequestId?
            @fillRequest @fillingRequestId, newBatch, rangeData, (err, requestData) =>
               return @onRequestError(err) if err
               newAssignment = requestData.newAssignment
               @data.set('removedRequestData', requestData.removedRequestData)
               @savedBatchIds.push(newAssignment.id)

               existingNewAssignments = @data.get("newAssignments")
               if existingNewAssignments?
                  existingNewAssignments.push(newAssignment)
                  @data.set('newAssignments', existingNewAssignments)
               else
                  @data.set('newAssignments', [newAssignment])

               @data.set('notifyBatchId', newAssignment.id)
               # TODO: Update this.
               # if data.assignmentCard?
               #    @data.set('assignmentCard', data.assignmentCard)
               @closeRangeTray()

               processNewAssignmentForGantt(newAssignment)
         else
            GanttPaneCore.createAssignment(newBatch, (err, newAssignment) =>
               return @onRequestError(err) if err
               @savedBatchIds.push(newAssignment.id)

               existingNewAssignments = @data.get("newAssignments")
               if existingNewAssignments?
                  existingNewAssignments.push(newAssignment)
                  @data.set('newAssignments', existingNewAssignments)
               else
                  @data.set('newAssignments', [newAssignment])

               @data.set('notifyBatchId', newAssignment.id)
               # TODO: Update this.
               # if data.assignmentCard?
               #    @data.set('assignmentCard', data.assignmentCard)
               @closeRangeTray()

               processNewAssignmentForGantt(newAssignment)
            )
               
   fillRequest: (fillingRequestId, newBatch, rangeData, callback) ->
      payload = {
         category_id: newBatch.cost_code_id,
         end_day: newBatch.end_day,
         end_time: newBatch.end_time,
         subcategory_id: newBatch.label_id,
         overtime: newBatch.overtime || false,
         percent_allocated: if newBatch.percent_allocated? then Number(newBatch.percent_allocated) else null,
         project_id: newBatch.project_id,
         resource_id: newBatch.resource_id,
         start_day: newBatch.start_day,
         start_time: newBatch.start_time,
         status_id: newBatch.status_id,
         work_days: newBatch.work_days,
      }
      try
         # TODO: Attempt to optimize to fewer network requests.
         oldRequest = await RequestStore.getRequest(fillingRequestId).payload
         [
            personResponse,
            projectResponse,
            requestResponse,
            statusResponse,
         ] = await Promise.all([
            PersonStore.getPerson(newBatch.resource_id).payload,
            ProjectStore.getProject(newBatch.project_id).payload,
            RequestStore.fillRequest(fillingRequestId, payload).payload,
            if newBatch.status_id then StatusStore.getStatus(newBatch.status_id, payload).payload else null,
         ])
         position = null
         if personResponse.data.job_title?
            job_title_id = personResponse.data.job_title.id
            position = (await PositionStore.getPosition(job_title_id).payload).data
      catch err
         console.log("err: ", err)
         return callback err

      requestData = {}

      requestData.removedRequestData = {
         projectId: oldRequest.data.project_id
         costCodeId: oldRequest.data.category_id
         labelId: oldRequest.data.subcategory_id
         id: fillingRequestId
      }
      newAssignmentData = requestResponse.data
      if newAssignmentData.category_id
         categoryName = projectResponse.data.categories
            .find((cat) => cat.id == newAssignmentData.category_id)
            .name
      else
         categoryName = null

      category = projectResponse.data.categories
         .find((cat) => cat.id == newAssignmentData.category_id) ? null
      subcategory = null
      if category?
         subcategory = category.subcategories.find((sc) => sc.id == newAssignmentData.subcategory_id) ? null

      status = null
      if statusResponse?
         status = {
            id: statusResponse.data.id,
            name: statusResponse.data.name,
            abbreviation: statusResponse.data.abbreviation ? "",
            color: statusResponse.data.color,
            sequence: statusResponse.data.sequence,
         }

      requestData.newAssignment = new Assignment({
         cost_code_id: newAssignmentData.category_id,
         created_at: newAssignmentData.created_at,
         end_day: newAssignmentData.end_day,
         id: newAssignmentData.id,
         label_id: newAssignmentData.subcategory_id,
         project_id: newAssignmentData.project_id,
         resource_id: newAssignmentData.resource_id,
         start_day: newAssignmentData.start_day,
         work_days: newAssignmentData.work_days,

         # Optional.
         end_time: newAssignmentData.end_time,
         overtime_rates: newAssignmentData.overtime_rates,
         overtime: newAssignmentData.overtime,
         pay_split: newAssignmentData.pay_split,
         percent_allocated: newAssignmentData.percent_allocated,
         start_time: newAssignmentData.start_time,
         status_id: newAssignmentData.status_id,
         status: status,

         # Baggage
         baggage: {
            cost_code_name: categoryName,
            employee_number: personResponse.data.employee_number,
            group_ids: [], # TODO
            job_number: projectResponse.data.job_number,
            person_assignable_group_ids: personResponse.data.assignable_group_ids,
            person_group_ids: personResponse.data.group_ids,
            person_name: personResponse.data.name, # TODO: Check formatting.
            project_color: projectResponse.data.color,
            project_name: projectResponse.data.name,
            project_status: projectResponse.data.status,
            hourly_wage: personResponse.data.hourly_wage, # TODO: Does this need to account for overrides?

            label_name: if subcategory? then subcategory.name else null,
            position_color: if position? then position.color else null,
            position_rate: if position? then position.hourly_rate else null,
            position_sequence: if position? then position.sequence else null,
            wage_overrides: projectResponse.data.wage_overrides,
         }
      })

      callback null, requestData

   saveAndNotify: ->
      @data.set('notify', true)
      @data.set('notifyProjectId', @core.state.selectedProject().id)
      @data.set('notifyResourceId', @resourceId)
      @data.set('notifyContext', if @editingExisting then "assignment-edit" else "assignment-new")
      @save(true)

   storeDeleteAssignment: (callback) ->
      try
         await Assignment2Store.deleteAssignment(@editingAssignment.batchId).payload
         callback(null)
      catch err
         callback(err)

   deleteAssignment: (notifying) ->
      handleDeleteCompletion = =>
         @showingDeleteButtons(false)
         if notifying
            modalManager.modalFinished()
         else
            @processRows @rows(), =>
               @originalRows(ko.toJS(@rows))
               @handleRedraw_()

      if @editingBatch()
         @storeDeleteAssignment((err) =>
            return @onRequestError(err) if err
            @rows()[0].assignments = @rows()[0].assignments.filter (assignment) =>
               return assignment.batchId != @editingAssignment.batchId

            @editingAssignment['deletedBatch'] = true
            @deletedAssignments.push(@editingAssignment)
            @closeRangeTray()

            handleDeleteCompletion()
         )
      else
         # TODO: Pulled this into a shared utility.
         originalBatchStartDay = @editingAssignment.batchStartDay
         originalBatchEndDay = @editingAssignment.batchEndDay

         batchesToSave = []
         updateData = {}

         # Checking to see if we cut in the middle or if its touching either end of the original.
         if @editingAssignment.startDay > originalBatchStartDay and @editingAssignment.endDay < originalBatchEndDay

            # New end date for the start of the original assignment
            calculatedEndDay = @calculateLastWorkDay(DateUtils.decrementDetachedDay(@editingAssignment.startDay), @editingAssignment.workDays)
            updateData['end_day'] = calculatedEndDay

            # Add another assignment for the second chunk of the assignment that holds the original params. 
            calculatedStartDay = @calculateFirstWorkDay(DateUtils.incrementDetachedDay(@editingAssignment.endDay), @editingAssignment.workDays)
            trailingBatch = {
               category_id: @editingAssignment.costCodeId
               custom_fields: {}
               end_day: @editingAssignment.batchEndDay
               end_time: @editingAssignment.endTime
               overtime_rates: @editingAssignment.overtimeRates
               overtime: @editingAssignment.overtime ? false
               pay_split: @editingAssignment.paySplit
               percent_allocated: @editingAssignment.percentAllocated
               project_id: @editingAssignment.projectId
               resource_id: @editingAssignment.resourceId
               start_day: calculatedStartDay
               start_time: @editingAssignment.startTime
               status_id: ko.unwrap(@editingAssignment.statusId)
               subcategory_id: @editingAssignment.labelId
               work_days: @editingAssignment.workDays
            }

            batchesToSave.push(trailingBatch)

         else
            # Figure out if we should update the start or end of the original assignment. 
            if @editingAssignment.startDay <= originalBatchStartDay
               # Update the end chunk as original
               calculatedStartDay = @calculateFirstWorkDay(DateUtils.incrementDetachedDay(@editingAssignment.endDay), @editingAssignment.workDays)
               updateData['start_day'] = calculatedStartDay
            else if @editingAssignment.endDay >= originalBatchEndDay
               # Update the start chunk as original
               calculatedEndDay = @calculateLastWorkDay(DateUtils.decrementDetachedDay(@editingAssignment.startDay), @editingAssignment.workDays)
               updateData['end_day'] = calculatedEndDay

         oldbatchId = @editingAssignment.batchId

         formattedEditingAssignment = {
            id: oldbatchId,
            category_id : @editingAssignment.costCodeId
            end_day: @editingAssignment.batchEndDay
            end_time: @editingAssignment.endTime
            overtime_rates: @editingAssignment.overtimeRates
            overtime: @editingAssignment.overtime ? false
            pay_split: @editingAssignment.paySplit
            percent_allocated: @editingAssignment.percentAllocated
            project_id: @editingAssignment.projectId
            resource_id: @editingAssignment.resourceId
            start_day: @editingAssignment.startDay
            start_time: @editingAssignment.startTime
            status_id: ko.unwrap(@editingAssignment.statusId)
            subcategory_id: @editingAssignment.labelId
            work_days: @editingAssignment.workDays
         }
         try
            newAssignments = await LegacyStore.breakoutAssignments({
               newAssignmentsPayload: batchesToSave,
               originalAssignment: formattedEditingAssignment,
               updateData,
            })
            @closeRangeTray()
            @rows()[0].assignments = @rows()[0].assignments.filter (item) =>
               return item.batchId != oldbatchId and !item.isPending

            formattedNewAssignment = newAssignments.map (item) =>
               return @constructAssignmentForGantt(item)
            @rows()[0].assignments = @rows()[0].assignments.concat(formattedNewAssignment)

            # TODO: Wire up for delete & notify with hard details since there isn't a
            # batch for what is removed. 

            handleDeleteCompletion()
         catch err
            return @onRequestError(err)

   deleteAndNotify: ->
      @data.set('notify', true)
      @data.set('notifyProjectId', @core.state.selectedProject().id)
      @data.set('notifyResourceId', @resourceId)
      @data.set('notifyContext', 'assignment-delete')
      @data.set('notifyBatchId', @editingAssignment.batchId)
      @deleteAssignment(true)

   # TODO: Pull this our into a shared utility
   computeInstances: (next) ->
      workDays = {
         0: @sundayOn()
         1: @mondayOn()
         2: @tuesdayOn()
         3: @wednesdayOn()
         4: @thursdayOn()
         5: @fridayOn()
         6: @saturdayOn()
      }

      instances = []
      endDay = DateUtils.getDetachedDay(@editingEndDate())

      processInstance = (instanceStartDate) ->
         validDetachedDays = []
         processingDate = instanceStartDate
         foundInstanceStart = false
         processingInstance = true
         processingDay = null

         while processingInstance
            working = workDays[processingDate.getDay()]
            if !working and foundInstanceStart
               processingInstance = false
               break
            else if working and !foundInstanceStart
               foundInstanceStart = true

            processingDay = DateUtils.getDetachedDay(processingDate)
            validDetachedDays.push(processingDay) if working

            processingDate = DateUtils.incrementDate(processingDate, 1)
            if processingDay >= endDay
               processingInstance = false
               break

         if validDetachedDays.length > 0
            instances.push({
               start_day: validDetachedDays[0]
               end_day: validDetachedDays[validDetachedDays.length - 1]
               days: validDetachedDays
            })

         if DateUtils.getDetachedDay(processingDate) > endDay
            next(instances)
         else
            return processInstance(processingDate)


      processInstance(new Date(@editingStartDate().getTime()))

   # TODO: Pull this our into a shared utility
   processRows: (newRows, next) =>
      ### Compression Comparison Alg. Cases.
         __________________
         | Range          |
         ------------------
      __________    __________
      | Case A |    | Case B |
      ----------    ----------
            ___________
            | Case C  |
            -----------
      _________________________
      | Case D                |
      -------------------------   
      ###

      # Process Tiers
      runningTiers = 0
      for task in newRows
         assTiers = {0: []}
         toTiers = {0: []}

         if @selectedRangeDays() == 1
            for ass in task.assignments
               foundClash = false

               # Check if assignment is overnight
               if ass.startTime > ass.endTime
                  # Sanitize s/e times for comparison.
                  if ass.singleDay < @viewStartDay()
                     # This was trailing from day before.
                     startTime = 0
                     endTime = ass.endTime
                  else
                     startTime = ass.startTime
                     endTime = 23.99
               else
                  startTime = ass.startTime
                  endTime = ass.endTime

               for key, val of assTiers
                  key = Number(key)
                  foundClash = false
                  if val.length == 0
                     val.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                     ass['tier'] = key
                     break

                  for range in val
                     # Check if the range is overnight.
                     if range.s > range.e
                        if range.singleDay < @viewStartDay()
                           # This was trailing from day before.
                           rangeStart = 0
                           rangeEnd = range.e
                        else
                           rangeStart = range.s
                           rangeEnd = 23.99
                     else
                        rangeStart = range.s
                        rangeEnd = range.e

                     # Case B & C
                     if ((startTime >= rangeStart and startTime < rangeEnd) or
                     # Case A
                     (endTime > rangeStart and endTime <= rangeEnd) or
                     # Case D
                     (startTime <= rangeStart and endTime >= rangeEnd))
                        foundClash = true
                        break

                  # check breaks
                  unless foundClash
                     val.push({s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay})
                     ass['tier'] = key
                     break

                  # If this is the last tier, we need another
                  if Object.keys(assTiers).length - 1 == key
                     newTier = key + 1
                     assTiers[newTier] = [{s: ass.startTime, e: ass.endTime, singleDay: ass.singleDay}]
                     ass['tier'] = newTier
                     break

         else
            for ass in task.assignments
               foundClash = false
               for key, val of assTiers
                  key = Number(key)
                  foundClash = false
                  if val.length == 0
                     val.push({s: ass.startDay, e: ass.endDay})
                     ass['tier'] = key
                     break

                  for range in val
                     # With this, we have to account for the fact that we will increment end days
                     # by 1 always for d3's scale to work properly.
                     if ((ass.startDay >= range.s and ass.startDay < range.e + 1) or
                     (ass.endDay >= range.s and ass.endDay <= range.e) or
                     (ass.startDay <= range.s and ass.endDay >= range.e))
                        foundClash = true
                        break

                  # check breaks
                  unless foundClash
                     val.push({s: ass.startDay, e: ass.endDay})
                     ass['tier'] = key
                     break

                  # If this is the last tier, we need another
                  if Object.keys(assTiers).length - 1 == key
                     newTier = key + 1
                     assTiers[newTier] = [{s: ass.startDay, e: ass.endDay}]
                     ass['tier'] = newTier
                     break

         for to in task.timeoff
            foundClash = false
            for key, val of toTiers
               key = Number(key)
               foundClash = false
               if val.length == 0
                  val.push({s: to.startDay, e: to.endDay})
                  to['tier'] = key
                  break

               for range in val
                  # With this, we have to account for the fact that we will increment end days
                  # by 1 always for d3's scale to work properly.
                  if ((to.startDay >= range.s and to.startDay < range.e + 1) or
                  (to.endDay > range.s and to.endDay <= range.e) or
                  (to.startDay <= range.s and to.endDay >= range.e))
                     foundClash = true
                     break

               # check breaks
               unless foundClash
                  val.push({s: to.startDay, e: to.endDay})
                  to['tier'] = key
                  break

               # If this is the last tier, we need another
               if Object.keys(toTiers).length - 1 == key
                  newTier = key + 1
                  toTiers[newTier] = [{s: to.startDay, e: to.endDay}]
                  to['tier'] = newTier
                  break

         assTierCount = Object.keys(assTiers).length
         toTierCount = Object.keys(toTiers).length
         if toTierCount == 1
            toTierCount = 0 if toTiers[0].length == 0

         task['assTiers'] = assTierCount
         task['toTiers'] = toTierCount
         task['tiersBefore'] = runningTiers
         minTiersPerRow = 2
         actualTierCount = assTierCount + toTierCount
         if minTiersPerRow > actualTierCount
            runningTiers = runningTiers + minTiersPerRow
            task['rowTiers'] = minTiersPerRow
         else
            runningTiers = runningTiers + actualTierCount
            task['rowTiers'] = actualTierCount

      @rows(newRows)
      next()

   loadAssignmenments: ->
      try
         startDay = DateUtils.getDetachedDay(@viewStartDate())
         if @selectedRangeDays() == 1
            endDay = startDay
         else
            endDate = new Date(@viewStartDate().getTime())
            endDate.setDate(endDate.getDate() + (@selectedRangeDays() - 1))
            endDay = DateUtils.getDetachedDay(endDate)
   
         data = await Assignment2Store.getPersonsGanttAssignments(@resourceId, startDay, endDay);
         originEdititingAssignment = []

         finishProcessingLoad = =>
            @processRows [data], =>
               
               # Decouple and store original to revert to.
               @originalRows @rows().map (item) ->
                  newRow = Object.assign({}, item)
                  newRow.assignments = newRow.assignments.filter((item) -> return !item.isPending).concat(originEdititingAssignment).map (ass) ->
                     return Object.assign({}, ass)
                  return newRow

               setTimeout => @handleRedraw_()

         if @editingAssignment?
            # TODO: This could be cleaned up
            if @editingBatch()
               # Remove other occurences of batch
               workingBatchId = @editingAssignment.batchId
               data.assignments = data.assignments.filter (ass) ->
                  if ass.isPending or ass.batchId == workingBatchId
                     originEdititingAssignment.push(ass)
                     return false
                  else 
                     true
            else
               # Remove the old copy of this occurence
               workingInstanceId = @editingAssignment.instanceId
               data.assignments = data.assignments.filter (ass) ->
                  if ass.isPending or ass.instanceId == workingInstanceId
                     originEdititingAssignment.push(ass)
                     return false
                  else 
                     true

            # Generate New Occurences
            @computeInstances (instances) =>
               for instance in instances
                  instance['batchStartDay'] = DateUtils.getDetachedDay(@editingStartDate())
                  instance['batchEndDay'] = DateUtils.getDetachedDay(@editingEndDate())
                  instance['batchId'] = workingBatchId
                  instance['color'] = @editingAssignment.color
                  instance['costCodeId'] = @editingAssignment.costCodeId
                  instance['labelId'] = @editingAssignment.labelId
                  instance['startTime'] = @editingAssignment.startTime
                  instance['endTime'] = @editingAssignment.endTime
                  instance['costCodeName'] = @editingAssignment.costCodeName
                  instance['labelName'] = @editingAssignment.labelName
                  instance['startDay'] = instance.start_day
                  instance['endDay'] = instance.end_day
                  instance['workDays'] = @editingAssignment.workDays
                  instance['projectId'] = @editingAssignment.projectId
                  instance['projectName'] = @editingAssignment.projectName
                  instance['singleDay'] = @editingAssignment.singleDay
                  instance['isPending'] = true
                  instance['statusId'] = @editingAssignment.statusId
                  instance['statusName'] = @editingAssignment.statusName

               # Add to existing data
               data.assignments = data.assignments.concat(instances)
               finishProcessingLoad()
         else
            finishProcessingLoad()

      catch error
         console.log(error)
         return error
      

   onRequestError: (err) ->
      console.log "Error: ", err
      message = err.responseJSON?.message
      @displayingNotice({
         ...GanttPane.Notice.REQUEST_ERROR
         text: message or GanttPane.Notice.REQUEST_ERROR.text
      })
      @isSaving(false)

   hasOvertimeRateChanged: (inputRate, originalRate) ->
      return (
         inputRate? and
         inputRate.length > 0 and
         Number(inputRate) != originalRate
      )


GanttPane.Notice = {
   NO_INSTANCES: {
      text: 'Your configuration needs to create at least one occurence. Check your work days.'
      info: null
      color: 'red'
      dissmissable: true
   }
   START_DAY_NOT_ACTIVE: {
      text: 'Your start day needs to be set as an active workday.'
      info: null
      color: 'red'
      dissmissable: true
   }
   END_DAY_NOT_ACTIVE: {
      text: 'Your end day needs to be set as an active workday.'
      info: null
      color: 'red'
      dissmissable: true
   }
   STATUS_REQUIRED: {
      text: 'Must specify a Status.',
      info: null,
      color: 'red',
      dissmissable: true,
   }
   REQUEST_ERROR: {
      text: 'Something went wrong, please try again.',
      info: null,
      color: 'red',
      dissmissable: true,
   }
}

import { observable, pureComputed } from "knockout";
import type { Observable, PureComputed } from "knockout";
import { ValueSet } from "@/models/value-set";
import { DateUtils } from "@/lib/utils/date";
import { DateFormat } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

export class DefaultStoreCore {
   readonly selectedDateFormat: Observable<DateFormat>;
   readonly selectedDateFormateSeparator: PureComputed<string>;

   constructor() {
      this.selectedDateFormat = observable<DateFormat>(DateFormat.MM_DD_YYYY);
      this.selectedDateFormateSeparator = pureComputed<string>(() => {
         return this.selectedDateFormat().indexOf("/") != -1 ? "/" : "-";
      });
   }

   getLanguageOptions(): ValueSet[] {
      return DefaultStoreCore.LanguageOptions.map(
         (option: { name: string; value: string }) => new ValueSet(option),
      );
   }

   getDefaultLanguageOptions(): Array<{ name: string; value: string }> {
      return DefaultStoreCore.LanguageOptions;
   }

   getDefaultResourceColorValues(): string[] {
      return DefaultStoreCore.ResourceColors;
   }

   getDateFormat(): DateFormat {
      return this.selectedDateFormat();
   }

   /**
    * Formerly: getCompanyFormattedReportHeader
    */
   getFormattedDate(date: Date): string {
      return DateUtils.formatDate(date, this.getDateFormat(), {
         dayFormat: DateUtils.DayFormat.TWO_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
      });
   }

   setDateFormat(format: DateFormat): void {
      this.selectedDateFormat(format);
   }

   getTimeOptions(startingValue?: number): ValueSet[] {
      if (startingValue == null) {
         return DefaultStoreCore.TimeOptions.map(
            (option: { name: string; value: number }) => new ValueSet(option),
         );
      }

      const cutoffTimes: ValueSet[] = [];
      const timeOptions: ValueSet[] = [];
      for (const option of DefaultStoreCore.TimeOptions) {
         if (option.value <= startingValue) {
            cutoffTimes.push(new ValueSet(option));
         } else {
            timeOptions.push(new ValueSet(option));
         }
      }
      return timeOptions.concat(cutoffTimes);
   }

   formatTime(timeValue: number): string | null {
      for (const option of DefaultStoreCore.TimeOptions) {
         if (option.value == timeValue) return option.name;
      }
      return null;
   }

   getTimeOffReasons(): ValueSet[] {
      return DefaultStoreCore.TimeOffReasons.map(
         (option: { name: string; value: string }) => new ValueSet(option),
      );
   }

   static readonly TimeOffReasons = [
      {
         name: "Bereavement",
         value: "bereavement",
      },
      {
         name: "Family",
         value: "family",
      },
      {
         name: "Jury Duty",
         value: "jury duty",
      },
      {
         name: "Lack of Work",
         value: "lack of work",
      },
      {
         name: "Medical",
         value: "medical",
      },
      {
         name: "Military",
         value: "military",
      },
      {
         name: "Personal",
         value: "personal",
      },
      {
         name: "School",
         value: "school",
      },
      {
         name: "Sick",
         value: "sick",
      },
      {
         name: "Vacation",
         value: "vacation",
      },
      {
         name: "Other",
         value: "other",
      },
   ];

   static readonly LanguageOptions = [
      {
         name: "English",
         value: "english",
      },
      {
         name: "Spanish",
         value: "spanish",
      },
      {
         name: "French",
         value: "french",
      },
      {
         name: "German",
         value: "german",
      },
      {
         name: "Italian",
         value: "italian",
      },
      {
         name: "Arabic",
         value: "arabic",
      },
      {
         name: "Russian",
         value: "russian",
      },
      {
         name: "Other",
         value: "other",
      },
   ];

   static readonly TimeOptions = [
      { name: "12:00 am", value: 0 },
      { name: "12:15 am", value: 0.25 },
      { name: "12:30 am", value: 0.5 },
      { name: "12:45 am", value: 0.75 },
      { name: "1:00 am", value: 1 },
      { name: "1:15 am", value: 1.25 },
      { name: "1:30 am", value: 1.5 },
      { name: "1:45 am", value: 1.75 },
      { name: "2:00 am", value: 2 },
      { name: "2:15 am", value: 2.25 },
      { name: "2:30 am", value: 2.5 },
      { name: "2:45 am", value: 2.75 },
      { name: "3:00 am", value: 3 },
      { name: "3:15 am", value: 3.25 },
      { name: "3:30 am", value: 3.5 },
      { name: "3:45 am", value: 3.75 },
      { name: "4:00 am", value: 4 },
      { name: "4:15 am", value: 4.25 },
      { name: "4:30 am", value: 4.5 },
      { name: "4:45 am", value: 4.75 },
      { name: "5:00 am", value: 5 },
      { name: "5:15 am", value: 5.25 },
      { name: "5:30 am", value: 5.5 },
      { name: "5:45 am", value: 5.75 },
      { name: "6:00 am", value: 6 },
      { name: "6:15 am", value: 6.25 },
      { name: "6:30 am", value: 6.5 },
      { name: "6:45 am", value: 6.75 },
      { name: "7:00 am", value: 7 },
      { name: "7:15 am", value: 7.25 },
      { name: "7:30 am", value: 7.5 },
      { name: "7:45 am", value: 7.75 },
      { name: "8:00 am", value: 8 },
      { name: "8:15 am", value: 8.25 },
      { name: "8:30 am", value: 8.5 },
      { name: "8:45 am", value: 8.75 },
      { name: "9:00 am", value: 9 },
      { name: "9:15 am", value: 9.25 },
      { name: "9:30 am", value: 9.5 },
      { name: "9:45 am", value: 9.75 },
      { name: "10:00 am", value: 10 },
      { name: "10:15 am", value: 10.25 },
      { name: "10:30 am", value: 10.5 },
      { name: "10:45 am", value: 10.75 },
      { name: "11:00 am", value: 11 },
      { name: "11:15 am", value: 11.25 },
      { name: "11:30 am", value: 11.5 },
      { name: "11:45 am", value: 11.75 },
      { name: "12:00 pm", value: 12 },
      { name: "12:15 pm", value: 12.25 },
      { name: "12:30 pm", value: 12.5 },
      { name: "12:45 pm", value: 12.75 },
      { name: "1:00 pm", value: 13 },
      { name: "1:15 pm", value: 13.25 },
      { name: "1:30 pm", value: 13.5 },
      { name: "1:45 pm", value: 13.75 },
      { name: "2:00 pm", value: 14 },
      { name: "2:15 pm", value: 14.25 },
      { name: "2:30 pm", value: 14.5 },
      { name: "2:45 pm", value: 14.75 },
      { name: "3:00 pm", value: 15 },
      { name: "3:15 pm", value: 15.25 },
      { name: "3:30 pm", value: 15.5 },
      { name: "3:45 pm", value: 15.75 },
      { name: "4:00 pm", value: 16 },
      { name: "4:15 pm", value: 16.25 },
      { name: "4:30 pm", value: 16.5 },
      { name: "4:45 pm", value: 16.75 },
      { name: "5:00 pm", value: 17 },
      { name: "5:15 pm", value: 17.25 },
      { name: "5:30 pm", value: 17.5 },
      { name: "5:45 pm", value: 17.75 },
      { name: "6:00 pm", value: 18 },
      { name: "6:15 pm", value: 18.25 },
      { name: "6:30 pm", value: 18.5 },
      { name: "6:45 pm", value: 18.75 },
      { name: "7:00 pm", value: 19 },
      { name: "7:15 pm", value: 19.25 },
      { name: "7:30 pm", value: 19.5 },
      { name: "7:45 pm", value: 19.75 },
      { name: "8:00 pm", value: 20 },
      { name: "8:15 pm", value: 20.25 },
      { name: "8:30 pm", value: 20.5 },
      { name: "8:45 pm", value: 20.75 },
      { name: "9:00 pm", value: 21 },
      { name: "9:15 pm", value: 21.25 },
      { name: "9:30 pm", value: 21.5 },
      { name: "9:45 pm", value: 21.75 },
      { name: "10:00 pm", value: 22 },
      { name: "10:15 pm", value: 22.25 },
      { name: "10:30 pm", value: 22.5 },
      { name: "10:45 pm", value: 22.75 },
      { name: "11:00 pm", value: 23 },
      { name: "11:15 pm", value: 23.25 },
      { name: "11:30 pm", value: 23.5 },
      { name: "11:45 pm", value: 23.75 },
   ];

   static readonly ResourceColors = [
      "#FF0537",
      "#B51600",
      "#FF621C",
      "#FF9831",
      "#FFCF00",
      "#FFFB23",
      "#CDFF2F",
      "#8AF33F",
      "#50BF00",
      "#128F00",
      "#34FFA9",
      "#63FDFF",
      "#6FD9FF",
      "#53A9FF",
      "#4D70F4",
      "#D35EFF",
      "#9C68FF",
      "#744BFF",
      "#FF54BE",
      "#FD94FF",
   ];

   static readonly TagColors = [
      "#FFC9B7",
      "#FFE2AD",
      "#FFFFC9",
      "#E8FFCE",
      "#A9FFCE",
      "#C2FFFE",
      "#D6E8FF",
      "#EFDCFF",
      "#DAD3FF",
      "#FFE8F5",
   ];

   static readonly BrandingColors = ["#FF0000", "#00FF00", "#0000FF"];
}

export const defaultStoreCore = new DefaultStoreCore();

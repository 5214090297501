import "./overtime-paysplit-editor-element.styl";
import template from "./overtime-paysplit-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import { observable } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";
import { TextFieldType } from "@/lib/components/text-field/text-field";
import type { AssignmentPaySplit } from "@laborchart-modules/common";

export type OvertimePaysplitEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<AssignmentPaySplit | null>;
} & EditorElementParams<Partial<AssignmentPaySplit> | null>;

export class OvertimePaysplitEditorElement extends ViewModel {
   readonly invalidInputMessage: OvertimePaysplitEditorElementParams["invalidInputMessage"];
   readonly isDisabled: OvertimePaysplitEditorElementParams["isDisabled"];
   readonly onChange: OvertimePaysplitEditorElementParams["onChange"];
   readonly title: OvertimePaysplitEditorElementParams["title"];
   readonly value: OvertimePaysplitEditorElementParams["value"];

   readonly overtimeFieldMediator = pureComputed<string | null>({
      read: () => this.value()?.overtime.toString() ?? null,
      write: (value) => {
         const valueAsNumber = Number(value);
         this.onChange({
            overtime: isNaN(valueAsNumber) ? 0 : valueAsNumber,
            straight: this.value()?.straight ?? 0,
            unpaid: this.value()?.unpaid ?? 0,
         });
      },
   });
   readonly straightFieldMediator = pureComputed<string | null>({
      read: () => this.value()?.straight.toString() ?? null,
      write: (value) => {
         const valueAsNumber = Number(value);
         this.onChange({
            overtime: this.value()?.overtime ?? 0,
            straight: isNaN(valueAsNumber) ? 0 : valueAsNumber,
            unpaid: this.value()?.unpaid ?? 0,
         });
      },
   });

   readonly unpaidFieldMediator = pureComputed<string | null>({
      read: () => this.value()?.unpaid.toString() ?? null,
      write: (value) => {
         const valueAsNumber = Number(value);
         this.onChange({
            overtime: this.value()?.overtime ?? 0,
            straight: this.value()?.straight ?? 0,
            unpaid: isNaN(valueAsNumber) ? 0 : valueAsNumber,
         });
      },
   });

   private readonly overtimeFieldParams: TextFieldParams;
   private readonly straightFieldParams: TextFieldParams;
   private readonly unpaidFieldParams: TextFieldParams;

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: OvertimePaysplitEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;

      this.overtimeFieldParams = {
         isClearable: observable(false),
         isDisabled,
         type: TextFieldType.NUMBER,
         value: this.overtimeFieldMediator,
      };
      this.straightFieldParams = {
         isClearable: observable(false),
         isDisabled,
         type: TextFieldType.NUMBER,
         value: this.straightFieldMediator,
      };
      this.unpaidFieldParams = {
         isClearable: observable(false),
         isDisabled,
         type: TextFieldType.NUMBER,
         value: this.unpaidFieldMediator,
      };
   }

   static factory(
      params: OvertimePaysplitEditorElementParams,
   ): ComponentArgs<OvertimePaysplitEditorElementParams> {
      return {
         name: "overtime-paysplit-editor-element",
         params,
      };
   }
}

ko.components.register("overtime-paysplit-editor-element", {
   viewModel: OvertimePaysplitEditorElement,
   template: template(),
   synchronous: true,
});

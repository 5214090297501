import "./work-hours-editor-element.styl";
import template from "./work-hours-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { TimePicker2Params } from "@/lib/components/time-picker-2/time-picker-2";
import type { EditorElementParams } from "@/lib/components/modals/editors/common/editor-element-template";

export type WorkHoursEditorElementParams = {
   endTime: PureComputed<number | null>;
   invalidInputMessage: PureComputed<string | null>;
   onEndTimeChange: (startTime: number) => void;
   onStartTimeChange: (startTime: number) => void;
   startTime: PureComputed<number | null>;
   title: string;
} & Omit<EditorElementParams<any>, "onChange">;

export class WorkHoursEditorElement extends ViewModel {
   readonly endTime: WorkHoursEditorElementParams["endTime"];
   readonly invalidInputMessage: WorkHoursEditorElementParams["invalidInputMessage"];
   readonly onEndTimeChange: WorkHoursEditorElementParams["onEndTimeChange"];
   readonly onStartTimeChange: WorkHoursEditorElementParams["onStartTimeChange"];
   readonly startTime: WorkHoursEditorElementParams["startTime"];
   readonly title: WorkHoursEditorElementParams["title"];

   readonly startTimeMediator = pureComputed<number | null>({
      read: () => unwrap(this.startTime),
      write: (startTime) => {
         if (startTime != null) this.onStartTimeChange(startTime);
      },
   });
   readonly startTimeInputParams: TimePicker2Params = {
      value: this.startTimeMediator,
   };

   readonly endTimeMediator = pureComputed<number | null>({
      read: () => unwrap(this.endTime),
      write: (endTime) => {
         if (endTime != null) this.onEndTimeChange(endTime);
      },
   });
   readonly endTimeInputParams: TimePicker2Params = {
      value: this.endTimeMediator,
   };

   constructor({
      endTime,
      invalidInputMessage,
      onEndTimeChange,
      onStartTimeChange,
      startTime,
      title = "Work Hours",
   }: WorkHoursEditorElementParams) {
      super(template());
      this.endTime = endTime;
      this.invalidInputMessage = invalidInputMessage;
      this.onEndTimeChange = onEndTimeChange;
      this.onStartTimeChange = onStartTimeChange;
      this.startTime = startTime;
      this.title = title;
   }

   static factory(
      params: WorkHoursEditorElementParams,
   ): ComponentArgs<WorkHoursEditorElementParams> {
      return {
         name: "work-hours-editor-element",
         params,
      };
   }
}

ko.components.register("work-hours-editor-element", {
   viewModel: WorkHoursEditorElement,
   template: template(),
   synchronous: true,
});

import React from "react";
import {
   DetailPage,
   H1,
   P,
   Form,
   Required,
   Button,
   Thumbnail,
   Typography,
   FlexList,
   useI18nContext,
   Spinner,
} from "@procore/core-react";
import { DateTimeFormatter, NumberFormatter } from "@procore/globalization-toolkit";
import { WorkDaysCheckboxes } from "./work-days-checkboxes";
import * as yup from "yup";
import type { LaborPlansFormProps } from "./prop-types";
import { calculateProjectDurationInWeeks } from "./helpers";
import { LaborPlansBreadCrumbs } from "./labor-plans-breadcrumbs";
import { ErrorBanner } from "../error-banner";
import styles from "./labor-plans.scss";
import classNames from "classnames";

const cx = classNames.bind(styles);

export const LaborPlansForm = (props: LaborPlansFormProps) => {
   const I18n = useI18nContext();
   const {
      projectDetailsUrl,
      imagesUrl,
      project,
      totalHours,
      tbdWeeks,
      loading,
      selectedWorkDays,
      setSelectedWorkDays,
      handleNextClick,
      onCancel,
      onBreadCrumbClick,
      curveError,
      tearsheet,
      canViewAllStatuses,
      viewableStatuses,
   } = props;
   const dateTimeOptions = {
      locale: I18n.currentLocale,
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as any);
   const numberOptions = { locale: I18n.currentLocale(), minimumFractionDigits: 0 };
   const numberFormatter = new NumberFormatter(numberOptions);

   const getProjectFormValues = () => {
      if (!project) return {};
      const formattedStartDate = dateFormatter.formatDateTime(project.start_date!);
      const formattedEndDate = project.est_end_date
         ? dateFormatter.formatDateTime(project.est_end_date)
         : I18n.t("views.company.workforce_planning.tbd");
      const total_duration = `${calculateProjectDurationInWeeks(project, tbdWeeks)} ${I18n.t(
         "views.company.workforce_planning.weeks",
      )}`;
      return {
         project: project.name,
         project_hours_to_date: `${numberFormatter.formatNumber(totalHours)} ${I18n.t(
            "views.company.workforce_planning.hours",
         )}`,
         daily_work_hours: `${project.daily_end_time - project.daily_start_time} ${I18n.t(
            "views.company.workforce_planning.hours",
         )}`,
         dates: `${formattedStartDate} - ${formattedEndDate}`,
         total_duration: total_duration,
         work_days: selectedWorkDays,
         labor_curve: "bell_curve",
      };
   };

   return (
      <Form
         view="create"
         initialValues={getProjectFormValues()}
         enableReinitialize
         onSubmit={(values) => handleNextClick(values)}
         validationSchema={yup.object().shape({
            total_estimated_hours: yup
               .number()
               .min(
                  40,
                  I18n.t("views.company.workforce_planning.labor_plan.validations.estimated_hours"),
               )
               .required(
                  I18n.t("views.company.workforce_planning.labor_plan.validations.estimated_hours"),
               ),
            max_peak_workers: yup
               .number()
               .min(
                  1,
                  I18n.t("views.company.workforce_planning.labor_plan.validations.peak_workers"),
               ),
            status: yup
               .object()
               .test(
                  "validate-status",
                  I18n.t("views.company.workforce_planning.labor_plan.validations.status"),
                  function (values: any) {
                     if (canViewAllStatuses) return true;

                     return !!values;
                  },
               ),
            work_days: yup
               .object()
               .test(
                  "validate-work-days",
                  I18n.t("views.company.workforce_planning.labor_plan.validations.work_days"),
                  function (values: any) {
                     let isValid = false;
                     Object.keys(values).forEach((i) => {
                        if (values[i]) {
                           isValid = true;
                        }
                     });
                     return isValid;
                  },
               ),
         })}
      >
         {/* istanbul ignore next */}
         {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
         {({ submitForm, setFieldValue, values }: FormikProps<any>) => (
            <Spinner loading={loading}>
               <DetailPage.Header>
                  {!tearsheet && (
                     <LaborPlansBreadCrumbs
                        onClick={onBreadCrumbClick}
                        project={project}
                        projectDetailsUrl={projectDetailsUrl!}
                     />
                  )}
                  <DetailPage.Title>
                     <H1>{I18n.t("views.company.workforce_planning.labor_plan.create_title")}</H1>
                     <P>{I18n.t("views.company.workforce_planning.labor_plan.details_text")}</P>
                  </DetailPage.Title>
               </DetailPage.Header>
               <DetailPage.Body
                  className={tearsheet ? cx("tearsheet-body") : cx("detail-page-body")}
               >
                  {curveError && (
                     <ErrorBanner
                        title={I18n.t("views.company.workforce_planning.error")}
                        content={I18n.t("views.company.workforce_planning.labor_plan.curve_error")}
                     />
                  )}
                  <DetailPage.Card>
                     <DetailPage.Section
                        heading={I18n.t("views.company.workforce_planning.project_details")}
                     >
                        <Form.Form>
                           <Form.Row>
                              <Form.Text
                                 view="read"
                                 name="project"
                                 label={I18n.t("views.company.workforce_planning.project")}
                                 colWidth={3}
                              />
                              <Form.Text
                                 view="read"
                                 name="dates"
                                 label={I18n.t("views.company.workforce_planning.dates")}
                                 colStart={5}
                                 colWidth={3}
                              />
                              <Form.Text
                                 view="read"
                                 name="total_duration"
                                 label={I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.total_duration",
                                 )}
                                 colStart={9}
                                 colWidth={3}
                                 tooltip={I18n.t(
                                    "views.company.workforce_planning.labor_plan.tooltips.duration",
                                 )}
                              />
                           </Form.Row>
                           <Form.Row>
                              <Form.Text
                                 view="read"
                                 name="daily_work_hours"
                                 label={I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.daily_work_hours",
                                 )}
                                 tooltip={I18n.t(
                                    "views.company.workforce_planning.labor_plan.tooltips.daily_work_hours",
                                 )}
                              />
                              <Form.Text
                                 view="read"
                                 name="project_hours_to_date"
                                 label={I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.project_hours_to_date",
                                 )}
                                 colStart={5}
                                 tooltip={I18n.t(
                                    "views.company.workforce_planning.labor_plan.tooltips.hours_to_date",
                                 )}
                              />
                           </Form.Row>
                           <DetailPage.Section
                              heading={I18n.t("views.company.workforce_planning.labor_plan.title")}
                           >
                              <Form.Row>
                                 <Form.Text
                                    required
                                    name="total_estimated_hours"
                                    label={I18n.t(
                                       "views.company.workforce_planning.labor_plan.labels.total_estimated_hours",
                                    )}
                                    colWidth={canViewAllStatuses ? 3 : 2}
                                 />
                                 <Form.Text
                                    name="max_peak_workers"
                                    label={I18n.t(
                                       "views.company.workforce_planning.labor_plan.labels.max_peak_workers",
                                    )}
                                    colStart={canViewAllStatuses ? 5 : 3}
                                    colWidth={canViewAllStatuses ? 3 : 2}
                                 />
                                 {!canViewAllStatuses && (
                                    <Form.Select
                                       name="status"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.status",
                                       )}
                                       tooltip={I18n.t(
                                          "views.company.workforce_planning.labor_plan.tooltips.status",
                                       )}
                                       colStart={5}
                                       colWidth={2}
                                       options={viewableStatuses}
                                       required
                                    />
                                 )}
                                 <Form.Field
                                    colStart={9}
                                    //colWidth={}
                                    as={WorkDaysCheckboxes}
                                    label={I18n.t("views.company.workforce_planning.work_days")}
                                    required
                                    name="work_days"
                                    setSelectedWorkDays={setSelectedWorkDays}
                                    value={selectedWorkDays}
                                 />
                              </Form.Row>
                           </DetailPage.Section>
                           <div>
                              <Typography weight="bold">
                                 {I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.labor_curve",
                                 )}
                                 <Required style={{ marginLeft: "5px" }} />
                              </Typography>

                              <FlexList>
                                 <Thumbnail
                                    style={{ paddingTop: "20px" }}
                                    size="lg"
                                    src={
                                       values.labor_curve == "bell_curve"
                                          ? `${imagesUrl}/labor-curves/bell-curve.png`
                                          : `${imagesUrl}/labor-curves/bell-curve-inactive.png`
                                    }
                                    required
                                    onClick={() => setFieldValue("labor_curve", "bell_curve")}
                                 />
                                 <Thumbnail
                                    style={{ paddingTop: "20px" }}
                                    size="lg"
                                    src={
                                       values.labor_curve == "linear"
                                          ? `${imagesUrl}/labor-curves/linear.png`
                                          : `${imagesUrl}/labor-curves/linear-inactive.png`
                                    }
                                    required
                                    onClick={() => {
                                       setFieldValue("labor_curve", "linear");
                                    }}
                                 />
                                 <Thumbnail
                                    style={{ paddingTop: "20px" }}
                                    size="lg"
                                    src={
                                       values.labor_curve == "custom_curve"
                                          ? `${imagesUrl}/labor-curves/custom-curve.png`
                                          : `${imagesUrl}/labor-curves/custom-curve-inactive.png`
                                    }
                                    required
                                    onClick={() => {
                                       setFieldValue("labor_curve", "custom_curve");
                                    }}
                                 />

                                 {values.labor_curve && (
                                    <div style={{ width: "200px" }}>
                                       <Typography weight="semibold">
                                          {I18n.t(
                                             `views.company.workforce_planning.labor_plan.labels.${values.labor_curve}`,
                                          )}
                                       </Typography>
                                       <br />
                                       <Typography>
                                          {I18n.t(
                                             `views.company.workforce_planning.labor_plan.labels.${values.labor_curve}_text`,
                                          )}
                                       </Typography>
                                    </div>
                                 )}
                              </FlexList>
                           </div>
                        </Form.Form>
                     </DetailPage.Section>
                  </DetailPage.Card>
               </DetailPage.Body>
               {!loading && (
                  <DetailPage.Footer
                     style={{
                        position: "fixed",
                        bottom: "0",
                        width: tearsheet ? "952px" : "100%",
                     }}
                  >
                     <DetailPage.FooterNotation>
                        <Required showLabel />
                     </DetailPage.FooterNotation>
                     <DetailPage.FooterActions>
                        <Button variant="secondary" onClick={() => onCancel()}>
                           {I18n.t("views.company.workforce_planning.cancel")}
                        </Button>
                        <Button type="submit" onClick={submitForm}>
                           {I18n.t("views.company.workforce_planning.next")}
                        </Button>
                     </DetailPage.FooterActions>
                  </DetailPage.Footer>
               )}
            </Spinner>
         )}
      </Form>
   );
};

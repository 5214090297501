import template from "./totals-config-pane.pug"
import ko from "knockout"
import { authManager } from "@/lib/managers/auth-manager"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

export class TotalsConfigPane extends PopupPane
   constructor: (selections, applyCallback) ->
      super(template())

      @resourcesGroupFilterFlag = ko.pureComputed(() => LaunchDarklyBrowser.getFlagValue("totals-job-titles-group-filter"))

      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
      @enableOverflow = ko.observable(true)
      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Reset Defaults"
      })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
      })

      @applyCallback = applyCallback

      @primaryViewByOptions = [
         new SegmentedControllerItem("Job Title", "job-title")
         new SegmentedControllerItem("Project", "project")
      ]
      @selectedPrimaryViewBy = ko.observable()
      for option in @primaryViewByOptions
         if option.value() == selections.viewBy
            @selectedPrimaryViewBy(option)
            break
      @selectedPrimaryViewBy(@primaryViewByOptions[0]) unless @selectedPrimaryViewBy()?

      @totalUnitOptions = [
         new SegmentedControllerItem("People", "people")
         new SegmentedControllerItem("Man-Days", "man-days")
         new SegmentedControllerItem("Hours", "hours")
      ]

      if @canViewProjectFinancials
         @totalUnitOptions.push(new SegmentedControllerItem("Cost", "cost"))

      @selectedTotalUnit = ko.observable()
      for option in @totalUnitOptions
         if option.value() == selections.totalsUnits
            @selectedTotalUnit(option)
            break
      @selectedTotalUnit(@totalUnitOptions[0]) unless @selectedTotalUnit()

      @projectSortOptions = [
         new DropDownItem("Name", "name")
         new DropDownItem("Project Number", "project-number")
         new DropDownItem("Earliest Start Date", "earliest-start-date")
         new DropDownItem("Earliest End Date", "earliest-end-date")
      ]
      @selectedProjectSort = ko.observable()
      for option in @projectSortOptions
         if option.value() == selections.projectSort
            @selectedProjectSort(option)
            break
      @selectedProjectSort(@projectSortOptions[0]) unless @selectedProjectSort()?

      @showJobNumbers = ko.observable(Boolean(selections.showJobNumbers))
      @showCategories = ko.observable(Boolean(selections.showCategories))
      @showSubCategories = ko.observable(Boolean(selections.showSubCategories))
      @includeResourcesOutsideGroup = ko.observable(Boolean(selections.includeResourcesOutsideGroup))

   closePopup: =>
      @dismissHandler()

   applyChanges: =>
      data = {
         viewBy: @selectedPrimaryViewBy().value()
         totalsUnits: @selectedTotalUnit().value()
         showJobNumbers: @showJobNumbers()
         showCategories: @showCategories()
         showSubCategories: @showSubCategories()
         includeResourcesOutsideGroup: @includeResourcesOutsideGroup()
         projectSort: @selectedProjectSort().value()
      }
      @applyCallback(data)
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @applyCallback(null)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)



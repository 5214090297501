import "./person-type-editor.styl";
import type { Observable, Subscription } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./person-type-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";
import type { PermissionOption } from "@/lib/components/drop-downs/panes/permission-drop-down-pane";
import { PermissionDropDownPane } from "@/lib/components/drop-downs/panes/permission-drop-down-pane";
import { TextCell } from "@/lib/components/grid/cells/text-cell";

export type PersonTypeValue = {
   isAssignable: boolean;
   isUser: boolean;
   hasEmail: boolean;
   permission: PermissionOption | null;
};

export type PersonTypeEditorParams = EditorComponentParams<PersonTypeValue>;

export class PersonTypeEditor extends AbstractFieldEditor<PersonTypeValue> {
   readonly isAssignable: Observable<boolean>;
   readonly isUser: Observable<boolean>;
   readonly hasEmail: boolean;
   readonly selectedPermission: Observable<PermissionOption | null>;
   readonly permissionDropDownPane = new PermissionDropDownPane();
   readonly cellFactory = TextCell.factory<PermissionOption>((option) => option.name);

   readonly hasChanged = pureComputed(() => {
      return (
         this.value().isAssignable !== this.initialValue.isAssignable ||
         this.value().isUser !== this.initialValue.isUser ||
         this.value().hasEmail !== this.initialValue.hasEmail ||
         this.value().permission !== this.initialValue.permission
      );
   });

   private readonly subscriptions: Subscription[] = [];

   constructor(params: PersonTypeEditorParams) {
      super(params, observable(unwrap(params.value)));
      this.isAssignable = observable(unwrap(params.value).isAssignable);
      this.isUser = observable(unwrap(params.value).isUser);
      this.hasEmail = unwrap(params.value).hasEmail;
      this.selectedPermission = observable(unwrap(params.value).permission);

      this.subscriptions.push(
         this.isAssignable.subscribe(this.onValueDependencyChanged, this),
         this.isUser.subscribe(this.onValueDependencyChanged, this),
         this.selectedPermission.subscribe(this.onValueDependencyChanged, this),
      );
   }

   validate(value: PersonTypeValue): ValidatedValue<PersonTypeValue> {
      if (!this.isAssignable() && !this.isUser()) {
         return { valid: false, error: "At least one type is required." };
      }
      if (this.isUser() && !this.hasEmail) {
         return { valid: false, error: "Email is required to become a user." };
      }
      if (this.isUser() && !this.selectedPermission()) {
         return { valid: false, error: "Permission is required to become a user." };
      }
      return { valid: true, value };
   }

   private onValueDependencyChanged() {
      this.value({
         isAssignable: this.isAssignable(),
         isUser: this.isUser(),
         hasEmail: this.hasEmail,
         permission: this.isUser() ? this.selectedPermission() : null,
      });
   }

   static factory<T>(
      provider: (records: T[]) => PersonTypeEditorParams,
   ): (records: T[]) => ComponentArgs<PersonTypeEditorParams> {
      return (records) => ({
         name: "person-type-editor",
         params: provider(records),
      });
   }
}

ko.components.register("person-type-editor", {
   viewModel: PersonTypeEditor,
   template: template(),
   synchronous: true,
});

import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { StreamedUpdate } from "@laborchart-modules/lc-core-api/dist/api/streams";
import type {
   GetTimeOffResponse,
   GetTimeOffForPersonResponse,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/get-time-off";
import type {
   UpdateSingleTimeOffPayload,
   UpdateSingleTimeOffResponse,
   UpdateTimeOffPayload,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/update-time-off";
import type {
   CreateTimeOffPayload,
   CreateTimeOffResponse,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/create-time-off";
import type {
   BatchDeleteTimeOffPayload,
   DeleteTimeOffResponse,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/delete-time-off";
import type {
   FindTimeOffPaginatedQueryParams,
   FindTimeOffPaginatedResponse,
   FindTimeOffStreamQueryParams,
   SerializedFindTimeOff,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";

export abstract class TimeOffStore extends Store {
   static getTimeOffForPerson(personId: string): StoreJsonResponse<GetTimeOffForPersonResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/time-off`,
         method: "GET",
      });
   }

   static getTimeOff(tagId: string): StoreJsonResponse<GetTimeOffResponse> {
      return this.requestJson({
         url: `/api/v3/time-off/${tagId}`,
         method: "GET",
      });
   }

   static updateTimeOffStream(
      updates: UpdateTimeOffPayload[],
   ): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/time-off/stream",
         method: "PATCH",
         body: updates,
      });
   }

   static findTimeOffStream(
      query: FindTimeOffStreamQueryParams,
   ): StoreStreamResponse<SerializedFindTimeOff> {
      return this.requestStream({
         url: "/api/v3/time-off/stream",
         method: "GET",
         query,
      });
   }

   static findTimeOffPaginated(
      query: FindTimeOffPaginatedQueryParams,
   ): StoreJsonResponse<FindTimeOffPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/time-off/",
         method: "GET",
         query,
      });
   }

   static deleteTimeOff(timeOffId: string): StoreJsonResponse<DeleteTimeOffResponse> {
      return this.requestJson({
         url: `/api/v3/time-off/${timeOffId}`,
         method: "DELETE",
      });
   }

   static batchDelete(updates: BatchDeleteTimeOffPayload): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/time-off/batch-delete",
         method: "POST",
         body: updates,
      });
   }

   static createTimeOff(
      timeOffData: CreateTimeOffPayload,
   ): StoreJsonResponse<CreateTimeOffResponse> {
      return this.requestJson({
         url: `/api/v3/time-off/`,
         method: "POST",
         body: timeOffData,
      });
   }

   static deleteOccurrence(timeOffId: string, occurrenceId: string): any {
      return this.requestJson({
         url: `/api/v3/time-off/${timeOffId}/instance/${occurrenceId}`,
         method: "DELETE",
      });
   }

   static updateTimeOff(
      timeOffId: string,
      timeOffData: UpdateSingleTimeOffPayload,
   ): StoreJsonResponse<UpdateSingleTimeOffResponse> {
      return this.requestJson({
         url: `/api/v3/time-off/${timeOffId}`,
         method: "PATCH",
         body: timeOffData,
      });
   }
}

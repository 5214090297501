import { ValueSet } from "@/models/value-set";
import { Store } from "@/stores/common/store.core";
import { Assignment2Store } from "./assignment-2-store.core";
import { PersonStore } from "./person-store.core";
import { ProjectStore } from "./project-store.core";
import { StatusStore } from "./status-store.core";
import { Assignment } from "@/models/assignment";
import type {
   GetAssignmentCreationSupportDataRequest,
   GetAssignmentCreationSupportDataResponse,
} from "@laborchart-modules/lc-core-api/dist/api/legacy/get-assignment-creation-support-data";
import type { AssignmentSupportData } from "./assignment-2-store.core";
import type { StoreJsonResponse } from "./common/store.core";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api";
import type { CreateAssignmentPayload } from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";

export abstract class LegacyStore extends Store {
   static getAssignmentCreationSupportData(
      query: GetAssignmentCreationSupportDataRequest["query"],
   ): StoreJsonResponse<GetAssignmentCreationSupportDataResponse> {
      return this.requestJson({
         url: "/api/v3/assignment-creation-support",
         query,
      });
   }

   // Note: This method is used to format the data returned from getAssignmentCreationSupportData to what the front-end expects.
   static formatAssignmentCreationSupportData(
      data: GetAssignmentCreationSupportDataResponse["data"],
   ): AssignmentSupportData {
      const groupedCostCodeOptions: AssignmentSupportData["groupedCostCodeOptions"] = {};
      for (const key in data.grouped_cost_code_options) {
         groupedCostCodeOptions[key] = data.grouped_cost_code_options[key].map(
            (item) => new ValueSet(item),
         );
      }
      const groupedLabelOptions: AssignmentSupportData["groupedLabelOptions"] = {};
      for (const key in data.grouped_label_options) {
         groupedLabelOptions[key] = data.grouped_label_options[key].map(
            (item) => new ValueSet(item),
         );
      }
      const formattedData: AssignmentSupportData = {
         companyTbdWeeks: data.company_tbd_weeks,
         groupedCostCodeOptions,
         groupedLabelOptions,
         overtimeDayRates: data.overtime_day_rates,
         paidShiftHours: data.paid_shift_hours,
         projectOptions: data.project_options.map((item) => new ValueSet(item)),
         resourceOptions: data.resource_options.map((item) => new ValueSet<string, unknown>(item)),
         statusOptions: data.status_options.map((item) => new ValueSet<string, unknown>(item)),
      };
      return formattedData;
   }

   static async breakoutAssignments({
      newAssignmentsPayload,
      originalAssignment,
      updateData,
   }: {
      newAssignmentsPayload: CreateAssignmentPayload[];
      originalAssignment: SerializedFindAssignment;
      updateData: { start_day: number } | { end_day: number };
   }): Promise<Assignment[]> {
      const peopleIds: Set<string> = new Set();
      const projectIds: Set<string> = new Set();
      const statusIds: Set<string | null> = new Set();
      [...newAssignmentsPayload, originalAssignment].forEach((a) => {
         peopleIds.add(a.resource_id);
         projectIds.add(a.project_id);
         statusIds.add((a as SerializedFindAssignment).status?.id ?? null);
      });
      const [newAssignments, people, projects, statuses, updatedAssignment] = await Promise.all([
         Promise.all(
            newAssignmentsPayload.map((a) =>
               Assignment2Store.createAssignment(a).payload.then((res) => res.data),
            ),
         ),
         Promise.all(
            [...peopleIds].map((id) => PersonStore.getPerson(id).payload.then((res) => res.data)),
         ),
         Promise.all(
            [...projectIds].map((id) =>
               ProjectStore.getProject(id).payload.then((res) => res.data),
            ),
         ),
         Promise.all(
            [...statusIds].flatMap((id) =>
               id != null ? [StatusStore.getStatus(id).payload.then((res) => res.data)] : [],
            ),
         ),
         Assignment2Store.updateSingleAssignment({
            assignmentId: originalAssignment.id,
            update: updateData,
         }).payload.then((res) => res.data),
      ]);

      return [...newAssignments, updatedAssignment].map((asgmt) => {
         const statusId = asgmt.status_id;

         const person = people.find((p) => p.id === asgmt.resource_id)!;
         const project = projects.find((p) => p.id === asgmt.project_id)!;
         const status = statuses?.find((s) => s.id === statusId);
         const category = project.categories.find((c) => c.id === asgmt.category_id);
         const subcategory = category?.subcategories.find((s) => s.id === asgmt.subcategory_id);
         const formattedData = {
            ...asgmt,
            cost_code_id: asgmt.category_id,
            label_id: asgmt.subcategory_id,
            status,
            baggage: {
               // Person.
               person_name: person.name,
               employee_number: person.employee_number,
               hourly_wage: person.hourly_wage,
               profile_pic_url: person.profile_pic_url,
               person_phone: person.phone,
               person_email: person.email,
               person_city: person.city_town,
               person_state: person.state_province,
               person_zipcode: person.zipcode,
               person_group_ids: person.group_ids,
               person_assignable_group_ids: person.assignable_group_ids,

               // Position.
               position_name: person.job_title?.name,
               position_color: person.job_title?.color,
               position_sequence: person.job_title?.sequence,
               position_rate: person.job_title?.hourly_rate,

               // Project.
               project_name: project.name,
               project_color: project.color,
               job_number: project.job_number,
               project_status: project.status,
               group_ids: project.group_ids,

               cost_code_name: category?.name,
               label_name: subcategory?.name,
            },
         };
         return new Assignment(formattedData);
      });
   }
}

import template from "./create-person-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput
import { ValidationUtils as validateEmailParent } from "@/lib/utils/validation"
validateEmail = validateEmailParent.validateEmail

import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { PersonStore } from "@/stores/person-store.core"
import { GroupStore } from "@/stores/group-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { JobTitleDropDownPane } from "@/lib/components/drop-downs/panes/job-title-drop-down-pane"
import { PermissionDropDownPane } from "@/lib/components/drop-downs/panes/permission-drop-down-pane"
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell"
import { TextCell } from "@/lib/components/grid/cells/text-cell"

import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { requestContext } from "@/stores/common/request-context";

export class CreatePersonPaneViewModel extends ModalPane
   constructor: () ->
      super("Create a Person", "Save", template())
      @overflowable(true)
      @usingTypedGroups = authManager.usingTypedGroups()

      # Data fields
      @firstName = ko.observable()
      @lastName = ko.observable()
      @employeeId = ko.observable()
      @phone = ko.observable()
      @phoneIsValid = ko.observable(false)
      # Used to pause while processing for formatting.
      @canSavePhone = ko.observable()
      @smsUpdates = ko.observable(false)
      @email = ko.observable()
      @emailUpdates = ko.observable(false)
      @positionOptions = ko.observableArray()
      @selectedPosition = ko.observable()

      @groupOptions = ko.observableArray()
      @selectedGroupIds = ko.observable(new Set())

      # Typed Group Properties
      @accessGroupsNeeded = ko.observable(false)
      @assignableGroupsNeeded = ko.observable(false)
      @selectedAccessGroupIds = ko.observable(new Set())
      @selectedAssignableGroupIds = ko.observable(new Set())

      @statusOptions = ko.observableArray([
         new SegmentedControllerItem("Active", "active")
         new SegmentedControllerItem("Inactive", "inactive")
      ])
      @selectedStatus = ko.observable(@statusOptions()[0])

      @typeOptions = ko.observableArray([
         new SegmentedControllerItem("User", CreatePersonPaneViewModel.PersonType.USER)
         new SegmentedControllerItem("Assignable", CreatePersonPaneViewModel.PersonType.ASSIGNABLE)
         new SegmentedControllerItem("Both", CreatePersonPaneViewModel.PersonType.BOTH)
      ])
      @selectedPersonType = ko.observable()
      @selectedPersonType.subscribe (newVal) =>
         if newVal.value() == CreatePersonPaneViewModel.PersonType.ASSIGNABLE
            @accessGroupsNeeded(false)
            @assignableGroupsNeeded(true)

         else if newVal.value() == CreatePersonPaneViewModel.PersonType.USER
            @accessGroupsNeeded(true)
            @assignableGroupsNeeded(false)
         
         else if newVal.value() == CreatePersonPaneViewModel.PersonType.BOTH
            @accessGroupsNeeded(true)
            @assignableGroupsNeeded(true)

      @permissionOptions = ko.observableArray()
      @selectedPermission = ko.observable()

      @groupsNeeded = ko.pureComputed =>
         if @selectedPermission() && @selectedPersonType().value() != CreatePersonPaneViewModel.PersonType.ASSIGNABLE
            return !@selectedPermission().is_admin
         return true

      @isUser = ko.pureComputed =>
         if @selectedPersonType()?
            return @selectedPersonType().value() == "user" or @selectedPersonType().value() == "both"
         else
            return false

      # Crazy Click Prevention
      @saveClicked = false

      @jobTitleDropdownParams = null
      @jobTitleDropDownPane = null
      @permissionDropdownParams = null
      @permissionDropDownPane = null


      @accessGroupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=>@groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedAccessGroupIds()
            write: (groups) => @selectedAccessGroupIds(groups)
         })
         textProvider: (group) => group.name,
      });

      @accessGroupDropdownParams = {
         actionInterceptor: @accessGroupDropdownPane.actionInterceptor,
         cellFactory: @accessGroupDropdownPane.cellFactory,
         panes: [@accessGroupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedAccessGroupIds()
            write: (groups) => @selectedAccessGroupIds(groups)
         })
         placeholder: "Select groups"
         isClearable: true
         selectedItemCellFactory: TextCell.factory((group) => group.id),
      };

      @assignableGroupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=>@groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedAssignableGroupIds()
            write: (groups) => @selectedAssignableGroupIds(groups)
         })
         textProvider: (group) => group.name,
      });

      @assignableGroupDropdownParams = {
         actionInterceptor: @assignableGroupDropdownPane.actionInterceptor,
         cellFactory: @assignableGroupDropdownPane.cellFactory,
         panes: [@assignableGroupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedAssignableGroupIds()
            write: (groups) => @selectedAssignableGroupIds(groups)
         })
         placeholder: "Select groups"
         isClearable: true
         selectedItemCellFactory: TextCell.factory((group) => group.id),
      };

      @groupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=>@groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIds()
            write: (groups) => @selectedGroupIds(groups)
         })
         textProvider: (group) => group.name,
      });

      @groupDropdownParams = {
         actionInterceptor: @groupDropdownPane.actionInterceptor,
         cellFactory: @groupDropdownPane.cellFactory,
         panes: [@groupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIds()
            write: (groups) => @selectedGroupIds(groups)
         })
         placeholder: "Select Groups"
         isClearable: true
         selectedItemCellFactory: TextCell.factory((group) => group.id),
      };

      @loadData()

   maybeShowValidationError: (next) =>
      unless validateInput(@firstName())
         @saveClicked = false
         return @displayingNotice(CreatePersonPaneViewModel.Notice.FIRST_NAME)
      unless validateInput(@lastName())
         @saveClicked = false
         return @displayingNotice(CreatePersonPaneViewModel.Notice.LAST_NAME)

      if @groupsNeeded()
         if @usingTypedGroups
            if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.ASSIGNABLE
               unless @selectedAssignableGroupIds().size > 0
                  @saveClicked = false
                  return @displayingNotice(CreatePersonPaneViewModel.Notice.GROUP)
            else if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.USER
               unless @selectedAccessGroupIds().size > 0
                  @saveClicked = false
                  return @displayingNotice(CreatePersonPaneViewModel.Notice.GROUP)
            else if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.BOTH
               unless @selectedAssignableGroupIds().size > 0 and @selectedAccessGroupIds().size > 0
                  @saveClicked = false
                  return @displayingNotice(CreatePersonPaneViewModel.Notice.BOTH_GROUP_TYPES)
         else
            unless @selectedGroupIds().size != 0
               @saveClicked = false
               return @displayingNotice(CreatePersonPaneViewModel.Notice.GROUP)

      unless @selectedPersonType()?
         @saveClicked = false
         return @displayingNotice(CreatePersonPaneViewModel.Notice.TYPE)

      for group from @selectedGroupIds()
         unless group and validateInput(group)
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.GROUP) 

      if @phone()? and !@phoneIsValid()
         @saveClicked = false
         return @displayingNotice(CreatePersonPaneViewModel.Notice.PHONE_ERROR)
      if @smsUpdates()
         unless validateInput(@phone())
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.PHONE_NOTIFICATIONS)
      if @emailUpdates()
         unless validateInput(@email())
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.EMAIL_NOTIFICATIONS)
      if (@selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.USER or @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.BOTH)
         unless validateInput(@email())
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.EMAIL)
         unless validateEmail(@email())
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.INVALID_EMAIL)
         unless @selectedPermission()?
            @saveClicked = false
            return @displayingNotice(CreatePersonPaneViewModel.Notice.PERMISSION)

      @displayingNotice(null)
      next()

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      return if @saveClicked
      @saveClicked = true
      continueSaveExecution = =>
         @maybeShowValidationError =>
            person = {
               name: {
                  first: @firstName().trim()
                  last: @lastName().trim()
               }
            }
            if @groupsNeeded()
               if @usingTypedGroups
                  accessGroupIds = [...@selectedAccessGroupIds()]
                  assignlableGroupIds = [...@selectedAssignableGroupIds()]
                  person['access_group_ids'] = []
                  person['assignable_group_ids'] = []
                  person['group_ids'] = []

                  for id in accessGroupIds
                     person.access_group_ids.push(id)
                     person.group_ids.push(id)

                  for id in assignlableGroupIds
                     person.assignable_group_ids.push(id)
                     unless person.group_ids.indexOf(id) != -1
                        person.group_ids.push(id)
               else
                  person['group_ids'] = [...@selectedGroupIds()]
            else
               person['group_ids'] = []

            # Refactored to make it very explicit
            if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.USER
               person['is_user'] = true
               person['is_assignable'] = false
               person['permission_level_id'] = @selectedPermission().id
            
            if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.BOTH
               person['is_user'] = true
               person['is_assignable'] = true
               person['permission_level_id'] = @selectedPermission().id
            
            if @selectedPersonType().value() == CreatePersonPaneViewModel.PersonType.ASSIGNABLE
               person['is_user'] = false
               person['is_assignable'] = true
               person['permission_level_id'] = null
            person['position_id'] =  @selectedPosition().id if @selectedPosition()?.id?
            person['status'] = @selectedStatus().value() if @selectedStatus()?
            if validateInput(@phone())
               person['phone'] = @phone()
               person['can_recieve_sms'] = @smsUpdates() if @smsUpdates()?
            else
               person['can_recieve_sms'] = false
            if validateInput(@email())
               person['email'] = @email()
               person['can_recieve_email'] = @emailUpdates() if @emailUpdates()?
            else
               person['can_recieve_email'] = false

            person['employee_number'] = @employeeId() if @employeeId()?

            @displayingNotice(CreatePersonPaneViewModel.Notice.ATTEMPTING_TO_SAVE)

            # additional fields required for lc-core-api
            person['address_1'] = null
            person['address_2'] = null
            person['city_town'] = null
            person['country'] = null
            person['dob'] = null
            person['emergency_contact_email'] = null
            person['emergency_contact_name'] = null
            person['emergency_contact_number'] = null
            person['emergency_contact_relation'] = null
            person['hired_date'] = null
            person['hourly_wage'] = null
            person['profile_pic_url'] = null
            person['state_province'] = null
            person['tag_instances'] = []
            person['zipcode'] = null
            person['custom_fields'] = {}
            person['gender'] = null
            person['job_title_id'] = if @selectedPosition()?.id? then @selectedPosition().id else null
            person['notification_profile_id'] = null
            person['created_from_wfp'] = requestContext.usingProcoreHostApp

            try
               response = await PersonStore.createPerson(person).payload
            catch err
               validationMessage = err.validation?[0]?.message
               @saveClicked = false
               if validationMessage == CreatePersonPaneViewModel.CoreErrorMessage.DUPLICATE_EMAIL
                  return @displayingNotice(CreatePersonPaneViewModel.Notice.PERSON_EMAIL_EXIST)
               else
                  Bugsnag.notify(err, (event) =>
                     event['context'] = "create-person-pane__createPerson"
                     event.addMetadata("Person Payload", person)
                     event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
                  )
                  return @displayingNotice(CreatePersonPaneViewModel.Notice.PERSON_GENERIC_ERROR)
                     
            @displayingNotice(null)
            @data.set('person', response.data)
            modalManager.modalFinished()

      if @canSavePhone()
         continueSaveExecution()
      else
         savePhoneBlock = @canSavePhone.subscribe (newVal) =>
            if newVal
               savePhoneBlock.dispose()
               continueSaveExecution()

   loadData: ->
      @jobTitleDropdownParams = {
         panes: [
            new JobTitleDropDownPane({
               transformer: (position) => position,
            })
         ]
         cellFactory: ColorCircleTextCell.factory((item) => {
               text: item.name,
               color: item.color,
            }
         )
         isClearable: true
         placeholder: "Select a job title"
         selectedItem: ko.pureComputed({
            read: () => @selectedPosition()
            write: (position) => 
               @selectedPosition(position)
         })
      }

      @permissionDropdownParams = {
         panes: [
            new PermissionDropDownPane({
               transformer: (permission) => permission,
            })
         ]
         placeholder: "Select a permission level"
         cellFactory: TextCell.factory((permission) => permission.name)
         isClearable: true
         selectedItem: ko.pureComputed({
            read: () => @selectedPermission()
            write: (permission) => 
               @selectedPermission(permission)
         })
      }

      [groups, userGroups] = await Promise.all([
         GroupStore.findGroupsStream().stream,
         authManager.getContextAccessibleGroupIds(),
      ])
      for await row from groups
         if userGroups.has(row.id)
            @groupOptions.push(row)
      
CreatePersonPaneViewModel.Notice = {
   FIRST_NAME: {
      text: 'Team Member must have a "First Name".'
      info: null
      color: 'red'
      dissmissable: true
   }
   LAST_NAME: {
      text: 'Team Member must have a "Last Name".'
      info: null
      color: 'red'
      dissmissable: true
   }
   GROUP: {
      text: 'At least one group must be selected.'
      info: null
      color: 'red'
      dissmissable: true
   }
   BOTH_GROUP_TYPES: {
      text: 'At least one group must be selected for each group type.'
      info: null
      color: 'red'
      dissmissable: true
   }
   PHONE_ERROR: {
      text: 'There is an error with the provided phone number. Please check the error for details.'
      info: null
      color: 'red'
      dissmissable: true
   }
   PHONE_NOTIFICATIONS: {
      text: 'To enable SMS notifications, you need a phone number.'
      info: null
      color: 'red'
      dissmissable: true
   }
   EMAIL_NOTIFICATIONS: {
      text: 'To enable email notifications, you need an email.'
      info: null
      color: 'red'
      dissmissable: true
   }
   TYPE: {
      text: 'Team member must have a "Type".'
      info: null
      color: 'red'
      dissmissable: true
   }
   EMAIL: {
      text: 'User must have an email.'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_EMAIL: {
      text: 'Email must be valid for a User.'
      info: null
      color: 'red'
      dissmissable: true
   }
   PERMISSION: {
      text: 'Permission level must be selected for a User.'
      info: null
      color: 'red'
      dissmissable: true
   }
   ATTEMPTING_TO_SAVE: {
      text: 'Saving New Person...'
      info: null
      color: 'green'
      dissmissable: false
   }
   PERSON_EMAIL_OR_PHONE_EXIST: {
      text: "A person's phone number and email must be unique within your company."
      info: null
      color: 'red'
      dissmissable: true
   }
   PERSON_EMAIL_EXIST: {
      text: "That email is already in use for an existing person."
      info: null
      color: 'red'
      dissmissable: true
   }
   PERSON_PHONE_EXIST: {
      text: "A person's phone must be unique within your company."
      info: null
      color: 'red'
      dissmissable: true
   }
   PERSON_GENERIC_ERROR: {
      text: "There was an error creating this person. Try Again."
      info: null
      color: 'red'
      dissmissable: true
   }
}

CreatePersonPaneViewModel.RecievableError = {
   PERSON_EMAIL_OR_PHONE_DUPE: 'personEmailOrPhoneConflict'
   PERSON_EMAIL_DUPE: 'personEmailDupe'
   PERSON_PHONE_DUPE: 'personPhoneDupe'
}

CreatePersonPaneViewModel.CoreErrorMessage = {
   DUPLICATE_EMAIL: 'Email is already in use'
}

CreatePersonPaneViewModel.PersonType = {
   USER: "user"
   ASSIGNABLE: "assignable"
   BOTH: "both"
}

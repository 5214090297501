import type {
   CreateAssignmentPayload,
   CreateAssignmentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type { StoreJsonResponse } from "./common";
import { Store } from "@/stores/common/store.core";

export abstract class EquipmentAssignmentStore extends Store {
   static createEquipmentAssignment(
      payload: CreateAssignmentPayload,
   ): StoreJsonResponse<CreateAssignmentResponse> {
      return this.requestJson({
         body: payload,
         method: "POST",
         url: "/api/v3/equipment-assignments",
      });
   }
}

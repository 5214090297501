import template from "./position-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { JobTitleDropDownPane } from "@/lib/components/drop-downs/panes/job-title-drop-down-pane";
import type { EditorElementParams } from "../common/editor-element-template";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";

export type PositionEditorElementParams = {
   position: PureComputed<SerializedPosition | null>;
} & EditorElementParams<SerializedPosition | null>;

export class PositionEditorElement extends ViewModel {
   readonly onChange: PositionEditorElementParams["onChange"];
   readonly position: PositionEditorElementParams["position"];
   readonly title: PositionEditorElementParams["title"];

   readonly positionMediator = pureComputed<SerializedPosition | null>({
      read: () => this.position(),
      write: (position) => {
         this.onChange(position);
      },
   });

   private readonly dropDownParams: DropDown2Params<SerializedPosition>;

   constructor({ onChange, position, title = "Needed Job Title" }: PositionEditorElementParams) {
      super(template());
      this.onChange = onChange;
      this.position = position;
      this.title = title;
      this.dropDownParams = {
         cellFactory: ColorCircleTextCell.factory<SerializedPosition>((item) => ({
            text: item.name,
            color: item.color,
         })),
         isClearable: true,
         panes: [
            new JobTitleDropDownPane({
               transformer: (position) => position,
            }) as any,
         ],
         selectedItem: this.positionMediator,
         selectedItemCellFactory: ColorCircleTextCell.factory<SerializedPosition>((item) => ({
            text: item.name,
            color: item.color,
         })),
      };

      this.position.notifySubscribers();
   }

   static factory(params: PositionEditorElementParams): ComponentArgs<PositionEditorElementParams> {
      return {
         name: "position-editor-element",
         params,
      };
   }
}

ko.components.register("position-editor-element", {
   viewModel: PositionEditorElement,
   template: template(),
   synchronous: true,
});

import React, { useCallback, useImperativeHandle } from "react";
import { useProjectTearsheet } from "../tearsheets/project/project-tearsheet";
import { useAssignmentTearsheet } from "../tearsheets/assignment/assignment-tearsheet";
import { useRequestTearsheet } from "../tearsheets/request/request-tearsheet";

interface TearsheetInput {
   projectId: string;
   assignmentId?: string;
   requestId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
}

/**
 * Represents a reference to a Gantt project tearsheet.
 */
export type GanttTearsheetRef = {
   /**
    * Opens the tearsheet for the specified project.
    *
    * @param projectId - The ID of the project to open the tearsheet for.
    * @param assignmentId - The ID of the assignment to open the tearsheet for.
    * @param requestId - The ID of the request to open the tearsheet for.
    * @param categoryId - The ID of the category to open the tearsheet for.
    * @param subcategoryId - The ID of the subcategory to open the tearsheet for.
    * @param callback - The callback to call when the tearsheet is closed.
    */
   handleOpenTearsheet: ({
      assignmentId,
      requestId,
      projectId,
      categoryId,
      subcategoryId,
      callback,
   }: TearsheetInput) => void;
};

export enum GanttTearsheetType {
   ProjectDetail = "project-detail",
   CreateAssignment = "create-assignment",
   CreateRequest = "create-request",
   EditAssignment = "edit-assignment",
   EditRequest = "edit-request",
   SendAssignmentAlerts = "send-assignment-alerts",
}

/**
 * This component is a wrapper around the ProjectTearsheetProvider which allows us
 * render the tearsheet without re-rendering the entire GanttContainer. We can use ProjectTearsheetProvider
 * to render the tearsheet for both the project details tearsheet and the create assignment tearsheet.
 */
const Tearsheet = (tearsheetType: GanttTearsheetType) => {
   return React.forwardRef<GanttTearsheetRef>((_, ref) => {
      let tearsheetDispatch: React.Dispatch<any>;

      switch (tearsheetType) {
         case GanttTearsheetType.ProjectDetail:
            tearsheetDispatch = useProjectTearsheet().dispatch;
            break;
         case GanttTearsheetType.CreateAssignment:
            tearsheetDispatch = useAssignmentTearsheet().dispatch;
            break;
         case GanttTearsheetType.EditAssignment:
            tearsheetDispatch = useAssignmentTearsheet().dispatch;
            break;
         case GanttTearsheetType.SendAssignmentAlerts:
            tearsheetDispatch = useAssignmentTearsheet().dispatch;
            break;
         case GanttTearsheetType.CreateRequest:
            tearsheetDispatch = useRequestTearsheet().dispatch;
            break;
         case GanttTearsheetType.EditRequest:
            tearsheetDispatch = useRequestTearsheet().dispatch;
            break;
         default:
            console.error(`Tearsheet type ${tearsheetType} not found`);
            return null;
      }

      const handleOpenTearsheet = useCallback(
         ({
            assignmentId,
            requestId,
            projectId,
            categoryId,
            subcategoryId,
            callback,
         }: TearsheetInput) => {
            tearsheetDispatch({
               type: `open-${tearsheetType}`,
               assignmentId,
               requestId,
               projectId,
               categoryId,
               subcategoryId,
               callback,
            });
         },
         [tearsheetDispatch],
      );

      useImperativeHandle(ref, () => ({
         handleOpenTearsheet,
      }));

      return null;
   });
};

export const GanttProjectDetailsTearsheet = Tearsheet(GanttTearsheetType.ProjectDetail);

export const GanttCreateAssignmentTearsheet = Tearsheet(GanttTearsheetType.CreateAssignment);

export const GanttSendAssignmentAlertsTearsheet = Tearsheet(
   GanttTearsheetType.SendAssignmentAlerts,
);

export const GanttCreateRequestTearsheet = Tearsheet(GanttTearsheetType.CreateRequest);

export const GanttEditAssignmentTearsheet = Tearsheet(GanttTearsheetType.EditAssignment);

export const GanttEditRequestTearsheet = Tearsheet(GanttTearsheetType.EditRequest);

import template from "./fill-placeholder-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"


export class FillPlaceholderPane extends PopupPane
   constructor: (options, selectCallback) ->
      assertArgs(arguments, Function, Function)
      super(template())

      @resourceOptions = options
      @selectedResource = ko.observable()
      @enableOverflow = ko.observable(true)
      @selectCallback = selectCallback

   handleSelection: (newVal) =>
      @selectCallback(newVal)
      @dismissHandler()


import template from "./status-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import type { StatusOption } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import { StatusDropDownPane } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import type { EditorElementParams } from "../common/editor-element-template";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { authManager } from "@/lib/managers/auth-manager";
import { PermissionLevel } from "@/models/permission-level";

export type StatusEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   status: PureComputed<StatusOption | null>;
} & EditorElementParams<StatusOption | null>;

export class StatusEditorElement extends ViewModel {
   readonly invalidInputMessage: StatusEditorElementParams["invalidInputMessage"];
   readonly onChange: StatusEditorElementParams["onChange"];
   readonly status: StatusEditorElementParams["status"];
   readonly title: StatusEditorElementParams["title"];

   readonly statusMediator = pureComputed<StatusOption | null>({
      read: () => this.status(),
      write: (status) => {
         this.onChange(status);
      },
   });

   private readonly dropDownParams: DropDown2Params<StatusOption>;

   constructor({
      invalidInputMessage,
      onChange,
      status,
      title = "Status",
   }: StatusEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.status = status;
      this.title = title;

      this.dropDownParams = {
         cellFactory: ColorCircleTextCell.factory<StatusOption>((item) => ({
            text: item.name,
            color: item.color,
         })),
         isClearable: authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES),
         panes: [new StatusDropDownPane()],
         selectedItem: this.statusMediator,
         selectedItemCellFactory: ColorCircleTextCell.factory<StatusOption>((item) => ({
            text: item.name,
            color: item.color,
         })),
      };

      this.status.notifySubscribers();
   }

   static factory(params: StatusEditorElementParams): ComponentArgs<StatusEditorElementParams> {
      return {
         name: "status-editor-element",
         params,
      };
   }
}

ko.components.register("status-editor-element", {
   viewModel: StatusEditorElement,
   template: template(),
   synchronous: true,
});

import "./button-drop-down.styl";
import template from "./button-drop-down.pug";
import type { MaybeComputed, MaybeObservable, MaybeObservableArray, Observable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import { measureText } from "@/lib/utils/text-size";
import { ButtonColor, ButtonSize } from "@/lib/utils/buttons";
import type { ComponentArgs } from "@/lib/components/common/component-args";

export type ButtonItemParams = {
   // Standard fields.
   clickedText: MaybeObservable<string>;
   onClick: () => Promise<unknown> | void;
   text: MaybeObservable<string>;
   isDisabled?: MaybeObservable<boolean> | MaybeComputed<boolean>;
   isHidden?: MaybeObservable<boolean> | MaybeComputed<boolean>;

   // Button confirm fields.
   confirmText?: MaybeComputed<string>;
   onCancel?: () => void;
   onConfirming?: () => void;
};

export const enum ButtonState {
   ACTING = "acting",
   CONFIRMING = "confirming",
   READY = "ready",
}

export class ButtonItem {
   readonly clickedText: MaybeObservable<string>;
   readonly isDisabled: MaybeObservable<boolean> | MaybeComputed<boolean>;
   readonly isHidden: MaybeObservable<boolean> | MaybeComputed<boolean>;
   readonly onClick: () => Promise<unknown> | void;
   readonly text: MaybeObservable<string>;

   readonly confirmText?: MaybeComputed<string>;
   readonly onConfirming?: () => void;
   readonly onCancel?: () => void;

   readonly state: Observable<ButtonState>;

   readonly hasConfirmMessage = pureComputed(() => {
      return unwrap(this.confirmText ?? "").length > 0;
   });

   readonly currentText = pureComputed((): string => {
      const defaultText = unwrap(this.text);
      const confirmText = this.confirmText ? unwrap(this.confirmText) : null;
      const clickedText = unwrap(this.clickedText);
      const state = this.state();
      if (state == ButtonState.CONFIRMING && confirmText) {
         return confirmText;
      } else if (state == ButtonState.ACTING && clickedText) {
         return clickedText;
      }
      return defaultText;
   });

   readonly onClickMediator = async (): Promise<void> => {
      if (this.state() == ButtonState.ACTING) return;

      if (!unwrap(this.hasConfirmMessage)) {
         this.state(ButtonState.ACTING);
         await this.onClick();
         this.state(ButtonState.READY);
         return;
      }

      if (this.state() == ButtonState.READY) {
         this.state(ButtonState.CONFIRMING);
         if (this.onConfirming) this.onConfirming();
         setTimeout(() => {
            if (this.state() == ButtonState.CONFIRMING) this.state(ButtonState.READY);
         }, 3000);
      } else if (this.state() == ButtonState.CONFIRMING) {
         this.state(ButtonState.ACTING);
         await this.onClick();
         this.state(ButtonState.READY);
      }
   };

   constructor({
      text,
      clickedText,
      onClick,
      isDisabled = false,
      isHidden = false,
      confirmText,
      onConfirming,
      onCancel,
   }: ButtonItemParams) {
      this.text = text;
      this.confirmText = confirmText;
      this.clickedText = clickedText;
      this.onConfirming = onConfirming;
      this.onCancel = onCancel;
      this.onClick = onClick;
      this.isDisabled = isDisabled;
      this.isHidden = isHidden;
      this.state = observable<ButtonState>(ButtonState.READY);
   }
}

export type ButtonDropDownParams = {
   buttons: MaybeObservableArray<ButtonItem>;
   color?: MaybeObservable<ButtonColor>;
   size?: MaybeObservable<ButtonSize>;
};

export class ButtonDropDown {
   private readonly buttons: MaybeObservableArray<ButtonItem>;
   readonly color: MaybeObservable<ButtonColor>;
   readonly size: MaybeObservable<ButtonSize>;

   readonly isExpanded = observable(false);

   readonly visibleButtons = pureComputed(() => {
      return unwrap(this.buttons).filter((button) => !unwrap(button.isHidden));
   });
   readonly firstVisisbleButton = pureComputed(() => {
      if (this.visibleButtons().length < 1) return null;
      return this.visibleButtons()[0];
   });

   readonly hasAnyVisibleButtons = pureComputed(() => {
      return this.visibleButtons().length !== 0;
   });
   readonly hasOneVisibleButton = pureComputed(() => {
      return this.visibleButtons().length === 1;
   });

   readonly enabledButtons = pureComputed(() => {
      return this.visibleButtons().filter((button) => !unwrap(button.isDisabled));
   });
   readonly firstEnabledButton = pureComputed(() => {
      if (this.enabledButtons().length < 1) return null;
      return this.enabledButtons()[0];
   });
   readonly isFullyDisabled = pureComputed(() => {
      const isFullyDisabled = this.enabledButtons().length == 0;
      // Close drop down if everything becomes disabled.
      if (isFullyDisabled) this.isExpanded(false);
      return isFullyDisabled;
   });

   readonly requiredElementWidth = pureComputed(() => {
      const paddingAmount = 2 * this.paddingAmount().horizontal;
      const expandButtonWidth = 24;

      const width = this.visibleButtons().reduce((acc, button, index) => {
         const textWidth = measureText({
            text: unwrap(button.currentText),
         }).width;
         const curWidth = index === 0 ? textWidth + expandButtonWidth : textWidth;
         return Math.max(acc, curWidth);
      }, 0);

      return `${paddingAmount + width}px`;
   });

   readonly paddingAmount = pureComputed(() => {
      const size = unwrap(this.size);
      if (unwrap(this.hasOneVisibleButton)) {
         return size == ButtonSize.LARGE
            ? { horizontal: 5, vertical: 5 }
            : size == ButtonSize.MEDIUM
            ? { horizontal: 4, vertical: 4 }
            : size == ButtonSize.SMALL
            ? { horizontal: 3, vertical: 1 }
            : { horizontal: 4, vertical: 4 };
      } else {
         return size == ButtonSize.LARGE
            ? { horizontal: 10, vertical: 5 }
            : size == ButtonSize.MEDIUM
            ? { horizontal: 8, vertical: 4 }
            : size == ButtonSize.SMALL
            ? { horizontal: 5, vertical: 1 }
            : { horizontal: 8, vertical: 4 };
      }
   });

   readonly buttonClasses = pureComputed(() => {
      const classes = [];
      switch (unwrap(this.color)) {
         case ButtonColor.BLUE:
            classes.push("button-drop-down--color-blue");
            break;
         case ButtonColor.ORANGE:
            classes.push("button-drop-down--color-orange");
            break;
         case ButtonColor.RED:
            classes.push("button-drop-down--color-red");
            break;
         default:
            classes.push("button-drop-down--color-none");
            break;
      }
      classes.push(`button-drop-down--size-${unwrap(this.size)}`);
      return classes.join(" ");
   });

   readonly toggleDropDown = (): void => {
      this.isExpanded(!this.isExpanded());
   };

   constructor({
      buttons,
      color = ButtonColor.WHITE,
      size = ButtonSize.NORMAL,
   }: ButtonDropDownParams) {
      this.buttons = buttons;
      this.color = color;
      this.size = size;
   }

   static factory(params: ButtonDropDownParams): ComponentArgs<ButtonDropDownParams> {
      return {
         name: "button-drop-down",
         params,
      };
   }
}

ko.components.register("button-drop-down", {
   viewModel: ButtonDropDown,
   template: template(),
   synchronous: true,
});

import { Store } from "@/stores/common/store.core";
import type { StoreJsonResponse } from "@/stores/common/store.core";
import { authManager } from "@/lib/managers/auth-manager";
import type { CompanyEntityOptionsResponse } from "@/react/prop-types";
import { EntitiesValues } from "@/react/prop-types";
export abstract class GanttStore extends Store {
   static getGanttCustomFilter(
      groupId: string,
   ): StoreJsonResponse<Partial<CompanyEntityOptionsResponse>> {
      const entitiesQuery = EntitiesValues.map(
         (entity, index) => `entities[${index}]=${entity}`,
      ).join("&");
      return this.requestJson({
         url: `/api/companies/${authManager.companyId()}/groups/${groupId}/entity-options?${entitiesQuery}`,
         method: "GET",
      });
   }
}

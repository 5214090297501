import { Model } from "@/models/model"
import ko from "knockout"
import { args as assertArgs, optional, ofType as assertOfType } from "@/vendor/insist"

export class ColumnHeader extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.key, String)
         assertOfType(data.sequence, nullable(Number))
         assertOfType(data.sortable, Boolean)
         # Opitonal
         assertOfType(data.baseName, optional(String))
         assertOfType(data.name, optional(String))
         assertOfType(data.meta, optional(Object))

      ###------------------------------------
         Knockout Observables
      ------------------------------------### 
      @key = ko.observable(data.key)
      # NOTE: keyId is used for project roles ('positions' column) and is defined
      # within the baggage but stored as 'key_id' in the DB.
      @keyId = ko.observable(data.key_id || data.baggage?.key_id || null)
      @sequence = ko.observable(data.sequence)
      @sortable = ko.observable(data.sortable)
      @baseName = ko.observable(data.baseName || null)
      @name = ko.observable(data.name || null)
      @meta = ko.observable(data.meta)
      @width = ko.observable(data.width or null)
 
      data.sub_properties = [] unless data.sub_properties?
      @subProperties = ko.observableArray data.sub_properties.map (item) ->
         return new SubProperty(item)

export class SubProperty extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         assertOfType(data.name, String)
         assertOfType(data.key, String)
         assertOfType(data.enabled, Boolean)
      @name = data.name
      @key = data.key
      @enabled = ko.observable(data.enabled)
      @width = ko.observable(data.width or null)


ColumnHeader.SubProperty = SubProperty
import "./custom-field-date-editor-element.styl";
import template from "./custom-field-date-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import { pureComputed } from "knockout";
import ko from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { DateInput2Params } from "@/lib/components/date-input-2/date-input-2";
import {
   DayFormat,
   getDetachedDay,
   MonthFormat,
   WeekDayFormat,
   YearFormat,
} from "@laborchart-modules/common/dist/datetime";
import { DateUtils } from "@/lib/utils/date";
import { defaultStore } from "@/stores/default-store";

export type CustomFieldDateEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   isClearable: boolean;
   value: PureComputed<Date | null>;
} & EditorElementParams<number | null>;

export class CustomFieldDateEditorElement extends ViewModel {
   readonly invalidInputMessage: CustomFieldDateEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldDateEditorElementParams["isDisabled"];
   readonly isClearable: CustomFieldDateEditorElementParams["isClearable"];
   readonly onChange: (change: Date | null) => void;
   readonly title: CustomFieldDateEditorElementParams["title"];
   readonly value: CustomFieldDateEditorElementParams["value"];

   private readonly dateInput2Params: DateInput2Params;

   readonly formattedDateText = pureComputed(() => {
      const value = this.value();
      if (value == null) return null;
      return DateUtils.formatDate(value, defaultStore.getDateFormat(), {
         dayFormat: DayFormat.ONE_DIGIT,
         monthFormat: MonthFormat.FULL,
         weekdayFormat: WeekDayFormat.ABBREV,
         yearFormat: YearFormat.FULL,
      });
   });

   constructor({
      invalidInputMessage,
      isDisabled,
      isClearable,
      onChange,
      title,
      value,
   }: CustomFieldDateEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.isClearable = isClearable;
      this.onChange = (change) => {
         onChange(change ? getDetachedDay(change) : null);
      };
      this.title = title;
      this.value = value;
      this.dateInput2Params = {
         date: this.value,
         isDisabled,
         isClearable,
         onDateChange: this.onChange,
      };
   }

   static factory(
      params: CustomFieldDateEditorElementParams,
   ): ComponentArgs<CustomFieldDateEditorElementParams> {
      return {
         name: "custom-field-date-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-date-editor-element", {
   viewModel: CustomFieldDateEditorElement,
   template: template(),
   synchronous: true,
});

import type { RowBase } from "@/lib/components/grid/grid-store";
import "./grid-store-rows-updater-failures-modal-pane.styl";
import template from "./grid-store-rows-updater-failures-modal-pane.pug";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { transformColumnGroups } from "@/lib/components/grid/grid-column-group";
import { ModalPane } from "@/lib/components/modals/modal-pane";
import { ArrayGridStore } from "@/lib/components/grid/array-grid-store";
import { ParagraphCell } from "@/lib/components/grid/cells/paragraph-cell";

type ErrorRow<T> = UpdateFailure<T> & { id: string };

export interface UpdateFailure<T> {
   record: T;
   reason: string;
}

export class GridStoreRowsUpdaterFailuresModalPane<TRow extends RowBase> extends ModalPane {
   readonly store: ArrayGridStore<ErrorRow<TRow>>;
   readonly columnGroups: Array<GridColumnGroup<ErrorRow<TRow>>>;

   constructor({
      updateFailures,
      columnGroups,
   }: {
      updateFailures: Array<UpdateFailure<TRow>>;
      columnGroups: Array<GridColumnGroup<TRow>>;
   }) {
      super("Update Failures", null, template());
      this.store = new ArrayGridStore(
         updateFailures.map((failure) => ({
            ...failure,
            id: failure.record.id,
         })),
      );
      this.columnGroups = this.transformColumnGroups(columnGroups);
   }

   private transformColumnGroups(
      columnGroups: Array<GridColumnGroup<TRow>>,
   ): Array<GridColumnGroup<ErrorRow<TRow>>> {
      return transformColumnGroups({
         columnGroups,
         transform: (row: ErrorRow<TRow>) => row.record,
      }).concat([
         {
            columns: [
               {
                  key: "reason",
                  header: "Failure",
                  width: 100,
                  cellFactory: ParagraphCell.factory((row) => ({
                     text: row.reason,
                     className: "grid-store-rows-updater-modal-pane__failure-cell",
                  })),
                  autoResizable: true,
               },
            ],
         },
      ]);
   }
}

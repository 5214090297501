import React, { useState } from "react";
import { Tabs } from "@procore/core-react";
import type { TabsProps } from "@/react/prop-types";

export const CustomTabs = ({ tabs, activeTab, setActiveTab }: TabsProps) => {
   const [hoveredTab, setHoveredTab] = useState<number | null>(null);

   const handleTabClick = (tabId: number) => {
      setActiveTab(tabId);
   };

   const handleMouseEnter = (tabId: number) => {
      setHoveredTab(tabId);
   };

   const handleMouseLeave = () => {
      setHoveredTab(null);
   };

   return (
      <Tabs>
         {tabs.map((tab) => {
            const isActive = activeTab === tab.id;
            const isHovered = hoveredTab === tab.id;
            //maintaining style for now as we are on older version of core-react to match the given design and will update it once we upgrade the core-react
            const linkStyle = {
               borderBottomColor: isActive ? "#000000" : "",
               fontWeight: isActive ? "600" : "400",
               cursor: "pointer",
               transition: "background-color 0.3s",
               backgroundColor: isHovered ? "#EFEFEF" : "",
               borderRadius: "4px 4px 0px 0px",
            };

            if (tab.visible) {
               return (
                  <Tabs.Tab key={tab.id} onClick={() => handleTabClick(tab.id)} active={isActive}>
                     <Tabs.Link
                        style={linkStyle}
                        onMouseEnter={() => handleMouseEnter(tab.id)}
                        onMouseLeave={handleMouseLeave}
                     >
                        {tab.label}
                     </Tabs.Link>
                  </Tabs.Tab>
               );
            }
         })}
      </Tabs>
   );
};

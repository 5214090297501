/**
 * Returns index of the search key, if it is contained in the array within the specified range;
 * otherwise, (-(insertion point) - 1). The insertion point is defined as the point at which the
 * key would be inserted into the array: the index of the first element in the range greater than
 * the key, or toIndex if all elements in the range are less than the specified key. Note that this
 * guarantees that the return value will be >= 0 if and only if the key is found.
 *
 * NOTE: Ripped straight from Java standard library.
 */
export function binarySearch<T, V = T>(
   sortedItems: T[],
   value: V,
   comparator: (item: T, value: V) => number,
): number {
   let low = 0;
   let high = sortedItems.length - 1;

   while (low <= high) {
      const mid = (low + high) >>> 1;
      const comparison = comparator(sortedItems[mid], value);
      if (comparison < 0) {
         low = mid + 1;
      } else if (comparison > 0) {
         high = mid - 1;
      } else {
         return mid; // key found
      }
   }
   // Key not found. Return the insertion point.
   return -(low + 1);
}

/** Groups values in an array that are defined as adjacent. */
export function groupAdjacent<T>(
   array: T[],
   isAdjacentProvider: (current: T, next: T) => boolean,
): T[][] {
   return array.reduce((acc, item) => {
      if (acc.length) {
         const latestGroup = acc[acc.length - 1];
         if (isAdjacentProvider(latestGroup[latestGroup.length - 1], item)) {
            return acc.slice(0, acc.length - 1).concat([latestGroup.concat(item)]);
         }
      }
      return acc.concat([[item]]);
   }, [] as T[][]);
}

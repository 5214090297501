import { Store } from "@/stores/common/store.core";
import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";
import type { GetTagResponse } from "@laborchart-modules/lc-core-api/dist/api/tags/get-tag";
import type {
   CreateTagPayload,
   CreateTagResponse,
} from "@laborchart-modules/lc-core-api/dist/api/tags/create-tag";
import type {
   FindTagsPaginatedQueryParams,
   FindTagsPaginatedResponse,
   FindTagsStreamQueryParams,
} from "@laborchart-modules/lc-core-api/dist/api/tags/find-tags";
import type {
   UpdateTagPayload,
   UpdateTagResponse,
} from "@laborchart-modules/lc-core-api/dist/api/tags/update-tag";
import type { DeleteTagResponse } from "@laborchart-modules/lc-core-api/dist/api/tags/delete-tag";

export abstract class TagStore extends Store {
   static createTag(tag: CreateTagPayload): StoreJsonResponse<CreateTagResponse> {
      return this.requestJson({
         url: "/api/v3/tags",
         method: "POST",
         body: tag,
      });
   }

   static updateTag(tagId: string, update: UpdateTagPayload): StoreJsonResponse<UpdateTagResponse> {
      return this.requestJson({
         url: `/api/v3/tags/${tagId}`,
         method: "PATCH",
         body: update,
      });
   }

   static deleteTag(tagId: string): StoreJsonResponse<DeleteTagResponse> {
      return this.requestJson({
         url: `/api/v3/tags/${tagId}`,
         method: "DELETE",
      });
   }

   static getTag(tagId: string): StoreJsonResponse<GetTagResponse> {
      return this.requestJson({
         url: `/api/v3/tags/${tagId}`,
         method: "GET",
      });
   }

   static findTagsPaginated(
      query: FindTagsPaginatedQueryParams,
   ): StoreJsonResponse<FindTagsPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/tags",
         method: "GET",
         query,
      });
   }

   static findTagsStream(query: FindTagsStreamQueryParams): StoreStreamResponse<SerializedTag> {
      return this.requestStream({
         url: "/api/v3/tags/stream",
         method: "GET",
         query,
      });
   }
}

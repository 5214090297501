import { Guid as GUID } from "@/lib/utils/guid";
import { Store } from "./common/store.core";

import { FanoutManager } from "@/lib/managers/fanout-manager";
import { fanoutManager } from "@/lib/managers/fanout-manager";
import { downloadFile } from "@/lib/reports/report-options";

import type { StoreJsonResponse } from "./common/store.core";
import type { RequestsListReportPayload } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/requests-list-report";
import type { CreateRequestsReportResponse } from "@laborchart-modules/lc-core-api/dist/api/requests/create-requests-report";
import type { GetGeneratedReportResponse } from "@laborchart-modules/lc-core-api/dist/api/generated-report/get-generated-report";
import type { GetGeneratedReportsResponse } from "@laborchart-modules/lc-core-api/dist/api/generated-report/get-generated-reports";
import type { DeleteGeneratedReportResponse } from "@laborchart-modules/lc-core-api/dist/api/generated-report/delete-generated-report";

export abstract class GeneratedReportStore extends Store {
   static create(
      reportTargetPath: string,
      data: RequestsListReportPayload,
   ): StoreJsonResponse<CreateRequestsReportResponse> {
      return this.requestJson({
         url: `/api/v3/${reportTargetPath}`,
         method: "POST",
         body: data,
      });
   }

   static get(reportId: string): StoreJsonResponse<GetGeneratedReportResponse> {
      return this.requestJson({
         url: `/api/v3/generated-report/${reportId}`,
         method: "GET",
      });
   }

   static search({
      groupId,
      limit,
      skip,
   }: {
      groupId: string;
      limit: number;
      skip: number;
   }): StoreJsonResponse<GetGeneratedReportsResponse> {
      return this.requestJson({
         url: `/api/v3/group/${groupId}/generated-reports`,
         method: "GET",
         query: { limit, skip },
      });
   }

   static delete(reportId: string): StoreJsonResponse<DeleteGeneratedReportResponse> {
      return this.requestJson({
         url: `/api/v3/generated-report/${reportId}`,
         method: "DELETE",
      });
   }

   static subscribeToGeneratedReportStatus = (
      reportId: string,
      { listenerNamespace = GUID() }: { listenerNamespace?: string } = {},
      callback: any,
   ): string => {
      fanoutManager.getSubscription(
         null,
         listenerNamespace,
         FanoutManager.Channel.GENERATED_REPORT,
         { generatedReportId: reportId },
         (message: any) => callback(null, message.data.report_status, message.data.progress),
      );
      return listenerNamespace;
   };

   static download(reportId: string): Promise<void> {
      return downloadFile(`/api/v3/generated-report/${reportId}/download`);
   }
}

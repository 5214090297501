import { Store } from "@/stores/common/store.core";
import type { StoreJsonResponse } from "@/stores/common/store.core";
import type {
   GetProjectTotalsOptions,
   GetProjectTotalsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/totals/get-project-totals";
import type {
   GetTotalsOptions,
   GetTotalsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/totals/get-totals";

export abstract class TotalsStore extends Store {
   static getProjectTotals(
      project_id: string,
      options: GetProjectTotalsOptions,
   ): StoreJsonResponse<GetProjectTotalsResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${project_id}/totals`,
         method: "GET",
         query: options,
      });
   }

   static getTotals(options: GetTotalsOptions): StoreJsonResponse<GetTotalsResponse> {
      return this.requestJson({
         url: `/api/v3/totals`,
         method: "POST",
         body: options,
      });
   }
}

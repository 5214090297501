import {
   Box,
   FlexList,
   Title,
   H2,
   Button,
   Select,
   Typography,
   Grid,
   Avatar,
   useI18nContext,
} from "@procore/core-react";
import React from "react";
import { getInitials, formatFullDateTime } from "../project/helpers";
import classnames from "classnames";
import styles from "../project/project-detail-cards/project-details.scss";
import type { ActivitiesResponseProps } from "../types";
import type { ActivityListProps, SortControlsProps } from "./types";
const cx = classnames.bind(styles);

const ActivityList: React.FC<ActivityListProps> = ({
   title,
   subtext,
   sortActivitiesOptions,
   sortActivitiesSpecificOption,
   onActivitiesSortChange,
   sortOptions,
   sortOption,
   onSortChange,
   activities,
   renderActivityContent,
   loadMoreHandler,
   limit,
   loadMoreLabel,
}) => {
   const I18n = useI18nContext();
   return (
      <React.Fragment>
         <Box padding="lg">
            <FlexList justifyContent="space-between">
               <Title>
                  <Title.Text>
                     <H2>{I18n.t(title)}</H2>
                  </Title.Text>
                  <Title.Subtext>{I18n.t(subtext)}</Title.Subtext>
               </Title>
            </FlexList>
         </Box>

         <SortControls
            sortActivitiesOptions={sortActivitiesOptions}
            selectedActivityOption={sortActivitiesSpecificOption}
            onActivitiesSortChange={onActivitiesSortChange}
            sortOptions={sortOptions}
            selectedSortOption={sortOption}
            onSortChange={onSortChange}
         />

         <Box>
            {activities.map((activity) => (
               <ActivityCard
                  key={activity.id}
                  activity={activity}
                  renderActivityContent={renderActivityContent}
               />
            ))}
         </Box>

         {activities.length < limit ? (
            <Box display="flex" alignItems="center" justifyContent="center" padding="md">
               <Button variant="secondary" onClick={loadMoreHandler}>
                  {I18n.t(loadMoreLabel)}
               </Button>
            </Box>
         ) : (
            <Box padding="xs"></Box>
         )}
      </React.Fragment>
   );
};

const SortControls: React.FC<SortControlsProps> = ({
   sortActivitiesOptions,
   selectedActivityOption,
   onActivitiesSortChange,
   sortOptions,
   selectedSortOption,
   onSortChange,
}) => {
   const I18n = useI18nContext();
   return (
      <Box display="flex" alignItems="center" padding="md">
         <Select
            label={selectedActivityOption.label}
            onSelect={(option) => onActivitiesSortChange(option.item)}
            style={{ marginRight: "10px" }}
         >
            {sortActivitiesOptions.map(
               (option) =>
                  option.show && (
                     <Select.Option
                        key={option.id}
                        value={option}
                        selected={option.id === selectedActivityOption.id}
                     >
                        {option.label}
                     </Select.Option>
                  ),
            )}
         </Select>
         <Typography intent="label" style={{ marginRight: "10px" }}>
            {I18n.t("views.company.workforce_planning.projects.sort_by")}
         </Typography>
         <Select label={selectedSortOption.label} onSelect={(option) => onSortChange(option.item)}>
            {sortOptions.map((option) => (
               <Select.Option
                  key={option.id}
                  value={option}
                  selected={option.id === selectedSortOption.id}
               >
                  {option.label}
               </Select.Option>
            ))}
         </Select>
      </Box>
   );
};

interface ActivityCardProps {
   activity: ActivitiesResponseProps;
   renderActivityContent: (activity: ActivitiesResponseProps) => React.ReactNode;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity, renderActivityContent }) => (
   <Box key={activity.id} padding="md" marginBottom="sm" className={cx("custom-card")}>
      <Grid>
         <Grid.Row>
            <Grid.Col style={{ display: "flex", alignItems: "center" }}>
               <Avatar style={{ marginRight: "8px" }}>
                  <Avatar.Label>
                     {getInitials(`${activity?.author_name?.first} ${activity.author_name?.last}`)}
                  </Avatar.Label>
               </Avatar>
               <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography intent="h3" style={{ marginRight: "8px" }}>
                     {`${activity?.author_name?.first} ${activity.author_name?.last}`}
                  </Typography>
                  <Typography
                     intent="small"
                     color="gray50"
                     style={{ fontWeight: 300, marginTop: "3px" }}
                  >
                     {`${formatFullDateTime(activity?.created_at)}`}
                  </Typography>
               </div>
            </Grid.Col>
         </Grid.Row>
         <Grid.Row>
            <Grid.Col>{renderActivityContent(activity)}</Grid.Col>
         </Grid.Row>
      </Grid>
   </Box>
);

export default ActivityList;

import { Form, useI18nContext } from "@procore/core-react";
import React, { useEffect, useState } from "react";

type CustomFilterComponentPropTypes = {
   onChange: (newValues: any) => void;
   value?: any[];
};

export const TextFilter = (props: CustomFilterComponentPropTypes) => {
   const I18n = useI18nContext();
   const [inputValue, setInputValue] = useState<string>("");

   useEffect(() => {
      const value = props.value ? props.value.map((x: { value: string }) => x.value) : [];
      if (value.length > 0) {
         setInputValue(value[0]);
      } else {
         setInputValue("");
      }
   }, [props.value]);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value) {
         setInputValue(value);
         const onChangeParam = [
            {
               value,
               label: value,
            },
         ];
         props.onChange(onChangeParam);
      } else {
         setInputValue("");
         props.onChange([]);
      }
   };
   return (
      <Form>
         <Form.Row>
            <Form.Text
               name="inputVal"
               colStart={1}
               colWidth={12}
               value={inputValue}
               onChange={handleChange}
               placeholder={I18n.t("views.company.workforce_planning.filters.value_placeholder")}
               style={{ marginTop: "-15px" }}
            />
         </Form.Row>
      </Form>
   );
};

import type { AssignmentFormValues, OverTimeRates, PaySplitRates } from "./types";
import { commonInitialValuesForRequestAndAssignment } from "../constants";

export const formInitialValues = (
   projectId?: string,
   categoryId?: string,
   subcategoryId?: string,
): AssignmentFormValues => {
   return {
      ...commonInitialValuesForRequestAndAssignment,
      equipment: null,
      project: projectId
         ? {
              id: projectId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
      category: categoryId
         ? {
              id: categoryId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
      subcategory: subcategoryId
         ? {
              id: subcategoryId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
      overtime: false,
      overtime_rates: DEFAULT_OVERTIME_RATES,
      pay_split: DEFAULT_PAY_SPLIT_RATES,
   };
};

export const DEFAULT_OVERTIME_RATES: OverTimeRates = {
   sunday: 1,
   monday: 1,
   tuesday: 1,
   wednesday: 1,
   thursday: 1,
   friday: 1,
   saturday: 1,
};

export const DEFAULT_PAY_SPLIT_RATES: PaySplitRates = {
   overtime: 0,
   straight: 0,
   unpaid: 0,
};

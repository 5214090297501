import type { useI18nContext } from "@procore/core-react";
import { object } from "yup";
import { getBaseSchemaForRequestAndAssignment } from "../validation-schema";

export const requestSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   includeStatus: boolean,
   selectedWorkDays: { [key: string]: boolean },
   assignmentByAllocation: boolean,
   assignmentByTime: boolean,
   includeQuantity: boolean,
) => {
   const baseSchema = getBaseSchemaForRequestAndAssignment(
      I18n,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
      includeStatus,
   );

   return object().shape({
      resource: object().nullable(),
      job_title: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      ...(includeQuantity && {
         quantity: object().required(
            I18n.t("views.company.workforce_planning.validations.required_field"),
         ),
      }),
      ...baseSchema,
   });
};

import "./fill-request-cell.styl";
import type { GridActionProvider, GridActionProviderParams, GridCellFactory } from "../grid-column";
import { GridActionResult, GridActionSource, GridCursorState } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./fill-request-cell.pug";
import ko from "knockout";

export class FillRequestCell {
   constructor() {}

   static factory<T>(): GridCellFactory<T, FillRequestCell> {
      return (): GridCell<FillRequestCell> => {
         return {
            component: {
               name: "fill-request-cell",
               params: {},
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(): {
      cellFactory: GridCellFactory<T>;
      cursorStateProvider: () => GridCursorState.ACTIONABLE;
      actionProvider: GridActionProvider<T>;
   } {
      return {
         cellFactory: FillRequestCell.factory(),
         cursorStateProvider: () => GridCursorState.ACTIONABLE,
         actionProvider: ({ source }: GridActionProviderParams<T>) => {
            if (
               source === GridActionSource.ENTER_KEY ||
               source === GridActionSource.UNFOCUSED_CLICK ||
               source === GridActionSource.FOCUSED_CLICK
            ) {
               return GridActionResult.SHOW_EDITOR;
            }

            return GridActionResult.REMAIN_FOCUSED;
         },
      };
   }
}

ko.components.register("fill-request-cell", {
   viewModel: FillRequestCell,
   template: template(),
   synchronous: true,
});

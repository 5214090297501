import ko from "knockout"
import { Model } from "@/models/model"
import { Person } from "@/models/person"

export class Message extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.sent_at, nullable(Number))
         assertOfType(data.send_at, nullable(Number))
         assertOfType(data.content, String)
         assertOfType(data.status, String)
         assertOfType(data.sender, optional(nullable(Object)))
         assertOfType(data.error_cause, nullable(String))
         assertOfType(data.conversation_id, String)
         assertOfType(data.include_signature, Boolean)
         assertOfType(data.read, optional(nullable(Boolean)))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id
      @conversationId = data.conversation_id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @createdAt = ko.observable(data.created_at)
      @sentAt = ko.observable(data.sent_at)
      @sendAt = ko.observable(data.send_at)
      @content = ko.observable(data.content)
      @status = ko.observable(data.status)
      # We are forcing disabling types here to allow users with tags to not have to merge
      # the data to pass validation.
      @sender = ko.observable if data.sender? then new Person(data.sender, true) else null
      @errorCause = ko.observable(data.error_cause)
      @includeSignature = ko.observable(data.include_signature)
      @read = ko.observable(data.read)


Message.Type = {
   OUTGOING: 'outgoing'
   INCOMING: 'incoming'
   ASSIGNMENT_NOTIFICATION: 'assignment-notification'
}

Message.Status = {
   PENDING: "pending",
   SENT: "sent",
   FAILED: "failed"
}

Message.ErrorCause = {
   All_MTHODS_FAILED: "all-methods-failed"
   SMS_FAILED: "sms-failed"
   EMAIL_FAILED: "email-failed"
}

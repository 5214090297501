import ko from "knockout"
import "./list-view-exports-pane.styl"
import template from "./list-view-exports-pane.pug"
import { Url as UrlUtils } from "@/lib/utils/url"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

## UI Assets ##
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { ReportOptions } from "@/lib/reports/report-options"

export class ListViewExportsPane extends PopupPane
   constructor: (pdfUrl, csvUrl, isHttpPost, data) ->
      assertArgs(arguments, nullable(String), nullable(String), optional(Boolean), optional(Object))
      super(template())
      # Both options can't be blank.
      @dismissHandler(Popup.ExitStatus.CANCELED) unless pdfUrl? or csvUrl?

      # Popup Properties
      @enableOverflow = ko.observable(true)

      # PDF
      @downloadPdfUrl = ko.observable(pdfUrl)
      @allowPdfDownload = ko.observable(false)

      # CSV
      @downloadCsvUrl = ko.observable(csvUrl)
      @allowCsvDownload = ko.observable(false)

      # Segmented Controller
      @exportOptions = []
      @exportOptions.push(new SegmentedControllerItem("PDF", "pdf")) if pdfUrl?
      @exportOptions.push(new SegmentedControllerItem("CSV", "csv")) if csvUrl?

      @selectedExportOption = ko.observable(@exportOptions[0])

      @orientationOptions = ReportOptions.createOrientationOptions()

      @selectedOrientationOption = ko.observable(@orientationOptions[0])

      @pdfSizeOptions = ReportOptions.createPdfSizeOptions()
      @selectedPDFSizeOption = ko.observable(@pdfSizeOptions[0])

      # To download a report using a HTTP post method
      @isHttpPost = if isHttpPost? then ko.observable(true) else ko.observable(false)
      @payloadForPost = if data then ko.observable(data) else ko.observable(null)

   exportFile: =>
      exportOption = @selectedExportOption().value()
      if exportOption == "csv"
         @allowCsvDownload(true)
      else if exportOption == "pdf"
         width = @selectedPDFSizeOption().value().width
         height = @selectedPDFSizeOption().value().height

         if @selectedOrientationOption().value() == "landscape"
            width = @selectedPDFSizeOption().value().height
            height = @selectedPDFSizeOption().value().width

         if @isHttpPost() == true
            modifiedPayload = @payloadForPost()
            modifiedPayload["paper_size"] = {
                  width: width
                  height: height
            }
            @payloadForPost(modifiedPayload)
         else
            params = {
               paper_size: {
                  width: width
                  height: height
               }
            }
            params = UrlUtils.serializeParams(params)
            @downloadPdfUrl(@downloadPdfUrl() + '&' + params)
         @allowPdfDownload(true)

   handleDownloadComplete: =>
      exportOption = @selectedExportOption().value()
      if exportOption == "csv"
         @allowCsvDownload(false)
         @downloadCsvUrl(null)
      else if exportOption == "pdf"
         @allowPdfDownload(false)
         @downloadPdfUrl(null)

      @isHttpPost(false)
      @payloadForPost(null)
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

import template from "./custom-field-bool-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";

export type CustomFieldBoolEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<boolean | null>;
} & EditorElementParams<boolean | null>;

export class CustomFieldBoolEditorElement extends ViewModel {
   readonly invalidInputMessage: CustomFieldBoolEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldBoolEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldBoolEditorElementParams["onChange"];
   readonly title: CustomFieldBoolEditorElementParams["title"];
   readonly value: CustomFieldBoolEditorElementParams["value"];

   readonly boolFieldMediator = pureComputed<boolean | null>({
      read: () => unwrap(this.value),
      write: (value) => {
         this.onChange(value);
      },
   });

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldBoolEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;
   }

   static factory(
      params: CustomFieldBoolEditorElementParams,
   ): ComponentArgs<CustomFieldBoolEditorElementParams> {
      return {
         name: "custom-field-bool-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-bool-editor-element", {
   viewModel: CustomFieldBoolEditorElement,
   template: template(),
   synchronous: true,
});

import "./request-assignment-instructions-pane.styl";
import template from "./request-assignment-instructions-pane.pug";
import { ViewModel } from "../../../../../vm/viewmodel";
import type { MaybeComputed } from "knockout";
import ko, { unwrap, pureComputed } from "knockout";
import type { CanRequestSize } from "../../../common/sized-modal";
import type { ComponentArgs } from "@/lib/components/common";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { StagedRequest } from "../../request-details-modal";

export type RequestAssignmentInstructionsParams = {
   request: MaybeComputed<SerializedFindRequest | StagedRequest>;
   updateInstructionText: (text: string | null) => void;
} & CanRequestSize;

export class RequestAssignmentInstructions extends ViewModel implements CanRequestSize {
   readonly request: MaybeComputed<SerializedFindRequest | StagedRequest>;
   readonly updateInstructionText: (text: string | null) => void;

   readonly requestSize: CanRequestSize["requestSize"];

   readonly description = `
      The text you add here will be pulled into the Assignment Alert
      received by the person who gets assigned to this Request under
      the heading "Assignment Instructions".
      `;

   readonly placeholderText = "Provide instructions here here...";

   readonly instructionText = pureComputed({
      read: () => unwrap(this.request).instruction_text,
      write: (text: string | null) => {
         this.updateInstructionText(text != null && text.length > 0 ? text : null);
      },
   });

   constructor({
      request,
      updateInstructionText,
      requestSize,
   }: RequestAssignmentInstructionsParams) {
      super(template());
      this.request = request;
      this.requestSize = requestSize;
      this.updateInstructionText = updateInstructionText;
   }

   static factory(
      params: RequestAssignmentInstructionsParams,
   ): ComponentArgs<RequestAssignmentInstructionsParams> {
      return {
         name: "request-assignment-instructions-pane",
         params,
      };
   }
}

ko.components.register("request-assignment-instructions-pane", {
   viewModel: RequestAssignmentInstructions,
   template: template(),
   synchronous: true,
});

/**
 * A component that provides a toast notification for file uploads.
 *
 * @remarks
 * This component is responsible for managing the state of file downloadables and rendering the toast overlay.
 * It also provides a context API for adding and removing toast items.
 */

import React from "react";
import { FileDownloadToastOverlay } from "./overlay";

export interface FileDownloadToastApi {
   addItems: (item: any) => void;
   setVisible: (visible: boolean) => void;
}

const FileDownloadToastContext = React.createContext<FileDownloadToastApi | null>(null);

export const FileDownloadToastProvider = (props: React.PropsWithChildren) => {
   const [items, setItems] = React.useState<any[]>([]);
   const [visible, setVisible] = React.useState<boolean>(false);

   const addItems = (newItems: any[]) => {
      setItems((prevItems) => [...prevItems, ...newItems]);
   };
   const context = React.useMemo(
      () => ({
         addItems,
         setVisible,
      }),
      [setItems, setVisible],
   );

   return (
      <FileDownloadToastContext.Provider value={context}>
         {props.children}
         {visible && <FileDownloadToastOverlay items={items} setVisible={setVisible} />}
      </FileDownloadToastContext.Provider>
   );
};

export function useExportToastContext(): any {
   const context = React.useContext(FileDownloadToastContext);

   if (context === null) {
      console.error(
         "@procore/labs-toast-alert: Your component must be wrapped in ExportsToastProvider",
      );
   }

   return context;
}

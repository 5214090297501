import { ActivityContentFormatter } from "@/lib/utils/activity-content-formatter"
import ko from "knockout"

### Models ###
import { Model } from "@/models/model"

export class Activity extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.author_name, Object)
         assertOfType(data.category, String)
         assertOfType(data.action_type, String)
         assertOfType(data.subject_1_name, [String, Object])
         assertOfType(data.subject_2_name, optional([String, Object]))
         assertOfType(data.subject_3_name, optional([String, Object]))
         assertOfType(data.meta, optional(Object))
         assertOfType(data.subject_1_type, optional(String))
         assertOfType(data.subject_1_id, optional(String))
         assertOfType(data.job_number, optional(String))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      
      @createdAt = ko.observable(data.created_at)
      @authorName = ko.observable("#{data.author_name.first} #{data.author_name.last}")
      @category = ko.observable(data.category)
      @actionType = ko.observable(data.action_type)
      @subject1Name = ko.observable(data.subject_1_name)
      @subject2Name = ko.observable(data.subject_2_name)
      @subject3Name = ko.observable(data.subject_3_name)
      @meta = ko.observable(data.meta)
      @details = ko.observable()
      if data.details? and data.details?.length > 0
         detailObject = {}
         for detailSet in data.details
            detailObject[detailSet.property_name] = detailSet.value
         @details(detailObject)

      @subject1Type = ko.observable(data.subject_1_type)
      @subject1Id = ko.observable(data.subject_1_id)
      @subject2Id = ko.observable(data.subject_2_id)
      @jobNumber = ko.observable(data.job_number)
      @projectGroupIds = ko.observable(data.project_group_ids)
      
      try
         # Any calls to activity formatter need to be after all other properties.
         @content = ActivityContentFormatter.FormatContent(@)
         @metaContent = ActivityContentFormatter.FormatMetaContent(@)
      catch err
         console.log("Activity model error:", err)


Activity.ActivityCategory = {
   PROJECT: "project"
   PROJECT_COST_CODE: "project_cost_code"
   PROJECT_COST_CODE_LABELS: "project_cost_code_labels"
   PROJECT_NOTES: "project_notes"
   PROJECT_TAGS: "project_tags"
   PROJECT_TAGS_ATTACHMENTS: "project_tags_attachments"
   PROJECT_ATTACHMENTS: "project_attachments"
   PROJECT_WAGE_OVERRIDE: "project_wage_override"
   PROJECT_ASSIGNMENTS: "project_assignments"
   PROJECT_FORECASTING: "project_forecasting"
   PROJECT_INFO: "project_info"
   PROJECT_ROLES: "project_roles"
   PROJECT_PLACEHOLDER: "project_placeholder"
   PROJECT_CANNED_ASSIGNMENT_MESSAGE: "project_canned_assignment_message"

   PERSON: "person"
   PERSON_NOTES: "person_notes"
   PERSON_ATTACHMENTS: "person_attachments"
   PERSON_TAGS: "person_tags"
   PERSON_INFO: "person_info"
   PERSON_TIME_OFF: "person_time_off"

   USER: "user"
}

Activity.CategoryIcon = {
   project: "icon-building-white"
   project_cost_code: "icon-cost-code-white"
   project_cost_code_labels: "icon-cost-code-white"
   project_notes: "icon-note-white"
   project_tags: "icon-tag-white"
   project_tags_attachments: "icon-tag-white"
   project_attachments: "icon-attachment-white"
   project_wage_override: "icon-wage-override-white"
   project_forecasting: "icon-forecasting-white"
   project_info: "icon-building-white"
   project_shift: "icon-building-white"
   project_roles: "icon-activity-project-roles-white"
   project_placeholder: "icon-position-placeholder-white"
   project_canned_assignment_message: "icon-paper-plane-white"

   person: "icon-person-white"
   person_assignments: "icon-person-white"
   person_notes: "icon-note-white"
   person_attachments: "icon-attachment-white"
   person_tags: "icon-tag-white"
   person_tags_attachments: "icon-tag-white"
   person_info: "icon-person-white"
   person_time_off: "icon-person-white"
   person_user_lock: "icon-lock-white"

   user: "icon-person"
}

Activity.ActionType = {
   CREATE: "create"
   UPDATE: "update"
   DELETE: "delete"
}

import { Card, Box, FlexList, Title, H2, Form, useI18nContext } from "@procore/core-react";
import React from "react";
import type { CommentsCardProps } from "../types";
import CommentsList from "./comments-list";

const CommentsCard: React.FC<CommentsCardProps> = ({ comments }) => {
   const I18n = useI18nContext();
   return (
      <Card style={{ marginBottom: "10px" }}>
         <Box padding="md">
            <FlexList justifyContent="space-between" marginBottom="sm">
               <Title>
                  <Title.Text>
                     <H2>{I18n.t("views.company.workforce_planning.comments")}</H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t("views.company.workforce_planning.assignment.assignment_comment_text")}
                  </Title.Subtext>
               </Title>
            </FlexList>
            <CommentsList comments={comments} />
            <Form.Form>
               <Form.Row>
                  <Form.TextArea
                     colStart={1}
                     colWidth={12}
                     name="comments"
                     placeholder={I18n.t(
                        "views.company.workforce_planning.new_comment_placeholder",
                     )}
                     label={I18n.t("views.company.workforce_planning.new_comment")}
                  />
               </Form.Row>
            </Form.Form>
         </Box>
      </Card>
   );
};

export default CommentsCard;

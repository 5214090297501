import "./batch-edit-conflict-modal-pane.styl";
import template from "./batch-edit-conflict-modal-pane.pug";
import { ModalPane } from "../modals/modal-pane";
import type { RowBase } from "../grid/grid-store";
import type { GridColumnGroup } from "../grid/grid-column-group";
import { transformColumnGroups } from "../grid/grid-column-group";
import { ArrayGridStore } from "../grid/array-grid-store";
import { SegmentedControllerItem } from "../segmented-controller/segmented-controller";
import type { Observable } from "knockout";
import { observable } from "knockout";
import { ParagraphCell } from "../grid/cells/paragraph-cell";
import { Modal } from "../modals/modal";
import type { ModalExitStatus } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager";
import type { Conflict, ConflictRow } from "../batch-actions/batch-actions";

enum BatchEditModalTab {
   CONFLICTS = "conflicts",
   VALID = "valid",
}

export type BatchEditConflictModalPaneParams<TRow extends RowBase> = {
   records: TRow[];
   conflicts: Array<Conflict<TRow>>;
   columnGroups: Array<GridColumnGroup<TRow>>;
   saveProvider: () => Promise<void>;
};

export class BatchEditConflictModalPane<TRow extends RowBase> extends ModalPane {
   readonly selectedModalTab: Observable<SegmentedControllerItem<BatchEditModalTab>>;
   readonly modalTabs: Array<SegmentedControllerItem<BatchEditModalTab>>;
   readonly conflictsColumnGroups: Array<GridColumnGroup<ConflictRow<TRow>>>;
   readonly conflictsGridStore: ArrayGridStore<ConflictRow<TRow>>;
   readonly hasValidRecords: boolean;

   readonly validColumnGroups: Array<GridColumnGroup<TRow>>;
   readonly validGridStore: ArrayGridStore<TRow>;
   private readonly saveProvider: () => Promise<void>;

   constructor({
      records,
      conflicts,
      columnGroups,
      saveProvider,
   }: BatchEditConflictModalPaneParams<TRow>) {
      super("Conflicts", null, template());
      this.saveProvider = saveProvider;
      this.validColumnGroups = columnGroups;
      this.validGridStore = new ArrayGridStore(
         records.filter((record) => {
            return !conflicts.find((conflict) => conflict.record.id == record.id);
         }),
      );
      this.conflictsColumnGroups = this.transformColumnGroups(columnGroups);
      this.conflictsGridStore = new ArrayGridStore(
         conflicts.map((conflict) => ({
            ...conflict,
            id: conflict.record.id,
         })),
      );

      const conflictCount = this.conflictsGridStore.rows().length;
      const validCount = this.validGridStore.rows().length;
      this.hasValidRecords = validCount > 0;
      this.modalTabs = [
         new SegmentedControllerItem<BatchEditModalTab>(
            `Conflicts (${conflictCount})`,
            BatchEditModalTab.CONFLICTS,
         ),
         new SegmentedControllerItem<BatchEditModalTab>(
            `Valid (${validCount})`,
            BatchEditModalTab.VALID,
         ),
      ];
      this.selectedModalTab = observable(this.modalTabs[0]);
      if (this.hasValidRecords) {
         this.setReadyToSave();
      }
   }

   async actionBtnIntercept(done: () => void): Promise<void> {
      this.saveProvider();
      done();
   }

   private transformColumnGroups(columnGroups: Array<GridColumnGroup<TRow>>) {
      return transformColumnGroups({
         columnGroups,
         transform: (row: ConflictRow<TRow>) => row.record,
      }).concat([
         {
            columns: [
               {
                  key: "reason",
                  header: "Conflict",
                  width: 100,
                  cellFactory: ParagraphCell.factory((row) => ({
                     text: row.reason,
                     className: "batch-edit-conflict-modal__conflict-cell",
                  })),
                  autoResizable: true,
               },
            ],
         },
      ]);
   }

   private setReadyToSave() {
      const validRecordCount = this.validGridStore.rows().length;
      const conflictCount = this.conflictsGridStore.rows().length;
      this.setActionText(`Save ${validRecordCount}/${validRecordCount + conflictCount}`);
      this.isActionEnabled(true);
   }

   static show<TRow extends RowBase>(
      params: BatchEditConflictModalPaneParams<TRow>,
   ): Promise<ModalExitStatus> {
      return new Promise((resolve) => {
         const pane = new BatchEditConflictModalPane<TRow>(params);
         const modal = new Modal();
         modal.setPanes([pane]);
         modalManager.showModal(
            modal,
            null,
            { class: "batch-edit-conflict-modal-pane" },
            (modal, status) => {
               resolve(status);
            },
         );
      });
   }
}

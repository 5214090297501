import * as React from "react";
import { Tearsheet } from "@procore/core-react";
import { RequestTearsheetContent } from "./request-tearsheet-content";

type RequestTearsheetState = OpenState | ClosedState;
type OpenState = { isOpen: true } & (CreateRequest | EditRequest);
type ClosedState = { isOpen: false; show: null };
type CreateRequest = {
   show: "create-request";
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
};
type EditRequest = {
   show: "edit-request";
   requestId: string;
   callback?: (data: any) => void;
};
type CloseAction = { type: "close" };
type CreateRequestAction = {
   type: "open-create-request";
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
};
type EditRequestAction = {
   type: "open-edit-request";
   requestId: string;
   callback?: (data: any) => void;
};

export type RequestTearsheetActions = CloseAction | CreateRequestAction | EditRequestAction;

export const reducer = (
   _state: RequestTearsheetState,
   action: RequestTearsheetActions,
): RequestTearsheetState => {
   switch (action.type) {
      case "open-create-request": {
         return {
            isOpen: true,
            show: "create-request",
            projectId: action.projectId,
            categoryId: action.categoryId,
            subcategoryId: action.subcategoryId,
            callback: action.callback,
         };
      }
      case "open-edit-request": {
         return {
            isOpen: true,
            show: "edit-request",
            requestId: action.requestId,
            callback: action.callback,
         };
      }
      case "close": {
         return {
            isOpen: false,
            show: null,
         };
      }
   }
};

export const initialState: RequestTearsheetState = {
   isOpen: false,
   show: null,
};

const RequestTearsheetContext = React.createContext<
   (RequestTearsheetState & { dispatch: React.Dispatch<RequestTearsheetActions> }) | null
>(null);

export const RequestTearsheetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   const [state, dispatch] = React.useReducer(reducer, initialState);

   const contextValue = React.useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

   return (
      <RequestTearsheetContext.Provider value={contextValue}>
         {children}
         <RequestTearsheet />
      </RequestTearsheetContext.Provider>
   );
};

export const useRequestTearsheet = () => {
   const context = React.useContext(RequestTearsheetContext);

   if (!context) {
      throw Error(
         "'useRequestTearsheet' is supposed to be used in the component that is a child of 'RequestTearsheetProvider'.",
      );
   }

   return context;
};

export const RequestTearsheet: React.FC = () => {
   const state = useRequestTearsheet();
   const isCreateRequestOpen = state.show === "create-request";
   const isEditRequestOpen = state.show === "edit-request";

   const handleClose = () => state.dispatch({ type: "close" });

   return (
      <Tearsheet open={state.isOpen} onClose={handleClose}>
         {isCreateRequestOpen && (
            <RequestTearsheetContent
               onClose={handleClose}
               projectId={state.projectId}
               categoryId={state.categoryId}
               subcategoryId={state.subcategoryId}
               callback={state.callback}
            />
         )}
         {isEditRequestOpen && (
            <RequestTearsheetContent
               onClose={handleClose}
               requestId={state.requestId}
               callback={state.callback}
            />
         )}
      </Tearsheet>
   );
};

export const onCreateRequestClick = (dispatch: React.Dispatch<RequestTearsheetActions>) => {
   dispatch({ type: "open-create-request" });
};

export const onEditRequestClick = (
   dispatch: React.Dispatch<RequestTearsheetActions>,
   requestId: string,
) => {
   dispatch({ type: "open-edit-request", requestId });
};

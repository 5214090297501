import type { AssignmentData, AssignmentWorkDays } from "@/models/assignment";
import "@bryntum/gantt/gantt.stockholm.css";
import moment from "moment-timezone";
import "./gantt-container.css";
import type { GanttProject, RawGanttData, TotalByDay } from "./prop-types";
import { TotalUnitType } from "./prop-types";

type TotalsForProject = Pick<GanttProject, "assignments"> & {
   paid_shift_hours: number;
   totals_by_days: TotalByDay[];
   totals_by_people: TotalByDay[];
   start_date: Date;
   est_end_date: Date;
};

export class GanttTotalsService {
   private _projects: {
      [key: string]: TotalsForProject;
   } = {};

   addProject(
      project: GanttProject,
      people: RawGanttData["people"],
      paidShiftHours: number,
      wageOverrides: GanttProject["wage_overrides"] = [],
   ) {
      // if the project is already calculated and the start and end date are the same, don't recalculate
      if (
         this._projects[project.id] &&
         moment(this._projects[project.id].start_date).isSame(moment(project.start_date)) &&
         moment(this._projects[project.id].est_end_date).isSame(moment(project.est_end_date))
      )
         return;

      const { minDate: minAssignmentStartDate, maxDate: maxAssignmentEndDate } = getMinMaxDates(
         project.assignments,
      );

      let projectStartDate = moment(project.start_date);
      let projectEndDate = moment(project.est_end_date);

      if (minAssignmentStartDate)
         projectStartDate = moment.min(minAssignmentStartDate, projectStartDate);
      if (maxAssignmentEndDate) projectEndDate = moment.max(maxAssignmentEndDate, projectEndDate);

      const totalsByDays = this._calculateTotalsByDay(
         project.assignments,
         people,
         paidShiftHours,
         moment(projectStartDate),
         moment(projectEndDate),
         wageOverrides,
      );

      const totalsByPeople = this._calculateTotalsForPeople(
         project.assignments,
         moment(projectStartDate),
         moment(projectEndDate),
      );

      this._projects[project.id] = {
         assignments: project.assignments,
         paid_shift_hours: paidShiftHours,
         start_date: projectStartDate.toDate(),
         est_end_date: projectEndDate.toDate(),
         totals_by_days: totalsByDays,
         totals_by_people: totalsByPeople,
      };
   }

   getTotalsByUnit(
      projectId: string,
      interval: moment.unitOfTime.Diff = "day",
      unitType: TotalUnitType = TotalUnitType.PEOPLE,
   ): TotalByDay[] {
      switch (unitType) {
         case TotalUnitType.HOURS:
            return this._getTotalsByHours(projectId, interval);
         case TotalUnitType.COST:
            return this._getTotalsByCost(projectId, interval);
         case TotalUnitType.MAN_DAYS:
            return this._getTotalsByManDays(projectId, interval);
         case TotalUnitType.PEOPLE:
         default:
            return this._getTotalsByPeople(projectId, interval);
      }
   }

   private _getTotalsByPeople(projectId: string, interval: moment.unitOfTime.Diff = "day") {
      if (interval === "day") return this._projects[projectId].totals_by_people;

      return this._calculateTotalsForPeople(
         this._projects[projectId].assignments,
         moment(this._projects[projectId].start_date),
         moment(this._projects[projectId].est_end_date),
         interval,
      );
   }

   private _getTotalsByHours(projectId: string, interval: moment.unitOfTime.Diff = "day") {
      return this._groupByInterval(
         this._projects[projectId].totals_by_days,
         interval,
         TotalUnitType.HOURS,
      );
   }

   private _getTotalsByCost(projectId: string, interval: moment.unitOfTime.Diff = "day") {
      return this._groupByInterval(
         this._projects[projectId].totals_by_days,
         interval,
         TotalUnitType.COST,
      );
   }

   private _getTotalsByManDays(projectId: string, interval: moment.unitOfTime.Diff = "day") {
      return this._groupByInterval(
         this._projects[projectId].totals_by_days,
         interval,
         TotalUnitType.MAN_DAYS,
      );
   }

   private _calculateTotalsForPeople(
      assignments: GanttProject["assignments"],
      initialDate: moment.Moment,
      endDate: moment.Moment,
      dateInterval: moment.unitOfTime.Diff = "day",
   ): TotalByDay[] {
      const totalsByInterval: TotalByDay[] = [];
      const intervalSpan = moment(endDate.endOf(dateInterval)).diff(
         moment(initialDate.startOf(dateInterval)),
         dateInterval,
      );

      for (let i = 0; i <= intervalSpan; i++) {
         const weekDay = initialDate.day() as keyof AssignmentWorkDays;
         const resources = new Set<string>(); // Store the number of people working on the current day

         assignments.forEach((assignment: AssignmentData) => {
            if (dateInterval === "day" && !assignment.work_days[weekDay]) return; // Not laboring on this day
            if (
               initialDate.isBetween(
                  assignment.start_day,
                  assignment.end_day,
                  dateInterval,
                  "[]", // Inclusive start and end day
               )
            ) {
               resources.add(assignment.resource_id);
            }
         });

         totalsByInterval.push({
            date: initialDate.toDate(),
            people: resources.size,
         });
         initialDate.add(1, dateInterval);
      }

      return this._groupByInterval(totalsByInterval, dateInterval, TotalUnitType.PEOPLE);
   }

   private _calculateTotalsByDay(
      assignments: GanttProject["assignments"],
      people: RawGanttData["people"],
      paidShiftHours: number,
      initialDate: moment.Moment,
      endDate: moment.Moment,
      wageOverrides: GanttProject["wage_overrides"] = [],
   ): TotalByDay[] {
      const totalsByDays: TotalByDay[] = []; // Store the number of people working on the current day
      const intervalSpan = moment(endDate).diff(moment(initialDate), "day");

      for (let i = 0; i <= intervalSpan; i++) {
         const weekDay = initialDate.day() as keyof AssignmentWorkDays;

         let totalHours = 0;
         let totalCost = 0;
         assignments.forEach((assignment: AssignmentData) => {
            if (!assignment.work_days[weekDay]) return; // Not laboring on this day

            if (
               initialDate.isBetween(
                  assignment.start_day,
                  assignment.end_day,
                  "day",
                  "[]", // Inclusive start and end day
               )
            ) {
               const resource = people.find((person: any) => person.id == assignment.resource_id);

               const workedHoursResource = this._calculateWorkedHours(assignment, paidShiftHours);
               const costResource = this._calculateCosts(
                  wageOverrides,
                  resource as RawGanttData["people"][0],
                  workedHoursResource,
               );

               // If overtime is applied then count all worked hours,
               // else don't count hours that exceed the paid shift hours
               totalHours += workedHoursResource;
               totalCost += costResource;
            }
         });
         const totalManDays = Math.round(totalHours / paidShiftHours);
         totalsByDays.push({
            date: initialDate.toDate(),
            hours: Math.round(totalHours * 100) / 100,
            cost: totalCost,
            manDays: totalManDays,
         });
         initialDate.add(1, "day");
      }
      return totalsByDays;
   }

   private _calculateWorkedHours(
      assignment: GanttProject["assignments"][0],
      paidShiftHours: number,
   ): number {
      let workedHoursResource = 0;
      if (assignment.end_time && assignment.start_time) {
         const workedShiftHours = moment(assignment.end_time, "HH:mm").diff(
            moment(assignment.start_time, "HH:mm"),
            "hours",
            true,
         );
         workedHoursResource = assignment.overtime
            ? workedShiftHours
            : Math.min(workedShiftHours, paidShiftHours);
      } else {
         workedHoursResource = (paidShiftHours * (assignment.percent_allocated ?? 100)) / 100;
      }
      return workedHoursResource;
   }

   private _calculateCosts(
      wageOverrides: GanttProject["wage_overrides"],
      resource: RawGanttData["people"][0],
      workedHoursResource: number,
   ): number {
      const wageOverride = wageOverrides.find((wo) => wo.position_id === resource?.job_title_id);
      const hourlyWage = wageOverride ? wageOverride.rate : resource?.hourly_wage ?? 0;
      return Math.round(hourlyWage * workedHoursResource);
   }

   /**
    * Groups the work by interval, if you want to view the work by week, month or year
    * it allows you to group the work by those periods
    *
    * @param totalsByDays Array of totals by day from start to end date
    * @param interval Type of interval to group the work (week, month, year)
    * @returns Array of totals grouped by interval
    */
   private _groupByInterval(
      totalsByDays: TotalByDay[] = [],
      interval: moment.unitOfTime.StartOf = "week",
      unitType: (typeof TotalUnitType)[keyof typeof TotalUnitType] = TotalUnitType.PEOPLE,
   ): TotalByDay[] {
      if (interval === "day") return totalsByDays;

      const totalsByInterval = [];

      if (totalsByDays.length > 0) {
         let initialDate = moment(totalsByDays[0].date);
         let total = 0;

         totalsByDays.forEach((day) => {
            const currentDate = moment(day.date);
            if (initialDate.isSame(currentDate, interval)) {
               total += day[unitType] ?? 0;
            } else {
               totalsByInterval.push({
                  date: initialDate.toDate(),
                  [unitType]: total,
               });
               total = day[unitType] ?? 0;
               initialDate = currentDate;
            }
         });

         totalsByInterval.push({
            date: initialDate.toDate(),
            [unitType]: total,
         });
      }
      return totalsByInterval;
   }
}

/**
 * Search for min and max date in an array of assignments or requests, to fetch the range of dates
 *
 * @param arr Array of assignments or requests
 * @returns Object with minDate and maxDate
 */
export function getMinMaxDates(arr: GanttProject["requests"] | GanttProject["assignments"]): {
   minDate: moment.Moment | null;
   maxDate: moment.Moment | null;
} {
   if (arr.length === 0) return { minDate: null, maxDate: null };

   let minDate = moment(arr[0].start_day);
   let maxDate = moment(arr[0].end_day);

   arr.forEach((elem) => {
      const startDay = moment(elem.start_day);
      const endDay = moment(elem.end_day);
      if (startDay.isBefore(minDate)) {
         minDate = startDay;
      }
      if (endDay.isAfter(maxDate)) {
         maxDate = endDay;
      }
   });
   return { minDate, maxDate };
}

import "./text-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./text-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export type TextEditorValidator = (val: string | null) => {
   status: boolean;
   message: string | null;
};

export type TextEditorParams = EditorComponentParams<string | null> & {
   isClearable?: boolean;
   isRequired?: MaybeSubscribable<boolean>;
   width: MaybeSubscribable<number>;
   validators?: TextEditorValidator[];
};

export class TextEditor extends AbstractFieldEditor<string | null> {
   readonly validators: TextEditorValidator[] = [];
   readonly width = observable(0);

   constructor(private readonly params: TextEditorParams) {
      super(params, observable(unwrap(params.value)));
      if (this.params.width) {
         this.width(unwrap(this.params.width));
      }

      if (this.params.validators) {
         this.validators.push(...this.params.validators);
      }
   }

   validate(value: string | null): ValidatedValue<string | null> {
      const { isRequired } = this.params;
      const isEmpty = value === null;
      // Only show the first wrong constraint so as not to overwhelm UI
      const getFirstConstraint = () =>
         this.validators.map((v) => v(value)).filter((v) => !v.status)[0];

      if (isEmpty && unwrap(isRequired)) {
         return { valid: false, error: `${this.title} is required.` };
      }
      const firstConstraint = getFirstConstraint();
      if (firstConstraint) {
         return { valid: false, error: firstConstraint.message };
      }
      return { valid: true, value };
   }

   onDelete = (): Promise<void> => {
      return this.save(null);
   };

   static factory<T>(
      provider: (records: T[]) => TextEditorParams,
   ): (records: T[]) => ComponentArgs<TextEditorParams> {
      return (records) => ({
         name: "text-editor",
         params: provider(records),
      });
   }
}

ko.components.register("text-editor", {
   viewModel: TextEditor,
   template: template(),
   synchronous: true,
});

import { DropDownItem } from "@/lib/components/drop-downs/drop-down";
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";
import { requestContext } from "@/stores/common/request-context";

export enum PageOrientation {
   LANDSCAPE = "landscape",
   PORTRAIT = "portrait",
}

export type PdfSize = { width: number; height: number };
export function createPdfSizeOptions(): Array<DropDownItem<PdfSize>> {
   return [
      new DropDownItem('Letter (8.5" x 11")', { width: 612, height: 792 }),
      new DropDownItem('Tabloid (11" x 17")', { width: 792, height: 1224 }),
      new DropDownItem('Legal (8.5" x 14")', { width: 612, height: 1008 }),
      new DropDownItem('Arch A (9" x 12")', { width: 648, height: 864 }),
      new DropDownItem('Arch B (12" x 18")', { width: 864, height: 1296 }),
      new DropDownItem('Arch C (18" x 24")', { width: 1296, height: 1728 }),
      new DropDownItem('Arch D (24" x 36")', { width: 1728, height: 2592 }),
   ];
}

export function createOrientationOptions(): Array<SegmentedControllerItem<PageOrientation>> {
   return [
      new SegmentedControllerItem<PageOrientation>("Portrait", PageOrientation.PORTRAIT),
      new SegmentedControllerItem<PageOrientation>("Landscape", PageOrientation.LANDSCAPE),
   ];
}

/**
 * Note this is a legacy implementation for downloading files.
 * @see https://github.com/johnculviner/jquery.fileDownload
 *
 * PLEASE NOTE: This function will automatically prepend the baseUrl
 * for the request. Do not add it at the call site. The function will serialize
 * the string in case, but it will not be necessary to pass it.
 */
export function downloadFile(url: string): Promise<void> {
   const finalUrl = requestContext.baseUrl + url.replace(requestContext.baseUrl, "");

   return new Promise<void>((resolve, reject) => {
      $.fileDownload(finalUrl, {
         successCallback: () => {
            resolve();
         },
         failCallback: () => {
            reject();
         },
      });
   });
}

// Export for backwards compatibility
export const ReportOptions = {
   createOrientationOptions,
   createPdfSizeOptions,
};

import type { UNSAFE_MenuSelection } from "@procore/core-react";
import { MultiSelect } from "@procore/core-react";
import React from "react";
import type { DataTableCellEditorProps, ColumnDefinition } from "@procore/data-table";
import { withDataTableEditor } from "@procore/data-table";

export interface CustomMultiSelect {
   id: string;
   label: string;
}

//Custom Cell Editor
export interface CustomMultiSelectEditorParams {
   options: CustomMultiSelect[];
   getOptions?: (item: CustomMultiSelect) => CustomMultiSelect[];
}

export type CustomMultiSelectEditorProps = DataTableCellEditorProps<
   CustomMultiSelect,
   CustomMultiSelectColumnDefinition
>;

export const Editor = React.forwardRef<HTMLDivElement, CustomMultiSelectEditorProps>(
   ({ columnDefinition, setDataValue, stopEditing, value }, ref) => {
      const options = columnDefinition.cellEditorParams?.options ?? ([] as CustomMultiSelect[]);

      const selectedOptions = columnDefinition.cellEditorParams?.getOptions?.(value);

      const [selectedOpt, setSelectedOpt] = React.useState<any[]>(selectedOptions ?? []);

      /* istanbul ignore next */
      function onSelect(selection: UNSAFE_MenuSelection | any) {
         if (selection.item && selection.item !== value) {
            // this is necessary to update the row data
            setDataValue(columnDefinition.field, selection);
         }
         if (selection && !selection.item && selection !== value) {
            // this is necessary to update multiselect data
            setSelectedOpt(selection);
            // this is necessary to update the row data
            setDataValue(columnDefinition.field, selection);
         }
      }

      const getMultiSelectId = (item: CustomMultiSelect) => item.id;
      function getMultiSelectLabel(item: CustomMultiSelect) {
         return item.label;
      }
      //Editor for MultiSelect
      return (
         <MultiSelect
            getId={getMultiSelectId}
            getLabel={getMultiSelectLabel}
            onChange={onSelect}
            options={options}
            value={selectedOpt}
            block
            afterHide={() => {
               // tells DataTable that editing is finished when the Select is closed
               stopEditing();
            }}
            ref={ref}
         />
      );
   },
);

export type CustomMultiSelectColumnDefinition = ColumnDefinition<
   CustomMultiSelect,
   typeof CustomMultiSelectEditor,
   CustomMultiSelectEditorParams
>;

export const CustomMultiSelectEditor = withDataTableEditor(Editor, "select");

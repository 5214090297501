import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/draggable-points")(Highcharts);

export type CustomGraphProps = {
   data: Highcharts.SeriesAreaOptions[];
   graphStart: number;
   yAxisTitle: string;
   width?: string;
   height?: string;
   imagesUrl: string;
   onDraggablePointDrop: (mean: number, stdDev?: number) => void;
   dragMinY: number;
};
export const CustomGraph = ({
   data,
   graphStart,
   yAxisTitle,
   width = "100%",
   height = "100%",
   imagesUrl,
   onDraggablePointDrop,
   dragMinY,
}: CustomGraphProps) => {
   const ONE_DAY = 1000 * 60 * 60 * 24;
   const [draggableSeries, setDraggableSeries] = React.useState<Highcharts.SeriesOptions[]>([]);
   const [originalMaxPeak, setOriginalMaxPeak] = React.useState<number>(0);

   React.useEffect(() => {
      if (data && data.length > 0) {
         const seriesData = data[0].data as number[];
         const midpoint = Math.round(seriesData.length / 2);
         const draggableSeriesData = new Array(seriesData.length).fill(null);
         draggableSeriesData[midpoint] = seriesData[midpoint];
         const series = {
            name: "Draggable Point",
            data: draggableSeriesData,
            color: "#FF5100",
            dragDrop: {
               draggableX: true,
               draggableY: true,
               dragMinY: dragMinY,
               dragMaxY: 2 * seriesData[midpoint],
            },
            marker: {
               symbol: `url(${imagesUrl}/labor-curves/drag-arrows.png)`,
            },
            pointInverval: 1,
         };
         setOriginalMaxPeak(seriesData[midpoint]);
         setDraggableSeries([series as any]);
      }
   }, []);
   const options: Highcharts.Options = {
      title: {
         text: undefined,
      },
      credits: {
         enabled: false,
      },
      legend: {
         enabled: false,
      },
      xAxis: {
         type: "datetime",
         tickInterval: 1,
         crosshair: true, // Option to extend component by making this a prop value
      },
      yAxis: {
         title: {
            text: yAxisTitle,
         },
         tickInterval: 1,
         crosshair: true,
      },
      plotOptions: {
         series: {
            pointStart: graphStart,
            pointIntervalUnit: "day",
            pointInterval: 1,
            point: {
               events: {
                  drop: function (e: any) {
                     const newStdDev = e.newPoint.y ? originalMaxPeak / e.newPoint.y : 1;
                     const relativeNewPoint = e.newPoint.x - graphStart;
                     const newPointDiff = Math.round(relativeNewPoint / ONE_DAY);
                     const newPeakWeek = Math.round(newPointDiff / 7);
                     onDraggablePointDrop(newPeakWeek, newStdDev);
                  },
               },
            },
            states: {
               inactive: {
                  opacity: 1,
               },
            },
         },
         area: {
            stacking: "normal",
            lineWidth: 0,
            marker: {
               enabled: false,
            },
         },
      },
      chart: {
         height: height,
      },
      tooltip: {
         enabled: false,
      },
      series: [...data, ...(draggableSeries as any)],
   };

   return (
      <div style={{ width: width }}>
         <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
   );
};

import "./person-name-editor.styl";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./person-name-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

type NameParams = { first: string | null; last: string | null };
type NameValue = { first: string; last: string };

export type PersonNameEditorParams = EditorComponentParams<NameParams, NameValue>;

export class PersonNameEditor extends AbstractFieldEditor<NameParams, NameValue> {
   readonly firstName = pureComputed<string | null>({
      read: () => {
         return this.value().first;
      },
      write: (first) => {
         this.value({ ...this.value(), first });
      },
      owner: this,
      pure: true,
   });

   readonly lastName = pureComputed<string | null>({
      read: () => {
         return this.value().last;
      },
      write: (last) => {
         this.value({ ...this.value(), last });
      },
      owner: this,
      pure: true,
   });

   readonly firstNameValidation = pureComputed(() => {
      return !this.firstName() ? "First name is required." : null;
   });

   readonly lastNameValidation = pureComputed(() => {
      return !this.lastName() ? "Last name is required." : null;
   });

   readonly hasChanged = pureComputed(() => {
      return (
         this.value().first !== this.initialValue.first ||
         this.value().last !== this.initialValue.last
      );
   });

   constructor(private readonly params: PersonNameEditorParams) {
      super(params, observable(unwrap(params.value)));
   }

   validate(value: NameParams): ValidatedValue<NameValue> {
      return value.first && value.last
         ? { valid: true, value: { first: value.first, last: value.last } }
         : { valid: false, error: null };
   }

   clearFirstName(): void {
      this.value({ ...this.value(), first: null });
   }

   clearLastName(): void {
      this.value({ ...this.value(), last: null });
   }

   static factory<T>(
      provider: (records: T[]) => PersonNameEditorParams,
   ): (records: T[]) => ComponentArgs<PersonNameEditorParams> {
      return (records) => ({
         name: "person-name-editor",
         params: provider(records),
      });
   }
}

ko.components.register("person-name-editor", {
   viewModel: PersonNameEditor,
   template: template(),
   synchronous: true,
});

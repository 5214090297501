import ko from "knockout"
import "./select-people-by-assignments-pane.styl"
import template from "./select-people-by-assignments-pane.pug"
import { DateUtils } from "@/lib/utils/date"

### Models ###
import { Person } from "@/models/person"

### Auth, Real-Time & Stores ###
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
{ WeekDayFormat, MonthFormat, YearFormat, DayFormat } = DateUtils
DATE_FORMAT_OPTIONS = {
   weekdayFormat: WeekDayFormat.ABBREV
   monthFormat: MonthFormat.FULL
   dayFormat: DayFormat.ONE_DIGIT
   yearFormat: YearFormat.FULL
}

export class SelectPeopleByAssignmentPane extends ModalPane
   constructor: (projectId, viewingDate, title, actionBtnTxt, description, options) ->
      assertArgs(arguments, String, Date, String, String, String, optional(Object))
      super("", "", template())
      @title = ko.observable(title)
      @actionBtnTxt = ko.observable(actionBtnTxt)
      @description = ko.observable(description)
      @overflowable(true)

      @forAlert = if options?.forAlert? then options.forAlert else false

      @projectId = projectId

      @currentViewingDate = ko.observable(viewingDate)
      @formattedViewingDate = ko.pureComputed =>
         return DateUtils.formatDate(@currentViewingDate(), defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)

      @selectionTypes = [
         new SegmentedControllerItem("Current Viewing Day", "viewing-date")
         new SegmentedControllerItem("Date Range", "range")
      ]
      @selectedSelectionType = ko.observable(if options?.rangeEndDate? then @selectionTypes[1] else @selectionTypes[0])

      @assignmentStartDate = ko.observable(new Date(viewingDate.getTime()))
      @assignmentStartDate.subscribe (newVal) =>
         if newVal > @assignmentEndDate() or !@assignmentEndDate()?
            @assignmentEndDate(newVal)

      @endTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Date", "date")
         new SegmentedControllerItem("Weeks", "weeks")   
      ])
      # TODO: maybe add project end date

      @selectedEndType = ko.observable(@endTypeOptions()[0])
      @selectedEndType.subscribe (newVal) =>
         return unless newVal?.value() == "project-end"
         
         if newVal.value() == "project-end" and @selectedProject()?.baggage()?.est_end_date?
            calculatedDate = new Date(@selectedProject().baggage().est_end_date)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(),DATE_FORMAT_OPTIONS)
            @assignmentEndDateDisposableBlock = true
            @assignmentEndDate(calculatedDate)
            @tbdEndDateString(calculatedDateString)

      @assignmentEndDateDisposableBlock = false
      @assignmentEndDate = ko.observable()
      if options?.rangeEndDate?
         @assignmentEndDate(options.rangeEndDate)
      @assignmentEndDate.subscribe (newVal) =>
         return @assignmentEndDateDisposableBlock = false if @assignmentEndDateDisposableBlock
         if newVal < @assignmentStartDate() or !@assignmentStartDate()?
            @assignmentStartDate(newVal)

      @numberOfWeeks = ko.observable(null)
      @weeksCalculatedDateString = ko.pureComputed =>
         return "Enter # of weeks" unless @numberOfWeeks()? and Number(@numberOfWeeks()) > 0
         return "Select Start Date" unless @assignmentStartDate()?
         weeks = Number(@numberOfWeeks())
         dayStep = if weeks >= 1 then (((weeks - 1) * 7) + 6) else (weeks * 7)
         dayStep = Math.round(dayStep)

         calculatedDate = DateUtils.incrementDate(ko.unwrap(@assignmentStartDate), dayStep)
         @assignmentEndDate(calculatedDate)

         return DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)

      @alertDeliveryOptions = [
         new SegmentedControllerItem("Send Immediately", "open")
         new SegmentedControllerItem("Schedule", "scheduled")
         new SegmentedControllerItem("Save Draft", "drafts")
      ]
      @selectedAlertDelivery = ko.observable(@alertDeliveryOptions[0])

      @scheduledAlertDate = ko.observable()
      @scheduledAlertTime = ko.observable()

      @actionBtnDisabled = ko.pureComputed =>
         if  @selectedSelectionType().value() == "range"
            return !(@assignmentStartDate()? and @assignmentEndDate()?)
         else
            return !@currentViewingDate()?

   getPeopleToMessage: =>
      return if @actionBtnDisabled()
      if @forAlert
         # We just need the selected date ranges.
         if  @selectedSelectionType().value() == "range"
            options = {
               startDay: DateUtils.getDetachedDay(@assignmentStartDate())
               endDay: DateUtils.getDetachedDay(@assignmentEndDate())
            }
         else
            viewingDay = DateUtils.getDetachedDay(@currentViewingDate())
            options = {
               startDay: viewingDay
               endDay: viewingDay
            }

         if @selectedAlertDelivery().value() == "scheduled"
            unless @scheduledAlertDate()? and @scheduledAlertTime()?
               return @displayingNotice(SelectPeopleByAssignmentPane.Notice.NEED_SCHEDULE)

         if @scheduledAlertDate()?
            @data.set('scheduledAlertDate', @scheduledAlertDate())
         if @scheduledAlertTime()?
            @data.set('scheduledAlertTime', @scheduledAlertTime())
         @data.set('selectedAssignmentRange', options)
         @data.set('context', @selectedAlertDelivery().value())

         # modalManager.modalFinished()
      # else
      if  @selectedSelectionType().value() == "range"
         options = {
            startDay: DateUtils.getDetachedDay(@assignmentStartDate())
            endDay: DateUtils.getDetachedDay(@assignmentEndDate())
         }
      else
         viewingDay = DateUtils.getDetachedDay(@currentViewingDate())
         options = {
            startDay: viewingDay
            endDay: viewingDay
         }

      try
         result = await Assignment2Store.getAssignedPeopleToMessage(@projectId, options).payload;
         assignees = result.data.assignees.map((item) -> return new Person(item, true))
         defaultRecipients = result.data.defaultRecipients.map((item) -> return new Person(item, true))
         @data.set('peopleToMessage', assignees)
         @data.set('defaultRecipients', defaultRecipients)
         modalManager.modalFinished()
      catch error
         return console.log "Error: ", error
        


SelectPeopleByAssignmentPane.Notice = {
   NEED_SCHEDULE: {
      text: "You need to pick a scheduled time and date."
      info: null
      color: 'red'
      dissmissable: true
   }      
}

import ko from 'knockout'
import "./custom-column-headers-pane.styl"
import template from "./custom-column-headers-pane.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import { ColumnHeader, SubProperty } from '@/models/column-header'

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

cloneColumns = (column) ->
   copy = column.clone(ColumnHeader)
   copy.selected = ko.observable(ko.unwrap(column.selected))
   if copy.subProperties().length > 0
      copy.subProperties = ko.observableArray(copy.subProperties().map (subProp) -> subProp.clone(SubProperty))
   return copy

export class CustomColumnHeadersPane extends PopupPane
   constructor: (currentColumnHeaders, allOptions) ->
      super(template())
      assertArgs(arguments, Function, Array)
      @currentColumnHeaders = currentColumnHeaders

      # Search Bar
      @searchQuery = ko.observable()

      # Current showing headers and options
      @allHeaders = allOptions.map cloneColumns

      @displayingColumnHeaders = ko.pureComputed =>
         filteredColumnHeaders = []
         query = @searchQuery()?.toLowerCase()
         if ValidationUtils.validateInput(query)
            filteredColumnHeaders = @allHeaders.filter (header) ->
               return header.name().toLowerCase().indexOf(query) != -1
         else
            filteredColumnHeaders = @allHeaders
         return filteredColumnHeaders.sort (a, b) ->
            return ko.unwrap(a.sequence) - ko.unwrap(b.sequence)

   resetToDefaultColumns: =>
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

   cancelColumnHeaderChanges: =>
      @dismissHandler(Popup.ExitStatus.CANCELED)

   saveColumns: =>
      currentColumnHeaders = @currentColumnHeaders()

      # Include all existing headers first.
      newHeaders = @allHeaders.filter (header) => 
         return header.selected() && currentColumnHeaders.find((h) => h.key() == header.key())

      # Include all the new headers.
      newHeaders = newHeaders.concat(
         @allHeaders.filter (header) => 
            return header.selected() && newHeaders.every((h) => h.key() != header.key())
      )

      # Reset all the sequences to account for added and removed headers.
      index = 0
      newHeaders.forEach((header) => header.sequence(index++))

      @currentColumnHeaders(newHeaders)
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

import writeXlsxFile from "write-excel-file";

const typeMap = { string: String, number: Number, date: Date };

export class MyXlsProvider {
   static write({
      filename,
      columns,
      rows,
   }: {
      filename: string;
      columns: any[];
      rows: any[];
   }): void {
      columns.forEach((col) => delete col.type);
      rows.forEach((row) =>
         row.forEach(
            (cell: any) => (cell.type = typeMap[cell.type as keyof typeof typeMap] || String),
         ),
      );

      writeXlsxFile([columns, ...rows], {
         // Convert pixels to symbols for `write-excel-file`
         columns: columns.map((col) => ({
            ...col,
            width: col.width ? Math.round(col.width / 10) : undefined,
         })),
         fileName: filename,
      });
   }
}

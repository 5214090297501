export const hexColors = [
   // Blacks
   "#2F3437",
   "#464F53",
   "#5E696E",
   "#6A767C",
   "#75838A",
   "#919CA1",
   "#ACB5B9",
   "#D6DADC",

   // Reds
   "#570F0F",
   "#821717",
   "#AD1F1F",
   "#C42223",
   "#D92626",
   "#E05252",
   "#E87D7D",
   "#F3BEBE",

   // Oranges
   "#662000",
   "#993000",
   "#CC4100",
   "#E64900",
   "#FF5100",
   "#FF7433",
   "#FF9666",
   "#FFCBB3",

   // Yellows
   "#5E4908",
   "#8E6D0B",
   "#BD910F",
   "#D5A411",
   "#ECB613",
   "#F0C442",
   "#F4D371",
   "#F9E9B9",

   // Greens
   "#194D1E",
   "#26732D",
   "#33993B",
   "#39AC43",
   "#40BF4A",
   "#66CC6E",
   "#8CD993",
   "#C6ECC9",

   // Teals
   "#125449",
   "#1B7E6E",
   "#24A892",
   "#28BEA5",
   "#2DD2B7",
   "#57DBC5",
   "#81E4D4",
   "#C0F2E9",

   // Blues
   "#0D2959",
   "#133D86",
   "#1952B3",
   "#1D5CC9",
   "#2066DF",
   "#4C85E6",
   "#79A3EC",
   "#BCD1F5",

   // Purples
   "#401D49",
   "#602C6D",
   "#803A92",
   "#9142A4",
   "#A049B6",
   "#B36DC5",
   "#C692D3",
   "#E3C9E9",

   // Pinks
   "#521447",
   "#7A1F6B",
   "#A3298F",
   "#B82EA1",
   "#CC33B3",
   "#D65CC2",
   "#E085D1",
   "#F0C2E8",
];

import { Url as UrlUtils } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { Store } from "@/stores/store"

### Models ###
import { Activity } from "@/models/activity"

export class ActivityStore extends Store
   constructor: ->
      super()       
      @vault = {}

   getPersonActivity: (entityId, depth, category, callback) ->
      assertArgs(arguments, String, Number, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/activity/people/#{entityId}/category/#{category}?depth=#{depth}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         activity = data.map (item) ->
            return new Activity(item)
         callback(null, activity)

   getProjectActivity: (entityId, depth, category, callback) ->
      assertArgs(arguments, String, Number, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/activity/projects/#{entityId}/category/#{category}?depth=#{depth}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         activity = data.map (item) ->
            return new Activity(item)
         callback(null, activity)

   getGroupsActivity: (filters, callback) ->
      assertArgs(arguments, Object, Function)

      params = UrlUtils.serializeParams(filters)

      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/groups/#{authManager.selectedGroupId()}/activity?#{params}"
         method: "GET"
      }, (err, results) =>
         return callback(err) if err
         results.data = results.data.map (item) ->
            return new Activity(item)
         callback(null, results)


# We are adding this here, as the coffeescript files will not allow
# us to import the ActivityEntityType from find-activity.ts in lc-node-modules/common.
ActivityStore.ActivityEntityType = {
   PROJECTS: "projects",
   PEOPLE: "people",
}

export activityStore = new  ActivityStore()
import "./tags-editor-element.styl";
import template from "./tags-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { ActionResult } from "@/lib/components/drop-downs/drop-down-2";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";
import type { EditorElementParams } from "../common/editor-element-template";
import type { MultiSelectItem } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import {
   MultiSelectDropDownPane,
   SELECT_ALL_ITEM,
} from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import { CheckboxCell } from "@/lib/components/grid/cells/checkbox-cell";
import { TagsCell } from "@/lib/components/grid/cells/tags-cell";
import { TagChip, TagExpirationState } from "@/lib/components/tags/tag-chip";

export type TagsEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   selectedTagIds: PureComputed<Set<string>>;
   tags: PureComputed<SerializedTag[]>;
} & EditorElementParams<Set<string>>;

export class TagsEditorElement extends ViewModel {
   readonly invalidInputMessage: TagsEditorElementParams["invalidInputMessage"];
   readonly onChange: TagsEditorElementParams["onChange"];
   readonly selectedTagIds: TagsEditorElementParams["selectedTagIds"];
   readonly tags: TagsEditorElementParams["tags"];
   readonly title: TagsEditorElementParams["title"];

   readonly dropDown2Params: DropDown2Params<MultiSelectItem<SerializedTag>>;

   constructor({
      invalidInputMessage,
      onChange,
      selectedTagIds,
      tags,
      title = "Tags",
   }: TagsEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.selectedTagIds = selectedTagIds;
      this.tags = tags;
      this.title = title;

      const pane = new MultiSelectDropDownPane({
         isSelectAllDisabled: true,
         items: this.tags,
         searchTextProvider: (tag) => `${tag.name}|${tag.abbreviation}|${tag.categories.join("|")}`,
         selectedIds: this.selectedTagIds,
         textProvider: (tag) => tag.name,
      });

      this.dropDown2Params = {
         actionInterceptor: () => ActionResult.TOGGLE,
         cellFactory: CheckboxCell.factory<MultiSelectItem<SerializedTag>>((item) => ({
            alignment: "left",
            title: item.item == SELECT_ALL_ITEM ? "Select All" : (item.item as SerializedTag).name,
            value: pureComputed(() => this.selectedTagIds().has(item.id)),
            component: TagChip.factory({
               abbreviation: (item.item as SerializedTag).abbreviation,
               color: (item.item as SerializedTag).color,
               expirationState: TagExpirationState.NOT_EXPIRING_SOON,
            }),
         })),
         panes: [pane as any],
         selectedIds: pureComputed({
            read: () => {
               return this.selectedTagIds();
            },
            write: (ids: Set<string>) => {
               this.onChange(ids);
            },
         }),
         selectedItem: pureComputed({
            read: () => {
               const tags = this.tags().filter((tag) => this.selectedTagIds().has(tag.id));
               return tags.length == 0
                  ? null
                  : { id: tags[0].id, item: tags[0] as SerializedTag | symbol };
            },
            write: () => {},
         }),
         selectedItemCellFactory: TagsCell.factory<MultiSelectItem<SerializedTag>>(() => {
            return {
               tagInstances: this.tags()
                  .filter((tag) => this.selectedTagIds().has(tag.id))
                  .map((tag) => ({
                     name: tag.name,
                     abbreviation: tag.abbreviation,
                     color: tag.color,
                     expirationState: TagExpirationState.NOT_EXPIRING_SOON, // TODO: Add the logic for this.
                  })),
            };
         }),
      };
   }

   static factory(params: TagsEditorElementParams): ComponentArgs<TagsEditorElementParams> {
      return {
         name: "tags-editor-element",
         params,
      };
   }
}

ko.components.register("tags-editor-element", {
   viewModel: TagsEditorElement,
   template: template(),
   synchronous: true,
});

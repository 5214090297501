import "./drop-down.styl"
import dropDownTemplateFn from "./drop-down.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import { Format as FormatUtils } from "@/lib/utils/format"
import ko from 'knockout';

export class DropDownItem
   constructor: (name, value, baggage) ->
      # Commenting this out because insist is failing to detect args correctly
      # for items with a Object as a value.
      # assertArgs(arguments, [String, Function], anything(), optional(Object))

      if name instanceof Function
         assertOfType(name(), String)
         @name = name
      else
         @name = ko.observable(name)
      if value instanceof Function
         @value = value
      else
         @value = ko.observable(value)

      # TODO: clean this up. Temp work aroudn for additional_searchables in baggage.
      @baggage = ko.observable(baggage or null)


export class DropDown
   constructor: (params) ->
      assertArgs(arguments, Object)
      assertOfType(params.searchable, optional([Boolean, Function]))

      @hasGroups = ko.observable(false)
      if params.values?
         @hasGroups(false)
         # assertOfType(params.values, [Function, arrayOf([DropDownItem, ValueSet])])
      else if params.groupedValues?
         assertOfType(params.groupedValues, Function)
         assertOfType(params.groupedValues(), Object)
         @hasGroups(true)

      if params.actionTitle instanceof Function
         @actionTitle = params.actionTitle
      else if typeof params.actionTitle == "string"
         @actionTitle = ko.observable(params.actionTitle)
      else
         @actionTitle = null

      if params.defaultTitle instanceof Function
         @defaultTitle = params.defaultTitle
      else if typeof params.defaultTitle == "string"
         @defaultTitle = ko.observable(params.defaultTitle)
      else
         @defaultTitle = ko.observable("Select an Option")

      if params.selectedValue instanceof Function
         @selectedValue = params.selectedValue
      else
         @selectedValue = ko.observable(null)

      if params.clearable
         if params.clearable instanceof Function
            assertOfType(params.clearable(), Boolean)
            @clearable = params.clearable
         else
            @clearable = ko.observable(params.clearable)
      else
         @clearable = null

      @showColors = params.showColors or false

      if params.loadable
         if @hasGroups()
            @loading = ko.observable(true)
            checkGroupedValueLoading = =>
               for key, val of params.groupedValues()
                  if val.length != 0
                     @loading(false)
                     break
            checkGroupedValueLoading()
            params.groupedValues.subscribe =>
               checkGroupedValueLoading()
         else
            @loading = if params.values().length != 0 then ko.observable(false) else ko.observable(true)
            params.values.subscribe (newVal) =>
               @loading(false) if newVal.length != 0
      else
         @loading = false

      if params.searchable
         if params.searchable instanceof Function
            assertOfType(params.searchable(), Boolean)
            @searchable = params.searchable
         else
            @searchable = ko.observable(params.searchable)
      else
         @searchable = false

      @searchQuery = ko.observable('')
      @searchPlaceholder = ko.observable(params.searchPlaceholder or 'Type search here')

      if params.values instanceof Function
         # assertOfType(params.values(), arrayOf([DropDownItem, ValueSet]))
         @values = params.values
      else
         @values = ko.observableArray(params.values)

      if params.groupedValues instanceof Function
         @groupedValues = params.groupedValues
      else
         @groupedValues = ko.observable(params.groupedValues)

      if params.groupedValues?
         @groupNames = ko.pureComputed =>
            names = []
            for key of @groupedValues()
               names.push(key)
            return names
         @groupDisplayValues = {}
         createGroupDisplayValue = (key, val) =>
            @groupDisplayValues[key] = ko.computed =>
               search = @searchQuery().toLowerCase()
               return ko.utils.arrayFilter val, (item) ->
                  item.name().toLowerCase().indexOf(search) >= 0
         processGroupedValues = =>
            for key, val of @groupedValues()
               createGroupDisplayValue(key, val)

         processGroupedValues()
         @groupedValues.subscribe =>
            processGroupedValues()

      @displayValues = ko.pureComputed =>
         filteredValues = @values()
         if ValidationUtils.validateInput(@searchQuery())
            search = @searchQuery().toLowerCase()
            return filteredValues = filteredValues.filter (item) ->
               if item.baggage?()?.additional_searchables?
                  for additonal in item.baggage().additional_searchables
                     return true if additonal.toLowerCase().indexOf(search) >= 0
               return item.name().toLowerCase().indexOf(search) >= 0

         return filteredValues if params.disableSort
         return FormatUtils.keyableSort(filteredValues, 'name')

      if params.selectCallback and params.selectCallback instanceof Function
         @selectCallback = params.selectCallback

      @isExpanded = ko.observable(false)
      @currentTitle = ko.pureComputed =>
         selectedValue = @selectedValue()
         unless @isExpanded()
            if selectedValue?
               if typeof selectedValue == "object"
                  return ko.unwrap(selectedValue.name)
               else
                  return selectedValue
         return @defaultTitle()

      if params.disabled
         if params.disabled instanceof Function
            @disabled = params.disabled
         else
            @disabled = ko.observable(params.disabled)
      else
         @disabled = ko.observable(false)

   toggleDropDown: =>
      return if @disabled()
      @isExpanded(!@isExpanded())
      if !@isExpanded()
         @searchQuery('')

   getGroupDisplayValues: (key) =>
      vals = @groupDisplayValues[key]
      return vals

   # This is required as opposed to just passing the 'toggleDropDown' to the dropdownDismisser
   # custom binding because the 'if isExpanded' check needs to be preformed and we can't do
   # that inside a annoymous function in the event listener that will call this becuase it
   # prevents the removal of the event listener if the callback is anonymous.
   maybeDismissDropDown: =>
      if @isExpanded()
         @isExpanded(false)
         @searchQuery('')

   valueSelected: (data, event, groupName) =>
      @isExpanded(false)
      @selectedValue(data)
      if @selectCallback?
         if groupName?
            @selectCallback({group: groupName, data: data})
         else
            @selectCallback(data)
      @searchQuery('')

   clearSelection: =>
      return unless @clearable()
      @selectedValue(null)

dropDownTemplate = dropDownTemplateFn()

ko.components.register("drop-down",
   viewModel: DropDown,
   template: dropDownTemplate
)

/**
 * NOTE: This file exists to avoid circular references between
 * AuthManager, Format, and DefaultStore.
 */
import { authManager } from "../managers/auth-manager";

export function formatName({ first, last }: { first: string; last: string }): string {
   return authManager.authedUser()?.preferences()?.displayLastNamesFirst() || false
      ? `${last}, ${first}`
      : `${first} ${last}`;
}

export function unformatName(
   name: string,
   lastNameFirst?: boolean,
): { first: string; last: string } {
   const [first, second] = name.split(lastNameFirst ? ", " : " ");
   return lastNameFirst ? { first: second, last: first } : { first, last: second };
}

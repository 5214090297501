import type { Project } from "@laborchart-modules/common";
import moment from "moment-timezone";
import type { DataTableRequest } from "./prop-types";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import type { CreateRequestPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type * as Highcharts from "highcharts";

// One day in millesconds
const ONE_DAY = 1000 * 60 * 60 * 24;

export function calculateProjectDurationInWeeks(project: Project, tbdWeeks: number = 26) {
   if (project.est_end_date) {
      const start = moment(project.start_date);
      const end = moment(project.est_end_date);
      return Math.ceil(end.diff(start, "weeks", true));
   }
   return tbdWeeks;
}

export function constructCoreApiRequests(
   requests: DataTableRequest[],
   selectedWorkDays: any,
   project: Project,
   status_id?: string,
): CreateRequestPayload[] {
   const apiReadyRequests = requests.map((r) => {
      return {
         start_day: getDetachedDay(r.start_day),
         end_day: getDetachedDay(r.end_day),
         project_id: project.id,
         start_time: project.daily_start_time,
         end_time: project.daily_end_time,
         work_days: selectedWorkDays,
         category_id: r.category?.id ?? null,
         job_title_id: r.job_title?.id ?? null,
         quantity: r.weekly_workers,
         status_id: status_id ?? null,
      };
   });
   return apiReadyRequests;
}

export function addDatesToRequestsCurve(
   requests: Array<Omit<DataTableRequest, "start_day" | "end_day">>,
   project: Project,
   selectedWorkDays: any,
): DataTableRequest[] {
   const first_workday = getFirstProjectWorkday(project, selectedWorkDays);
   const last_workday_of_first_week = getLastProjectWorkdayOfFirstWeek(project, selectedWorkDays);
   return requests.map((r) => {
      const start_date = new Date(first_workday);
      const end_date = new Date(last_workday_of_first_week);
      const week_start_offset = r.week_start - 1;
      start_date.setDate(start_date.getDate() + 7 * week_start_offset);
      // We are already account for the first week by getting last work day, hence - 1
      end_date.setDate(end_date.getDate() + 7 * (r.request_duration + week_start_offset - 1));
      return {
         ...r,
         start_day: start_date,
         end_day: end_date,
      };
   });
}

export function getNewWeekStartFromDate(date: Date, project: Project, selectedWorkDays: any): any {
   const _MS_PER_DAY = 1000 * 60 * 60 * 24;
   const first_workday = getFirstProjectWorkday(project, selectedWorkDays);
   const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
   const utc2 = Date.UTC(
      first_workday.getFullYear(),
      first_workday.getMonth(),
      first_workday.getDate(),
   );
   const difference_in_days = (utc1 - utc2) / _MS_PER_DAY;
   const difference_in_weeks = difference_in_days / 7;
   return Math.floor(difference_in_weeks) + 1;
}

export function getNewWeekEndFromDate(date: Date, project: Project, selectedWorkDays: any): any {
   const _MS_PER_DAY = 1000 * 60 * 60 * 24;
   const last_workday = getLastProjectWorkdayOfFirstWeek(project, selectedWorkDays);
   const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
   const utc2 = Date.UTC(
      last_workday.getFullYear(),
      last_workday.getMonth(),
      last_workday.getDate(),
   );
   const difference_in_days = (utc1 - utc2) / _MS_PER_DAY;
   const difference_in_weeks = difference_in_days / 7;
   return Math.ceil(difference_in_weeks) + 1;
}

export function getEndDayfromEndWeek(
   request: DataTableRequest,
   project: Project,
   selectedWorkdays: any,
): Date {
   const last_workday = getLastProjectWorkdayOfFirstWeek(project, selectedWorkdays);
   const week_start_offset = request.week_start - 1;
   last_workday.setDate(
      last_workday.getDate() + 7 * (request.request_duration + week_start_offset - 1),
   );
   return last_workday;
}

export function getStartDayFromStartWeek(
   request: DataTableRequest,
   project: Project,
   selectedWorkdays: any,
): Date {
   const first_workday = getFirstProjectWorkday(project, selectedWorkdays);
   const week_start_offset = request.week_start - 1;
   first_workday.setDate(first_workday.getDate() + 7 * week_start_offset);
   return first_workday;
}

export function calculateTotalRequestHours(
   requests: DataTableRequest[],
   project: Project,
   selectedWorkdays: any,
): number {
   const daily_hours = project.daily_end_time - project.daily_start_time;
   let sum = 0;
   requests.forEach((r) => {
      // Make a copy of the date to avoid updating the request when iterating
      const date = new Date(r.start_day.getTime());
      let request_hours = 0;
      // Add the daily work hours to the request total for every workday between the start and end
      while (date <= r.end_day) {
         const isoWorkday = date.getDay();
         if (selectedWorkdays[isoWorkday]) {
            request_hours += daily_hours;
         }
         date.setDate(date.getDate() + 1);
      }
      // Multiply the request by the quanity
      request_hours *= r.weekly_workers;
      sum += request_hours;
   });

   return sum;
}

export function getWorkdaysPerWeek(workdays: any): number {
   let workdaysPerWeek = 0;
   Object.keys(workdays).forEach((k) => {
      if (workdays[k]) {
         workdaysPerWeek++;
      }
   });
   return workdaysPerWeek;
}

function getFirstProjectWorkday(project: Project, selectedWorkDays: any): Date {
   const maybe_workday = new Date(project.start_date!);

   for (let i = 0; i < 7; i++) {
      if (selectedWorkDays[maybe_workday.getDay()]) {
         return maybe_workday;
      }

      maybe_workday.setDate(maybe_workday.getDate() + 1);
   }
   return maybe_workday;
}

function getLastProjectWorkdayOfFirstWeek(project: Project, selectedWorkDays: any): Date {
   let lastIsoWorkday;
   Object.keys(selectedWorkDays).forEach((i) => {
      if (selectedWorkDays[i]) {
         lastIsoWorkday = i;
      }
   });
   const maybe_workday = new Date(project.start_date!);

   for (let i = 0; i < 7; i++) {
      if (maybe_workday.getDay() == lastIsoWorkday) {
         break;
      }

      maybe_workday.setDate(maybe_workday.getDate() + 1);
   }
   return maybe_workday;
}

export function formatRequestsForGraph(
   requests: DataTableRequest[],
   color: string = "#FF5100", // Procore orange,
   enableMouseTracking: boolean = true,
): Array<Highcharts.SeriesAreaOptions & { job_title: null | string }> {
   const startDay = findRequestSeriesStartDay(requests);
   const endDay = findRequestSeriesEndDay(requests);
   const seriesDataLength = Math.ceil((endDay.getTime() - startDay.getTime()) / ONE_DAY) + 1;
   const seriesData: Array<Highcharts.SeriesAreaOptions & { job_title: null | string }> = [
      {
         name: "None",
         type: "area",
         data: new Array(seriesDataLength).fill(0),
         job_title: null,
         color: color,
         enableMouseTracking: enableMouseTracking,
      },
   ];
   requests.forEach((x) => {
      const startDayIndex = Math.round((x.start_day.getTime() - startDay.getTime()) / ONE_DAY);
      const endDayIndex = Math.round((x.end_day.getTime() - startDay.getTime()) / ONE_DAY);
      const requestJobTitleId = x.job_title ? x.job_title.id : null;
      const series = seriesData.find((s) => s.job_title === requestJobTitleId);

      if (series) {
         for (let i = startDayIndex; i <= endDayIndex; i++) {
            (series.data as number[])[i] = (series.data as number[])[i] + x.weekly_workers;
         }
      } else {
         // Any data missing a job title ID will go into the None bucket
         /* istanbul ignore next */
         if (!x.job_title) return;

         const newSeries: Highcharts.SeriesAreaOptions & { job_title: null | string } = {
            name: x.job_title.name,
            type: "area",
            data: new Array<number>(seriesDataLength).fill(0),
            job_title: x.job_title.id,
            color: x.job_title.color,
            enableMouseTracking: enableMouseTracking,
         };
         for (let i = startDayIndex; i <= endDayIndex; i++) {
            (newSeries.data as number[])[i] = (newSeries.data as number[])[i] + x.weekly_workers;
         }
         seriesData.push(newSeries);
      }
   });
   return seriesData;
}

export function findRequestSeriesStartDay(requests: DataTableRequest[]): Date {
   requests.sort((a, b) => {
      if (a.start_day < b.start_day) {
         return -1;
      }
      if (a.start_day > b.start_day) {
         return 1;
      }
      return 0;
   });
   return requests[0].start_day;
}

function findRequestSeriesEndDay(requests: DataTableRequest[]): Date {
   requests.sort((a, b) => {
      if (a.end_day > b.end_day) {
         return -1;
      }
      if (a.end_day < b.end_day) {
         return 1;
      }
      return 0;
   });
   return requests[0].end_day;
}

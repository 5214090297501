export type GanttConfigPanelValues = {
   showProjectInformation: boolean;
   showAllocationInformation: boolean;
   hideWeekends: boolean;
   showTimeoff: boolean;
   showResourcesNotInGroup: boolean;
   rowHeight: number;
   taskBorderRadius: number;
   showTotals: boolean;
   showTotalsSummary: boolean;
   totalsCellUnits: string;
};

// The configure panel state is defined here in this parent container so that it's state can be used to conditionally render/configure the BryntumGantt component
const TOTALS_CELL_UNITS_OPTIONS = ["People", "Person-Days", "Hours", "Cost"];
// These values are always used whenever the "Reset" button is clicked, regardless of what's saved in local storage
const defaultGanttConfigurePanelValues: GanttConfigPanelValues = {
   showProjectInformation: true,
   showAllocationInformation: true,
   hideWeekends: false,
   showTimeoff: false,
   showResourcesNotInGroup: false,
   rowHeight: 40,
   taskBorderRadius: 5,
   showTotals: false,
   showTotalsSummary: false,
   totalsCellUnits: TOTALS_CELL_UNITS_OPTIONS[0],
};

// These values are used to populate the initial state of the configure menu on page load
export function getGanttConfigurePanelValues(): GanttConfigPanelValues {
   const savedGanttConfigurePanelState = localStorage.getItem("gantt-configure-panel");
   return savedGanttConfigurePanelState
      ? JSON.parse(savedGanttConfigurePanelState)
      : defaultGanttConfigurePanelValues;
}

export function updateGanttConfigurePanelLocalStorage(
   key: keyof GanttConfigPanelValues,
   value: any,
) {
   const config: Partial<GanttConfigPanelValues> =
      JSON.parse(localStorage.getItem("gantt-configure-panel")!) ?? {};
   config[key] = value;
   localStorage.setItem("gantt-configure-panel", JSON.stringify(config));
}

import * as React from "react";
import { Tearsheet } from "@procore/core-react";
import { AssignmentTearsheetContent } from "../assignment/assignment-tearsheet-content";
import { SendAssignmentAlertsTearsheetContent } from "./send-assignment-alerts-tearsheet-content";

type AssignmentTearsheetState = OpenState | ClosedState;
type OpenState = { isOpen: true } & (CreateAssignment | EditAssignment | SendAssignmentAlerts);
type ClosedState = { isOpen: false; show: null };
type CreateAssignment = {
   show: "create-assignment";
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
};
type EditAssignment = {
   show: "edit-assignment";
   assignmentId: string;
   callback?: (data: any) => void;
};
type SendAssignmentAlerts = {
   show: "send-assignment-alerts";
   projectId: string;
   callback?: (data: any) => void;
};

type CloseAction = { type: "close" };
export type CreateAssignmentAction = {
   type: "open-create-assignment";
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
};
type EditAssignmentAction = {
   type: "open-edit-assignment";
   assignmentId: string;
   callback?: (data: any) => void;
};
type SendAssignmentAlertsAction = {
   type: "open-send-assignment-alerts";
   projectId: string;
   callback?: (data: any) => void;
};

export type AssignmentTearsheetActions =
   | CloseAction
   | CreateAssignmentAction
   | EditAssignmentAction
   | SendAssignmentAlertsAction;

export const reducer = (
   _state: AssignmentTearsheetState,
   action: AssignmentTearsheetActions,
): AssignmentTearsheetState => {
   switch (action.type) {
      case "open-create-assignment": {
         return {
            isOpen: true,
            show: "create-assignment",
            projectId: action.projectId,
            categoryId: action.categoryId,
            subcategoryId: action.subcategoryId,
            callback: action.callback,
         };
      }
      case "open-edit-assignment": {
         return {
            isOpen: true,
            show: "edit-assignment",
            assignmentId: action.assignmentId,
            callback: action.callback,
         };
      }
      case "open-send-assignment-alerts": {
         return {
            isOpen: true,
            show: "send-assignment-alerts",
            projectId: action.projectId,
            callback: action.callback,
         };
      }
      case "close": {
         return {
            isOpen: false,
            show: null,
         };
      }
   }
};

export const initialState: AssignmentTearsheetState = {
   isOpen: false,
   show: null,
};

const AssignmentTearsheetContext = React.createContext<
   (AssignmentTearsheetState & { dispatch: React.Dispatch<AssignmentTearsheetActions> }) | null
>(null);

export const AssignmentTearsheetProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [state, dispatch] = React.useReducer(reducer, initialState);

   const contextValue = React.useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

   return (
      <AssignmentTearsheetContext.Provider value={contextValue}>
         {children}
         <AssignmentTearsheet />
      </AssignmentTearsheetContext.Provider>
   );
};

export const useAssignmentTearsheet = () => {
   const context = React.useContext(AssignmentTearsheetContext);

   if (!context) {
      throw Error(
         "'useAssignmentTearsheet' is supposed to be used in the component that is a child of 'AssignmentTearsheetProvider'.",
      );
   }

   return context;
};

export const AssignmentTearsheet: React.FC = () => {
   const state = useAssignmentTearsheet();
   const isEditAssignmentOpen = state.show === "edit-assignment";
   const isCreateAssignmentOpen = state.show === "create-assignment";
   const isSendAssignmentAlertsOpen = state.show === "send-assignment-alerts";

   const handleClose = () => state.dispatch({ type: "close" });

   return (
      <Tearsheet open={state.isOpen} onClose={handleClose}>
         {isCreateAssignmentOpen && (
            <AssignmentTearsheetContent
               onClose={handleClose}
               projectId={state.projectId}
               categoryId={state.categoryId}
               subcategoryId={state.subcategoryId}
               callback={state.callback}
            />
         )}
         {isEditAssignmentOpen && (
            <AssignmentTearsheetContent
               onClose={handleClose}
               assignmentId={state.assignmentId}
               callback={state.callback}
            />
         )}
         {isSendAssignmentAlertsOpen && (
            <SendAssignmentAlertsTearsheetContent
               onClose={handleClose}
               projectId={state.projectId}
            />
         )}
      </Tearsheet>
   );
};

export const onCreateAssignmentClick = (dispatch: React.Dispatch<AssignmentTearsheetActions>) => {
   dispatch({ type: "open-create-assignment" });
};

export const onEditAssignmentClick = (
   dispatch: React.Dispatch<AssignmentTearsheetActions>,
   assignmentId: string,
) => {
   dispatch({ type: "open-edit-assignment", assignmentId });
};

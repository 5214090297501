import "./tags-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./tags-cell.pug";
import ko from "knockout";
import type { TagExpirationState } from "@/lib/components/tags/tag-chip";

const TAG_HEIGHT = 20;
const TAG_WIDTH = 38;

export interface TagInstance {
   name: string;
   abbreviation: string;
   color: string;
   expirationState: TagExpirationState;
}

export interface TagsCellParams {
   tagInstances: TagInstance[];
}

export class TagsCell {
   readonly tagInstances: TagInstance[];
   constructor(params: TagsCellParams) {
      this.tagInstances = params.tagInstances;
   }

   static factory<T>(formatter: (params: T) => TagsCellParams): GridCellFactory<T, TagsCellParams> {
      return (data: T, context): GridCell<TagsCellParams> => {
         const params = formatter(data);
         const tagsPerRow = Math.floor(context.width / TAG_WIDTH);
         const rows = Math.ceil(params.tagInstances.length / tagsPerRow);
         return {
            component: {
               name: "tags-cell",
               params,
            },
            height: rows * TAG_HEIGHT,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => TagsCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: TagsCell.factory(provider),
         copyProvider: (data: T) =>
            provider(data)
               .tagInstances.map((tag) => tag.name)
               .join(", "),
      };
   }
}

ko.components.register("tags-cell", {
   viewModel: TagsCell,
   template: template(),
   synchronous: true,
});

import "./color-circle-text-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./color-circle-text-cell.pug";
import ko from "knockout";

export interface ColorCircleTextCellParams {
   text: string;
   color?: string | null;
}

export class ColorCircleTextCell {
   readonly text: string;
   readonly color: string | null;
   constructor({ text, color = null }: ColorCircleTextCellParams) {
      this.text = text;
      this.color = color;
   }

   static factory<T>(
      formatter: (data: T) => ColorCircleTextCellParams,
   ): GridCellFactory<T, ColorCircleTextCellParams> {
      return (data: T): GridCell<ColorCircleTextCellParams> => {
         return {
            component: {
               name: "color-circle-text-cell",
               params: formatter(data),
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => ColorCircleTextCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: ColorCircleTextCell.factory(provider),
         copyProvider: (data: T) => provider(data).text,
      };
   }
}

ko.components.register("color-circle-text-cell", {
   viewModel: ColorCircleTextCell,
   template: template(),
   synchronous: true,
});

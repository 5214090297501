import { Box, Grid, Typography } from "@procore/core-react";
import type { CommentsCardProps } from "../types";
import React, { useMemo } from "react";
import { formatDate } from "@laborchart-modules/common/dist/datetime";
import { formatName } from "@/lib/utils/preferences";
import "../style.css";

export const sortComments = (comments: CommentsCardProps["comments"]) => {
   return [...comments].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
   });
};
const CommentsList: React.FC<CommentsCardProps> = ({ comments }) => {
   const sortedComments = useMemo(() => sortComments(comments), [comments]);
   return (
      <div className="scrollable-container">
         {sortedComments.map((comment) => {
            return (
               <Box key={comment.id} marginBottom="sm">
                  <Grid>
                     <Grid.Row>
                        <Grid.Col style={{ display: "flex", alignItems: "center" }}>
                           <Typography intent="h3" style={{ marginRight: "8px", fontSize: "14px" }}>
                              {comment.author_name
                                 ? formatName(comment.author_name)
                                 : "Unknown Author"}{" "}
                              - {formatDate(new Date(comment.created_at))}
                           </Typography>
                        </Grid.Col>
                     </Grid.Row>
                     <Grid.Row>
                        <Grid.Col>
                           <Typography intent="small" className="typography-small">
                              {comment.content}
                           </Typography>
                        </Grid.Col>
                     </Grid.Row>
                  </Grid>
               </Box>
            );
         })}
      </div>
   );
};

export default React.memo(CommentsList);

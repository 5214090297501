import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";
import type { FindTagsStreamQueryParams } from "@laborchart-modules/lc-core-api/dist/api/tags/find-tags";
import type {
   FindPositionsPaginatedQueryParams,
   FindPositionsPaginatedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/positions/find-positions";
import type { FindStatusesStreamQueryParams } from "@laborchart-modules/lc-core-api/dist/api/statuses/find-statuses";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";
import type {
   UpdateCompanyPayload,
   UpdateCompanyResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-company";
import type { DeleteTagCategoryResponse } from "@laborchart-modules/lc-core-api/dist/api/company/delete-tag-category";
import type { DisableTagCategoriesResponse } from "@laborchart-modules/lc-core-api/dist/api/company/disable-tag-categories";
import type {
   EditTagCategoryPayload,
   EditTagCategoryResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/edit-tag-category";
import type {
   UpdateTagCategoriesPayload,
   UpdateTagCategoriesResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-tag-categories";
import type {
   UpdateAlertConfigPayload,
   UpdateAlertConfigResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-alert-config";
import type { GetAlertConfigResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-alert-config";
import type { GetCostingInfoResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-costing-info";
import type {
   UpdateCostingInfoPayload,
   UpdateCostingInfoResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-costing-info";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import type {
   UpdateIntegratedFieldsPayload,
   UpdateIntegratedFieldsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-integrated-fields";
import type { GetNotificationThresholdsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-notification-thresholds";
import type {
   UpdateNotificationThresholdsPayload,
   UpdateNotificationThresholdsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-notification-thresholds";
import type { GetQrCodeConfigResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-qr-code-config";
import type {
   UpdateQrCodeConfigPayload,
   UpdateQrCodeConfigResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-qr-code-config";
import type { GetSensitiveFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-sensitive-fields";
import type {
   UpdateSensitiveFieldsPayload,
   UpdateSensitiveFieldsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/company/update-sensitive-fields";
import type {
   UpdateUserPayload,
   UpdateUserResponse,
} from "@laborchart-modules/lc-core-api/dist/api/people/update-user";
import type { DeleteNotificationProfileResponse } from "@laborchart-modules/lc-core-api/dist/api/notification-profiles/delete-notification-profile";
import type {
   CreateNotificationProfilePayload,
   CreateNotificationProfileResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notification-profiles/create-notification-profile";
import type {
   UpdateNotificationProfilePayload,
   UpdateNotificationProfileResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notification-profiles/update-notification-profile";

export abstract class SettingsStore extends Store {
   //#region Company
   static updateCompany(update: UpdateCompanyPayload): StoreJsonResponse<UpdateCompanyResponse> {
      return this.requestJson({
         url: `/api/v3/settings/company`,
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Tag Category
   static deleteTagCategory(category: string): StoreJsonResponse<DeleteTagCategoryResponse> {
      return this.requestJson({
         url: `/api/v3/settings/tag-categories/${category}`,
         method: "DELETE",
      });
   }

   static disableTagCategories(): StoreJsonResponse<DisableTagCategoriesResponse> {
      return this.requestJson({
         url: "/api/v3/settings/tag-categories",
         method: "DELETE",
      });
   }

   static editTagCategory(
      body: EditTagCategoryPayload,
   ): StoreJsonResponse<EditTagCategoryResponse> {
      return this.requestJson({
         url: "/api/v3/settings/tag-categories",
         method: "PATCH",
         body,
      });
   }

   static updateTagCategories(
      update: UpdateTagCategoriesPayload,
   ): StoreJsonResponse<UpdateTagCategoriesResponse> {
      return this.requestJson({
         url: "/api/v3/settings/tag-categories",
         method: "POST",
         body: update,
      });
   }
   //#endregion

   //#region Alert Config
   static getAlertConfig(): StoreJsonResponse<GetAlertConfigResponse> {
      return this.requestJson({
         url: "/api/v3/settings/alert-config",
         method: "GET",
      });
   }

   static updateAlertConfig(
      update: UpdateAlertConfigPayload,
   ): StoreJsonResponse<UpdateAlertConfigResponse> {
      return this.requestJson({
         url: "/api/v3/settings/alert-config",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Costing Info
   static getCostingInfo(): StoreJsonResponse<GetCostingInfoResponse> {
      return this.requestJson({
         url: "/api/v3/settings/costing-info",
         method: "GET",
      });
   }
   static updateCostingInfo(
      update: UpdateCostingInfoPayload,
   ): StoreJsonResponse<UpdateCostingInfoResponse> {
      return this.requestJson({
         url: "/api/v3/settings/costing-info",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Integrated Fields
   static getIntegratedFields(): StoreJsonResponse<GetIntegratedFieldsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/integrated-fields",
         method: "GET",
      });
   }

   static updateIntegratedFields(
      update: UpdateIntegratedFieldsPayload,
   ): StoreJsonResponse<UpdateIntegratedFieldsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/integrated-fields",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Notification Thresholds
   static getNotificationThresholds(): StoreJsonResponse<GetNotificationThresholdsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/notification-thresholds",
         method: "GET",
      });
   }

   static updateNotificationThresholds(
      update: UpdateNotificationThresholdsPayload,
   ): StoreJsonResponse<UpdateNotificationThresholdsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/notification-thresholds",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Notication Profiles
   static createNotificationProfile(
      payload: CreateNotificationProfilePayload,
   ): StoreJsonResponse<CreateNotificationProfileResponse> {
      return this.requestJson({
         url: "/api/v3/settings/notification-profiles",
         method: "POST",
         body: payload,
      });
   }

   static deleteNotificationProfile(
      notificationProfileId: string,
   ): StoreJsonResponse<DeleteNotificationProfileResponse> {
      return this.requestJson({
         url: `/api/v3/settings/notification-profiles/${notificationProfileId}`,
         method: "DELETE",
      });
   }

   static updateNotificationProfile(
      notificationProfileId: string,
      payload: UpdateNotificationProfilePayload,
   ): StoreJsonResponse<UpdateNotificationProfileResponse> {
      return this.requestJson({
         url: `/api/v3/settings/notification-profiles/${notificationProfileId}`,
         method: "PATCH",
         body: payload,
      });
   }
   //#endregion

   //#region QR Code Config
   static getQrCodeConfig(): StoreJsonResponse<GetQrCodeConfigResponse> {
      return this.requestJson({
         url: "/api/v3/settings/qr-code-config",
         method: "GET",
      });
   }

   static updateQrCodeConfig(
      update: UpdateQrCodeConfigPayload,
   ): StoreJsonResponse<UpdateQrCodeConfigResponse> {
      return this.requestJson({
         url: "/api/v3/settings/qr-code-config",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Sensitive Fields
   static getSensitiveFields(): StoreJsonResponse<GetSensitiveFieldsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/sensitive-fields",
         method: "GET",
      });
   }

   static updateSensitiveFields(
      update: UpdateSensitiveFieldsPayload,
   ): StoreJsonResponse<UpdateSensitiveFieldsResponse> {
      return this.requestJson({
         url: "/api/v3/settings/sensitive-fields",
         method: "PATCH",
         body: update,
      });
   }
   //#endregion

   //#region Tag
   static findTagsStream(query: FindTagsStreamQueryParams): StoreStreamResponse<SerializedTag> {
      return this.requestStream({
         url: "/api/v3/settings/tags/stream",
         method: "GET",
         query,
      });
   }
   //#endregion

   //#region Status
   static findStatusesStream(
      query: FindStatusesStreamQueryParams,
   ): StoreStreamResponse<SerializedStatus> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/settings/statuses/stream",
      });
   }
   //#endregion

   //#region Position
   static findPositionsPaginated(
      query: FindPositionsPaginatedQueryParams,
   ): StoreJsonResponse<FindPositionsPaginatedResponse> {
      return this.requestJson({
         method: "GET",
         query,
         url: "/api/v3/settings/job-titles",
      });
   }
   //#endregion

   //#region Person
   static updateUser(update: UpdateUserPayload): StoreJsonResponse<UpdateUserResponse> {
      return this.requestJson({
         url: `/api/v3/settings/people/user`,
         method: "PATCH",
         body: update,
      });
   }
}

import "./popup-list-pane.styl"
import template from "./popup-list-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"

export class PopupListPane extends PopupPane
   constructor: (title, items, options)->
      super(template())
      @defaultTitle = ko.observable(title)
      @defaultLeftActionBtn = ko.observable(false)
      @defaultRightActionBtn = ko.observable(false)
      @selectedItem = ko.observable()
      @items = ko.observableArray(items)
      @hasSelectedItem = ko.pureComputed => return @selectedItem()?
      @title = ko.pureComputed =>
         return if @selectedItem()?.title()? then @selectedItem().title() else @defaultTitle()
      @leftActionBtn = ko.pureComputed =>
         return if @selectedItem()?.leftActionBtn()? then @selectedItem().leftActionBtn() else @defaultLeftActionBtn
      @rightActionBtn = ko.pureComputed =>
         return if @selectedItem()?.rightActionBtn()? then @selectedItem().rightActionBtn() else @defaultRightActionBtn
      @showArrows = ko.observable(if options? then options.showArrows else true)

      # Watch each popup list item for isOpen changes.
      createSubscriptionObject = (item) =>
         return {
            item: item
            subscription: item.isOpen.subscribe(@onIsOpenChanged_.bind(@, item))
         }

      @subscriptionObjects = []
      for item in @items()
         @subscriptionObjects.push(createSubscriptionObject(item))

      @items.subscribe (items) =>
         validObjects = []
         # Dispose of subscriptions that are no longer needed.
         for obj in @subscriptionObjects
            if items.indexOf(obj.item) == -1
               obj.subscription.dispose()
            else
               validObjects(obj)

         # Subscribe to new items.
         for item in items
            continue if item.type != PaletteItem.TYPE.SLIDE
            found = false
            for obj in @subscriptionObjects
               if obj.item == item
                  found = true
                  break
            unless found
               validObjects.push(createSubscriptionObject(item))

         subscriptionObjects = validObjects

   ### Called when this pane has been transitioned to. Direction = (forward/back) ###
   transitionedTo: (direction) ->
      assertArgs(arguments, String)

   ### Called when this pane has been transitioned from. Direction = (forward/back). ###
   transitionedFrom: (direction) ->
      assertArgs(arguments, String)

   onIsOpenChanged_: (listItem, isOpen) ->
      assertArgs(arguments, Object, Boolean)
      if isOpen
         @selectedItem(listItem)
      else if listItem == @selectedItem() and !isOpen
         @selectedItem(null)

   handleItemSelection: (item) =>
      if item.callbackData?
         item.clickCallback(item.callbackData)
         return @dismissHandler()
      # TODO: Why aren't we also hitting the dismiss handler here? 
      return item.clickCallback()
import "./custom-field-editor-element.styl";
import template from "./custom-field-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { SerializedCustomFieldInstance } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-instance-serializer";
import type {
   CustomFieldInstance,
   CustomFieldValue,
} from "@laborchart-modules/common/dist/rethink/schemas";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas";
import type { CustomFieldTextEditorElementParams } from "../custom-field-text-editor-element/custom-field-text-editor-element";
import { CustomFieldTextEditorElement } from "../custom-field-text-editor-element/custom-field-text-editor-element";
import type { CustomFieldNumberEditorElementParams } from "../custom-field-number-editor-element/custom-field-number-editor-element";
import { CustomFieldNumberEditorElement } from "../custom-field-number-editor-element/custom-field-number-editor-element";
import type { CustomFieldBoolEditorElementParams } from "../custom-field-bool-editor-element/custom-field-bool-editor-element";
import { CustomFieldBoolEditorElement } from "../custom-field-bool-editor-element/custom-field-bool-editor-element";
import type { CustomFieldCurrencyEditorElementParams } from "../custom-field-currency-editor-element/custom-field-currency-editor-element";
import { CustomFieldCurrencyEditorElement } from "../custom-field-currency-editor-element/custom-field-currency-editor-element";
import type { CustomFieldDateEditorElementParams } from "../custom-field-date-editor-element/custom-field-date-editor-element";
import { CustomFieldDateEditorElement } from "../custom-field-date-editor-element/custom-field-date-editor-element";
import type { CustomFieldHexColorEditorElementParams } from "../custom-field-hex-color-editor-element/custom-field-hex-color-editor-element";
import { CustomFieldHexColorEditorElement } from "../custom-field-hex-color-editor-element/custom-field-hex-color-editor-element";
import type { CustomFieldMultiSelectEditorElementParams } from "../custom-field-multi-select-editor-element/custom-field-multi-select-editor-element";
import { CustomFieldMultiSelectEditorElement } from "../custom-field-multi-select-editor-element/custom-field-multi-select-editor-element";
import type { CustomFieldParagraphEditorElementParams } from "../custom-field-paragraph-editor-element/custom-field-paragraph-editor-element";
import { CustomFieldParagraphEditorElement } from "../custom-field-paragraph-editor-element/custom-field-paragraph-editor-element";
import type { CustomFieldSelectEditorElementParams } from "../custom-field-select-editor-element/custom-field-select-editor-element";
import { CustomFieldSelectEditorElement } from "../custom-field-select-editor-element/custom-field-select-editor-element";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";

export type NullableCustomFieldValue = CustomFieldValue | null;
export type NullableValueCustomFieldInstance = Omit<CustomFieldInstance, "value"> & {
   value: NullableCustomFieldValue;
};

export type CustomFieldEditorElementParams = {
   customField: SerializedCustomField;
   customFieldInstance: PureComputed<NullableValueCustomFieldInstance>;
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
} & EditorElementParams<SerializedCustomFieldInstance["value"] | null>;

type CustomFieldEditorParamsUnion =
   | CustomFieldBoolEditorElementParams
   | CustomFieldCurrencyEditorElementParams
   | CustomFieldDateEditorElementParams
   | CustomFieldHexColorEditorElementParams
   | CustomFieldMultiSelectEditorElementParams
   | CustomFieldNumberEditorElementParams
   | CustomFieldParagraphEditorElementParams
   | CustomFieldSelectEditorElementParams
   | CustomFieldTextEditorElementParams;

export class CustomFieldEditorElement extends ViewModel {
   readonly customField: CustomFieldEditorElementParams["customField"];
   readonly customFieldInstance: CustomFieldEditorElementParams["customFieldInstance"];
   readonly invalidInputMessage: CustomFieldEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldEditorElementParams["onChange"];
   readonly title: CustomFieldEditorElementParams["title"];

   readonly specificEditor: ComponentArgs<CustomFieldEditorParamsUnion>;
   readonly generateEditor = (): ComponentArgs<CustomFieldEditorParamsUnion> => {
      const customField = this.customField;

      const genericCustomFieldEditorParams = {
         invalidInputMessage: this.invalidInputMessage,
         isDisabled: this.isDisabled,
         onChange: this.onChange,
         requestSize: () => {},
         title: customField.name,
      };
      switch (customField.type) {
         case CustomFieldType.BOOL:
            return CustomFieldBoolEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<boolean | null>(
                  () => (this.customFieldInstance()?.value as boolean | null) ?? null,
               ),
            });
         case CustomFieldType.CURRENCY:
            return CustomFieldCurrencyEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<number | null>(
                  () => (this.customFieldInstance()?.value as number | null) ?? null,
               ),
            });
         case CustomFieldType.DATE:
            return CustomFieldDateEditorElement.factory({
               ...genericCustomFieldEditorParams,
               isClearable: true,
               value: pureComputed<Date | null>(() => {
                  const instance = this.customFieldInstance();
                  return instance == null || instance.value == null
                     ? null
                     : getAttachedDate(instance.value as number);
               }),
            });
         case CustomFieldType.HEX_COLOR:
            return CustomFieldHexColorEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<string | null>(
                  () => (this.customFieldInstance()?.value as string | null) ?? null,
               ),
            });
         case CustomFieldType.MULTI_SELECT:
            return CustomFieldMultiSelectEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<string[] | null>(
                  () => (this.customFieldInstance()?.value as string[] | null) ?? null,
               ),
               customFieldOptions: customField.values,
            });
         case CustomFieldType.NUMBER:
            return CustomFieldNumberEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<number | null>(
                  () => (this.customFieldInstance()?.value as number | null) ?? null,
               ),
            });
         case CustomFieldType.PARAGRAPH:
            return CustomFieldParagraphEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<string | null>(
                  () => (this.customFieldInstance()?.value as string | null) ?? null,
               ),
            });
         case CustomFieldType.SELECT:
            return CustomFieldSelectEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<string | null>(
                  () => (this.customFieldInstance()?.value as string | null) ?? null,
               ),
               customFieldOptions: customField.values,
            });
         case CustomFieldType.TEXT:
            return CustomFieldTextEditorElement.factory({
               ...genericCustomFieldEditorParams,
               value: pureComputed<string | null>(
                  () => (this.customFieldInstance()?.value as string | null) ?? null,
               ),
            });
      }
   };

   constructor({
      customField,
      customFieldInstance,
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
   }: CustomFieldEditorElementParams) {
      super(template());

      this.customField = customField;
      this.customFieldInstance = customFieldInstance;
      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.specificEditor = this.generateEditor();
   }

   static factory(
      params: CustomFieldEditorElementParams,
   ): ComponentArgs<CustomFieldEditorElementParams> {
      return {
         name: "custom-field-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-editor-element", {
   viewModel: CustomFieldEditorElement,
   template: template(),
   synchronous: true,
});

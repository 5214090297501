import "./disabled-groups-editor.styl";
import type { MaybeObservable, MaybeObservableArray, Observable, ObservableArray } from "knockout";
import ko, { isObservable, observable, observableArray, pureComputed } from "knockout";
import template from "./disabled-groups-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";

export interface DisabledGroupsEditorParams {
   errorText: MaybeObservable<string>;
   groupNameList?: MaybeObservableArray<string>;
   width?: MaybeObservable<number>;
}

export type FullDisabledGroupsEditorParams = DisabledGroupsEditorParams &
   EditorComponentParams<null>;

const DEFAULT_WIDTH = 224;
const HORIZONTAL_MARGIN = 24;

export class DisabledGroupsEditor {
   readonly errorText: Observable<string>;
   readonly groupNameList: ObservableArray<string>;
   readonly width: Observable<number>;

   readonly visibleWidth = pureComputed(() => {
      return `${this.width() - HORIZONTAL_MARGIN}px`;
   });

   constructor(private readonly params: FullDisabledGroupsEditorParams) {
      this.errorText = isObservable(this.params.errorText)
         ? this.params.errorText
         : (observable(this.params.errorText) as Observable<string>);
      this.groupNameList = isObservable(this.params.groupNameList)
         ? this.params.groupNameList
         : observableArray(this.params.groupNameList || []);
      this.groupNameList =
         this.groupNameList().length == 0
            ? observableArray(["Groups Not Found"])
            : this.groupNameList;
      this.width = isObservable(this.params.width)
         ? this.params.width
         : (observable(this.params.width || DEFAULT_WIDTH) as Observable<number>);
   }

   onClose = (): void => {
      if (this.params.onClose) this.params.onClose({ hasSaved: false });
   };

   static create(
      params: DisabledGroupsEditorParams,
   ): ComponentArgs<FullDisabledGroupsEditorParams> {
      return {
         name: "disabled-groups-editor",
         params: {
            saveProvider: async () => {},
            title: null,
            value: null,
            ...params,
         },
      };
   }

   static factory<T>(
      provider: (records: T[]) => DisabledGroupsEditorParams,
   ): (records: T[]) => ComponentArgs<FullDisabledGroupsEditorParams> {
      return (records) => DisabledGroupsEditor.create(provider(records));
   }
}

ko.components.register("disabled-groups-editor", {
   viewModel: DisabledGroupsEditor,
   template: template(),
   synchronous: true,
});

import type { Size } from "./geometry";

const MEASURING_ELEMENT = document.createElement("span");

export type MeasureTextParams = {
   classes?: string[];
   width?: number;
   height?: number;
   text: string;
};

/**
 * Calculate the height/width of an element with the given classes that is needed
 * to contain the given text.
 */
export function measureText({ classes, width, height, text }: MeasureTextParams): Size {
   // Create an element to use for measuring text.
   if (!MEASURING_ELEMENT.parentElement) {
      MEASURING_ELEMENT.style.position = "absolute";
      MEASURING_ELEMENT.style.top = "-99999px";
      MEASURING_ELEMENT.style.left = "-99999px";
      document.body.appendChild(MEASURING_ELEMENT);
   }
   MEASURING_ELEMENT.className = classes?.join(" ") ?? "";

   MEASURING_ELEMENT.style.width = width ? `${width}px` : "";
   MEASURING_ELEMENT.style.height = height ? `${height}px` : "";
   MEASURING_ELEMENT.textContent = text;

   return {
      height: MEASURING_ELEMENT.clientHeight,
      width: MEASURING_ELEMENT.clientWidth,
   };
}

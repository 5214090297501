import React from "react";
import { Card, Box, FlexList, Title, H2, Form, Typography } from "@procore/core-react";
import { formatOptions } from "@/react/shared/helper";
import { AssignmentSwitch } from "../assignment/assignment-switch";
import { WorkDaysCheckboxes } from "../../labor-plans/work-days-checkboxes";
import type { OverTimeProps, ScheduleCardProps } from "../types";
import { timeOptions } from "@/lib/utils/timezones";
import { TagSelector } from "./tag-selector";
import { FormSwitch } from "./form-switch";
import { Error } from "@procore/core-icons";
import { getTotalHours } from "../helper";
import { days, paySplitFields } from "@/react/shared/constants";

type columnStart = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const ScheduleCard: React.FC<ScheduleCardProps> = ({
   I18n,
   setFieldValue,
   assignmentCreationSupportData,
   selectedWorkDays,
   setSelectedWorkDays,
   assignmentByTime,
   setAssignmentByTime,
   assignmentByAllocation,
   setAssignmentByAllocation,
   canViewAllStatuses,
   currentAssignments = null,
   showTagSelector = false,
   overTimeProps = {},
}) => {
   const {
      showOverTime,
      isOverTimeSelected,
      setIsOverTimeSelected,
      defaultOverTimeRates,
      errors,
      values,
      setTotalHours,
   } = overTimeProps as OverTimeProps;

   return (
      <Card style={{ marginBottom: "10px" }}>
         <Box padding="md">
            {currentAssignments}
            <FlexList justifyContent="space-between" marginBottom="sm">
               <Title>
                  <Title.Text>
                     <H2>{I18n.t("views.company.workforce_planning.schedule")}</H2>
                  </Title.Text>
               </Title>
            </FlexList>
            <Form.Form>
               <Form.Row>
                  <Form.DateSelect
                     colStart={1}
                     colWidth={3}
                     name="start_date"
                     label={I18n.t("views.company.workforce_planning.assignment.start_date")}
                     required
                  />
                  <Form.DateSelect
                     colStart={4}
                     colWidth={3}
                     name="end_date"
                     label={I18n.t("views.company.workforce_planning.assignment.end_date")}
                     required
                  />
                  {assignmentByTime && (
                     <>
                        <Form.Select
                           colStart={7}
                           colWidth={3}
                           name="start_time"
                           label={I18n.t("views.company.workforce_planning.assignment.start_time")}
                           options={timeOptions}
                           onSearch={false}
                           required
                           placeholder={I18n.t("views.company.workforce_planning.select_option")}
                           onSelect={({ item }) => {
                              if (setTotalHours) {
                                 setTotalHours(getTotalHours(values.end_time.id, item.id));
                              }
                           }}
                        />
                        <Form.Select
                           colStart={10}
                           colWidth={3}
                           name="end_time"
                           label={I18n.t("views.company.workforce_planning.assignment.end_time")}
                           options={timeOptions}
                           onSearch={false}
                           required
                           placeholder={I18n.t("views.company.workforce_planning.select_option")}
                           onSelect={({ item }) => {
                              if (setTotalHours) {
                                 setTotalHours(getTotalHours(item.id, values.start_time.id));
                              }
                           }}
                        />
                     </>
                  )}
                  {assignmentByAllocation && (
                     <Form.Number
                        colStart={7}
                        colWidth={3}
                        name="percent_allocated"
                        label={I18n.t(
                           "views.company.workforce_planning.assignment.percent_allocated",
                        )}
                        required
                        placeholder={I18n.t("views.company.workforce_planning.enter_value")}
                     />
                  )}
               </Form.Row>
               <Form.Row>
                  <Form.Field
                     name="assignment_by_time"
                     as={AssignmentSwitch}
                     colStart={1}
                     colWidth={6}
                     inlineLabel={I18n.t(
                        "views.company.workforce_planning.assignment.assignment_by_time",
                     )}
                     setValues={setFieldValue}
                     value={assignmentByTime}
                     toggleAssignmentByAllocation={setAssignmentByAllocation}
                     toggleAssignmentByTime={setAssignmentByTime}
                  />
                  <Form.Field
                     name="assignment_by_allocation"
                     as={AssignmentSwitch}
                     colStart={7}
                     colWidth={6}
                     inlineLabel={I18n.t(
                        "views.company.workforce_planning.assignment.assignment_by_allocation",
                     )}
                     setValues={setFieldValue}
                     value={assignmentByAllocation}
                     toggleAssignmentByAllocation={setAssignmentByAllocation}
                     toggleAssignmentByTime={setAssignmentByTime}
                  />
               </Form.Row>
               <Form.Row>
                  <Form.Field
                     colStart={1}
                     colWidth={6}
                     as={WorkDaysCheckboxes}
                     label={I18n.t("views.company.workforce_planning.work_days")}
                     required
                     name="work_days"
                     setSelectedWorkDays={setSelectedWorkDays}
                     value={selectedWorkDays}
                     setValues={setFieldValue}
                  />
                  {showTagSelector && (
                     <Form.Field
                        name="tags"
                        as={TagSelector}
                        colStart={7}
                        colWidth={6}
                        label={I18n.t("views.company.workforce_planning.projects.tags")}
                     />
                  )}
               </Form.Row>
               {!canViewAllStatuses && (
                  <Form.Row>
                     <Form.Select
                        colStart={1}
                        colWidth={6}
                        name="status"
                        label={I18n.t("views.company.workforce_planning.projects.status")}
                        placeholder={I18n.t("views.company.workforce_planning.select_option")}
                        options={formatOptions(
                           assignmentCreationSupportData?.data.status_options ?? [],
                        )}
                        required
                     />
                  </Form.Row>
               )}
               {showOverTime && assignmentByTime && (
                  <>
                     <Form.Row>
                        <Form.Field
                           name="overtime"
                           as={FormSwitch}
                           colStart={1}
                           colWidth={6}
                           inlineLabel={I18n.t(
                              "views.company.workforce_planning.assignment.overtime",
                           )}
                           value={isOverTimeSelected}
                           toggleValue={setIsOverTimeSelected}
                           setValues={setFieldValue}
                        />
                     </Form.Row>

                     {isOverTimeSelected && (
                        <>
                           {/* Weekday Overtime Rates Row */}
                           <Form.Row>
                              {days.map((day, index) => (
                                 <Form.Number
                                    key={day}
                                    name={`overtime_rates.${day}`}
                                    colStart={(1 + index * 2) as columnStart}
                                    colWidth={2}
                                    label={I18n.t(`views.company.workforce_planning.${day}_abbrev`)}
                                    style={{ width: "120px" }}
                                    onChange={(value) => {
                                       if (value.parsedNumber === null && defaultOverTimeRates) {
                                          setFieldValue(
                                             `overtime_rates.${day}`,
                                             defaultOverTimeRates[
                                                day as keyof typeof defaultOverTimeRates
                                             ],
                                          );
                                       }
                                    }}
                                 />
                              ))}
                           </Form.Row>
                           {/* Pay Split Label and Error Message */}
                           <FlexList>
                              <Typography intent="label">
                                 {I18n.t("views.company.workforce_planning.assignment.pay_split")}
                              </Typography>
                              {errors.pay_split && (
                                 <div
                                    className="error-container"
                                    style={{ display: "flex", alignItems: "center", gap: "4px" }}
                                 >
                                    <Error size="sm" className="error-icon" />
                                    <span className="error-text">{errors.pay_split}</span>
                                 </div>
                              )}
                           </FlexList>

                           {/* Pay Split Fields */}
                           <Form.Row style={{ marginTop: "10px" }}>
                              {paySplitFields.map((field, index) => (
                                 <Form.Number
                                    key={field}
                                    name={`pay_split.${field}`}
                                    colStart={(1 + index * 4) as columnStart}
                                    colWidth={4}
                                    label={I18n.t(
                                       `views.company.workforce_planning.assignment.${field}`,
                                    )}
                                 />
                              ))}
                           </Form.Row>
                        </>
                     )}
                  </>
               )}
            </Form.Form>
         </Box>
      </Card>
   );
};

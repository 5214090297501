import "./request-comments-pane.styl";
import template from "./request-comments-pane.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { Observable, PureComputed } from "knockout";
import ko, { observable, pureComputed } from "knockout";
import type { CanRequestSize } from "@/lib/components/modals/common/sized-modal";
import type { ComponentArgs } from "@/lib/components/common";
import { formatName } from "@/lib/utils/preferences";
import { formatDate } from "@laborchart-modules/common/dist/datetime";
import type { UpdateRequestCommentsPayload } from "@laborchart-modules/lc-core-api";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { StagedRequest } from "../../request-details-modal";

export type RequestCommentsPaneParams = {
   request: PureComputed<SerializedFindRequest | StagedRequest>;
   stagedCommentContent: PureComputed<UpdateRequestCommentsPayload[0]["content"] | null>;
   updateNewComment: (text: string | null) => void;
} & CanRequestSize;

export class RequestCommentsPane extends ViewModel implements CanRequestSize {
   readonly request: RequestCommentsPaneParams["request"];
   readonly stagedCommentContent: RequestCommentsPaneParams["stagedCommentContent"];
   readonly updateNewComment: RequestCommentsPaneParams["updateNewComment"];

   readonly requestSize: CanRequestSize["requestSize"];

   readonly description = `
      These comments are only visible in the app and will never be included in
      Assignment Alerts that are sent out when this Request is filled.
      `;
   readonly noCommentsText = `No one has made a comment yet.`;

   readonly value: Observable<string | null>;
   readonly newComment = pureComputed({
      read: () => this.stagedCommentContent() ?? "",
      write: (text: string | null) => {
         this.value(text);
         this.updateNewComment(text);
      },
   });
   readonly comments = pureComputed(() => {
      return this.request().comments.sort((left, right) => right.created_at - left.created_at);
   });
   readonly hasAnyComments = pureComputed(() => this.comments().length > 0);

   constructor({
      request,
      stagedCommentContent,
      updateNewComment,
      requestSize,
   }: RequestCommentsPaneParams) {
      super(template());
      this.request = request;
      this.requestSize = requestSize;
      this.stagedCommentContent = stagedCommentContent;
      this.updateNewComment = updateNewComment;
      this.value = observable(null);

      requestSize({
         width: 660,
         height: 500,
      });
   }

   getMetadataString(comment: SerializedFindRequest["comments"][0]): string {
      const name = comment.author_name ? formatName(comment.author_name) : "Unknown Author";
      return `${name} - ${formatDate(new Date(comment.created_at))}`;
   }

   static factory(params: RequestCommentsPaneParams): ComponentArgs<RequestCommentsPaneParams> {
      return {
         name: "request-comments-pane",
         params,
      };
   }
}

ko.components.register("request-comments-pane", {
   viewModel: RequestCommentsPane,
   template: template(),
   synchronous: true,
});

import React, { useState } from "react";
import { ZIndexProvider, useI18nContext } from "@procore/core-react";
import { CaretDown, CaretUp, Clear } from "@procore/core-icons";
import {
   StyledActionButton,
   StyledActions,
   StyledBody,
   StyledContainer,
   StyledCount,
   StyledHeader,
   StyledTitle,
} from "./file-download-toast.styl";
import { FileDownloadToastItem } from "./item";

export const FileDownloadToastOverlay = (props: any) => {
   const I18n = useI18nContext();
   const [collapsed, setCollapsed] = useState(false);
   const itemsList = props.items ?? [];

   return (
      <ZIndexProvider initialValue={1000}>
         <StyledContainer
            data-qa="file-upload-toast-overlay"
            opened={!collapsed}
            isStickyPage={props.isStickyPage}
         >
            <StyledHeader direction="row">
               <StyledTitle alignSelf="stretch" flexGrow="2">
                  {I18n.t("views.company.workforce_planning.file_download_toast.generated_reports")}
                  <StyledCount data-qa="file-upload-toast-count">{itemsList.length}</StyledCount>
               </StyledTitle>
               <StyledActions alignSelf="center" flexGrow="0">
                  <StyledActionButton
                     title={
                        collapsed
                           ? I18n.t("views.company.workforce_planning.file_download_toast.expand")
                           : I18n.t("views.company.workforce_planning.file_download_toast.collapse")
                     }
                     variant="tertiary"
                     onClick={() => setCollapsed(!collapsed)}
                     aria-label={I18n.t(
                        "views.company.workforce_planning.file_download_toast.collapse",
                     )}
                     icon={
                        collapsed ? (
                           <CaretUp color="white" size="lg" />
                        ) : (
                           <CaretDown color="white" size="lg" />
                        )
                     }
                  />
                  <StyledActionButton
                     variant="tertiary"
                     onClick={() => props.setVisible(false)}
                     icon={<Clear color="white" size="lg" />}
                  />
               </StyledActions>
            </StyledHeader>
            <StyledBody>
               {itemsList.map((item: { id: string; name: string }) => (
                  <FileDownloadToastItem key={item.id} item={item} />
               ))}
            </StyledBody>
         </StyledContainer>
      </ZIndexProvider>
   );
};

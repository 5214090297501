import { useState } from "react";

// Custom hook to handle modal open and close
export const useModal = () => {
   const [isOpen, setIsOpen] = useState(false);

   const openModal = () => setIsOpen(true);
   const closeModal = () => setIsOpen(false);

   return {
      isOpen,
      openModal,
      closeModal,
   };
};

import template from "./boards-config-pane.pug"
import ko from "knockout"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

export class BoardsConfigPane extends PopupPane
   constructor: (selections, applyCallback) ->
      assertArgs(arguments, Object, Function)
      super(template(), "Configure View")

      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Reset Defaults"
      })
      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
      })
      @enableOverflow = ko.observable(true)
      @applyCallback = applyCallback

      # Remove this property when the LD flag is removed (WFP-1231)
      @launchDarklyEnabledForResourceGroupFiltering = LaunchDarklyBrowser.getFlagValue("gantt-group-filter-by-default") ? false

      @launchDarklyEnabledForMultipleColumnLayout = LaunchDarklyBrowser.getFlagValue("boards-multiple-column-layout")

      # If LD flag enabled, default value is false (new default behavior), otherwise default value is true (traditional default behavior)
      @showResourcesNotInGroup = ko.observable(selections.showResourcesNotInGroup ? !@launchDarklyEnabledForResourceGroupFiltering)
      @multipleColumnLayout = ko.observable(selections.multipleColumnLayout ? !@launchDarklyEnabledForMultipleColumnLayout)

   applyChanges: =>
      data = {
         showResourcesNotInGroup: @showResourcesNotInGroup()
         multipleColumnLayout: @multipleColumnLayout()
      }
      @applyCallback(data)
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @applyCallback(null)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

import type { useI18nContext } from "@procore/core-react";
import { object, date, array, string } from "yup";

export const bulkSendMessageSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   shouldScheduleDelivery: boolean,
) => {
   if (!shouldScheduleDelivery) {
      return object().shape({
         recipients: array()
            .required(I18n.t("views.company.workforce_planning.validations.required_field"))
            .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
         subject: string()
            .trim()
            .required(I18n.t("views.company.workforce_planning.validations.required_field"))
            .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
         content: string()
            .trim()
            .required(I18n.t("views.company.workforce_planning.validations.required_field"))
            .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
      });
   }

   return object().shape({
      recipients: array()
         .required(I18n.t("views.company.workforce_planning.validations.required_field"))
         .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
      subject: string()
         .trim()
         .required(I18n.t("views.company.workforce_planning.validations.required_field"))
         .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
      content: string()
         .trim()
         .required(I18n.t("views.company.workforce_planning.validations.required_field"))
         .min(1, I18n.t("views.company.workforce_planning.validations.required_field")),
      scheduled_day: date().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      hour: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      minute: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      day_night: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   });
};

import type { Observable, ObservableArray, PureComputed, Subscription } from "knockout";
import { observable, pureComputed } from "knockout";
import { CheckboxCell } from "../cells/checkbox-cell";
import type { GridColumnGroup } from "../grid-column-group";
import type { RowBase } from "../grid-store";

const ENTER_KEY = "Enter";

export class CheckboxColumnGroupManager<T extends RowBase> {
   readonly columnGroup: GridColumnGroup<T>;
   readonly hasAllChecked = observable(false);

   private readonly selectedIds: ObservableArray<string>;
   private readonly allIds: PureComputed<string[]> | Observable<string[]>;
   private readonly headerCheckboxValue = pureComputed({
      read: () => {
         if (this.hasAllChecked()) return true;
         const currentlySelectedCount = this.getCurrentlySelectedCount();
         if (currentlySelectedCount == 0) return false;
         return currentlySelectedCount == this.allIds().length ? true : null;
      },
      write: (value: boolean | null) => {
         this.lastSelectedId = null;
         const currentlySelectedCount = this.getCurrentlySelectedCount();
         if (
            !value ||
            (currentlySelectedCount != 0 && currentlySelectedCount != this.allIds().length)
         ) {
            // Change the state to always move from `null` => `false` instead of `null` => `true`.
            this.selectedIds([]);
            this.hasAllChecked(false);
         } else {
            this.selectedIds([...this.allIds()]);
            this.hasAllChecked(true);
         }
      },
      owner: this,
   });
   private readonly checkboxObservables = new Map<string, Observable<boolean>>();
   private readonly subscriptions: Subscription[] = [];
   private ignoreCheckboxChanges = false;
   private isShiftDown = false;
   private lastSelectedId: string | null = null;

   constructor({
      selectedIds,
      allIds,
   }: {
      selectedIds: ObservableArray<string>;
      allIds: PureComputed<string[]> | Observable<string[]>;
   }) {
      this.selectedIds = selectedIds;
      this.allIds = allIds;
      this.subscriptions.push(
         selectedIds.subscribe(this.onSelectedIdsChanged, this),
         allIds.subscribe(this.onAllIdsChanged, this),
      );
      this.columnGroup = {
         columns: [
            {
               key: "selected-ids-column",
               header: {
                  name: "checkbox-cell",
                  params: {
                     value: this.headerCheckboxValue,
                     isFocusable: true,
                  },
               },
               width: 32,
               ...CheckboxCell.columnProviders(
                  (record: T) =>
                     ({
                        value: this.getOrCreateObservable(record),
                     } as any),
               ),
            },
         ],
      };

      window.addEventListener("keydown", this.onKeyDown, { capture: true });
      window.addEventListener("click", this.onClick, { capture: true });
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
      window.removeEventListener("keydown", this.onKeyDown, { capture: true });
      window.removeEventListener("click", this.onClick, { capture: true });
   }

   private getOrCreateObservable(record: T) {
      if (this.checkboxObservables.has(record.id)) {
         return this.checkboxObservables.get(record.id)!;
      }
      const checkboxObservable = observable(this.selectedIds().includes(record.id));
      this.subscriptions.push(
         checkboxObservable.subscribe((isChecked) =>
            this.onCheckboxValueChanged(record.id, isChecked),
         ),
      );
      this.checkboxObservables.set(record.id, checkboxObservable);
      return checkboxObservable;
   }

   private onSelectedIdsChanged(selectedIds: string[]) {
      this.ignoreCheckboxChanges = true;
      for (const [id, checkboxObservable] of this.checkboxObservables.entries()) {
         checkboxObservable(selectedIds.includes(String(id)));
      }
      this.ignoreCheckboxChanges = false;
   }

   private onClick = (event: MouseEvent): void => {
      this.isShiftDown = event.shiftKey ? true : false;
   };

   private onKeyDown = (event: KeyboardEvent): void => {
      this.isShiftDown = event.key === ENTER_KEY && event.shiftKey ? true : false;
   };

   private onAllIdsChanged(allIds: string[]) {
      if (allIds.length == 0) {
         this.hasAllChecked(false);
      } else if (this.hasAllChecked() && allIds.length != this.selectedIds().length) {
         const selectedIds = this.selectedIds();
         selectedIds.push(...allIds.filter((id) => selectedIds.indexOf(id) == -1));
      }
   }

   private onCheckboxValueChanged(id: string, isChecked: boolean) {
      if (this.ignoreCheckboxChanges) return;
      const isSelected = this.selectedIds().includes(id);
      if (isChecked && !isSelected) {
         this.selectedIds.push(id);
      } else if (!isChecked && isSelected) {
         this.selectedIds.remove(id);
         this.hasAllChecked(false);
      }

      this.handleMultiToggle(id);

      this.lastSelectedId = id;
   }

   private handleMultiToggle(id: string) {
      if (!this.isShiftDown || this.lastSelectedId == null) return;

      const selectedIndex = this.allIds().indexOf(id);
      const lastIndex = this.allIds().indexOf(this.lastSelectedId);

      const newlyChangedIds = this.allIds().slice(
         selectedIndex < lastIndex ? selectedIndex : lastIndex,
         selectedIndex < lastIndex ? lastIndex + 1 : selectedIndex + 1,
      );

      const newSelectedState = this.selectedIds().includes(id);

      if (newlyChangedIds.length > 0) {
         if (newSelectedState) {
            this.selectedIds.push(
               ...newlyChangedIds.filter((el) => this.selectedIds.indexOf(el) == -1),
            );
         } else {
            this.selectedIds.remove((el) => newlyChangedIds.includes(el));
         }
      }
   }

   private getCurrentlySelectedCount(): number {
      return this.selectedIds().reduce(
         (acc, cur) => acc + (this.allIds().includes(cur) ? 1 : 0),
         0,
      );
   }
}

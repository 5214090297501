import "./text-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./text-cell.pug";
import ko from "knockout";

export interface TextCellParams {
   value: string;
   isItalicized?: boolean;
}

export class TextCell {
   readonly value: string;
   readonly isItalicized: Exclude<TextCellParams["isItalicized"], undefined>;

   constructor(params: TextCellParams) {
      this.isItalicized = params.isItalicized ?? false;
      this.value = params.value;
   }

   static factory<T>(formatter: (data: T) => string): GridCellFactory<T, TextCellParams> {
      return (data: T): GridCell<TextCellParams> => {
         return {
            component: {
               name: "text-cell",
               params: {
                  value: formatter(data),
               },
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => string): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: TextCell.factory(provider),
         copyProvider: (data: T) => provider(data),
      };
   }
}

ko.components.register("text-cell", {
   viewModel: TextCell,
   template: template(),
   synchronous: true,
});

import template from "./custom-field-paragraph-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";

export type CustomFieldParagraphEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<string | null>;
} & EditorElementParams<string | null>;

export class CustomFieldParagraphEditorElement extends ViewModel {
   readonly invalidInputMessage: CustomFieldParagraphEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldParagraphEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldParagraphEditorElementParams["onChange"];
   readonly title: CustomFieldParagraphEditorElementParams["title"];
   readonly value: CustomFieldParagraphEditorElementParams["value"];

   readonly textFieldMediator = pureComputed<string | null>({
      read: () => unwrap(this.value),
      write: (value) => {
         this.onChange(value != null && value.length > 0 ? value : null);
      },
   });

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldParagraphEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;
   }

   static factory(
      params: CustomFieldParagraphEditorElementParams,
   ): ComponentArgs<CustomFieldParagraphEditorElementParams> {
      return {
         name: "custom-field-paragraph-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-paragraph-editor-element", {
   viewModel: CustomFieldParagraphEditorElement,
   template: template(),
   synchronous: true,
});

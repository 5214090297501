import type { MaybeSubscribable, Observable, Subscription } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import "./number-editor.styl";
import template from "./number-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export interface NumberEditorParams extends EditorComponentParams<number | null> {
   isRequired?: MaybeSubscribable<boolean>;
}

export class NumberEditor extends AbstractFieldEditor<number | null> {
   readonly inputValue: Observable<string | null>;

   readonly hasChanged = pureComputed(() => {
      return this.value() !== this.initialValue;
   });

   private readonly subscription: Subscription;

   constructor(private readonly params: NumberEditorParams) {
      super(params, observable(unwrap(params.value)));
      this.inputValue = observable(this.value() != null ? String(this.value()) : null);
      this.subscription = this.inputValue.subscribe((value) => {
         const valueAsNumber = Number(value?.replace(/,/g, ""));
         this.value(value == "" || value == null || isNaN(valueAsNumber) ? null : valueAsNumber);
      });
   }

   validate(value: number | null): ValidatedValue<number | null> {
      const { isRequired } = this.params;
      const valueAsNumber = Number(this.inputValue()?.replace(/,/g, ""));
      const isEmpty = this.inputValue() == null || this.inputValue() == "";
      if (isEmpty && unwrap(isRequired)) {
         return {
            valid: false,
            error: `${this.title} is required.`,
         };
      }
      if (isNaN(valueAsNumber) && !isEmpty) {
         return {
            valid: false,
            error: `${this.title} must be a number.`,
         };
      }
      return { valid: true, value };
   }

   dispose = (): void => {
      this.subscription.dispose();
   };

   static factory<T>(
      provider: (records: T[]) => NumberEditorParams,
   ): (records: T[]) => ComponentArgs<NumberEditorParams> {
      return (records) => ({
         name: "number-editor",
         params: provider(records),
      });
   }
}

ko.components.register("number-editor", {
   viewModel: NumberEditor,
   template: template(),
   synchronous: true,
});

import { MultiSelect } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import React, { useEffect } from "react";
import { ColorSwab } from "../ColorSelectComponent/ColorSelectColumn";

type CustomFilterComponentPropTypes = {
   columnDefinition: ColumnDefinition;
   onChange: (newValues: any[]) => void;
   value?: any[];
};

export interface OptionItem {
   id: string;
   label: string;
   name: string;
   color: string;
   shape?: string;
   componentName: string;
   objectValue?: any;
   value: string;
}

export const getMultiSelectId = (item: OptionItem) => item.value ?? item.id;
export const getMultiSelectLabel = (item: OptionItem) => item.name;

export const optionRenderer = (item: OptionItem) => (
   <MultiSelect.Option value={item} key={item.id}>
      <ColorSwab color={item.color} shape="pill" label={item.name} />
   </MultiSelect.Option>
);
export const tokenRenderer = ({
   disabled,
   focused,
   option,
   removeToken,
}: any): React.JSX.Element => (
   <ColorSwab
      color={option.color}
      shape="pill"
      label={getMultiSelectLabel(option)}
      disabled={disabled}
      focused={focused}
      removeToken={removeToken}
      displayRemoveToken={true}
   />
);

const MultiSelectPillFilter = (props: CustomFilterComponentPropTypes) => {
   const [selected, setSelected] = React.useState<string[]>([]); // Initialize state with an empty array
   const [options, setOptions] = React.useState<OptionItem[]>([]); // Initialize state with an empty array
   const [hasLoadedOptions, setHasLoadedOptions] = React.useState(false);

   React.useEffect(() => {
      if (!hasLoadedOptions) {
         const fetchOptions = async () => {
            try {
               const formattedTags = await handleFormattedOptions();
               if (formattedTags?.length > 0) {
                  setOptions(formattedTags);
                  // Set the flag to indicate that options have been loaded
                  setHasLoadedOptions(true);
               }
            } catch (error) {
               // Handle any errors
               console.error(error);
            }
         };

         fetchOptions();
      }
   }, [hasLoadedOptions]);

   async function handleFormattedOptions(): Promise<OptionItem[]> {
      const formattedOptions = await props.columnDefinition.filterProps?.getFilterOptions?.();

      const formattedTags = formattedOptions?.map((option: any) => ({
         name: option.name,
         color: option.color,
         value: option.id,
         shape: "pill",
         componentName: "multiselect",
      })) as OptionItem[];
      return formattedTags;
   }

   useEffect(() => {
      const preSelected = props.value ? props.value : [];
      setSelected(preSelected);
   }, [props.value]);

   function handleChange(key: any) {
      // Map the incoming options to ensure consistent format

      const selectedOpt = key?.map((option: any) => ({
         ...option,
         value: option.value,
         label: option.name,
      }));

      // Call the onChange function with the unique options
      props.onChange(selectedOpt);

      // Update the selected state with the unique options
      setSelected(selectedOpt);
   }

   return (
      (options.length > 0 && (
         <MultiSelect
            getId={getMultiSelectId}
            getLabel={getMultiSelectLabel}
            //loading={loading}
            onChange={handleChange}
            //onSearch={onSearch}
            options={options}
            optionRenderer={optionRenderer}
            tokenRenderer={tokenRenderer}
            value={selected}
            block
         />
      )) ||
      null
   );
};

export default MultiSelectPillFilter;

import { authManager } from "@/lib/managers/auth-manager";
import type { Callback } from "@/lib/type-utils";
import type { WorkDays } from "@/lib/utils/date";
import type { AssignmentData } from "@/models/assignment";
import { Assignment } from "@/models/assignment";
import { PermissionLevel } from "@/models/permission-level";
import type { ValueSet } from "@/models/value-set";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { PersonStore } from "@/stores/person-store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { StatusStore } from "@/stores/status-store.core";
import type { AssignmentPaySplit, Person } from "@laborchart-modules/common/dist/rethink/schemas";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import type { SerializedAssignment } from "@laborchart-modules/common/dist/rethink/serializers/assignment-2-serializer";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";
import type { UpdateAssignmentPayload } from "@laborchart-modules/lc-core-api";
import type { CreateAssignmentPayload } from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type { SerializedGetPerson } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type { SerializedGetProject } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import type { OvertimeRates } from "../editors/overtime-rates-editor-element/overtime-rates-editor-element";

export type GanttPaneCoreParams = {
   resourceId: string | null;
   supportData: NestedComputedAssignmentSupportData;
};

export type GanttPaneUpdateAssignmentData = {
   cost_code_id: string | null;
   end_day: number;
   end_time: number | null;
   label_id: string | null;
   overtime: boolean;
   percent_allocated: number | null;
   project_id: string;
   resource_id: string;
   start_day: number;
   start_time: number | null;
   status_id: string | null;
   work_days: WorkDays;

   pay_split?: AssignmentPaySplit;
   overtime_rates?: OvertimeRates;
};

export type GanttPaneAssignmentDataBaggage = {
   project_color: string;
   group_ids: [];
   person_group_ids: Person["group_ids"];
   person_assignable_group_ids?: Person["assignable_group_ids"];
   project_name: string;
   job_number: string | null;
   cost_code_name: string | null;
   person_name: { first: string; last: string };
   employee_number: string | null;
   position_color: string | null;
};

class GanttPaneCoreState {
   readonly canViewAllStatuses = authManager.checkAuthAction(
      PermissionLevel.Action.CAN_VIEW_ALL_STATUSES,
   );
   readonly statusOptions: PureComputed<AssignmentSupportData["statusOptions"]>;
   readonly projectOptions: PureComputed<AssignmentSupportData["projectOptions"]>;
   readonly groupedCostCodeOptions: PureComputed<AssignmentSupportData["groupedCostCodeOptions"]>;
   readonly groupedLabelOptions: PureComputed<AssignmentSupportData["groupedLabelOptions"]>;
   readonly resourceOptions: PureComputed<AssignmentSupportData["resourceOptions"]>;

   private readonly internalState: {
      selectedCostCode: Observable<ValueSet | null>;
      selectedLabel: Observable<ValueSet | null>;
      selectedProject: Observable<ValueSet | null>;
      selectedResource: Observable<ValueSet | null>;
      selectedStatus: Observable<ValueSet | null>;
   };

   //#region Legacy Mediators
   readonly selectedCostCode = pureComputed({
      read: () => this.internalState.selectedCostCode(),
      write: (costCode: ValueSet | null) => {
         if (costCode?.value != this.internalState.selectedCostCode()?.value) {
            this.selectedLabel(null);
         }
         this.internalState.selectedCostCode(costCode);
      },
   });
   readonly selectedLabel = pureComputed({
      read: () => this.internalState.selectedLabel(),
      write: (label: ValueSet | null) => {
         this.internalState.selectedLabel(label);
      },
   });
   readonly selectedProject = pureComputed({
      read: () => this.internalState.selectedProject(),
      write: (label: ValueSet | null) => {
         this.internalState.selectedProject(label);
      },
   });
   readonly selectedResource = pureComputed({
      read: () => this.internalState.selectedResource(),
      write: (resource: ValueSet | null) => {
         this.internalState.selectedResource(resource);
      },
   });
   readonly selectedStatus = pureComputed({
      read: () => this.internalState.selectedStatus(),
      write: (status: ValueSet | null) => {
         this.internalState.selectedStatus(status);
      },
   });
   //#endregion

   //#region Pure Computeds
   readonly activeProjectOptions = pureComputed(() => {
      return this.projectOptions().filter(
         (project) => project.baggage().status === ProjectStatus.ACTIVE,
      );
   });

   readonly filteredCostCodeOptions = pureComputed(() => {
      const costCodeOptions = this.groupedCostCodeOptions();
      const selectedProject = this.selectedProject();

      if (costCodeOptions == null || selectedProject == null) return [];

      if (costCodeOptions[selectedProject.id!] != null) {
         return costCodeOptions[selectedProject.id!].filter(function (code) {
            return code.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly filteredLabelOptions = pureComputed(() => {
      const labelOptions = this.groupedLabelOptions();
      const selectedProject = this.selectedProject();
      const selectedCostCode = this.selectedCostCode();

      if (labelOptions == null || selectedProject == null || selectedCostCode == null) return [];

      if (labelOptions[selectedCostCode.value()] != null) {
         return labelOptions[selectedCostCode.value()].filter(function (label) {
            return label.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly resourceId = pureComputed(() => {
      return this.selectedResource()?.value() ?? null;
   });
   //#endregion

   constructor({
      resourceId,
      supportData,
   }: {
      resourceId: string | null;
      supportData: NestedComputedAssignmentSupportData;
   }) {
      this.statusOptions = supportData.statusOptions;
      this.projectOptions = supportData.projectOptions;
      this.groupedCostCodeOptions = supportData.groupedCostCodeOptions;
      this.groupedLabelOptions = supportData.groupedLabelOptions;
      this.resourceOptions = supportData.resourceOptions;

      const selectedResource =
         resourceId != null
            ? this.resourceOptions().find((resource) => {
                 return resource.value() == resourceId;
              }) ?? null
            : null;

      this.internalState = {
         selectedCostCode: observable(null),
         selectedLabel: observable(null),
         selectedProject: observable(null),
         selectedResource: observable(selectedResource),
         selectedStatus: observable(null),
      };
   }
}

/**
 * This class only exists to make working with GanttPane easier by adding
 * typed methods. It is expected that the GanttPane will eventually be
 * completely rewritten, not simply piecemeal migrated to this file.
 */
export class GanttPaneCore {
   readonly state: GanttPaneCoreState;

   constructor({ resourceId, supportData }: GanttPaneCoreParams) {
      this.state = new GanttPaneCoreState({
         resourceId,
         supportData,
      });
   }

   /**
    * Formats data from core-api to be compatible with Assignment front-end model.
    */
   static getAssignmentDataFormatted({
      assignment,
      resource,
      project,
      status,
   }: {
      assignment: SerializedAssignment;
      resource: SerializedGetPerson;
      project: SerializedGetProject;
      status: SerializedStatus | null;
   }): AssignmentData & { baggage: GanttPaneAssignmentDataBaggage } {
      return {
         ...assignment,
         cost_code_id: assignment.category_id,
         label_id: assignment.subcategory_id,
         status_id: assignment.status_id,
         status: status ?? null,
         baggage: {
            project_color: project.color,
            group_ids: [],
            person_group_ids: resource.group_ids,
            person_assignable_group_ids: resource.assignable_group_ids,
            project_name: project.name,
            job_number: project.job_number,
            cost_code_name:
               project.categories.find((cat) => cat.id == assignment.category_id)?.name ?? null,
            person_name: resource.name,
            employee_number: resource.employee_number,
            position_color: resource.job_title?.color ?? null,
         },
      };
   }

   static async createAssignment(
      newBatch: GanttPaneUpdateAssignmentData,
      callback: Callback<Assignment>,
   ): Promise<void> {
      const payload: CreateAssignmentPayload = {
         ...newBatch,
         category_id: newBatch.cost_code_id,
         custom_fields: {},
         overtime: newBatch.overtime ?? false,
         pay_split: newBatch.overtime === true ? newBatch.pay_split : null,
         percent_allocated:
            newBatch.percent_allocated != null ? Number(newBatch.percent_allocated) : null,
         subcategory_id: newBatch.label_id,
      };
      try {
         const [assignmentResponse, resourceResponse, projectResponse, statusResponse] =
            await Promise.all([
               Assignment2Store.createAssignment(payload).payload,
               PersonStore.getPerson(newBatch.resource_id).payload,
               ProjectStore.getProject(newBatch.project_id).payload,
               newBatch.status_id ? StatusStore.getStatus(newBatch.status_id).payload : null,
            ]);
         const createdAssignmentFormatted = this.getAssignmentDataFormatted({
            assignment: assignmentResponse.data,
            resource: resourceResponse.data,
            project: projectResponse.data,
            status: statusResponse?.data ?? null,
         });
         return callback(null, new Assignment(createdAssignmentFormatted));
      } catch (error: unknown) {
         console.log("err: ", error);
         return callback(error as Error);
      }
   }

   static async updateAssignment(
      assignmentId: string,
      newBatch: GanttPaneUpdateAssignmentData,
      callback: Callback<Assignment>,
   ): Promise<void> {
      const payload: UpdateAssignmentPayload = {
         ...newBatch,
         category_id: newBatch.cost_code_id,
         custom_fields: {},
         overtime: newBatch.overtime ?? false,
         pay_split: newBatch.overtime === true ? newBatch.pay_split : null,
         percent_allocated:
            newBatch.percent_allocated != null ? Number(newBatch.percent_allocated) : null,
         subcategory_id: newBatch.label_id,
      };
      try {
         const [assignmentResponse, resourceResponse, projectResponse, statusResponse] =
            await Promise.all([
               Assignment2Store.updateSingleAssignment({
                  assignmentId: assignmentId,
                  update: payload,
               }).payload,
               PersonStore.getPerson(newBatch.resource_id).payload,
               ProjectStore.getProject(newBatch.project_id).payload,
               newBatch.status_id ? StatusStore.getStatus(newBatch.status_id).payload : null,
            ]);
         const updatedAssignmentFormatted = this.getAssignmentDataFormatted({
            assignment: assignmentResponse.data,
            resource: resourceResponse.data,
            project: projectResponse.data,
            status: statusResponse?.data ?? null,
         });
         return callback(null, new Assignment(updatedAssignmentFormatted));
      } catch (error: unknown) {
         console.log("err: ", error);
         return callback(error as Error);
      }
   }
}

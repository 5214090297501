import { Model } from "@/models/model"
import { CannedMessage } from "@/models/canned-message"
import * as ko from "knockout"

export class Company extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.timezone, String)
         assertOfType(data.address_1, nullable(String))
         assertOfType(data.address_2, nullable(String))
         assertOfType(data.city_town, nullable(String))
         assertOfType(data.state_province, nullable(String))
         assertOfType(data.zipcode, nullable(String))
         assertOfType(data.country, nullable(String))
         assertOfType(data.date_format_preference, String)
         assertOfType(data.currency, String)
         assertOfType(data.contact_phone, optional(String))
         assertOfType(data.contact_email, optional(String))
         assertOfType(data.contact_name, optional(String))
         assertOfType(data.logo_url, optional(String))
         assertOfType(data.twilio_number, optional(String))
         assertOfType(data.alert_config, nullable(Object))
         assertOfType(data.tbd_weeks, Number)

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id
      @isActive = ko.observable(data.is_active)

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @name = ko.observable(data.name)
      @timezone = ko.observable(data.timezone)
      @address1 = ko.observable(data.address_1)
      @address2 = ko.observable(data.address_2)
      @cityTown = ko.observable(data.city_town)
      @stateProvince = ko.observable(data.state_province)
      @zipcode = ko.observable(data.zipcode)
      @country = ko.observable(data.country)
      @dateFormatPreference = ko.observable(data.date_format_preference)
      @currency = ko.observable(data.currency)
      @contactPhone = ko.observable(data.contact_phone)
      @contactEmail = ko.observable(data.contact_email)
      @contactName = ko.observable(data.contact_name)
      @twilioNumber = ko.observable(data.twilio_number)
      @alertConfig = ko.observable (if data.alert_config? then new AlertConfig(data.alert_config) else null)
      @tbdWeeks = ko.observable(data.tbd_weeks)
      @branding = ko.observable (if data.branding? then new Branding(data.branding) else null)

export class AlertConfig extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.response_one, nullable(String))
         assertOfType(data.color_one, nullable(String))
         assertOfType(data.descriptor_one, nullable(String))
         assertOfType(data.response_two, nullable(String))
         assertOfType(data.color_two, nullable(String))
         assertOfType(data.descriptor_two, nullable(String))
         assertOfType(data.response_three, nullable(String))
         assertOfType(data.color_three, nullable(String))
         assertOfType(data.descriptor_three, nullable(String))
         assertOfType(data.default_template, optional(Object))

      @responseOne = ko.observable(data.response_one)
      @colorOne = ko.observable(data.color_one)
      @descriptorOne = ko.observable(data.descriptor_one)
      @responseTwo = ko.observable(data.response_two)
      @colorTwo = ko.observable(data.color_two)
      @descriptorTwo = ko.observable(data.descriptor_two)
      @responseThree = ko.observable(data.response_three)
      @colorThree = ko.observable(data.color_three)
      @descriptorThree = ko.observable(data.descriptor_three)
      @defaultTemplate = ko.observable(if data.default_template? then new CannedMessage(data.default_template, true) else null)

export class Branding extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.logo_url, String)
         assertOfType(data.primary_color, String)
         assertOfType(data.secondary_color, String)

      @logoUrl = ko.observable(data.logo_url)
      @primaryColor = ko.observable(data.primary_color)
      @secondaryColor = ko.observable(data.secondary_color)


Company.AlertConfig = AlertConfig
import "./time-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./time-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export interface TimeEditorParams extends EditorComponentParams<number | null> {
   isRequired?: MaybeSubscribable<boolean>;
}

export class TimeEditor extends AbstractFieldEditor<number | null> {
   readonly validation = pureComputed(() => {
      if (unwrap(this.params.isRequired)) {
         if (this.value() === null) return `${this.title} is required.`;
      }
      return null;
   });

   constructor(private readonly params: TimeEditorParams) {
      super(params, observable(unwrap(params.value)));
   }

   validate(value: number | null): ValidatedValue<number | null> {
      return unwrap(this.params.isRequired) && value == null
         ? { valid: false, error: `${this.title} is required.` }
         : { valid: true, value };
   }

   static factory<T>(
      provider: (records: T[]) => TimeEditorParams,
   ): (records: T[]) => ComponentArgs<TimeEditorParams> {
      return (records) => ({
         name: "time-editor",
         params: provider(records),
      });
   }
}

ko.components.register("time-editor", {
   viewModel: TimeEditor,
   template: template(),
   synchronous: true,
});

import "./allocation-type-editor-element.styl";
import template from "./allocation-type-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { SegmentedController2Params } from "@/lib/components/segmented-controller-2/segmented-controller-2";
import type { EditorElementParams } from "../common/editor-element-template";

export type AllocationTypeEditorElementParams = {
   currentAllocationType: PureComputed<AllocationType>;
} & EditorElementParams<AllocationType>;

export const enum AllocationType {
   HOURS = "hours",
   PERCENT = "percent",
}
export class AllocationTypeEditorElement extends ViewModel {
   readonly currentAllocationType: AllocationTypeEditorElementParams["currentAllocationType"];
   readonly onChange: AllocationTypeEditorElementParams["onChange"];
   readonly title: AllocationTypeEditorElementParams["title"];

   readonly segmentedControllerParams: SegmentedController2Params<AllocationType>;

   readonly selectAllocationType = (type: AllocationType): void => {
      this.onChange(type);
   };

   constructor({
      currentAllocationType,
      onChange,
      title = "Allocation Type",
   }: AllocationTypeEditorElementParams) {
      super(template());
      this.currentAllocationType = currentAllocationType;
      this.onChange = onChange;
      this.title = title;

      const isTypeHours = pureComputed(() => this.currentAllocationType() === AllocationType.HOURS);
      const isTypePercent = pureComputed(
         () => this.currentAllocationType() === AllocationType.PERCENT,
      );
      this.segmentedControllerParams = {
         onChange,
         options: [
            { name: "Hours", value: AllocationType.HOURS, isSelected: isTypeHours },
            { name: "Percent", value: AllocationType.PERCENT, isSelected: isTypePercent },
         ],
         requestSize: () => {},
      };
   }

   static factory(
      params: AllocationTypeEditorElementParams,
   ): ComponentArgs<AllocationTypeEditorElementParams> {
      return {
         name: "allocation-type-editor-element",
         params,
      };
   }
}

ko.components.register("allocation-type-editor-element", {
   viewModel: AllocationTypeEditorElement,
   template: template(),
   synchronous: true,
});

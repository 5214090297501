import template from "./custom-field-multi-select-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { MultiSelectItem } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import {
   MultiSelectDropDownPane,
   SELECT_ALL_ID,
} from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { TextCell } from "@/lib/components/grid/cells/text-cell";

export type CustomFieldMultiSelectEditorElementParams = {
   customFieldOptions: string[] | null;
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<string[] | null>;
} & EditorElementParams<string[] | null>;

export class CustomFieldMultiSelectEditorElement extends ViewModel {
   readonly customFieldOptions: CustomFieldMultiSelectEditorElementParams["customFieldOptions"];
   readonly invalidInputMessage: CustomFieldMultiSelectEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldMultiSelectEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldMultiSelectEditorElementParams["onChange"];
   readonly title: CustomFieldMultiSelectEditorElementParams["title"];
   readonly value: CustomFieldMultiSelectEditorElementParams["value"];

   readonly selectedIdsMediator = pureComputed<Set<string>>({
      read: () => {
         const value = unwrap(this.value);
         return value ? new Set(value) : new Set<string>();
      },
      write: (value) => {
         this.onChange(value.size > 0 ? [...value].filter((item) => item != SELECT_ALL_ID) : null);
      },
   });

   readonly itemsPane: MultiSelectDropDownPane<{ id: string }>;

   private readonly dropDown2Params: DropDown2Params<MultiSelectItem<{ id: string }>>;

   constructor({
      customFieldOptions,
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldMultiSelectEditorElementParams) {
      super(template());

      this.customFieldOptions = customFieldOptions;
      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;

      this.itemsPane = new MultiSelectDropDownPane<{ id: string }>({
         items: (this.customFieldOptions ?? []).map<MultiSelectItem<{ id: string }>>((option) => ({
            item: { id: option },
            id: option,
         })),
         selectedIds: this.selectedIdsMediator,
         textProvider: (item) => item.id,
      });

      this.dropDown2Params = {
         actionInterceptor: this.itemsPane.actionInterceptor,
         cellFactory: this.itemsPane.cellFactory,
         isClearable: true,
         isDisabled,
         panes: [this.itemsPane as any],
         selectedIds: this.selectedIdsMediator,
         selectedItemCellFactory: TextCell.factory<{ id: string }>((item) => item.id),
      };
   }

   static factory(
      params: CustomFieldMultiSelectEditorElementParams,
   ): ComponentArgs<CustomFieldMultiSelectEditorElementParams> {
      return {
         name: "custom-field-multi-select-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-multi-select-editor-element", {
   viewModel: CustomFieldMultiSelectEditorElement,
   template: template(),
   synchronous: true,
});

import React from "react";
import { H1, Flex, Switch, Typography } from "@procore/core-react";

export const AutoEndAssignmentsToggle = (props: any) => {
   const { company, toggleAutoEndAssignments } = props;

   return (
      <>
         <H1>
            {I18n.t(
               "views.company.workforce_planning.settings.auto_end_assignments.auto_end_assignments_heading",
            )}
         </H1>
         <Flex>
            <Switch
               checked={company?.auto_end_assignments}
               onChange={() => toggleAutoEndAssignments(company)}
            />
            <Typography style={{ paddingLeft: "10px" }}>
               {I18n.t(
                  "views.company.workforce_planning.settings.auto_end_assignments.auto_end_assignments_details",
               )}
            </Typography>
         </Flex>
      </>
   );
};

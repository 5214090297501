import type { StoreJsonResponse, StoreStreamResponse } from "./common";
import { Store } from "./common";
import type {
   CreatePositionPayload,
   CreatePositionResponse,
} from "@laborchart-modules/lc-core-api/dist/api/positions/create-position";
import type {
   UpdatePositionPayload,
   UpdatePositionResponse,
} from "@laborchart-modules/lc-core-api/dist/api/positions/update-position";
import type { DeletePositionResponse } from "@laborchart-modules/lc-core-api/dist/api/positions/delete-position";
import type { StreamReorderPositionsPayload } from "@laborchart-modules/lc-core-api/dist/api/positions/reorder-positions";
import type {
   FindPositionsPaginatedQueryParams,
   FindPositionsPaginatedResponse,
   FindPositionsStreamQueryParams,
} from "@laborchart-modules/lc-core-api/dist/api/positions/find-positions";

import type { GetPositionResponse } from "@laborchart-modules/lc-core-api/dist/api/positions/get-position";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers/position-serializer";

export abstract class PositionStore extends Store {
   static createPosition(
      positionData: CreatePositionPayload,
   ): StoreJsonResponse<CreatePositionResponse> {
      return this.requestJson({
         url: "/api/v3/job-titles",
         method: "POST",
         body: positionData,
      });
   }

   static updatePosition(
      positionId: string,
      positionData: UpdatePositionPayload,
   ): StoreJsonResponse<UpdatePositionResponse> {
      return this.requestJson({
         url: `/api/v3/job-titles/${positionId}`,
         method: "PATCH",
         body: positionData,
      });
   }

   static findPositionsPaginated(
      query: FindPositionsPaginatedQueryParams,
   ): StoreJsonResponse<FindPositionsPaginatedResponse> {
      return this.requestJson({
         method: "GET",
         query,
         url: "/api/v3/job-titles",
      });
   }

   static findPositionsStream(
      query: FindPositionsStreamQueryParams,
   ): StoreStreamResponse<SerializedPosition> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/job-titles/stream",
      });
   }

   static deletePosition(positionId: string): StoreJsonResponse<DeletePositionResponse> {
      return this.requestJson({
         url: `/api/v3/job-titles/${positionId}`,
         method: "DELETE",
      });
   }

   static reorderPositions(
      reorderPositions: Record<number, string>,
   ): StoreStreamResponse<StreamReorderPositionsPayload> {
      return this.requestStream({
         url: "/api/v3/job-titles/reorder",
         method: "PATCH",
         body: reorderPositions,
      });
   }

   static getPosition(positionId: string): StoreJsonResponse<GetPositionResponse> {
      return this.requestJson({
         method: "GET",
         url: `/api/v3/job-titles/${positionId}`,
      });
   }
}

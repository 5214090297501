### Auth, Real-Time & Stores ###
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { FanoutMessage } from "@/lib/managers/fanout-manager"
import { Store } from "@/stores/store"
import { ActivityStore } from "@/stores/activity-store.core";
import { ActivityStore as LegacyActivityStore} from "@/stores/activity-store"

### Models ###
import { Activity } from "@/models/activity"

### Framework Includes ###
import ko from "knockout"

export class PeopleStore extends Store
   constructor: ->
      super()
      @vault = {
         "unassigned-people": null
         "off-people": null
         "assigned-people": null
         "get-person": null
         "person-activity": null
      }

   subscribeToPersonActivity: (listenerData, listenerNamespace, forceRequest, callback) ->
      [listenerData, listenerNamespace, forceRequest, callback] = assertArgs(arguments, optional(Object), String, optional(Boolean), Function)
      @getPersonActivity = ((message, listenerData, forceRequest) ->
         [message, listenerData, forceRequest] = assertArgs(arguments, optional(FanoutMessage), optional(Object), optional(Boolean))
         requestData = fanoutManager.getListenerData(listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: listenerData.personId}) or listenerData

         return @requestPersonActivity_(requestData, listenerNamespace, callback) if forceRequest
         timestamp = if message? then message.timestamp else 0
         @maybeGetVaultData PeopleStore.ValutKey.PERSON_ACTIVITY, timestamp, (err, data) =>
            return @requestPersonActivity_(requestData, listenerNamespace, callback) if err == Store.Error.VAULT_OUTDATED
            return console.log "Error: ", err if err?
            callback(null, ko.unwrap(data))
            return fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: listenerData.personId}, @getPersonActivity)
      ).bind(@)
      force = if forceRequest?  then forceRequest else false
      @getPersonActivity(listenerData, force)

   #This one seems to be no longer used
   resetPassword: (resetId, password, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/reset-password/#{resetId}"
         method: "POST"
         data: {password: password}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   #This one should not be used, the UI component is hidden
   resetPasswordInternally: (personId, password, newPassword, callback) ->
      assertArgs(arguments, String, String, String, Function)
      @makeRequest {
         url: "/api/people/#{personId}/reset-password/internal"
         method: "POST"
         data: {
            current_password: password
            new_password: newPassword
         }
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   # async
   requestPersonActivity_: (requestData, listenerNamespace, callback) =>
      query = {
         entity_id: requestData.personId,
         entity_type: LegacyActivityStore.ActivityEntityType.PEOPLE
         limit: 25
      }
      if requestData.category != "all"
         query['included_categories'] = [requestData.category]
      try
         results = await ActivityStore.findActivityPaginated(query).payload
         activity = []
         for item from results.data
            activity.push(new Activity(item))
         @maybeSetVaultData(PeopleStore.ValutKey.PERSON_ACTIVITY, activity, Date.now(), callback)
      catch err
         return console.log "Error: ", err

      fanoutManager.getSubscription(requestData, listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: requestData.personId}, @getPersonActivity)

PeopleStore.ValutKey = {
   UNASSIGNED_PEOPLE: "unassigned-people"
   OFF_PEOPLE: "off-people"
   ASSIGNED_PEOPLE: "assigned-people"
   GET_PERSON: "get-person"
   PERSON_ACTIVITY: "person-activity"
}

PeopleStore.ListViewType = {
   People: "people"
   Projects: "project"
   Requests: "request"
   Assignments: "assignment"
   AssignmentsList: "assignments-list"
   TimeOff: "time-off"
}

export peopleStore = new  PeopleStore()
import type { ObservableArray } from "knockout";
import { isObservable, observable, observableArray } from "knockout";
import type { GridStore, RowBase } from "./grid-store";
import { LoadingState } from "./grid-store";

/** `GridStore` based by an observable array. */
export class ArrayGridStore<T extends RowBase> implements GridStore<T> {
   readonly rows: ObservableArray<T>;
   readonly loadingState = observable(LoadingState.NONE);
   readonly hasPreviousRows = observable(false);
   readonly hasNextRows = observable(false);

   constructor(rows: ObservableArray<T> | T[]) {
      this.rows = isObservable(rows) ? rows : observableArray(rows);
   }

   setFirstVisibleRow(): void {}
   loadInitialRows(): void {}
   loadPreviousRows(): void {}
   loadNextRows(): void {}
}

import template from "./custom-field-select-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";

export type CustomFieldSelectEditorElementParams = {
   customFieldOptions: string[] | null;
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<string | null>;
} & EditorElementParams<string | null>;

export class CustomFieldSelectEditorElement extends ViewModel {
   readonly customFieldOptions: CustomFieldSelectEditorElementParams["customFieldOptions"];
   readonly invalidInputMessage: CustomFieldSelectEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldSelectEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldSelectEditorElementParams["onChange"];
   readonly title: CustomFieldSelectEditorElementParams["title"];
   readonly value: CustomFieldSelectEditorElementParams["value"];

   readonly selectedItemMediator = pureComputed<{ id: string } | null>({
      read: () => {
         const value = this.value();
         return value ? { id: value } : null;
      },
      write: (value) => {
         this.onChange(value?.id ?? null);
      },
   });

   readonly itemsPane: ArrayDropDownPane<{ id: string }>;

   private readonly dropDown2Params: DropDown2Params<{ id: string }>;

   constructor({
      customFieldOptions,
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldSelectEditorElementParams) {
      super(template());

      this.customFieldOptions = customFieldOptions;
      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;

      this.itemsPane = new ArrayDropDownPane<{ id: string }>({
         items: (this.customFieldOptions ?? []).map((option) => ({ id: option })),
      });

      this.dropDown2Params = {
         cellFactory: TextCell.factory<{ id: string }>((item) => item.id),
         isClearable: true,
         isDisabled,
         panes: [this.itemsPane],
         selectedItem: this.selectedItemMediator,
      };
   }

   static factory(
      params: CustomFieldSelectEditorElementParams,
   ): ComponentArgs<CustomFieldSelectEditorElementParams> {
      return {
         name: "custom-field-select-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-select-editor-element", {
   viewModel: CustomFieldSelectEditorElement,
   template: template(),
   synchronous: true,
});

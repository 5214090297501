import { Model } from "@/models/model"
import ko from "knockout"

export class Alert extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.company_id, String)
         assertOfType(data.recipient_ids, arrayOf(String))
         assertOfType(data.reply_code, nullable(String))
         assertOfType(data.assignment_id, String)
         assertOfType(data.subject, nullable(String))
         assertOfType(data.content, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.sent_at, nullable(Number))
         assertOfType(data.send_at, nullable(Number))
         assertOfType(data.closed_at, nullable(Number))
         assertOfType(data.sender_id, String)
         assertOfType(data.responses, arrayOf(Object))
         assertOfType(data.context, String)
         assertOfType(data.include_signature, Boolean)

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @companyId = ko.observable(data.company_id)
      @recipientIds = ko.observable(data.recipient_ids)
      @replyCode = ko.observable(data.reply_code)
      @assignmentId = ko.observable(data.assignment_id)
      @subject = ko.observable(data.subject)
      @content = ko.observable(data.content)
      @createdAt = ko.observable(data.created_at)
      @sentAt = ko.observable(data.sent_at)
      @sendAt = ko.observable(data.send_at)
      @closedAt = ko.observable(data.closed_at)
      @senderId = ko.observable(data.sender_id)
      @responses = ko.observableArray(data.responses.map (item) -> new AlertResponse(item))
      @context = ko.observable(data.context)
      @includeSignature = ko.observable(data.include_signature)

Alert.Context = {
   OPEN: "open"
   SCHEDULED: "scheduled"
   DRAFTS: "drafts"
   CLOSED: "closed"
}

export class AlertResponse extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.author_id, String)
         assertOfType(data.word, nullable(String))
         assertOfType(data.color, nullable(String))

      ###------------------------------------
         Model ID & Meta
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @authorId = ko.observable(data.author_id)
      @word = ko.observable(data.word)
      @color = ko.observable(data.color)


Alert.AlertResponse = AlertResponse
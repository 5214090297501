import "./custom-field-currency-editor-element.styl";
import template from "./custom-field-currency-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import { observable } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";
import { TextFieldType } from "@/lib/components/text-field/text-field";

export type CustomFieldCurrencyEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<number | null>;
} & EditorElementParams<number | null>;

export class CustomFieldCurrencyEditorElement extends ViewModel {
   readonly invalidInputMessage: CustomFieldCurrencyEditorElementParams["invalidInputMessage"];
   readonly isDisabled: CustomFieldCurrencyEditorElementParams["isDisabled"];
   readonly onChange: CustomFieldCurrencyEditorElementParams["onChange"];
   readonly title: CustomFieldCurrencyEditorElementParams["title"];
   readonly value: CustomFieldCurrencyEditorElementParams["value"];

   readonly textFieldMediator = pureComputed<string | null>({
      read: () => this.value()?.toString() ?? null,
      write: (value) => {
         const val = value != null ? Number(value) : null;
         this.onChange(val == null || isNaN(val) ? null : val);
      },
   });

   private readonly textFieldParams: TextFieldParams;

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: CustomFieldCurrencyEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;
      this.textFieldParams = {
         isClearable: observable(isDisabled != true),
         isDisabled: this.isDisabled,
         type: TextFieldType.NUMBER,
         value: this.textFieldMediator,
      };
   }

   static factory(
      params: CustomFieldCurrencyEditorElementParams,
   ): ComponentArgs<CustomFieldCurrencyEditorElementParams> {
      return {
         name: "custom-field-currency-editor-element",
         params,
      };
   }
}

ko.components.register("custom-field-currency-editor-element", {
   viewModel: CustomFieldCurrencyEditorElement,
   template: template(),
   synchronous: true,
});

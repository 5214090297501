import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { GetProjectUsersResponse } from "@laborchart-modules/lc-core-api/dist/api/project-directory/get-user";
import type { AddUserToProjectDirectoryResponse } from "@laborchart-modules/lc-core-api/dist/api/project-directory/add-user";

export type AddUserToProjectDirectoryPayload = {
   user: {
      permission_template_id: number;
   };
};

export abstract class ProjectDirectoryStore extends Store {
   static getProjectDirectoryUser(
      projectId: number,
      userId: number,
   ): StoreJsonResponse<Partial<GetProjectUsersResponse>> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/users/${userId}`,
         method: "GET",
      });
   }

   static addProjectDirectoryUser(
      projectId: number,
      userId: number,
      payload: AddUserToProjectDirectoryPayload,
   ): StoreJsonResponse<Partial<AddUserToProjectDirectoryResponse>> {
      return this.requestJson({
         body: payload,
         method: "POST",
         url: `/api/v3/projects/${projectId}/users/${userId}/add-to-directory`,
      });
   }
}

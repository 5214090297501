import React from "react";
import { Link as LinkIcon } from "@procore/core-icons";
import { Tooltip, Button } from "@procore/core-react";
import { ProcoreTargetType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

export const LinkToProcoreEntity = (props: {
   entityType: "person" | "project";
   procoreEntityId: string;
   procoreCompanyId?: string;
   procoreTargetType?: string;
   searchTerm?: string;
}) => {
   const { entityType, procoreEntityId, procoreCompanyId, procoreTargetType, searchTerm } = props;
   const linkUrl =
      entityType == "person"
         ? procoreTargetType == ProcoreTargetType.PERSON
            ? `/${procoreCompanyId}/company/directory/groups/reference_users?page=1&sort=name&search=${searchTerm}`
            : `/${procoreCompanyId}/company/directory/users/${procoreEntityId}?tab=general`
         : `/${procoreEntityId}/project/admin`;

   const popoverHeading =
      entityType == "person" ? "Record linked to Procore Directory" : "Linked Record in Procore";

   const handleClick = () => {
      if (procoreCompanyId == null) {
         return;
      }
      window.open(linkUrl, "_blank");
   };
   return (
      <Tooltip overlay={popoverHeading} trigger={["hover", "focus"]}>
         <span tabIndex={0}>
            <Button icon={<LinkIcon />} onClick={handleClick} variant="secondary" size="sm" />
         </span>
      </Tooltip>
   );
};

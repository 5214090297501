import type { RowBase } from "@/lib/components/grid/grid-store";
import { ProgressNotification } from "@/notifications/progress-notification";
import type { StoreStreamResponse } from "@/stores/common";
import type { ObservableArray } from "knockout";
import {
   Action,
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import type { CancellableStream } from "@/lib/streams/cancellable-stream";
import { groupAdjacent } from "../arrays";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { modalManager } from "@/lib/managers/modal-manager";
import type { UpdateFailure } from "./grid-store-rows-updater-failures-modal-pane";
import { GridStoreRowsUpdaterFailuresModalPane } from "./grid-store-rows-updater-failures-modal-pane";
import { Modal } from "@/lib/components/modals/modal";
import type { StreamedUpdate } from "@laborchart-modules/lc-core-api/dist/api/streams";
import { nextTick } from "@/lib/async/next-tick";
import { parseError, ValidationError } from "@/stores/common/store-errors";
import { ValidationErrorCode } from "@laborchart-modules/lc-core-api/dist/api/errors";
import type { BatchDeletePayload } from "@laborchart-modules/lc-core-api";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "../bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";

export type UpdateStreamProvider<TUpdatePayload> = (
   updates: TUpdatePayload,
) => StoreStreamResponse<StreamedUpdate>;

export type ErrorMessageProvider = (error: Error) => string | null;

export type GridStoreUpdateRowError = { id: string; error: any };
export class GridStoreUpdateError extends Error {
   constructor(readonly causes: GridStoreUpdateRowError[]) {
      super("Update failed.");
   }
}

interface ActiveUpdate {
   stream: StoreStreamResponse<StreamedUpdate>;
   notification: ProgressNotification | null;
   size: number;
}

/** Number of updates to do in a row before waiting a tick to let the browser catch up. */
const UPDATES_PER_BATCH = 20;

export class GridStoreRowsUpdater<TRow extends RowBase, TUpdatePayload> {
   private readonly rows: ObservableArray<TRow>;
   private readonly updateStreamProvider: UpdateStreamProvider<TUpdatePayload>;
   private readonly errorModalColumnGroups: Array<GridColumnGroup<TRow>>;
   private readonly errorMessageProvider: ErrorMessageProvider;

   private activeUpdate: ActiveUpdate | null = null;

   constructor({
      rows,
      updateStreamProvider,
      errorModalColumnGroups,
      errorMessageProvider,
   }: {
      rows: ObservableArray<TRow>;
      updateStreamProvider: UpdateStreamProvider<TUpdatePayload>;
      errorModalColumnGroups: Array<GridColumnGroup<TRow>>;
      errorMessageProvider: ErrorMessageProvider;
   }) {
      this.rows = rows;
      this.updateStreamProvider = updateStreamProvider;
      this.errorModalColumnGroups = errorModalColumnGroups;
      this.errorMessageProvider = errorMessageProvider;
   }

   async update({
      updatePayload,
      rowApplier,
      size,
   }: {
      updatePayload: TUpdatePayload;
      rowApplier: (row: TRow) => TRow | null;
      size: number;
   }): Promise<void> {
      if (this.activeUpdate) this.cancel();

      const notification =
         size > 1
            ? new ProgressNotification({
                 message:
                    size > 100
                       ? `Preparing to update ${size} records... (this may take a minute)`
                       : `Preparing to update ${size} records...`,
              })
            : null;
      const activeUpdate: ActiveUpdate = {
         stream: this.updateStreamProvider(updatePayload),
         notification,
         size,
      };
      this.activeUpdate = activeUpdate;

      // Show the notification if necessary.
      if (notification) notificationManagerInstance.show(notification);

      // Attempt to start the stream. Show a generic error notification if the request
      // fails.
      let stream: CancellableStream<StreamedUpdate>;
      try {
         stream = await activeUpdate.stream.stream;
      } catch (err: unknown) {
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = "grid-store-rows-updater_update";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("update payload", updatePayload as any);
         });
         this.showActiveUpdateFailure(err);
         this.activeUpdate = null;
         throw new GridStoreUpdateError([{ id: "n/a", error: err as Error }]);
      }

      let pendingUpdateIndices: number[] = [];
      const streamErrors: Array<{ success: false; id: string; error: any }> = [];
      let count = 0;

      // Apply the updates to the rows as they're confirmed by the server.
      for await (const update of stream) {
         if (update.success) {
            const rows = this.rows();
            const index = rows.findIndex((r) => update.id == r.id);
            if (index != -1) {
               pendingUpdateIndices.push(index);
            }
         } else {
            streamErrors.push(update);
         }
         count += 1;

         // Apply the updates in batches to avoid updating the rows observable too frequently.
         if (pendingUpdateIndices.length > UPDATES_PER_BATCH) {
            this.updateRows({ indices: pendingUpdateIndices, rowApplier });
            pendingUpdateIndices = [];

            if (notification) {
               notification.update({
                  message: `Updating ${size} records...`,
                  percent: count / size,
               });
            }

            // Unblock the event loop after applying the updates.
            await nextTick();
         }
      }

      // Apply the last batch of updates.
      this.updateRows({ indices: pendingUpdateIndices, rowApplier });

      // Handle updating the final notification.
      try {
         if (streamErrors.length > 0) {
            this.showFailedRowsMessage(streamErrors, size);
            const error = new GridStoreUpdateError(streamErrors);
            Bugsnag.notify(error as NotifiableError, (event) => {
               event.context = "grid-store-rows-updater_update";
               event.addMetadata(
                  BUGSNAG_META_TAB.USER_DATA,
                  buildUserData(authManager.authedUser()!, authManager.activePermission),
               );
               event.addMetadata("update payload", updatePayload as any);
            });
            // Fail the promise if we're still processing and all the rows failed to
            // updated.
            if (streamErrors.length == size) throw error;
         } else if (notification) {
            notification.success({ message: `Updated ${size} records.` });
            setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
         }
      } finally {
         this.activeUpdate = null;
      }
   }

   async delete(payload: BatchDeletePayload): Promise<void> {
      if (this.activeUpdate) this.cancel();
      const size = payload.ids.length;
      const removeRow = () => null;

      const notification =
         size > 1
            ? new ProgressNotification({
                 message:
                    size > 100
                       ? `Preparing to delete ${size} records... (this may take a minute)`
                       : `Preparing to delete ${size} records...`,
              })
            : null;
      const activeUpdate: ActiveUpdate = {
         stream: this.updateStreamProvider(payload as any), // TODO: Remove 'as any'
         notification,
         size,
      };
      this.activeUpdate = activeUpdate;

      // Show the notification if necessary.
      if (notification) notificationManagerInstance.show(notification);

      // Attempt to start the stream. Show a generic error notification if the request
      // fails.
      const stream = await activeUpdate.stream.stream.catch((err: unknown) => {
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = "grid-store-rows-updater_delete";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });
         this.showActiveUpdateFailure(err);
         this.activeUpdate = null;
         throw new GridStoreUpdateError([{ id: "n/a", error: err as Error }]);
      });

      let pendingUpdateIndices: number[] = [];
      const streamErrors: Array<{ success: false; id: string; error: any }> = [];
      let count = 0;

      // Apply the updates to the rows as they're confirmed by the server.
      for await (const update of stream) {
         if (update.success) {
            const rows = this.rows();
            const index = rows.findIndex((r) => update.id == r.id);
            if (index != -1) {
               pendingUpdateIndices.push(index);
            }
         } else {
            streamErrors.push(update);
         }
         count += 1;

         // Apply the updates in batches to avoid updating the rows observable too frequently.
         if (pendingUpdateIndices.length > UPDATES_PER_BATCH) {
            this.updateRows({ indices: pendingUpdateIndices, rowApplier: removeRow });
            pendingUpdateIndices = [];

            if (notification) {
               notification.update({
                  message: `Deleting ${size} records...`,
                  percent: count / size,
               });
            }

            // Unblock the event loop after applying the updates.
            await nextTick();
         }
      }

      // Apply the last batch of updates.
      this.updateRows({ indices: pendingUpdateIndices, rowApplier: removeRow });

      // Handle updating the final notification.
      try {
         if (streamErrors.length > 0) {
            this.showFailedRowsMessage(streamErrors, size);
            const error = new GridStoreUpdateError(streamErrors);
            Bugsnag.notify(error as NotifiableError, (event) => {
               event.context = "grid-store-rows-updater_update";
               event.addMetadata(
                  BUGSNAG_META_TAB.USER_DATA,
                  buildUserData(authManager.authedUser()!, authManager.activePermission),
               );
            });
            // Fail the promise if we're still processing and all the rows failed to
            // update.
            if (streamErrors.length == size) throw error;
         } else if (notification) {
            notification.success({ message: `Deleted ${size} records.` });
            setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
         }
      } finally {
         this.activeUpdate = null;
      }
   }

   cancel(): void {
      if (!this.activeUpdate) return;
      this.activeUpdate.stream.cancel();
      if (this.activeUpdate.notification) {
         this.activeUpdate.notification.failed({
            message: `Update of ${this.activeUpdate.size} was cancelled.`,
         });
      }
      this.activeUpdate = null;
   }

   private updateRows({
      indices,
      rowApplier,
   }: {
      indices: number[];
      rowApplier: (row: TRow) => TRow | null;
   }) {
      const rows = this.rows();

      /**
       * Group adjacent rows to be updated together to be updated in chunks,
       * for efficiency purposes. Update chunks from last to first so that
       * if rows are removed, then the indicies for yet-to-be-updated chunks
       * will remain unaffected.
       */
      groupAdjacent(
         indices.sort((a, b) => a - b),
         (current, next) => current + 1 == next,
      )
         .sort(([a], [b]) => b - a)
         .forEach((indices) => {
            const newRows = indices
               .map((index) => rowApplier(rows[index]))
               .filter((row) => row != null) as TRow[];
            this.rows.splice(indices[0], indices.length, ...newRows);
         });
   }

   private showFailedRowsMessage(
      streamErrors: Array<StreamedUpdate & { success: false }>,
      total: number,
   ) {
      if (!this.activeUpdate) return;

      // Only show a simple notification if user made one change and it failed.
      if (total == 1 && streamErrors.length == 1) {
         const notification = new Notification({
            text: this.getErrorMessage(streamErrors[0].error),
            icon: Icons.WARNING,
         });
         notificationManagerInstance.show(notification);
         return;
      }

      // Show a full error modal if there were multiple changes.
      const rows = this.rows();
      const failedRows: Array<UpdateFailure<TRow>> = streamErrors
         .map((err) => {
            const row = rows.find((r) => r.id == err.id);
            if (!row) return null;
            return {
               record: row,
               reason: this.getErrorMessage(err.error),
            };
         })
         .filter((row) => row) as Array<UpdateFailure<TRow>>;

      const message = `Failed to update ${failedRows.length} of ${total} records.`;
      let notification: Notification;
      const action = new Action({
         text: "View Failures",
         type: Action.Type.RED,
         onClick: () => {
            const modal = new Modal();
            modal.setPanes([
               new GridStoreRowsUpdaterFailuresModalPane({
                  columnGroups: this.errorModalColumnGroups,
                  updateFailures: failedRows,
               }),
            ]);
            modalManager.showModal(modal, null, { class: "grid-store-rows-updater-modal-pane" });
            notificationManagerInstance.dismiss(notification);
         },
      });
      if (this.activeUpdate.notification) {
         notification = this.activeUpdate.notification;
         this.activeUpdate.notification.failed({
            message,
            actions: [action],
         });
      } else {
         notification = new Notification({
            text: message,
            icon: Icons.WARNING,
            actions: [action],
         });
         notificationManagerInstance.show(notification);
      }
   }

   private showActiveUpdateFailure(error: any) {
      const message = this.getErrorMessage(error);
      if (!this.activeUpdate) return;
      if (this.activeUpdate.notification) {
         this.activeUpdate.notification.failed({ message });
      } else {
         notificationManagerInstance.show(
            new Notification({
               icon: Icons.WARNING,
               text: message,
               duration: 5000,
            }),
         );
      }
   }

   private getErrorMessage(error: any): string {
      const definedMessage = this.errorMessageProvider(error);
      if (definedMessage) return definedMessage;

      // Handle permission errors generically.
      // NOTE: It's a bad idea to add more specific error handling here. Instead,
      // implement custom messages in the `errorMessageProvider`. Permissions errors
      // are a special case because they represent stale UI state.
      const parsedError = parseError(error);
      if (
         parsedError instanceof ValidationError &&
         parsedError.validation.some(
            (v) => v.params?.code == ValidationErrorCode.MISSING_PERMISSION,
         )
      ) {
         return "You do not have permission to make this change.";
      }

      return "An unexpected error occurred while saving.";
   }
}

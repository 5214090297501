import template from "./cost-code-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { SerializedCostCode } from "@laborchart-modules/common/dist/rethink/serializers";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import type { EditorElementParams } from "../common/editor-element-template";

export type CostCodeEditorElementParams = {
   costCode: PureComputed<SerializedCostCode | null>;
   costCodeOptions: PureComputed<SerializedCostCode[]>;
   isDisabled?: PureComputed<boolean>;
   placeholder?: PureComputed<string | null>;
} & EditorElementParams<SerializedCostCode | null>;

export class CostCodeEditorElement extends ViewModel {
   readonly title: string;

   readonly onChange: CostCodeEditorElementParams["onChange"];
   readonly costCode: CostCodeEditorElementParams["costCode"];
   readonly costCodeOptions: CostCodeEditorElementParams["costCodeOptions"];

   readonly costCodeMediator = pureComputed<SerializedCostCode | null>({
      read: () => this.costCode() ?? null,
      write: (costCode) => {
         this.onChange(costCode);
      },
   });

   readonly dropDown2Params: DropDown2Params<SerializedCostCode>;

   constructor({
      onChange,
      costCode,
      costCodeOptions,
      isDisabled,
      placeholder,
      title = "Category",
   }: CostCodeEditorElementParams) {
      super(template());
      this.onChange = onChange;
      this.costCode = costCode;
      this.costCodeOptions = costCodeOptions;
      this.title = title;

      const costCodeDropDownPane = new ArrayDropDownPane({
         items: pureComputed(() => {
            return this.costCodeOptions().sort((left, right) => left.sequence - right.sequence);
         }) as PureComputed<SerializedCostCode[] | Error | null>,
         searchTextProvider: (costCode) => costCode.name,
      });

      this.dropDown2Params = {
         panes: [costCodeDropDownPane],
         cellFactory: TextCell.factory<SerializedCostCode>((item) => item.name),
         isClearable: true,
         isDisabled: isDisabled ?? false,
         placeholder,
         selectedItemCellFactory: TextCell.factory<SerializedCostCode>((item) => item.name),
         selectedIds: pureComputed({
            read: () => {
               const costCodeId = this.costCodeMediator()?.id;
               return new Set(costCodeId ? [costCodeId] : []);
            },
            write: (ids) => {
               const loadedCostCodes = costCodeDropDownPane.gridStore()?.rows() ?? [];
               const selectedCostCode = loadedCostCodes.find(
                  (costCode) => costCode.id == [...ids][0],
               );
               this.costCodeMediator(selectedCostCode ?? null);
            },
         }),
         selectedItem: pureComputed({
            read: () => this.costCodeMediator(),
            write: (data) => {
               this.costCodeMediator(data);
            },
         }),
      };
   }

   static factory(params: CostCodeEditorElementParams): ComponentArgs<CostCodeEditorElementParams> {
      return {
         name: "cost-code-editor-element",
         params,
      };
   }
}

ko.components.register("cost-code-editor-element", {
   viewModel: CostCodeEditorElement,
   template: template(),
   synchronous: true,
});

import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/draggable-points")(Highcharts);

export type LaborGraphProps = {
   data: Highcharts.SeriesAreaOptions[];
   graphStart: number;
   yAxisTitle?: string;
   title?: string;
   xTickInterval?: number;
   yTickInterval?: number;
   enableZoom?: boolean;
   width?: string;
   height?: string;
};
export const LaborGraph = ({
   data,
   graphStart,
   yAxisTitle,
   title,
   xTickInterval = 1,
   yTickInterval = 1,
   enableZoom = false,
   width = "100%",
   height = "100%",
}: LaborGraphProps) => {
   const options: Highcharts.Options = {
      title: {
         text: title,
      },
      credits: {
         enabled: false,
      },
      xAxis: {
         type: "datetime",
         tickInterval: xTickInterval,
         crosshair: true, // Option to extend component by making this a prop value
      },
      yAxis: {
         title: {
            text: yAxisTitle,
         },
         tickInterval: yTickInterval,
      },
      plotOptions: {
         series: {
            pointStart: graphStart,
            pointIntervalUnit: "day",
            pointInterval: 1,
         },
         area: {
            stacking: "normal",
            lineWidth: 0,
            marker: {
               enabled: false,
               symbol: "circle",
            },
         },
      },
      chart: {
         zooming: {
            type: enableZoom ? "x" : undefined,
         },
         height: height,
      },
      tooltip: {
         enabled: true,
         xDateFormat: "%Y-%m-%d",
      },
      series: data,
   };

   return (
      <div style={{ width: width }}>
         <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
   );
};

import "./request-edit-pane.styl";
import template from "./request-edit-pane.pug";
import { ViewModel } from "../../../../../vm/viewmodel";
import type { Observable, ObservableArray, PureComputed } from "knockout";
import { observableArray } from "knockout";
import ko, { pureComputed, observable } from "knockout";
import type { CanRequestSize } from "../../../common/sized-modal";
import type { ComponentArgs } from "@/lib/components/common";
import { ProjectStore } from "@/stores/project-store.core";
import type { SerializedProject } from "@laborchart-modules/common/dist/rethink/serializers/project-serializer";
import type {
   SerializedCostCode,
   SerializedCostCodeLabel,
   SerializedPosition,
   SerializedTag,
} from "@laborchart-modules/common/dist/rethink/serializers";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import type { StagedRequest } from "../../request-details-modal";
import type { ProjectEditorElementParams } from "@/lib/components/modals/editors/project-editor-element/project-editor-element";
import { ProjectEditorElement } from "@/lib/components/modals/editors/project-editor-element/project-editor-element";
import type { CostCodeEditorElementParams } from "@/lib/components/modals/editors/cost-code-editor-element/cost-code-editor-element";
import { CostCodeEditorElement } from "@/lib/components/modals/editors/cost-code-editor-element/cost-code-editor-element";
import type { CostCodeLabelEditorElementParams } from "@/lib/components/modals/editors/cost-code-label-editor-element/cost-code-label-editor-element";
import { CostCodeLabelEditorElement } from "@/lib/components/modals/editors/cost-code-label-editor-element/cost-code-label-editor-element";
import type { PositionEditorElementParams } from "@/lib/components/modals/editors/position-editor-element/position-editor-element";
import { PositionEditorElement } from "@/lib/components/modals/editors/position-editor-element/position-editor-element";
import type { StartDayEditorElementParams } from "@/lib/components/modals/editors/start-day-editor-element/start-day-editor-element";
import {
   StartDayEditorElement,
   StartDayOption,
} from "@/lib/components/modals/editors/start-day-editor-element/start-day-editor-element";
import type { EndDayEditorElementParams } from "@/lib/components/modals/editors/end-day-editor-element/end-day-editor-element";
import {
   EndDayEditorElement,
   EndDayOption,
} from "@/lib/components/modals/editors/end-day-editor-element/end-day-editor-element";
import type {
   WorkDaySelection,
   WorkDayEditorElementParams,
} from "@/lib/components/modals/editors/work-day-editor-element/work-day-editor-element";
import {
   WorkDayEditorElement,
   WorkDay,
} from "@/lib/components/modals/editors/work-day-editor-element/work-day-editor-element";
import type { AllocationTypeEditorElementParams } from "@/lib/components/modals/editors/allocation-type-editor-element/allocation-type-editor-element";
import {
   AllocationType,
   AllocationTypeEditorElement,
} from "@/lib/components/modals/editors/allocation-type-editor-element/allocation-type-editor-element";
import type { NestedComputedAssignmentSupportData } from "@/stores/assignment-2-store.core";
import { DateUtils } from "@/lib/utils/date";
import type { WorkHoursEditorElementParams } from "@/lib/components/modals/editors/work-hours-editor-element/work-hours-editor-element";
import { WorkHoursEditorElement } from "@/lib/components/modals/editors/work-hours-editor-element/work-hours-editor-element";
import type { PercentAllocationEditorElementParams } from "@/lib/components/modals/editors/percent-allocation-editor-element/percent-allocation-editor-element";
import { PercentAllocationEditorElement } from "@/lib/components/modals/editors/percent-allocation-editor-element/percent-allocation-editor-element";
import type { NullableValueCustomFieldInstance } from "@/lib/components/modals/editors/custom-field-editor-element/custom-field-editor-element";
import { CustomFieldEditorElement } from "@/lib/components/modals/editors/custom-field-editor-element/custom-field-editor-element";
import type { CustomFieldValue } from "@laborchart-modules/common/dist/rethink/schemas";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { SerializedCustomFieldInstance } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-instance-serializer";
import type { QuantityEditorElementParams } from "@/lib/components/modals/editors/quantity-editor-element/quantity-editor-element";
import { QuantityEditorElement } from "@/lib/components/modals/editors/quantity-editor-element/quantity-editor-element";
import type { TagsEditorElementParams } from "../../../editors/tags-editor-element/tags-editor-element";
import { TagsEditorElement } from "../../../editors/tags-editor-element/tags-editor-element";
import { authManager } from "@/lib/managers/auth-manager";
import type { StatusEditorElementParams } from "../../../editors/status-editor-element/status-editor-element";
import { StatusEditorElement } from "../../../editors/status-editor-element/status-editor-element";
import type { StatusOption } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import { PermissionLevel } from "@/models/permission-level";
import { getLastWorkDateInNumberOfWeeks, mutateForTimezone } from "@/lib/utils/date-2";

type DisplayOption = "block" | "none";

const enum RequestEditorName {
   ALLOCATION_TYPE_EDITOR = "allocation-type-editor",
   COST_CODE_EDITOR = "cost-code-editor",
   COST_CODE_LABEL_EDITOR = "cost-code-label-editor",
   END_DAY_EDITOR = "end-day-editor",
   JOB_TITLE_EDITOR = "job-title-editor",
   PERCENT_ALLOCATION_EDITOR = "percent-allocation-editor",
   PROJECT_EDITOR = "project-editor",
   QUANTITY_EDITOR = "quantity-editor",
   START_DAY_EDITOR = "start-day-editor",
   STATUS_EDITOR = "status-editor",
   TAGS_EDITOR = "tags-editor",
   WORK_DAY_EDITOR = "work-day-editor",
   WORK_HOURS_EDITOR = "work-hours-editor",
   CUSTOM_FIELD_EDITOR = "custom-field-editor",
}

type RequestEditorData = {
   component: ComponentArgs<any>;
   display: PureComputed<DisplayOption>;
   isUsed: () => boolean;
   name: RequestEditorName;
   validationText: PureComputed<string | null>;
};

class RequestEditPaneState {
   private readonly internalState: {
      allocationType: Observable<AllocationType>;
      endDayNumWeeks: Observable<number | null>;
      endDayOption: Observable<EndDayOption>;
      startDayOption: Observable<StartDayOption>;
   };

   // Nouns.
   readonly project = pureComputed(() => this.stagedRequest().project);
   readonly costCode = pureComputed(() => this.stagedRequest().category);
   readonly costCodeLabel = pureComputed(() => this.stagedRequest().subcategory);
   readonly position = pureComputed(() => this.stagedRequest().job_title);
   readonly statusOption = pureComputed<StatusOption | null>(() => {
      const status = this.stagedRequest().status;

      return status != null
         ? {
              color: status.color,
              id: status.id,
              name: status.name,
              sequence: status.sequence,
              abbreviation: status.abbreviation,
           }
         : null;
   });
   readonly startDay = pureComputed(() => {
      const startDay = this.stagedRequest().start_day;
      return startDay ? getAttachedDate(startDay) : null;
   });
   readonly endDay = pureComputed(() => {
      const endDay = this.stagedRequest().end_day;
      return endDay ? getAttachedDate(endDay) : null;
   });

   readonly costCodeOptions = pureComputed<SerializedCostCode[]>(() => {
      const project = this.project();
      return project != null ? project.categories : [];
   });
   readonly costCodeLabelOptions = pureComputed<SerializedCostCodeLabel[]>(() => {
      const costCode = this.costCode();
      return costCode != null ? costCode.subcategories : [];
   });

   readonly allocationType = pureComputed(() => this.internalState.allocationType());
   readonly endDayNumWeeks = pureComputed(() => this.internalState.endDayNumWeeks());
   readonly endDayOption = pureComputed(() => this.internalState.endDayOption());
   readonly startDayOption = pureComputed(() => this.internalState.startDayOption());

   readonly request: PureComputed<SerializedFindRequest | StagedRequest>;
   readonly stagedRequest: PureComputed<StagedRequest>;
   readonly supportData: NestedComputedAssignmentSupportData;

   readonly updateRequestDetails: (update: Partial<StagedRequest>) => void;

   // Non-writing helpers.
   readonly isWorkDay = (day: WorkDay) => this.stagedRequest().work_days[day];

   constructor({
      request,
      stagedRequest,
      supportData,
      updateRequestDetails,
   }: {
      request: PureComputed<SerializedFindRequest | StagedRequest>;
      stagedRequest: PureComputed<StagedRequest>;
      supportData: NestedComputedAssignmentSupportData;
      updateRequestDetails: (update: Partial<StagedRequest>) => void;
   }) {
      this.internalState = {
         allocationType: observable(
            request().percent_allocated != null ? AllocationType.PERCENT : AllocationType.HOURS,
         ),
         endDayNumWeeks: observable<number | null>(supportData.companyTbdWeeks()),
         endDayOption: observable<EndDayOption>(EndDayOption.DATE),
         startDayOption: observable<StartDayOption>(StartDayOption.DATE),
      };
      this.request = request;
      this.stagedRequest = stagedRequest;
      this.supportData = supportData;
      this.updateRequestDetails = updateRequestDetails;
   }

   // Verbs.
   setProject = (project: SerializedProject | null) => {
      if (project?.id == this.stagedRequest().project_id) return;

      this.updateRequestDetails({
         project: project,
         project_id: project?.id ?? null,
      });

      if (this.startDayOption() == StartDayOption.PROJECT_START) {
         // Trigger recomputing to ensure that start day is still correct.
         this.setStartDayOption(this.startDayOption());
      }
      if (project?.est_end_date != null && this.endDayOption() == EndDayOption.TBD) {
         this.setEndDayOption(EndDayOption.DATE);
      } else if (project?.est_end_date == null && this.endDayOption() == EndDayOption.PROJECT_END) {
         this.setEndDayOption(EndDayOption.DATE);
      } else if (project?.est_end_date != null && this.endDayOption() == EndDayOption.PROJECT_END) {
         this.setEndDayOption(EndDayOption.PROJECT_END);
      }
      if (
         this.allocationType() == AllocationType.HOURS &&
         (this.request().start_time == null || this.request().end_time == null)
      ) {
         // Trigger recomputing to auto-set start and end time if not already set.
         this.setAllocationType(this.allocationType());
      }

      if (project === null) {
         // Clear the cost code if project was cleared.
         this.setCostCode(null);
         return;
      }

      if (project.id != this.request().project_id) {
         // Clear the cost code if project was changed from original state.
         this.setCostCode(null);
      } else {
         const selectedCostCodeId = this.request().category_id;
         const selectedCostCode = selectedCostCodeId
            ? project.categories.find((category) => selectedCostCodeId == category.id) ?? null
            : null;
         this.setCostCode(selectedCostCode);
      }
   };
   setCostCode = (costCode: SerializedCostCode | null) => {
      if (costCode?.id == this.stagedRequest().category_id) return;

      this.updateRequestDetails({
         category: costCode,
         category_id: costCode?.id ?? null,
      });

      if (costCode === null) {
         // Clear the cost code label if cost code was cleared.
         this.setCostCodeLabel(null);
         return;
      }

      if (costCode.id != this.request().category_id) {
         // Clear the cost code label if cost code was changed from original state.
         this.setCostCodeLabel(null);
      } else {
         // Reset the cost code label to the original value if
         // cost code value was reverted to original value.
         const selectedCostCodeLabelId = this.request().subcategory_id;
         const selectedCostCodeLabel = selectedCostCodeLabelId
            ? costCode.subcategories.find(
                 (subcategory) => selectedCostCodeLabelId == subcategory.id,
              ) ?? null
            : null;
         this.setCostCodeLabel(selectedCostCodeLabel);
      }
   };
   setCostCodeLabel = (costCodeLabel: SerializedCostCodeLabel | null) => {
      if (costCodeLabel?.id == this.stagedRequest().subcategory_id) return;

      this.updateRequestDetails({
         subcategory: costCodeLabel,
         subcategory_id: costCodeLabel?.id ?? null,
      });
   };
   setPosition = (position: SerializedPosition | null) => {
      this.updateRequestDetails({ job_title: position, job_title_id: position?.id ?? null });
   };
   setStatus = (status: StatusOption | null) => {
      this.updateRequestDetails({
         status:
            status != null
               ? {
                    abbreviation: status.abbreviation,
                    color: status.color! ?? null,
                    company_id: authManager.companyId(),
                    id: status.id! ?? null,
                    name: status.name! ?? null,
                    sequence: status.sequence,
                 }
               : null,
         status_id: status?.id ?? null,
      });
   };
   setStartDay = (startDay: Date | null) => {
      if (startDay) this.updateRequestDetails({ start_day: getDetachedDay(startDay) });
      else this.updateRequestDetails({ start_day: null });

      if ([EndDayOption.TBD, EndDayOption.WEEKS].includes(this.endDayOption()))
         // Trigger recomputing to ensure that end day is still correct.
         this.setEndDayOption(this.endDayOption());
   };
   setEndDay = (endDay: Date | null) => {
      if (endDay) this.updateRequestDetails({ end_day: getDetachedDay(endDay) });
      else this.updateRequestDetails({ end_day: null });
   };
   setEndDayNumWeeks = (endDayNumWeeks: number | null) => {
      this.internalState.endDayNumWeeks(endDayNumWeeks);
      if (endDayNumWeeks == null || endDayNumWeeks < 1 || endDayNumWeeks > 4000) return;

      const endDate = getLastWorkDateInNumberOfWeeks({
         numberOfWeeks: endDayNumWeeks,
         startDate: this.startDay() ?? new Date(),
         workDays: this.stagedRequest().work_days,
      });

      this.setEndDay(endDate);
   };
   updateWorkDays = (workDayUpdate: Partial<WorkDaySelection>) => {
      const newWorkDays = {
         ...this.stagedRequest().work_days,
         ...workDayUpdate,
      };
      this.updateRequestDetails({
         work_days: {
            [WorkDay.SUNDAY]: newWorkDays[WorkDay.SUNDAY],
            [WorkDay.MONDAY]: newWorkDays[WorkDay.MONDAY],
            [WorkDay.TUESDAY]: newWorkDays[WorkDay.TUESDAY],
            [WorkDay.WEDNESDAY]: newWorkDays[WorkDay.WEDNESDAY],
            [WorkDay.THURSDAY]: newWorkDays[WorkDay.THURSDAY],
            [WorkDay.FRIDAY]: newWorkDays[WorkDay.FRIDAY],
            [WorkDay.SATURDAY]: newWorkDays[WorkDay.SATURDAY],
         },
      });

      const endDayOption = this.endDayOption();
      if ([EndDayOption.TBD, EndDayOption.WEEKS].includes(endDayOption))
         // Trigger recomputing to ensure that end day is still a valid work day.
         this.setEndDayNumWeeks(this.endDayNumWeeks());
      if (this.startDayOption() == StartDayOption.PROJECT_START)
         // Trigger recomputing to ensure that start day is still a valid work day.
         this.setStartDayOption(this.startDayOption());
   };
   setStartTime = (startTime: number | null) => {
      this.updateRequestDetails({ start_time: startTime });
   };
   setEndTime = (endTime: number | null) => {
      this.updateRequestDetails({ end_time: endTime });
   };
   setPercentAllocation = (percentAllocation: number | null) => {
      this.updateRequestDetails({ percent_allocated: percentAllocation });
   };

   setAllocationType = (allocationType: AllocationType) => {
      this.internalState.allocationType(allocationType);
      if (allocationType === AllocationType.HOURS) {
         this.setPercentAllocation(null);
         const project = this.project();
         this.setStartTime(this.request().start_time ?? project?.daily_start_time ?? null);
         this.setEndTime(this.request().end_time ?? project?.daily_end_time ?? null);
      }
      if (allocationType === AllocationType.PERCENT) {
         this.setStartTime(null);
         this.setEndTime(null);
         this.setPercentAllocation(this.request().percent_allocated ?? 100);
      }
   };
   setEndDayOption = (endDayOption: EndDayOption) => {
      this.internalState.endDayOption(endDayOption);

      if ([EndDayOption.TBD, EndDayOption.WEEKS].includes(endDayOption)) {
         const weeks =
            endDayOption == EndDayOption.TBD
               ? this.supportData.companyTbdWeeks()
               : this.endDayNumWeeks();
         this.setEndDayNumWeeks(weeks);
      } else if (endDayOption == EndDayOption.PROJECT_END) {
         const project = this.project();
         const projectEndDate =
            project?.est_end_date != null ? new Date(project.est_end_date) : new Date();
         mutateForTimezone(projectEndDate);
         this.setEndDay(projectEndDate);
      }
   };
   setStartDayOption = (startDayOption: StartDayOption) => {
      this.internalState.startDayOption(startDayOption);

      if (startDayOption === StartDayOption.PROJECT_START) {
         const project = this.project();
         const projectStartDate =
            project && project.start_date ? new Date(project.start_date) : new Date();
         mutateForTimezone(projectStartDate);

         // Get the offset needed to select the first work day on or after the project start date.
         const offset =
            [0, 1, 2, 3, 4, 5, 6].find((offset) =>
               this.isWorkDay((projectStartDate.getDay() + offset) % 7),
            ) ?? 0;
         const startDate =
            offset == 0 ? projectStartDate : DateUtils.incrementDate(projectStartDate, offset);
         this.setStartDay(startDate);
      }
   };
   setQuantity = (quantity: number | null) => {
      this.updateRequestDetails({ quantity: quantity });
   };
   setTagIds = (tagIds: Set<string>) => {
      this.updateRequestDetails({ tag_ids: [...tagIds] });
   };

   setCustomFieldValue = ({
      customField,
      value,
   }: {
      customField: SerializedCustomField;
      value: CustomFieldValue | null;
   }) => {
      const newInstance =
         value == null
            ? // Null values don't have instances.
              null
            : ({
                 field_id: customField.id,
                 type: customField.type,
                 value,
                 name: customField.name,
                 integration_name: customField.integration_name,
              } as SerializedCustomFieldInstance);
      const newFields = this.stagedRequest()
         .custom_fields.filter((field) => field.field_id != customField.id)
         .concat(newInstance != null ? [newInstance] : []);
      this.updateRequestDetails({
         custom_fields: newFields,
      });
   };
}

export type RequestEditPaneParams = {
   customFields: PureComputed<SerializedCustomField[]>;
   isNewRequest: PureComputed<boolean>;
   preventingSave: PureComputed<boolean>;
   request: PureComputed<SerializedFindRequest | StagedRequest>;
   setPreventingSave: (preventSave: boolean) => void;
   stagedRequest: PureComputed<StagedRequest>;
   supportData: NestedComputedAssignmentSupportData;
   tags: PureComputed<SerializedTag[]>;
   updateRequestDetails: (update: Partial<StagedRequest>) => void;
} & CanRequestSize;

export class RequestEditPane extends ViewModel implements CanRequestSize {
   private readonly state: RequestEditPaneState;

   readonly editorSections: Array<ObservableArray<RequestEditorData>>;

   readonly customFields: RequestEditPaneParams["customFields"];
   readonly isNewRequest: RequestEditPaneParams["isNewRequest"];
   readonly requestSize: RequestEditPaneParams["requestSize"];
   readonly tags: RequestEditPaneParams["tags"];

   readonly projectPlaceholder: Observable<string>;

   readonly preventingSave: PureComputed<boolean>;
   readonly setPreventingSave: (preventSave: boolean) => void;

   readonly enabledCustomFieldEditors: ObservableArray<RequestEditorData>;
   readonly disabledCustomFieldEditors: ObservableArray<RequestEditorData>;
   readonly getCustomFieldEditors = (integrationOnly: boolean): RequestEditorData[] => {
      const customFields = this.customFields();

      const editorFactories = customFields
         .filter((field) => field.integration_only == integrationOnly)
         .map((field) => {
            const customFieldInstance = pureComputed<NullableValueCustomFieldInstance>(() => {
               const exisitingCustomFieldInstance =
                  this.state
                     .stagedRequest()
                     .custom_fields.find((instance) => instance.field_id == field.id) ?? null;
               if (exisitingCustomFieldInstance != null) {
                  return exisitingCustomFieldInstance as NullableValueCustomFieldInstance;
               }
               return {
                  field_id: field.id,
                  integration_name: field.integration_name,
                  name: field.name,
                  type: field.type,
                  value: null,
               };
            });
            return CustomFieldEditorElement.factory({
               customField: field,
               customFieldInstance,
               invalidInputMessage: pureComputed<string | null>(() => null),
               isDisabled: field.integration_only,
               onChange: (value) => {
                  this.state.setCustomFieldValue({
                     customField: field,
                     value,
                  });
               },
               requestSize: () => {},
            });
         });

      return editorFactories.map<RequestEditorData>((component) => ({
         component,
         display: pureComputed<DisplayOption>(() => "block" as const),
         isUsed: () => true,
         name: RequestEditorName.CUSTOM_FIELD_EDITOR,
         validationText: pureComputed<string | null>(() => null),
      }));
   };

   private readonly editorList: RequestEditorData[];

   constructor({
      customFields,
      isNewRequest,
      preventingSave,
      request,
      requestSize,
      setPreventingSave,
      stagedRequest,
      supportData,
      tags,
      updateRequestDetails,
   }: RequestEditPaneParams) {
      super(template());
      this.state = new RequestEditPaneState({
         request,
         stagedRequest,
         supportData,
         updateRequestDetails,
      });
      this.isNewRequest = isNewRequest;
      this.preventingSave = preventingSave;
      requestSize({
         width: 720,
         height: 500,
      });
      this.requestSize = requestSize;

      this.setPreventingSave = setPreventingSave;

      this.customFields = customFields;
      this.projectPlaceholder = observable("Loading...");
      this.tags = tags;

      const sectionedEditorList = this.getSectionedEditorList();
      this.editorList = sectionedEditorList.reduce((acc, cur) => [...acc, ...cur], []);
      this.editorSections = sectionedEditorList.map((section) => {
         return observableArray(section.filter((data) => data.isUsed()));
      });
      this.enabledCustomFieldEditors = observableArray(this.getCustomFieldEditors(false));
      this.disabledCustomFieldEditors = observableArray(this.getCustomFieldEditors(true));

      this.load();

      this.setPreventingSave(!this.isAllValid());
      this.isAllValid.subscribe((isAllValid) => {
         this.setPreventingSave(!isAllValid);
      });
   }

   async load(): Promise<void> {
      // TODO: Load status tracking.
      try {
         await Promise.all([this.loadProjectData()]);
      } catch {
         // TODO: Error handling.
      }
   }

   private async loadProjectData(): Promise<void> {
      const projectId = this.state.stagedRequest().project_id;
      if (projectId == null) return;
      try {
         // Get project data and update project state.
         const selectedProject = (await ProjectStore.getProject(projectId).payload).data;
         this.state.setProject(selectedProject);
      } catch (error) {
         this.state.setProject(null);
         throw error;
      }
   }

   startDayEditorFactory = (): ComponentArgs<StartDayEditorElementParams> => {
      return StartDayEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.START_DAY_EDITOR)!
               .validationText();
         }),
         onStartDayChange: (startDay) => {
            this.state.setStartDay(startDay);
         },
         onStartDayOptionChange: (startDayOption) => {
            this.state.setStartDayOption(startDayOption);
         },
         requestSize: () => {},
         startDay: this.state.startDay,
         startDayOption: this.state.startDayOption,
         title: "Start Day",
      });
   };

   endDayEditorFactory = (): ComponentArgs<EndDayEditorElementParams> => {
      return EndDayEditorElement.factory({
         endDay: this.state.endDay,
         endDayNumWeeks: this.state.endDayNumWeeks,
         endDayOption: this.state.endDayOption,
         projectHasEndDay: pureComputed(
            () => this.state.stagedRequest().project?.est_end_date != null,
         ),
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.END_DAY_EDITOR)!
               .validationText();
         }),
         onEndDayChange: (endDay) => {
            this.state.setEndDay(endDay);
         },
         onEndDayNumWeeksChange: (endDayNumWeeks) => {
            this.state.setEndDayNumWeeks(endDayNumWeeks);
         },
         onEndDayOptionChange: (endDayOption) => {
            this.state.setEndDayOption(endDayOption);
         },
         requestSize: () => {},
         title: "End Day",
      });
   };

   jobTitleEditorFactory = (): ComponentArgs<PositionEditorElementParams> => {
      return PositionEditorElement.factory({
         onChange: (position) => {
            this.state.setPosition(position);
         },
         position: this.state.position,
         title: "Needed Job Title",
         requestSize: () => {},
      });
   };

   statusEditorFactory = (): ComponentArgs<StatusEditorElementParams> => {
      return StatusEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.STATUS_EDITOR)!
               .validationText();
         }),
         onChange: (status) => {
            this.state.setStatus(status);
         },
         status: this.state.statusOption,
         title: "Status",
         requestSize: () => {},
      });
   };

   projectEditorFactory = (): ComponentArgs<ProjectEditorElementParams> => {
      return ProjectEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.PROJECT_EDITOR)!
               .validationText();
         }),
         onChange: (project) => {
            this.state.setProject(project);
         },
         project: this.state.project,
         title: "Project",
         requestSize: () => {},
      });
   };

   quantityEditorFactory = (): ComponentArgs<QuantityEditorElementParams> => {
      return QuantityEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.QUANTITY_EDITOR)!
               .validationText();
         }),
         onChange: (quantity) => {
            this.state.setQuantity(quantity);
         },
         requestSize: () => {},
         title: "Quantity",
         value: pureComputed(() => this.state.stagedRequest().quantity ?? null),
      });
   };

   tagsEditorFactory = (): ComponentArgs<TagsEditorElementParams> => {
      return TagsEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.TAGS_EDITOR)!
               .validationText();
         }),
         onChange: (tagIds) => {
            this.state.setTagIds(tagIds);
         },
         requestSize: () => {},
         title: "Tags",
         selectedTagIds: pureComputed(() => new Set(this.state.stagedRequest().tag_ids)),
         tags: this.tags,
      });
   };

   costCodeEditorFactory = (): ComponentArgs<CostCodeEditorElementParams> => {
      return CostCodeEditorElement.factory({
         onChange: (costCode) => {
            this.state.setCostCode(costCode);
         },
         costCode: this.state.costCode,
         costCodeOptions: this.state.costCodeOptions,
         isDisabled: pureComputed(
            () => this.state.project() == null || this.state.costCodeOptions().length == 0,
         ),
         placeholder: pureComputed<string | null>(() =>
            this.state.project() == null
               ? "Project must be selected first."
               : this.state.costCodeOptions().length == 0
               ? "This project has no category options."
               : null,
         ),
         title: "Category",
         requestSize: () => {},
      });
   };

   costCodeLabelEditorFactory = (): ComponentArgs<CostCodeLabelEditorElementParams> => {
      return CostCodeLabelEditorElement.factory({
         onChange: (costCodeLabel) => {
            this.state.setCostCodeLabel(costCodeLabel);
         },
         costCodeLabel: this.state.costCodeLabel,
         costCodeLabelOptions: this.state.costCodeLabelOptions,
         isDisabled: pureComputed(
            () => this.state.costCode() == null || this.state.costCodeLabelOptions().length == 0,
         ),
         placeholder: pureComputed<string | null>(() =>
            this.state.costCode() == null
               ? "Category must be selected first."
               : this.state.costCodeLabelOptions().length == 0
               ? "This category has no subcategory options."
               : null,
         ),
         title: "Subcategory",
         requestSize: () => {},
      });
   };

   workDayEditorFactory = (): ComponentArgs<WorkDayEditorElementParams> => {
      return WorkDayEditorElement.factory({
         onChange: (workDays) => {
            this.state.updateWorkDays(workDays);
         },
         workDays: pureComputed(() => this.state.stagedRequest().work_days),
         title: "Working Days",
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.WORK_DAY_EDITOR)!
               .validationText();
         }),
         requestSize: () => {},
      });
   };

   allocationTypeEditorFactory = (): ComponentArgs<AllocationTypeEditorElementParams> => {
      return AllocationTypeEditorElement.factory({
         onChange: (type: AllocationType) => {
            this.state.setAllocationType(type);
         },
         currentAllocationType: this.state.allocationType,
         title: "Allocation Type",
         requestSize: () => {},
      });
   };

   workHoursEditorFactory = (): ComponentArgs<WorkHoursEditorElementParams> => {
      return WorkHoursEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.WORK_HOURS_EDITOR)!
               .validationText();
         }),
         onStartTimeChange: (startTime) => {
            this.state.setStartTime(startTime);
         },
         onEndTimeChange: (endTime) => {
            this.state.setEndTime(endTime);
         },
         startTime: pureComputed(() => this.state.stagedRequest().start_time),
         endTime: pureComputed(() => this.state.stagedRequest().end_time),
         title: "Work Hours",
         requestSize: () => {},
      });
   };

   percentAllocationEditorFactory = (): ComponentArgs<PercentAllocationEditorElementParams> => {
      return PercentAllocationEditorElement.factory({
         invalidInputMessage: pureComputed(() => {
            if (this.editorList == null) return null;
            return this.editorList
               .find((editor) => editor.name === RequestEditorName.PERCENT_ALLOCATION_EDITOR)!
               .validationText();
         }),
         onChange: (percentAllocation) => {
            this.state.setPercentAllocation(percentAllocation);
         },
         percentAllocation: pureComputed(() => this.state.stagedRequest().percent_allocated),
         title: "Percent Allocated",
         requestSize: () => {},
      });
   };

   private readonly isAllValid = pureComputed(() => {
      return this.editorList
         .filter((editor) => editor.display() != "none" && editor.isUsed() === true)
         .every((editor) => editor.validationText() == null);
   });

   private readonly getSectionedEditorList: () => RequestEditorData[][] = () => [
      [
         {
            component: this.projectEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.PROJECT_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               const project_id = this.state.stagedRequest().project_id;
               return project_id == null ? "Required" : null;
            }),
         },
         {
            component: this.costCodeEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.COST_CODE_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => null),
         },
         {
            component: this.costCodeLabelEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.COST_CODE_LABEL_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => null),
         },
         {
            component: this.jobTitleEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.JOB_TITLE_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => null),
         },
         {
            component: this.statusEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.STATUS_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               return authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES) !==
                  true && this.state.stagedRequest().status_id == null
                  ? "Required"
                  : null;
            }),
         },
         {
            component: this.workDayEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.WORK_DAY_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               return Object.values(this.state.stagedRequest().work_days).every(
                  (isWorkDay) => !isWorkDay,
               )
                  ? "Must have at least one work day selected"
                  : null;
            }),
         },
         {
            component: this.tagsEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.TAGS_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => null),
         },
         {
            component: this.quantityEditorFactory(),
            isUsed: () => this.isNewRequest(),
            name: RequestEditorName.QUANTITY_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               const quantity = this.state.stagedRequest().quantity;
               return quantity == null
                  ? null
                  : quantity < 1
                  ? "Must be at least 1"
                  : quantity > 100
                  ? "Must be less than or equal to 100"
                  : null;
            }),
         },
      ],
      [
         {
            component: this.startDayEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.START_DAY_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               const startDay = this.state.startDay();
               return startDay == null
                  ? "Required"
                  : !this.state.isWorkDay(startDay.getDay())
                  ? "Must be a work day"
                  : startDay.getTime() > (this.state.endDay()?.getTime() ?? 0)
                  ? "Start day must be before end day"
                  : null;
            }),
         },
         {
            component: this.endDayEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.END_DAY_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => {
               const endDay = this.state.endDay();
               const endDayOption = this.state.endDayOption();
               const endDayNumWeeks = this.state.endDayNumWeeks();
               return endDay == null
                  ? "Required"
                  : !this.state.isWorkDay(endDay.getDay())
                  ? "Must be a work day"
                  : (this.state.startDay()?.getTime() ?? 0) > endDay.getTime()
                  ? "End day must be after start day"
                  : endDayNumWeeks == null
                  ? "Must enter a number of weeks"
                  : endDayOption === EndDayOption.WEEKS && endDayNumWeeks > 4000
                  ? "Number of weeks cannot be over 4000"
                  : endDayOption === EndDayOption.WEEKS && endDayNumWeeks < 1
                  ? "Number of weeks cannot be less than 1"
                  : null;
            }),
         },
         {
            component: this.allocationTypeEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.ALLOCATION_TYPE_EDITOR,
            display: pureComputed<DisplayOption>(() => "block" as const),
            validationText: pureComputed<string | null>(() => null),
         },
         {
            component: this.workHoursEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.WORK_HOURS_EDITOR,
            display: pureComputed<DisplayOption>(() =>
               this.state.allocationType() === AllocationType.HOURS ? "block" : "none",
            ),
            validationText: pureComputed<string | null>(() => {
               const startTime = this.state.stagedRequest().start_time;
               const endTime = this.state.stagedRequest().end_time;
               return startTime == null || endTime == null
                  ? "Required"
                  : startTime < 0 || startTime > 23.75
                  ? "Invalid start time"
                  : endTime < 0 || endTime > 23.75
                  ? "Invalid end time"
                  : null;
            }),
         },
         {
            component: this.percentAllocationEditorFactory(),
            isUsed: () => true,
            name: RequestEditorName.PERCENT_ALLOCATION_EDITOR,
            display: pureComputed<DisplayOption>(() =>
               this.state.allocationType() === AllocationType.PERCENT ? "block" : "none",
            ),
            validationText: pureComputed<string | null>(() => {
               const percentAllocation = this.state.stagedRequest().percent_allocated;
               return percentAllocation == null
                  ? "Required"
                  : percentAllocation <= 0
                  ? "Must be greater than zero"
                  : null;
            }),
         },
      ],
   ];

   static factory(params: RequestEditPaneParams): ComponentArgs<RequestEditPaneParams> {
      return {
         name: "request-edit-pane",
         params,
      };
   }
}

ko.components.register("request-edit-pane", {
   viewModel: RequestEditPane,
   template: template(),
   synchronous: true,
});

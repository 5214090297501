import { Model } from "@/models/model"

export class GeneratedReport extends Model
   constructor: (data) ->
      super(data)
      { id, creator_id, created_at, generated_at, report_status, name, company_id, group_id, report_type, payload} = data
      @id = id;
      @creatorId = ko.observable(creator_id)
      @createdAt = ko.observable(new Date(created_at))
      @companyId = ko.observable(company_id)
      @groupId = ko.observable(group_id)
      @name = ko.observable(name)
      @reportType = ko.observable(report_type)
      @reportStatus = ko.observable(report_status)
      @payload = ko.observable(payload)
      @generatedAt = ko.observable(generated_at)

GeneratedReport.ReportStatus = {
   PENDING: "pending",
   FAILED: "failed",
   COMPLETE: "complete",
}

GeneratedReport.ProgressStage = {
   PREPARING: "preparing",
   AGGREGATING: "aggregating",
   RENDERING: "rendering", 
}

GeneratedReport.STAGE_ORDER = [
   GeneratedReport.ProgressStage.PREPARING,
   GeneratedReport.ProgressStage.AGGREGATING,
   GeneratedReport.ProgressStage.RENDERING,
]

import { observable, unwrap } from "knockout";
import { ArrayDropDownPane } from "./array-drop-down-pane";
import type { LoadItemsParams } from "../drop-down-pane";
import { StatusStore } from "@/stores/status-store.core";

export interface StatusOption {
   abbreviation: string | null;
   id: string;
   color: string;
   name: string;
   sequence: number;
}

/** `DropDownStore` backed by flag (state) options. */
export class StatusDropDownPane extends ArrayDropDownPane<StatusOption> {
   constructor() {
      super({
         items: observable(null),
         searchTextProvider: (item) => item.name,
      });
   }

   async loadItems(params: LoadItemsParams): Promise<void> {
      super.loadItems(params);
      if (unwrap(this.items) != null) return;
      try {
         const stream = await StatusStore.findStatusesStream({}).stream;
         const statuses: StatusOption[] = [];
         for await (const status of stream) {
            statuses.push({
               abbreviation: status.abbreviation,
               id: status.id,
               color: status.color,
               name: status.name,
               sequence: status.sequence,
            });
         }
         this.items(statuses);
      } catch {
         this.items(new Error("Failed to load"));
      }
   }
}

var pug = require("!../../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-assignable"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-assignable\" width=\"20px\" height=\"16px\" viewbox=\"0 0 20 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-assignable\" fill=\"#7C8084\"\u003E\u003Crect id=\"icon-assignable__bg\" opacity=\"0\" x=\"0\" y=\"0\" width=\"20\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003Cpath id=\"icon-assignable__shape\" d=\"M1.66666667,0 L18.3333333,0 C19.2083333,0 20,0.844444444 20,1.77777778 L20,14.2222222 C20,15.1555556 19.2083333,16 18.3333333,16 L1.66666667,16 C0.791666667,16 0,15.1555556 0,14.2222222 L0,1.77777778 C0,0.844444444 0.791666667,0 1.66666667,0 L1.66666667,0 Z M11.25,2.66666667 L11.25,3.55555556 L18.25,3.55555556 L18.25,2.66666667 L11.25,2.66666667 L11.25,2.66666667 Z M11.25,4.44444444 L11.25,5.33333333 L17.8125,5.33333333 L18.25,5.33333333 L18.25,4.44444444 L11.25,4.44444444 L11.25,4.44444444 Z M11.25,6.22222222 L11.25,7.11111111 L17.375,7.11111111 L17.375,6.22222222 L11.25,6.22222222 L11.25,6.22222222 Z M7,9.69777778 C5.25,9.69777778 1.75,10.6666667 1.75,12.4444444 L1.75,13.3333333 L12.25,13.3333333 L12.25,12.4444444 C12.25,10.6666667 8.75,9.69777778 7,9.69777778 L7,9.69777778 Z M7,2.66666667 C5.55025253,2.66666667 4.375,3.860574 4.375,5.33333333 C4.375,6.80609267 5.55025253,8 7,8 C8.44974747,8 9.625,6.80609267 9.625,5.33333333 C9.625,3.860574 8.44974747,2.66666667 7,2.66666667 Z\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-user"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-user\" width=\"16px\" height=\"16px\" viewbox=\"0 0 16 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-user\"\u003E\u003Crect id=\"icon-user__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003Cpath id=\"icon-user__shape\" d=\"M8,0 C10.209139,0 12,1.790861 12,4 C12,6.209139 10.209139,8 8,8 C5.790861,8 4,6.209139 4,4 C4,1.790861 5.790861,0 8,0 L8,0 Z M8,10 C12.42,10 16,11.79 16,14 L16,16 L0,16 L0,14 C0,11.79 3.58,10 8,10 Z\" fill=\"#7C8084\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-user-invite-pending"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-user-invite-pending\" width=\"18px\" height=\"16px\" viewbox=\"0 0 18 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-user-pending\"\u003E\u003Crect id=\"icon-user-pending__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"18\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003Cpath id=\"icon-user-pending__shape\" d=\"M8,0 C10.209139,0 12,1.790861 12,4 C12,6.209139 10.209139,8 8,8 C5.790861,8 4,6.209139 4,4 C4,1.790861 5.790861,0 8,0 L8,0 Z M8,10 C12.42,10 16,11.79 16,14 L16,16 L0,16 L0,14 C0,11.79 3.58,10 8,10 L8,10 Z M16,6 L16,1 L18,1 L18,6 L16,6 L16,6 Z M16,10 L16,8 L18,8 L18,10 L16,10 Z\" fill=\"#7C8084\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"person-type__container\" data-bind=\"foreach: types\"\u003E\u003C!-- ko if: $data === 'user' --\u003E";
pug_mixins["icon-user"]();
pug_html = pug_html + "\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: $data === 'assignable' --\u003E";
pug_mixins["icon-assignable"]();
pug_html = pug_html + "\u003C!-- \u002Fko --\u003E\n\u003C!-- ko if: $data === 'invite-pending' --\u003E";
pug_mixins["icon-user-invite-pending"]();
pug_html = pug_html + "\u003C!-- \u002Fko --\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;
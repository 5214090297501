import ko from "knockout"

export class Column
   constructor: (params) ->
      data = params || {}
       
      @key = ko.observable(data.key)
      @keyId = ko.observable(data.keyId)
      @title = ko.observable(data.title)
      @meta = ko.observable(data.meta || {})
      @sequence = ko.observable(data.sequence)
      @headerTemplate = ko.observable(data.headerTemplate)
      @cellTemplate = ko.observable(data.cellTemplate)
      @isSortable = ko.observable(data.isSortable)
      @sortActive = ko.observable(data.sortActive)
      @sortOrder = ko.observable(data.sortOrder)
      @width = ko.observable(data.width || 155)
      @resize = ko.observable(!!data.resize)

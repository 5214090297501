import React, { useEffect } from "react";
import type { NumberInputValueChange as ValueChange } from "@procore/core-react";
import { NumberInput, FlexList, Select, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import { filterClassifiers } from "@/react/shared/constants";
type CustomFilterComponentPropTypes = {
   columnDefinition: ColumnDefinition;
   onChange: (newValues: any[]) => void;
   value: any;
};
export const NumericValueFilter = (props: CustomFilterComponentPropTypes) => {
   // The numeric filter will only allow one value to be entered at a time, but the
   // props.value will always come as an array. In the future there is potential to enhance this component
   // to allow multiple values to be entered. But for now, we only care about the first value in the props.value
   // array.
   const [classifier, setClassifier] = React.useState<
      undefined | { id: number; label: string; value: string }
   >(props.value?.[0]?.classifier);
   const [value, setValue] = React.useState<number | null>(props.value?.[0]?.value);
   const [field, setField] = React.useState<string | null>(null);
   const I18n = useI18nContext();

   useEffect(() => {
      setField(props.columnDefinition.field);
      if (props.value && props.value.length > 0) {
         setClassifier(props.value[0].classifier);
         setValue(props.value[0].value);
      } else {
         setValue(null);
      }
   }, [props.value]);

   /* istanbul ignore next */
   function handleClassifierSelect(selection: any) {
      const selected = filterClassifiers.find((x) => x.value == selection.item);
      if (selected) {
         //a shallow copy of the selected object to avoid mutating the original object
         const selectedCopy = { ...selected };
         selectedCopy.label = I18n.t(selectedCopy.label);
         setClassifier(selectedCopy);
      } else {
         setClassifier(undefined);
      }
      setValue(null);
      props.onChange([]);
   }

   function handleNumberChange(val: ValueChange) {
      if (classifier && val.parsedNumber != null) {
         // added this additional check to validate percent_complete since %age value lies between 0-100
         if (field === "percent_complete" && !(val.parsedNumber >= 0 && val.parsedNumber <= 100)) {
            return;
         }
         const newValue = [
            { classifier: classifier, value: val.parsedNumber, stringValue: val.value },
         ];
         setValue(val.parsedNumber);
         props.onChange(newValue);
      } else {
         setValue(null);
         props.onChange([]);
      }
   }

   return (
      <FlexList>
         <Select
            label={classifier?.label}
            onSelect={handleClassifierSelect}
            placeholder={I18n.t("views.company.workforce_planning.classifiers.placeholder")}
            style={{ width: "190px" }}
         >
            {filterClassifiers.map((c) => (
               <Select.Option key={c.id} value={c.value} selected={c.value === classifier?.value}>
                  {I18n.t(c.label)}
               </Select.Option>
            ))}
         </Select>
         <NumberInput
            disabled={!classifier}
            onChange={handleNumberChange}
            value={value ?? null}
            title="numeric-value-filter"
            placeholder={I18n.t("views.company.workforce_planning.filters.value_placeholder")}
         />
      </FlexList>
   );
};

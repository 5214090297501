import React, { useEffect, useState } from "react";
import {
   Box,
   Button,
   Card,
   EmptyState,
   FlexList,
   H2,
   Spinner,
   useI18nContext,
} from "@procore/core-react";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { useModal } from "@/react/shared/hooks/useModal";
import { useToastAlertContext } from "@procore/toast-alert";

import { ActionMode } from "@/react/prop-types";
import { FileDownloadModal } from "@/react/shared/modals/file-download-modal";

import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import type {
   AttachmentsData,
   DetailsAttachmentCardProps,
   EmptyAttachmentProps,
   ListAttachmentsCardProps,
} from "./types";
import { AddAttachments } from "./details-add-attachments";
import { getDateString } from "../project/helpers";
import { PageType } from "../types";
import { getAttachmentKeys } from "./helper";

const EmptyAttachments: React.FC<EmptyAttachmentProps> = ({
   openAddAttachmentModal,
   isAddAttachmentOpen,
   pageType,
}) => {
   const I18n = useI18nContext();
   if (isAddAttachmentOpen) {
      return null;
   }
   // common for both project and people
   const title = "views.company.workforce_planning.project_detail_attachments.title";
   const emptyStateTitle =
      "views.company.workforce_planning.project_detail_attachments.attachments_empty_state_title";
   const buttonText = "views.company.workforce_planning.project_detail_attachments.add_attachment";

   // specific to project or people
   const emptyStateDescription = `views.company.workforce_planning.${
      pageType === PageType.PROJECT ? "project" : "people"
   }_detail_attachments.attachments_empty_state_description`;

   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t(title)}</H2>
            </FlexList>
         </Box>
         <Box>
            <EmptyState compact>
               <EmptyState.Title>{I18n.t(emptyStateTitle)}</EmptyState.Title>
               <EmptyState.Description className="empty-state-description">
                  {I18n.t(emptyStateDescription)}
               </EmptyState.Description>
               <EmptyState.Actions className="empty-state-actions">
                  <Button variant="secondary" onClick={openAddAttachmentModal}>
                     {I18n.t(buttonText)}
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const ListAttachmentsCard: React.FC<ListAttachmentsCardProps> = ({
   attachments,
   openAddAttachmentItemModal,
   isAddAttachmentOpen,
   canEditAttachments,
   pageType,
}) => {
   const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
   const { isOpen, openModal, closeModal } = useModal();

   const handleModalClose = () => {
      setSelectedAttachment(null);
      closeModal();
   };

   const handleDownloadLinkClick = (attachment: SerializedAttachment) => {
      setSelectedAttachment(attachment);
      openModal();
   };

   if (isAddAttachmentOpen) {
      return null;
   }

   const { titleKey, buttonTextKey, helpTextKey } = getAttachmentKeys(pageType);

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey={titleKey}
            helpTextKey={helpTextKey}
            buttonTextKey={buttonTextKey}
            openAddItemModal={openAddAttachmentItemModal}
            btnDisabled={false}
            permission={canEditAttachments && !isAddAttachmentOpen}
            icon={null}
         />
         {!isAddAttachmentOpen && (
            <Box padding="md">
               {attachments && attachments.length > 0 && (
                  <AttachmentLink attachments={attachments} onLinkClick={handleDownloadLinkClick} />
               )}
            </Box>
         )}
         <Box padding="xxs" />
         {selectedAttachment && (
            <FileDownloadModal
               open={isOpen}
               onClose={handleModalClose}
               fileName={selectedAttachment.name}
               uploadedOn={getDateString(new Date(selectedAttachment.created_at))}
               attachmentId={selectedAttachment.id}
               mimeType={selectedAttachment.mimetype}
            />
         )}
      </React.Fragment>
   );
};

export const DetailsAttachmentsCard: React.FC<DetailsAttachmentCardProps> = ({
   attachmentData,
   refetchData,
   updateData,
   loading: attachmentDataLoading,
   pageType,
}) => {
   const I18n = useI18nContext();
   const { showToast } = useToastAlertContext();
   const { checkAuthAction } = usePermissionContext();
   const [canEditAttachments, setCanEditAttachments] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const { openModal, closeModal, isOpen } = useModal();

   useEffect(() => {
      const valueAction =
         pageType === PageType.PROJECT
            ? AuthAction.EDIT_PROJECT_ATTACHMENTS
            : AuthAction.EDIT_PEOPLE_ATTACHMENTS;
      const canEditProjectAttachments = checkAuthAction(valueAction);
      setCanEditAttachments(canEditProjectAttachments);
   }, [checkAuthAction]);

   const handleAttachmentCreation = async (payload: AttachmentsData, mode: string) => {
      setLoading(true);
      try {
         const response = await updateData(payload);
         if (response.data && mode === ActionMode.SAVE) {
            refetchData();
         }
         closeModal();
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
      }
   };
   return (
      <React.Fragment>
         {(canEditAttachments ||
            (attachmentData && attachmentData.data?.attachments.length > 0)) && (
            <Card style={{ marginBottom: "5px" }}>
               <Spinner loading={attachmentDataLoading}>
                  {canEditAttachments &&
                     attachmentData &&
                     attachmentData?.data?.attachments.length === 0 && (
                        <EmptyAttachments
                           openAddAttachmentModal={openModal}
                           isAddAttachmentOpen={isOpen}
                           pageType={pageType}
                        />
                     )}
                  {attachmentData && attachmentData?.data?.attachments.length > 0 && (
                     <ListAttachmentsCard
                        attachments={attachmentData?.data?.attachments}
                        openAddAttachmentItemModal={openModal}
                        isAddAttachmentOpen={isOpen}
                        canEditAttachments={canEditAttachments}
                        pageType={pageType}
                     />
                  )}
                  {canEditAttachments && (
                     <AddAttachments
                        availableAttachments={attachmentData?.data?.attachments}
                        open={isOpen}
                        onClose={closeModal}
                        handleCreate={handleAttachmentCreation}
                        showLoader={loading}
                        pageType={pageType}
                     />
                  )}
               </Spinner>
            </Card>
         )}
      </React.Fragment>
   );
};

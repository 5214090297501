import { Store } from "@/stores/common/store.core";
import type { StoreJsonResponse } from "@/stores/common/store.core";
import { authManager } from "@/lib/managers/auth-manager";
import type { Attachment } from "@/react/prop-types";
import type { GetSignedURLResponse } from "@laborchart-modules/lc-core-api/dist/api/attachments/signed-url";

export abstract class AttachmentStore extends Store {
   static createAttachment(data: {
      name: string;
      mimetype: string;
      bytes: number;
   }): StoreJsonResponse<Attachment> {
      return this.requestJson({
         url: `/api/companies/${authManager.companyId()}/attachments`,
         method: "POST",
         body: data,
      });
   }

   static deleteAttachment(attachmentId: string): StoreJsonResponse<boolean> {
      return this.requestJson({
         url: `/api/companies/${authManager.companyId()}/attachments/${attachmentId}`,
         method: "DELETE",
      });
   }

   static uploadFile(fileId: string, formData: FormData): StoreJsonResponse<boolean> {
      return this.requestJson({
         url: `/api/companies/${authManager.companyId()}/upload/${fileId}`,
         method: "POST",
         body: formData,
      });
   }

   static getSignedUrl(url: string): StoreJsonResponse<GetSignedURLResponse> {
      return this.requestJson({
         method: "GET",
         url,
      });
   }
}

import "./confirm-delete-person-pane.styl"
import template from "./confirm-delete-person-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmDeletePersonPaneViewModel extends ModalPane
   constructor: (personName) ->
      assertArgs(arguments, String)
      super("Delete Person", "", template())
      @personName = personName
      @confirmationText = ko.observable()
      @canDelete = ko.pureComputed =>
         return unless @confirmationText()?
         return @confirmationText().toLowerCase() == "yes"

   confirmDelete: ->
      return unless @canDelete()
      modalManager.modalFinished()

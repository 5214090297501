import React, { useCallback, useEffect, useState } from "react";
import type { ListPersonActivitiesProps, PeopleDetailsActivitiesProps } from "../prop-types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import type {
   ActivitiesResponseProps,
   ActivitiesSpecificOptionType,
   ExtendedActivitiesResponseProps,
} from "../../types";
import { dateSortOptions, personSpecificActivityOptions } from "@/react/shared/constants";
import {
   ActivityCategory,
   IntegratedEntity,
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { useFindActivityPaginatedQuery } from "@/react/components/common/queries/queries";
import EmptyActivities from "../../activities/activity-empty";
import { Card, Spinner, useI18nContext } from "@procore/core-react";
import ActivityList from "../../activities/activity-list";
import { ActivityStore } from "@/stores/activity-store.core";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import {
   getActionText,
   getTargetText,
   renderActivityText,
   renderAssignmentsActivityText,
   renderNotesActivityText,
   renderSimpleActivityText,
   renderTimeOffActivityTest,
} from "../../activities/activity-helper";
import { initialLimitForActivity } from "../../constants";
import { PersonActivitiesInfoCard } from "./person-activities-info-card";
import { prepareActivitiesPayload } from "../../helper";
const ListActivities: React.FC<ListPersonActivitiesProps> = ({
   activitiesState,
   setActivitiesState,
   personId,
   entityType,
   includedCategories,
   nextStartingAfter,
   setNextStartingAfter,
   limitActivities,
   personSpecificOptions,
   setLimitActivities,
}) => {
   const I18n = useI18nContext();
   const [sortedActivities, setSortedActivities] = useState(activitiesState);
   const [sortOption, setSortOption] = useState(dateSortOptions[1]);
   const [sortPersonSpecificOption, setSortPersonSpecificOption] =
      useState<ActivitiesSpecificOptionType>(personSpecificOptions[1]); // as assignment has to be default
   useEffect(() => {
      setSortedActivities(activitiesState);
   }, [activitiesState]);

   useEffect(() => {
      loadActivities();
   }, [sortPersonSpecificOption]);

   useEffect(() => {
      const sorted = [...activitiesState].sort((a, b) => {
         const dateA = new Date(a.created_at).getTime();
         const dateB = new Date(b.created_at).getTime();
         return sortOption.value === Order.ASCENDING ? dateA - dateB : dateB - dateA;
      });
      setSortedActivities(sorted);
   }, [sortOption, activitiesState]);

   const fetchActivities = async (isLoadMore = false) => {
      const payload = prepareActivitiesPayload(
         personId,
         entityType,
         sortPersonSpecificOption.id === "0"
            ? includedCategories
            : [sortPersonSpecificOption.value],
         initialLimitForActivity,
         isLoadMore ? nextStartingAfter : undefined,
      );

      const activityData = await ActivityStore.findActivityPaginated(payload, {}).payload;
      if (activityData?.data) {
         // Update state based on whether we are loading more or fetching specific activities
         if (isLoadMore) {
            setActivitiesState((prevState) => [...prevState, ...activityData.data]);
         } else {
            setActivitiesState(activityData.data);
            setLimitActivities(activityData.pagination?.total_possible);
         }
         setNextStartingAfter(activityData.pagination?.next_starting_after ?? "");
      }
   };

   const loadActivities = useCallback(
      (isLoadMore = false) => fetchActivities(isLoadMore),
      [
         personId,
         entityType,
         sortPersonSpecificOption,
         includedCategories,
         nextStartingAfter,
         setActivitiesState,
         setNextStartingAfter,
         setLimitActivities,
      ],
   );

   const renderActivityContent = (activity: ActivitiesResponseProps) => {
      const actionTypeText = getActionText(activity?.action_type, activity?.category, I18n);
      const targetText = getTargetText(activity?.action_type, activity?.category, I18n);
      // switching based on the category related to a person activity
      switch (activity.category) {
         case ActivityCategory.PERSON:
         case ActivityCategory.PERSON_USER_LOCK:
            return renderSimpleActivityText(activity, actionTypeText);
         case ActivityCategory.PERSON_NOTES:
            return renderNotesActivityText(activity, actionTypeText);
         case ActivityCategory.PERSON_ASSIGNMENTS:
            return renderAssignmentsActivityText(
               activity as ExtendedActivitiesResponseProps,
               actionTypeText,
               I18n,
            );
         case ActivityCategory.PERSON_TAGS_ATTACHMENTS:
         case ActivityCategory.PERSON_ATTACHMENTS:
         case ActivityCategory.PERSON_TAGS:
            return renderActivityText(activity, actionTypeText, targetText, I18n);
         case ActivityCategory.PERSON_TIME_OFF:
            return renderTimeOffActivityTest(activity, actionTypeText, I18n);
         case ActivityCategory.PERSON_INFO:
            return <PersonActivitiesInfoCard activity={activity} />;
      }
   };

   return (
      <ActivityList
         title="views.company.workforce_planning.people_details_activities.title"
         subtext="views.company.workforce_planning.people_details_activities.activities_sub_text"
         sortActivitiesOptions={personSpecificOptions}
         sortActivitiesSpecificOption={sortPersonSpecificOption}
         onActivitiesSortChange={setSortPersonSpecificOption}
         sortOptions={dateSortOptions}
         sortOption={sortOption}
         onSortChange={setSortOption}
         activities={sortedActivities}
         renderActivityContent={renderActivityContent}
         loadMoreHandler={() => {
            loadActivities(true);
         }}
         limit={limitActivities}
         loadMoreLabel="views.company.workforce_planning.people_details_activities.load_more"
      />
   );
};

export const PersonDetailsActivities: React.FC<PeopleDetailsActivitiesProps> = ({ personId }) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const [activitiesState, setActivitiesState] = useState<ActivitiesResponseProps[]>([]);
   const [nextStartingAfter, setNextStartingAfter] = useState<string>("");
   const [limitActivities, setLimitActivities] = useState<number>(initialLimitForActivity);
   const [personSpecificOption, setPersonSpecificOption] = useState<ActivitiesSpecificOptionType[]>(
      personSpecificActivityOptions,
   );
   const includedCategories: string[] = [];
   const entityType = IntegratedEntity.PEOPLE;

   useEffect(() => {
      const actionMapping = [
         {
            id: "2",
            action: AuthAction.VIEW_PEOPLE_ATTACHMENTS,
            value: ActivityCategory.PERSON_ATTACHMENTS,
         },
         {
            id: "4",
            action: AuthAction.VIEW_PEOPLE_NOTES,
            value: ActivityCategory.PERSON_NOTES,
         },
         {
            id: "5",
            action: AuthAction.VIEW_PEOPLE_TAGS,
            value: ActivityCategory.PERSON_TAGS,
         },
         {
            id: "6",
            action: AuthAction.VIEW_PEOPLE_TIMEOFF,
            value: ActivityCategory.PERSON_TIME_OFF,
         },
      ];
      // here we are checking against the permissions, if allowed then only we will list them in dropdown
      actionMapping.forEach(({ id, action, value }) => {
         if (checkAuthAction(action)) {
            includedCategories.push(value);
         } else {
            setPersonSpecificOption((prevItems) =>
               prevItems.map((item) => (item.id === id ? { ...item, show: false } : item)),
            );
         }
      });
      // these are the other categories which are always allowed
      // we need to search through all the categories whether an activity exists or not
      // and since we need to toggle some categories based on permission this logic is required
      includedCategories.push(
         ActivityCategory.PERSON_ASSIGNMENTS,
         ActivityCategory.PERSON_INFO,
         ActivityCategory.PERSON, // is not there in the dropdown (as per design) but can come when ALL selected
         ActivityCategory.PERSON_USER_LOCK, // is not there in the dropdown (as per design) but can come when ALL selected
         ActivityCategory.PERSON_TAGS_ATTACHMENTS, // is not there in the dropdown (as per design) but can come when ALL selected
      );
   }, [checkAuthAction]);

   // this call is added to find out where the activities exists or not to render the empty state or the list state
   const { data: activitiesData, isLoading: activitiesLoading } = useFindActivityPaginatedQuery(
      personId,
      entityType,
      includedCategories,
      initialLimitForActivity,
   );

   useEffect(() => {
      if (activitiesData?.data && activitiesData?.data.length > 0) {
         setNextStartingAfter(activitiesData?.pagination?.next_starting_after ?? "");
         setActivitiesState(activitiesData?.data);
         setLimitActivities(activitiesData?.pagination?.total_possible);
      }
   }, [activitiesData]);

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={activitiesLoading}>
            {activitiesData?.data.length === 0 && (
               <EmptyActivities
                  title={I18n.t("views.company.workforce_planning.people_details_activities.title")}
                  emptyTitle={I18n.t(
                     "views.company.workforce_planning.people_details_activities.activities_empty_state_title",
                  )}
                  emptyDescription={I18n.t(
                     "views.company.workforce_planning.people_details_activities.activities_empty_state_description",
                  )}
               />
            )}
            {activitiesData?.data && activitiesData.data.length > 0 && (
               <ListActivities
                  activitiesState={activitiesState}
                  setActivitiesState={setActivitiesState}
                  personId={personId}
                  entityType={entityType}
                  includedCategories={includedCategories}
                  nextStartingAfter={nextStartingAfter}
                  setNextStartingAfter={setNextStartingAfter}
                  limitActivities={limitActivities}
                  personSpecificOptions={personSpecificOption}
                  setLimitActivities={setLimitActivities}
               />
            )}
         </Spinner>
      </Card>
   );
};

import { Model } from "@/models/model"
import { Message } from "@/models/message"
import { Person } from "@/models/person"

export class Conversation extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.created_at, Number)
         assertOfType(data.is_private, Boolean)
         assertOfType(data.group_id, String)
         assertOfType(data.context, String)
         assertOfType(data.last_message, Object)
         assertOfType(data.last_updated, Number)
         assertOfType(data.message_count, optional(Number))
         assertOfType(data.participants_ids, optional(arrayOf(String)))
         assertOfType(data.participants, optional(arrayOf(Object)))
         assertOfType(data.starred, Boolean)
         assertOfType(data.viewed, Boolean)
         assertOfType(data.original_recipient_ids, arrayOf(String))
         assertOfType(data.original_subject, nullable(String))


      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @createdAt = ko.observable(data.created_at)
      @isPrivate = ko.observable(data.is_private)
      @groupId = ko.observable(data.group_id)
      @context = ko.observable(data.context)
      @lastMessage = ko.observable(new Message(data.last_message, disableTypes))
      @lastUpdated = ko.observable(data.last_updated)
      @messageCount = ko.observable(data.message_count)
      @starred = ko.observable(data.starred)
      @viewed = ko.observable(data.viewed)
      @participantIds = ko.observableArray(data.participant_ids)
      @participantNames = ko.observableArray(data.participant_names)
      @participants = ko.observableArray if !data.participants? then [] else data.participants.map (user) -> return new User(user, disableTypes)
      @originalRecipientIds = ko.observable(data.original_recipient_ids)
      @originalSubject = ko.observable(data.original_subject)


import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";

export abstract class IntegrationsStore extends Store {
   static getActiveIntegrations(): StoreJsonResponse<any> {
      return this.requestJson({
         url: "/api/v3/integrations",
         method: "GET",
      });
   }

   static updateIntegration(id: string, payload: any): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/integrations/${id}`,
         method: "PATCH",
         body: payload,
      });
   }

   static getProjectIntegrationStatus(id: string): StoreJsonResponse<any> {
      return this.requestJson({
         url: `/api/v3/integrations/status/project/${id}`,
         method: "GET",
      });
   }
}

export const timeOffReasons = [
   {
      id: 1,
      label: "Bereavement",
      value: "bereavement",
   },
   {
      id: 2,
      label: "Family",
      value: "family",
   },
   {
      id: 3,
      label: "Jury Duty",
      value: "jury duty",
   },
   {
      id: 4,
      label: "Lack of Work",
      value: "lack of work",
   },
   {
      id: 5,
      label: "Medical",
      value: "medical",
   },
   {
      id: 6,
      label: "Military",
      value: "military",
   },
   {
      id: 7,
      label: "Personal",
      value: "personal",
   },
   {
      id: 8,
      label: "School",
      value: "school",
   },
   {
      id: 9,
      label: "Sick",
      value: "sick",
   },
   {
      id: 10,
      label: "Vacation",
      value: "vacation",
   },
   { id: 11, label: "Other", value: "other" },
];

export const timeOptions = [
   { id: 1, label: "12:00 AM", value: 0 },
   { id: 2, label: "12:15 AM", value: 0.25 },
   { id: 3, label: "12:30 AM", value: 0.5 },
   { id: 4, label: "12:45 AM", value: 0.75 },
   { id: 5, label: "1:00 AM", value: 1 },
   { id: 6, label: "1:15 AM", value: 1.25 },
   { id: 7, label: "1:30 AM", value: 1.5 },
   { id: 8, label: "1:45 AM", value: 1.75 },
   { id: 9, label: "2:00 AM", value: 2 },
   { id: 10, label: "2:15 AM", value: 2.25 },
   { id: 11, label: "2:30 AM", value: 2.5 },
   { id: 12, label: "2:45 AM", value: 2.75 },
   { id: 13, label: "3:00 AM", value: 3 },
   { id: 14, label: "3:15 AM", value: 3.25 },
   { id: 15, label: "3:30 AM", value: 3.5 },
   { id: 16, label: "3:45 AM", value: 3.75 },
   { id: 17, label: "4:00 AM", value: 4 },
   { id: 18, label: "4:15 AM", value: 4.25 },
   { id: 19, label: "4:30 AM", value: 4.5 },
   { id: 20, label: "4:45 AM", value: 4.75 },
   { id: 21, label: "5:00 AM", value: 5 },
   { id: 22, label: "5:15 AM", value: 5.25 },
   { id: 23, label: "5:30 AM", value: 5.5 },
   { id: 24, label: "5:45 AM", value: 5.75 },
   { id: 25, label: "6:00 AM", value: 6 },
   { id: 27, label: "6:15 AM", value: 6.25 },
   { id: 28, label: "6:30 AM", value: 6.5 },
   { id: 29, label: "6:45 AM", value: 6.75 },
   { id: 30, label: "7:00 AM", value: 7 },
   { id: 31, label: "7:15 AM", value: 7.25 },
   { id: 32, label: "7:30 AM", value: 7.5 },
   { id: 33, label: "7:45 AM", value: 7.75 },
   { id: 34, label: "8:00 AM", value: 8 },
   { id: 35, label: "8:15 AM", value: 8.25 },
   { id: 36, label: "8:30 AM", value: 8.5 },
   { id: 37, label: "8:45 AM", value: 8.75 },
   { id: 38, label: "9:00 AM", value: 9 },
   { id: 39, label: "9:15 AM", value: 9.25 },
   { id: 40, label: "9:30 AM", value: 9.5 },
   { id: 41, label: "9:45 AM", value: 9.75 },
   { id: 42, label: "10:00 AM", value: 10 },
   { id: 43, label: "10:15 AM", value: 10.25 },
   { id: 44, label: "10:30 AM", value: 10.5 },
   { id: 45, label: "10:45 AM", value: 10.75 },
   { id: 46, label: "11:00 AM", value: 11 },
   { id: 47, label: "11:15 AM", value: 11.25 },
   { id: 48, label: "11:30 AM", value: 11.5 },
   { id: 49, label: "11:45 AM", value: 11.75 },
   { id: 50, label: "12:00 PM", value: 12 },
   { id: 51, label: "12:15 PM", value: 12.25 },
   { id: 52, label: "12:30 PM", value: 12.5 },
   { id: 53, label: "12:45 PM", value: 12.75 },
   { id: 54, label: "1:00 PM", value: 13 },
   { id: 55, label: "1:15 PM", value: 13.25 },
   { id: 56, label: "1:30 PM", value: 13.5 },
   { id: 57, label: "1:45 PM", value: 13.75 },
   { id: 58, label: "2:00 PM", value: 14 },
   { id: 59, label: "2:15 PM", value: 14.25 },
   { id: 60, label: "2:30 PM", value: 14.5 },
   { id: 61, label: "2:45 PM", value: 14.75 },
   { id: 62, label: "3:00 PM", value: 15 },
   { id: 63, label: "3:15 PM", value: 15.25 },
   { id: 64, label: "3:30 PM", value: 15.5 },
   { id: 65, label: "3:45 PM", value: 15.75 },
   { id: 66, label: "4:00 PM", value: 16 },
   { id: 67, label: "4:15 PM", value: 16.25 },
   { id: 68, label: "4:30 PM", value: 16.5 },
   { id: 69, label: "4:45 PM", value: 16.75 },
   { id: 70, label: "5:00 PM", value: 17 },
   { id: 71, label: "5:15 PM", value: 17.25 },
   { id: 72, label: "5:30 PM", value: 17.5 },
   { id: 73, label: "5:45 PM", value: 17.75 },
   { id: 74, label: "6:00 PM", value: 18 },
   { id: 75, label: "6:15 PM", value: 18.25 },
   { id: 76, label: "6:30 PM", value: 18.5 },
   { id: 77, label: "6:45 PM", value: 18.75 },
   { id: 78, label: "7:00 PM", value: 19 },
   { id: 79, label: "7:15 PM", value: 19.25 },
   { id: 80, label: "7:30 PM", value: 19.5 },
   { id: 81, label: "7:45 PM", value: 19.75 },
   { id: 82, label: "8:00 PM", value: 20 },
   { id: 83, label: "8:15 PM", value: 20.25 },
   { id: 84, label: "8:30 PM", value: 20.5 },
   { id: 85, label: "8:45 PM", value: 20.75 },
   { id: 86, label: "9:00 PM", value: 21 },
   { id: 87, label: "9:15 PM", value: 21.25 },
   { id: 88, label: "9:30 PM", value: 21.5 },
   { id: 89, label: "9:45 PM", value: 21.75 },
   { id: 90, label: "10:00 PM", value: 22 },
   { id: 91, label: "10:15 PM", value: 22.25 },
   { id: 92, label: "10:30 PM", value: 22.5 },
   { id: 93, label: "10:45 PM", value: 22.75 },
   { id: 94, label: "11:00 PM", value: 23 },
   { id: 95, label: "11:15 PM", value: 23.25 },
   { id: 96, label: "11:30 PM", value: 23.5 },
   { id: 97, label: "11:45 PM", value: 23.75 },
];

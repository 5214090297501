import "./color-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./color-cell.pug";
import type { MaybeComputed } from "knockout";
import ko from "knockout";

export type ColorCellParams = {
   color: MaybeComputed<string | null>;
   hideWhenNull?: boolean;
};

export class ColorCell {
   readonly color: ColorCellParams["color"];
   readonly hideWhenNull: ColorCellParams["hideWhenNull"];
   constructor(params: ColorCellParams) {
      this.color = params.color;
      this.hideWhenNull = params.hideWhenNull ?? false;
   }

   static factory<T>(formatter: (data: T) => string | null): GridCellFactory<T, ColorCellParams> {
      return (data: T): GridCell<ColorCellParams> => {
         return {
            component: {
               name: "color-cell",
               params: {
                  color: formatter(data),
               },
            },
            height: null,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => string | null): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: ColorCell.factory(provider),
         copyProvider: (data: T) => provider(data) || "",
      };
   }
}

ko.components.register("color-cell", {
   viewModel: ColorCell,
   template: template(),
   synchronous: true,
});

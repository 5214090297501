import "./percent-editor.styl";
import type { MaybeSubscribable, Observable, Subscription } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./percent-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export type PercentValidator = (val: number | null) => { status: boolean; message: string | null };

export interface PercentEditorParams extends EditorComponentParams<number | null> {
   isClearable?: boolean;
   isRequired?: MaybeSubscribable<boolean>;
   validators?: PercentValidator[];
}

export class PercentEditor extends AbstractFieldEditor<number | null> {
   readonly inputValue: Observable<string | null>;

   private readonly subscription: Subscription;

   constructor(private readonly params: PercentEditorParams) {
      super(params, observable(unwrap(params.value)));
      this.inputValue = observable(this.value() != null ? String(this.value()) : null);
      this.subscription = this.inputValue.subscribe((value) => {
         const valueAsNumber = Number(value);
         this.value(value == "" || value == null || isNaN(valueAsNumber) ? null : valueAsNumber);
      });
   }

   validate(value: number | null): ValidatedValue<number | null> {
      const { isRequired, validators } = this.params;
      const valueAsNumber = Number(this.inputValue());
      const isEmpty = this.inputValue() == null || this.inputValue() == "";

      // Only show the first wrong constraint so as not to overwhelm UI
      const getFirstConstraint = () =>
         (validators || []).map((v) => v(this.value())).filter((v) => !v.status)[0];

      // Optional Block of error handling
      if (isEmpty && unwrap(isRequired))
         return {
            valid: false,
            error: `${this.title} is required.`,
         };

      const firstConstraint = getFirstConstraint();
      if (firstConstraint) {
         return {
            valid: false,
            error: firstConstraint.message,
         };
      }
      if (isNaN(valueAsNumber)) {
         return {
            valid: false,
            error: `${this.title} must be a number.`,
         };
      }
      return { valid: true, value };
   }

   dispose = (): void => {
      this.subscription.dispose();
   };

   onDelete = (): Promise<void> => {
      return this.save(null);
   };

   static factory<T>(
      provider: (records: T[]) => PercentEditorParams,
   ): (records: T[]) => ComponentArgs<PercentEditorParams> {
      return (records) => ({
         name: "percent-editor",
         params: provider(records),
      });
   }
}

ko.components.register("percent-editor", {
   viewModel: PercentEditor,
   template: template(),
   synchronous: true,
});

import type { StoreStreamResponse } from "@/stores/common";
import { GroupStore } from "@/stores/group-store.core";
import type { SerializedGroup } from "@laborchart-modules/common/dist/rethink/serializers/group-serializer";
import type { FindGroupsPaginatedQueryParams } from "@laborchart-modules/lc-core-api/dist/api/groups/find-groups";
import { observable } from "knockout";
import type { KeysetGridStoreParams, ResponsePayload } from "../../grid/keyset-grid-store";
import { KeysetGridStore } from "../../grid/keyset-grid-store";
import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";

export type SerializedGroupTransformer<T extends ItemBase> = (group: SerializedGroup) => T;

/** `DropDownStore` backed by group options. */
export class GroupDropDownPane<T extends ItemBase = SerializedGroup> implements DropDownPane<T> {
   // TODO: Check on this one
   readonly gridStore = observable<any>(null);
   readonly isSearchable = observable(true);

   private readonly transformer: (group: SerializedGroup) => T;

   constructor({ transformer }: { transformer: (group: SerializedGroup) => T }) {
      this.transformer = transformer;
   }

   loadItems(params: LoadItemsParams): void {
      this.gridStore(this.createGridStore(params));
   }

   private createGridStore(params: LoadItemsParams) {
      return new GroupGridStore({
         queryParams: {
            limit: 20,
            name: params.search ?? null,
         },
         startingCursor: params.startingAt ? { startingAt: params.startingAt } : null,
         transformer: this.transformer,
      });
   }

   static create(
      params: {
         name?: string | null;
      } = {},
   ): GroupDropDownPane<SerializedGroup> {
      return new GroupDropDownPane({
         ...params,
         transformer: (group) => group,
      });
   }
}

interface GroupGridStoreParams<T extends ItemBase>
   extends KeysetGridStoreParams<FindGroupsPaginatedQueryParams> {
   transformer: SerializedGroupTransformer<T>;
}

class GroupGridStore<T extends ItemBase = SerializedGroup> extends KeysetGridStore<
   T,
   FindGroupsPaginatedQueryParams
> {
   private readonly transformer: SerializedGroupTransformer<T>;

   constructor(params: GroupGridStoreParams<T>) {
      super(params);
      this.transformer = params.transformer;
   }

   async loadRows(queryParams: FindGroupsPaginatedQueryParams): Promise<ResponsePayload<T>> {
      const { starting_at, ...otherQueryParams } = queryParams;
      const payload = await GroupStore.findGroupsPaginated({
         ...otherQueryParams,
         ...(starting_at == "my-groups" ? {} : { starting_at }),
      }).payload;

      // Add My Groups always as the first option.
      if (
         (queryParams.starting_after === undefined && queryParams.starting_at === "my-groups") ||
         payload.pagination.previous_starting_before === null
      ) {
         payload.data.unshift({
            name: "My Groups",
            id: "my-groups",
         } as any);
      }

      return {
         data: payload.data.map(this.transformer),
         pagination: payload.pagination,
      };
   }

   createLoadAllRowsStream(): StoreStreamResponse<T> {
      throw new Error("Not implemented");
   }
}

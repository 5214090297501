import "./assignment-details-modal.styl";
import template from "./assignment-details-modal.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { Observable, PureComputed } from "knockout";
import ko, { toJS, pureComputed, observable, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common/component-args";
import type { ModalAction, ModalFooterParams } from "@/lib/components/modals/common/modal-footer";
import { ButtonDropDown, ButtonItem } from "@/lib/components/button-drop-down/button-drop-down";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { ButtonColor, ButtonSize } from "@/lib/utils/buttons";
import type { OptionalSize, CanRequestSize } from "@/lib/components/modals/common/sized-modal";
import { AssignmentEditPane } from "./panes/assignment-edit-pane/assignment-edit-pane";
import type { UpdateAssignmentPayload } from "@laborchart-modules/lc-core-api";
import {
   Icons,
   notificationManagerInstance,
   Notification,
} from "@/lib/managers/notification-manager";
import { ProgressNotification } from "@/notifications/progress-notification";
import { authManager } from "@/lib/managers/auth-manager";
import type { AssignmentWorkDays } from "@/models/assignment";
import type { NestedComputedAssignmentSupportData } from "@/stores/assignment-2-store.core";
import type { ModalTab, TabbedPaneParams } from "@/lib/components/modals/common/tabbed-pane";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments/find-assignments";
import type { NullableCustomFieldValue } from "../editors/custom-field-editor-element/custom-field-editor-element";
import type {
   SerializedPerson,
   SerializedProject,
} from "@laborchart-modules/common/dist/rethink/serializers";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { ModalHeaderParams } from "@/lib/components/modals/common/modal-header";
import { WorkDay } from "../editors/work-day-editor-element/work-day-editor-element";
import type {
   CreateAssignmentPayload,
   CreateAssignmentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import { EventHandler } from "@/lib/utils/event-handler";
import type { MakeKeysNullable } from "@/lib/type-utils";

import { PermissionLevel } from "@/models/permission-level";
import type { AssignmentPaySplit } from "@laborchart-modules/common";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

export enum AssignmentDetailsModalType {
   CREATE_ASSIGNMENT = "create-assignment",
   EDIT_ASSIGNMENT = "edit-assignment",
   FILL_REQUEST = "fill-request",
}

export const enum AssignmentDetailsModalPane {
   EDIT_ASSIGNMENT = 0,
}

export type AssignmentDetailsModalUpdater = ({
   assignment,
   update,
   showAlertModal,
}: {
   assignment: SerializedFindAssignment;
   update: Partial<AssignmentDetailsModalStagedUpdate>;
   showAlertModal: boolean;
}) => Promise<void>;

export type AssignmentDetailsModalParams = {
   createAssignment: (
      assignment: CreateAssignmentPayload,
      showAlertModal: boolean,
   ) => Promise<CreateAssignmentResponse | void>;
   customFields: PureComputed<SerializedCustomField[]>;
   deleteAssignment: ({
      assignment,
      showAlertModal,
   }: {
      assignment: SerializedFindAssignment;
      showAlertModal: boolean;
   }) => Promise<void>;
   assignmentDetailsModalType: AssignmentDetailsModalType;
   supportData: NestedComputedAssignmentSupportData;
   updateAssignment: AssignmentDetailsModalUpdater;

   assignment?: PureComputed<SerializedFindAssignment> | null;
   initialPane?: AssignmentDetailsModalPane;
   newAssignmentSeedData?: Partial<Pick<StagedAssignment, "project">>;
} & CanRequestSize;

export type UpdatableAssignmentFields = Pick<
   UpdateAssignmentPayload,
   | "category_id"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "overtime_rates"
   | "overtime"
   | "pay_split"
   | "percent_allocated"
   | "project_id"
   | "resource_id"
   | "start_day"
   | "start_time"
   | "status_id"
   | "subcategory_id"
   | "work_days"
>;
export type UpdateAssignmentDetails = Pick<
   UpdatableAssignmentFields,
   | "category_id"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "overtime_rates"
   | "overtime"
   | "pay_split"
   | "percent_allocated"
   | "project_id"
   | "resource_id"
   | "start_day"
   | "start_time"
   | "subcategory_id"
   | "work_days"
>;

export type AssignmentDetailsModalStagedUpdate = Pick<
   UpdateAssignmentPayload,
   | "category_id"
   | "custom_fields"
   | "end_day"
   | "end_time"
   | "overtime_rates"
   | "overtime"
   | "pay_split"
   | "percent_allocated"
   | "project_id"
   | "resource_id"
   | "start_day"
   | "start_time"
   | "status_id"
   | "subcategory_id"
   | "work_days"
> & {
   category?: SerializedFindAssignment["category"];
   person?: SerializedFindAssignment["person"] | SerializedPerson;
   project?: SerializedFindAssignment["project"] | SerializedProject;
   status?: SerializedFindAssignment["status"];
   subcategory?: SerializedFindAssignment["subcategory"];
};

type AssignmentUpdateVisitor = {
   visit: (update: Partial<StagedAssignment>) => boolean;
   accept: (update: Partial<StagedAssignment>) => void;
};

export type StagedAssignment = MakeKeysNullable<
   Omit<SerializedFindAssignment, "person" | "project">,
   "end_day" | "project_id" | "start_day" | "resource_id"
> & {
   person: SerializedPerson | null;
   project: SerializedProject | null;
};

class AssignmentDetailsModalState {
   readonly assignment: PureComputed<StagedAssignment | SerializedFindAssignment>;

   private readonly assignmentCreator: AssignmentDetailsModalParams["createAssignment"];
   private readonly assignmentDeleter: AssignmentDetailsModalParams["deleteAssignment"];
   private readonly assignmentUpdater: AssignmentDetailsModalParams["updateAssignment"];
   private readonly customFields: AssignmentDetailsModalParams["customFields"];
   readonly supportData: AssignmentDetailsModalParams["supportData"];

   private readonly internalState: {
      isDeleting: Observable<boolean>;
      assignmentDetailsModalType: Observable<AssignmentDetailsModalType>;
      isSaving: Observable<boolean>;
      preventSaveFromEditPane: Observable<boolean>;
      assignmentedSizeOfContent: Observable<OptionalSize | null>;
      assignmentedSizeOfFooter: Observable<OptionalSize | null>;
      assignmentedSizeOfHeader: Observable<OptionalSize | null>;
      stagedAssignment: Observable<StagedAssignment>;
   };

   // Nouns.
   readonly preventSaveFromEditPane = pureComputed(() =>
      this.internalState.preventSaveFromEditPane(),
   );
   readonly assignmentedSizeCombined = pureComputed<OptionalSize>(() => {
      const width =
         (this.internalState.assignmentedSizeOfHeader()?.width ?? 0) +
         (this.internalState.assignmentedSizeOfContent()?.width ?? 0) +
         (this.internalState.assignmentedSizeOfFooter()?.width ?? 0);
      const height =
         (this.internalState.assignmentedSizeOfHeader()?.height ?? 0) +
         (this.internalState.assignmentedSizeOfContent()?.height ?? 0) +
         (this.internalState.assignmentedSizeOfFooter()?.height ?? 0);
      return {
         width: width === 0 ? null : width,
         height: height === 0 ? null : height,
      };
   });

   readonly isSaving = pureComputed(() => this.internalState.isSaving());
   readonly assignmentDetailsModalType = pureComputed(() =>
      this.internalState.assignmentDetailsModalType(),
   );
   readonly isDeleting = pureComputed(() => this.internalState.isDeleting());
   readonly stagedAssignment = pureComputed(() => this.internalState.stagedAssignment());

   readonly stagedUpdatesFromEditPane = pureComputed((): AssignmentDetailsModalStagedUpdate => {
      const standardFieldsUpdate = this.getStandardFieldUpdates();
      const customFieldsUpdate = this.getCustomFieldUpdates();
      return customFieldsUpdate != null
         ? { ...standardFieldsUpdate, ...customFieldsUpdate }
         : standardFieldsUpdate;
   });

   readonly getStandardFieldUpdates = (): AssignmentDetailsModalStagedUpdate => {
      const assignment = this.assignment();
      const stagedAssignment = this.stagedAssignment();
      if (stagedAssignment == null) return {};

      const visitors: Array<{
         visit: boolean;
         accept: AssignmentDetailsModalStagedUpdate;
      }> = [
         {
            visit:
               "project" in stagedAssignment &&
               stagedAssignment.project != null &&
               (stagedAssignment.project?.id ?? null) != (assignment.project?.id ?? null),
            accept: {
               project: stagedAssignment.project!,
               project_id: stagedAssignment.project?.id,
            },
         },
         {
            visit:
               "person" in stagedAssignment &&
               stagedAssignment.person != null &&
               (stagedAssignment.person?.id ?? null) != (assignment.person?.id ?? null),
            accept: {
               person: stagedAssignment.person!,
               resource_id: stagedAssignment.person?.id,
            },
         },
         {
            visit:
               "status" in stagedAssignment &&
               (stagedAssignment.status?.id ?? null) != (assignment.status?.id ?? null),
            accept: {
               status: stagedAssignment.status,
               status_id: stagedAssignment.status?.id ?? null,
            },
         },
         {
            visit:
               "category" in stagedAssignment &&
               (stagedAssignment.category?.id ?? null) != (assignment.category?.id ?? null),
            accept: {
               category: stagedAssignment.category,
               category_id: stagedAssignment.category?.id ?? null,
            },
         },
         {
            visit:
               "subcategory" in stagedAssignment &&
               (stagedAssignment.subcategory?.id ?? null) != (assignment.subcategory?.id ?? null),
            accept: {
               subcategory: stagedAssignment.subcategory,
               subcategory_id: stagedAssignment.subcategory?.id ?? null,
            },
         },
         {
            visit:
               "start_day" in stagedAssignment &&
               stagedAssignment.start_day != null &&
               stagedAssignment.start_day != assignment.start_day,
            accept: { start_day: stagedAssignment.start_day! },
         },
         {
            visit:
               "end_day" in stagedAssignment &&
               stagedAssignment.end_day != null &&
               stagedAssignment.end_day != assignment.end_day,
            accept: { end_day: stagedAssignment.end_day! },
         },
         {
            visit:
               "start_time" in stagedAssignment &&
               stagedAssignment.start_time != assignment.start_time,
            accept: { start_time: stagedAssignment.start_time! },
         },
         {
            visit:
               "end_time" in stagedAssignment && stagedAssignment.end_time != assignment.end_time,
            accept: { end_time: stagedAssignment.end_time! },
         },
         {
            visit:
               "percent_allocated" in stagedAssignment &&
               stagedAssignment.percent_allocated != assignment.percent_allocated,
            accept: { percent_allocated: stagedAssignment.percent_allocated! },
         },
         {
            visit:
               "work_days" in stagedAssignment &&
               stagedAssignment.work_days != null &&
               new Array<keyof AssignmentWorkDays>(0, 1, 2, 3, 4, 5, 6).some(
                  (dayIndex) =>
                     stagedAssignment.work_days[dayIndex] != assignment.work_days[dayIndex],
               ),
            accept: { work_days: stagedAssignment.work_days },
         },
         {
            visit:
               "overtime" in stagedAssignment && stagedAssignment.overtime != assignment.overtime,
            accept: { overtime: stagedAssignment.overtime },
         },
         {
            visit:
               "overtime_rates" in stagedAssignment &&
               new Array<keyof AssignmentWorkDays>(0, 1, 2, 3, 4, 5, 6).some((dayIndex) => {
                  const newRate = stagedAssignment.overtime_rates
                     ? stagedAssignment.overtime_rates[dayIndex]
                     : 0;
                  const oldRate = assignment.overtime_rates
                     ? assignment.overtime_rates[dayIndex]
                     : 0;
                  return newRate != oldRate;
               }),
            accept: { overtime_rates: stagedAssignment.overtime_rates },
         },
         {
            visit:
               "pay_split" in stagedAssignment &&
               new Array<keyof AssignmentPaySplit>("overtime", "straight", "unpaid").some(
                  (split) => {
                     const newSplit = stagedAssignment.pay_split
                        ? stagedAssignment.pay_split[split]
                        : 0;
                     const oldSplit = assignment.pay_split ? assignment.pay_split[split] : 0;
                     return newSplit != oldSplit;
                  },
               ),
            accept: { pay_split: stagedAssignment.pay_split },
         },
      ];

      return visitors
         .filter((visitor) => visitor.visit)
         .map<AssignmentDetailsModalStagedUpdate>((acceptor) => acceptor.accept)
         .reduce((left, right) => ({ ...left, ...right }), {});
   };

   // TODO: Refactor some of this out to a shared location.
   readonly getCustomFieldUpdates = () => {
      const assignment = this.assignment();
      const stagedAssignment = this.stagedAssignment();
      if (authManager.companyModules()?.customFields != true) {
         return null;
      }
      const newValueUpdates = stagedAssignment.custom_fields
         .filter((field) => {
            const originalField = assignment.custom_fields.find(
               (cf) => cf.field_id == field.field_id,
            );
            if (originalField == null) {
               // Custom field instance did not already exist on assignment record.
               return true;
            } else if (!Array.isArray(originalField.value) || !Array.isArray(field.value)) {
               return originalField.value != field.value;
            } else {
               // Handle comparisons of arrays, specifically multi-selects.
               const originalSet = new Set(originalField.value);
               const newSet = new Set(field.value);
               return (
                  originalSet.size != newSet.size ||
                  [...newSet].some((val) => !originalSet.has(val))
               );
            }
         })
         .map<Record<string, NullableCustomFieldValue>>((field) => ({
            [field.field_id]: field.value,
         }));
      const deletedValueUpdates = assignment.custom_fields
         .filter(
            (field) =>
               stagedAssignment.custom_fields.findIndex((cf) => cf.field_id == field.field_id) ==
               -1,
         )
         .map<Record<string, NullableCustomFieldValue>>((field) => ({
            [field.field_id]: null,
         }));
      const allUpdates = [...newValueUpdates, ...deletedValueUpdates].reduce(
         (acc, cur) => ({ ...acc, ...cur }),
         {},
      );
      return Object.keys(allUpdates).length > 0 ? { custom_fields: allUpdates } : null;
   };

   readonly stagedUpdate = pureComputed((): AssignmentDetailsModalStagedUpdate => {
      return this.stagedUpdatesFromEditPane();
   });

   readonly hasEditPaneChanges = pureComputed(() => {
      return Object.keys(this.stagedUpdatesFromEditPane()).length > 0;
   });

   readonly hasAnyChanges = pureComputed(() => {
      return (
         this.assignmentDetailsModalType() == AssignmentDetailsModalType.CREATE_ASSIGNMENT ||
         this.assignmentDetailsModalType() == AssignmentDetailsModalType.FILL_REQUEST ||
         this.hasEditPaneChanges() === true
      );
   });

   readonly canSave = pureComputed(() => {
      return this.hasAnyChanges() === true && this.preventSaveFromEditPane() === false;
   });

   // Verbs.
   readonly setPreventSaveFromEditPane = (preventSaveFromEditPane: boolean) => {
      this.internalState.preventSaveFromEditPane(preventSaveFromEditPane);
   };
   readonly setIsSaving = (isSaving: boolean) => {
      this.internalState.isSaving(isSaving);
   };
   readonly setIsDeleting = (isDeleting: boolean) => {
      this.internalState.isDeleting(isDeleting);
   };
   readonly addStagedUpdateData = (update: Partial<StagedAssignment>) => {
      this.internalState.stagedAssignment({
         ...this.stagedAssignment(),
         ...update,
      });
   };
   readonly requestSizeForContent = (size: OptionalSize) => {
      this.internalState.assignmentedSizeOfContent(size);
   };
   readonly requestSizeForFooter = (size: OptionalSize) => {
      this.internalState.assignmentedSizeOfFooter(size);
   };
   readonly requestSizeForHeader = (size: OptionalSize) => {
      this.internalState.assignmentedSizeOfHeader(size);
   };

   //Assignment Verbs.
   readonly saveAssignment = async (showAlertModal: boolean): Promise<void> => {
      if (
         this.assignmentDetailsModalType() == AssignmentDetailsModalType.CREATE_ASSIGNMENT ||
         this.assignmentDetailsModalType() == AssignmentDetailsModalType.FILL_REQUEST
      ) {
         const stagedAssignment = this.stagedAssignment();
         const newAssignment: CreateAssignmentPayload = {
            category_id: stagedAssignment.category_id,
            custom_fields: stagedAssignment.custom_fields
               .filter((field) => {
                  const fullField = this.customFields().find((cf) => cf.id == field.field_id);
                  return fullField != null && fullField.integration_only != true;
               })
               .map((field) => ({ [field.field_id]: field.value }))
               .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
            end_day: stagedAssignment.end_day!,
            end_time: stagedAssignment.end_time,
            overtime_rates: stagedAssignment.overtime_rates,
            overtime: stagedAssignment.overtime,
            pay_split: stagedAssignment.pay_split,
            percent_allocated: stagedAssignment.percent_allocated,
            project_id: stagedAssignment.project_id!,
            resource_id: stagedAssignment.resource_id!,
            start_day: stagedAssignment.start_day!,
            start_time: stagedAssignment.start_time,
            status_id: stagedAssignment.status_id,
            subcategory_id: stagedAssignment.subcategory_id,
            work_days: stagedAssignment.work_days,
         };
         await this.assignmentCreator(newAssignment, showAlertModal);
      } else {
         await this.assignmentUpdater({
            assignment: this.assignment() as SerializedFindAssignment,
            update: this.stagedUpdate(),
            showAlertModal,
         });
      }
      this.internalState.stagedAssignment(JSON.parse(JSON.stringify(toJS(this.assignment))));
   };
   readonly deleteAssignment = (showAlertModal: boolean) => {
      if (this.assignmentDetailsModalType() != AssignmentDetailsModalType.EDIT_ASSIGNMENT) return;
      return this.assignmentDeleter({
         assignment: this.assignment() as SerializedFindAssignment,
         showAlertModal,
      });
   };

   constructor({
      assignment,
      assignmentCreator,
      assignmentDeleter,
      assignmentUpdater,
      assignmentDetailsModalType,
      customFields,
      newAssignmentSeedData,
      supportData,
   }: {
      assignment?: PureComputed<SerializedFindAssignment> | null;
      assignmentCreator: (
         assignment: CreateAssignmentPayload,
         showAlertModal: boolean,
      ) => Promise<CreateAssignmentResponse | void>;
      assignmentDeleter: ({
         assignment,
         showAlertModal,
      }: {
         assignment: SerializedFindAssignment;
         showAlertModal: boolean;
      }) => Promise<void>;
      assignmentUpdater: AssignmentDetailsModalUpdater;
      assignmentDetailsModalType: AssignmentDetailsModalType;
      customFields: PureComputed<SerializedCustomField[]>;
      newAssignmentSeedData?: Partial<Pick<StagedAssignment, "project">>;
      supportData: NestedComputedAssignmentSupportData;
   }) {
      this.assignment = (assignment ??
         pureComputed<StagedAssignment>(
            (): StagedAssignment => ({
               category_id: null,
               category: null,
               company_id: authManager.companyId(),
               created_at: Date.now(),
               creator_id: null,
               custom_fields: [],
               end_day: null,
               end_time: newAssignmentSeedData?.project?.daily_end_time ?? null,
               id: null as any,
               overtime_rates: null,
               overtime: false,
               pay_split: null,
               percent_allocated: null,
               person: null,
               project_id: newAssignmentSeedData?.project?.id ?? null,
               project: newAssignmentSeedData?.project ?? null,
               resource_id: null,
               start_day: null,
               start_time: newAssignmentSeedData?.project?.daily_start_time ?? null,
               status_id: null,
               status: null,
               subcategory_id: null,
               subcategory: null,
               work_days: {
                  [WorkDay.SUNDAY]: false,
                  [WorkDay.MONDAY]: true,
                  [WorkDay.TUESDAY]: true,
                  [WorkDay.WEDNESDAY]: true,
                  [WorkDay.THURSDAY]: true,
                  [WorkDay.FRIDAY]: true,
                  [WorkDay.SATURDAY]: false,
               },
            }),
         )) as PureComputed<SerializedFindAssignment | StagedAssignment>;
      this.internalState = {
         isDeleting: observable(false),
         assignmentDetailsModalType: observable(assignmentDetailsModalType),
         isSaving: observable(false),
         preventSaveFromEditPane: observable(false),
         assignmentedSizeOfContent: observable(null),
         assignmentedSizeOfFooter: observable(null),
         assignmentedSizeOfHeader: observable(null),
         stagedAssignment: observable(JSON.parse(JSON.stringify(toJS(this.assignment)))),
      };
      this.assignmentCreator = assignmentCreator;
      this.assignmentDeleter = assignmentDeleter;
      this.assignmentUpdater = assignmentUpdater;
      this.customFields = customFields;
      this.supportData = supportData;
   }
}

export class AssignmentDetailsModal extends ViewModel {
   private readonly state: AssignmentDetailsModalState;

   readonly customFields: AssignmentDetailsModalParams["customFields"];

   readonly notificationManagerInstance = notificationManagerInstance;

   readonly headerSize = observable<OptionalSize>({ height: null, width: null });
   readonly footerSize = observable<OptionalSize>({ height: null, width: null });
   readonly contentSize = observable<OptionalSize>({ height: null, width: null });

   onKeyDown = (self: this, event: KeyboardEvent): boolean => {
      const handled = new EventHandler([
         {
            visit: () =>
               event.key == "Enter" &&
               (event.metaKey || event.ctrlKey) &&
               unwrap(this.state.canSave),
            accept: () => {
               this.state.saveAssignment(false);
            },
         },
         {
            visit: () => event.key == "Escape" && !this.state.hasAnyChanges(),
            accept: () => modalManager.clearModal(),
         },
      ]).handle(event);
      return !handled;
   };

   readonly cancelButton = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Cancel",
            clickedText: "Exiting...",
            confirmText: pureComputed<string>(() => {
               return this.state.hasAnyChanges() ? "Close without saving?" : "";
            }),
            onClick: () => {
               modalManager.clearModal();
            },
         }),
      ],
      size: ButtonSize.LARGE,
   });
   readonly saveButtons = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Save",
            clickedText: "Saving...",
            onClick: async () => {
               if (this.state.canSave()) await this.saveAssignment(false);
            },
            isDisabled: pureComputed(() => !this.state.canSave()),
         }),
         ...(authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)
            ? [
                 new ButtonItem({
                    text: "Save & Alert",
                    clickedText: "Saving...",
                    onClick: async () => {
                       if (this.state.canSave()) await this.saveAssignment(true);
                    },
                    isDisabled: pureComputed(() => !this.state.canSave()),
                 }),
              ]
            : []),
      ],
      color: ButtonColor.ORANGE,
      size: ButtonSize.LARGE,
   });
   readonly deleteButtons = ButtonDropDown.factory({
      buttons: [
         new ButtonItem({
            text: "Delete",
            clickedText: "Deleting...",
            confirmText: "Are you sure?",
            onClick: async () => {
               await this.deleteAssignment(false);
            },
            isDisabled: false,
         }),
         ...(authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)
            ? [
                 new ButtonItem({
                    text: "Delete & Alert",
                    clickedText: "Deleting...",
                    confirmText: "Are you sure?",
                    onClick: async () => {
                       await this.deleteAssignment(true);
                    },
                    isDisabled: false,
                 }),
              ]
            : []),
      ],
      color: ButtonColor.RED,
      size: ButtonSize.LARGE,
   });

   readonly actionButtons = pureComputed<ModalAction[]>(() => {
      return [
         { buttonComponent: this.cancelButton },
         ...(this.state.assignmentDetailsModalType() == AssignmentDetailsModalType.EDIT_ASSIGNMENT
            ? [{ buttonComponent: this.deleteButtons }]
            : []),
         { buttonComponent: this.saveButtons },
      ];
   });

   readonly tabs: ModalTab[];

   private readonly modalFooterParams: ModalFooterParams;
   private readonly modalHeaderParams: ModalHeaderParams;
   private readonly tabbedPaneParams: TabbedPaneParams;

   constructor({
      createAssignment,
      customFields,
      deleteAssignment,
      assignment,
      requestSize,
      supportData,
      updateAssignment,
      assignmentDetailsModalType,
      newAssignmentSeedData,
      initialPane = AssignmentDetailsModalPane.EDIT_ASSIGNMENT,
   }: AssignmentDetailsModalParams) {
      super(template());
      this.state = new AssignmentDetailsModalState({
         assignment,
         assignmentCreator: createAssignment,
         assignmentDeleter: deleteAssignment,
         assignmentDetailsModalType,
         assignmentUpdater: updateAssignment,
         customFields,
         newAssignmentSeedData,
         supportData,
      });
      this.customFields = customFields;
      this.state.assignmentedSizeCombined.subscribe((size) => {
         requestSize(size);
      });
      this.tabs = this.createTabs();

      this.modalFooterParams = {
         actions: this.actionButtons,
         requestSize: this.state.requestSizeForFooter,
      };
      this.modalHeaderParams = {
         title:
            this.state.assignmentDetailsModalType() == AssignmentDetailsModalType.CREATE_ASSIGNMENT
               ? "New Assignment"
               : this.state.assignmentDetailsModalType() == AssignmentDetailsModalType.FILL_REQUEST
               ? "New Assignment"
               : "Assignment Details",
         requestSize: this.state.requestSizeForHeader,
      };
      this.tabbedPaneParams = {
         tabs: this.tabs,
         initialTabIndex: initialPane,
         requestSize: this.state.requestSizeForContent,
      };
   }

   readonly createTabs: () => ModalTab[] = () => [
      {
         tabName: "Edit Assignment",
         component: AssignmentEditPane.factory({
            customFields: this.customFields,
            assignmentDetailsModalType: this.state.assignmentDetailsModalType,
            preventingSave: this.state.preventSaveFromEditPane,
            assignment: this.state.assignment,
            requestSize: () => {}, // Overwritten by tabbed-pane.
            setPreventingSave: this.state.setPreventSaveFromEditPane,
            stagedAssignment: this.state.stagedAssignment,
            supportData: this.state.supportData,
            updateAssignmentDetails: this.updateAssignmentDetails,
         }),
         hasChanges: pureComputed(() => this.state.hasEditPaneChanges()),
      },
   ];

   static factory(
      params: AssignmentDetailsModalParams,
   ): ComponentArgs<AssignmentDetailsModalParams> {
      return {
         name: "assignment-details-modal",
         params,
      };
   }

   private async saveAssignment(showAlertModal: boolean): Promise<void> {
      if (this.state.isSaving()) {
         const notification = new Notification({
            text: "Save already in progress.",
            icon: Icons.WARNING,
         });
         this.notificationManagerInstance.show(notification);
         return;
      }
      this.state.setIsSaving(true);
      const notification = new ProgressNotification({
         message: "Saving...",
         actions: [],
      });
      this.notificationManagerInstance.show(notification);
      try {
         if (showAlertModal == true) modalManager.clearModal();
         await this.state.saveAssignment(showAlertModal);
         notification.success({
            message: "Saved successfully.",
         });
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
      } catch (error) {
         notification.failed({
            message: "Failed to save changes.",
         });
      }
      this.state.setIsSaving(false);
   }

   private async deleteAssignment(showAlertModal: boolean): Promise<void> {
      if (this.state.isDeleting()) {
         const notification = new Notification({
            text: "Deletion already in progress.",
            icon: Icons.WARNING,
         });
         this.notificationManagerInstance.show(notification);
         return;
      }
      this.state.setIsDeleting(true);
      const notification = new ProgressNotification({
         message: "Deleting...",
         actions: [],
      });
      this.notificationManagerInstance.show(notification);
      try {
         if (showAlertModal == true) modalManager.clearModal();
         await this.state.deleteAssignment(showAlertModal);
         notification.success({
            message: "Deleted successfully.",
         });
         setTimeout(() => notificationManagerInstance.dismiss(notification), 5000);
      } catch (error) {
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "assignment-details-modal_delete";
            event.addMetadata(BUGSNAG_META_TAB.ASSIGNMENT, this.state.assignment());
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });

         notification.failed({
            message: "Failed to delete assignment.",
         });
      }
      this.state.setIsDeleting(false);
      modalManager.clearModal();
   }

   private updateAssignmentDetails = (update: Partial<StagedAssignment>): void => {
      this.assignmentUpdateVisitors
         .filter((visitor) => visitor.visit(update))
         .forEach((acceptor) => acceptor.accept(update));
   };

   private readonly assignmentUpdateVisitors: AssignmentUpdateVisitor[] = [
      {
         visit: ({ project, project_id }): boolean => {
            return project != null && project_id != null;
         },
         accept: ({ project, project_id }): void => {
            this.state.addStagedUpdateData({
               project: project!,
               project_id: project_id!,
            });
         },
      },
      {
         visit: ({ person, resource_id }): boolean => {
            return person != null && resource_id != null;
         },
         accept: ({ person, resource_id }): void => {
            this.state.addStagedUpdateData({
               person: person!,
               resource_id: resource_id!,
            });
         },
      },
      {
         visit: ({ status, status_id }): boolean => {
            return status !== undefined && status_id !== undefined;
         },
         accept: ({ status, status_id }): void => {
            this.state.addStagedUpdateData({ status, status_id });
         },
      },
      {
         visit: ({ category, category_id }): boolean => {
            return category !== undefined && category_id !== undefined;
         },
         accept: ({ category, category_id }): void => {
            this.state.addStagedUpdateData({ category, category_id });
         },
      },
      {
         visit: ({ subcategory, subcategory_id }): boolean => {
            return subcategory !== undefined && subcategory_id !== undefined;
         },
         accept: ({ subcategory, subcategory_id }): void => {
            this.state.addStagedUpdateData({ subcategory, subcategory_id });
         },
      },
      {
         visit: ({ start_day }): boolean => {
            return start_day !== undefined;
         },
         accept: ({ start_day }): void => {
            this.state.addStagedUpdateData({ start_day });
         },
      },
      {
         visit: ({ end_day }): boolean => {
            return end_day !== undefined;
         },
         accept: ({ end_day }): void => {
            this.state.addStagedUpdateData({ end_day });
         },
      },
      {
         visit: ({ start_time }): boolean => {
            return start_time !== undefined;
         },
         accept: ({ start_time }): void => {
            this.state.addStagedUpdateData({ start_time });
         },
      },
      {
         visit: ({ end_time }): boolean => {
            return end_time !== undefined;
         },
         accept: ({ end_time }): void => {
            this.state.addStagedUpdateData({ end_time });
         },
      },
      {
         visit: ({ overtime }): boolean => {
            return overtime !== undefined;
         },
         accept: ({ overtime }): void => {
            this.state.addStagedUpdateData({ overtime });
         },
      },
      {
         visit: ({ overtime_rates }): boolean => {
            return overtime_rates !== undefined;
         },
         accept: ({ overtime_rates }): void => {
            this.state.addStagedUpdateData({ overtime_rates });
         },
      },
      {
         visit: ({ pay_split }): boolean => {
            return pay_split !== undefined;
         },
         accept: ({ pay_split }): void => {
            this.state.addStagedUpdateData({ pay_split });
         },
      },
      {
         visit: ({ percent_allocated }): boolean => {
            return percent_allocated !== undefined;
         },
         accept: ({ percent_allocated }): void => {
            this.state.addStagedUpdateData({ percent_allocated });
         },
      },
      {
         visit: ({ work_days }): boolean => {
            return work_days != null;
         },
         accept: ({ work_days }): void => {
            this.state.addStagedUpdateData({ work_days });
         },
      },
      {
         visit: ({ custom_fields }): boolean => {
            return custom_fields != null;
         },
         accept: ({ custom_fields }): void => {
            this.state.addStagedUpdateData({ custom_fields });
         },
      },
   ];
}

ko.components.register("assignment-details-modal", {
   viewModel: AssignmentDetailsModal,
   template: template(),
   synchronous: true,
});

import { timeOptions } from "./constants";

export const getOrderedTimeOptions = (start?: number) => {
   if (!start) {
      return timeOptions;
   }

   const cutoffTimes = [];
   const remainingTimes = [];

   for (const option of timeOptions) {
      if (option.value < start) {
         cutoffTimes.push(option);
      } else {
         remainingTimes.push(option);
      }
   }

   return remainingTimes.concat(cutoffTimes);
};

import { PersonStore } from "@/stores/person-store.core";
import type { GetPersonDetailsResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import * as React from "react";

export function useGetPeopleDetails(id: string) {
   const [data, setData] = React.useState<GetPersonDetailsResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   const fetchPersonDetails = React.useCallback(async () => {
      setIsLoading(true);
      setData(null);
      const data = await PersonStore.getPersonDetails(id).payload;
      setData(data);
      setIsLoading(false);
   }, [id]);

   React.useEffect(() => {
      if (!id) return;
      fetchPersonDetails();
   }, [id, fetchPersonDetails]);

   // added refetch function to allow for manual refetching if and when needed
   const refetch = React.useCallback(() => {
      fetchPersonDetails();
   }, [fetchPersonDetails]);

   return { data, isLoading, refetch };
}

import type { useI18nContext } from "@procore/core-react";
import { object, date, number } from "yup";
import { getBaseSchemaForRequestAndAssignment } from "../validation-schema";
import type { AssignmentFormValues } from "./types";
import { getTotalHours } from "../helper";

export const assignmentSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   includeStatus: boolean,
   selectedWorkDays: { [key: string]: boolean },
   assignmentByAllocation: boolean,
   assignmentByTime: boolean,
   isProcoreIdAvailable: boolean,
   isEquipmentEnabled: boolean,
   isOverTimeSelected: boolean,
) => {
   const baseSchema = getBaseSchemaForRequestAndAssignment(
      I18n,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
      includeStatus,
   );

   const getValidationStringForResource = () => {
      // this logic will be removed once we complete the equipment beta
      if (isEquipmentEnabled) {
         return I18n.t(
            "views.company.workforce_planning.assignment.validations.labor_or_equipment_required",
         );
      }
      return I18n.t("views.company.workforce_planning.validations.required_field");
   };

   return object().shape({
      resource: object()
         .nullable()
         .test("resource-or-equipment", getValidationStringForResource(), function (value) {
            const equipment = this.parent?.equipment;
            // If neither `resource` nor `equipment` is filled, return a validation error
            return !!value || !!equipment;
         }),

      equipment: object()
         .nullable()
         .test(
            "equipment-or-resource",
            I18n.t(
               "views.company.workforce_planning.assignment.validations.labor_or_equipment_required",
            ),
            function (value) {
               const resource = this.parent?.resource;
               if (isProcoreIdAvailable) {
                  // If neither `resource` nor `equipment` is filled, return a validation error
                  return !!value || !!resource;
               }
               // Otherwise, `equipment` is not required
               return true;
            },
         ),

      // Include the rest of the base schema
      ...baseSchema,

      // Conditional validation for `pay_split` if `isOverTimeSelected` is true
      ...(isOverTimeSelected && {
         pay_split: object()
            .shape({
               overtime: number().nullable(),
               straight: number().nullable(),
               unpaid: number().nullable(),
            })
            .test("sum-equals-total-hours", function (pay_split) {
               // Use `this.parent` to access the form values
               const formValues: AssignmentFormValues = this.parent;

               // Calculate total hours using the `getTotalHours` function
               const totalHours = getTotalHours(formValues.end_time.id, formValues.start_time.id);

               // Construct the validation message dynamically
               const validationMessage = I18n.t(
                  "views.company.workforce_planning.assignment.validations.total_time_add_check",
                  { hours: totalHours },
               );

               // Extract individual values from `pay_split`
               const overtime = pay_split?.overtime ?? 0;
               const straight = pay_split?.straight ?? 0;
               const unpaid = pay_split?.unpaid ?? 0;

               // Calculate the sum of pay split values
               const sum = overtime + straight + unpaid;

               // Validate that the sum equals totalHours
               return sum === totalHours || this.createError({ message: validationMessage });
            }),
      }),
   });
};

export const assignmentAlertsSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   shouldScheduleDelivery: boolean,
) => {
   if (!shouldScheduleDelivery) return;

   return object().shape({
      scheduled_day: date().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      hour: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      minute: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      day_night: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   });
};

import { commonInitialValuesForRequestAndAssignment } from "../constants";
import type { RequestFormValues } from "./types";

export const formInitialValues = (
   projectId?: string,
   categoryId?: string,
   subcategoryId?: string,
): RequestFormValues => {
   return {
      ...commonInitialValuesForRequestAndAssignment,
      job_title: null,
      quantity: null,
      tags: [],
      project: projectId
         ? {
              id: projectId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
      category: categoryId
         ? {
              id: categoryId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
      subcategory: subcategoryId
         ? {
              id: subcategoryId,
              label: "", // Label is required for typing but the form field only needs ID
           }
         : null,
   };
};

import type { ProjectRole } from "@laborchart-modules/common";
import { v4 } from "uuid";

export function reconstructHtmlWithTokens(initialTokens: any[], text: string): string {
   return text.replace(/LC-DT<{(.*?)}>/g, (match, tokenId) => {
      const token = initialTokens.find((t) => t.id === tokenId);
      if (!token) return match; // If token not found, leave the placeholder as is

      const tmpNode = document.createElement("span");
      const newNode = document.createElement("span");
      newNode.contentEditable = "false";
      newNode.dataset.token = JSON.stringify(token);
      newNode.className = "lc__canned-token";
      newNode.innerHTML = `{ ${token.name} }`;
      tmpNode.appendChild(newNode);
      return tmpNode.innerHTML;
   });
}

function processHtmlToken(
   htmlToken: string,
   projectId: string,
): { tokenObject: any; contentString: string } {
   const encodedData = htmlToken.match(/data-token=["']({.*?})["']/);
   const newNode = document.createElement("span");
   newNode.innerHTML = encodedData![1];
   const text = newNode.textContent;
   const processingToken = JSON.parse(text!);
   const tokenId = v4();
   processingToken["id"] = tokenId;
   if (!processingToken.subject_1_id) {
      processingToken.subject_1_id =
         processingToken.subject_1_type === "projects" ? projectId : null;
   }
   return { tokenObject: processingToken, contentString: ` LC-DT<{${tokenId}}> ` };
}

export function stripAndReturnHTMLTokens(content: string, projectId: string) {
   const dynamicTokens: any[] = [];
   const tokenlessContent = content.replace(/<span(.*?)lc__canned(.*?)<\/span>/g, (hit: string) => {
      const { tokenObject, contentString } = processHtmlToken(hit, projectId);
      dynamicTokens.push(tokenObject);
      return contentString;
   });
   // We need to replace breakpoints with newline characters for SMS messages
   const apiReadyContent = tokenlessContent
      .replace(/<p><br><\/p>/g, "\n")
      .replace(/<br\s*\/?>/gi, "\n");

   return { content: apiReadyContent, dynamicTokens: dynamicTokens };
}

export function getRoleTokensForMessages(roles: ProjectRole[]) {
   const roleTokens: any[] = [];

   roles.forEach((r: any) => {
      const name = `${r.assignee.name.first} ${r.assignee.name.last}`;
      roleTokens.push({
         name: `${r.position.name}: ${name}`,
         subject_1_key: "name",
         subject_1_type: "people",
         subject_1_id: r.assignee.id,
      });

      if (r.assignee.phone) {
         roleTokens.push({
            name: `${name}: Phone`,
            subject_1_key: "phone",
            subject_1_type: "people",
            subject_1_id: r.assignee.id,
         });
      }

      if (r.assignee.email) {
         roleTokens.push({
            name: `${name}: Email`,
            subject_1_key: "email",
            subject_1_type: "people",
            subject_1_id: r.assignee.id,
         });
      }
   });

   return roleTokens;
}

export const validateAlertContent = (alertContent: { subject: string; content: string }) => {
   const newErrorFields: string[] = [];

   if (alertContent.subject.trim().length === 0) {
      newErrorFields.push("subject");
   }
   if (alertContent.content.trim().length === 0) {
      newErrorFields.push("content");
   }

   return newErrorFields;
};

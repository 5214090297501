import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";
import type { MaybeObservableArray, Observable, PureComputed, Subscription } from "knockout";
import { isObservable, observable, pureComputed, unwrap } from "knockout";
import type { GridStore } from "../../grid/grid-store";
import { ArrayGridStore } from "../../grid/array-grid-store";
import { textSearch } from "@/lib/utils/text-search";
import { ActionResult } from "../drop-down-2";
import type { GridCellFactory } from "../../grid/grid-column";
import { CompositeCellFactoryBuilder } from "../../grid/cells/composite-cell-factory";
import type { CheckboxCellParams } from "../../grid/cells/checkbox-cell";
import { CheckboxCell } from "../../grid/cells/checkbox-cell";
import { getSetDifference, getSetUnion } from "@/lib/utils/sets";

export const SELECT_ALL_ID = "__SELECT_ALL__";
export const SELECT_ALL_ITEM = Symbol("select all");

export const SELECT_ALL_ITEM_INSTANCE: MultiSelectItem<any> = {
   id: SELECT_ALL_ID,
   item: SELECT_ALL_ITEM,
};

export interface MultiSelectItem<T extends ItemBase> extends ItemBase {
   item: symbol | T;
}

export interface MultiSelectDropDownPaneParams<T> {
   /** Items to display. */
   items: MaybeObservableArray<T> | PureComputed<T[]>;

   /**
    * Observable of the selected IDs. This observable will be directly managed
    * by the `MultiSelectDropDownPane`.
    */
   selectedIds: Observable<Set<string>> | PureComputed<Set<string>>;

   /** Provider to extract the text used within each cell. */
   textProvider: (item: T) => string;

   /** Provider used to extract text when filtering for a drop down search. */
   searchTextProvider?: ((item: T) => string) | null;

   /**
    * Used to selectively disable list items that cannot be edited,
    * but can be viewed.
    */
   isDisabledItem?: (item: T) => boolean;

   isSelectAllDisabled?: boolean;
}

/** `DropDownPane` backed by an array. */
export class MultiSelectDropDownPane<T extends ItemBase & { color?: string }>
   implements DropDownPane<MultiSelectItem<T>>
{
   readonly gridStore: Observable<GridStore<MultiSelectItem<T>> | null>;
   readonly isSearchable: Observable<boolean>;
   readonly actionableSelectedIds = pureComputed({
      read: () => getSetDifference(this.selectedIds(), this.nonActionableSelectedIds()),
      write: (selectedIds: Set<string>) =>
         this.selectedIds(getSetUnion(selectedIds, this.nonActionableSelectedIds())),
   });

   private nonActionableSelectedIds = pureComputed(() => {
      return new Set(
         unwrap(this.items)
            .filter((item) => {
               return typeof item == "symbol"
                  ? false
                  : this.isDisabledItem(item) && this.selectedIds().has(item.id);
            })
            .map((item) => item.id),
      );
   });

   private readonly isAllSelected = pureComputed(() => {
      const gridStore = this.gridStore();
      const selectedIds = this.selectedIds();
      if (!gridStore || selectedIds.size == 0) return false;
      return selectedIds.size == gridStore.rows().length - 1 ? true : false;
   });

   private readonly items: MultiSelectDropDownPaneParams<T>["items"];
   private readonly selectedIds: MultiSelectDropDownPaneParams<T>["selectedIds"];
   private readonly textProvider: (item: T) => string;
   private readonly searchTextProvider: ((item: T) => string) | null;
   private readonly isDisabledItem: (item: T) => boolean;
   private readonly isSelectAllDisabled: Exclude<
      MultiSelectDropDownPaneParams<T>["isSelectAllDisabled"],
      undefined
   >;
   private readonly subscriptions: Subscription[] = [];
   private search: string | null = null;

   constructor({
      items,
      selectedIds,
      textProvider,
      searchTextProvider = null,
      isDisabledItem = () => false,
      isSelectAllDisabled = false,
   }: MultiSelectDropDownPaneParams<T>) {
      this.isDisabledItem = isDisabledItem;
      this.isSelectAllDisabled = isSelectAllDisabled;
      this.items = items;
      this.searchTextProvider = searchTextProvider;
      this.selectedIds = selectedIds;
      this.textProvider = textProvider;
      this.isSearchable = observable(Boolean(this.searchTextProvider));
      this.gridStore = observable<GridStore<MultiSelectItem<T>> | null>(
         new ArrayGridStore(this.createItems(unwrap(items))),
      );
      if (isObservable(this.items)) {
         this.subscriptions.push(this.items.subscribe(this.onItemsChanged, this));
      }
   }

   /** This `GridCellFactory` should be passed to the DropDown. */
   get cellFactory(): GridCellFactory<MultiSelectItem<T>> {
      return new CompositeCellFactoryBuilder<MultiSelectItem<T>>()
         .add<CheckboxCellParams>({
            visit: (item) => item.item == SELECT_ALL_ITEM,
            accept: () => {
               return CheckboxCell.factory(() => ({
                  title: "Select All",
                  value: this.isAllSelected,
                  isFocusable: false,
                  alignment: "left",
               }));
            },
         })
         .add<CheckboxCellParams>({
            visit: (item) => item.item != SELECT_ALL_ITEM,
            accept: () => {
               return CheckboxCell.factory((item) => ({
                  title: this.textProvider(item.item as T),
                  value: pureComputed(() => this.selectedIds().has(item.id)),
                  isFocusable: false,
                  alignment: "left",
                  isDisabled: this.isDisabledItem(item.item as T),
                  dotColor: (item.item as T).color ?? null,
               }));
            },
         })
         .build();
   }

   /** This `actionInterceptor` should be passed to the DropDown. */
   actionInterceptor = (item: MultiSelectItem<T>): ActionResult => {
      if (item.item != SELECT_ALL_ITEM) return ActionResult.TOGGLE;
      const newIds = new Set(this.selectedIds());
      if (this.isAllSelected() == false) {
         // Select all of the items.
         this.gridStore()!
            .rows()
            .forEach((row) => {
               if (row.item != SELECT_ALL_ITEM) {
                  newIds.add(row.id);
               }
            });
         this.selectedIds(newIds);
      } else {
         // Deselect all of the items.
         this.selectedIds(new Set());
      }
      return ActionResult.IGNORE;
   };

   loadItems(params: LoadItemsParams): void {
      this.search = params.search;
      if (!this.search || !this.searchTextProvider) {
         this.gridStore(new ArrayGridStore(this.createItems(unwrap(this.items))));
         return;
      }
      const filtered = textSearch({
         items: unwrap(this.items),
         textProvider: this.searchTextProvider!,
         search: this.search,
      });
      this.gridStore(new ArrayGridStore(this.createItems(filtered)));
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   private onItemsChanged() {
      this.loadItems({ search: this.search, startingAt: null });
   }

   private createItems(items: T[]) {
      if (items.length == 0) {
         this.isSearchable(false);
         return [];
      }
      const resultItems = this.isSelectAllDisabled == true ? [] : [SELECT_ALL_ITEM_INSTANCE];
      return resultItems.concat(
         items.map((item) => {
            return { id: item.id, item };
         }),
      );
   }
}

import "./multiline-linked-text-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./multiline-linked-text-cell.pug";
import ko from "knockout";

const PADDING = 5;
const LINE_HEIGHT = 18;

export interface MultilineLinkedTextCellValue {
   text: string;
   href: string | null;
}

export interface MultilineLinkedTextCellParams {
   values: MultilineLinkedTextCellValue[];
}

export class MultilineLinkedTextCell {
   readonly values: MultilineLinkedTextCellValue[];
   constructor(params: MultilineLinkedTextCellParams) {
      this.values = params.values;
   }

   static factory<T>(
      formatter: (data: T) => MultilineLinkedTextCellParams,
   ): GridCellFactory<T, MultilineLinkedTextCellParams> {
      return (data: T): GridCell<MultilineLinkedTextCellParams> => {
         const params = formatter(data);
         return {
            component: {
               name: "multiline-linked-text-cell",
               params,
            },
            height: params.values.length * LINE_HEIGHT + PADDING,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => MultilineLinkedTextCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: MultilineLinkedTextCell.factory(provider),
         copyProvider: (data: T) =>
            provider(data)
               .values.map((v) => v.text)
               .join(", "),
      };
   }
}

ko.components.register("multiline-linked-text-cell", {
   viewModel: MultilineLinkedTextCell,
   template: template(),
   synchronous: true,
});

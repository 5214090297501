import type { MaybeObservable, Observable, PureComputed } from "knockout";
import { observable, pureComputed, unwrap } from "knockout";
import type { EditorComponentParams } from "./editor-component";
import { SaveCancelledError } from "./editor-component";
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import { GridStoreUpdateError } from "@/lib/utils/grid-store/grid-store-rows-updater";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";

export type ValidatedValue<T> =
   | {
        valid: true;
        value: T;
     }
   | {
        valid: false;
        error: string | null;
     };

/**
 * Abstract class which adds common functionality for editors.
 * Editors using the FieldEditor component may extend this class to add
 * common onSave and onCancel functionality.
 */
export abstract class AbstractFieldEditor<TValue, TSave = TValue> {
   readonly title: MaybeObservable<string | null>;
   readonly saveText: MaybeObservable<string | null>;
   readonly value: Observable<TValue> | PureComputed<TValue>;
   readonly isSaving = observable(false);
   readonly initialValue: TValue;

   readonly validation = pureComputed(() => {
      const result = this.validate(this.value());
      return result.valid ? null : result.error;
   });

   readonly hasChanged = pureComputed(() => {
      return this.value() !== this.initialValue;
   });

   readonly canSave = pureComputed(() => {
      return !this.isSaving() && this.hasChanged() && this.validate(this.value()).valid;
   });

   constructor(
      private readonly editorParams: EditorComponentParams<TValue, TSave>,
      value: Observable<TValue> | PureComputed<TValue>,
   ) {
      this.title = editorParams.title;
      this.saveText = "Save";
      this.value = value;
      this.initialValue = unwrap(value);
      this.onSave = this.onSave.bind(this);
   }

   /**
    * Implement to convert the current value into the save value.
    * NOTE: Used to generate the validation errors.
    */
   abstract validate(value: TValue): ValidatedValue<TSave>;

   async onSave(): Promise<void> {
      const result = this.validate(this.value());
      if (result.valid) {
         await this.save(result.value);
      }
   }

   onCancel = (): void => {
      if (this.editorParams.onClose) {
         this.editorParams.onClose({ hasSaved: false });
      }
   };

   async save(value: TSave): Promise<void> {
      this.isSaving(true);
      try {
         await this.editorParams.saveProvider(value);
         if (this.editorParams.onClose) {
            this.editorParams.onClose({ hasSaved: true });
         }
      } catch (error) {
         if (error instanceof SaveCancelledError || error instanceof GridStoreUpdateError) return;
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "abstract-field-editor_save";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
         });
         notificationManagerInstance.show(
            new Notification({
               icon: Icons.WARNING,
               text: "An unexpected error occurred while saving.",
               duration: 5000,
            }),
         );
      } finally {
         this.isSaving(false);
      }
   }
}

import "./confirm-assignment-end-pane.styl"
import template from "./confirm-assignment-end-pane.pug"
import { DateUtils } from "@/lib/utils/date"

### Auth, Real-Time & Stores ###
import { Assignment2Store } from "@/stores/assignment-2-store.core"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

import ko from "knockout"

export class ConfirmAssignmentEndPane extends ModalPane
   constructor: (assignment, viewingDate) ->
      assertArgs(arguments, Object, Date)
      super("End #{assignment.resourceTitle()}'s Assignment", "", template())
      @overflowable(true)
      @assignment = assignment
      @viewingDate = viewingDate
      @explanationText = ko.observable()
      @canSave = ko.observable(true)
      @endingDate = ko.observable()
      @endingDate.subscribe (newVal) =>
         endingDay = DateUtils.getDetachedDay(newVal)

         # Need to be strings.
         inRangeDays = []
         startDate = DateUtils.getAttachedDate(assignment.startDay())
         endDate = DateUtils.getAttachedDate(assignment.endDay())
         startWorkDay = startDate.getDay()
         endWorkDay = endDate.getDay()
         duration = DateUtils.daysBetweenDates(startDate, endDate) + 1

         if duration >= 7
            inRangeDays = ['0','1','2','3','4','5','6']
         else
            if startWorkDay < endWorkDay
               for i in [startWorkDay...(endWorkDay + 1)]
                  inRangeDays.push("#{i}")

            else if startWorkDay > endWorkDay
               for i in [startWorkDay...7]
                  inRangeDays.push("#{i}")

               for i in [0...(endWorkDay + 1)]
                  inRangeDays.push("#{i}")

            else if startWorkDay == endWorkDay
                  inRangeDays = ["#{startWorkDay}"]

         # TODO: Should use DateUtils.getFormattedWorkDays
         workDayAbbrvs = {
            0: "Su"
            1: "M"
            2: "Tu"
            3: "W"
            4: "Th"
            5: "F"
            6: "Sa"
         }
         workDayString = "["
         seekingActiveDay = true
         lastActiveKey = null
         for key, val of assignment.workDays()
            # Restrict to only showing days that are within range.
            if val
               val = inRangeDays.indexOf(key) !=-1

            if seekingActiveDay
               if val
                  lastActiveKey = key
                  workDayString += "#{workDayAbbrvs[key]}-"
                  seekingActiveDay = false
            else
               if !val
                  # Ok to always subtract becuase Sunday can't be hit here.
                  previousKey = String(key - 1)
                  if previousKey == lastActiveKey
                     workDayString = "#{workDayString.slice(0, -1)},"
                  else
                     workDayString += "#{workDayAbbrvs[key - 1]},"
                  seekingActiveDay = true
               else if key == "6" and val
                  # Might be at the end and enabled all the way through. 
                  workDayString += "Sa,"

         workDayString = "#{workDayString.slice(0, -1)}]"
         text = "Based on the assignments work days of #{workDayString},"

         if endingDay <= assignment.startDay()
            @canSave(true)
            @explanationText("#{text}<br>this assignment will be removed completely.")
         else if endingDay >= assignment.endDay()
            @canSave(false)
            currentEndDate = DateUtils.formatDetachedDay(assignment.endDay(), defaultStore.getDateFormat())
            @explanationText("Your end date needs to be before the assignments existing end date of #{currentEndDate}")
         else
            if assignment.workDays()[newVal.getDay()]
               @canSave(true)
               formattedEndDate = DateUtils.formatDetachedDay(endingDay, defaultStore.getDateFormat())
               @explanationText("#{text}<br>this assignment will now end on #{formattedEndDate}.")
            else
               # Rolling back to find the last working day.
               @endingDate(DateUtils.decrementDate(newVal))

      @endingDate(DateUtils.decrementDate(viewingDate))

   save: ->
      return unless @canSave()
      endingDay = DateUtils.getDetachedDay(@endingDate())
      if endingDay <= @assignment.startDay()
         try
            await Assignment2Store.deleteAssignment(@assignment.id).payload
            @data.set('notifyContext', "assignment-delete")
            @data.set('notifyBatchId', @assignment.id)
            modalManager.modalFinished()
         catch err
            return console.log("Error: ", err)
      else
         try
            await Assignment2Store.updateSingleAssignment({
               assignmentId: @assignment.id,
               update: { end_day: endingDay },
            }).payload
            @data.set('notifyContext', "assignment-edit")
            @data.set('notifyBatchId', @assignment.id)
            modalManager.modalFinished()
         catch err
            return console.log("Error: ", err)

   saveAndAlert: ->
      return unless @canSave()
      @data.set('notify', true)
      @data.set('notifyProjectId', @assignment.projectId())
      @data.set('notifyResourceId', @assignment.resourceId())

      @save()
   
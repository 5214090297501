import * as React from "react";
import { DetailPage, Box, useI18nContext } from "@procore/core-react";
import type { CellValueChangeParams, TableApi } from "@procore/data-table";
import { ProjectStore } from "@/stores/project-store.core";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { ProjectListDataTable } from "./project-list-data-table";
import { useGetGroupOptionsQuery } from "../tearsheets/project/queries";
import {
   filterGroupDetails,
   convertProjectDataTableFilters,
   convertDataTableSort,
   filterGroupIds,
   columnHeadersMap,
   defaultProjectListTableConfig,
   filterFieldMap,
   filterRendererMap,
} from "./helpers";
import { convertSavedViewToDataTableConfig, getUserName } from "@/react/shared/helper";
import { ProjectTearsheetProvider } from "../tearsheets/project/project-tearsheet";
import moment from "moment-timezone";
import { DateUtils } from "@/lib/utils/date";
import type {
   CustomFieldProjectType,
   ProjectListServerFilter,
   SortModel,
} from "@/react/prop-types";
import { useGetCustomFields, useGetSavedView } from "../common/queries/queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { ProjectListData } from "./project-list-prop-types";
import type { ProjectStatus as ProjectStatusType } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import {
   timeOptions,
   timeZoneOptions,
   TIME_ZONES_MAP,
   TIME_ZONES_MAP_REVERSED,
} from "@/lib/utils/timezones";
import type { TimeZonesMapValues } from "@/lib/utils/timezones";
import { FieldMapping } from "./project-list-enums";
import { CustomFieldType, type SavedView } from "@laborchart-modules/common";
import { FileDownloadToastProvider } from "../data-table/DataExport/FileDownload";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { useGetProjectRoleOptions } from "./queries";
import { useViewPreferenceContext } from "@/react/providers/permission-context-provider";

export function ProjectListContainer({ setTitle }: { setTitle: (title: string) => void }) {
   const { getViewPreference } = useViewPreferenceContext();
   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;
   const { groupId } = useGroupContext();
   const I18n = useI18nContext();
   const { data: groupOptions } = useGetGroupOptionsQuery();
   const [tableApi, setTableApi] = React.useState<TableApi>();
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.PROJECT);
   const { data: roleOptions } = useGetProjectRoleOptions(groupId);
   const [convertedSavedView, setConvertedSavedView] = React.useState<any>();

   const url = new URL(window.location.href);
   const params = url.searchParams;
   const [viewId] = React.useState(params.get("viewId"));

   const { data: savedViewData } = useGetSavedView(viewId);

   // I don't like this implementation but it fixes a production issue now.
   // On future pages we need to figure out how to prevent constant re-rendering.
   React.useEffect(() => {
      if (viewId && savedViewData && customFields && !convertedSavedView) {
         const convertedSavedViewData = convertSavedViewToDataTableConfig(
            savedViewData?.data as SavedView,
            columnHeadersMap,
            defaultProjectListTableConfig,
            filterFieldMap,
            filterRendererMap,
            groupId,
            customFields,
            roleOptions,
         );
         setConvertedSavedView(convertedSavedViewData);
         setTitle(savedViewData.data?.name ?? I18n.t("views.company.workforce_planning.list"));
      }
   }, [
      groupId,
      viewId,
      savedViewData,
      convertedSavedView,
      setConvertedSavedView,
      customFields,
      roleOptions,
   ]);

   const handleTableReady = (api: TableApi) => setTableApi(api);

   const fetchProjectList = async (
      filters: ProjectListServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
   ) => {
      const coreApiFilters = convertProjectDataTableFilters(filters);

      const coreApiSort = convertDataTableSort(sortModel);
      const params = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         starting_after: startingAfter ?? undefined,
         search: search,
         sort_by: coreApiSort.sort_by,
         sort_direction: coreApiSort.direction,
         custom_field_id: coreApiSort.custom_field_id, // required when sorted by custom field
         group_id: groupId == "my-groups" ? undefined : groupId,
         filters: coreApiFilters,
      };
      const projectData = await ProjectStore.getProjectList(params).payload;

      const projectListDetails = [];

      for (const projectList of projectData.data) {
         const wage_overrides = projectList.wage_overrides;
         const projectListOptions = filterGroupDetails({
            groupOptions,
            projectGroupList: projectList.group_ids,
         });

         const tags = projectList.tag_instances?.map((tag) => ({
            label: tag.tag.abbreviation,
            color: tag.tag.color,
            value: tag.tag.id,
            name: tag.tag.name,
            shape: "pill",
            componentName: "multiselect",
         }));
         let projectStatus = {
            value: projectList.status,
            label: I18n.t("views.company.workforce_planning.inactive"),
            color: "gray",
         };
         if (projectList.status === ProjectStatus.ACTIVE) {
            projectStatus = {
               value: projectList.status,
               label: I18n.t("views.company.workforce_planning.active"),
               color: "green",
            };
         }
         if (projectList.status === ProjectStatus.PENDING) {
            projectStatus = {
               value: projectList.status,
               label: I18n.t("views.company.workforce_planning.pending"),
               color: "yellow",
            };
         }
         const roleAttrs: Record<string, any> = {};
         projectList.roles.forEach((role: any) => {
            roleAttrs[`positions_${role.job_title_id}`] = {
               label: role.assignee_name,
               href: `/groups/${groupId}/people/${role.person_id}`,
            };
         });
         // Initialize the base project details
         const projectDetails: Record<string, any> = {
            ...projectList,
            project_name: projectList.name,
            color: {
               label: "",
               color: projectList.color ? projectList.color : "",
               shape: "square",
            },
            start_date: projectList.start_date ? new Date(projectList.start_date) : null,
            est_end_date: projectList.est_end_date ? new Date(projectList.est_end_date) : null,
            project_hash: projectList.job_number,
            project_type: projectList.project_type,
            postal: projectList.zipcode,
            created_at: projectList.created_at ? new Date(projectList.created_at) : null,
            created_by: getUserName(projectList.creator_name, isLastNameFirst),
            est_avg_rate: projectList.bid_rate ?? "",
            percent_complete: projectList.percent_complete,
            tag_instances: tags,
            wage_overrides: wage_overrides
               ? wage_overrides.map((wo) => ({
                    label: I18n.t(
                       "views.company.workforce_planning.projects.wage_overrides_value",
                       {
                          wage_overrides_position_name: wo.position_name,
                          wage_overrides_rate: wo.rate,
                       },
                    ),
                 }))
               : "",
            groups: projectListOptions,
            status: projectStatus,
            timezone: timeZoneOptions.find(
               ({ id }) =>
                  id ===
                  TIME_ZONES_MAP_REVERSED[(projectList.timezone ?? "") as TimeZonesMapValues],
            )!,
            daily_start_time: {
               id: projectList.daily_start_time,
               label: timeOptions.find(({ id }) => id === projectList.daily_start_time)!.label,
            },
            daily_end_time: {
               id: projectList.daily_end_time,
               label: timeOptions.find(({ id }) => id === projectList.daily_end_time)!.label,
            },
            procore_id: projectList.procore_id,
            ...roleAttrs,
         };

         // Adding custom fields as individual attributes
         if (projectList.custom_fields) {
            projectList.custom_fields.forEach((customField: CustomFieldProjectType) => {
               const { value, type, integration_name } = customField;
               if (type === CustomFieldType.DATE && value) {
                  projectDetails[integration_name] = DateUtils.getAttachedDate(value);
               } else if (type === CustomFieldType.HEX_COLOR && value) {
                  projectDetails[integration_name] = {
                     label: "",
                     color: value,
                     shape: "square",
                  };
               } else {
                  projectDetails[integration_name] = value;
               }
            });
         }
         projectListDetails.push(projectDetails);
      }
      return { data: projectListDetails, pagination: projectData.pagination };
   };

   //Edit project - inline update project details
   /* istanbul ignore next */
   const updateProject = async (params: CellValueChangeParams<any>) => {
      const { id, timezone } = params.rowData as ProjectListData;

      // Extract 'fieldName' and 'newValue' from 'params'
      const { field: fieldName, newValue } = params;

      const maybeCustomField = customFields?.find((x) => x.integration_name === fieldName);

      // Initialize 'payload' with 'id' and the updated field. For custom fields, the
      // payload key should be the custom field ID.
      const payload = { id, [fieldName]: newValue };

      // Check and update fields based on 'fieldName' due to the different data types and properties
      if (fieldName === FieldMapping.groups) {
         payload[FieldMapping.groups] = filterGroupIds({ groupOptions, editedGroupIds: newValue });
      } else if (fieldName === FieldMapping.start_date) {
         payload[fieldName] = newValue ? newValue.getTime() : null;
      } else if (fieldName === FieldMapping.est_end_date) {
         payload[fieldName] = newValue ? newValue.getTime() : null;
      } else if (
         fieldName === FieldMapping.daily_start_time ||
         fieldName === FieldMapping.daily_end_time
      ) {
         payload[fieldName] = newValue.id;
      } else if (fieldName === FieldMapping.status) {
         payload[fieldName] = newValue.value as ProjectStatusType;
      } else if (fieldName === FieldMapping.timezone) {
         payload[fieldName] = TIME_ZONES_MAP[timezone.id];
      } else if (
         fieldName === FieldMapping.bid_rate ||
         fieldName === FieldMapping.percent_complete
      ) {
         payload[fieldName] = Number(newValue);
      } else if (fieldName === FieldMapping.color) {
         payload[fieldName] = newValue.color;
      } else if (maybeCustomField) {
         delete payload[fieldName];
         let value = newValue;
         if (maybeCustomField.type === CustomFieldType.HEX_COLOR) {
            value = newValue.color;
         }
         if (maybeCustomField.type === CustomFieldType.DATE) {
            value = getDetachedDay(newValue);
         }
         if (
            maybeCustomField.type === CustomFieldType.NUMBER ||
            maybeCustomField.type === CustomFieldType.CURRENCY
         ) {
            value = Number(newValue);
         }
         payload["custom_fields"] = { [maybeCustomField.id]: value };
      }

      // Sending the updated payload to ProjectStore and returning the response
      const response = ProjectStore.updateProjectsStream([payload]);
      return response;
   };

   const currentDateTime = moment().format("YYYYMMDD_HHmmss");

   return (
      <ProjectTearsheetProvider projectsTableApi={tableApi}>
         <FileDownloadToastProvider>
            <DetailPage width="block" className={"border-box project-list-detail-page"}>
               <DetailPage.Main className={"project-list-page-main"}>
                  <DetailPage.Body className={"project-list-page-body"}>
                     <DetailPage.Card className={"project-list-card"}>
                        <DetailPage.Section className="project-list-section">
                           <Box
                              style={{
                                 height: "100%",
                                 marginTop: "5px",
                              }}
                           >
                              {!viewId && groupOptions && roleOptions && (
                                 <ProjectListDataTable
                                    fetchProjectList={fetchProjectList}
                                    groupId={groupId}
                                    savedView={null}
                                    currentDateTime={currentDateTime}
                                    tableApi={tableApi}
                                    handleTableReady={handleTableReady}
                                    customFields={customFields}
                                    groupOptions={groupOptions}
                                    updateProject={updateProject}
                                    roleOptions={roleOptions}
                                    isLastNameFirst={isLastNameFirst}
                                 />
                              )}

                              {/* If the view ID is present, wait until the saved view is loaded from the API to render the data table */}
                              {viewId &&
                                 convertedSavedView &&
                                 groupOptions &&
                                 customFields &&
                                 roleOptions && (
                                    <ProjectListDataTable
                                       fetchProjectList={fetchProjectList}
                                       groupId={groupId}
                                       currentDateTime={currentDateTime}
                                       tableApi={tableApi}
                                       handleTableReady={handleTableReady}
                                       customFields={customFields}
                                       groupOptions={groupOptions}
                                       updateProject={updateProject}
                                       savedView={convertedSavedView}
                                       roleOptions={roleOptions}
                                       isLastNameFirst={isLastNameFirst}
                                    />
                                 )}
                           </Box>
                        </DetailPage.Section>
                     </DetailPage.Card>
                  </DetailPage.Body>
               </DetailPage.Main>
            </DetailPage>
         </FileDownloadToastProvider>
      </ProjectTearsheetProvider>
   );
}

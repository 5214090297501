import "./edit-note-pane.styl"
import template from "./edit-note-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"
import ko from "knockout"

### Auth, Real-Time, Stores ###
import { NoteStore } from "@/stores/note-store.core"

### Models ###
import { Note } from "@/models/note"

export class EditNotePaneViewModel extends ModalPane
   constructor: (entityId, entityType, note) ->
      assertArgs(arguments, String, String, Note)
      super("Edit Note", "", template())
      @initialNote = note
      @editingNote = note.clone(Note)
      @entityType = entityType
      @entityId = entityId

      @isSaveEnabled = ko.computed =>
         checkAttachments = =>
            if @initialNote.attachments().length != @editingNote.attachments().length
               return true
            else
               initialAttachmentIds = @initialNote.attachments().map (attachment) ->
                  return attachment.id
               for attachment in @editingNote.attachments()
                  return true if initialAttachmentIds.indexOf(attachment.id) == -1
         return (checkAttachments()) or (@initialNote.content() != @editingNote.content()) or (@initialNote.isPrivate() != @editingNote.isPrivate())

   deleteNote: () =>
      if @entityType == "person"
         try
            await NoteStore.deleteNote(@editingNote.id, { person_id: @entityId }).payload
            modalManager.modalFinished()
         catch err
            return console.error("EditNotePaneViewPeople person deleteNote - Error: ", err)
      else if @entityType == "project"
         try
            await NoteStore.deleteNote(@editingNote.id, { project_id: @entityId }).payload
            modalManager.modalFinished()
         catch err
            return console.error("EditNotePaneViewPeople project deleteNote - Error: ", err)
      else
         modalManager.modalCancelled()

   saveNote: ->
      if @isSaveEnabled()
         @data.set("content", @editingNote.content()) if @editingNote.content()?
         @data.set("attachments", @editingNote.attachments()) if @editingNote.attachments()?
         @data.set("isPrivate", @editingNote.isPrivate()) if @editingNote.isPrivate()?
         modalManager.modalFinished() if Object.keys(@data).length > 0

import React from "react";
import { H1, Flex, Switch, Typography } from "@procore/core-react";

export const ShiftProjectToggle = (props: any) => {
   const { integration, toggleIntegrationShiftProject } = props;

   return (
      <>
         <H1>
            {I18n.t("views.company.workforce_planning.settings.integration_settings.shift_project")}
         </H1>
         <Flex>
            <Switch
               checked={integration?.shift_project}
               onChange={() => toggleIntegrationShiftProject(integration)}
            />
            <Typography style={{ paddingLeft: "10px" }}>
               {I18n.t(
                  "views.company.workforce_planning.settings.integration_settings.shift_project_detail",
               )}
            </Typography>
         </Flex>
      </>
   );
};

import "./list-view-export-modal-pane.styl"
import template from "./list-view-export-modal-pane.pug"
import { router } from "@/lib/router"
import { Icons } from "@/lib/managers/notification-manager"
import { Notification } from "@/lib/managers/notification-manager"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { GeneratedReportStore } from "@/stores/generated-report-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Notifications ###
import { GeneratedReportNotification } from "@/notifications/generated-report-notification"

## UI Assets ##
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import { ReportOptions } from "@/lib/reports/report-options"

State = {
   SELECTING: "selecting",
   DONE: "done",
}

ACTION_BUTTON_TEXT = "Export"

export class ListViewExportModalPane extends ModalPane
   constructor: (payload = {}, title, reportTargetPath, reportType) ->
      assertArgs(arguments, Object, String, String, String)
      super(title, ACTION_BUTTON_TEXT, template())
      @payload = payload
      @overflowable(true)
      @reportType = reportType
      @reportTargetPath = reportTargetPath
      @state = ko.observable(State.SELECTING)

      # Segmented Controller
      @exportOptions = [
         new SegmentedControllerItem("PDF", "pdf"),
         new SegmentedControllerItem("CSV", "csv"),
      ]

      @selectedExportOption = ko.observable(@exportOptions[0])

      @orientationOptions = ReportOptions.createOrientationOptions()
      @selectedOrientationOption = ko.observable(@orientationOptions[1])
      @pdfSizeOptions = ReportOptions.createPdfSizeOptions()
      @selectedPDFSizeOption = ko.observable(@pdfSizeOptions[0])

   actionBtnIntercept: () =>
      return if @state() != State.SELECTING
      orientation = @selectedOrientationOption().value()
      pageSize = @selectedPDFSizeOption().value()

      Object.assign(@payload, {
         report_type: @reportType
         file_type: @selectedExportOption().value()
         page_size: ({
            width: if orientation == "portrait" then pageSize.width else pageSize.height
            height: if orientation == "portrait" then  pageSize.height else pageSize.width
         })
      })

      @isActionEnabled(false)
      @setActionText("Exporting...")
      @createFromStore((err, report) =>
         if err
            @isActionEnabled(true)
            @setActionText(ACTION_BUTTON_TEXT)
            @state(State.SELECTING)
            return notificationManager.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "Unable to export report. Please try again or contact support.",
               })
            )
         @setActionText("")
         @state(State.DONE)
         return notificationManager.show(new GeneratedReportNotification(report))
      )

   navigateToReports: () =>
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/reports")
      modalManager.modalFinished()

   createFromStore: (callback) ->
      try
         payload = await GeneratedReportStore.create(@reportTargetPath, @payload).payload
         report = payload.data;
         report.name = ko.observable(report.name)
         callback(null, report)
      catch err
         return callback(err)


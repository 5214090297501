import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { ValidatePhoneNumberResponse } from "@laborchart-modules/lc-core-api/dist/api/twilio/validate-phone-number";
export abstract class TwilioStoreCore extends Store {
   static validateNumber(phoneNumber: string): StoreJsonResponse<ValidatePhoneNumberResponse> {
      return this.requestJson({
         url: `/api/v3/twilio/validate`,
         method: "GET",
         query: { number: phoneNumber },
      });
   }
}

import "./single-assignment-transfer-pane.styl"
import template from "./single-assignment-transfer-pane.pug"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"
import { authManager } from "@/lib/managers/auth-manager"
import { PermissionLevel } from "@/models/permission-level"

### Auth, Real-Time, & Stores ###
import { defaultStore } from "@/stores/default-store"
# Core-api Stores
import { Assignment2Store } from "@/stores/assignment-2-store.core"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

### Utils ###
import { getLastWorkDate } from "@/lib/utils/date-2";

{ WeekDayFormat, MonthFormat, YearFormat, DayFormat } = DateUtils
DATE_FORMAT_OPTIONS = {
   weekdayFormat: WeekDayFormat.ABBREV
   monthFormat: MonthFormat.FULL
   dayFormat: DayFormat.ONE_DIGIT
   yearFormat: YearFormat.FULL
}

export class SingleAssignmentTransferPane extends ModalPane
   constructor: (sourceAssignmentCard, newProjectInfo, transferDay, supportData, costingData) ->
      assertArgs(arguments, Object, Object, Number, Object, optional(Object))
      super("Transfer", null, template())

      @setTitle("Transfer #{sourceAssignmentCard.resourceTitle()}")
      @isActionEnabled(false)
      @overflowable = ko.observable(true)

      @costingData = ko.observable(costingData)
      @currentProjectHasStartDate = ko.observable(false)

      @sourceAssignmentCard = sourceAssignmentCard

      # Default to the assignment's current workdays
      @sundayOn = ko.observable(@sourceAssignmentCard.workDays()['0'])
      @mondayOn = ko.observable(@sourceAssignmentCard.workDays()['1'])
      @tuesdayOn = ko.observable(@sourceAssignmentCard.workDays()['2'])
      @wednesdayOn = ko.observable(@sourceAssignmentCard.workDays()['3'])
      @thursdayOn = ko.observable(@sourceAssignmentCard.workDays()['4'])
      @fridayOn = ko.observable(@sourceAssignmentCard.workDays()['5'])
      @saturdayOn = ko.observable(@sourceAssignmentCard.workDays()['6'])

      @startingWorkDays = {
         0: @sundayOn()
         1: @mondayOn()
         2: @tuesdayOn()
         3: @wednesdayOn()
         4: @thursdayOn()
         5: @fridayOn()
         6: @saturdayOn()
      }

      @oldTruncatingDate = ko.observable(null)
      @oldTruncatingDate.subscribe((newVal) =>
         if !@startingWorkDays[newVal.getDay()]
            @displayingNotice(SingleAssignmentTransferPane.Notice.OLD_END_DAY_NOT_ACTIVE)
      )
      lastWorkDate = getLastWorkDate({
         fromDate: DateUtils.getAttachedDate(transferDay),
         workDays: @startingWorkDays,
      })
      @oldTruncatingDate(lastWorkDate)

      @projectOptions = ko.unwrap(supportData.projectOptions)
      @selectedProject = ko.observable(
         @projectOptions.find((projectOption) => return projectOption.value() == newProjectInfo.projectId) ? null
      )

      @selectedProject.subscribe (newVal) =>
         @selectedCostCode(null)
         @selectedLabel(null)
         if newVal.baggage()?.start_date?
            @currentProjectHasStartDate(true)
         else
            @currentProjectHasStartDate(false)

         if newVal.baggage()?.est_end_date?
            endOptions = @endTypeOptions()
            endOptions[2] = projectEndOption
            @endTypeOptions(endOptions)
            if @selectedEndType()?.value() == "tbd"
               @selectedEndType(projectEndOption)
            @tbdEndDateString(DateUtils.formatDate(new Date(newVal.baggage().est_end_date)), defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
         else
            endOptions = @endTypeOptions()
            endOptions[2] = tbdEndOption
            @endTypeOptions(endOptions)
            if @selectedEndType()?.value() == "project-end"
               @selectedEndType(tbdEndOption)
            tbdWeeks = supportData.companyTbdWeeks
            if @assignmentStartDate()?
               calculatedDate = DateUtils.incrementDate(@assignmentStartDate(), (tbdWeeks * 7))
               calculatedDate = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
               @tbdEndDateString("#{calculatedDate} - #{tbdWeeks} Weeks")
            else
               @tbdEndDateString("#{tbdWeeks} Weeks Out")

         if newVal.baggage()?.daily_start_time?
            @selectedStartTime(newVal.baggage().daily_start_time)

         if newVal.baggage()?.daily_end_time?
            @selectedEndTime(newVal.baggage().daily_end_time)

      @statusOptions = ko.unwrap(supportData.statusOptions)
      @selectedStatus = ko.observable(
         @statusOptions.find((statusOption) => return statusOption.value() == @sourceAssignmentCard.status()?.id) ? null
      )
      @canViewAllStatuses = authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES)

      @showingCostCode = ko.observable(false)
      @showingLabel = ko.observable(false)

      @groupedCostCodeOptions = supportData.groupedCostCodeOptions

      @filteredCostCodeOptions = ko.pureComputed =>
         return [] unless ko.unwrap(@groupedCostCodeOptions)? and @selectedProject()?
         return ko.unwrap(@groupedCostCodeOptions)[@selectedProject().id].filter (code) ->
            return !code.baggage().archived

      if @selectedProject()? && newProjectInfo.costCodeId?
         @selectedCostCode = ko.observable(
            @filteredCostCodeOptions().find((costCodeOption) => return costCodeOption.value() == newProjectInfo.costCodeId) ? null
         )
         @showingCostCode(true)
      else
         @selectedCostCode = ko.observable(null)

      @filteredCostCodeOptions.subscribe =>
         if newProjectInfo.costCodeId?
            foundMatch = false
            for option in @filteredCostCodeOptions()
               if option.value() == newProjectInfo.costCodeId
                  @showingCostCode(true)
                  @selectedCostCode(option)
                  foundMatch = true
                  break
            unless foundMatch
               @showingCostCode(false)

      @groupedLabelOptions = supportData.groupedLabelOptions

      @filteredLabelOptions = ko.pureComputed =>
         return [] unless ko.unwrap(@groupedLabelOptions)? and @selectedProject()? and @selectedCostCode()?
         return ko.unwrap(@groupedLabelOptions)[@selectedCostCode().value()].filter (label) ->
            return !label.baggage().archived

      if @selectedProject()? && @selectedCostCode()? && newProjectInfo.labelId?
         @selectedLabel = ko.observable(
            @filteredLabelOptions().find((labelOption) => return labelOption.value() == newProjectInfo.labelId) ? null
         )
         @showingLabel(true)
      else
         @selectedLabel = ko.observable(null)

      @filteredLabelOptions.subscribe =>
         if newProjectInfo.labelId?
            foundMatch = false
            for option in @filteredLabelOptions()
               if option.value() == newProjectInfo.labelId
                  @showingLabel(true)
                  @selectedLabel(option)
                  foundMatch = true
                  break
            unless foundMatch
               @showingLabel(false)

      @tbdEndDateString = ko.observable("Select Start Date...")

      @assignmentStartDate = ko.observable(DateUtils.getAttachedDate(transferDay))
      @assignmentStartDate.subscribe (newVal) =>
         if @selectedEndType().value() == "tbd" and newVal?
            tbdWeeks = supportData.companyTbdWeeks
            calculatedDate = DateUtils.incrementDate(newVal, (tbdWeeks * 7))
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
            @tbdEndDateString("#{calculatedDateString} - #{tbdWeeks} Weeks")

      @projectStartDateString = ko.observable()
      @startTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Date", "date")
         new SegmentedControllerItem("Project Start", "project-start")
      ])
      @selectedStartType = ko.observable(@startTypeOptions()[0])
      @selectedStartType.subscribe (newVal) =>
         return unless newVal?.value() == "project-start"

         if @selectedProject()?.baggage()?.start_date?
            calculatedDate = new Date(@selectedProject().baggage().start_date)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
            @assignmentStartDate(calculatedDate)
            @projectStartDateString(calculatedDateString)

      tbdEndOption = new SegmentedControllerItem("TBD", "tbd")
      projectEndOption = new SegmentedControllerItem("Project End", "project-end")
      @endTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Date", "date")
         new SegmentedControllerItem("Weeks", "weeks")
         tbdEndOption
      ])
      @selectedEndType = ko.observable(@endTypeOptions()[0])
      @selectedEndType.subscribe (newVal) =>
         return unless newVal?.value() == "tbd" or newVal?.value() == "project-end"

         if newVal.value() == "tbd" and @assignmentStartDate()?
            tbdWeeks = supportData.companyTbdWeeks
            dayStep = if tbdWeeks >= 1 then (((tbdWeeks - 1) * 7) + 6) else (tbdWeeks * 7)
            dayStep = Math.round(dayStep)
            calculatedDate = DateUtils.incrementDate(@assignmentStartDate(), dayStep)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
            @assignmentEndDate(calculatedDate)
            @tbdEndDateString("#{calculatedDateString} - #{tbdWeeks} Weeks")
         else if newVal.value() == "project-end" and @selectedProject()?.baggage()?.est_end_date?
            calculatedDate = new Date(@selectedProject().baggage().est_end_date)
            calculatedDateString = DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)
            @assignmentEndDate(calculatedDate)
            @tbdEndDateString(calculatedDateString)

      @assignmentEndDate = ko.observable(DateUtils.getAttachedDate(sourceAssignmentCard.endDay()))
      @numberOfWeeks = ko.observable(null)
      @weeksCalculatedDateString = ko.pureComputed =>
         return "Enter # of weeks" unless @numberOfWeeks()? and Number(@numberOfWeeks()) > 0
         return "Select Start Date" unless @assignmentStartDate()?
         weeks = Number(@numberOfWeeks())
         dayStep = if weeks >= 1 then (((weeks - 1) * 7) + 6) else (weeks * 7)
         dayStep = Math.round(dayStep)
         calculatedDate = DateUtils.incrementDate(@assignmentStartDate(), dayStep)
         @assignmentEndDate(calculatedDate)
         return DateUtils.formatDate(calculatedDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS)

      @selectedStartTime = ko.observable(newProjectInfo.startTime)
      @selectedEndTime = ko.observable(newProjectInfo.endTime)

      @allocationOptions = [
         new SegmentedControllerItem("Work Hours", "hours")
         new SegmentedControllerItem("Percent", "percent")
      ]
      @selectedAllocationOption = ko.observable(@allocationOptions[0])
      if @sourceAssignmentCard.percentAllocated()?
         @selectedAllocationOption(@allocationOptions[1])
      @selectedAllocationOption.subscribe (newVal) =>
         if newVal.value() == "percent"
            @overtime(false)

      @allocationIsHours = ko.pureComputed =>
         return @selectedAllocationOption().value() == "hours"

      @percentAllocated = ko.observable(@sourceAssignmentCard.percentAllocated())

      @assignmentHourDuration = ko.pureComputed =>
         return '' unless (@allocationIsHours() and
         @selectedStartTime()? and @selectedEndTime()?)

         startTime = @selectedStartTime()
         endTime = @selectedEndTime()
         if startTime < endTime
            return endTime - startTime
         else if startTime > endTime
            # Overnight
            return endTime + (24 - startTime)
         else
            return ''

      @allowOvertimeControls = ko.pureComputed =>
         return ko.unwrap(@costingData()?.overtimeDayRates)?

      @straightHours = ko.observable()
      @overtimeHours = ko.observable()
      @unpaidHours = ko.observable()

      @paySplitInvalid = ko.pureComputed =>
         accountedHours = Number(@straightHours()) + Number(@overtimeHours()) + Number(@unpaidHours())
         if @selectedStartTime() < @selectedEndTime()
            hoursDuration = @selectedEndTime() - @selectedStartTime()
         else
            hoursDuration = @selectedEndTime() + (24 - @selectedStartTime())

         return !(accountedHours == hoursDuration)

      @overtime = ko.observable(false)
      @overtime.subscribe () =>
         # TODO: Make sure this doesn't override actuals from editing.
         @calculatePaySplit()

      @sundayOtRate = ko.observable()
      @mondayOtRate = ko.observable()
      @tuesdayOtRate = ko.observable()
      @wednesdayOtRate = ko.observable()
      @thursdayOtRate = ko.observable()
      @fridayOtRate = ko.observable()
      @saturdayOtRate = ko.observable()

      overtimeDayRates = ko.unwrap(costingData?.overtimeDayRates)
      if overtimeDayRates?
         @sundayOtRate(overtimeDayRates.sunday)
         @mondayOtRate(overtimeDayRates.monday)
         @tuesdayOtRate(overtimeDayRates.tuesday)
         @wednesdayOtRate(overtimeDayRates.wednesday)
         @thursdayOtRate(overtimeDayRates.thursday)
         @fridayOtRate(overtimeDayRates.friday)
         @saturdayOtRate(overtimeDayRates.saturday)

      oldBatchStart = DateUtils.formatDetachedDay(@sourceAssignmentCard.startDay(), defaultStore.getDateFormat())
      oldBatchEnd = DateUtils.formatDetachedDay(@sourceAssignmentCard.endDay(), defaultStore.getDateFormat())
      @transferDisclaimer = ko.pureComputed =>
         if DateUtils.getDetachedDay(@oldTruncatingDate()) < @sourceAssignmentCard.startDay()
            return "The previous assignment from #{oldBatchStart} to #{oldBatchEnd} will be completly removed."
         else if DateUtils.getDetachedDay(@oldTruncatingDate()) >= @sourceAssignmentCard.endDay()
            return "The previous assignment from #{oldBatchStart} to #{oldBatchEnd} will remain as is."
         else
            return "The previous assignment from #{oldBatchStart} to #{oldBatchEnd} will now end at the date below."

      @oldAssignmentWillBeTruncated = ko.observable(true)

      # Set inbound project
      for option in @projectOptions
         if option.id == newProjectInfo.projectId
            @selectedProject(option)
            break

      @isSaving = ko.observable(false)
      @canSave = ko.pureComputed =>
         return false if @isSaving()
         return (@selectedProject()? and
         (@sundayOn() or @mondayOn() or @tuesdayOn() or @wednesdayOn() or @thursdayOn() or @fridayOn() or @saturdayOn()) and
         @assignmentStartDate()? and
         @assignmentEndDate()? and
         @selectedStartTime()? and
         @selectedEndTime()? and
         @isOnWorkDay() and
         @startingWorkDays[@oldTruncatingDate().getDay()]
         )

   isOnWorkDay: () ->
      activeWorkDays = {
         0: @sundayOn()
         1: @mondayOn()
         2: @tuesdayOn()
         3: @wednesdayOn()
         4: @thursdayOn()
         5: @fridayOn()
         6: @saturdayOn()
      }
      startWorkDay = @assignmentStartDate().getDay()
      endWorkDay = @assignmentEndDate().getDay()
      return activeWorkDays[startWorkDay] && activeWorkDays[endWorkDay]

   toggleCostCodeVisibility: ->
      # return if @detailsDisabled()
      @showingCostCode(!@showingCostCode())

   toggleLabelVisibility: ->
      # return if @detailsDisabled()
      @showingLabel(!@showingLabel())

   calculatePaySplit: =>
      return unless @overtime() and @selectedStartTime()? and @selectedEndTime()?
      if @selectedStartTime() < @selectedEndTime()
         hoursDuration = @selectedEndTime() - @selectedStartTime()
      else
         hoursDuration = @selectedEndTime() + (24 - @selectedStartTime())

      if hoursDuration <= (ko.unwrap(@costingData().paidShiftHours) + .5)
         @overtimeHours(0)
         if hoursDuration <= ko.unwrap(@costingData().paidShiftHours)
            @straightHours(hoursDuration)
            @unpaidHours(0)
         else
            @straightHours(ko.unwrap(@costingData().paidShiftHours))
            @unpaidHours(hoursDuration - ko.unwrap(@costingData().paidShiftHours))
      else
         @straightHours(ko.unwrap(@costingData().paidShiftHours))
         @unpaidHours(.5)
         @overtimeHours(hoursDuration - (ko.unwrap(@costingData().paidShiftHours) + .5))

   # TODO: Pull this our into a shared utility
   computeInstances_: (startDate, endDate, workDays, next) ->
      instances = []
      endDay = DateUtils.getDetachedDay(endDate)

      processInstance = (instanceStartDate) ->
         validDetachedDays = []
         processingDate = instanceStartDate
         foundInstanceStart = false
         processingInstance = true
         processingDay = null

         while processingInstance
            working = workDays[processingDate.getDay()]
            if !working and foundInstanceStart
               processingInstance = false
               break
            else if working and !foundInstanceStart
               foundInstanceStart = true

            processingDay = DateUtils.getDetachedDay(processingDate)
            validDetachedDays.push(processingDay) if working

            processingDate = DateUtils.incrementDate(processingDate, 1)
            if processingDay >= endDay
               processingInstance = false
               break

         if validDetachedDays.length > 0
            instances.push({
               start_day: validDetachedDays[0]
               end_day: validDetachedDays[validDetachedDays.length - 1]
               days: validDetachedDays
            })

         if DateUtils.getDetachedDay(processingDate) > endDay
            next(instances)
         else
            return processInstance(processingDate)


      processInstance(new Date(startDate.getTime()))

   deleteAssignment: (callback) ->
      try
         await Assignment2Store.deleteAssignment(@sourceAssignmentCard.id).payload
         callback(null)
      catch err
         return callback(err)

   updateAssignment: (updateData, callback) ->
      try
         result = await Assignment2Store.updateSingleAssignment({
            assignmentId: @sourceAssignmentCard.id,
            update: {
               ...updateData,
            }
         }).payload
         callback(null, result.data)
      catch err
         return callback(err)

   createAssignment: (newBatch, callback) ->
      transformedNewBatch = {
         ...newBatch,
         category_id: newBatch.cost_code_id,
         custom_fields: {},
         subcategory_id: newBatch.label_id,
         status_id: newBatch.status_id,
      }
      try
         result = await Assignment2Store.createAssignment(transformedNewBatch).payload
         callback(null, result.data)
      catch err
         return callback(err)

   save: ->
      return unless @canSave()
      @displayingNotice(SingleAssignmentTransferPane.Notice.SAVING)

      activeWorkDays = {
         0: @sundayOn()
         1: @mondayOn()
         2: @tuesdayOn()
         3: @wednesdayOn()
         4: @thursdayOn()
         5: @fridayOn()
         6: @saturdayOn()
      }
      startWorkDay = @assignmentStartDate().getDay()
      endWorkDay = @assignmentEndDate().getDay()
      return @displayingNotice(SingleAssignmentTransferPane.Notice.START_DAY_NOT_ACTIVE) unless activeWorkDays[startWorkDay]
      return @displayingNotice(SingleAssignmentTransferPane.Notice.END_DAY_NOT_ACTIVE) unless activeWorkDays[endWorkDay]
      if @assignmentStartDate() > @assignmentEndDate()
         return @displayingNotice(SingleAssignmentTransferPane.Notice.END_DAY_IS_BEFORE_START_DAY)

      @isSaving(true)

      doneReplacing = false
      doneCreating = false

      if DateUtils.getDetachedDay(@oldTruncatingDate()) >= @sourceAssignmentCard.endDay()
         # Nothing to change
         doneReplacing = true
      else if DateUtils.getDetachedDay(@oldTruncatingDate()) < @sourceAssignmentCard.startDay()
         # Remove the old assignment completely.
         @deleteAssignment((err) =>
            return @onRequestError(err) if err
            doneReplacing = true
            if doneCreating
               # TODO: Send some data back to the board to update view.
               @isSaving(false)
               modalManager.modalFinished()
         )
      else
         # OLD
         updateData = {end_day: DateUtils.getDetachedDay(@oldTruncatingDate())}

         @updateAssignment(updateData, (err, updatedAssignment) =>
            return @onRequestError(err) if err
            @data.set('replacementBatch', updatedAssignment)
            doneReplacing = true
            if doneCreating
               # TODO: Send some data back to the board to update view.
               @isSaving(false)
               modalManager.modalFinished()
         )

      # NEW
      newBatch = {
         project_id: @selectedProject().id
         cost_code_id: if @selectedCostCode()? then @selectedCostCode().value() else null
         label_id: if @selectedLabel()? then @selectedLabel().value() else null
         resource_id: ko.unwrap(@sourceAssignmentCard.resourceId)
         start_day: DateUtils.getDetachedDay(@assignmentStartDate())
         end_day: DateUtils.getDetachedDay(@assignmentEndDate())
         start_time: @selectedStartTime()
         end_time: @selectedEndTime()
         work_days: {
            0: @sundayOn()
            1: @mondayOn()
            2: @tuesdayOn()
            3: @wednesdayOn()
            4: @thursdayOn()
            5: @fridayOn()
            6: @saturdayOn()
         }
         status_id: if @selectedStatus()? then @selectedStatus().value() else null
         overtime: @overtime()
      }

      if @allocationIsHours()
         newBatch['start_time'] = @selectedStartTime()
         newBatch['end_time'] = @selectedEndTime()
         newBatch['percent_allocated'] = null
      else
         newBatch['start_time'] = null
         newBatch['end_time'] = null
         newBatch['percent_allocated'] = Number(@percentAllocated())

      if @overtime()
         newBatch['pay_split'] = {
            straight: Number(@straightHours())
            overtime: Number(@overtimeHours())
            unpaid: Number(@unpaidHours())
         }

         # Only save rates if different than defaults.
         if (Number(@sundayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).sunday or
         Number(@mondayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).monday or
         Number(@tuesdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).tuesday or
         Number(@wednesdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).wednesday or
         Number(@thursdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).thursday or
         Number(@fridayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).friday or
         Number(@saturdayOtRate()) != ko.unwrap(@costingData().overtimeDayRates).saturday)
            newBatch['overtime_rates'] = {
               "0": Number(@sundayOtRate())
               "1": Number(@mondayOtRate())
               "2": Number(@tuesdayOtRate())
               "3": Number(@wednesdayOtRate())
               "4": Number(@thursdayOtRate())
               "5": Number(@fridayOtRate())
               "6": Number(@saturdayOtRate())
                  }

      @createAssignment(newBatch, (err, newAssignment) =>
         return @onRequestError(err) if err

         @data.set('newBatch', {
               batchId: newAssignment.id
               startDay: newAssignment.start_day
               endDay: newAssignment.end_day
            })
         @data.set('notifyBatchId', newAssignment.id)

         doneCreating = true
         if doneReplacing
            # TODO: Send some data back to the board to update view.
            @isSaving(false)
            modalManager.modalFinished()
      )

   saveAndNotify: =>
      return unless @canSave()
      @data.set('notify', true)
      @data.set('notifyProjectId', @selectedProject().id)
      @data.set('notifyResourceId', ko.unwrap(@sourceAssignmentCard.resourceId))
      @data.set('notifyContext', "assignment-transfer")

      @save()

   onRequestError: (err) ->
      console.log "Error: ", err
      message = err.responseJSON?.message
      @displayingNotice({
         ...SingleAssignmentTransferPane.Notice.REQUEST_ERROR
         text: message or SingleAssignmentTransferPane.Notice.REQUEST_ERROR.text
      })
      @isSaving(false)

SingleAssignmentTransferPane.Notice = {
   SAVING: {
      text: 'Saving...'
      info: null
      color: 'green'
      dissmissable: false
   }
   START_DAY_NOT_ACTIVE: {
      text: 'Your start day needs to be set as an active workday.'
      info: null
      color: 'red'
      dissmissable: true
   }
   END_DAY_NOT_ACTIVE: {
      text: 'Your end day needs to be set as an active workday.'
      info: null
      color: 'red'
      dissmissable: true
   }
   OLD_END_DAY_NOT_ACTIVE: {
      text: 'Your old assignment\'s end day needs to be set as an active workday.'
      info: null
      color: 'red'
      dissmissable: true
   }
   END_DAY_IS_BEFORE_START_DAY: {
      text: 'End Day cannot be before Start Day.'
      info: null
      color: 'red'
      dissmissable: true
   }
   REQUEST_ERROR: {
      text: 'Something went wrong, please try again.',
      info: null,
      color: 'red',
      dissmissable: true,
   }
}
SingleAssignmentTransferPane.DynamicNotice = {
   DATE_TOO_EARLY: (startDate) ->
      return {
         text: "Dates must be on or after the original assignment's start date (#{startDate})"
         info: null
         color: 'red'
         dissmissable: true
      }
}

import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { SerializedNotificationProfile } from "@laborchart-modules/common/dist/rethink/serializers/notification-profile-serializer";
import type { FindNotificationProfilesQueryParams } from "@laborchart-modules/lc-core-api/dist/api/notification-profiles/find-notification-profiles";
import type { GetNotificationProfileResponse } from "@laborchart-modules/lc-core-api/dist/api/notification-profiles/get-notification-profile";

export abstract class NotificationProfileStore extends Store {
   static getNotificationProfile(
      notificationProfileId: string,
   ): StoreJsonResponse<GetNotificationProfileResponse> {
      return this.requestJson({
         url: `/api/v3/notification-profiles/${notificationProfileId}`,
         method: "GET",
      });
   }

   static findNotificationProfilesStream(
      query: FindNotificationProfilesQueryParams,
   ): StoreStreamResponse<SerializedNotificationProfile> {
      return this.requestStream({
         url: "/api/v3/notification-profiles/stream",
         method: "GET",
         query,
      });
   }
}

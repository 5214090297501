import type { ReactElement } from "react";
import React from "react";
import { withDataTableRenderer } from "@procore/data-table";
import type { DataTableCellRendererProps, ColumnDefinition } from "@procore/data-table";
import type { DropdownFlyoutOption } from "@procore/core-react";
import { DropdownFlyout, useI18nContext, Button } from "@procore/core-react";
import { EllipsisVertical } from "@procore/core-icons";

interface CustomActionCellRendererParams {
   onDeleteRow: (params: any) => void;
   onAddRowAbove: (params: any) => void;
   onAddRowBelow: (params: any) => void;
   onCopyRow: (params: any) => void;
}

export type CustomActionColumnDefinition = ColumnDefinition<
   string,
   typeof CustomActionCellRenderer,
   CustomActionCellRendererParams
>;

export type CustomActionCellRendererProps =
   DataTableCellRendererProps<CustomActionColumnDefinition>;

export const CustomActionCell = (props: CustomActionCellRendererProps): ReactElement => {
   const { data, columnDefinition } = props;
   const { onDeleteRow, onCopyRow, onAddRowAbove, onAddRowBelow } =
      columnDefinition.cellRendererParams;
   const I18n = useI18nContext();
   const options: DropdownFlyoutOption[] = [
      {
         label: I18n.t("views.company.workforce_planning.copy"),
         value: "copy",
      },
      {
         label: I18n.t("views.company.workforce_planning.labor_plan.add_above"),
         value: "add_above",
      },
      {
         label: I18n.t("views.company.workforce_planning.labor_plan.add_below"),
         value: "add_below",
      },
      {
         label: I18n.t("views.company.workforce_planning.delete"),
         value: "delete",
      },
   ];

   const handleOptionClick = (params: DropdownFlyoutOption) => {
      if (params.value == "copy") {
         onCopyRow(data);
      } else if (params.value == "add_above") {
         onAddRowAbove(data);
      } else if (params.value == "add_below") {
         onAddRowBelow(data);
      } else if (params.value == "delete") {
         onDeleteRow(data);
      }
   };
   return (
      <div key={data.id}>
         <DropdownFlyout
            options={options}
            onClick={(params: DropdownFlyoutOption) => handleOptionClick(params)}
            key={data.id}
         >
            <Button
               icon={<EllipsisVertical />}
               variant="tertiary"
               data-testid="custom-action-cell-button"
            />
         </DropdownFlyout>
      </div>
   );
};

export const CustomActionCellRenderer = withDataTableRenderer(CustomActionCell, "select");

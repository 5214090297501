import "./view-paragraph-pane.styl"
import template from "./view-paragraph-pane.pug"
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

export class ViewParagraphPane extends ModalPane
   constructor: (title, paragraphText) ->
      super(title, "", template())

      @paragraphText = paragraphText.replace(/\n/g, "<br />")

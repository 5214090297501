import template from "./link-to-procore-cell.pug";
import ko from "knockout";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import renderReactComponent from "@/react/render-react-component";
import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";

export type LinkToProcoreCellParams = {
   entityType: "person" | "project";
   entityId: string;
   procoreId: string | null;
   procoreTargetType?: string;
   searchTerm?: string;
};

export class LinkToProcoreCell {
   constructor(readonly params: LinkToProcoreCellParams) {
      const procoreId = params.procoreId;
      if (procoreId == null) {
         return;
      }

      // Wait for the next tick of the event loop to ensure the DOM element is present.
      setTimeout(() => {
         renderReactComponent(`link-id-${params.entityId}`, "LinkToProcoreEntity", {
            entityType: params.entityType,
            procoreEntityId: procoreId,
            procoreCompanyId: getProcoreCompanyIdFromURL(),
            procoreTargetType: params.procoreTargetType,
            searchTerm: params.searchTerm,
         });
      }, 0);
   }

   static factory<T>(
      formatter: (data: T) => LinkToProcoreCellParams,
   ): GridCellFactory<T, LinkToProcoreCellParams> {
      return (data: T): GridCell<LinkToProcoreCellParams> => {
         return {
            component: {
               name: "link-to-procore-cell",
               params: formatter(data),
            },
            height: 16,
         };
      };
   }
}

ko.components.register("link-to-procore-cell", {
   viewModel: LinkToProcoreCell,
   template: template(),
   synchronous: true,
});

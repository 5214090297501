import "./request-scope-of-work-pane.styl";
import template from "./request-scope-of-work-pane.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { MaybeComputed } from "knockout";
import ko, { unwrap, pureComputed } from "knockout";
import type { CanRequestSize } from "@/lib/components/modals/common/sized-modal";
import type { ComponentArgs } from "@/lib/components/common";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { StagedRequest } from "../../request-details-modal";

export type RequestScopeOfWorkPaneParams = {
   request: MaybeComputed<SerializedFindRequest | StagedRequest>;
   updateWorkScopeText: (text: string | null) => void;
} & CanRequestSize;

export class RequestScopeOfWorkPane extends ViewModel implements CanRequestSize {
   readonly request: MaybeComputed<SerializedFindRequest | StagedRequest>;
   readonly updateWorkScopeText: (text: string | null) => void;

   readonly requestSize: CanRequestSize["requestSize"];

   readonly description = `
      The text you add here will be pulled into the
      Assignment Alert recieved by the person who gets assigned to this
      Request under the heading "Scope of Work".
      `;
   readonly placeholderText = "Describe scope of work here...";

   readonly workScopeText = pureComputed({
      read: () => unwrap(this.request).work_scope_text,
      write: (text: string | null) => {
         this.updateWorkScopeText(text != null && text.length > 0 ? text : null);
      },
   });

   constructor({ request, updateWorkScopeText, requestSize }: RequestScopeOfWorkPaneParams) {
      super(template());
      this.request = request;
      this.requestSize = requestSize;
      this.updateWorkScopeText = updateWorkScopeText;
   }

   static factory(
      params: RequestScopeOfWorkPaneParams,
   ): ComponentArgs<RequestScopeOfWorkPaneParams> {
      return {
         name: "request-scope-of-work-pane",
         params,
      };
   }
}

ko.components.register("request-scope-of-work-pane", {
   viewModel: RequestScopeOfWorkPane,
   template: template(),
   synchronous: true,
});

import "./disabled-editor.styl";
import type { MaybeObservable, Observable } from "knockout";
import ko, { isObservable, observable, pureComputed } from "knockout";
import template from "./disabled-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";

export interface DisabledEditorParams {
   text: MaybeObservable<string>;
   width?: MaybeObservable<number>;
}

export type FullDisabledEditorParams = DisabledEditorParams & EditorComponentParams<null>;

const DEFAULT_WIDTH = 224;
const HORIZONTAL_MARGIN = 24;

export class DisabledEditor {
   readonly text: Observable<string>;
   readonly width: Observable<number>;

   readonly visibleWidth = pureComputed(() => {
      return `${this.width() - HORIZONTAL_MARGIN}px`;
   });

   constructor(private readonly params: FullDisabledEditorParams) {
      this.text = isObservable(this.params.text)
         ? this.params.text
         : (observable(this.params.text || "") as Observable<string>);
      this.width = isObservable(this.params.width)
         ? this.params.width
         : (observable(this.params.width || DEFAULT_WIDTH) as Observable<number>);
   }

   onClose = (): void => {
      if (this.params.onClose) this.params.onClose({ hasSaved: false });
   };

   static create(params: DisabledEditorParams): ComponentArgs<FullDisabledEditorParams> {
      return {
         name: "disabled-editor",
         params: {
            saveProvider: async () => {},
            title: null,
            value: null,
            ...params,
         },
      };
   }

   static factory<T>(
      provider: (records: T[]) => DisabledEditorParams,
   ): (records: T[]) => ComponentArgs<FullDisabledEditorParams> {
      return (records) => DisabledEditor.create(provider(records));
   }
}

ko.components.register("disabled-editor", {
   viewModel: DisabledEditor,
   template: template(),
   synchronous: true,
});

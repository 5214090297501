import "./checkbox-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./checkbox-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export interface CheckboxEditorParams extends EditorComponentParams<boolean | null> {
   label?: string | MaybeSubscribable<string>;
   isClearable?: MaybeSubscribable<boolean>;
}

export class CheckboxEditor extends AbstractFieldEditor<boolean | null> {
   readonly label = observable<string>();

   constructor(readonly params: CheckboxEditorParams) {
      super(params, observable(unwrap(params.value)));
      // Immediately set the value to `false` if it's null to still enforce the
      // value being different in order to save but not visually showing the `null`
      // state.
      if (this.value() == null) this.value(false);
      if (params.label) this.label(unwrap(params.label));
   }

   validate(value: boolean | null): ValidatedValue<boolean | null> {
      return { valid: true, value };
   }

   onDelete = (): Promise<void> => {
      return this.save(null);
   };

   static factory<T>(
      provider: (records: T[]) => CheckboxEditorParams,
   ): (records: T[]) => ComponentArgs<CheckboxEditorParams> {
      return (records) => ({
         name: "checkbox-editor",
         params: provider(records),
      });
   }
}

ko.components.register("checkbox-editor", {
   viewModel: CheckboxEditor,
   template: template(),
   synchronous: true,
});

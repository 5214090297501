import React from "react";
import { Modal, Button, useI18nContext } from "@procore/core-react";

export type DiscardChangesDialoguePropTypes = {
   open: boolean;
   header: string;
   body: string;
   onClose: () => void;
   onConfirm: () => void;
};

export const DiscardChangesDialogue = (props: DiscardChangesDialoguePropTypes) => {
   const { open, onClose, onConfirm, header, body } = props;
   const I18n = useI18nContext();
   return (
      <Modal howToClose={["x", "footer-button"]} open={open} onClose={() => onClose()}>
         <Modal.Header>{header}</Modal.Header>
         <Modal.Body>{body}</Modal.Body>
         <Modal.Footer>
            <Modal.FooterButtons>
               <Button variant="primary" onClick={() => onConfirm()}>
                  {I18n.t("views.company.workforce_planning.leave_without_saving")}
               </Button>
            </Modal.FooterButtons>
         </Modal.Footer>
      </Modal>
   );
};

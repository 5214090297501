import { Model } from "@/models/model"
import { observable } from "knockout"

export class Status extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.abbreviation, nullable(String))
         assertOfType(data.color, String)
         assertOfType(data.sequence, nullable(Number))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @name = observable(data.name)
      @abbreviation = observable(data.abbreviation ? "")
      @color = observable(data.color)
      @sequence = observable(data.sequence)

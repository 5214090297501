import template from "./overtime-checkbox-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";

export type OvertimeCheckboxEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   isDisabled: boolean;
   value: PureComputed<boolean>;
} & EditorElementParams<boolean>;

export class OvertimeCheckboxEditorElement extends ViewModel {
   readonly invalidInputMessage: OvertimeCheckboxEditorElementParams["invalidInputMessage"];
   readonly isDisabled: OvertimeCheckboxEditorElementParams["isDisabled"];
   readonly onChange: OvertimeCheckboxEditorElementParams["onChange"];
   readonly title: OvertimeCheckboxEditorElementParams["title"];
   readonly value: OvertimeCheckboxEditorElementParams["value"];

   readonly boolFieldMediator = pureComputed<boolean>({
      read: () => unwrap(this.value),
      write: (value) => {
         this.onChange(value);
      },
   });

   constructor({
      invalidInputMessage,
      isDisabled,
      onChange,
      title,
      value,
   }: OvertimeCheckboxEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.isDisabled = isDisabled;
      this.onChange = onChange;
      this.title = title;
      this.value = value;
   }

   static factory(
      params: OvertimeCheckboxEditorElementParams,
   ): ComponentArgs<OvertimeCheckboxEditorElementParams> {
      return {
         name: "overtime-checkbox-editor-element",
         params,
      };
   }
}

ko.components.register("overtime-checkbox-editor-element", {
   viewModel: OvertimeCheckboxEditorElement,
   template: template(),
   synchronous: true,
});

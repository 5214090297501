import "./currency-editor.styl";
import type { MaybeSubscribable, Observable, Subscription } from "knockout";
import ko, { observable, unwrap } from "knockout";
import template from "./currency-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export interface CurrencyEditorParams extends EditorComponentParams<number | null> {
   isRequired?: MaybeSubscribable<boolean>;
   minVal?: number;
   isClearable?: boolean;
}

export class CurrencyEditor extends AbstractFieldEditor<number | null> {
   readonly inputValue: Observable<string | null>;
   readonly isClearable: boolean;

   private readonly subscription: Subscription;

   constructor(private readonly params: CurrencyEditorParams) {
      super(params, observable(unwrap(params.value)));
      this.inputValue = observable(this.value() != null ? String(this.value()) : null);
      this.isClearable = params.isClearable ?? false;
      this.subscription = this.inputValue.subscribe((value) => {
         const valueAsNumber = Number(value?.replace(/,/g, ""));
         this.value(value == "" || value == null || isNaN(valueAsNumber) ? null : valueAsNumber);
      });
   }

   validate(value: number | null): ValidatedValue<number | null> {
      const isRequired = unwrap(this.params.isRequired);
      const valueAsNumber = Number(this.inputValue()?.replace(/,/g, ""));
      const isEmpty = this.inputValue() == null || this.inputValue() == "";
      if (isEmpty && isRequired) {
         return { valid: false, error: `${this.title} is required.` };
      }
      if (isNaN(valueAsNumber) && !isEmpty) {
         return { valid: false, error: `${this.title} must be a number.` };
      }
      if (this.params.minVal && !isEmpty && valueAsNumber < this.params.minVal) {
         return {
            valid: false,
            error: `${this.title} must be greater than or equal to $${this.params.minVal}`,
         };
      }

      return { valid: true, value };
   }

   dispose = (): void => {
      this.subscription.dispose();
   };

   onDelete = (): Promise<void> => {
      return this.save(null);
   };

   static factory<T>(
      provider: (records: T[]) => CurrencyEditorParams,
   ): (records: T[]) => ComponentArgs<CurrencyEditorParams> {
      return (records) => ({
         name: "currency-editor",
         params: provider(records),
      });
   }
}

ko.components.register("currency-editor", {
   viewModel: CurrencyEditor,
   template: template(),
   synchronous: true,
});

import ko from "knockout"
import "./create-canned-message-pane.styl"
import template from "./create-canned-message-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput
import { v4 } from "uuid";

### Auth, Real-Time & Stores ###
import { MessageStore } from "@/stores/message-store.core"

### Models ###
import { CannedMessage } from "@/models/canned-message"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class CreateCannedMessagePane extends ModalPane
   constructor: (messageId, ownerId, messageType, tokens, title, options) ->
      assertArgs(arguments, nullable(String), String, String, arrayOf(Object), optional(String), optional(Object))
      super("Default Message", "Save", template())
      @title(title) if title?
      @actionText(options.actionBtnText) if options?.actionBtnText?
      @negationText(options.negationBtnText) if options?.negationBtnText?
      @messageId = messageId
      @existingMessage = ko.observable(null)
      if options?.existingMessage?
         @existingMessage(options?.existingMessage)
      @messageLoaded = ko.pureComputed =>
         return if @messageId? then @existingMessage()? else true

      @returnOneOffTemplate = if options?.returnOneOffTemplate? then options?.returnOneOffTemplate else false
      @ownerId = ownerId
      @messageType = messageType
      @tokenQuery = ko.observable(null)
      @tokenOptions = ko.observableArray(tokens)
      @loadingTokens = ko.pureComputed =>
         return @tokenOptions().length == 0

      @displayingTokens = ko.pureComputed =>
         query = @tokenQuery()
         return @tokenOptions() unless validateInput(query)
         return @tokenOptions().filter (token) ->
            token.name.toLowerCase().indexOf(query.toLowerCase()) != -1

      @messageTypes = ko.observableArray([
         new SegmentedControllerItem("Individual Messages", "Individual")
         new SegmentedControllerItem("Group", "group")
      ])
      @selectedMessageType = ko.observable(@messageTypes()[0])

      @subjectHtmlContent = ko.observable(CreateCannedMessagePane.BASE_SUBJECT_CONTENT)
      @subjectContainerElement = ko.observable(null)
      @baseSubjectHtmlContent = CreateCannedMessagePane.BASE_SUBJECT_CONTENT

      @messageHtmlContent = ko.observable(CreateCannedMessagePane.BASE_BODY_CONTENT)
      @messageContainerElement = ko.observable(null)
      @baseMessageHtmlContent = CreateCannedMessagePane.BASE_BODY_CONTENT

      @isActionEnabled = ko.pureComputed =>
         return CreateCannedMessagePane.EMPTY_BODY_CONTENT.indexOf(@messageHtmlContent()) == -1

      # Selection Tracking
      @parentNode = ko.observable(null)
      @range = ko.observable(null)

      if @messageId?
         @loadMessage()
      else if @existingMessage()?
         # Passed in 1 off tempalte.
         @processLoadedMessage(@existingMessage())

   addToken: (token) =>
      return unless @range()?
      newNode = document.createElement("span")
      newNode.contentEditable = false
      if token.subject1Type == "projects"
         token['subject1Id'] = @ownerId
      newNode.dataset.token = JSON.stringify(token)
      newNode.className = 'lc__canned-token'
      newNode.innerHTML = "{ #{token.name} }"
      @range().insertNode(newNode)
      @range().insertNode(document.createElement("span"))

      newRange = document.createRange()
      newRange.setStart(@parentNode().lastChild, @parentNode().lastChild.childNodes.length)
      newRange.setEnd(@parentNode(), @parentNode().childNodes.length)
      @range(newRange)

      # Ensure all content is up to date even if no additional character was hit.
      @subjectHtmlContent(@subjectContainerElement().innerHTML)
      @messageHtmlContent(@messageContainerElement().innerHTML)

   actionBtnIntercept: (next) =>
      dynamicTokens = []
      processTokenHit = (hit) ->
         encodedData = hit.match(/data-token=["']({.*?})["']/)
         newNode = document.createElement("span")
         # Just the JSON Object.
         newNode.innerHTML = encodedData[1]
         text = newNode.textContent
         processingToken = JSON.parse(text)
         tokenId = v4()
         processingToken['id'] = tokenId
         dynamicTokens.push(processingToken)
         return " LC-DT<{#{tokenId}}> "

      subjectText = null
      if CreateCannedMessagePane.EMPTY_SUBJECT_CONTENT.indexOf(@subjectHtmlContent()) == -1
         rawSubject = @subjectHtmlContent()
         tokenlessSubject = rawSubject.replace /<span(.*?)lc__canned(.*?)<\/span>/g, (hit) ->
            return processTokenHit(hit)

         subjectNode = document.createElement("span")
         subjectNode.innerHTML = tokenlessSubject
         subjectText = subjectNode.textContent
         subjectText = subjectText.replace(/\n/g, "")
         subjectText = subjectText.replace(/\s+/g, ' ')

      # Get the tokens data out
      rawContent = @messageHtmlContent()
      tokenlessContent = rawContent.replace /<span(.*?)lc__canned(.*?)<\/span>/g, (hit) ->
         return processTokenHit(hit)

      formattedContent = tokenlessContent.replace(/<p><br><\/p>/g, "\n")
      formattedContent = formattedContent.replace(/<\/p><p/g, "</p>\n<p")
      totalNode = document.createElement("span")
      totalNode.innerHTML = formattedContent
      totalText = totalNode.textContent.replace(/[^\x00-\x7F]/g, "") #eslint-disable-line no-control-regex
      # Doing this because coffeescript was erroring on literal syntax.
      spaceRegex = new RegExp(' +', 'g')
      totalText = totalText.replace(spaceRegex, ' ')

      messageData = {
         owner_id: @ownerId
         subject: subjectText
         content: totalText
         include_signature: false
         is_group: @selectedMessageType().value() == "group"
         dynamic_tokens: dynamicTokens.map (item) =>
            item['subject_1_type'] = item.subject1Type
            item['subject_1_key'] = item.subject1Key
            if item.subject1Id?
               item['subject_1_id'] = item.subject1Id
            else
               item['subject_1_id'] = null
            
            if item.subject2Type?
               item['subject_2_type'] = item.subject2Type
               item['subject_2_key'] = item.subject2Key
            if item.subject3Type?
               item['subject_3_type'] = item.subject3Type
               item['subject_3_key'] = item.subject3Key
            
            # Make sure project specific tokens refer to the correct project when
            # tokens are copy/pasted from a different project's canned message.
            if item.subject_1_type == "projects"
               item['subject_1_id'] = @ownerId

            delete item.subject1Type
            delete item.subject1Key
            delete item.subject1Id
            delete item.subject2Type
            delete item.subject2Key
            return item
         type: @messageType
      }
      if @returnOneOffTemplate
         @data.set("template", messageData)
         return modalManager.modalFinished()
      else
         if @messageId?
            await (MessageStore.updateCannedMessage(@messageId, messageData)).payload;
            next()
         else
            newMessage = await (MessageStore.createCannedMessage(messageData)).payload;
            @data.set("message", newMessage)
            next()

   decodeTokenToHtml_: (hit, tokens) ->
      tokenId = hit.match(/\{(.*?)}/)[1]
      matchedToken = null
      for token in tokens
         if token.id == tokenId
            matchedToken = token
            break

      tmpNode = document.createElement("span")
      newNode = document.createElement("span")
      newNode.contentEditable = false
      newNode.dataset.token = JSON.stringify(matchedToken)
      newNode.className = 'lc__canned-token'
      newNode.innerHTML = "{ #{matchedToken.name} }"
      tmpNode.appendChild(newNode)
      return tmpNode.innerHTML

   processLoadedMessage: (message) =>
      if message.subject()?
         tokenedSubject = message.subject().replace /LC-DT<{(.*?)}>/g, (hit) =>
            return @decodeTokenToHtml_(hit, message.dynamicTokens())
         @subjectHtmlContent(tokenedSubject)

      tokenedMessage = message.content().replace /LC-DT<{(.*?)}>/g, (hit) =>
            return @decodeTokenToHtml_(hit, message.dynamicTokens())
      chunks = tokenedMessage.split("\n")

      formattedMessage = ""
      for chunk in chunks
         chunk = "<br>" if chunk == ""
         formattedMessage += "<p class='canned-message-pane__subject-content'>#{chunk}</p>"
      @messageHtmlContent(formattedMessage)
      @selectedMessageType(@messageTypes()[1]) if message.isGroup()
      @existingMessage(message)

   loadMessage: ->
      message = await (MessageStore.getCannedMessage(@messageId)).payload
      @processLoadedMessage(new CannedMessage(message.data))
         


CreateCannedMessagePane.BASE_SUBJECT_CONTENT = "<p class='canned-message-pane__subject-content'>&#8203;</p>"
CreateCannedMessagePane.EMPTY_SUBJECT_CONTENT = [
   '<p class="canned-message-pane__subject-content">​</p>'
   '<p class="canned-message-pane__subject-content"><br></p>'
   CreateCannedMessagePane.BASE_SUBJECT_CONTENT
]
CreateCannedMessagePane.BASE_BODY_CONTENT = '<p class="canned-message-pane__textarea-content">&#8203;</p>'
CreateCannedMessagePane.EMPTY_BODY_CONTENT = [
   '<p class="canned-message-pane__textarea-content">​</p>'
   '<p class="canned-message-pane__textarea-content"><br></p>'
   CreateCannedMessagePane.BASE_BODY_CONTENT
]
import { textSearch } from "@/lib/utils/text-search";
import type { MaybeObservable, Observable, PureComputed, Subscription } from "knockout";
import { isComputed } from "knockout";
import { isObservable, observable, unwrap } from "knockout";
import { AlwaysLoadingGridStore } from "../../grid/always-loading-grid-store";
import { AlwaysErrorGridStore } from "../../grid/always-error-grid-store";
import { ArrayGridStore } from "../../grid/array-grid-store";
import type { GridStore } from "../../grid/grid-store";
import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";

type ItemsType<T> = T[] | null | Error;
export interface ArrayDropDownPaneParams<T> {
   /** Items to display. */
   items: MaybeObservable<ItemsType<T>> | PureComputed<ItemsType<T>>;
   /** Provider used to extract text when filtering for a drop down search. */
   searchTextProvider?: ((item: T) => string) | null;
}

/** `DropDownPane` backed by an array. */
export class ArrayDropDownPane<TItem extends ItemBase> implements DropDownPane<TItem> {
   readonly gridStore: Observable<GridStore<TItem> | null>;
   readonly isSearchable: Observable<boolean>;

   protected readonly items: Observable<ItemsType<TItem>> | PureComputed<ItemsType<TItem>>;
   private readonly searchTextProvider: ((item: TItem) => string) | null;
   private readonly subscriptions: Subscription[] = [];
   private search: string | null = null;

   constructor({ items, searchTextProvider = null }: ArrayDropDownPaneParams<TItem>) {
      this.items =
         isObservable<ItemsType<TItem>>(items) || isComputed<ItemsType<TItem>>(items)
            ? items
            : observable(items);
      this.gridStore = observable(null);
      this.searchTextProvider = searchTextProvider;
      this.isSearchable = observable(Boolean(this.searchTextProvider));

      if (isObservable(this.items)) {
         this.subscriptions.push(this.items.subscribe(this.onItemsChanged, this));
      }
   }

   loadItems(params: LoadItemsParams): void {
      const items = unwrap(this.items);
      this.search = params.search;
      if (!Array.isArray(items) || !this.search || !this.searchTextProvider) {
         this.gridStore(this.createGridStore(items));
         return;
      }
      const filtered = textSearch({
         items,
         textProvider: this.searchTextProvider!,
         search: this.search,
      });
      this.gridStore(new ArrayGridStore(filtered));
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   private onItemsChanged() {
      this.loadItems({ search: this.search, startingAt: null });
   }

   private createGridStore(items: TItem[] | null | Error) {
      if (Array.isArray(items)) {
         return new ArrayGridStore(items);
      } else if (items instanceof Error) {
         return new AlwaysErrorGridStore(items.message);
      }
      return new AlwaysLoadingGridStore();
   }
}

import type { LoadItemsParams } from "../drop-down-pane";
import { NotificationProfileStore } from "@/stores/notification-profile-store.core";
import type { Observable } from "knockout";
import { observable, unwrap } from "knockout";
import { ArrayDropDownPane } from "./array-drop-down-pane";
import { NotificationProfile } from "@/models/notification-profile";

export class NotificationProfileDropDownPane extends ArrayDropDownPane<NotificationProfile> {
   constructor() {
      super({
         items: observable(null),
         searchTextProvider: (item) => item.name(),
      });
   }

   async loadItems(params: LoadItemsParams): Promise<void> {
      super.loadItems(params);
      if (unwrap(this.items) != null) return;
      const items = this.items as Observable<NotificationProfile[] | Error | null>;

      try {
         const stream = await NotificationProfileStore.findNotificationProfilesStream({}).stream;
         const notificationProfiles: NotificationProfile[] = [];
         for await (const item of stream) {
            notificationProfiles.push(new NotificationProfile(item));
         }
         items(notificationProfiles);
      } catch (err) {
         return items(new Error("Failed to load"));
      }
   }
}

import { ObservableData } from "@/lib/utils/observable-data"
import { ViewModel } from "@/lib/vm/viewmodel"

export class PopupListItem extends ViewModel
   constructor: (options)->
      super(options.template)
      @options = options
      @title = ko.observable(options.title or null)
      @icon = ko.observable(options.icon or null)
      @isOpen = ko.observable(options.isOpen or null)
      @leftActionBtn = ko.observable(options.leftActionBtn or null)
      @rightActionBtn = ko.observable(options.rightActionBtn or null)
      @clickCallback = options.clickCallback or ->
      @callbackData = options.callbackData or null

   itemClicked: =>
      if @options.template?
         @isOpen(true)
      else
         @clickCallback()


import "./color-picker-editor.styl";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, observableArray, pureComputed, unwrap } from "knockout";
import template from "./color-picker-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";
import { defaultStore } from "@/stores/default-store";

export interface ColorPickerEditorParams extends EditorComponentParams<string | null> {
   isRequired?: MaybeSubscribable<boolean>;
}

export class ColorPickerEditor extends AbstractFieldEditor<string | null> {
   static readonly HEX_REGEX = /^#[0-9a-fA-F]{6}$/;

   readonly hexColor = pureComputed<string | null>({
      read: () => {
         return this.value();
      },
      write: (hex) => {
         // We want to allow bad hex input so that we can notify the user that it is not a valid input.
         if (hex === "") this.value(null);
         else this.value(hex);
      },
      owner: this,
      pure: true,
   });

   readonly colorOptions = observableArray(defaultStore.getDefaultResourceColorValues());

   constructor(private readonly params: ColorPickerEditorParams) {
      super(params, observable(unwrap(params.value)?.toLowerCase() ?? null));
   }

   validate(value: string | null): ValidatedValue<string | null> {
      const { isRequired } = this.params;
      const isEmpty = value === null;
      if (isEmpty && unwrap(isRequired)) {
         return { valid: false, error: `${this.title} is required.` };
      }
      if (value && !ColorPickerEditor.HEX_REGEX.test(value)) {
         return { valid: false, error: `${this.title} is not a valid hex value.` };
      }
      return { valid: true, value };
   }

   onColorOptionClick = (color: string): void => {
      this.hexColor(color);
   };

   onDelete = (): Promise<void> => this.save(null);

   static factory<T>(
      provider: (records: T[]) => ColorPickerEditorParams,
   ): (records: T[]) => ComponentArgs<ColorPickerEditorParams> {
      return (records) => ({
         name: "color-picker-editor",
         params: provider(records),
      });
   }
}

ko.components.register("color-picker-editor", {
   viewModel: ColorPickerEditor,
   template: template(),
   synchronous: true,
});

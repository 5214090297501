import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   FindConversationsForContextPaginatedQueryParams,
   FindConversationsForContextPaginatedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/conversations/find-conversations-for-context";
import type { ToggleConversationStarredResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/toggle-conversation-starred";
import type { GetConversationResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/get-conversation";
import type { DeleteConversationResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/delete-conversation";
import type { ArchiveConversationResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/archive-conversation";
import type { UnarchiveConversationResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/unarchive-conversation";
import type { BreakGroupConversationResponse } from "@laborchart-modules/lc-core-api/dist/api/conversations/break-group-conversations";
import type {
   UpdateConversationPayload,
   UpdateConversationResponse,
} from "@laborchart-modules/lc-core-api/dist/api/conversations/update-conversation";

export abstract class ConversationStore extends Store {
   static findConversationsForContextPaginated(
      query: FindConversationsForContextPaginatedQueryParams,
   ): StoreJsonResponse<FindConversationsForContextPaginatedResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/context`,
         query,
      });
   }

   static toggleConversationStarred(
      conversationId: string,
   ): StoreJsonResponse<ToggleConversationStarredResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/toggle-star`,
         method: "PATCH",
      });
   }

   static getConversation(conversationId: string): StoreJsonResponse<GetConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}`,
      });
   }

   static deleteConversation(
      conversationId: string,
   ): StoreJsonResponse<DeleteConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}`,
         method: "DELETE",
      });
   }

   static archiveConversation(
      conversationId: string,
   ): StoreJsonResponse<ArchiveConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/archive`,
         method: "PATCH",
      });
   }

   static unarchiveConversation(
      conversationId: string,
   ): StoreJsonResponse<UnarchiveConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/unarchive`,
         method: "PATCH",
      });
   }

   static breakGroupIntoIndividualMessages(
      conversationId: string,
   ): StoreJsonResponse<BreakGroupConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/break-group`,
         method: "POST",
      });
   }

   static updateConversation(
      conversationId: string,
      messageId: string,
      body: UpdateConversationPayload,
   ): StoreJsonResponse<UpdateConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/messages/${messageId}`,
         method: "PATCH",
         body,
      });
   }

   static replyToConversation(
      conversationId: string,
      body: UpdateConversationPayload,
   ): StoreJsonResponse<UpdateConversationResponse> {
      return this.requestJson({
         url: `/api/v3/conversations/${conversationId}/messages`,
         method: "POST",
         body,
      });
   }
}

import "./text-field.styl";
import template from "./text-field.pug";
import type {
   MaybeComputed,
   MaybeObservable,
   Observable,
   PureComputed,
   Subscribable,
   Subscription,
} from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";

export enum TextFieldType {
   TEXT = "text",
   NUMBER = "number",
   TEXTAREA = "textarea",
}

export type TextFieldParams = {
   value: Observable<string | null> | PureComputed<string | null>;
   isClearable?: Subscribable<boolean>;
   isDisabled?: boolean;
   placeholder?: string;
   type?: TextFieldType;

   /**
    * Attributes to apply to the text input.
    * @example
    * attr: { step: "0.01" }
    */
   attr?: MaybeObservable<any>;
   classes?: Record<string, MaybeComputed<boolean>>;
};

export class TextField {
   readonly attr: MaybeObservable<any>;
   readonly classes: TextFieldParams["classes"];
   readonly hasFocus = observable(false);
   readonly iconLeft: Node[];
   readonly iconRight: Node[];
   readonly isClearable: Subscribable<boolean>;
   readonly isDisabled: boolean;
   readonly type: TextFieldType;
   readonly value = observable<string | null>(null);

   readonly inputAttr = pureComputed(() => ({
      ...unwrap(this.attr),
      type: this.type,
   }));

   readonly clearButtonDisplayValue = pureComputed(() =>
      unwrap(this.value) && unwrap(this.isClearable) ? "flex" : "none",
   );

   private readonly subscriptions: Subscription[] = [];

   constructor(private readonly params: TextFieldParams, nodes: Node[]) {
      this.type = params.type || TextFieldType.TEXT;
      this.value(params.value());
      if (params.isClearable) {
         this.isClearable = params.isClearable;
      } else {
         this.isClearable = pureComputed(() => true);
      }
      this.isDisabled = params.isDisabled || false;
      this.attr = {
         ...unwrap(params.attr || {}),
         placeholder: params.placeholder,
      };
      this.classes = params.classes ?? {};

      this.iconLeft = nodes.filter((node) => node.nodeName.toUpperCase() === "ICON-LEFT");
      this.iconRight = nodes.filter((node) => node.nodeName.toUpperCase() === "ICON-RIGHT");

      this.subscriptions.push(
         this.value.subscribe((newVal) => {
            if (newVal === "" || newVal === null) return params.value(null);
            params.value(newVal);
         }),
         params.value.subscribe((newVal) => {
            this.value(newVal);
         }),
      );
   }

   clearValue(): void {
      this.value(null);
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   static factory(params: TextFieldParams): ComponentArgs<TextFieldParams> {
      return {
         name: "text-field",
         params,
      };
   }
}

ko.components.register("text-field", {
   viewModel: {
      createViewModel: (params: ko.components.ViewModelParams, componentInfo) => {
         return new TextField(params as TextFieldParams, componentInfo.templateNodes);
      },
   } as ko.components.ViewModelFactory,
   template: template(),
   synchronous: true,
});

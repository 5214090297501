import "./recipients-list-pane.styl"
import template from "./recipients-list-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"
import ko from "knockout"

export class RecipientsListPane extends ModalPane
   constructor: (recipients, actionText, defaultRecipients) ->
      assertArgs(arguments, arrayOf(Object), String, optional(arrayOf(Object)))
      super("Recipients", "", template())
      @actionText = ko.observable(actionText)
      @recipients = ko.observableArray(recipients)

      @defaultRecipients = ko.observableArray(defaultRecipients or [])

      @displayRecipients = ko.pureComputed =>
         return @recipients().sort (a, b) ->
            if a.firstName() < b.firstName()
               return -1
            else if a.firstName() > b.firstName()
               return 1
            else if a.lastName() < b.lastName()
               return -1
            else if a.lastName() > b.lastName()
               return 1
            else
               return 0

      @displayDefaultRecipients = ko.pureComputed =>
         return @defaultRecipients().sort (a, b) ->
            if a.firstName() < b.firstName()
               return -1
            else if a.firstName() > b.firstName()
               return 1
            else if a.lastName() < b.lastName()
               return -1
            else if a.lastName() > b.lastName()
               return 1
            else
               return 0

      @removedRecipients = ko.observableArray()

   getPersonName: (person) ->
      name = "#{person.firstName()} #{person.lastName()}"
      name += " (Default Recipient)" if person.baggage()?.default_recipient
      return name

   removePerson: (person) =>
      @recipients.remove(person)
      @removedRecipients.push(person)

   removeDefaultRecipient: (person) =>
      @defaultRecipients.remove(person)
      @removedRecipients.push(person)

   actionBtnIntercept: ->
      @data.set('removedRecipients', @removedRecipients())
      modalManager.modalFinished()

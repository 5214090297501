import { findCustomField } from "@/react/shared/custom-field-utils";
import {
   convertProjectDataTableFilters,
   fieldTranslationKeyMap,
   legacyFieldNamesMap,
   sortFieldMaps,
} from "./helpers";
import { GeneratedReportStore } from "@/stores/generated-report-store.core";
import {
   GenerateReportType,
   ReportType,
   type ProjectListServerFilter,
   type ReportResponse,
} from "@/react/prop-types";
import type { CreateProjectListReportParams } from "./project-list-prop-types";
import React from "react";
import type { AppliedRoleOption } from "@laborchart-modules/lc-core-api/dist/api/projects/get-applied-role-options";
import { ProjectStore } from "@/stores/project-store.core";
import { ColumnEntityType } from "@laborchart-modules/common";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { FindProjectsSortBy } from "./project-list-enums";

export async function createProjectListReport(
   params: CreateProjectListReportParams,
): Promise<ReportResponse> {
   const {
      configKey,
      customFields,
      groupId,
      formValues,
      I18n,
      roleOptions,
      isLastNameFirst,
      search,
   } = params;
   let customFieldId: string | null = null;
   let sortBy: FindProjectsSortBy = FindProjectsSortBy.NAME;
   const localStorageTableConfig = JSON.parse(localStorage.getItem(configKey) ?? "");
   const sortField = localStorageTableConfig.columnState.find((col: any) => col.sort !== null);

   if (sortField) {
      const customField = findCustomField(customFields!, sortField.field);
      if (customField) {
         customFieldId = customField.id;
      }
      sortBy = customFieldId ? sortFieldMaps["custom_field"] : sortFieldMaps[sortField.field];
   }

   const column_headers = localStorageTableConfig.columnState
      .filter((col: any) => !col.hidden)
      .map((col: any, i: number) => {
         const customField = findCustomField(customFields!, col.field);

         let key, name, key_id, meta, base_name;

         if (col.field.includes("positions_")) {
            const positionId = col.field.split("_").pop(); // Extract the position ID part from col.field
            const position = roleOptions?.find((pos) => pos.position_id === positionId);

            if (position) {
               key = `positions`;
               name = position.position_name;
               key_id = position.position_id; // Add key_id for positions
               base_name = null;
            }
         } else {
            key = customField ? "custom_fields" : legacyFieldNamesMap[col.field];
            name = customField ? col.field : I18n.t(fieldTranslationKeyMap[col.field]);
            base_name = customField ? customField.integration_name : null;
         }

         // Add meta object when customField exists
         if (customField) {
            meta = {
               field_id: customField.id,
               field_property: customField.integration_name,
               field_type: customField.type,
               field_entity: ColumnEntityType.PROJECTS,
            };
         }

         return {
            key,
            name,
            ...(key_id && { key_id }), // Add key_id if it exists
            width: Math.round(col.width), // column width can be a float, so round it
            sequence: i,
            base_name,
            sortable: true,
            sub_properties: [],
            ...(customField && { meta }), // Add meta field only if customField exists
         };
      });

   const payload: any = {
      sort_by: sortBy,
      sort_direction: sortField?.sort == "desc" ? Order.DESCENDING : Order.ASCENDING,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // This is how legacy report generation gets TZ
      report_type: ReportType.ProjectList,
      group_id: groupId,
      limit: 40,
      filters: convertProjectDataTableFilters(
         localStorageTableConfig.serverFilters as ProjectListServerFilter[],
      ),
      page_size: {
         height:
            formValues.orientation.id == "portrait"
               ? formValues.page_size.value.height
               : formValues.page_size.value.width,
         width:
            formValues.orientation.id == "portrait"
               ? formValues.page_size.value.width
               : formValues.page_size.value.height,
      },
      display_last_names_first: isLastNameFirst,
      file_type: formValues.file_type.value,
      column_headers,
      ...(search && { search }), // add search if it exists
      ...(customFieldId && { custom_field_id: customFieldId }), // add custom_field_id if it exists
   };
   const report = await GeneratedReportStore.create(GenerateReportType.ProjectList, payload)
      .payload;

   return report;
}

export function useGetProjectRoleOptions(groupId: string) {
   const [data, setData] = React.useState<AppliedRoleOption[]>();
   const [loading, setLoading] = React.useState<boolean>(true);

   React.useEffect(() => {
      async function fetchRoleOptions() {
         setLoading(true);
         setData(undefined);

         const response = await ProjectStore.getProjectAppliedRoleOptions(groupId).payload;
         setData(response.data);
         setLoading(false);
      }

      fetchRoleOptions();
   }, [groupId]);

   return { data, loading };
}

import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";

import type {
   AssignmentOvertimeRates,
   AssignmentPaySplit,
   AssignmentWorkDays,
} from "@/models/assignment";

import type { Person } from "@/models/person";
import type { Position } from "@/models/position";
import type { Project } from "@/models/project";
import type { ValueSet } from "@/models/value-set";
import type { Status } from "@/models/status";
import type { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import type { Observable, PureComputed } from "knockout";

import type {
   FindAssignmentsPaginatedQueryParams,
   SerializedFindAssignment,
   FindAssignmentsPaginatedResponse,
   UpdateAssignmentsPayload,
   FindAssignmentsStreamQueryParams,
   UpdateAssignmentPayload,
   UpdateSingleAssignmentResponse,
   GetAssignmentDetailReponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments";
import type {
   CreateAssignmentPayload,
   CreateAssignmentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type {
   DeleteAssignmentResponse,
   BatchDeleteAssignmentsPayload,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/delete-assignment";
import type { StreamedUpdate } from "@laborchart-modules/lc-core-api/dist/api/streams";
import { Url as UrlUtils } from "@/lib/utils/url";
import {
   getAttachedDate,
   getDetachedDay,
   incrementDate,
} from "@laborchart-modules/common/dist/datetime/date";
import { authManager } from "@/lib/managers/auth-manager";
import { postgresDateToJavascriptDate, timeStringToFloat } from "@/lib/utils/date-2";
import type { GetAssignedPeopleToMessageQueryParams } from "@laborchart-modules/lc-core-api/dist/api/assignments/get-assigned-people-to-message";
import type {
   PeopleGanttAssignment,
   PeopleGanttProject,
   PeopleGanttTimeOff,
   PersonGantt,
} from "@laborchart-modules/common/dist/postgres/procedures/get-people-gantt";
import type {
   PeopleGanttOptions,
   GetPeopleGanttResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/get-people-gantt";
import type {
   GetContextedCardsOptions,
   GetContextedCardsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/get-contexted-cards";

export declare interface GetAssignmentsAssignment {
   costCodeId: string | null;
   costCodeName: string | null;
   endDay: number;
   endTime: number | null;
   id: string;
   labelId: string | null;
   labelName: string | null;
   name: string;
   overtime: boolean;
   overtimeRates?: AssignmentOvertimeRates;
   paySplit?: AssignmentPaySplit;
   percentAllocated: number | null;
   person: Observable<Person>;
   position: Observable<Position>;
   project: Observable<Project>;
   projectId: string;
   resourceId: string;
   startDay: number;
   startTime: number | null;
   status: Observable<Status | null>;
   statusId: string | null;
   workDays: AssignmentWorkDays;
}

export type NestedComputedAssignmentSupportData = {
   [k in keyof AssignmentSupportData]: PureComputed<AssignmentSupportData[k]>;
};
export declare interface GetAssignmentsResult {
   assignments: GetAssignmentsAssignment[];
   startingAfter: string | null;
   startingBefore: string | null;
}

export declare type AssignmentSupportData = {
   projectOptions: Array<
      ValueSet<
         string | null,
         {
            additional_searchables: string[];
            daily_end_time: number;
            daily_start_time: number;
            est_end_date: number | null;
            start_date: number | null;
            status: ProjectStatus;
         }
      >
   >;
   companyTbdWeeks: number;
   groupedCostCodeOptions: { [projectId: string]: Array<ValueSet<string, { archived: boolean }>> };
   groupedLabelOptions: { [costCodeId: string]: Array<ValueSet<string, { archived: boolean }>> };
   overtimeDayRates: {
      sunday: number;
      monday: number;
      tuesday: number;
      wednesday: number;
      thursday: number;
      friday: number;
      saturday: number;
   } | null;
   paidShiftHours: number;
   resourceOptions: Array<ValueSet<string>>;
   statusOptions: Array<ValueSet<string>>;
};
export abstract class Assignment2Store extends Store {
   static createAssignment(
      payload: CreateAssignmentPayload,
   ): StoreJsonResponse<CreateAssignmentResponse> {
      return this.requestJson({
         body: payload,
         method: "POST",
         url: "/api/v3/assignments",
      });
   }

   static deleteAssignment(assignmentId: string): StoreJsonResponse<DeleteAssignmentResponse> {
      return this.requestJson({
         method: "DELETE",
         url: `/api/v3/assignments/${assignmentId}`,
      });
   }

   static batchDelete(updates: BatchDeleteAssignmentsPayload): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/assignments/batch-delete",
         method: "POST",
         body: updates,
      });
   }

   static getAssignments(
      query: FindAssignmentsPaginatedQueryParams,
   ): StoreJsonResponse<FindAssignmentsPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/assignments",
         query,
      });
   }

   static getAssignmentDetails(
      assignmentId: string,
   ): StoreJsonResponse<GetAssignmentDetailReponse> {
      return this.requestJson({
         url: `/api/v3/assignments/${assignmentId}`,
      });
   }

   static getAssignmentsStream(
      query: FindAssignmentsStreamQueryParams,
   ): StoreStreamResponse<SerializedFindAssignment> {
      return this.requestStream({
         url: "/api/v3/assignments/stream",
         query,
      });
   }

   static updateAssignmentsStream(
      updates: UpdateAssignmentsPayload[],
   ): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/assignments/stream",
         method: "PATCH",
         body: updates,
      });
   }

   static updateSingleAssignment({
      assignmentId,
      update,
   }: {
      assignmentId: string;
      update: UpdateAssignmentPayload;
   }): StoreJsonResponse<UpdateSingleAssignmentResponse> {
      return this.requestJson({
         url: `/api/v3/assignments/${assignmentId}`,
         method: "PATCH",
         body: update,
      });
   }

   static async getRawProjectsGanttData(options: any) {
      const payload: any = await this.requestJson({
         url: `/api/v3/projects-gantt`,
         method: "GET",
         query: options,
      }).payload;

      // Update any null est_end_date values to be based on the start_date & tbd_weeks
      payload.projects = payload.projects.map((project: any) => ({
         ...project,
         est_end_date:
            project.est_end_date ??
            // Adding this ternary so that we only attemp to use the tbd_weeks if it exists. This was done
            // as a safety net because of trouble releasing lc-core-api
            (project.tbd_weeks
               ? new Date(
                    new Date(project.start_date).getTime() +
                       project.tbd_weeks * 7 * 24 * 60 * 60 * 1000,
                 )
               : null),
      }));

      return payload;
   }

   static async getProjectsGanttData(ganttState: any, options: any) {
      const singleDay = options.startDay == options.endDay ? options.startDay : null;

      const payload: any = await this.requestJson({
         url: `/api/v3/projects-gantt`,
         method: "GET",
         query: options,
      }).payload;

      if (options.transformData === false) return payload;

      const rows = payload.projects.map((project: any) => {
         const tagInstances = project.tag_instances
            .filter((instance: any) => !instance.archived)
            .map((instance: any) => {
               const tagData = ganttState.tags().find((tag: any) => tag.id == instance.tag_id);

               return tagData ? { ...instance, ...tagData } : instance;
            });

         const costCodeSequences: any = {};
         const labelSequences: any = {};
         // if the cost code isn't archived, add it to our costCodeSequences, and then
         // check if its labels are archived, and if not, add them to the labelSequences.
         project.cost_codes?.forEach((code: any) => {
            if (code.archived == false) {
               costCodeSequences[code.id] = code.sequence;
               code.labels?.forEach((label: any) => {
                  if (label.archived == false) labelSequences[label.id] = label.sequence;
               });
            }
         });

         const groupNames: any = [];

         const groupedAssignments: any = {};
         project.assignments.forEach((a: any) => {
            const person = payload.people.find((person: any) => person.id == a.resource_id);
            if (!person) {
               console.info("person not found:", {
                  people: payload.people.map((p: any) => p.id),
                  resourceId: a.resource_id,
                  a,
               });
            } else {
               const jobTitle =
                  payload.jobTitles.find((jobTitle: any) => jobTitle.id == person.job_title_id) ??
                  null;
               const status = (() => {
                  const foundStatusOption = ganttState.assignmentSupportData
                     .statusOptions()
                     .find((option: any) => option.value() == a.status_id);
                  if (!foundStatusOption) return null;
                  return {
                     id: foundStatusOption.value(),
                     name: foundStatusOption.name(),
                     abbreviation: foundStatusOption.baggage().abbreviation,
                     color: foundStatusOption.color(),
                  };
               })();

               let categoryGroup = groupedAssignments[a.cost_code_id];
               if (categoryGroup === undefined) {
                  categoryGroup = [];
                  groupedAssignments[a.cost_code_id] = categoryGroup;
               }

               let labelGroup = categoryGroup.find(
                  (labelGroup: any) => labelGroup.labelId === a.label_id,
               );
               if (labelGroup === undefined) {
                  labelGroup = {
                     labelId: a.label_id,
                     peopleAssignments: [],
                  };
                  categoryGroup.push(labelGroup);
               }

               let personGroup = labelGroup.peopleAssignments.find(
                  (personGroup: any) => personGroup.personId === person.id,
               );
               if (personGroup === undefined) {
                  personGroup = {
                     personId: person.id,
                     personName: { first: person.first_name, last: person.last_name },
                     positionColor: jobTitle?.color || null,
                     positionName: jobTitle?.name || null,
                     positionSequence: jobTitle?.sequence || null,
                     assignments: [],
                     timeoff: person.time_off.flatMap((timeoff: any) => {
                        return timeoff.instances.map((instance: any) => {
                           return {
                              startDay: instance.start_day,
                              endDay: instance.end_day,
                              start: getAttachedDate(instance.start_day),
                              end: incrementDate(getAttachedDate(instance.end_day)),
                              startTime: timeStringToFloat(timeoff.batch_start_time),
                              endTime: timeStringToFloat(timeoff.batch_end_time),
                              isPaid: timeoff.is_paid,
                              reason: timeoff.reason,
                              repeat: timeoff.repeat,
                           };
                        });
                     }),
                  };
                  labelGroup.peopleAssignments.push(personGroup);
               }

               const formattedAssignment: any = {
                  id: a.id,
                  instanceId: null,
                  singleDay: singleDay,
                  // person fields
                  employeeNumber: person.employee_number,
                  hourlyWage: person.hourly_wage,
                  personAssignableGroupIds: person.assignable_group_ids ?? [],
                  personGroupIds: person.group_ids,
                  profilePicUrl: person.profile_pic_url,
                  personName: { first: person.first_name, last: person.last_name },
                  // job title fields
                  positionColor: jobTitle?.color || null,
                  positionName: jobTitle?.name || null,
                  positionId: jobTitle?.id || null,
                  positionRate: jobTitle?.hourly_rate || null,
                  positionSequence: jobTitle?.sequence || null,
                  // project fields
                  groupIds: project.group_ids,
                  jobNumber: project.job_number,
                  projectColor: project.color,
                  projectId: project.id,
                  projectName: project.name,
                  projectStatus: project.status,
                  // status fields
                  status: status,
                  statusId: status?.id || null,
                  statusName: status?.name || null,
                  // assignment fields
                  costCodeId: a.cost_code_id,
                  costCodeName:
                     project.cost_codes?.find((cc: any) => cc.id === a.cost_code_id)?.name || null,
                  labelId: a.label_id,
                  labelName:
                     project.cost_codes
                        ?.find((cc: any) => cc.id === a.cost_code_id)
                        ?.labels?.find((l: any) => l.id === a.label_id)?.name || null,
                  start: postgresDateToJavascriptDate(a.start_day),
                  startDay: getDetachedDay(postgresDateToJavascriptDate(a.start_day)),
                  startTime: timeStringToFloat(a.start_time),
                  workDays: a.work_days,
                  resourceId: a.resource_id,
                  endTime: timeStringToFloat(a.end_time),
                  endDay: getDetachedDay(postgresDateToJavascriptDate(a.end_day)),
                  end: incrementDate(postgresDateToJavascriptDate(a.end_day)),
                  batchEndDay: a.end_day
                     ? getDetachedDay(postgresDateToJavascriptDate(a.end_day))
                     : null,
                  batchId: a.id,
                  batchStartDay: a.start_day
                     ? getDetachedDay(postgresDateToJavascriptDate(a.start_day))
                     : null,
                  overtime: a.overtime,
                  percentAllocated: a.percent_allocated,
               };
               if (a.percent_allocated) {
                  // Show the entire day
                  formattedAssignment["startTime"] = 0;
                  formattedAssignment["endTime"] = 24;
               }
               if (a.pay_split) {
                  formattedAssignment["paySplit"] = {
                     overtime: Number(a.pay_split.overtime),
                     straight: Number(a.pay_split.straight),
                     unpaid: Number(a.pay_split.unpaid),
                  };
               }
               if (a.overtime_rates) {
                  formattedAssignment["overtimeRates"] = {
                     "0": a.overtime_rates["0"],
                     "1": a.overtime_rates["1"],
                     "2": a.overtime_rates["2"],
                     "3": a.overtime_rates["3"],
                     "4": a.overtime_rates["4"],
                     "5": a.overtime_rates["5"],
                     "6": a.overtime_rates["6"],
                  };
               }

               personGroup.assignments.push(formattedAssignment);
            }
         });

         const groupedRequests: any = {};
         project.requests.forEach((r: any) => {
            /* istanbul ignore next */
            const jobTitle =
               payload.jobTitles.find((jobTitle: any) => jobTitle.id == r.position_id) ?? null;
            const status = (() => {
               const foundStatusOption = ganttState.assignmentSupportData
                  .statusOptions()
                  .find((option: any) => option.value() == r.status_id);
               if (!foundStatusOption) return null;
               return {
                  id: foundStatusOption.value(),
                  name: foundStatusOption.name(),
                  abbreviation: foundStatusOption.baggage().abbreviation,
                  color: foundStatusOption.color(),
               };
            })();

            let categoryGroup = groupedRequests[r.cost_code_id];
            if (categoryGroup === undefined) {
               categoryGroup = [];
               groupedRequests[r.cost_code_id] = categoryGroup;
            }

            let labelGroup = categoryGroup.find(
               (labelGroup: any) => labelGroup.labelId === r.label_id,
            );
            if (labelGroup === undefined) {
               labelGroup = {
                  labelId: r.label_id,
                  requests: [],
               };
               categoryGroup.push(labelGroup);
            }

            const formattedRequest: any = {
               id: r.id,
               instanceId: null,
               instructionText: r.instruction_text,
               workScopeText: r.work_scope_text,
               creatorId: r.creator_id,
               singleDay: singleDay,
               // job title fields
               positionColor: jobTitle?.color || null,
               positionName: jobTitle?.name || null,
               positionId: jobTitle?.id || null,
               positionRate: jobTitle?.hourly_rate || null,
               positionSequence: jobTitle?.sequence || null,
               // project fields
               groupIds: project.group_ids,
               jobNumber: project.job_number,
               projectColor: project.color,
               projectId: project.id,
               projectName: project.name,
               projectStatus: project.status,
               // status fields
               status: status,
               statusId: status?.id || null,
               statusName: status?.name || null,
               // assignment fields
               costCodeId: r.cost_code_id,
               costCodeName:
                  project.cost_codes.find((cc: any) => cc.id === r.cost_code_id)?.name || null,
               labelId: r.label_id,
               labelName:
                  project.cost_codes
                     .find((cc: any) => cc.id === r.cost_code_id)
                     ?.labels?.find((l: any) => l.id === r.label_id)?.name || null,
               start: postgresDateToJavascriptDate(r.start_day),
               startDay: getDetachedDay(postgresDateToJavascriptDate(r.start_day)),
               startTime: timeStringToFloat(r.start_time),
               workDays: r.work_days,
               endTime: timeStringToFloat(r.end_time),
               endDay: getDetachedDay(postgresDateToJavascriptDate(r.end_day)),
               end: incrementDate(postgresDateToJavascriptDate(r.end_day)),
               batchEndDay: r.end_day
                  ? getDetachedDay(postgresDateToJavascriptDate(r.end_day))
                  : null,
               batchId: r.id,
               batchStartDay: r.start_day
                  ? getDetachedDay(postgresDateToJavascriptDate(r.start_day))
                  : null,
               percentAllocated: r.percent_allocated,
            };

            labelGroup.requests.push(formattedRequest);
         });

         return {
            ...project,
            tagInstances,
            costCodeSequences,
            labelSequences,
            groupNames,
            groupedAssignments,
            groupedRequests,
            dailyStartTime: project.daily_start_time,
            dailyEndTime: project.daily_end_time,
            wageOverrides: project.wage_overrides,
            estEndDate: project.est_end_date ? new Date(project.est_end_date) : null,
            jobNumber: project.job_number,
            profilePicUrl: project.profile_pic_url,
            projectId: project.id,
            projectName: project.name,
            projectStatus: project.status,
            startDate: project.start_date ? new Date(project.start_date) : null,
            bidRate: project.bid_rate,
         };
      });

      // This is kind of a hack on what the intended purpose of this field is, but this is more efficient and helps us avoid having to make our endpoint too opinionated for now.
      // We could go in to the manager method and also return a field on the response called totalPossible that is a count of the projects after filtering and before the limit is applied,
      // but if we did that then we may not be able to use it for the people in the same way after that, because people gantt would be expecting a count of the people.
      // There are several ways to solve this, but this is the most efficient way for now and we can improve the design when we get there and is necessary.
      // Also we can only do this because the totalPossible value isn't being shown in the UI anywhere. If it were then we'd be giving an innacurate number to the user so this would
      // not be an acceptable solution.
      const moreRowsToLoad = rows.length >= options.limit;
      return {
         rows,
         totalPossible: moreRowsToLoad ? rows.length + 1 : 0,
         toSkip: options.skip + options.limit,
      };
   }

   static async getProjectsGanttAssignments(options: any) {
      const singleDay = options.startDay == options.endDay ? options.startDay : null;
      const params = UrlUtils.serializeParams(options);

      const data: any = await this.requestJson({
         url: `/api/companies/${authManager.companyId()}/groups/${authManager.selectedGroupId()}/projects-gantt-assignments-2?${params}`,
         method: "GET",
      }).payload;

      const formattedRows = data.rows.map((item: any) => {
         const projectSet: any = {};
         projectSet["color"] = item.color;
         projectSet["costCodeSequences"] = {};
         projectSet["dailyEndTime"] = item.daily_end_time;
         projectSet["dailyStartTime"] = item.daily_start_time;
         projectSet["estEndDate"] = item.est_end_date ? new Date(item.est_end_date) : null;
         projectSet["jobNumber"] = item.job_number;
         projectSet["profilePicUrl"] = item.profile_pic_url;
         projectSet["projectId"] = item.id;
         projectSet["projectName"] = item.name;
         projectSet["projectStatus"] = item.status;
         projectSet["startDate"] = item.start_date ? new Date(item.start_date) : null;
         projectSet["bidRate"] = item.bid_rate;
         projectSet["wageOverrides"] = item.wage_overrides;

         for (const code of item.cost_code_sequences) {
            projectSet.costCodeSequences[code.id] = code.sequence;
         }

         projectSet["labelSequences"] = {};
         for (const label of item.label_sequences) {
            projectSet.labelSequences[label.id] = label.sequence;
         }

         projectSet["groupNames"] = item.group_ids
            .map((id: string) => {
               return authManager.companyGroupNames()[id];
            })
            .filter((item: any) => item != null);

         projectSet["tagInstances"] = item.tag_instances;

         const groupedAssignments: any = {};

         // for(const group of assignments.data) {
         for (const group of item.assignments) {
            const labeledAssignments = [];
            for (const set of group.labeled_assignments) {
               const formattedPeopleSets = [];
               for (const subset of set.people_assignments) {
                  const formattedAssignments = [];
                  for (const ass of subset.assignments) {
                     const formattedAssignment: any = {
                        batchEndDay: ass.end_day || null,
                        batchId: ass.id,
                        batchStartDay: ass.start_day || null,
                        costCodeId: ass.cost_code_id,
                        costCodeName: ass.cost_code_name,
                        employeeNumber: ass.employee_number,
                        // TODO: Is this really safe?
                        end: getAttachedDate(ass.end_day + 1),
                        endDay: ass.end_day,
                        endTime: ass.end_time,
                        groupIds: ass.group_ids,
                        hourlyWage: ass.hourly_wage,
                        instanceId: null,
                        jobNumber: item.job_number,
                        labelId: ass.label_id,
                        labelName: ass.label_name,
                        overtime: ass.overtime,
                        percentAllocated: ass.percent_allocated,
                        personAssignableGroupIds: ass.person_assignable_group_ids,
                        personGroupIds: ass.person_group_ids,
                        // TODO: This need to not be overlapping with above property.
                        personName: ass.name,
                        positionColor: ass.position ? ass.position.color : null,
                        positionName: ass.position ? ass.position.name : null,
                        positionId: ass.position ? ass.position.id : null,
                        positionRate: ass.position ? ass.position.hourly_rate : null,
                        positionSequence: ass.position ? ass.position.sequence : null,
                        projectColor: ass.project_color,
                        projectId: item.id,
                        projectName: item.name,
                        projectStatus: item.status,
                        resourceId: ass.resource_id,
                        singleDay: singleDay,
                        start: getAttachedDate(ass.start_day),
                        startDay: ass.start_day,
                        startTime: ass.start_time,
                        status: ass.status,
                        statusId: ass.status_id || null,
                        statusName: ass.status_name || null,
                        workDays: ass.work_days,
                     };

                     if (ass.percent_allocated) {
                        // Show the entire day
                        formattedAssignment["startTime"] = 0;
                        formattedAssignment["endTime"] = 24;
                     }

                     if (ass.pay_split) {
                        formattedAssignment["paySplit"] = {
                           overtime: Number(ass.pay_split.overtime),
                           straight: Number(ass.pay_split.straight),
                           unpaid: Number(ass.pay_split.unpaid),
                        };
                     }
                     if (ass.overtime_rates) {
                        formattedAssignment["overtimeRates"] = {
                           "0": ass.overtime_rates["0"],
                           "1": ass.overtime_rates["1"],
                           "2": ass.overtime_rates["2"],
                           "3": ass.overtime_rates["3"],
                           "4": ass.overtime_rates["4"],
                           "5": ass.overtime_rates["5"],
                           "6": ass.overtime_rates["6"],
                        };
                     }
                     formattedAssignments.push(formattedAssignment);
                  }
                  const timeoffInstances = [];
                  for (const timeoff of subset.timeoff) {
                     for (const instance of timeoff.instances) {
                        if (instance.archived) continue;
                        timeoffInstances.push({
                           reason: timeoff.reason,
                           isPaid: timeoff.is_paid,
                           repeat: timeoff.repeat,
                           startTime: timeoff.batch_start_time,
                           endTime: timeoff.batch_end_time,
                           startDay: instance.start_day,
                           start: getAttachedDate(instance.start_day),
                           endDay: instance.end_day,
                           end: getAttachedDate(instance.end_day + 1),
                        });
                     }
                  }
                  formattedPeopleSets.push({
                     assignments: formattedAssignments,
                     timeoff: timeoffInstances,
                     personId: subset.personId,
                     personName: formattedAssignments[0].personName,
                     positionColor: formattedAssignments[0].positionColor,
                     positionName: formattedAssignments[0].positionName,
                     positionSequence: formattedAssignments[0].positionSequence,
                  });
               }
               labeledAssignments.push({
                  labelId: set.label_id,
                  peopleAssignments: formattedPeopleSets,
               });
            }
            groupedAssignments[group.cost_code_id] = labeledAssignments;
         }
         projectSet["groupedAssignments"] = groupedAssignments;

         const groupedRequests: any = {};
         // for(const group of requests.data) {
         for (const group of item.requests) {
            const labeledRequests = [];
            for (const set of group.labeled_assignments) {
               const formattedRequests = [];
               for (const request of set.reduction) {
                  const formattedRequest: any = {
                     batchEndDay: request.end_day || null,
                     batchId: request.id,
                     batchStartDay: request.start_day || null,
                     costCodeId: request.cost_code_id,
                     costCodeName: request.cost_code_name,
                     creatorId: request.creator_id,
                     end: getAttachedDate(request.end_day + 1),
                     endDay: request.end_day,
                     endTime: request.end_time,
                     groupIds: request.group_ids,
                     id: request.id,
                     instanceId: null,
                     instructionText: request.instruction_text,
                     jobNumber: item.job_number,
                     labelId: request.label_id,
                     labelName: request.label_name,
                     percentAllocated: request.percent_allocated,
                     positionColor: request.position ? request.position.color : null,
                     positionId: request.position ? request.position.id : null,
                     positionName: request.position ? request.position.name : null,
                     positionRate: request.position?.hourly_rate
                        ? request.position.hourly_rate
                        : null,
                     positionSequence: request.position ? request.position.sequence : null,
                     projectColor: request.project_color,
                     projectId: item.id,
                     projectName: item.name,
                     projectStatus: item.status,
                     resourceId: request.resource_id,
                     singleDay: null,
                     start: getAttachedDate(request.start_day),
                     startDay: request.start_day,
                     startTime: request.start_time,
                     status: request.status,
                     statusId: request.status_id || null,
                     statusName: request.status_name || null,
                     workDays: request.work_days,
                     workScopeText: request.work_scope_text,
                  };

                  formattedRequests.push(formattedRequest);
               }
               labeledRequests.push({ labelId: set.group, requests: formattedRequests });
            }
            groupedRequests[String(group.cost_code_id)] = labeledRequests;
         }
         projectSet["groupedRequests"] = groupedRequests;

         return projectSet;
      });

      const formattedData: any = { rows: formattedRows, totalPossible: data.total_possible };
      if (data.to_skip) formattedData["toSkip"] = data.to_skip;

      return formattedData;
   }

   static getAssignedPeopleToMessage(
      projectId: string,
      query: GetAssignedPeopleToMessageQueryParams,
   ) {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/message-assignments`,
         query,
      });
   }

   static async getPeopleGanttData(options: PeopleGanttOptions) {
      const payload: GetPeopleGanttResponse = (await this.requestJson({
         url: `/api/v3/people-gantt`,
         method: "GET",
         query: options,
      }).payload) as GetPeopleGanttResponse;
      const singleDay = options.startDay == options.endDay ? options.startDay : null;
      const formattedRows = formatPeopleGanttAssignments(payload.data as PersonGantt[], singleDay);

      // This is kind of a hack on what the intended purpose of this field is, but this is more efficient and helps us avoid having to make our endpoint too opinionated for now.
      // We could go in to the manager method and also return a field on the response called totalPossible that is a count of the projects after filtering and before the limit is applied,
      // but if we did that then we may not be able to use it for the people in the same way after that, because people gantt would be expecting a count of the people.
      // There are several ways to solve this, but this is the most efficient way for now and we can improve the design when we get there and is necessary.
      // Also we can only do this because the totalPossible value isn't being shown in the UI anywhere. If it were then we'd be giving an innacurate number to the user so this would
      // not be an acceptable solution.
      const moreRowsToLoad = formattedRows.length >= options.limit;
      return {
         rows: formattedRows,
         totalPossible: moreRowsToLoad ? formattedRows.length + 1 : 0,
         toSkip: options.skip + options.limit,
      };
   }

   static getContextedResourceCards(
      options: GetContextedCardsOptions,
   ): StoreJsonResponse<GetContextedCardsResponse> {
      return this.requestJson({
         url: `/api/v3/groups/${authManager.selectedGroupId()}/resource-cards`,
         method: "GET",
         query: options,
      });
   }

   static async getPersonsGanttAssignments(resourceId: string, startDay: number, endDay: number) {
      const payload: GetPeopleGanttResponse = (await this.requestJson({
         url: `/api/v3/people/${resourceId}/gantt`,
         method: "GET",
         query: {
            startDay,
            endDay,
         },
      }).payload) as GetPeopleGanttResponse;

      const singleDay = startDay == endDay ? startDay : null;
      const formattedRows = formatPeopleGanttAssignments(payload.data as PersonGantt[], singleDay);

      const assignments: any[] = [];
      const timeoff: any[] = [];
      formattedRows.forEach((person) => {
         person.projects.forEach((project: any) => {
            assignments.push(...project.assignments);
         });
         person.timeoff.forEach((to: any) => {
            timeoff.push(...to.instances);
         });
      });

      return { assignments, timeoff };
   }
}
function formatPeopleGanttAssignments(rows: PersonGantt[], singleDay: number | null) {
   const formattedRows: any[] = [];
   rows.forEach((person) => {
      const personSet: any = {};
      personSet["personId"] = person.id;
      personSet["personName"] = authManager.authedUser()?.preferences()?.displayLastNamesFirst()
         ? person.name.last + ", " + person.name.first
         : person.name.first + " " + person.name.last;
      personSet["employeeNumber"] = person.employee_number;
      personSet["hourlyWage"] = person.hourly_wage;
      personSet["positionColor"] = person.position_data ? person.position_data.color : null;
      personSet["positionId"] = person.position_data ? person.position_data.id : null;
      personSet["positionName"] = person.position_data ? person.position_data.name : null;
      personSet["positionRate"] = person.position_data ? person.position_data.hourly_rate : null;
      personSet["profilePicUrl"] = person.profile_pic_url;
      personSet["tagInstances"] = person.tag_instances;
      personSet["groupNames"] = person.group_ids
         .map((id: string) => authManager.companyGroupNames()[id])
         .filter((item: string) => item !== undefined && item !== null);

      // Format projects
      const formattedProjects: any[] = [];
      person.projects.forEach((project: PeopleGanttProject) => {
         const formattedProject: any = {
            isRestrictedProject: false,
            jobNumber: project.job_number,
            projectColor: project.color,
            projectEndDate: project.est_end_date,
            projectId: project.project_id,
            projectName: project.name,
            projectStartDate: project.start_date,
            projectGroupIds: project.group_ids,
         };

         // Format assignments
         const formattedAssignments: any[] = [];
         project.assignments.forEach((ass: PeopleGanttAssignment) => {
            const formattedAssignment: any = {
               batchEndDay: ass.end_day
                  ? getDetachedDay(postgresDateToJavascriptDate(ass.end_day as string))
                  : null,
               batchId: ass.id,
               batchStartDay: ass.start_day
                  ? getDetachedDay(postgresDateToJavascriptDate(ass.start_day as string))
                  : null,
               color: project.color,
               costCodeId: ass.cost_code_id,
               costCodeName:
                  project.cost_codes?.find((cc: any) => cc.id === ass.cost_code_id)?.name ?? null,
               end: incrementDate(postgresDateToJavascriptDate(ass.end_day as string)),
               endDay: getDetachedDay(postgresDateToJavascriptDate(ass.end_day as string)),
               endTime: ass.end_time ? timeStringToFloat(ass.end_time as string) : null,
               groupIds: project.group_ids,
               hourlyWage: person.hourly_wage ?? null,
               instanceId: null,
               isRestrictedProject: false,
               jobNumber: project.job_number,
               labelId: ass.label_id,
               labelName:
                  project.cost_codes
                     ?.find((cc: any) => cc.id === ass.cost_code_id)
                     ?.labels?.find((l: any) => l.id === ass.label_id)?.name ?? null,
               overtime: ass.overtime,
               percentAllocated: ass.percent_allocated,
               positionRate: person.position_data ? person.position_data.hourly_rate : null,
               projectId: project.project_id,
               projectName: project.name,
               projectStatus: project.status,
               resourceId: ass.resource_id,
               singleDay: singleDay,
               start: postgresDateToJavascriptDate(ass.start_day as string),
               startDay: getDetachedDay(postgresDateToJavascriptDate(ass.start_day as string)),
               startTime: ass.start_time ? timeStringToFloat(ass.start_time as string) : null,
               status: ass.status,
               statusId: ass.status_id,
               statusName: ass.status ? ass.status.name : null,
               wageOverrides: project.wage_overrides,
               workDays: ass.work_days,
            };

            if (ass.pay_split) {
               formattedAssignment["paySplit"] = {
                  overtime: Number(ass.pay_split.overtime),
                  straight: Number(ass.pay_split.straight),
                  unpaid: Number(ass.pay_split.unpaid),
               };
            }

            if (ass.overtime_rates) {
               formattedAssignment["overtimeRates"] = {
                  "0": ass.overtime_rates["0"],
                  "1": ass.overtime_rates["1"],
                  "2": ass.overtime_rates["2"],
                  "3": ass.overtime_rates["3"],
                  "4": ass.overtime_rates["4"],
                  "5": ass.overtime_rates["5"],
                  "6": ass.overtime_rates["6"],
               };
            }
            formattedAssignments.push(formattedAssignment);
         });
         formattedProject["assignments"] = formattedAssignments;
         formattedProjects.push(formattedProject);
      });

      // Format timeoffs
      person.timeoff.forEach((timeoff: PeopleGanttTimeOff) => {
         const timeoffInstances: any = [];
         timeoff.instances.forEach((instance) => {
            timeoffInstances.push({
               startDay: instance.start_day,
               endDay: instance.end_day,
               start: getAttachedDate(instance.start_day),
               end: incrementDate(getAttachedDate(instance.end_day)),
               startTime: timeStringToFloat(timeoff.batch_start_time as string),
               endTime: timeStringToFloat(timeoff.batch_end_time as string),
               isPaid: timeoff.is_paid,
               reason: timeoff.reason,
               repeat: timeoff.repeat,
            });
         });

         timeoff.instances = timeoffInstances;
      });

      personSet["projects"] = formattedProjects;
      personSet["timeoff"] = person.timeoff;
      personSet["requests"] = [];
      formattedRows.push(personSet);
   });
   return formattedRows;
}

import { ConversationContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { BulkMessageFormValues } from "./bulk-send-message-tearsheet";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { MessageStore } from "@/stores/message-store.core";
import type { CreateInitialMessageResponse } from "@laborchart-modules/lc-core-api/dist/api/messages/create-message";

export async function sendOrScheduleMessage(
   values: BulkMessageFormValues,
   context: ConversationContext,
): Promise<CreateInitialMessageResponse> {
   const shouldScheduleDelivery = context === ConversationContext.SCHEDULED;
   const payload = {
      content: values.content,
      context: context,
      include_signature: false,
      is_group: values.send_as.id === "group_message",
      is_private: values.message_visibility?.find((x: any) => x.id === "all_can_view")
         ? false
         : true,
      original_subject: values.subject,
      recipient_ids: values.recipients.map((r: any) => r.id),
      subject: values.subject,
      detached_day: shouldScheduleDelivery ? getDetachedDay(values.scheduled_day) : undefined,
      send_at: shouldScheduleDelivery ? values.scheduled_day.getTime() : undefined,
      time: shouldScheduleDelivery
         ? values.day_night?.value === "am"
            ? Number(values.hour.value) + Number(values.minute.value)
            : Number(values.hour.value) + Number(values.minute.value) + 12
         : undefined,
   };
   if (shouldScheduleDelivery) {
      return await MessageStore.scheduleInitialMessageAndConversation(payload as any).payload;
   } else {
      return await MessageStore.createInitialMessageAndConversation(payload).payload;
   }
}

import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   H2,
   P,
   Page,
   Required,
   Switch,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import React from "react";
import type { FormikProps } from "formik";
import { RecipientsTable } from "../../communications/recipients-table";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import {
   ProjectDetailAlertTokens,
   scheduleAlertHourOptions,
   scheduleAlertMinuteOptions,
} from "../../communications/constants";
import type { DynamicTokenEditorRef } from "../../communications/dynamic-tokens/dynamic-token-editor";
import { DynamicTokenEditor } from "../../communications/dynamic-tokens/dynamic-token-editor";
import { ProjectStore } from "@/stores/project-store.core";
import { AlertStore } from "@/stores/alert-store.core";
import { reconstructHtmlWithTokens } from "../../communications/dynamic-tokens/token-utils";
import { formatOneOffAlertsPayload } from "./helpers";
import { isBefore } from "date-fns";
import { useToastAlertContext } from "@procore/toast-alert";
import { AlertContext } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { assignmentAlertsSchema } from "./validation-schemas";
import { DateTimeFormatter } from "@procore/globalization-toolkit";

type SendAssignmentAlertTearsheetContentProps = {
   projectId: string;
   onClose: () => void;
   onShowProjectDetail?: (projectId: string) => void;
};

export const SendAssignmentAlertsTearsheetContent = (
   props: SendAssignmentAlertTearsheetContentProps,
) => {
   const I18n = useI18nContext();
   const { projectId, onClose, onShowProjectDetail } = props;
   const [recipientsToIgnore, setRecipientsToIgnore] = React.useState<any[]>([]);
   const [shouldScheduleDelivery, setShouldScheduleDelivery] = React.useState<boolean>(false);
   const [alertContent, setAlertContent] = React.useState({ subject: "", content: "" });
   const [initialAlertContent, setInitialAlertContent] = React.useState({
      subject: "",
      content: "",
   });
   const editorRef = React.useRef<DynamicTokenEditorRef>(null);
   const { showToast } = useToastAlertContext();

   const dateTimeOptions = {
      locale: I18n.currentLocale,
      timeZone: "UTC",
      dateStyle: "medium",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as any);

   async function fetchAlertTemplate() {
      const data: any = (
         await ProjectStore.getProjectAlertInfo(projectId, "assignment-new").payload
      ).data;

      if (data?.template) {
         const initialSubject = reconstructHtmlWithTokens(
            data.template.dynamic_tokens,
            data.template.subject,
         );
         const initialContent = reconstructHtmlWithTokens(
            data.template.dynamic_tokens,
            data.template.content,
         );
         setInitialAlertContent({ subject: initialSubject, content: initialContent });
         setAlertContent({ subject: initialSubject, content: initialContent });
      }
   }
   React.useEffect(() => {
      fetchAlertTemplate();
   }, [projectId]);

   const handleContentChange = React.useCallback(
      (content: { subject: string; content: string }) => {
         setAlertContent(content);
      },
      [],
   );

   const handleSubmit = React.useCallback(
      async (values: any) => {
         try {
            const payload = formatOneOffAlertsPayload(
               alertContent,
               values,
               shouldScheduleDelivery,
               projectId,
               recipientsToIgnore,
            );
            await AlertStore.sendOneOffProjectAlerts({ body: payload }).payload;
            const toastMessage = shouldScheduleDelivery
               ? I18n.t(
                    "views.company.workforce_planning.communications.message_toasts.scheduled_success",
                 )
               : I18n.t(
                    "views.company.workforce_planning.communications.message_toasts.sent_success",
                 );
            showToast.success(toastMessage);
            if (onShowProjectDetail) onShowProjectDetail(projectId);
         } catch (err) {
            showToast.error(
               I18n.t("views.company.workforce_planning.communications.message_toasts.failure"),
            );
         }
      },
      [alertContent, shouldScheduleDelivery, recipientsToIgnore],
   );

   const handleSaveDraft = React.useCallback(
      async (values: any) => {
         try {
            const payload = formatOneOffAlertsPayload(
               alertContent,
               values,
               shouldScheduleDelivery,
               projectId,
               recipientsToIgnore,
            );
            payload.context = AlertContext.DRAFTS;
            await AlertStore.sendOneOffProjectAlerts({ body: payload }).payload;
            showToast.success(
               I18n.t(
                  "views.company.workforce_planning.communications.message_toasts.draft_success",
               ),
            );
            if (onShowProjectDetail) onShowProjectDetail(projectId);
         } catch (err) {
            showToast.error(
               I18n.t("views.company.workforce_planning.communications.message_toasts.failure"),
            );
         }
      },
      [alertContent],
   );

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Page.Header>
               <Page.Title>
                  <H1>
                     {I18n.t("views.company.workforce_planning.communications.assignment_alert")}
                  </H1>
               </Page.Title>
            </Page.Header>
            <Form
               view="create"
               onSubmit={handleSubmit}
               initialValues={{
                  send_to: { id: "current_day" },
                  start_date: new Date(),
                  end_date: new Date(),
                  day_night: {
                     id: "am",
                     value: "am",
                  },
               }}
               validationSchema={assignmentAlertsSchema(I18n, shouldScheduleDelivery)}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ values, isSubmitting, submitForm, setFieldValue }: FormikProps<any>) => (
                  <>
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <Form.Form>
                           <Box padding="md">
                              <Card>
                                 <Box padding="md" style={{ display: "grid", gap: "14px" }}>
                                    <H2>
                                       {I18n.t(
                                          "views.company.workforce_planning.communications.recipients",
                                       )}
                                    </H2>
                                    <Form.Row>
                                       <Form.RadioButtons
                                          // Added this hide-banner class to make up for whatever hidden element is there taking an extra 10px
                                          className="border-box hide-banner"
                                          name="send_to"
                                          label={I18n.t(
                                             "views.company.workforce_planning.communications.send_to",
                                          )}
                                          required
                                          options={[
                                             {
                                                id: "current_day",
                                                value: "current_day",
                                                label: (
                                                   <Typography>
                                                      {I18n.t(
                                                         "views.company.workforce_planning.communications.assigned_on_current_day",
                                                      )}
                                                      <em>
                                                         {dateFormatter.formatDateTime(
                                                            (() => {
                                                               const date = new Date();
                                                               date.setHours(0, 0, 0, 0);
                                                               return date;
                                                            })(),
                                                         )}
                                                      </em>
                                                   </Typography>
                                                ),
                                             },
                                             {
                                                id: "range",
                                                value: "range",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.communications.assigned_in_range",
                                                ),
                                             },
                                          ]}
                                       />
                                    </Form.Row>
                                    {values.send_to?.value === "range" && (
                                       <Form.Row>
                                          <Form.DateSelect
                                             colStart={1}
                                             name="start_date"
                                             label={I18n.t(
                                                "views.company.workforce_planning.start_date",
                                             )}
                                             required
                                             onClear={() => setFieldValue("start_date", new Date())}
                                          />
                                          <Form.DateSelect
                                             colStart={4}
                                             name="end_date"
                                             label={I18n.t(
                                                "views.company.workforce_planning.end_date",
                                             )}
                                             required
                                             onClear={() => setFieldValue("end_date", new Date())}
                                          />
                                       </Form.Row>
                                    )}
                                    <RecipientsTable
                                       projectId={props.projectId}
                                       startDate={
                                          values.send_to?.value === "range"
                                             ? getDetachedDay(values.start_date)
                                             : getDetachedDay(new Date())
                                       }
                                       endDate={
                                          values.send_to?.value === "range"
                                             ? getDetachedDay(values.end_date)
                                             : getDetachedDay(new Date())
                                       }
                                       recipientsToIgnore={recipientsToIgnore}
                                       setRecipientsToIgnore={setRecipientsToIgnore}
                                    />
                                    <Flex gap="sm">
                                       <Switch
                                          checked={shouldScheduleDelivery}
                                          onChange={() =>
                                             setShouldScheduleDelivery(!shouldScheduleDelivery)
                                          }
                                       />
                                       <P>
                                          {I18n.t(
                                             "views.company.workforce_planning.communications.schedule_delivery",
                                          )}
                                       </P>
                                    </Flex>
                                    {shouldScheduleDelivery && (
                                       <Form.Row>
                                          <Form.DateSelect
                                             colStart={1}
                                             name="scheduled_day"
                                             label={I18n.t(
                                                "views.company.workforce_planning.communications.date",
                                             )}
                                             disabledDate={(date, today) => isBefore(date, today)}
                                             required
                                          />
                                          <Form.Select
                                             colStart={4}
                                             colWidth={3}
                                             name="hour"
                                             label={I18n.t(
                                                "views.company.workforce_planning.communications.hour",
                                             )}
                                             options={scheduleAlertHourOptions}
                                             onSearch={false}
                                             required
                                          />
                                          <Form.Select
                                             colStart={7}
                                             colWidth={3}
                                             name="minute"
                                             label={I18n.t(
                                                "views.company.workforce_planning.communications.minute",
                                             )}
                                             options={scheduleAlertMinuteOptions}
                                             onSearch={false}
                                             required
                                          />
                                          <Form.RadioButtons
                                             className="border-box flex"
                                             colStart={10}
                                             colWidth={2}
                                             name="day_night"
                                             label={I18n.t(
                                                "views.company.workforce_planning.communications.day_night",
                                             )}
                                             tooltip={I18n.t(
                                                "views.company.workforce_planning.communications.day_night_tooltip",
                                             )}
                                             options={[
                                                {
                                                   id: "am",
                                                   label: "AM",
                                                   value: "am",
                                                },
                                                {
                                                   id: "pm",
                                                   label: "PM",
                                                   value: "pm",
                                                },
                                             ]}
                                          />
                                       </Form.Row>
                                    )}
                                 </Box>
                              </Card>

                              <DynamicTokenEditor
                                 ref={editorRef}
                                 tokens={ProjectDetailAlertTokens}
                                 I18n={I18n}
                                 onContentChange={handleContentChange}
                                 initialContent={initialAlertContent}
                              />
                           </Box>
                        </Form.Form>
                     </Page.Body>
                     <Page.Footer>
                        <Box padding="md">
                           <Flex justifyContent="space-between" alignItems="center">
                              <Required showLabel />
                              <FlexList space="sm">
                                 <Button
                                    variant="tertiary"
                                    disabled={isSubmitting}
                                    onClick={onClose}
                                 >
                                    {I18n.t("views.company.workforce_planning.cancel")}
                                 </Button>
                                 <Button
                                    variant="secondary"
                                    onClick={() => handleSaveDraft(values)}
                                 >
                                    {I18n.t(
                                       "views.company.workforce_planning.communications.save_draft",
                                    )}
                                 </Button>
                                 <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                    {shouldScheduleDelivery
                                       ? I18n.t(
                                            "views.company.workforce_planning.communications.schedule",
                                         )
                                       : I18n.t(
                                            "views.company.workforce_planning.communications.send",
                                         )}
                                 </Button>
                              </FlexList>
                           </Flex>
                        </Box>
                     </Page.Footer>
                  </>
               )}
            </Form>
         </Page.Main>
      </Page>
   );
};

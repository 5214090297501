import type { LoadItemsParams } from "../drop-down-pane";
import { observable, unwrap } from "knockout";
import { ArrayDropDownPane } from "./array-drop-down-pane";
import { PermissionStore } from "@/stores/permission-store.core";
import type { SerializedPermissionLevel } from "@laborchart-modules/common/dist/rethink/serializers/permission-level-serializer";

export interface PermissionOption {
   id: string;
   name: string;
   isAdmin: boolean;
}

/** `DropDownStore` backed by permission level options. */
export class PermissionDropDownPane extends ArrayDropDownPane<SerializedPermissionLevel> {
   constructor() {
      super({
         items: observable(null),
         searchTextProvider: (item) => item.name,
      });
   }

   async loadItems(params: LoadItemsParams): Promise<void> {
      super.loadItems(params);
      if (unwrap(this.items) != null) return;
      const stream = await PermissionStore.findPermissionLevelsStream({}).stream;
      const permissionLevels: SerializedPermissionLevel[] = [];
      for await (const permissionLevel of stream) {
         permissionLevels.push(permissionLevel);
      }
      this.items(permissionLevels);
   }
}

import "./icon-picker-pane.styl"
import template from "./icon-picker-pane.pug"
import { ObservableData } from "@/lib/utils/observable-data"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

export class IconPickerPane extends PopupPane
   constructor: (selectedIcon) ->
      assertArgs(arguments, Function)
      super(template(), "Group Icons")
      @selectedIcon = selectedIcon

   selectIcon: (iconName) ->
      @selectedIcon(iconName)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

import "./processing-notice-pane.styl"
import template from "./processing-notice-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"

export class ProcessingNoticePaneViewModel extends ModalPane
   constructor: (message, showAnimation) ->
      assertArgs(arguments, [Function, String], Boolean)
      super("", "", template())
      @disableHeader(true)
      if message instanceof Function
         @message = message
      else
         @message = ko.observable(message)

      @showAnimation = showAnimation

import type { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import type { SerializedProject } from "@laborchart-modules/common/dist/rethink/serializers/project-serializer";
import type { KeysetGridStoreParams, ResponsePayload } from "../../grid/keyset-grid-store";
import { KeysetGridStore } from "../../grid/keyset-grid-store";
import type { DropDownPane, ItemBase, LoadItemsParams } from "../drop-down-pane";
import { ProjectStore } from "@/stores/project-store.core";
import type { FindProjectsPaginatedQueryParams } from "@laborchart-modules/lc-core-api/dist/api/projects/find-projects";
import type { StoreStreamResponse } from "@/stores/common/store.core";
import { observable } from "knockout";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";

export type SerializedProjectTransformer<T extends ItemBase> = (project: SerializedProject) => T;

export type ProjectDropDownPaneParams<T extends ItemBase = SerializedProject> = {
   transformer: SerializedProjectTransformer<T>;
   status?: ProjectStatus | null;
   groupId?: string | null;
   filters?: Filter[] | null;
};

/** `DropDownPane` backed by projects. */
export class ProjectDropDownPane<T extends ItemBase = SerializedProject>
   implements DropDownPane<T>
{
   // TODO: Check on this one
   readonly gridStore = observable<any>(null);
   readonly isSearchable = observable(true);

   private readonly transformer: SerializedProjectTransformer<T>;
   private readonly status: ProjectStatus | null;
   private readonly groupId: string | null;
   private readonly filters: Filter[] | null;

   constructor({
      transformer,
      status = null,
      groupId = null,
      filters = null,
   }: ProjectDropDownPaneParams<T>) {
      this.transformer = transformer;
      this.status = status;
      this.groupId = groupId;
      this.filters = filters;
   }

   loadItems(params: LoadItemsParams): void {
      this.gridStore(this.createGridStore(params));
   }

   private createGridStore(params: LoadItemsParams) {
      return new ProjectGridStore({
         queryParams: {
            limit: 20,
            group_id: this.groupId || undefined,
            status: this.status || undefined,
            filters: this.filters || undefined,
            search: params.search || undefined,
         },
         startingCursor: params.startingAt ? { startingAt: params.startingAt } : null,
         transformer: this.transformer,
      });
   }

   create(params: ProjectDropDownPaneParams): ProjectDropDownPane<SerializedProject> {
      return new ProjectDropDownPane({
         ...params,
         transformer: (project) => project,
      });
   }
}

interface ProjectGridStoreParams<T extends ItemBase>
   extends KeysetGridStoreParams<FindProjectsPaginatedQueryParams> {
   transformer: SerializedProjectTransformer<T>;
}

class ProjectGridStore<T extends ItemBase = SerializedProject> extends KeysetGridStore<
   T,
   FindProjectsPaginatedQueryParams
> {
   private readonly transformer: SerializedProjectTransformer<T>;

   constructor(params: ProjectGridStoreParams<T>) {
      super(params);
      this.transformer = params.transformer;
   }

   async loadRows(queryParams: FindProjectsPaginatedQueryParams): Promise<ResponsePayload<T>> {
      const payload = await ProjectStore.findProjects(queryParams).payload;
      if (
         queryParams.starting_at != null &&
         payload.data.every((project) => project.id != queryParams.starting_at)
      ) {
         /* eslint-disable-next-line no-unused-vars */
         const { starting_at, ...newQueryParams } = queryParams;
         const newPayload = await ProjectStore.findProjects(newQueryParams).payload;
         return {
            data: newPayload.data.map(this.transformer),
            pagination: newPayload.pagination,
         };
      } else {
         return {
            data: payload.data.map(this.transformer),
            pagination: payload.pagination,
         };
      }
   }

   createLoadAllRowsStream(): StoreStreamResponse<T> {
      throw new Error("Not implemented.");
   }
}

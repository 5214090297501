import "./text-cell.styl";
import type { GridCellFactory } from "../grid-column";

export type CompositeCellFactory<T> = GridCellFactory<T, unknown>;

export interface CompositeCellFactoryVisitor<TValue, TCellParams = unknown> {
   visit(value: TValue): boolean;
   accept(value: TValue): GridCellFactory<TValue, TCellParams>;
}

/**
 * Builder for constructing a `CellFactory` capable of using specific `CellFactory`
 * for different types of items.
 */
export class CompositeCellFactoryBuilder<T> {
   private visitors: Array<CompositeCellFactoryVisitor<T>> = [];

   add<TCellParams = unknown>(visitor: CompositeCellFactoryVisitor<T, TCellParams>): this {
      this.visitors.push(visitor);
      return this;
   }

   build(): CompositeCellFactory<T> {
      const visitors = this.visitors.concat();
      return (data: T, context) => {
         const visitor = visitors.find((v) => v.visit(data));
         if (!visitor) {
            throw new Error(`Unable to find cell factory for data: ${JSON.stringify(data)}`);
         }
         return visitor.accept(data)(data, context);
      };
   }
}

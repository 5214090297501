import "./edit-action-cell.styl";
import type { GridActionProvider, GridActionProviderParams, GridCellFactory } from "../grid-column";
import { GridActionResult, GridActionSource, GridCursorState } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./edit-action-cell.pug";
import ko from "knockout";

export type EditActionCellParams<T> = {
   data: T;
   isDisabled?: (data: T) => boolean;
   onClick: (data: T) => void;
};

export class EditActionCell<T> {
   private readonly isDisabled: boolean;
   constructor(private readonly params: EditActionCellParams<T>) {
      this.isDisabled = params.isDisabled ? params.isDisabled(params.data) : false;
   }

   onClick = (): void => {
      this.params.onClick(this.params.data);
   };

   static factory<T>({
      isDisabled,
      onClick,
   }: Pick<EditActionCellParams<T>, "isDisabled" | "onClick">): GridCellFactory<
      T,
      EditActionCellParams<T>
   > {
      return (data: T): GridCell<EditActionCellParams<T>> => {
         return {
            component: {
               name: "edit-action-cell",
               params: { data, isDisabled, onClick },
            },
            height: null,
         };
      };
   }

   static columnProviders<T>({
      onClick,
      isDisabled,
   }: Pick<EditActionCellParams<T>, "isDisabled" | "onClick">): {
      cellFactory: GridCellFactory<T>;
      cursorStateProvider: () => GridCursorState.ACTIONABLE;
      actionProvider: GridActionProvider<T>;
   } {
      return {
         cellFactory: EditActionCell.factory({ onClick, isDisabled }),
         cursorStateProvider: () => GridCursorState.ACTIONABLE,
         actionProvider: ({ row, source }: GridActionProviderParams<T>) => {
            if (source == GridActionSource.ENTER_KEY) onClick(row);
            return GridActionResult.REMAIN_FOCUSED;
         },
      };
   }
}

ko.components.register("edit-action-cell", {
   viewModel: EditActionCell,
   template: template(),
   synchronous: true,
});

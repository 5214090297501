import React, { useEffect } from "react";
import type { FormFieldValueComponentProps } from "@procore/core-react";
import { Select } from "@procore/core-react";
import { hexColors } from "../constants/hex-colors";

const Swab = (value: string) => {
   return (
      <div
         data-testid={`color-swab-${value}`}
         style={{
            background: `${value}`,
            marginRight: "5px",
            borderRadius: "4px",
            height: "20px",
            width: "48px",
            border: "1px solid rgba(0, 0, 0, 0.32)",
         }}
      />
   );
};

export const ColorPicker = (props: FormFieldValueComponentProps<string>) => {
   const [value, setValue] = React.useState<string | undefined>();

   useEffect(() => {
      setValue(props.field.input.value);
   }, [props.field.input.value]);

   const handleChange = (item: any) => {
      props.field.helpers.setValue(item.item);
      setValue(item.item);
   };

   const view = props.field.meta.view;

   const items = hexColors.map((color: string, i: number) => {
      return {
         id: i.toString(),
         value: color,
      };
   });

   return (
      <>
         {view === "read" ? (
            <div>{Swab(value ?? "")}</div>
         ) : (
            <Select
               onSelect={handleChange}
               style={{ width: "100px" }}
               label={value && Swab(value)}
               disabled={props.field.meta.disabled}
            >
               {items.map((item) => (
                  <Select.Option key={item.id} value={item.value} selected={item.value === value}>
                     {Swab(item.value)}
                  </Select.Option>
               ))}
            </Select>
         )}
      </>
   );
};

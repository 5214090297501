import "./tag-instances-editor.styl";
import type { MaybeSubscribable, ObservableArray, PureComputed } from "knockout";
import ko, { observable, observableArray, pureComputed, unwrap } from "knockout";
import template from "./tag-instances-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import type { Tag } from "@/models/tag";
import { TagExpirationState } from "@/lib/components/tags/tag-chip";
import { TagStore } from "@/stores/tag-store.core";
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import { Transition } from "@/lib/components/transitioning-content/transitioning-content";
import type { Attachment } from "@/models/attachment";
import { isEqualSets } from "@/lib/utils/sets";
import { DateUtils } from "@/lib/utils/date";
import type {
   UpdateTagInstancePayload,
   UpdateTagInstancePayloads,
} from "@laborchart-modules/common/dist/api/tag-instances/update-tag-instances";
import { BatchEditConflictModalPane } from "@/lib/components/batch-edit/batch-edit-conflict-modal-pane";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import type { RowBase } from "@/lib/components/grid/grid-store";
import type {
   SerializedTag,
   SerializedTagInstance,
} from "@laborchart-modules/common/dist/rethink/serializers";
import { authManager } from "@/lib/managers/auth-manager";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { Conflict } from "../../batch-actions/batch-actions";

// TODO: Factor out front-end model version of Tag when People, Projects, and Requests list are no longer using the front-end models.

export function toSerializedTag(tag: Tag): SerializedTag {
   return {
      id: tag.id,
      abbreviation: tag.abbreviation(),
      categories: tag.categories(),
      color: tag.color(),
      company_id: authManager.companyId(),
      expr_days_warning: tag.exprDaysWarning(),
      globally_accessible: tag.globallyAccessible(),
      group_ids: tag.groupIds(),
      name: tag.name(),
      require_expr_date: tag.requireExprDate(),
   };
}

export function toSerializedTagInstance(tag: Tag): SerializedTagInstance {
   return {
      expr_date: tag.exprDate(),
      id: tag.id,
      tag_id: tag.tagId(),
      attachment_ids: tag.attachments().map((attachment) => attachment.id),
   };
}

export type TagInstancesEditorRecord = {
   /** Id of the record (ex: person ID, project ID, etc). */
   id: string;

   /**
    * Set of group IDs to use when limiting the visible tags. Allows all
    * tags the current user has access to when set to `null`.
    */
   group_ids: Set<string> | null;
   tagInstances: PureComputed<SerializedTagInstance[]>;
};

export interface TagInstancesEditorParams<TRecord extends RowBase = RowBase>
   extends EditorComponentParams<TRecord[], TagInstancesEditorUpdate> {
   /**
    * Provides attachments for the given record and tag instance.
    * NOTE: Attachments will be disabled when the provider is not supplied.
    */
   attachmentsProvider?: (recordId: string, tagId: string) => Promise<Attachment[]>;

   /** Whether expiration dates should be respected. */
   isExpirationDayEnabled: MaybeSubscribable<boolean>;

   validator?: (record: TRecord, update: TagInstancesEditorUpdate) => string | null;
   conflictModalColumnGroups?: Array<GridColumnGroup<TRecord>>;
   recordTransformer: (record: TRecord) => TagInstancesEditorRecord;
}

export type StreamUpdateTagInstancesPayload = {
   id: string;
   tag_instances: UpdateTagInstancePayloads;
};
export type TagInstancesEditorUpdate = {
   payload: StreamUpdateTagInstancesPayload[];
   addedTag: SerializedTag | null;
   addedTagInstance: Omit<SerializedTagInstance, "id"> | null;
   removedTagId: string | null;
};

type RenderedTag = {
   tag: SerializedTag;
   records: TagInstancesEditorRecord[];
};

const enum View {
   LOADING = 0,
   TAG_LIST = 1,
   ADD_OR_EDIT_TAG = 2,
}

const enum AttachmentsState {
   LOADED = 0,
   LOADING = 1,
   ERROR = 2,
}

export class TagInstancesEditor<TRecord extends RowBase> {
   readonly records: ObservableArray<TRecord>;
   readonly view = observable(View.LOADING);
   readonly transition = observable(Transition.FORWARD);
   readonly isAttachmentsEnabled: boolean;

   readonly editingTag = observable<RenderedTag | null>(null);
   readonly editingDate = observable<Date | null>(null);
   readonly editingAttachments = observableArray<Attachment>();
   readonly editingAttachmentsState = observable<AttachmentsState | null>(null);
   readonly editingAffectedRecords = observableArray<TRecord>();
   readonly editingHasInstanceRecords = observableArray<TRecord>();
   readonly editingUpdatedCount = observable(0);
   readonly editingAddedCount = observable(0);
   readonly isNew = observable(false);
   readonly isUploadingAttachment = observable(false);

   readonly isSaving = observable(false);
   readonly containsFocus = observable(false).extend({ notify: "always" });

   readonly title = pureComputed(() => {
      if (this.view() == View.TAG_LIST) return "Tags";
      return this.isNew() ? "Add Tag" : "Edit Tag";
   });
   readonly availableTags = pureComputed(() => {
      const records = this.transformedRecords();
      if (records.some((record) => record.group_ids == null)) {
         return this.allTags();
      }
      return this.allTags().filter((tag) => {
         if (tag.globally_accessible) return true;
         return records.some((record) => this.isTagVisible(record, tag));
      });
   });
   readonly renderedTags = pureComputed<RenderedTag[]>(() => {
      const records = this.transformedRecords();
      return this.allTags()
         .map((tag) => {
            // Clone the tag instance to get the correct expiration date
            // when there's only a single record.
            const tagInstance = records[0].tagInstances().find((t) => t.tag_id == tag.id);
            if (this.records().length == 1) {
               if (tagInstance) {
                  return { tag, records };
               } else {
                  return { tag, records: [] };
               }
            }
            return {
               tag,
               records: records.filter((record) => {
                  return record.tagInstances().some((t) => t.tag_id == tag.id);
               }),
            };
         })
         .filter((renderedTag) => renderedTag.records.length);
   }).extend({ trackArrayChanges: true });
   readonly tagIdsInUse = pureComputed(() => {
      return new Set(this.renderedTags().map((renderedTag) => renderedTag.tag.id));
   });
   readonly hasEditingContent = pureComputed(() => {
      return (
         this.records().length > 1 ||
         this.isAttachmentsEnabled ||
         this.requiresExpiration(this.editingTag())
      );
   });
   readonly validation = pureComputed(() => {
      const editingTag = this.editingTag();
      if (editingTag && this.requiresExpiration(editingTag) && !this.editingDate()) {
         return "Expiration date is required.";
      }
      return null;
   });
   readonly canSave = pureComputed(() => {
      const editingTag = this.editingTag();
      if (this.isSaving() || this.validation() || !editingTag) return false;
      if (this.records().length > 1) {
         return this.editingAddedCount() || this.editingUpdatedCount();
      }
      if (this.isUploadingAttachment()) return false;
      if (this.isNew()) return true;
      if (this.editingAttachmentsState() != AttachmentsState.LOADED) return false;

      const editingTagInstance = editingTag.records[0]
         .tagInstances()
         .find((tagInstance) => tagInstance.tag_id === editingTag.tag.id);
      const hasAttachmentsChanged =
         editingTagInstance != null &&
         !isEqualSets(
            new Set(editingTagInstance.attachment_ids),
            new Set(this.editingAttachments().map((a) => a.id)),
         );
      const hasDateChanged =
         this.isExpirationDayEnabled && editingTagInstance?.expr_date != this.editingDate();
      return hasAttachmentsChanged || hasDateChanged;
   });
   readonly canUpdateTagInstance = pureComputed(() => {
      const editing = this.editingTag();
      if (!editing) return false;
      const hasExpiration = this.requiresExpiration(editing);
      return (this.records().length == 1 && this.isAttachmentsEnabled) || hasExpiration;
   });

   private readonly allTags = observableArray<SerializedTag>();
   private readonly isExpirationDayEnabled: boolean;
   private readonly attachmentsProvider:
      | ((recordId: string, tagId: string) => Promise<Attachment[]>)
      | null;

   private readonly recordTransformer: (record: TRecord) => TagInstancesEditorRecord;
   private readonly transformedRecords = pureComputed(() => {
      return this.records().map(this.recordTransformer);
   });

   constructor(readonly params: TagInstancesEditorParams<TRecord>) {
      this.records = observableArray(unwrap(params.value));
      this.isExpirationDayEnabled = unwrap(params.isExpirationDayEnabled) || false;
      this.isAttachmentsEnabled = Boolean(params.attachmentsProvider);
      this.attachmentsProvider = params.attachmentsProvider || null;
      this.recordTransformer = params.recordTransformer;
      this.loadAllTags();
   }

   requiresExpiration = (renderedTag: RenderedTag | null): boolean => {
      return Boolean(
         this.isExpirationDayEnabled && renderedTag && renderedTag.tag.require_expr_date,
      );
   };

   getExpirationState = (renderedTag: RenderedTag): TagExpirationState => {
      if (!this.isExpirationDayEnabled || this.records().length > 1) {
         return TagExpirationState.NOT_EXPIRING_SOON;
      }

      const renderedTagInstance = renderedTag.records[0]
         .tagInstances()
         .find((tagInstance) => tagInstance.tag_id === renderedTag.tag.id);
      const expirationDate = renderedTagInstance?.expr_date;
      if (!expirationDate) return TagExpirationState.NOT_EXPIRING_SOON;

      const detachedDayNow = DateUtils.getDetachedDay(new Date());
      if (expirationDate <= detachedDayNow) return TagExpirationState.EXPIRED;

      const daysBetween = DateUtils.getDaysBetweenDetachedDays(detachedDayNow, expirationDate);
      const numOfDaysToWarn = renderedTag.tag.expr_days_warning;
      if (!numOfDaysToWarn) return TagExpirationState.NOT_EXPIRING_SOON;
      if (daysBetween <= numOfDaysToWarn) {
         return TagExpirationState.EXPIRING_SOON;
      }

      return TagExpirationState.NOT_EXPIRING_SOON;
   };

   getCountText = (count: number): string => {
      return count == 1 ? "1 record" : `${count} records`;
   };

   onVisible = (): void => {
      if (this.view() == View.ADD_OR_EDIT_TAG && !this.isNew()) {
         this.loadAttachments(this.editingTag()!);
      }
   };

   onHidden = (): void => {
      // Change focus when a view is hidden instead of when one is visible
      // to guarantee the recently hidden view doesn't grab focus.
      this.containsFocus(true);
   };

   onTagSelected = (tag: SerializedTag): void => {
      this.editTag({
         renderedTag: {
            tag,
            records: this.transformedRecords().filter((record) => this.isTagVisible(record, tag)),
         },
         isNew: !this.tagIdsInUse().has(tag.id),
      });
   };

   onTagClicked = (renderedTag: RenderedTag): void => {
      this.editTag({ renderedTag, isNew: false });
   };

   onBackClicked = (): void => {
      this.transition(Transition.BACKWARD);
      this.view(View.TAG_LIST);
   };

   onCloseClicked = (): void => {
      if (this.params.onClose) this.params.onClose({ hasSaved: false });
   };

   onCancelClicked = (): void => {
      this.transition(Transition.BACKWARD);
      this.view(View.TAG_LIST);
   };

   onSaveClicked = async (): Promise<void> => {
      const editingTag = this.editingTag();
      if (editingTag == null) return;
      const tagId = editingTag.tag.id;

      // Determine the set of records to update. Filter out records that already
      // have a tag instance when the tag instances cannot be updated.
      const affected = this.canUpdateTagInstance()
         ? this.editingAffectedRecords().map(this.recordTransformer)
         : this.editingAffectedRecords()
              .map(this.recordTransformer)
              .filter((record) => {
                 return record.tagInstances().every((tagInstance) => tagInstance.tag_id != tagId);
              });

      // Create the newly added tag.
      const addedTagInstance: Omit<SerializedTagInstance, "id"> = {
         tag_id: tagId,
         expr_date: this.editingDate() ? DateUtils.getDetachedDay(this.editingDate()!) : null,
         attachment_ids: this.editingAttachments().map((attachment) => attachment.id),
      };

      // Create the update payload.
      const update: UpdateTagInstancePayload = {
         expr_date: addedTagInstance.expr_date,
         ...(this.records().length == 1
            ? { attachment_ids: this.editingAttachments().map((a) => a.id) }
            : {}),
      };
      const tagInstances = { [tagId]: update };
      const payload = affected.map<StreamUpdateTagInstancesPayload>((record) => ({
         id: record.id,
         tag_instances: tagInstances,
      }));

      const isSuccess = await this.saveChanges({
         update: {
            payload,
            addedTag: editingTag.tag,
            addedTagInstance,
            removedTagId: null,
         },
         errorMessage: "An unexpected error prevented the tag from saving.",
      });
      if (!isSuccess) return;
      this.transition(Transition.BACKWARD);
      this.view(View.TAG_LIST);
   };

   onDeleteClicked = async (): Promise<void> => {
      const tag = this.editingTag();
      if (!tag) return;
      const tagId = tag.tag.id;
      const records = this.editingHasInstanceRecords();

      const payload = records.map<StreamUpdateTagInstancesPayload>((record) => ({
         id: record.id,
         tag_instances: {
            [tagId]: null,
         },
      }));

      const isSuccess = await this.saveChanges({
         update: {
            payload,
            addedTag: null,
            addedTagInstance: null,
            removedTagId: tagId,
         },
         errorMessage: "An unexpected error prevented the tag from being deleted.",
      });
      if (!isSuccess) return;
      this.transition(Transition.BACKWARD);
      this.view(View.TAG_LIST);
   };

   private async saveChanges({
      update: payload,
      errorMessage,
   }: {
      update: TagInstancesEditorUpdate;
      errorMessage: string;
   }): Promise<boolean> {
      try {
         if (this.params.validator != null) {
            const conflicts = this.getConflicts(payload);
            if (conflicts.length > 0) {
               await BatchEditConflictModalPane.show({
                  records: this.records(),
                  conflicts: conflicts,
                  columnGroups: this.params.conflictModalColumnGroups!,
                  saveProvider: async () => {
                     const editorFactory = TagInstancesEditor.factory(() => this.params);
                     const validRecords = this.records().filter(
                        (r) => !conflicts.some((c) => c.record.id == r.id),
                     );
                     editorFactory(validRecords).params.saveProvider(payload);
                  },
               });
               return true;
            }
         }
         await this.params.saveProvider(payload);
         return true;
      } catch (err) {
         notificationManagerInstance.show(
            new Notification({
               text: errorMessage,
               icon: Icons.WARNING,
               duration: 5000,
            }),
         );
         return false;
      } finally {
         this.isSaving(false);
      }
   }

   private getConflicts(update: TagInstancesEditorUpdate): Array<Conflict<TRecord>> {
      if (this.params.validator == null) return [];
      const result = this.records()
         .map((r) => ({ record: r, reason: this.params.validator!(r, update) }))
         .filter((r) => r.reason != null) as Array<Conflict<TRecord>>;

      return result;
   }

   private async loadAllTags() {
      const tagStream = await TagStore.findTagsStream({
         sort_direction: Order.ASCENDING,
      }).stream;
      const tags: SerializedTag[] = [];
      for await (const tag of tagStream) {
         tags.push(tag);
      }
      this.allTags(tags);
      this.transition(Transition.FADE);
      this.view(View.TAG_LIST);
   }

   private editTag({ renderedTag, isNew }: { renderedTag: RenderedTag; isNew: boolean }) {
      const records = this.records();
      const tag = renderedTag.tag;
      this.isNew(isNew);

      if (records.length > 1) {
         this.editingDate(null);
      } else {
         const existingTagInstance = renderedTag.records[0]
            .tagInstances()
            .find((t) => t.tag_id == tag.id);
         this.editingDate(
            existingTagInstance && existingTagInstance.expr_date
               ? DateUtils.getAttachedDate(existingTagInstance.expr_date!)
               : null,
         );
         // NOTE: Attachment editing is disabled for multiple records at a time.
         this.editingAttachmentsState(
            isNew || !this.isAttachmentsEnabled
               ? AttachmentsState.LOADED
               : AttachmentsState.LOADING,
         );
      }
      this.editingTag(renderedTag);
      const affectedRecords = records.filter((record) =>
         this.isTagVisible(this.recordTransformer(record), renderedTag.tag),
      );
      const hasInstanceRecords = affectedRecords.filter((record) => {
         return this.params
            .recordTransformer(record)
            .tagInstances()
            .some((tagInstance) => tagInstance.tag_id == renderedTag?.tag.id);
      });
      this.editingAttachments([]);
      this.editingAffectedRecords(affectedRecords);
      this.editingHasInstanceRecords(hasInstanceRecords);
      this.editingUpdatedCount(this.canUpdateTagInstance() ? hasInstanceRecords.length : 0);
      this.editingAddedCount(
         affectedRecords.filter((record) => {
            return this.params
               .recordTransformer(record)
               .tagInstances()
               .every((tagInstance) => tagInstance.tag_id != renderedTag?.tag.id);
         }).length,
      );
      this.transition(Transition.FORWARD);
      this.view(View.ADD_OR_EDIT_TAG);
   }

   private async loadAttachments(renderedTag: RenderedTag) {
      if (!this.attachmentsProvider) return;
      try {
         this.editingAttachmentsState(AttachmentsState.LOADING);
         const attachments = await this.attachmentsProvider(
            this.records()[0].id!,
            renderedTag.tag.id,
         );
         if (this.editingTag() == renderedTag) {
            this.editingAttachments(attachments);
            this.editingAttachmentsState(AttachmentsState.LOADED);
         }
      } catch (error) {
         if (this.editingTag() == renderedTag) {
            this.editingAttachmentsState(AttachmentsState.ERROR);
         }
      }
   }

   private isTagVisible(record: TagInstancesEditorRecord, tag: SerializedTag) {
      return (
         record.group_ids == null ||
         tag.globally_accessible ||
         tag.group_ids.some((groupId) => record.group_ids!.has(groupId))
      );
   }

   static factory<TRecord extends RowBase = RowBase>(
      provider: (records: TRecord[]) => TagInstancesEditorParams<TRecord>,
   ): (records: TRecord[]) => ComponentArgs<TagInstancesEditorParams<TRecord>> {
      return (records) => ({
         name: "tag-instances-editor",
         params: provider(records),
      });
   }
}

ko.components.register("tag-instances-editor", {
   viewModel: TagInstancesEditor,
   template: template(),
   synchronous: true,
});

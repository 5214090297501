import "./create-message-or-alert-pane.styl"
import template from "./create-message-or-alert-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput
import { DateUtils } from "@/lib/utils/date"
import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { defaultStore } from "@/stores/default-store"
import { MessageStore } from "@/stores/message-store.core"
import { ConversationStore } from "@/stores/conversation-store.core"
import { AlertStore } from "@/stores/alert-store.core"
import { PersonStore } from "@/stores/person-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"

### Models ###
import { ValueSet } from "@/models/value-set"
import { Conversation } from "@/models/conversation"
import { Alert } from "@/models/alert"
import { Person } from "@/models/person"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import ko from "knockout"

SMS_COTENT_SIZE_WARNING = 1100

export class CreateMessageOrAlertPane extends ModalPane
   constructor: (messageData, title, recipients, loadableRecipients, existingData, supplementalData, isAlert = false) ->
      assertArgs(arguments, Object, nullable(String), nullable([Array, Function]), Boolean, nullable(Object), nullable(Object), optional(Boolean))
      super("New Message", null, template())
      @setTitle(title) if title?
      @overflowable(true)
      @actionButtonClicked = false

      @isAlert = isAlert
      @subject = ko.observable(messageData.subject or '')
      @content = ko.observable(messageData.content or '')
      @existingAsPrivate = messageData.isPrivate or false
      @isShared = ko.observable(if messageData.isPrivate? then !messageData.isPrivate else false)
      @isPrivate = ko.pureComputed =>
         return !@isShared()
      @showingScheduleControls = ko.observable(false)
      @existingDateTime = messageData.existingDateTime or null
      @scheduledDate = ko.observable(null)
      @scheduledTime = ko.observable(null)
      @timeOptions = ko.observableArray(defaultStore.getTimeOptions(4.75))
      if @existingDateTime?
         @showingScheduleControls(true)
         @scheduledDate(new Date(@existingDateTime))
         timeVal = DateUtils.getTimeValueForDate(new Date(@existingDateTime))
         @scheduledTime(timeVal) 

      @showingScheduleControls.subscribe (newVal) =>
         unless newVal
            @scheduledDate(null)
            @scheduledTime(null)
      if messageData.scheduledDate? and messageData.scheduledTime?
         @showingScheduleControls(true)
         @scheduledDate(messageData.scheduledDate)
         @scheduledTime(messageData.scheduledTime) 
         
      @dateSelectorFrame = Popup.FrameType.ABOVE
      @dateSelectorArrow = Popup.ArrowLocation.BOTTOM_RIGHT
      @addingRecipient = ko.observable(false)
      @isEditing = existingData?
      @actionText("Save") if @isEditing
      @editingConvoWasGroup = messageData.isGroup
      @existingData = existingData or null
      @existingRecipientIds = existingData?.recipientIds or []
      @loadableRecipients = loadableRecipients
      if recipients instanceof Function
         @recipients = recipients
      else
         @recipients = if recipients? then ko.observableArray(recipients) else ko.observableArray([])
      @recipientIds = ko.pureComputed =>
         return @recipients().map (item) ->
            return item.id
      @displayRecipients = ko.pureComputed =>
         return @recipients().sort (a, b) ->
            if a.firstName() < b.firstName()
               return -1
            else if a.firstName() > b.firstName()
               return 1
            else if a.lastName() < b.lastName()
               return -1
            else if a.lastName() > b.lastName()
               return 1
            else
               return 0

      @messageTypes = ko.observableArray([
         new SegmentedControllerItem("Individual Messages", "Individual")
         new SegmentedControllerItem("Group Message", "group")
      ])
      @selectedMessageType = ko.observable(@messageTypes()[0])
      @selectedMessageType = ko.observable(@messageTypes()[1]) if messageData.isGroup

      @projectId = supplementalData?.projectId or null
      @assignmentId = supplementalData?.assignmentId or null

      @peoplePool = ko.observableArray()
      @peopleOptions = ko.observableArray([])
      @peopleDisplayOptions = ko.pureComputed =>
         filteredOptions = @peopleOptions().filter (option) =>
            return @recipientIds().indexOf(option.value()) == -1
         if filteredOptions.length == 0 and @peoplePool().length != 0
            return [new ValueSet({name: "Already Added All In Group", value: CreateMessageOrAlertPane.AllTakenFillerVal})]
         else
            return filteredOptions

      @sendBtnText = ko.pureComputed =>
         return "Send Now" unless @showingScheduleControls()
         return "Schedule"

      @saveActionsEnabled = ko.pureComputed =>
         return (validateInput(@content()) and (@recipientIds().length > 0))

      @isActionEnabled = ko.pureComputed =>
         return (validateInput(@content()) and (@recipientIds().length > 0))

      @sendActionsEnabled = ko.pureComputed =>
         return (validateInput(@content()) and (@recipientIds().length > 0) and
         (!@showingScheduleControls() or
         (@showingScheduleControls() and @scheduledDate()? and @scheduledTime()?)))

      @loadingRecipients = ko.pureComputed =>
         return @loadableRecipients and @recipients().length == 0

      @hasSmsOnlyRecipients = ko.pureComputed =>
         for recipient in @recipients()
            if !recipient.canRecieveEmail() or !recipient.email()
               return true
         return false             

      @isContentLengthWarningVisible = ko.pureComputed =>
         return @hasSmsOnlyRecipients() and @content()?.length >= SMS_COTENT_SIZE_WARNING

      @loadData()

   toggleRecipientAdding: ->
      @addingRecipient(!@addingRecipient())

   getPersonName: (person) ->
      name = "#{person.firstName()} #{person.lastName()}"
      name += " (Default Recipient)" if person.baggage()?.default_recipient
      return name

   newPersonSelected: (newVal) =>
      @addingRecipient(false)
      newId = newVal.value()
      return if newId == CreateMessageOrAlertPane.AllTakenFillerVal
      for person in @peoplePool()
         if person.id == newId
            @recipients.push(person)
            break

   removePerson: (person) =>
      @recipients.remove(person)

   getScheduledText: ->
      dateTime = new Date(@existingDateTime)
      formattedDate = DateUtils.getShortNumericDate(dateTime, defaultStore.getDateFormat())
      return "Sending on #{formattedDate} at #{DateUtils.getTime(dateTime)}"

   failValidation: (notice) =>
      @actionButtonClicked = false
      return @displayingNotice(notice)

   maybeShowValidationError: (next) =>
      return @failValidation(CreateMessageOrAlertPane.Notice.NO_CONTENT) unless validateInput(@content())
      return @failValidation(CreateMessageOrAlertPane.Notice.NO_RECIPIENTS) unless @recipientIds().length > 0
      foundValidSendingMethod = false
      for recipient in  @recipients()
         if (recipient.canRecieveEmail() and recipient.email()) or( recipient.canRecieveSms() and recipient.phone()) or (recipient.canRecieveMobile() and recipient.hasNotifiableDevices())
            foundValidSendingMethod = true
            break
      return @failValidation(CreateMessageOrAlertPane.Notice.NO_RECIPIENTS) unless foundValidSendingMethod
      if @showingScheduleControls() and (!@scheduledDate()? or !@scheduledTime()?)
         return @failValidation(CreateMessageOrAlertPane.Notice.INVALID_SCHEDULE)
      next()

   getMessageData_: ->
      data = {
         content: @content()
         include_signature: false
         is_private: @isPrivate()
         recipient_ids: @recipientIds()
         is_group: @selectedMessageType().value() == "group"
      }
      data['subject'] = @subject() if validateInput(@subject)
      return data

   saveEdit: (context) ->
      return if @actionButtonClicked
      @actionButtonClicked = true
      existingIdIndex = @recipientIds().indexOf(@existingRecipientIds[0])
      data = @getMessageData_()
      data['context'] = context
      if @scheduledDate()?
         data['context'] = "scheduled"
         data['detached_day'] = DateUtils.getDetachedDay(@scheduledDate())
         data['time'] =  @scheduledTime()

         data['send_at'] = (() =>
            [ newHours, newMinutes = 0 ] = String(@scheduledTime()).split(".")

            newMinutes = newMinutes + "0" if String(newMinutes).length == 1
            newMinutes = Math.round(newMinutes * 60 / 100)
            newSendAt = @scheduledDate()
            newSendAt.setHours(newHours)
            newSendAt.setMinutes(newMinutes)
            return newSendAt.getTime()
         )()

      @displayingNotice(CreateMessageOrAlertPane.Notice.SAVING)
      if @isAlert
         try
            payloadRequest = {
               params: {
                  alert_id: @existingData.alertId
               }
               body: data
            }
            alertUpdated = await AlertStore.updateAlert(payloadRequest).payload
            @data.set("updatedAlert", new Alert(alertUpdated.data))
            @displayingNotice(null)
            return modalManager.modalFinished()
         catch err
            if err?
               if err.detailCode? and err.detailCode == "noChangesMade"
                  @displayingNotice(null)
                  return modalManager.modalFinished()
               else
                  @actionButtonClicked = false
                  console.error("CreateMessageOrAlertPane saveEdit 0 - Error: ", err)
                  Bugsnag.notify(err, (event) =>
                     event.context = "create-message-or-alert-pane__saveEdit__0";
                     event.addMetadata(
                        BUGSNAG_META_TAB.USER_DATA,
                        buildUserData(authManager.authedUser(), authManager.activePermission),
                     );
                  );
                  return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)
      else
         data['send_draft'] = true if context == "sent"
         # Just update a existing group conversation
         if @selectedMessageType().value() == "group"
            convoId = @existingData.conversationId
            messageId = @existingData.messageId
            try
               payload = await ConversationStore.updateConversation(convoId, messageId, data).payload
               @data.set("updatedConvo", payload.data)
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               @actionButtonClicked = false
               console.error("CreateMessageOrAlertPane saveEdit 1 - Error: ", err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__saveEdit__1";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)

         # Catch a group message being broken into individual messages
         # TODO: Does this need to be a else if?
         if @editingConvoWasGroup and @selectedMessageType().value() != "group"
            groupId = @existingData.groupId
            conversationId = @existingData.conversationId
            try
               await ConversationStore.breakGroupIntoIndividualMessages(groupId, conversationId, data)
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               @actionButtonClicked = false
               return console.log("error: ", err)

         else if existingIdIndex >= 0 and @recipientIds().length == 1
            # Just update
            delete data.recipient_ids
            convoId = @existingData.conversationId
            messageId = @existingData.messageId
            try
               payload = await ConversationStore.updateConversation(convoId, messageId, data).payload
               @data.set("updatedConvo", payload.data)
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               @actionButtonClicked = false
               console.error("CreateMessageOrAlertPane saveEdit 2 - Error: ", err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__saveEdit__2";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)

         else if @recipientIds().length >= 1 and @selectedMessageType().value() != "group"
            data.recipient_ids.splice(existingIdIndex, 1) if existingIdIndex >= 0
            # New people to add conversations for.
            creationSucceeded = false
            updateSucceeded = false
            maybeCloseModal = =>
               return unless updateSucceeded if existingIdIndex >= 0
               return unless creationSucceeded
               @displayingNotice(null)
               modalManager.modalFinished()

            try
               # if record has data.subject, change name to original_subject before sending off request
               data["original_subject"] = if data["subject"] then data["subject"] else null
               payload = await MessageStore.createInitialMessageAndConversation(data, @projectId).payload
               creationSucceeded = true
               @data.set("newConvos", payload.data.map((convo) => new Conversation(convo)))
               maybeCloseModal()
            catch err
               @actionButtonClicked = false
               console.error("CreateMessageOrAlertPane saveEdit 3 - Error: ", err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__saveEdit__3";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)
 
            if existingIdIndex >= 0
               updateData = @getMessageData_()
               updateData['context'] = context
               delete updateData.recipient_ids
               convoId = @existingData.conversationId
               messageId = @existingData.messageId
               try
                  payload = await ConversationStore.updateConversation(convoId, messageId, updateData).payload
                  @data.set("updatedConvo", payload.data)
                  updateSucceeded = true
                  maybeCloseModal()
               catch err
                  @actionButtonClicked = false
                  console.error("CreateMessageOrAlertPane saveEdit 4 - Error: ", err)
                  Bugsnag.notify(err, (event) =>
                     event.context = "create-message-or-alert-pane__saveEdit__4";
                     event.addMetadata(
                        BUGSNAG_META_TAB.USER_DATA,
                        buildUserData(authManager.authedUser(), authManager.activePermission),
                     );
                  );
                  return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)
            else
               try
                  await ConversationStore.deleteConversation(@existingData.conversationId).payload
                  @data.set("deletedConvoId", @existingData.conversationId)
                  updateSucceeded = true
                  maybeCloseModal()
               catch err
                  @actionButtonClicked = false
                  console.error("CreateMessageOrAlertPane saveEdit 5 - Error: ", err)
                  Bugsnag.notify(err, (event) =>
                     event.context = "create-message-or-alert-pane__saveEdit__5";
                     event.addMetadata(
                        BUGSNAG_META_TAB.USER_DATA,
                        buildUserData(authManager.authedUser(), authManager.activePermission),
                     );
                  );
                  return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)
         else
            return modalManager.modalFinished()

   sendNow: =>
      return if @actionButtonClicked
      @actionButtonClicked = true
      @displayingNotice(CreateMessageOrAlertPane.Notice.SAVING)
      @maybeShowValidationError =>
         creationCallback = (err, conversations) =>
            if err?
               @actionButtonClicked = false
               console.error("sendNow error: ", err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__sendNow";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
                  event.addMetadata(
                     "error",
                     err,
                  );
                  event.addMetadata(
                     "message data",
                     @getMessageData_(),
                  );
                  event.addMetadata(
                     "meta",
                     {
                        isEditing: @isEditing,
                        isAlert: @isAlert,
                        projectId: @projectId,
                        assignmentId: @assignmentId,
                        scheduledDate: @scheduledDate(),
                        detachedDay: DateUtils.getDetachedDay(@scheduledDate()),
                        time: @scheduledTime(),
                     },
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)
            @displayingNotice(null)
            @data.set("newConvos", conversations)
            modalManager.modalFinished()
         if @scheduledDate()?
            context = "scheduled"
         else
            context = if @isAlert then "open" else "sent"
         if @isEditing
            @actionButtonClicked = false
            # We are doing this so we actually move the draft out and not just create a new message.
            return @saveEdit(context) 
         data = @getMessageData_()
         data['context'] = context
         if @scheduledDate()?
            data['detached_day'] = DateUtils.getDetachedDay(@scheduledDate())
            data['time'] =  @scheduledTime()
            data['send_at'] = (() =>
               [ newHours, newMinutes = 0 ] = String(@scheduledTime()).split(".")

               newMinutes = newMinutes + "0" if String(newMinutes).length == 1
               newMinutes = Math.round(newMinutes * 60 / 100)
               newSendAt = @scheduledDate()
               newSendAt.setHours(newHours)
               newSendAt.setMinutes(newMinutes)
               return newSendAt.getTime()
            )()
            if @isAlert
               try
                  data['project_id'] = @projectId
                  data['assignment_id'] = @assignmentId
                  if authManager.selectedGroupId() != "my-groups"
                      data["group_id"] = authManager.selectedGroupId()

                  payloadRequest = {
                     body: data
                  }
                  payload = await AlertStore.scheduleAlert(payloadRequest).payload
                  creationCallback(null, payload.data)
               catch err
                  creationCallback(err)
            else
               try
                  data["project_id"] = @projectId if @projectId
                  data["original_subject"] = if data["subject"] then data["subject"] else null
                  payload = await MessageStore.scheduleInitialMessageAndConversation(data).payload
                  creationCallback(null, payload.data)
               catch err
                  creationCallback(err)
         else
           if @isAlert
               try
                  data['project_id'] = @projectId
                  data['assignment_id'] = @assignmentId
                  if authManager.selectedGroupId() != "my-groups"
                     data["group_id"] = authManager.selectedGroupId()
                  payload = await AlertStore.createAlert(data).payload
                  creationCallback(null, payload.data)
               catch err
                  console.error("Error creating alert:", err)
                  creationCallback(err)
            else
               try
                  # if record has data.subject, change name to original_subject before sending off request
                  data["original_subject"] = if data["subject"] then data["subject"] else null
                  payload = await MessageStore.createInitialMessageAndConversation(data, @projectId).payload
                  newConversations = payload.data.map((convo) => new Conversation(convo))
                  creationCallback(null, newConversations)
               catch err
                  console.error("Error creating message:", err)
                  creationCallback(err)

   saveAsDraft: ->
      return if @actionButtonClicked
      @actionButtonClicked = true
      @maybeShowValidationError =>
         data = @getMessageData_()
         data['context'] = "drafts"
         @displayingNotice(CreateMessageOrAlertPane.Notice.SAVING)
         if @isAlert
            try
               data['project_id'] = @projectId
               data['assignment_id'] = @assignmentId
               if authManager.selectedGroupId() != "my-groups"
                  data["group_id"] = authManager.selectedGroupId()
               await AlertStore.createAlert(data).payload
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               @actionButtonClicked = false
               console.error("saveAsDraft error 1:",err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__saveAsDraft__1";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)               
         else
            try
               # if record has data.subject, change name to original_subject before sending off request
               data["original_subject"] = if data["subject"] then data["subject"] else null
               payload = await MessageStore.createInitialMessageAndConversation(data, @projectId).payload
               @displayingNotice(null)
               @data.set("newConvos", payload.data.map((convo) => new Conversation(convo)))
               modalManager.modalFinished()
            catch err
               @actionButtonClicked = false
               console.error("saveAsDraft error 2:",err)
               Bugsnag.notify(err, (event) =>
                  event.context = "create-message-or-alert-pane__saveAsDraft__2";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser(), authManager.activePermission),
                  );
               );
               return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)

   actionBtnIntercept: ->
      @maybeShowValidationError =>
         return @saveEdit(@existingData.context)

   deleteConversation: ->
      return if @actionButtonClicked
      @actionButtonClicked = true
      @displayingNotice(CreateMessageOrAlertPane.Notice.SAVING)
      try
         await ConversationStore.deleteConversation(@existingData.conversationId).payload
         @data.set("deletedConvoId", @existingData.conversationId)
         @displayingNotice(null)
         modalManager.modalFinished()
      catch err
         @actionButtonClicked = false
         console.error("CreateMessageOrAlertPane deleteConversation - Error: ", err)
         Bugsnag.notify(err, (event) =>
            event.context = "create-message-or-alert-pane__deleteConversation";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser(), authManager.activePermission),
            );
         );
         return @displayingNotice(CreateMessageOrAlertPane.Notice.SAVE_FAILED)

   loadData: ->
      lastNamesFirst = authManager.authedUser().preferences().displayLastNamesFirst()
      try
         response = await PersonStore.getPeopleForMessage().payload
         people = response.data.map (person) ->
            return new Person(person, true)
         @peoplePool(people)
         @peopleOptions people.map (person) =>
            valSet = new ValueSet({
               name: if lastNamesFirst then "#{person.lastName()}, #{person.firstName()}" else "#{person.firstName()} #{person.lastName()}"
               value: person.id
            })
            @recipients.push(person) if @existingRecipientIds.indexOf(person.id) != -1
            return valSet
      catch err
         Bugsnag.notify(err, (event) =>
            event.context = "create-message-or-alert-pane__loadData";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser(), authManager.activePermission),
            );
            event.addMetadata(
               "PeopleForMessage",
               response.data
            )
         );


CreateMessageOrAlertPane.Notice = {
   NO_CONTENT: {
      text: 'Your message must have content'
      info: null
      color: 'red'
      dissmissable: true
   }
   NO_RECIPIENTS: {
      text: 'Must have at least 1 recipient with a valid sending method'
      info: null
      color: 'red'
      dissmissable: true
   }
   INVALID_SCHEDULE: {
      text: 'Scheduled messages must have both a date and time'
      info: null
      color: 'red'
      dissmissable: true
   }
   SAVING: {
      text: 'Processing Message...'
      info: null
      color: 'green'
      dissmissable: false
   }
   SAVE_FAILED: {
      text: 'This message failed to process, please close and try again.'
      info: null
      color: 'red'
      dissmissable: false
   }
}

CreateMessageOrAlertPane.AllTakenFillerVal = "all-taken"

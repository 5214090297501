import "./quantity-editor-element.styl";
import template from "./quantity-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import { observable } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { EditorElementParams } from "../common/editor-element-template";
import type { TextFieldParams } from "@/lib/components/text-field/text-field";
import { TextFieldType } from "@/lib/components/text-field/text-field";

export type QuantityEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   value: PureComputed<number | null>;
} & EditorElementParams<number | null>;

export class QuantityEditorElement extends ViewModel {
   readonly invalidInputMessage: QuantityEditorElementParams["invalidInputMessage"];
   readonly onChange: QuantityEditorElementParams["onChange"];
   readonly title: QuantityEditorElementParams["title"];
   readonly value: QuantityEditorElementParams["value"];

   readonly textFieldMediator = pureComputed<string | null>({
      read: () => this.value()?.toString() ?? null,
      write: (value) => {
         const val = value != null ? Number(value) : null;
         this.onChange(val == null || isNaN(val) ? null : val);
      },
   });

   private readonly textFieldParams: TextFieldParams;

   constructor({ invalidInputMessage, onChange, title, value }: QuantityEditorElementParams) {
      super(template());

      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.title = title;
      this.value = value;

      this.textFieldParams = {
         isClearable: observable(true),
         type: TextFieldType.NUMBER,
         value: this.textFieldMediator,
      };
   }

   static factory(params: QuantityEditorElementParams): ComponentArgs<QuantityEditorElementParams> {
      return {
         name: "quantity-editor-element",
         params,
      };
   }
}

ko.components.register("quantity-editor-element", {
   viewModel: QuantityEditorElement,
   template: template(),
   synchronous: true,
});

import template from "./fyi-info-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class FyiInfoPaneViewModel extends ModalPane
   constructor: (title, message) ->
      assertArgs(arguments, String, String)
      super("", "", template())
      @title = ko.observable(title)
      @message = message

   # confirm: ->
   #    modalManager.modalFinished()


import "./batch-config-pane.styl";
import template from "./batch-config-pane.pug";
import { defaultStore } from "@/stores/default-store";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import { authManager } from "@/lib/managers/auth-manager";
import { PermissionLevel } from "@/models/permission-level";

/* Popups */
import { PopupPane } from "@/lib/components/popup/popup-pane";
import { DateUtils } from "@/lib/utils/date";

/* UI Assets */
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";

import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import type { ValueSet } from "@/models/value-set";
import { EndDayOption } from "../../modals/editors/end-day-editor-element/end-day-editor-element";
import { getLastWorkDateInNumberOfWeeks } from "@/lib/utils/date-2";
import { AllocationType } from "../../modals/editors/allocation-type-editor-element/allocation-type-editor-element";
import {
   WeekDayFormat,
   DayFormat,
   MonthFormat,
   YearFormat,
} from "@laborchart-modules/common/dist/datetime";

// TODO: Move to shared location.
export enum MessageSendOption {
   DONT_SEND = "dont-send",
   SAVE_DRAFT = "save-draft",
   SCHEDULE = "schedule",
   SEND_INSTANTLY = "send-instantly",
}

export type BatchConfigPaneConfig = {
   editWorkDays: Observable<boolean>;
   endDate: Observable<Date | null>;
   endTime: Observable<number | null>;
   messageScheduleDate: Observable<Date | null>;
   messageScheduleTime: Observable<number | null>;
   messageType: Observable<MessageSendOption | null>;
   percentAllocated: Observable<number | null>;
   selectedCostCode: Observable<ValueSet | null>;
   selectedLabel: Observable<ValueSet | null>;
   selectedProject: Observable<ValueSet | null>;
   selectedStatus: Observable<ValueSet | null>;
   startDate: Observable<Date | null>;
   startTime: Observable<number | null>;

   sunday: Observable<boolean>;
   monday: Observable<boolean>;
   tuesday: Observable<boolean>;
   wednesday: Observable<boolean>;
   thursday: Observable<boolean>;
   friday: Observable<boolean>;
   saturday: Observable<boolean>;
};

const DATE_FORMAT_OPTIONS = {
   dayFormat: DayFormat.ONE_DIGIT,
   monthFormat: MonthFormat.FULL,
   weekdayFormat: WeekDayFormat.ABBREV,
   yearFormat: YearFormat.FULL,
};

class BatchConfigPaneState {
   //#region Local Constants
   readonly allocationOptions = [
      new SegmentedControllerItem("Work Hours", AllocationType.HOURS),
      new SegmentedControllerItem("Percent", AllocationType.PERCENT),
   ];
   readonly endTypeOptions = [
      new SegmentedControllerItem("Date", EndDayOption.DATE),
      new SegmentedControllerItem("Weeks", EndDayOption.WEEKS),
      new SegmentedControllerItem("TBD", EndDayOption.TBD),
   ];
   //#endregion

   readonly companyTbdWeeks: PureComputed<AssignmentSupportData["companyTbdWeeks"]>;
   readonly groupedCostCodeOptions: PureComputed<AssignmentSupportData["groupedCostCodeOptions"]>;
   readonly groupedLabelOptions: PureComputed<AssignmentSupportData["groupedLabelOptions"]>;
   readonly projectOptions: PureComputed<AssignmentSupportData["projectOptions"]>;
   readonly statusOptions: PureComputed<AssignmentSupportData["statusOptions"]>;

   private readonly internalState: {
      dontSend: Observable<boolean>;
      editWorkDays: Observable<boolean>;
      endDate: Observable<Date | null>;
      endTime: Observable<number | null>;
      messageScheduleDate: Observable<Date | null>;
      messageScheduleTime: Observable<number | null>;
      messageType: Observable<MessageSendOption | null>;
      numberOfWeeks: Observable<number | null>;
      percentAllocated: Observable<number | null>;
      saveDraft: Observable<boolean>;
      schedule: Observable<boolean>;
      selectedAllocationOption: Observable<SegmentedControllerItem<AllocationType>>;
      selectedCostCode: Observable<ValueSet | null>;
      selectedEndType: Observable<SegmentedControllerItem<EndDayOption>>;
      selectedLabel: Observable<ValueSet | null>;
      selectedProject: Observable<ValueSet | null>;
      selectedStatus: Observable<ValueSet | null>;
      sendInstantly: Observable<boolean>;
      startDate: Observable<Date | null>;
      startTime: Observable<number | null>;

      sunday: Observable<boolean>;
      monday: Observable<boolean>;
      tuesday: Observable<boolean>;
      wednesday: Observable<boolean>;
      thursday: Observable<boolean>;
      friday: Observable<boolean>;
      saturday: Observable<boolean>;
   };

   //#region Legacy Mediators
   readonly selectedCostCode = pureComputed({
      read: () => this.internalState.selectedCostCode(),
      write: (costCode: ValueSet | null) => {
         if (costCode?.value != this.internalState.selectedCostCode()?.value) {
            this.selectedLabel(null);
         }
         this.internalState.selectedCostCode(costCode);
      },
   });
   readonly selectedLabel = pureComputed({
      read: () => this.internalState.selectedLabel(),
      write: (label: ValueSet | null) => {
         this.internalState.selectedLabel(label);
      },
   });
   readonly selectedProject = pureComputed({
      read: () => this.internalState.selectedProject(),
      write: (project: ValueSet | null) => {
         if (project?.value != this.internalState.selectedProject()?.value) {
            this.selectedCostCode(null);
         }
         this.internalState.selectedProject(project);
      },
   });
   readonly selectedStatus = pureComputed({
      read: () => this.internalState.selectedStatus(),
      write: (status: ValueSet | null) => {
         this.internalState.selectedStatus(status);
      },
   });
   readonly startDate = pureComputed({
      read: () => this.internalState.startDate(),
      write: (startDate: Date | null) => {
         this.internalState.startDate(startDate);
         if (startDate == null) return;

         const endDate = this.endDate();

         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         } else if (endDate != null && startDate > endDate) {
            this.endDate(startDate);
         }
      },
   });

   readonly endDate = pureComputed({
      read: () => this.internalState.endDate(),
      write: (endDate: Date | null) => {
         this.internalState.endDate(endDate);
         if (endDate == null) return;

         const startDate = this.startDate();

         if (startDate != null && endDate < startDate) {
            this.startDate(endDate);
         }
      },
   });

   readonly startTime = pureComputed({
      read: () => this.internalState.startTime(),
      write: (startTime) => {
         this.internalState.startTime(startTime);
      },
   });

   readonly endTime = pureComputed({
      read: () => this.internalState.endTime(),
      write: (endTime) => {
         this.internalState.endTime(endTime);
      },
   });

   readonly percentAllocated = pureComputed({
      read: () => this.internalState.percentAllocated(),
      write: (percentAllocated) => {
         this.internalState.percentAllocated(percentAllocated);
      },
   });

   readonly selectedEndType = pureComputed({
      read: () => this.internalState.selectedEndType(),
      write: (endTypeItem) => {
         this.internalState.selectedEndType(endTypeItem);
         const endType = endTypeItem.value();

         if (endType == EndDayOption.DATE) {
            this.numberOfWeeks(null);
         } else if (endType == EndDayOption.TBD) {
            const startDate = this.startDate();
            if (startDate != null) {
               this.numberOfWeeks(this.companyTbdWeeks());
            }
         }
      },
   });

   readonly selectedAllocationOption = pureComputed({
      read: () => this.internalState.selectedAllocationOption(),
      write: (allocationTypeItem) => {
         this.internalState.selectedAllocationOption(allocationTypeItem);
         const allocationType = allocationTypeItem.value();
         if (allocationType === AllocationType.HOURS) {
            this.percentAllocated(null);
         } else if (allocationType === AllocationType.PERCENT) {
            this.startTime(null);
            this.endTime(null);
         }
      },
   });

   readonly numberOfWeeks = pureComputed({
      read: () => this.internalState.numberOfWeeks(),
      write: (numberOfWeeks) => {
         this.internalState.numberOfWeeks(numberOfWeeks);

         const startDate = this.startDate();
         if (numberOfWeeks == null || numberOfWeeks == 0) {
            this.endDate(null);
         } else if (startDate != null) {
            const endDate = getLastWorkDateInNumberOfWeeks({
               numberOfWeeks,
               startDate,
               workDays: this.workDays(),
            });

            this.endDate(endDate);
         }
      },
   });

   readonly editWorkDays = pureComputed({
      read: () => this.internalState.editWorkDays(),
      write: (value) => {
         this.internalState.editWorkDays(value);
      },
   });
   readonly sunday = pureComputed({
      read: () => this.internalState.sunday(),
      write: (value) => {
         this.internalState.sunday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly monday = pureComputed({
      read: () => this.internalState.monday(),
      write: (value) => {
         this.internalState.monday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly tuesday = pureComputed({
      read: () => this.internalState.tuesday(),
      write: (value) => {
         this.internalState.tuesday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly wednesday = pureComputed({
      read: () => this.internalState.wednesday(),
      write: (value) => {
         this.internalState.wednesday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly thursday = pureComputed({
      read: () => this.internalState.thursday(),
      write: (value) => {
         this.internalState.thursday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly friday = pureComputed({
      read: () => this.internalState.friday(),
      write: (value) => {
         this.internalState.friday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });
   readonly saturday = pureComputed({
      read: () => this.internalState.saturday(),
      write: (value) => {
         this.internalState.saturday(value);
         if ((this.numberOfWeeks() ?? 0) > 0) {
            // Trigger re-calculation.
            this.numberOfWeeks(this.numberOfWeeks());
         }
      },
   });

   readonly sendInstantly = pureComputed({
      read: () => this.internalState.sendInstantly(),
      write: (sendInstantly) => {
         this.internalState.sendInstantly(sendInstantly);
         if (sendInstantly === false) {
            return;
         }
         this.messageType(MessageSendOption.SEND_INSTANTLY);
         this.saveDraft(false);
         this.schedule(false);
         this.dontSend(false);
      },
   });

   readonly saveDraft = pureComputed({
      read: () => this.internalState.saveDraft(),
      write: (saveDraft) => {
         this.internalState.saveDraft(saveDraft);
         if (saveDraft === false) {
            return;
         }
         this.messageType(MessageSendOption.SAVE_DRAFT);
         this.sendInstantly(false);
         this.schedule(false);
         this.dontSend(false);
      },
   });

   readonly schedule = pureComputed({
      read: () => this.internalState.schedule(),
      write: (schedule) => {
         this.internalState.schedule(schedule);
         if (schedule === false) {
            return;
         }
         this.messageType(MessageSendOption.SCHEDULE);
         this.saveDraft(false);
         this.sendInstantly(false);
         this.dontSend(false);
      },
   });

   readonly dontSend = pureComputed({
      read: () => this.internalState.dontSend(),
      write: (dontSend) => {
         this.internalState.dontSend(dontSend);
         if (dontSend === false) {
            return;
         }
         this.messageType(MessageSendOption.DONT_SEND);
         this.saveDraft(false);
         this.schedule(false);
         this.sendInstantly(false);
      },
   });

   readonly messageType = pureComputed({
      read: () => this.internalState.messageType(),
      write: (messageType) => {
         this.internalState.messageType(messageType);
      },
   });

   readonly messageScheduleDate = pureComputed({
      read: () => this.internalState.messageScheduleDate(),
      write: (messageScheduleDate) => {
         this.internalState.messageScheduleDate(messageScheduleDate);
      },
   });

   readonly messageScheduleTime = pureComputed({
      read: () => this.internalState.messageScheduleTime(),
      write: (messageScheduleTime) => {
         this.internalState.messageScheduleTime(messageScheduleTime);
      },
   });
   //#endregion

   //#region Pure Computeds
   readonly allocationIsHours = pureComputed(() => {
      return this.selectedAllocationOption().value() === AllocationType.HOURS;
   });

   readonly assignmentHourDuration = pureComputed(() => {
      const startTime = this.startTime();
      const endTime = this.endTime();
      const allocationIsHours = this.allocationIsHours();
      if (allocationIsHours === false || startTime === null || endTime === null) {
         return "";
      }
      if (startTime < endTime) {
         return endTime - startTime;
      } else if (startTime > endTime) {
         // Overnight
         return endTime + (24 - startTime);
      } else {
         return "";
      }
   });

   readonly tbdEndDateString = pureComputed(() => {
      const selectedEndType = this.selectedEndType().value();
      const startDate = this.startDate();
      const endDate = this.endDate();
      if (selectedEndType != EndDayOption.TBD || startDate == null || endDate == null) {
         return null;
      }
      const tbdWeeks = this.companyTbdWeeks();
      const calculatedDateString = DateUtils.formatDate(
         endDate,
         defaultStore.getDateFormat(),
         DATE_FORMAT_OPTIONS,
      );
      return `${calculatedDateString} - ${tbdWeeks} Weeks`;
   });

   readonly weeksCalculatedDateString = pureComputed(() => {
      const numberOfWeeks = this.numberOfWeeks();
      const startDate = this.startDate();
      const endDate = this.endDate();
      if (endDate == null || numberOfWeeks == null || numberOfWeeks <= 0) {
         return "Enter # of weeks";
      }
      if (startDate == null) {
         return "Select Start Date";
      }
      return DateUtils.formatDate(endDate, defaultStore.getDateFormat(), DATE_FORMAT_OPTIONS);
   });

   readonly filteredCostCodeOptions = pureComputed(() => {
      const costCodeOptions = this.groupedCostCodeOptions();
      const selectedProject = this.selectedProject();

      if (costCodeOptions == null || selectedProject == null) return [];

      if (costCodeOptions[selectedProject.id!] != null) {
         return costCodeOptions[selectedProject.id!].filter(function (code) {
            return code.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly filteredLabelOptions = pureComputed(() => {
      const labelOptions = this.groupedLabelOptions();
      const selectedProject = this.selectedProject();
      const selectedCostCode = this.selectedCostCode();

      if (labelOptions == null || selectedProject == null || selectedCostCode == null) return [];

      if (labelOptions[selectedCostCode.value()] != null) {
         return labelOptions[selectedCostCode.value()].filter(function (label) {
            return label.baggage().archived === false;
         });
      } else {
         return [];
      }
   });

   readonly workDays = pureComputed(() => {
      return {
         0: this.internalState.sunday(),
         1: this.internalState.monday(),
         2: this.internalState.tuesday(),
         3: this.internalState.wednesday(),
         4: this.internalState.thursday(),
         5: this.internalState.friday(),
         6: this.internalState.saturday(),
      };
   });

   readonly rightActionBtnEnabled = pureComputed(() => {
      return (
         (this.sunday() ||
            this.monday() ||
            this.tuesday() ||
            this.wednesday() ||
            this.thursday() ||
            this.friday() ||
            this.saturday()) &&
         (!this.schedule() ||
            this.messageScheduleDate() != null ||
            this.messageScheduleTime() != null)
      );
   });
   //#endregion

   constructor({
      config,
      supportData,
   }: {
      config: BatchConfigPaneConfig;
      supportData: NestedComputedAssignmentSupportData;
   }) {
      this.companyTbdWeeks = supportData.companyTbdWeeks;
      this.groupedCostCodeOptions = supportData.groupedCostCodeOptions;
      this.groupedLabelOptions = supportData.groupedLabelOptions;
      this.projectOptions = supportData.projectOptions;
      this.statusOptions = supportData.statusOptions;

      this.internalState = {
         dontSend: observable(true),
         numberOfWeeks: observable(null),
         saveDraft: observable(false),
         schedule: observable(false),
         selectedAllocationOption: observable(this.allocationOptions[0]),
         selectedEndType: observable(this.endTypeOptions[0]),
         sendInstantly: observable(false),

         editWorkDays: config.editWorkDays,
         endDate: config.endDate,
         endTime: config.endTime,
         messageScheduleDate: config.messageScheduleDate,
         messageScheduleTime: config.messageScheduleTime,
         messageType: config.messageType,
         percentAllocated: config.percentAllocated,
         selectedCostCode: config.selectedCostCode,
         selectedLabel: config.selectedLabel,
         selectedProject: config.selectedProject,
         selectedStatus: config.selectedStatus,
         startDate: config.startDate,
         startTime: config.startTime,

         sunday: config.sunday,
         monday: config.monday,
         tuesday: config.tuesday,
         wednesday: config.wednesday,
         thursday: config.thursday,
         friday: config.friday,
         saturday: config.saturday,
      };

      const messageType = this.messageType();
      if (messageType != null && messageType !== MessageSendOption.DONT_SEND) {
         if (messageType === MessageSendOption.SAVE_DRAFT) {
            this.saveDraft(true);
         } else if (messageType === MessageSendOption.SCHEDULE) {
            this.schedule(true);
         } else if (messageType === MessageSendOption.SEND_INSTANTLY) {
            this.sendInstantly(true);
         }
      } else {
         this.messageType(MessageSendOption.DONT_SEND);
      }
   }
}

export class BatchConfigPane extends PopupPane {
   readonly canViewAllStatuses = authManager.checkAuthAction(
      PermissionLevel.Action.CAN_VIEW_ALL_STATUSES,
   );

   readonly state: BatchConfigPaneState;

   readonly enableOverflow = pureComputed(() => true);

   constructor(config: BatchConfigPaneConfig, supportData: NestedComputedAssignmentSupportData) {
      super(template());
      this.state = new BatchConfigPaneState({ config, supportData });

      this.rightActionBtn({
         text: "Set",
         callback: this.setChanges,
      });

      this.leftActionBtn({
         text: "Close",
         callback: this.closePopup,
      });
   }

   readonly setChanges = (): void => {
      if (
         this.state.schedule() &&
         (this.state.messageScheduleDate() == null || this.state.messageScheduleTime() == null)
      ) {
         return;
      }
      if (
         !(
            this.state.sunday() ||
            this.state.monday() ||
            this.state.tuesday() ||
            this.state.wednesday() ||
            this.state.thursday() ||
            this.state.friday() ||
            this.state.saturday()
         )
      ) {
         return;
      }
      return this.dismissHandler!();
   };

   readonly closePopup = (): void => {
      return this.dismissHandler!();
   };
}

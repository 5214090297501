import React, { useState, useEffect } from "react";
import type { CostCode, Project } from "@laborchart-modules/common";
import { LaborPlansForm } from "./labor-plans-form";
import { LaborPlansRequestsForm } from "./labor-plans-requests-form";
import type { DataTableRequest, LaborPlansContainerProps } from "./prop-types";
import { generateBellCurve, generateLinearPlan } from "./curve-generator";
import { calculateProjectDurationInWeeks, constructCoreApiRequests } from "./helpers";
import { useToastAlertContext } from "@procore/toast-alert";
import { DetailPage, useI18nContext } from "@procore/core-react";
import { DiscardChangesDialogue } from "../discard-changes-dialogue";
import { ProjectStore } from "@/stores/project-store.core";
import { CompanyStore } from "@/stores/company-store.core";
import { TotalsStore } from "@/stores/totals-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { RequestStore } from "@/stores/request-store.core";
import { CustomCurve } from "./custom-curve/custom-curve";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { StatusStore } from "@/stores/status-store.core";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";

export const LaborPlansContainer = (props: LaborPlansContainerProps) => {
   const { router, projectDetailsUrl, projectId, tearsheet = false, showProjectDetail } = props;
   const I18n = useI18nContext();
   const imagesUrl = `${(window as any).config.LC_DOMAIN}/images`;
   const [formState, setFormState] = useState<
      "basic_info" | "customizing_curve" | "finalizing_requests"
   >("basic_info");
   const [formValues, setFormValues] = useState<any>();
   const [requests, setRequests] = useState<any[]>([]);
   const [jobTitles, setJobTitles] = useState<any>([]);
   const [project, setProject] = useState<(Project & { categories: CostCode[] }) | undefined>();
   const [totalHours, setTotalHours] = useState<number | undefined>();
   const [tbdWeeks, setTbdWeeks] = useState<number | undefined>();
   const [curveError, setCurveError] = useState<boolean>(false);
   const [loading, setLoading] = useState(true);
   const [showConfirmDialogue, setShowConfirmDialogue] = useState<boolean>(false);
   const [onConfirmLeaveRoute, setOnConfirmLeaveRoute] = useState<string>("/home");
   const [selectedWorkDays, setSelectedWorkDays] = useState<any>({
      "0": false,
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": false,
   });
   const [canViewAllStatuses, setCanViewAllStatuses] = React.useState<boolean>(false);
   const [viewableStatuses, setViewableStatuses] = React.useState<
      Array<{ id: string; label: string }>
   >([]);
   const { checkAuthAction } = usePermissionContext();
   const { showToast } = useToastAlertContext();

   const getProject = async () => {
      setLoading(true);
      const project = await ProjectStore.getProjectDetails(projectId).payload;
      setProject(project.data as any);
      setLoading(false);
   };

   const getTbdWeeks = async () => {
      const result = await CompanyStore.getCompany().payload;
      setTbdWeeks(result.data.tbd_weeks);
   };

   const getTotals = async () => {
      const res = (
         await TotalsStore.getProjectTotals(projectId, {
            getAssignments: "true",
            getRequests: "true",
            totalsUnit: "hours",
            viewBy: "project",
         }).payload
      ).data;
      let total_hours = 0;
      // Sum up the totals response and set state
      const assignment_totals = res.rollup_data.daily_ass_totals;
      const request_totals = res.rollup_data.daily_request_totals;
      Object.keys(assignment_totals).forEach((key: string) => {
         total_hours += Number(assignment_totals[key]);
      });

      Object.keys(request_totals).forEach((key: string) => {
         total_hours += Number(request_totals[key]);
      });

      setTotalHours(total_hours);
   };

   const getViewableStatuses = async () => {
      const statuses = [];
      const stream = await StatusStore.findStatusesStream({}).stream;
      for await (const { id, name } of stream) {
         statuses.push({ id: id, label: name });
      }
      setViewableStatuses(statuses);
   };

   useEffect(() => {
      const canViewAllStatuses = checkAuthAction(AuthAction.CAN_VIEW_ALL_STATUSES);
      setCanViewAllStatuses(canViewAllStatuses);
      if (!canViewAllStatuses) {
         getViewableStatuses();
      }
      getProject();
      getTotals();
      getTbdWeeks();
   }, []);

   const getJobTitles = async (project: Project) => {
      let positions: JobTitle[] = [];
      let total_possible = -1;
      let starting_after = undefined;
      while (total_possible != positions.length) {
         const result: any = await PositionStore.findPositionsPaginated({
            limit: 100,
            group_ids: project.group_ids,
            starting_after: starting_after,
         }).payload;
         positions = positions.concat(result.data);
         total_possible = result.pagination.total_possible;
         starting_after = result.pagination.next_starting_after;
      }
      setJobTitles(positions);
   };

   const handleCustomCurveFinalized = async (requests: DataTableRequest[]) => {
      setRequests(requests);
      await getJobTitles(project as Project);
      setLoading(false);
      setFormState("finalizing_requests");
   };

   const handleNextClick = async (values: any) => {
      try {
         setCurveError(false);
         const duration = calculateProjectDurationInWeeks(project!, tbdWeeks);
         const work_hours = project!.daily_end_time - project!.daily_start_time;
         const max_peak_workers = values.max_peak_workers ?? null;
         const curve = values.labor_curve;

         // For standard curves, generate the requests and transition to the final screen
         setFormValues({
            ...values,
         });
         if (curve == "bell_curve" || curve == "linear") {
            setLoading(true);
            const requestsCurve =
               curve == "bell_curve"
                  ? generateBellCurve(
                       duration,
                       work_hours,
                       values.total_estimated_hours,
                       max_peak_workers,
                       values.work_days,
                       project as Project,
                    )
                  : generateLinearPlan(
                       duration,
                       work_hours,
                       values.total_estimated_hours,
                       max_peak_workers,
                       values.work_days,
                       project as Project,
                    );

            setRequests(
               requestsCurve.map((r: DataTableRequest) => ({
                  ...r,
                  status: canViewAllStatuses ? null : values.status,
               })),
            );
            await getJobTitles(project as Project);
            setLoading(false);
            setFormState("finalizing_requests");
         } else if (curve == "custom_curve") {
            setFormState("customizing_curve");
         }
      } catch (err) {
         setLoading(false);
         setCurveError(true);
      }
   };

   const onCancel = () => {
      if (tearsheet && showProjectDetail) {
         showProjectDetail(projectId);
      }
      setOnConfirmLeaveRoute("back");
      setShowConfirmDialogue(true);
   };

   const onBreadCrumbClick = (route: string) => {
      setOnConfirmLeaveRoute(route);
      setShowConfirmDialogue(true);
   };

   const onAddRequests = async (requests: DataTableRequest[]) => {
      try {
         setLoading(true);
         const totalRequests = requests.reduce(
            (sum: number, r: DataTableRequest) => sum + r.weekly_workers,
            0,
         );
         const apiReadyRequests = constructCoreApiRequests(
            requests,
            selectedWorkDays,
            project!,
            formValues.status?.id,
         );
         await RequestStore.batchCreateRequests({
            requests: apiReadyRequests,
            labor_plan: {
               labor_curve: formValues.labor_curve,
               requests_count: totalRequests,
            },
         }).payload;
         showToast.success(
            I18n.t("views.company.workforce_planning.labor_plan.toasts.success", {
               project: project?.name,
            }),
         );
         if (props.fromGantt) {
            router?.back();
         } else if (tearsheet && showProjectDetail) {
            showProjectDetail(projectId);
         } else {
            router?.navigate(null, projectDetailsUrl ?? "/home");
         }
      } catch (err) {
         showToast.error(I18n.t("views.company.workforce_planning.labor_plan.toasts.failure"));
         router?.back();
      }
   };

   let form;

   switch (formState) {
      case "basic_info": {
         form = (
            <LaborPlansForm
               project={project!}
               totalHours={totalHours ?? 0}
               tbdWeeks={tbdWeeks ?? 0}
               loading={loading}
               selectedWorkDays={selectedWorkDays}
               projectDetailsUrl={projectDetailsUrl}
               imagesUrl={imagesUrl}
               setSelectedWorkDays={setSelectedWorkDays}
               handleNextClick={handleNextClick}
               onCancel={onCancel}
               onBreadCrumbClick={onBreadCrumbClick}
               curveError={curveError}
               tearsheet={tearsheet}
               canViewAllStatuses={canViewAllStatuses}
               viewableStatuses={viewableStatuses}
            />
         );
         break;
      }
      case "customizing_curve": {
         form = (
            <CustomCurve
               project={project!}
               totalHours={totalHours ?? 0}
               tbdWeeks={tbdWeeks ?? 0}
               loading={loading}
               selectedWorkDays={selectedWorkDays}
               projectDetailsUrl={projectDetailsUrl}
               onCancel={onCancel}
               onBack={() => setFormState("basic_info")}
               onBreadCrumbClick={onBreadCrumbClick}
               curveError={curveError}
               tearsheet={tearsheet}
               formValues={formValues}
               onNext={handleCustomCurveFinalized}
               imagesUrl={imagesUrl}
            />
         );
         break;
      }
      case "finalizing_requests": {
         form = (
            <LaborPlansRequestsForm
               loading={loading}
               requests={requests}
               project={project as any}
               formValues={formValues}
               projectDetailsUrl={projectDetailsUrl}
               selectedWorkDays={selectedWorkDays}
               jobTitles={jobTitles}
               onBack={() => setFormState("basic_info")}
               onAddRequests={onAddRequests}
               onCancel={onCancel}
               onBreadCrumbClick={onBreadCrumbClick}
               tearsheet={tearsheet}
               canViewAllStatuses={canViewAllStatuses}
            />
         );
         break;
      }
   }

   return (
      <DetailPage width={tearsheet ? "xl" : "lg"} style={{ height: "100%", marginBottom: "67px" }}>
         <DetailPage.Main
            style={{ width: tearsheet ? "952px" : "", height: "100%" }}
            className={"detail-page-main"}
         >
            <DiscardChangesDialogue
               open={showConfirmDialogue}
               header={I18n.t("views.company.workforce_planning.discard_changes")}
               body={I18n.t("views.company.workforce_planning.discard_changes_warning")}
               onClose={() => setShowConfirmDialogue(false)}
               onConfirm={() => {
                  if (onConfirmLeaveRoute == "back") {
                     router?.back();
                  } else {
                     router?.navigate(null, onConfirmLeaveRoute);
                  }
                  setShowConfirmDialogue(false);
               }}
            />
            {form}
         </DetailPage.Main>
      </DetailPage>
   );
};

import React from "react";
import { ProjectTearsheetProvider } from "../tearsheets/project/project-tearsheet";
import { AssignmentTearsheetProvider } from "../tearsheets/assignment/assignment-tearsheet";
import { RequestTearsheetProvider } from "../tearsheets/request/request-tearsheet";
import type { TableApi } from "@procore/data-table";

export default function TearsheetProvider({
   children,
   projectsTableApi,
}: {
   children: React.ReactNode;
   projectsTableApi: TableApi | undefined;
}) {
   const providers = [
      ProjectTearsheetProvider,
      AssignmentTearsheetProvider,
      RequestTearsheetProvider,
   ];

   // Wrap the children in each provider so that all Tearsheets are accessible through this one provider.
   return providers.reduce((acc, Provider) => {
      return <Provider projectsTableApi={projectsTableApi}>{acc}</Provider>;
   }, children);
}

// and now this component will be able to access all the methods of the providers.
// <TearsheetProvider>
//    <Component></Component>
// </TearsheetProvider>

import React, { useEffect, useState } from "react";
import { IntegrationsStore } from "@/stores/integration-store.core";
import { Content, Spinner } from "@procore/core-react";
import { ShiftProjectToggle } from "./shift-project-toggle";
import { IntegratedEntity } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { EmptyState } from "../../empty-state/empty-state";
import { CompanyStore } from "@/stores/company-store.core";
import { SettingsStore } from "@/stores/settings-store.core";
import { AutoEndAssignmentsToggle } from "./auto-end-assignments-toggle";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export const IntegrationSettingsContainer = () => {
   const [integrations, setIntegrations] = useState<any[]>();
   const [company, setCompany] = useState<any>();
   const [loading, setLoading] = useState<boolean>(true);

   const fetchIntegrations = async () => {
      const data = await IntegrationsStore.getActiveIntegrations().payload;

      setIntegrations(data);
      setLoading(false);
   };
   const fetchCompany = async () => {
      const request = await CompanyStore.getCompany().payload;

      setCompany(request.data);
   };

   const toggleIntegrationShiftProject = async (integration: any) => {
      if (!integration) return;
      const payload = {
         shift_project: !integration.shift_project,
      };
      await IntegrationsStore.updateIntegration(integration.id, payload).payload;
      fetchIntegrations();
   };

   const toggleAutoEndAssignments = async (company: any) => {
      if (!company) return;
      const payload = {
         auto_end_assignments: !company.auto_end_assignments,
      };

      await SettingsStore.updateCompany(payload).payload;
      fetchCompany();
   };

   const integrationThatShiftsProject = integrations?.find((x: any) => {
      if (
         x.integration_type == "sql-remote" &&
         x.integrated_entities?.includes(IntegratedEntity.PROJECTS) &&
         x.active
      ) {
         return x;
      }
      if (x.integration_type == "procore" && x.active) {
         return x;
      }
      if (x.internal_procore && x.laborchart_procore_sync_project_source == "procore") {
         return x;
      }
   });

   const canToggleAutoEndingAssignments = LaunchDarklyClient.getFlagValue("auto-end-assignments");

   useEffect(() => {
      setLoading(true);
      fetchIntegrations();
      fetchCompany();
   }, []);

   if (canToggleAutoEndingAssignments) {
      return (
         <Spinner loading={loading}>
            <Content>
               {integrationThatShiftsProject ? (
                  <>
                     <ShiftProjectToggle
                        integration={integrationThatShiftsProject}
                        toggleIntegrationShiftProject={toggleIntegrationShiftProject}
                     />
                     <AutoEndAssignmentsToggle
                        company={company}
                        toggleAutoEndAssignments={toggleAutoEndAssignments}
                     />
                  </>
               ) : (
                  <>
                     <AutoEndAssignmentsToggle
                        company={company}
                        toggleAutoEndAssignments={toggleAutoEndAssignments}
                     />
                     <EmptyState
                        title={I18n.t(
                           "views.company.workforce_planning.settings.integration_settings.no_integrations",
                        )}
                        description={I18n.t(
                           "views.company.workforce_planning.settings.integration_settings.empty_state",
                        )}
                        compact
                     />
                  </>
               )}
            </Content>
         </Spinner>
      );
   } else {
      return (
         <Spinner loading={loading}>
            <Content>
               {integrationThatShiftsProject ? (
                  <ShiftProjectToggle
                     integration={integrationThatShiftsProject}
                     toggleIntegrationShiftProject={toggleIntegrationShiftProject}
                  />
               ) : (
                  <EmptyState
                     title={I18n.t(
                        "views.company.workforce_planning.settings.integration_settings.no_integrations",
                     )}
                     description={I18n.t(
                        "views.company.workforce_planning.settings.integration_settings.empty_state",
                     )}
                     compact
                  />
               )}
            </Content>
         </Spinner>
      );
   }
};

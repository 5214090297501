import React, { useState, useEffect } from "react";
import {
   Tearsheet,
   Button,
   useI18nContext,
   Form,
   Spinner,
   Page,
   H1,
   Card,
   Box,
   Flex,
   FlexList,
   Required,
} from "@procore/core-react";
import { Plus } from "@procore/core-icons";
import * as yup from "yup";
import { getOrderedTimeOptions } from "./helpers";
import { timeOffReasons, timeOptions } from "./constants";
import type { FormikProps } from "formik";
import { isAfter } from "date-fns";
import {
   type CreateTimeOffFormValues,
   type CreateTimeOffModalProps,
} from "../../time-off-list/time-off-list-prop-types";
import { ErrorBanner } from "../../error-banner";

import { DEFAULT_END_TIME, DEFAULT_START_TIME } from "@/react/shared/constants";
import { useToastAlertContext } from "@procore/toast-alert";

export const CreateTimeOffTearsheet = (props: CreateTimeOffModalProps) => {
   const { createTimeOff, afterCreate, streamPeople } = props;
   const I18n = useI18nContext();
   const [open, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const [people, setPeople] = useState();
   const { showToast } = useToastAlertContext();

   const fetchPeople = async () => {
      const people = await streamPeople();
      setPeople(people);
      setLoading(false);
   };

   useEffect(() => {
      if (open) {
         setLoading(true);
         fetchPeople();
      }
   }, [open]);

   const orderedTimeOptions = getOrderedTimeOptions(DEFAULT_START_TIME);

   const initialValues = {
      type: {
         id: 1,
         value: "paid",
         label: I18n.t("views.company.workforce_planning.time_off.paid"),
      },
      repeat: {
         id: 1,
         value: "never",
         label: I18n.t("views.company.workforce_planning.time_off.never"),
      },
      daily_start_time: timeOptions.find((x) => x.value == DEFAULT_START_TIME),
      daily_end_time: timeOptions.find((x) => x.value == DEFAULT_END_TIME),
      cadence: undefined,
   };

   const handleSubmit = async (values: CreateTimeOffFormValues) => {
      setLoading(true);
      try {
         await createTimeOff(values);
         setLoading(false);
         setOpen(false);
         showToast.success(
            I18n.t("views.company.workforce_planning.time_off.modals.success_toast"),
         );
         afterCreate();
      } catch (err) {
         setLoading(false);
         setError(true);
      }
   };

   return (
      <>
         <Button onClick={() => setOpen(true)} icon={<Plus />}>
            {I18n.t("views.company.workforce_planning.create")}
         </Button>
         <Tearsheet open={open} onClose={() => setOpen(false)}>
            <Spinner loading={loading}>
               <Page>
                  <Page.Main
                     style={{
                        width: "450px",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                     }}
                  >
                     <Page.Header>
                        {error && (
                           <Page.Banner>
                              <ErrorBanner
                                 title={I18n.t("views.company.workforce_planning.error")}
                                 content={I18n.t(
                                    "views.company.workforce_planning.time_off.modals.create_error",
                                 )}
                              />
                           </Page.Banner>
                        )}
                        <Page.Title>
                           <H1>
                              {I18n.t(
                                 "views.company.workforce_planning.time_off.modals.create_header",
                              )}
                           </H1>
                        </Page.Title>
                     </Page.Header>

                     <Form
                        view="create"
                        onSubmit={handleSubmit}
                        initialValues={initialValues as any}
                        validationSchema={yup.object().shape({
                           resource_id: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           start_date: yup
                              .date()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           end_date: yup
                              .date()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              )
                              .test(
                                 "validate-end-date",
                                 I18n.t(
                                    "views.company.workforce_planning.validations.end_date_before_start_date",
                                 ),
                                 function (endDate) {
                                    const { start_date } = this.parent;
                                    return endDate.getTime() >= start_date.getTime();
                                 },
                              ),
                           daily_start_time: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           daily_end_time: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           reason: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           type: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           repeat: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           cadence: yup.number().test(
                              "validate-cadence",
                              I18n.t("views.company.workforce_planning.validations.required_field"),
                              // If time off does not repeat, cadence can be undefined. Otherwise, cadence must  be set and >= 1
                              function (cadence) {
                                 const { repeat } = this.parent;
                                 if (repeat.value == "never") {
                                    return true;
                                 } else if (!cadence || cadence < 1) {
                                    return false;
                                 }
                                 return true;
                              },
                           ),
                           repeat_end_date: yup
                              .date()
                              .test(
                                 "validate-repeat-end-day",
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                                 // Repeat end date must be after end date if the time off is repeating
                                 function (repeat_end_date) {
                                    const { repeat } = this.parent;
                                    if (repeat.value == "never") {
                                       return true;
                                    } else if (!repeat_end_date) {
                                       return false;
                                    }
                                    return true;
                                 },
                              )
                              .test(
                                 "validate-repeat-end-date-after-end-date",
                                 I18n.t(
                                    "views.company.workforce_planning.validations.repeat_end_date_before_end_date",
                                 ),
                                 function (repeat_end_date) {
                                    const { end_date } = this.parent;
                                    if (
                                       repeat_end_date &&
                                       repeat_end_date.getTime() < end_date.getTime()
                                    ) {
                                       return false;
                                    }

                                    return true;
                                 },
                              ),
                        })}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {(formikProps: FormikProps<any>) => {
                           const { repeat } = formikProps.values;
                           return (
                              <Form.Form>
                                 <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                    <Card>
                                       <Box padding="md">
                                          <Form.Row>
                                             <Form.Select
                                                name="resource_id"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.resource",
                                                )}
                                                options={people}
                                                colStart={1}
                                                colWidth={11}
                                                block
                                                required
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.DateSelect
                                                name="start_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.start_date",
                                                )}
                                                colStart={1}
                                                colWidth={6}
                                                onChange={(date) => {
                                                   // Manually set end date when the user selects a start date if end date is not set.
                                                   // This mimics legacy behavior so new modal does not add clicks.
                                                   formikProps.setFieldValue("start_date", date);
                                                   if (!formikProps.values.end_date && date) {
                                                      formikProps.setFieldValue("end_date", date);
                                                   }
                                                }}
                                                required
                                             />
                                             <Form.DateSelect
                                                name="end_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.end_date",
                                                )}
                                                colStart={7}
                                                colWidth={6}
                                                disabledDate={(date) => {
                                                   const { start_date } = formikProps.values;

                                                   return isAfter(start_date, date);
                                                }}
                                                required
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="daily_start_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_start_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={1}
                                                colWidth={5}
                                                required
                                                onSearch={false}
                                             />
                                             <Form.Select
                                                name="daily_end_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_end_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={7}
                                                colWidth={5}
                                                required
                                                onSearch={false}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="reason"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.reason",
                                                )}
                                                options={timeOffReasons}
                                                required
                                                onSearch={false}
                                                colStart={1}
                                                colWidth={11}
                                                block
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.RadioButtons
                                                name="type"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.type",
                                                )}
                                                options={[
                                                   {
                                                      id: 1,
                                                      value: "paid",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.paid",
                                                      ),
                                                   },
                                                   {
                                                      id: 2,
                                                      value: "unpaid",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.unpaid",
                                                      ),
                                                   },
                                                ]}
                                             />
                                             <Form.Checkboxes
                                                colStart={7}
                                                name="weekend"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.weekends",
                                                )}
                                                options={[
                                                   {
                                                      id: 1,
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.apply_to_saturday",
                                                      ),
                                                      value: "apply_to_saturday",
                                                   },
                                                   {
                                                      id: 2,
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.apply_to_sunday",
                                                      ),
                                                      value: "apply_to_sunday",
                                                   },
                                                ]}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.RadioButtons
                                                name="repeat"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.repeat",
                                                )}
                                                options={[
                                                   {
                                                      id: 1,
                                                      value: "never",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.never",
                                                      ),
                                                   },
                                                   {
                                                      id: 2,
                                                      value: "weekly",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.weekly",
                                                      ),
                                                   },
                                                   {
                                                      id: 3,
                                                      value: "monthly",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.monthly",
                                                      ),
                                                   },
                                                   {
                                                      id: 4,
                                                      value: "yearly",
                                                      label: I18n.t(
                                                         "views.company.workforce_planning.time_off.yearly",
                                                      ),
                                                   },
                                                ]}
                                             />
                                             {repeat.value !== "never" && (
                                                <Form.Number
                                                   colStart={7}
                                                   colWidth={4}
                                                   min={1}
                                                   style={{ height: "36px", width: "145px" }}
                                                   name="cadence"
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.time_off.modals.labels.cadence",
                                                   )}
                                                   required
                                                   onChange={(e) => {
                                                      if (parseInt(e.value) < 1) {
                                                         formikProps.setFieldValue("cadence", "");
                                                      }
                                                   }}
                                                />
                                             )}
                                          </Form.Row>
                                          {repeat.value !== "never" && (
                                             // When showing repeat end day, use  negative top margin to group with cadence
                                             <Form.Row style={{ marginTop: "-90px" }}>
                                                <Form.DateSelect
                                                   colStart={7}
                                                   colWidth={4}
                                                   name="repeat_end_date"
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.time_off.modals.labels.repeat_end_date",
                                                   )}
                                                   disabledDate={(date) => {
                                                      const { end_date } = formikProps.values;

                                                      return isAfter(end_date, date);
                                                   }}
                                                   required
                                                />
                                             </Form.Row>
                                          )}
                                       </Box>
                                    </Card>
                                 </Page.Body>
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="md">
                                             <Button
                                                variant="tertiary"
                                                onClick={() => setOpen(false)}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button variant="primary" type="submit">
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              </Form.Form>
                           );
                        }}
                     </Form>
                  </Page.Main>
               </Page>
            </Spinner>
         </Tearsheet>
      </>
   );
};

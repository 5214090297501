import { type useI18nContext } from "@procore/core-react";
import { object, string, array, boolean } from "yup";

export const createPersonSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   phoneNumberValid: boolean,
) =>
   object().shape({
      first_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      last_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      group_ids: array().test(
         "group_ids",
         I18n.t("views.company.workforce_planning.people.validations.group_required"),
         /* istanbul ignore next */
         function (value) {
            if (this.parent.permission_level?.is_admin) {
               return true;
            }
            return value && value.length > 0;
         },
      ),
      employee_id: string(),
      type: object(),
      permission_level: object().nullable(),
      enable_email: boolean(),
      enable_sms: boolean(),
      email: string()
         .email(I18n.t("views.company.workforce_planning.validations.invalid_email"))
         .test(
            "email",
            I18n.t("views.company.workforce_planning.people.validations.email_required_for_user"),
            /* istanbul ignore next */
            function (value) {
               if (
                  this.parent.type.value === "user" ||
                  this.parent.type.value === "assignable_user"
               ) {
                  return !!value;
               }
               return true;
            },
         )
         .nullable(),
      phone: string()
         .test(
            "phone",
            I18n.t("views.company.workforce_planning.people.validations.invalid_phone_number"),
            () => phoneNumberValid,
         )
         .nullable(),
   });

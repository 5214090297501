import template from "./person-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import type { DropDown2Params } from "@/lib/components/drop-downs/drop-down-2";
import { PersonDropDownPane } from "@/lib/components/drop-downs/panes/person-drop-down-pane";
import { authManager } from "@/lib/managers/auth-manager";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers";
import type { EditorElementParams } from "../common/editor-element-template";
import { formatName } from "@/lib/utils/preferences";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";

export type PersonEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   person: PureComputed<SerializedPerson | null>;
} & EditorElementParams<SerializedPerson | null>;

export class PersonEditorElement extends ViewModel {
   readonly invalidInputMessage: PersonEditorElementParams["invalidInputMessage"];
   readonly onChange: PersonEditorElementParams["onChange"];
   readonly person: PersonEditorElementParams["person"];
   readonly title: PersonEditorElementParams["title"];

   readonly personMediator = pureComputed<SerializedPerson | null>({
      read: () => this.person(),
      write: (person) => {
         this.onChange(person);
      },
   });

   readonly dropDown2Params: DropDown2Params<SerializedPerson>;

   constructor({
      invalidInputMessage,
      onChange,
      person,
      title = "Person",
   }: PersonEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onChange = onChange;
      this.person = person;
      this.title = title;

      const pane = new PersonDropDownPane({
         groupIds:
            authManager.selectedGroupId() != "my-groups"
               ? new Set([authManager.selectedGroupId()])
               : null,
         isAssignable: true,
         status: PersonStatus.ACTIVE,
         transformer: (person) => person,
      });

      this.dropDown2Params = {
         cellFactory: TextCell.factory<SerializedPerson>((item) => formatName(item.name)),
         panes: [pane as any],
         selectedIds: pureComputed({
            read: () => {
               const person = this.person();
               return new Set(person ? [person.id] : []);
            },
            write: (ids: Set<string>) => {
               const loadedPeople = pane.gridStore()?.rows() ?? [];
               const selectedPerson = loadedPeople.find(
                  (person: SerializedPerson) => person.id == [...ids][0],
               );
               this.personMediator(selectedPerson ?? null);
            },
         }),
         selectedItem: this.personMediator,
         selectedItemCellFactory: TextCell.factory<SerializedPerson>((item) =>
            formatName(item.name),
         ),
      };
   }

   static factory(params: PersonEditorElementParams): ComponentArgs<PersonEditorElementParams> {
      return {
         name: "person-editor-element",
         params,
      };
   }
}

ko.components.register("person-editor-element", {
   viewModel: PersonEditorElement,
   template: template(),
   synchronous: true,
});

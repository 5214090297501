import "./multiline-text-cell.styl";
import type { GridCellFactory } from "../grid-column";
import type { GridCell } from "../grid-cell";
import template from "./multiline-text-cell.pug";
import ko from "knockout";

const PADDING = 5;
const LINE_HEIGHT = 18;

export interface MultilineTextCellValue {
   text: string;
}

export interface MultilineTextCellParams {
   values: MultilineTextCellValue[];
}

export class MultilineTextCell {
   readonly values: MultilineTextCellValue[];
   constructor(params: MultilineTextCellParams) {
      this.values = params.values;
   }

   static factory<T>(
      formatter: (data: T) => MultilineTextCellParams,
   ): GridCellFactory<T, MultilineTextCellParams> {
      return (data: T): GridCell<MultilineTextCellParams> => {
         const params = formatter(data);
         return {
            component: {
               name: "multiline-text-cell",
               params,
            },
            height: params.values.length * LINE_HEIGHT + PADDING,
         };
      };
   }

   static columnProviders<T>(provider: (data: T) => MultilineTextCellParams): {
      cellFactory: GridCellFactory<T>;
      copyProvider: (data: T) => string;
   } {
      return {
         cellFactory: MultilineTextCell.factory(provider),
         copyProvider: (data: T) =>
            provider(data)
               .values.map((v) => v.text)
               .join(", "),
      };
   }
}

ko.components.register("multiline-text-cell", {
   viewModel: MultilineTextCell,
   template: template(),
   synchronous: true,
});

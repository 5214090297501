import React from "react";
import { Button, Modal, Typography, useI18nContext } from "@procore/core-react";
import { useToastAlertContext } from "@procore/toast-alert";
import { Image, FileList } from "@procore/core-icons";
import { downloadFile } from "@/lib/reports/report-options";
import { authManager } from "@/lib/managers/auth-manager";
import type { FileDownloadModalProps } from "@/react/prop-types";

import styles from "../custom-style.scss";

import classnames from "classnames";

const cx = classnames.bind(styles);

export const FileDownloadModal: React.FC<FileDownloadModalProps> = ({
   open,
   onClose,
   fileName,
   uploadedOn,
   attachmentId,
   mimeType,
}) => {
   const I18n = useI18nContext();
   const [loading, setLoading] = React.useState(false);
   const { showToast } = useToastAlertContext();

   const handleDownload = async () => {
      try {
         setLoading(true);
         const companyId = authManager.companyId();
         const downloadUrl = `/api/download/companies/${companyId}/attachments/${attachmentId}`;
         // its a function used from a jquery library
         await downloadFile(downloadUrl);
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_download_message"));
      } finally {
         setLoading(false);
         onClose();
      }
   };

   return (
      <Modal open={open} onClose={onClose} width="sm">
         <Modal.Header className={cx("modal-center")}>
            {I18n.t(
               "views.company.workforce_planning.project_details_tags.tags_attachment_file_details",
            )}
         </Modal.Header>
         <Modal.Body className={cx("modal-center")}>
            {mimeType.includes("image") ? <Image size="lg" /> : <FileList size="lg" />}
            <Typography intent="h3">{fileName}</Typography>
            <Typography intent="small">
               {I18n.t(
                  "views.company.workforce_planning.project_details_tags.tags_file_uploaded_on",
               )}
               : {uploadedOn}
            </Typography>
         </Modal.Body>
         <Modal.Footer>
            <Modal.FooterButtons>
               <Button variant="secondary" onClick={onClose}>
                  {I18n.t("views.company.workforce_planning.close")}
               </Button>
               <Button variant="primary" onClick={handleDownload}>
                  {!loading && I18n.t("views.company.workforce_planning.download")}
                  {loading && I18n.t("views.company.workforce_planning.downloading")}
               </Button>
            </Modal.FooterButtons>
         </Modal.Footer>
      </Modal>
   );
};

import React, { useEffect, useState } from "react";
import { Box, Button, Grid, H3, Spinner, useI18nContext } from "@procore/core-react";
import { FileUpload } from "@/react/shared/components/file-upload";
import { ActionMode } from "@/react/prop-types";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import styles from "../project/project-detail-cards/project-details.scss";
import classnames from "classnames";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import type { AddAttachmentsProps, AttachmentsData } from "./types";
import { prepareAttachmentsPayload } from "../helper";
import { getAttachmentKeys } from "./helper";

const cx = classnames.bind(styles);

export const AddAttachments = ({
   availableAttachments,
   open,
   onClose,
   handleCreate,
   showLoader,
   pageType,
}: AddAttachmentsProps) => {
   const I18n = useI18nContext();
   const [attachments, setAttachments] = useState<SerializedAttachment[]>(
      availableAttachments ?? [],
   );
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      setAttachments(availableAttachments ?? []);
   }, [open]);

   const handleClose = () => {
      onClose();
      setAttachments([]);
   };

   const handleAttachmentCreation = async () => {
      const payload: AttachmentsData = prepareAttachmentsPayload(attachments, deletedAttachmentIds);
      await handleCreate(payload, ActionMode.SAVE);
      handleClose();
   };

   if (!open) {
      return null;
   }

   const { titleKey, buttonTextKey, helpTextKey } = getAttachmentKeys(pageType);

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey={titleKey}
            helpTextKey={helpTextKey}
            buttonTextKey={buttonTextKey}
            btnDisabled={true}
            permission={false}
            icon={null}
         />
         <Box padding="sm" className={cx("custom-box")}>
            <Grid>
               <Grid.Row>
                  <Grid.Col colWidth={12}>
                     <H3>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_tags.tags_attachments_label",
                        )}
                     </H3>
                     <FileUpload
                        attachments={attachments}
                        setAttachments={setAttachments}
                        deletedAttachmentIds={deletedAttachmentIds}
                        setDeletedAttachmentIds={setDeletedAttachmentIds}
                        loading={loading}
                        setLoading={setLoading}
                     />
                  </Grid.Col>
               </Grid.Row>
            </Grid>
            <Box
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               style={{ width: "100%" }}
               padding="sm"
            >
               <Button
                  variant="secondary"
                  onClick={handleClose}
                  style={{ marginRight: "8px" }}
                  disabled={loading}
               >
                  {I18n.t("views.company.workforce_planning.cancel")}
               </Button>

               <Button
                  variant="primary"
                  onClick={handleAttachmentCreation}
                  disabled={loading}
                  style={{ width: !showLoader ? "auto" : "70px" }}
               >
                  {!showLoader ? (
                     I18n.t("views.company.workforce_planning.save")
                  ) : (
                     <Spinner loading={true} size="md" color="white" />
                  )}
               </Button>
            </Box>
         </Box>
         <Box padding="xxs" />
      </React.Fragment>
   );
};

import type {
   StoreJsonResponse,
   StoreRequestErrorHandlingOptions,
   StoreStreamResponse,
} from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   FindActivityStreamQueryParams,
   FindActivityPaginatedQueryParams,
   FindActivityPaginatedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/activity/find-activity";
import type { FindActivityActivity } from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";

export abstract class ActivityStore extends Store {
   static findActivityStream(
      query: FindActivityStreamQueryParams,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreStreamResponse<FindActivityActivity> {
      return this.requestStream({
         url: "/api/v3/activity/stream",
         method: "GET",
         query,
         ...errorHandlingOptions,
      });
   }

   static findActivityPaginated(
      query: FindActivityPaginatedQueryParams,
      errorHandlingOptions: StoreRequestErrorHandlingOptions = {},
   ): StoreJsonResponse<FindActivityPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/activity/paginated",
         method: "GET",
         query,
         ...errorHandlingOptions,
      });
   }
}

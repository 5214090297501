import "./start-day-editor-element.styl";
import template from "./start-day-editor-element.pug";
import { ViewModel } from "@/lib/vm/viewmodel";
import type { PureComputed } from "knockout";
import ko, { pureComputed } from "knockout";
import type { ComponentArgs } from "@/lib/components/common";
import {
   DayFormat,
   MonthFormat,
   WeekDayFormat,
   YearFormat,
} from "@laborchart-modules/common/dist/datetime";
import { defaultStore } from "@/stores/default-store";
import { DateUtils } from "@/lib/utils/date";
import type { SegmentedController2Params } from "@/lib/components/segmented-controller-2/segmented-controller-2";
import type { DateInput2Params } from "@/lib/components/date-input-2/date-input-2";
import type { EditorElementParams } from "../common/editor-element-template";

export type StartDayEditorElementParams = {
   invalidInputMessage: PureComputed<string | null>;
   onStartDayChange: (day: Date | null) => void;
   onStartDayOptionChange: (option: StartDayOption) => void;
   startDay: PureComputed<Date | null>;
   startDayOption: PureComputed<StartDayOption>;
   title: string;
} & Omit<EditorElementParams<any>, "onChange">;

export const enum StartDayOption {
   DATE = "date",
   PROJECT_START = "project-start",
}

export class StartDayEditorElement extends ViewModel {
   readonly invalidInputMessage: StartDayEditorElementParams["invalidInputMessage"];
   readonly onStartDayChange: StartDayEditorElementParams["onStartDayChange"];
   readonly onStartDayOptionChange: StartDayEditorElementParams["onStartDayOptionChange"];
   readonly startDay: StartDayEditorElementParams["startDay"];
   readonly startDayOption: StartDayEditorElementParams["startDayOption"];
   readonly title: StartDayEditorElementParams["title"];

   readonly isOptionDate = pureComputed(() => this.startDayOption() === StartDayOption.DATE);
   readonly isOptionProjectStart = pureComputed(
      () => this.startDayOption() === StartDayOption.PROJECT_START,
   );

   private readonly dateInputParams: DateInput2Params;
   private readonly segmentedControllerParams: SegmentedController2Params<StartDayOption>;

   // Formatted date text.
   readonly formattedDateText = pureComputed(() => {
      const startDay = this.startDay();
      if (startDay == null) return null;
      return DateUtils.formatDate(startDay, defaultStore.getDateFormat(), {
         dayFormat: DayFormat.ONE_DIGIT,
         monthFormat: MonthFormat.FULL,
         weekdayFormat: WeekDayFormat.ABBREV,
         yearFormat: YearFormat.FULL,
      });
   });

   constructor({
      invalidInputMessage,
      onStartDayChange,
      onStartDayOptionChange,
      startDay,
      startDayOption,
      title = "Start Day",
   }: StartDayEditorElementParams) {
      super(template());
      this.invalidInputMessage = invalidInputMessage;
      this.onStartDayChange = onStartDayChange;
      this.onStartDayOptionChange = onStartDayOptionChange;
      this.startDay = startDay;
      this.startDayOption = startDayOption;
      this.title = title;

      this.dateInputParams = {
         date: pureComputed<Date | null>(() => this.startDay()),
         isInvalid: pureComputed(() => this.invalidInputMessage() != null),
         onDateChange: (startDay) => {
            this.onStartDayChange(startDay);
         },
      };

      this.segmentedControllerParams = {
         onChange: this.onStartDayOptionChange,
         options: [
            { name: "Date", value: StartDayOption.DATE, isSelected: this.isOptionDate },
            {
               name: "Project Start",
               value: StartDayOption.PROJECT_START,
               isSelected: this.isOptionProjectStart,
            },
         ],
         requestSize: () => {},
      };
   }

   static factory(params: StartDayEditorElementParams): ComponentArgs<StartDayEditorElementParams> {
      return {
         name: "start-day-editor-element",
         params,
      };
   }
}

ko.components.register("start-day-editor-element", {
   viewModel: StartDayEditorElement,
   template: template(),
   synchronous: true,
});

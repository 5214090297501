import { Icons } from "@/lib/managers/notification-manager"
import { Notification } from "@/lib/managers/notification-manager"
import { Action as NotificationAction } from "@/lib/managers/notification-manager"

### Auth, Real-Time & Stores ###
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { GeneratedReportStore } from "@/stores/generated-report-store.core"

### Models ###
import { GeneratedReport } from "@/models/generated-report"

GET_REPORT_FANOUT_TIMEOUT = 5000
GET_REPORT_REFRESH_INTERVAL = 3000
GET_REPORT_TOTAL_TIMEOUT = 60000
GET_REPORT_RETRY_LIMIT = Math.floor(GET_REPORT_TOTAL_TIMEOUT / GET_REPORT_REFRESH_INTERVAL)

export class GeneratedReportNotification extends Notification 
   constructor: (report) ->
      super(
         { icon: Icons.PENDING, text: "Generating reportsssss '#{report.name()}'"}
      )
      @report = report
      @stage = GeneratedReport.ProgressStage.PREPARING
      @percentComplete = null
      @estimatedTimeRemaining = null
      recievedProgressUpdate = false

      @subscriptionId = GeneratedReportStore.subscribeToGeneratedReportStatus report.id, {}, (err, status, progress) =>
         recievedProgressUpdate = true
         if err or status == GeneratedReport.ReportStatus.FAILED
            console.log "Error: ", err if err
            @setToErrorState_("Failed to generate report '#{report.name()}'. Please try again or contact support.")
         else if status == GeneratedReport.ReportStatus.COMPLETE
            @setToCompleted_()
         else if progress
            @updateProgress_(progress)
      
      @updateProgress_({ stage: GeneratedReport.ProgressStage.PREPARING })

      setTimeout =>
         @getReportStatus(1) unless recievedProgressUpdate  
      , GET_REPORT_FANOUT_TIMEOUT

   getReportStatus: (count) =>
      return if count > GET_REPORT_RETRY_LIMIT
      try
         result = await GeneratedReportStore.get(@report.id).payload 
         data = new GeneratedReport(result.data)
         status = data.reportStatus()
         if status == "complete"
            @setToCompleted_()
         else if status == 'failed'
            console.log "Report Failed: ", data
            @setToErrorState_("Failed to generate report '#{@report.name()}'. Please try again or contact support.")
         else
            setTimeout =>
               @getReportStatus(count+1)
            , GET_REPORT_REFRESH_INTERVAL
      catch error
         console.log "Error: ", error


   updateProgress_: (progress) ->
      if GeneratedReport.STAGE_ORDER.indexOf(progress.stage) > GeneratedReport.STAGE_ORDER.indexOf(@stage)
         @stage = progress.stage 

      if progress.percentage > @percentComplete 
         @percentComplete = progress.percentage 
         @estimatedTimeRemaining = progress.estimated_time_remaining

      if @stage == GeneratedReport.ProgressStage.PREPARING
         @text("Preparing to generate report '#{@report.name()}'");
      else if @stage == GeneratedReport.ProgressStage.AGGREGATING
         @text("Aggregating data for report '#{@report.name()}'");
      else if @stage == GeneratedReport.ProgressStage.RENDERING
         if @percentComplete && @estimatedTimeRemaining
            percent = Math.min(Math.floor(@percentComplete * 100) || 0, 99);
            timeRemaining = switch
               when @estimatedTimeRemaining < 45 * 1000 then "less than a minute"
               when @estimatedTimeRemaining < 90 * 1000 then "1 minute"
               else "#{Math.ceil(@estimatedTimeRemaining / 1000 / 60)} minutes"
            @text("Generating file for report '#{@report.name()}'... #{percent}% (#{timeRemaining})");
         else
            @text("Generating file for report '#{@report.name()}");

   setToCompleted_: () ->
      @icon(Icons.SUCCESS)
      @text("Report '#{@report.name()}' has been generated.")
      @actions([
         new NotificationAction({ 
            type: NotificationAction.Type.BLUE
            text: "Download",
            onClick: => @download_(),
         })
      ])
      @dispose_()

   setToErrorState_: (message) ->
      @icon(Icons.WARNING)
      @text(message)
      @actions([])
      @dispose_()

   download_: () ->
      try
         await GeneratedReportStore.download(@report.id);
      catch error
         console.error error
         notificationManager.dismiss(@)
         @dispose_()
         @setToErrorState_("Failed to download report '#{@report.name()}'.")
         return;

   dispose_: ->
      return unless @subscriptionId 
      fanoutManager.unsubscribe(
         @subscriptionId, FanoutManager.Channel.GENERATED_REPORT, generatedReportId: @report.id
      )
      @subscriptionId = null


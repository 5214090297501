import React from "react";
import { EmptyState as CoreEmptyState } from "@procore/core-react";

export const EmptyState = (props: any) => {
   const { compact, title, description } = props;

   // TODO: Enhance this to take in create functions and display proper options
   return (
      <CoreEmptyState>
         <CoreEmptyState compact={compact}>
            <CoreEmptyState.NoItemsWithoutCreatePermissions />
            <CoreEmptyState.Title>{title}</CoreEmptyState.Title>
            <CoreEmptyState.Description>{description}</CoreEmptyState.Description>
         </CoreEmptyState>
      </CoreEmptyState>
   );
};

import { PageType } from "../types";

export const getAttachmentKeys = (pageType: PageType) => {
   return {
      titleKey: "views.company.workforce_planning.project_detail_attachments.title", // common for both project and people
      buttonTextKey: "views.company.workforce_planning.edit", // common for both project and people
      helpTextKey: `views.company.workforce_planning.${
         pageType === PageType.PROJECT ? "project" : "people"
      }_detail_attachments.subtitle`, // specific to project or people
   };
};

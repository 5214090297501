import styled from "styled-components";
import { Box, Button, Card, Flex, colors, spacing } from "@procore/core-react";

export const StyledContainer = styled(Box)<{
   isStickyPage: boolean;
   opened: boolean;
}>`
   right: 1em;
   bottom: ${({ isStickyPage }: { isStickyPage?: boolean }) => {
      return isStickyPage ? "108px" : "5em";
   }};
   width: ${({ opened }: { opened?: boolean }) => {
      return opened ? "384px" : "256px";
   }};
   height: ${({ opened }: { opened?: boolean }) => (opened ? "312px" : "44px")};
   position: fixed;
   box-shadow: 0 10px 10px rgb(0 0 0 / 26%), 0 14px 28px rgb(0 0 0 / 25%);
   z-index: 1000;
   border-radius: 4px;
   overflow: hidden;
   transition: width 0.5s cubic-bezier(0, 1, 0, 1), height 0.5s cubic-bezier(0, 1, 0, 1);
   background: ${colors.gray96};
`;

export const StyledHeader = styled(Flex)`
   background: ${colors.gray10};
   height: 28px;
   line-height: 28px;
   padding: 8px 16px 8px 16px;
   box-sizing: content-box;
   color: white;
`;

export const StyledTitle = styled(Box)`
   font-size: 13px;
   font-weight: bold;
   color: ${colors.white};
   white-space: nowrap;
   overflow: hidden;
`;

export const StyledBody = styled(Box)`
   overflow-y: auto;
   max-height: calc(100% - 44px);
   padding: 4px 12px 4px 12px;
   align-self: center;
`;

export const StyledActions = styled(Box)`
   white-space: nowrap;
`;

export const StyledActionButton = styled(Button)`
   background: ${colors.gray10};
   padding: 0;
   height: 24px;

   &:hover {
      background: ${colors.gray30};
      box-shadow: none;
      outline: none;
   }

   &:focus {
      background: ${colors.gray10};
      box-shadow: none;
      outline: none;
   }

   &:active,
   &:disabled {
      background: ${colors.gray10};
      box-shadow: none;
      outline: none;
   }
`;

export const StyledCount = styled(Box)`
   display: inline-block;
   background: ${colors.gray30};
   color: ${colors.white};
   line-height: 20px;
   padding: 0 ${spacing.sm}px;
   margin: ${spacing.xs}px ${spacing.sm}px;
   border-radius: ${spacing.xs}px;
`;

export const StyledUnhoverButton = styled(Box)``;
export const StyledHoverButton = styled(Box)`
   display: none;
`;

export const FileDownloadToastCardContainer = styled(Card)`
   padding: 8px 12px;
   margin: 8px auto;
`;

export const FileDownloadToastCard = styled(Box)`
   display: flex;
   column-gap: 8px;
   justify-content: space-between;

   &:hover {
      ${StyledHoverButton} {
         display: block;
      }

      ${StyledUnhoverButton} {
         display: none;
      }
   }
`;

export const FileDownloadToastContent = styled(Box)`
   flex: 2 0;
   overflow: hidden;
   margin: auto;
`;

import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   ScheduleAlertResponse,
   ScheduleAlertRequest,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/schedule-alert";
import type { CloseAlertResponse } from "@laborchart-modules/lc-core-api/dist/api/alerts/close-alert";
import type {
   ProcessDefaultAlertForProjectRequest,
   ProcessDefaultAlertForProjectResponse,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/process-default-alert-for-project";
import type {
   SendOneOffProjectAlertsRequest,
   SendOneOffProjectAlertsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/send-one-off-project-alerts";
import type { SendAlertNowResponse } from "@laborchart-modules/lc-core-api/dist/api/alerts/send-alert-now";
import type { DeleteAlertResponse } from "@laborchart-modules/lc-core-api/dist/api/alerts/delete-alert";
import type { PromptAlertResponse } from "@laborchart-modules/lc-core-api/dist/api/alerts/prompt-alert";
import type {
   UpdateAlertRequest,
   UpdateAlertResponse,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/update-alert";

import type {
   CreateAlertPayload,
   CreateAlertResponse,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/create-alert";
import type {
   GetAlertsRequest,
   GetAlertsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/alerts/get-alerts";

export abstract class AlertStore extends Store {
   static scheduleAlert(request: ScheduleAlertRequest): StoreJsonResponse<ScheduleAlertResponse> {
      return this.requestJson({
         url: `/api/v3/schedule/alerts`,
         method: "POST",
         body: request.body,
      });
   }

   static closeAlert(alertId: string): StoreJsonResponse<CloseAlertResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/${alertId}/close`,
         method: "POST",
      });
   }

   static processDefaultAlertForProject(
      request: ProcessDefaultAlertForProjectRequest,
   ): StoreJsonResponse<ProcessDefaultAlertForProjectResponse> {
      return this.requestJson({
         url: `/api/v3/default-alert`,
         method: "POST",
         body: request.body,
      });
   }

   static sendOneOffProjectAlerts(
      request: SendOneOffProjectAlertsRequest,
   ): StoreJsonResponse<SendOneOffProjectAlertsResponse> {
      return this.requestJson({
         url: `/api/v3/one-off-alerts`,
         method: "POST",
         body: request.body,
      });
   }

   static sendAlertNow(alertId: string): StoreJsonResponse<SendAlertNowResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/${alertId}/send-now`,
         method: "POST",
      });
   }

   static deleteAlert(alertId: string): StoreJsonResponse<DeleteAlertResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/${alertId}`,
         method: "DELETE",
      });
   }

   static promptAlert(alertId: string): StoreJsonResponse<PromptAlertResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/${alertId}/prompt`,
         method: "POST",
      });
   }

   static updateAlert(request: UpdateAlertRequest): StoreJsonResponse<UpdateAlertResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/${request.params.alert_id}`,
         method: "PATCH",
         body: request.body,
      });
   }

   static createAlert(request: CreateAlertPayload): StoreJsonResponse<CreateAlertResponse> {
      return this.requestJson({
         url: `/api/v3/alerts`,
         method: "POST",
         body: request,
      });
   }

   static getAlerts(request: GetAlertsRequest): StoreJsonResponse<GetAlertsResponse> {
      return this.requestJson({
         url: `/api/v3/alerts/contexts/${request.params.context}`,
         method: "GET",
         query: request.query,
      });
   }
}

import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { Ban, Check } from "@procore/core-icons";
import { Button, Flex, P, Spinner, Table, useI18nContext } from "@procore/core-react";
import React from "react";
type RecipientsTableProps = {
   projectId: string;
   startDate: number;
   endDate: number;
   recipientsToIgnore: string[];
   setRecipientsToIgnore: (newValue: string[]) => void;
};

export const RecipientsTable = (props: RecipientsTableProps) => {
   const I18n = useI18nContext();
   const { projectId, startDate, endDate, setRecipientsToIgnore, recipientsToIgnore } = props;
   const [recipients, setRecipients] = React.useState<any[]>([]);
   const [loading, setLoading] = React.useState<boolean>(true);

   async function fetchMessageRecipients() {
      const response: any = await Assignment2Store.getAssignedPeopleToMessage(projectId, {
         startDay: startDate,
         endDay: endDate,
      }).payload;
      const allRecipients = response.data.assignees.concat(response.data.defaultRecipients);
      setRecipients(allRecipients);
      setRecipientsToIgnore([]);
      setLoading(false);
   }
   React.useEffect(() => {
      setLoading(true);
      fetchMessageRecipients();
   }, [projectId, startDate, endDate]);

   function handleRemove(id: string) {
      const newRecipients = recipients.filter((r: any) => r.id !== id);
      setRecipientsToIgnore(recipientsToIgnore.concat(id));
      setRecipients(newRecipients);
   }

   return (
      <Spinner loading={loading}>
         <Table.Container>
            <Table>
               <Table.Header>
                  <Table.HeaderRow>
                     <Table.HeaderCell style={{ width: "40%" }}>
                        {I18n.t("views.company.workforce_planning.time_off.name")}
                     </Table.HeaderCell>
                     <Table.HeaderCell style={{ border: "none" }}>
                        {I18n.t("views.company.workforce_planning.people.email")}
                     </Table.HeaderCell>
                     <Table.HeaderCell style={{ border: "none" }}>
                        {I18n.t("views.company.workforce_planning.communications.sms")}
                     </Table.HeaderCell>
                     <Table.HeaderCell style={{ border: "none" }}>
                        {I18n.t("views.company.workforce_planning.communications.mobile")}
                     </Table.HeaderCell>
                     {/* The blank column cell below will be for a "Remove" button in the table body */}
                     <Table.HeaderCell style={{ border: "none" }} />
                  </Table.HeaderRow>
               </Table.Header>
               <Table.Body>
                  {recipients.map((recipient: any) => (
                     <Table.BodyRow key={`recipient-${recipient.id}`}>
                        <Table.BodyCell style={{ width: "50%" }}>
                           <Table.TextCell>
                              <P>{`${recipient.name.first} ${recipient.name.last}`}</P>
                           </Table.TextCell>
                        </Table.BodyCell>
                        <Table.BodyCell>
                           <Flex justifyContent="center">
                              <Table.IconCell>
                                 {recipient.can_recieve_email ? <Check /> : <Ban />}
                              </Table.IconCell>
                           </Flex>
                        </Table.BodyCell>
                        <Table.BodyCell>
                           <Flex justifyContent="center">
                              <Table.IconCell>
                                 {recipient.can_recieve_sms ? <Check /> : <Ban />}
                              </Table.IconCell>
                           </Flex>
                        </Table.BodyCell>
                        <Table.BodyCell>
                           <Flex justifyContent="center">
                              <Table.IconCell>
                                 {recipient.can_recieve_mobile ? <Check /> : <Ban />}
                              </Table.IconCell>
                           </Flex>
                        </Table.BodyCell>
                        <Table.BodyCell style={{ width: "30%" }}>
                           <Flex justifyContent="center">
                              <Button
                                 variant="secondary"
                                 onClick={() => handleRemove(recipient.id)}
                              >
                                 {I18n.t("views.company.workforce_planning.remove")}
                              </Button>
                           </Flex>
                        </Table.BodyCell>
                     </Table.BodyRow>
                  ))}
               </Table.Body>
            </Table>
         </Table.Container>
      </Spinner>
   );
};

import { Box, Flex, FlexList, useI18nContext } from "@procore/core-react";
import {
   ServerSideDataTable,
   LinkCellRenderer,
   TextCellRenderer,
   NumberCellRenderer,
   DateCellRenderer,
   DateCellEditor,
   SelectCellRenderer,
   SelectCellEditor,
   MultiSelectFilterRenderer,
   PillCellRenderer,
   PillSelectCellEditor,
} from "@procore/data-table";
import type {
   TableApi,
   ServerSideGetRowsParams,
   DataTableCellRendererProps,
   PillCellColumnDefinition,
   ColumnDefinition,
   DateCellColumnDefinition,
   LinkCellColumnDefinition,
   NumberCellColumnDefinition,
   TextCellColumnDefinition,
   SelectCellColumnDefinition,
} from "@procore/data-table";
import React, { useCallback, useEffect, useState, useRef } from "react";
import {
   ColorSelectEditor,
   ColorSelectRenderer,
} from "../data-table/ColorSelectComponent/ColorSelectColumn";
import { HidePastTimeOffFilter } from "./custom-filters/hide-past-time-off-filter";
import { DateFilter } from "../data-table/custom-filters/date-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import {
   defaultTimeOffTableConfig,
   columnHeadersMap,
   filterNameMaps,
   filterFieldMap,
   filterRendererMap,
   handleCellValueChanged,
   updateTimeOff,
} from "./time-off-list-helpers";
import {
   convertDataTableConfigToSavedView,
   getReorderedColumnDefinition,
} from "@/react/shared/helper";
import { CreateSavedViewModal } from "../modals/saved-view/create-saved-view-modal";
import { DateUtils } from "@/lib/utils/date";
import type { TimeOffListDataTableProps } from "./time-off-list-prop-types";
import type { SelectValue } from "@/react/prop-types";
import { getOrderedTimeOptions } from "../tearsheets/time-off/helpers";
import { timeOffReasons } from "../tearsheets/time-off/constants";
import { CreateTimeOffTearsheet } from "../tearsheets/time-off/create-time-off-tearsheet";
import { EditTimeOffTearsheet } from "../tearsheets/time-off/edit-time-off-tearsheet";
import { BulkEditTimeOffTearsheet } from "../tearsheets/time-off/bulk-edit-time-off-tearsheet";
import { DeleteTimeOffModal } from "./modals/confirm-delete-modal";
import { DateTimeFormatter } from "@procore/globalization-toolkit";
import { SavedViewPage } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { DEFAULT_START_TIME } from "@/react/shared/constants";
import { ExportFile } from "../data-table/DataExport/ExportFile";

const LOCAL_STORAGE_CONFIG_KEY = "timeOffDataTableConfig";

export const TimeOffListDataTable = (props: TimeOffListDataTableProps) => {
   const I18n = useI18nContext();
   const {
      fetchTimeOffList,
      groupId,
      createTimeOff,
      streamJobTitles,
      savedView,
      streamPeople,
      currentDateTime,
      bulkUpdateTimeOff,
   } = props;

   const dateTimeOptions = {
      locale: I18n.currentLocale,
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as any);

   const [tableApi, setTableApi] = useState<TableApi>();
   const nextStartAfter = useRef(undefined);

   const handleTableReady = (api: TableApi) => setTableApi(api);

   const orderedTimeOptions = getOrderedTimeOptions(DEFAULT_START_TIME);

   const cachedConfig =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)!) ?? defaultTimeOffTableConfig;
   const inititalConfig = savedView ? savedView : cachedConfig;
   const config = useRef(inititalConfig);

   const onTableConfigChange = React.useCallback(
      (config: any) => {
         if (!savedView) {
            localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(config));
         }
      },
      [savedView],
   );

   //If the Group ID prop changes, fetch the time off list again
   useEffect(() => {
      if (tableApi) {
         tableApi.refreshServerSide({});
      }
   }, [groupId]);

   const timeOffTypeOptions = [
      {
         id: 1,
         value: "paid",
         label: I18n.t("views.company.workforce_planning.time_off.paid"),
         color: "green",
      },
      {
         id: 2,
         value: "unpaid",
         label: I18n.t("views.company.workforce_planning.time_off.unpaid"),
         color: "yellow",
      },
   ];
   /* istanbul ignore next */
   const cellFormatters = {
      workers_name(value: any) {
         return value?.label;
      },
      job_titles(value: any) {
         return value?.label;
      },
      start_date(value: any) {
         return value ? DateUtils.getShortNumericDate(value) : "";
      },
      end_date(value: any) {
         return value ? DateUtils.getShortNumericDate(value) : "";
      },
      submitted_on(value: any) {
         return value ? DateUtils.getShortNumericDate(new Date(value)) : "";
      },
      type(value: any) {
         return value.id === 1
            ? I18n.t("views.company.workforce_planning.time_off.paid")
            : I18n.t("views.company.workforce_planning.time_off.unpaid");
      },
      daily_start_time(value: any) {
         return value?.label || value || "";
      },
      daily_end_time(value: any) {
         return value?.label || value || "";
      },
      reason(value: any) {
         if (value.value) {
            const snake_case_value = value.value
               ? value.value.split(" ").join("_")
               : value.split(" ").join("_");
            return I18n.t(`views.company.workforce_planning.time_off.reasons.${snake_case_value}`);
         } else {
            return value;
         }
      },
   };
   // DataTable Column definitions
   const workersNameColumn: LinkCellColumnDefinition = {
      field: "workers_name",
      headerName: I18n.t("views.company.workforce_planning.time_off.name"),
      cellRenderer: LinkCellRenderer,
      cellRendererParams: {
         getURL: (value) => {
            return value ? value.href : "";
         },
      },
      getStringFormattedValue: (value) => {
         return value.label;
      },
      flex: 10,
      cellCSVFormatter: cellFormatters.workers_name,
      hidden: false,
      lockVisible: true,
      sortable: true,
   };
   const personStatusTimeOffColumn: TextCellColumnDefinition = {
      field: "person_status",
      filterRenderer: StatusFilter as any,
      headerName: I18n.t("views.company.workforce_planning.person_status"),
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.person_status"),
         getFilterTokenText: (item: any) => {
            // Prefix text for the status
            const text = I18n.t("views.company.workforce_planning.projects.status") + ": ";

            // Create an array of translated status values
            const status: string[] = item.value.map((v: any) => {
               return I18n.t("views.company.workforce_planning." + v.value);
            });

            // Join the translated statuses with a comma and return the complete text
            return text + status.join(", ");
         },
         getLabel: (item: any) => item.label,
         index: 2,
      },
      flex: 2,
      cellRenderer: TextCellRenderer,
      getStringFormattedValue: (value) => {
         return value == "active"
            ? I18n.t("views.company.workforce_planning.active")
            : I18n.t("views.company.workforce_planning.inactive");
      },
      sortable: false,
   };
   const jobTitleColumn: ColumnDefinition = {
      field: "job_title",
      headerName: I18n.t("views.company.workforce_planning.job_title"),
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => item.color,
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      cellEditor: ColorSelectEditor,
      cellEditorParams: {
         getColor: (item: { color: string }) => item.color,
      },
      cellCSVFormatter: cellFormatters.job_titles,
      filterProps: {
         getFilterOptions: async () => {
            return await streamJobTitles();
         },
         getLabel: (item: { id: string; label: string }) => item.label,
         index: 0,
      },
      filterRenderer: MultiSelectFilterRenderer,
   };
   const startDateColumn: DateCellColumnDefinition = {
      field: "start_date",
      editable: (value) => {
         return value.data?.occurrences_count > 1 ? false : true;
      },
      bulkEditable: (value) => {
         return value.data?.occurrences_count > 1 ? false : true;
      },
      bulkEditEditor: "dateSelect",
      headerName: I18n.t("views.company.workforce_planning.start_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      filterRenderer: DateFilter as any,
      flex: 8,
      minWidth: 150,
      cellCSVFormatter: cellFormatters.start_date,
      valueValidator: (value) => {
         if (value.data?.start_date === undefined) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.required_field"),
               isRequired: true,
            };
         }
         return { isValid: true, isRequired: value.data?.occurrences_count > 1 ? false : true };
      },
      filterProps: {
         getFilterTokenText: (item: any) => {
            let tokenText = "";
            if (item.value.length > 0) {
               const selectedDate = dateFormatter.formatDateTime(new Date(item.value[0].date));
               const label = item.value[0].classifier.label;
               tokenText = `${item.headerName} ${label} ${selectedDate}`;
            }
            return tokenText;
         },
         getFilterHeadingText: () => "Time Off Start Date",
         index: 4,
      },
   };
   const endDateColumn: DateCellColumnDefinition = {
      field: "end_date",
      editable: (value) => {
         return value.data?.occurrences_count > 1 ? false : true;
      },
      bulkEditable: (value) => {
         return value.data?.occurrences_count > 1 ? false : true;
      },
      bulkEditEditor: "dateSelect",
      headerName: I18n.t("views.company.workforce_planning.end_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      filterRenderer: DateFilter as any,
      flex: 8,
      minWidth: 150,
      cellCSVFormatter: cellFormatters.end_date,
      valueValidator: (value) => {
         if (value.data?.end_date === undefined) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.required_field"),
               isRequired: true,
            };
         }
         if (value.data?.end_date?.getTime() < value.data?.start_date?.getTime()) {
            return {
               isValid: false,
               errorMessage: I18n.t(
                  "views.company.workforce_planning.validations.end_date_before_start_date",
               ),
               isRequired: true,
            };
         }
         return { isValid: true, isRequired: value.data?.occurrences_count > 1 ? false : true };
      },
      filterProps: {
         getFilterTokenText: (item: any) => {
            let tokenText = "";
            if (item.value.length > 0) {
               const selectedDate = dateFormatter.formatDateTime(new Date(item.value[0].date));
               const label = item.value[0].classifier.label;
               tokenText = `${item.headerName} ${label} ${selectedDate}`;
            }
            return tokenText;
         },
         getFilterHeadingText: () => "Time Off End Date",
         index: 5,
      },
   };
   const startTimeColumn: SelectCellColumnDefinition = {
      field: "daily_start_time",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.time_off.start_time"),
      flex: 5,
      editable: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => orderedTimeOptions,
         getId: (item: SelectValue<any>) => item?.label,
      },
      getStringFormattedValue: (value: SelectValue<any>) => {
         if (value === undefined) {
            return "--";
         }
         return value?.label;
      },
      cellCSVFormatter: cellFormatters.daily_start_time,
      bulkEditable: true,
      bulkEditEditor: "select",
      bulkEditEditorParams: {
         getOptions: () => {
            return orderedTimeOptions;
         },
      } as any,
   };
   const endTimeColumn: SelectCellColumnDefinition = {
      field: "daily_end_time",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.time_off.end_time"),
      flex: 6,
      editable: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => orderedTimeOptions,
         getId: (item: SelectValue<any>) => item?.label,
      },
      getStringFormattedValue: (value: SelectValue<any>) => {
         if (value === undefined) {
            return "--";
         }
         return value?.label;
      },
      cellCSVFormatter: cellFormatters.daily_end_time,
      bulkEditable: true,
      bulkEditEditor: "select",
      bulkEditEditorParams: {
         getOptions: () => {
            return orderedTimeOptions;
         },
      } as any,
   };
   const reasonColumn: SelectCellColumnDefinition = {
      field: "reason",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.time_off.reason"),
      flex: 7,
      filterProps: {
         getId: (item: any) => item.id,
         getLabel: (item: any) => item.label,
         getFilterOptions: () => {
            return timeOffReasons as any;
         },
         index: 3,
      },
      getStringFormattedValue(value) {
         /* istanbul ignore next */
         if (value.id) {
            const snake_case_value = value.value
               ? value.value.split(" ").join("_")
               : value.split(" ").join("_");
            return I18n.t(`views.company.workforce_planning.time_off.reasons.${snake_case_value}`);
         } else {
            return value;
         }
      },
      filterRenderer: MultiSelectFilterRenderer,
      editable: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => timeOffReasons,
         getId: (item: SelectValue<any>) => item?.value,
      },
      cellCSVFormatter: cellFormatters.reason,
      bulkEditable: true,
      bulkEditEditor: "select",
      bulkEditEditorParams: {
         getOptions: () => {
            return timeOffReasons;
         },
      } as any,
   };
   const typeColumn: PillCellColumnDefinition = {
      field: "type",
      cellRenderer: PillCellRenderer,
      cellRendererParams: {
         getColor: (item: any) => {
            if (item.value === "paid") {
               return "green";
            }
            if (item === true) {
               return "green";
            }
            return "yellow";
         },
      },
      getStringFormattedValue: (value: any) => {
         if (value.value === "paid") {
            return I18n.t("views.company.workforce_planning.time_off.paid");
         }
         if (value.value === "unpaid") {
            return I18n.t("views.company.workforce_planning.time_off.unpaid");
         }

         return value
            ? I18n.t("views.company.workforce_planning.time_off.paid")
            : I18n.t("views.company.workforce_planning.time_off.unpaid");
      },
      headerName: I18n.t("views.company.workforce_planning.time_off.type"),
      flex: 8,
      editable: true,
      cellEditor: PillSelectCellEditor,
      cellEditorParams: {
         selectOptions: () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(timeOffTypeOptions);
               }, 500);
            }),
         getId: (item) => item?.value,
         getColor: (item) => {
            if (item.value === "paid") {
               return "green";
            }
            if (item === true) {
               return "green";
            }
            return "yellow";
         },
      },
      bulkEditable: true,
      bulkEditEditor: "select",
      bulkEditEditorParams: {
         getOptions: () => {
            return timeOffTypeOptions;
         },
      },
      cellCSVFormatter: cellFormatters.type,
   };
   const repeatColumn: TextCellColumnDefinition = {
      field: "repeat",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.time_off.repeat"),
      getStringFormattedValue: (value: string) => {
         return I18n.t(`views.company.workforce_planning.time_off.${value.toLowerCase()}`);
      },
      flex: 3,
   };
   const occurrencesColumn: NumberCellColumnDefinition = {
      field: "occurences",
      headerName: I18n.t("views.company.workforce_planning.time_off.occurrences"),
      cellRenderer: NumberCellRenderer,
      editable: false,
      flex: 2,
   };
   const submittedOnColumn: DateCellColumnDefinition = {
      field: "submitted_on",
      headerName: I18n.t("views.company.workforce_planning.time_off.submitted_on"),
      cellRenderer: DateCellRenderer,
      editable: false,
      cellCSVFormatter: cellFormatters.submitted_on,
      flex: 6,
   };
   const hidePastTimeOffColumn: TextCellColumnDefinition = {
      field: "hide_past_time_off",
      cellRenderer: TextCellRenderer,
      filterRenderer: HidePastTimeOffFilter,
      headerName: I18n.t("views.company.workforce_planning.time_off.hide_past_time_off"),
      filterProps: {
         getFilterTokenText: ({ headerName }) => headerName,
         index: 1,
      },
      hidden: true,
      lockVisible: true,
      flex: 12,
   };
   const timeOffColumnDefinitions: ColumnDefinition[] = [
      workersNameColumn,
      personStatusTimeOffColumn,
      jobTitleColumn,
      startDateColumn,
      endDateColumn,
      startTimeColumn,
      endTimeColumn,
      reasonColumn,
      typeColumn,
      repeatColumn,
      occurrencesColumn,
      submittedOnColumn,
      hidePastTimeOffColumn,
   ];
   //Reorder the columns based on the saved column state to maintain column order
   const reorderedTimeOffColumnDefinitions = getReorderedColumnDefinition(
      timeOffColumnDefinitions,
      inititalConfig.columnState,
   );

   const handleServerSideDataRequest = useCallback(
      async ({ request, onSuccess, onError }: ServerSideGetRowsParams) => {
         try {
            let startingAfter = undefined;
            // If requesting a second page of data, apply the starting_after query param
            if (request && request.startRow && request.startRow > 0) {
               startingAfter = nextStartAfter.current;
            }
            const data = await fetchTimeOffList(
               request.serverFilters,
               request.sortModel,
               startingAfter,
               request.searchValue,
            );
            onSuccess({ rowData: data.data, rowCount: data.pagination.total_possible });
            nextStartAfter.current = data.pagination.next_starting_after;
         } catch (err) {
            onError();
         }
      },
      [groupId],
   );

   const Edit = (props: DataTableCellRendererProps) => {
      return (
         <EditTimeOffTearsheet
            timeOff={props.data}
            tableApi={tableApi}
            updateTimeOff={updateTimeOff}
         />
      );
   };

   const Delete = (props: DataTableCellRendererProps) => {
      return <DeleteTimeOffModal timeOffId={props.data.id} tableApi={tableApi} />;
   };

   return (
      <ServerSideDataTable
         initialTableConfig={config.current}
         columnDefinitions={reorderedTimeOffColumnDefinitions}
         onServerSideDataRequest={handleServerSideDataRequest}
         getRowId={(params) => params.data.id}
         onTableConfigChange={onTableConfigChange}
      >
         <Flex
            style={{
               height: "100%",
               width: "100%",
            }}
            alignItems="stretch"
         >
            <Flex flex="0 1 0px" alignItems="stretch">
               <ServerSideDataTable.FiltersPanel
                  style={{
                     marginRight: "16px",
                  }}
               />
            </Flex>

            <Box flex="1" display="flex-column" alignItems="stretch">
               {/* @ts-expect-error Quick Controls need to optionally accept children*/}
               <ServerSideDataTable.QuickControls>
                  <Flex>
                     <FlexList size="xs" alignItems="center">
                        <ServerSideDataTable.Search
                           placeholder={I18n.t(
                              "views.company.workforce_planning.time_off.search_placeholder",
                           )}
                        />
                        <ServerSideDataTable.FiltersPanelButton />
                        <ServerSideDataTable.QuickFilters />
                     </FlexList>
                     <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
                        <CreateTimeOffTearsheet
                           createTimeOff={createTimeOff}
                           streamPeople={streamPeople}
                           afterCreate={() => tableApi?.refreshServerSide({})}
                        />
                        <ExportFile
                           exportFileName={"TimeOff_" + currentDateTime + ".csv"}
                           tableApiObj={tableApi}
                        />
                        <CreateSavedViewModal
                           tableApi={tableApi}
                           configKey={LOCAL_STORAGE_CONFIG_KEY}
                           conversionFunction={convertDataTableConfigToSavedView}
                           page={SavedViewPage.TIME_OFF}
                           columnHeadersMap={columnHeadersMap}
                           filterNameMaps={filterNameMaps}
                           filterFieldMap={filterFieldMap}
                           filterRendererMap={filterRendererMap}
                           groupId={groupId}
                        />
                        <ServerSideDataTable.ConfigPanelButton />
                     </FlexList>
                  </Flex>
               </ServerSideDataTable.QuickControls>
               <ServerSideDataTable.BulkActions>
                  <BulkEditTimeOffTearsheet
                     bulkUpdateTimeOff={bulkUpdateTimeOff}
                     tableApi={tableApi}
                  />
                  <DeleteTimeOffModal isBulk tableApi={tableApi} />
               </ServerSideDataTable.BulkActions>
               <ServerSideDataTable.Table
                  onTableReady={handleTableReady}
                  paginationPageSize={100}
                  rowActions={[Edit, Delete]}
                  selectionSSREnabled
                  onCellValueChanged={handleCellValueChanged}
               />
            </Box>
            <Flex flex="0 1 0px" alignItems="stretch">
               <ServerSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
            </Flex>
         </Flex>
      </ServerSideDataTable>
   );
};

import { Box, Flex, Switch, Typography } from "@procore/core-react";
import React from "react";
import { ActionMode } from "@/react/prop-types";
import { Check, Ban } from "@procore/core-icons";

export const FormSwitch = React.memo((props: any) => {
   const { view } = props.field.meta;
   const { toggleValue, setValues } = props;

   const handleChange = (value: boolean) => {
      toggleValue(value);
      setValues("overtime", value);
   };
   return (
      <Flex>
         {view === ActionMode.READ ? (
            <Box>
               {props.value ? (
                  <Check size="sm" style={{ marginTop: "2px" }} />
               ) : (
                  <Ban size="sm" style={{ marginTop: "2px" }} />
               )}
            </Box>
         ) : (
            <Switch
               checked={props.value}
               onChange={() => {
                  handleChange(!props.value);
               }}
               data-testid="overtime-switch"
            />
         )}
         <Typography style={{ paddingLeft: "10px" }}>{props.inlineLabel}</Typography>
      </Flex>
   );
});

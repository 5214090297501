import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type {
   CreateCustomFieldPayload,
   CreateCustomFieldResponse,
} from "@laborchart-modules/lc-core-api/dist/api/custom-fields/create-custom-field";
import type { GetCustomFieldResponse } from "@laborchart-modules/lc-core-api/dist/api/custom-fields/get-custom-field";
import type {
   FindCustomFieldsPaginatedQueryParams,
   FindCustomFieldsPaginatedResponse,
   FindCustomFieldsStreamQueryParams,
} from "@laborchart-modules/lc-core-api/dist/api/custom-fields/find-custom-fields";
import type {
   UpdateCustomFieldPayload,
   UpdateCustomFieldResponse,
} from "@laborchart-modules/lc-core-api/dist/api/custom-fields/update-custom-field";
import type { DeleteCustomFieldResponse } from "@laborchart-modules/lc-core-api/dist/api/custom-fields/delete-custom-field";

export abstract class CustomFieldStore extends Store {
   static createCustomField(
      customFieldData: CreateCustomFieldPayload,
   ): StoreJsonResponse<CreateCustomFieldResponse> {
      return this.requestJson({
         url: `/api/v3/custom-fields`,
         method: "POST",
         body: customFieldData,
      });
   }

   static deleteCustomField(customFieldId: string): StoreJsonResponse<DeleteCustomFieldResponse> {
      return this.requestJson({
         url: `/api/v3/custom-fields/${customFieldId}`,
         method: "DELETE",
      });
   }

   static findCustomFieldsPaginated(
      query: FindCustomFieldsPaginatedQueryParams,
   ): StoreJsonResponse<FindCustomFieldsPaginatedResponse> {
      return this.requestJson({
         url: `/api/v3/custom-fields`,
         query,
      });
   }

   static findCustomFieldsStream(
      query: FindCustomFieldsStreamQueryParams,
   ): StoreStreamResponse<SerializedCustomField> {
      return this.requestStream({
         url: `/api/v3/custom-fields/stream`,
         query,
      });
   }

   static getCustomField(customFieldId: string): StoreJsonResponse<GetCustomFieldResponse> {
      return this.requestJson({
         url: `/api/v3/custom-fields/${customFieldId}`,
      });
   }

   static updateCustomField(
      customFieldId: string,
      update: UpdateCustomFieldPayload,
   ): StoreJsonResponse<UpdateCustomFieldResponse> {
      return this.requestJson({
         url: `/api/v3/custom-fields/${customFieldId}`,
         method: "PATCH",
         body: update,
      });
   }
}

import "./detached-day-editor.styl";
import { DateUtils } from "@/lib/utils/date";
import type { MaybeSubscribable } from "knockout";
import ko, { observable, pureComputed, unwrap } from "knockout";
import template from "./detached-day-editor.pug";
import type { EditorComponentParams } from "@/lib/components/editors/common/editor-component";
import type { ComponentArgs } from "@/lib/components/common";
import { defaultStore } from "@/stores/default-store";
import type { ValidatedValue } from "@/lib/components/editors/common/abstract-field-editor";
import { AbstractFieldEditor } from "@/lib/components/editors/common/abstract-field-editor";

export type DetachedDayValidator = (val: number | null) => {
   status: boolean;
   message: string | null;
};

export interface DetachedDayEditorParams extends EditorComponentParams<number | null> {
   isRequired?: MaybeSubscribable<boolean>;
   isClearable?: MaybeSubscribable<boolean>;
   onOrBeforeDay?: MaybeSubscribable<number>;
   onOrAfterDay?: MaybeSubscribable<number>;
   validators?: MaybeSubscribable<DetachedDayValidator[]>;
}

export class DetachedDayEditor extends AbstractFieldEditor<number | null> {
   readonly date = pureComputed<Date | null>({
      read: () => {
         const day = this.value();
         return day ? DateUtils.getAttachedDate(day) : null;
      },
      write: (date: Date | null) => {
         this.value(date ? DateUtils.getDetachedDay(date) : null);
      },
      pure: true,
   });

   constructor(readonly params: DetachedDayEditorParams) {
      super(params, observable(unwrap(params.value)));
   }

   validate(value: number | null): ValidatedValue<number | null> {
      // Validate when day is required.
      if (unwrap(this.params.isRequired) && !value) {
         return {
            valid: false,
            error: `${unwrap(this.title)} is required.`,
         };
      }

      // Return early when value is not required and is null.
      // NOTE: The save button is never clickable with a null value. The only way to
      // clear the value is using the clear button.
      if (value == null) {
         return { valid: false, error: null };
      }

      // Validate when day must be on or before a specific day.
      if (this.params.onOrBeforeDay && value > unwrap(this.params.onOrBeforeDay)) {
         const day = this.formatDay(unwrap(this.params.onOrBeforeDay));
         return {
            valid: false,
            error: `${unwrap(this.title)} must be on or before ${day}.`,
         };
      }

      // Validate when day must be on or after a specific day.
      if (this.params.onOrAfterDay && value < unwrap(this.params.onOrAfterDay)) {
         const day = this.formatDay(unwrap(this.params.onOrAfterDay));
         return {
            valid: false,
            error: `${unwrap(this.title)} must be on or after ${day}.`,
         };
      }

      // Check custom validators.
      const validators = unwrap(this.params.validators) || [];
      const [firstFailure] = validators
         .map((validator) => validator(this.value()))
         .filter((result) => !result.status);
      if (firstFailure) {
         return { valid: false, error: firstFailure.message };
      }

      return { valid: true, value };
   }

   onDelete = (): Promise<void> => {
      return this.save(null);
   };

   private formatDay(day: number) {
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat(), {
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
         weekdayFormat: DateUtils.WeekDayFormat.NONE,
      });
   }

   static factory<T>(
      provider: (records: T[]) => DetachedDayEditorParams,
   ): (records: T[]) => ComponentArgs<DetachedDayEditorParams> {
      return (records) => ({
         name: "detached-day-editor",
         params: provider(records),
      });
   }
}

ko.components.register("detached-day-editor", {
   viewModel: DetachedDayEditor,
   template: template(),
   synchronous: true,
});

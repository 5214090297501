import type { StoreJsonResponse } from "./common/store.core";
import { Store } from "./common/store.core";
import type {
   CreateNotePayload,
   CreateNoteResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notes/create-note";
import type { DeleteNoteResponse } from "@laborchart-modules/lc-core-api/dist/api/notes/delete-note";
import type {
   UpdateNotePayload,
   UpdateNoteResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notes/update-note";

// Exclusive OR. Only one of the two properties can be provided
type ProjectXorPersonId =
   | { person_id: string; project_id?: never }
   | { person_id?: never; project_id: string };

export abstract class NoteStore extends Store {
   static createNote(
      note: CreateNotePayload,
      entityId: ProjectXorPersonId,
   ): StoreJsonResponse<CreateNoteResponse> {
      return this.requestJson({
         url: "/api/v3/notes",
         method: "POST",
         body: { ...note, ...entityId },
      });
   }

   static deleteNote(
      noteId: string,
      entityId: ProjectXorPersonId,
   ): StoreJsonResponse<DeleteNoteResponse> {
      // Ethier personId or projectId must be provided. If neither are provided, query will be empty and zod will throw an error
      return this.requestJson({
         url: `/api/v3/notes/${noteId}`,
         method: "DELETE",
         query: { ...entityId },
      });
   }

   static updateNote(
      noteId: string,
      entityId: ProjectXorPersonId,
      update: UpdateNotePayload,
   ): StoreJsonResponse<UpdateNoteResponse> {
      return this.requestJson({
         url: `/api/v3/notes/${noteId}`,
         method: "PATCH",
         body: { ...update, ...entityId },
      });
   }
}

import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";

type CloudinaryUploadResponse = {
   asset_id: string;
   public_id: string;
   version: number;
   version_id: string;
   signature: string;
   width: number;
   height: number;
   format: "jpg" | "jpeg" | "png" | "x-png" | "gif" | "bmp";
   resource_type: "image";
   created_at: string;
   tags: any[];
   bytes: number;
   type: "upload";
   etag: string;
   placeholder: boolean;
   url: string;
   secure_url: string;
   folder: string;
   api_key: string;
};

type CloudinaryUploadError = {
   error: {
      http_code: number;
      message: string;
   };
};

export abstract class CloudinaryStore extends Store {
   static uploadPhoto(
      base64Url: string,
   ): StoreJsonResponse<CloudinaryUploadResponse | CloudinaryUploadError> {
      return this.requestJson({
         method: "POST",
         url: "/cloudinary/upload",
         body: { file: base64Url },
      });
   }
}
